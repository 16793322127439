import { Tooltip } from '@mui/material'
import React from 'react'
import commonNames from '../../constants/commonNames.json';
import { useAccessControlContext } from '../../contexts/AccessControlProvider';
const CreditsWidget = ({ companyData }) => {

    const { permissions } = useAccessControlContext();
    const { canSeeCredits } = permissions;
    return (
        canSeeCredits ? <Tooltip title={`${commonNames.Credits} are used to run analysis on your videos.`} arrow>
            <span style={{ whiteSpace: 'nowrap', display: 'flex', alignItems: 'center', marginRight: '20px', fontWeight: '', fontSize: '14px', cursor: 'pointer' }}>My wallet: {companyData?.credits % 1 == 0 ? companyData?.credits : Number(companyData?.credits).toFixed(2)} {commonNames.credits}</span>
        </Tooltip > : <></>
    )
}

export default CreditsWidget
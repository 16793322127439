import React from 'react'
import AllProjectsV2 from './components/project/AllProjectsV2'
import { Container, Toolbar } from '@mui/material';
import AccessControlAccountError from '../cidgets/AccessControlAccountError';
import { useAccessControlContext } from '../../contexts/AccessControlProvider';
import { useNetworkContext } from '../../contexts/NetworkProvider';
import AllNetwork from './components/network/AllNetwork';

const HomeDashboard = (props) => {
  const { isNetworkView } = useNetworkContext();
  const { permissions, role } = useAccessControlContext();
  const { canSeeAllProjects } = permissions;
  const { appBarHeight, companyData } = props;
  return (
    <>
      {companyData ? <Container maxWidth="xl">
        <Toolbar />
        {!canSeeAllProjects ? <AccessControlAccountError access={role} /> : <AllProjectsV2 appBarHeight={appBarHeight} />}
        {/* {!canSeeAllProjects ? <AccessControlAccountError access={role} /> : isNetworkView ? <AllNetwork /> : <AllProjectsV2 appBarHeight={appBarHeight} />} */}
      </Container> : <></>}
    </>
  )
}

export default (HomeDashboard);
import { Chip, Tooltip } from "@mui/material";
import COLORS from '../../constants/colors.json'

const AnalysisTypeIndicator = ({ type = 0, size = 12, weight = 'normal', hasBorder = false, long = false }) => {

    const types = {
        0: { name: "Assets", long: "Assets Analysis", color: COLORS.colors[1], textColor: 'black' },
        2: { name: "360", long: "360 Tagger", color: COLORS.colors[2], textColor: 'white' },
        3: { name: "Safety", long: "Safety Analysis", color: '#8d3df4', textColor: 'black' },

        1: { name: "3D Scan", long: "3D Scanning", color: COLORS.colors[0], textColor: 'white' },
        4: { name: "Advanced", long: "Advanced Imaging", color: '#49ce02', textColor: 'white' },
        5: { name: "Detector", long: "Distress Detector", color: '#0B6E4F', textColor: 'white' },
    }

    return (
        <Tooltip title={types[type].long} arrow>
            <Chip
                size="small"
                variant="outlined"
                label={long ? types[type].long : types[type].name}
                sx={{ borderRadius: '7px', margin: '0px', height: Number((size * 2) / 10).toFixed(0) * 10, padding: '3px', backgroundColor: types[type].color, color: types[type].textColor, fontWeight: weight, fontSize: size, border: hasBorder ? '2px solid white' : '0px' }}
            />
        </Tooltip>
    );
}

export default AnalysisTypeIndicator;
import React, { useEffect, useRef, useState } from 'react'
import './table.css'
import { connect } from 'react-redux';
import { storage } from '../../../../../../config/firebaseConfig';
import CustomTooltip from '../../../../../cidgets/CustomTooltip';
import { Card, Checkbox } from '@mui/material';
const BasicListTable = (props) => {
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const { allNetworkDefects, datastore } = props;
    const { page, limit, defectPinned, setDefectPinned } = props;
    const { basicImagingData } = datastore;
    const { dataInfo: basicImagingDataInfo, defectNames } = basicImagingData;


    const handlePinning = (e) => {
        setDefectPinned(e)
    }
    const priorityMap = {
        "map": basicImagingDataInfo['priority'],
        "keys": Object.keys(basicImagingDataInfo['priority']),
        "values": Object.values(basicImagingDataInfo['priority']),
        "usefulKeys": ['0', '1', '2'],
    }
    const [defectImageURLs, setDefectImageURLs] = useState([]);
    const tableRef = useRef(null);
    const style = {
        "paddingLeft": "15px",
        "paddingRight": "15px",
        "width": "100%",
        "height": "100%",
        "overflow": "auto",
    }
    useEffect(() => {
        const generateDefectImageURLs = async () => {

            const urls = {};
            await Promise.all(allNetworkDefects.map(async defect => {
                const { id, defectImagePath } = defect;
                try {
                    const storageRef = storage.ref(defectImagePath);
                    const downloadURL = await storageRef.getDownloadURL();
                    urls[id] = downloadURL;
                } catch (error) {
                    console.error(`Error fetching download URL for defect with ID ${id}:`, error);
                    urls[id] = null;
                }
            }));
            setDefectImageURLs(urls);
        };

        generateDefectImageURLs();
    }, [allNetworkDefects]);

    return (
        <div style={style}>
            {allNetworkDefects && allNetworkDefects.length > 0 ?
                <table className="assetTable">
                    <thead style={{}}>
                        <tr>
                            <th className="" style={{ width: '10%', fontWeight: '700' }}>Number</th>
                            <th className="" style={{ width: '20%', fontWeight: '700' }}>Feature</th>
                            <th className="" style={{ width: '40%', fontWeight: '700' }}>Category</th>
                            <th className="" style={{ width: '20%', fontWeight: '700' }}>Priority</th>
                            <th className="" style={{ width: '20%', fontWeight: '700' }}>Confirm data</th>
                        </tr>
                    </thead>
                    <tbody ref={tableRef}>
                        {allNetworkDefects.map((e, i) => {
                            const { id, sectionId, defectName, priority, isValid } = e;
                            const obj = {
                                pred: e,
                            }
                            const check = (obj.pred.id === defectPinned?.pred?.id ?? '')
                            return <tr
                                key={id}
                                id={id}
                                style={{ transition: 'all ease 0.0s', ...check ? { backgroundColor: '#B0FFA4', border: '2px solid #60EE00', cursor: 'pointer' } : { cursor: 'pointer' } }}
                                role="button"
                            >
                                <td style={{ width: '10%', fontWeight: '400' }}
                                    onClick={() => handlePinning(obj)}
                                >
                                    <div style={{
                                        width: '40px', height: '40px', borderRadius: '50%',
                                        // backgroundColor: score,
                                        display: 'flex', alignItems: 'center',
                                        justifyContent: 'center',
                                        color: "black",
                                        fontSize: '16px',
                                        fontWeight: '400'
                                    }}>{page * limit + i}</div>
                                </td>
                                <td style={{ width: '20%', fontWeight: '400' }}
                                    onClick={() => handlePinning(obj)}
                                >
                                    <CustomTooltip
                                        placement="top"
                                        arrow
                                        title={
                                            <Card
                                                elevation={5}>
                                                <img src={defectImageURLs[id]} width="100px" style={{ maxHeight: '200px' }} />
                                            </Card>
                                        }
                                        widget={
                                            <img src={defectImageURLs[id]} height={50} width={50} style={{ objectFit: 'cover' }} />
                                        }
                                    />
                                </td>
                                <td
                                    onClick={() => handlePinning(obj)}
                                    // onClick={!isCompareOpen ? () => { handlePinning(obj) } : null} 
                                    style={{ width: '40%', fontWeight: '400', color: '#000000' }} className="capitalize-text">
                                    {defectNames ? defectNames[defectName] : <span style={{ color: '#000000', fontWeight: '400' }}><i>{'unnamed'}</i></span>}
                                </td>

                                <td style={{ width: '20%', color: '#000000', fontWeight: '400' }} className="capitalize-text"
                                    // onClick={() => { handleShareClick(outputId) }}
                                    onClick={() => handlePinning(obj)}
                                >
                                    {`${priorityMap?.map[priority].name}`}
                                </td>
                                <td
                                    onClick={() => handlePinning(obj)}
                                    // onClick={!isCompareOpen ? () => { handlePinning(obj) } : null} 
                                    style={{ width: '20%', fontWeight: '400' }}><Checkbox checked={isValid} disabled  {...label}
                                        sx={{
                                            '&.Mui-checked.Mui-disabled': {
                                                color: '#f0f0f0',
                                                '& .MuiSvgIcon-root': {
                                                    backgroundColor: '#606060',
                                                },
                                            },
                                            '&.Mui-disabled': {
                                                color: '#606060',
                                                backgroundColor: 'transparent',
                                                '& .MuiSvgIcon-root': {
                                                    backgroundColor: 'transparent',
                                                },
                                            },
                                            position: 'static',
                                        }}
                                    /></td>
                            </tr>
                        })}
                    </tbody>
                </table> : <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>No data found</div>
            }

        </div >
    )
}
const mapStateToProps = (state) => {
    return {
        datastore: state.datastore,
    };
};

export default connect(mapStateToProps, null)(BasicListTable);
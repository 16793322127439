import React from 'react'
import CustomProgress from '../../../../cidgets/CustomProgress';
import DistressListTable from './defectsListView/DistressListTable';
import AssetListTable from './defectsListView/AssetListTable';
import SafetyListTable from './defectsListView/SafetyListTable';
import BasicListTable from './defectsListView/BasicListTable';
const ProjectDefectListView = (props) => {
  let { allNetworkDefects, drNetworkData, defectPinned, setDefectPinned, page, limit } = props;
  const projectType = drNetworkData.projectType;
  const distressTypeList = [1, 4];
  const basicType = drNetworkData.projectType === 5;
  const assetType = drNetworkData.projectType === 0;
  const safetyType = drNetworkData.projectType === 3;


  if (!allNetworkDefects) {
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <CustomProgress text={"Loading defects"} />
    </div>
  }
  const defectsListViewComponent = [distressTypeList.includes(projectType) ?
    <DistressListTable allNetworkDefects={allNetworkDefects}
      page={page}
      limit={limit}
      defectPinned={defectPinned} setDefectPinned={setDefectPinned} 
      drNetworkData={drNetworkData}
      /> :
    assetType ? <AssetListTable /> : safetyType ? <SafetyListTable /> :
      basicType ?
        <BasicListTable allNetworkDefects={allNetworkDefects}
          page={page}
          limit={limit}
          defectPinned={defectPinned} setDefectPinned={setDefectPinned} /> : <></>]
  return (
    <>
      {defectsListViewComponent}
    </>
  )
}

export default ProjectDefectListView
import firebase from 'firebase/app'
import 'firebase/firestore';
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/database'
import functions from "firebase/functions";

const firebaseConfig = {
    apiKey: "AIzaSyBX6T63o-N1Nysxbb4P3uhCw1v8Q6zEeA8",
    authDomain: "deeprowserverless.firebaseapp.com",
    projectId: "deeprowserverless",
    storageBucket: "deeprowserverless.appspot.com",
    messagingSenderId: "76531320480",
    appId: "1:76531320480:web:3a83629a8e3ac92c822bc5",
    measurementId: "G-LMKB5JC73B"
  };

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const storage = firebase.storage();
const cloudFunction = firebase.functions();
const firestore = firebase.firestore();

export { storage, cloudFunction, firestore, firebase as default };
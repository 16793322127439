import { useEffect, useState } from "react";
import { Player, BigPlayButton, ControlBar, PlaybackRateMenuButton, Shortcut, VolumeMenuButton } from 'video-react';
import POSTER from '../../../../../../assets/bg/POSTER1.png'

// Global variable.
// PS: Donot remove.
var currentTime = 0

const SurfaceEvaluationVideoPlayer = (props) => {
    const {
        // isVideoActive, videoPlayerState, setVideoPlayerState, 
        shouldOffset = true, altKey = 0, link, playerRef, height, control = true, handleNextFrame, handlePrevFrame, fps = 24, disableControlsFully = false, startTime = 0, defaultRate = 1, onCurrentTimeChange = () => { } } = props;
    const [videoPlayerState, setVideoPlayerState] = useState(null)
    useEffect(() => {
        if (playerRef) {
            playerRef.current?.subscribeToStateChange(handleBind)
        }
    });

    useEffect(() => {
        // //////console.log("mounted!")
        playerRef.current.play()
        playerRef.current.pause()

    }, [])

    useEffect(() => {
        playerRef.current?.seek(startTime)
        playerRef.current.playbackRate = defaultRate;
        // //////console.log("Start time", startTime, link.split('/').pop())

        return () => {
            // //////console.log("Exiting", link.split('/').pop(), currentTime)
        };
    }, [playerRef])

    const handleBind = (state) => {
        currentTime = state?.currentTime
        setVideoPlayerState(state);
    }

    useEffect(() => {
        if (!videoPlayerState?.isActive) {
            playerRef.current.play()
            playerRef.current.pause()
        }
    }, videoPlayerState)
    // useEffect(() => {
    //     if (isVideoActive) {
    //         playerRef.current.play()
    //         playerRef.current.pause()
    //     }
    // }, [isVideoActive])

    useEffect(() => {
        // //////console.log(currentTime)
        onCurrentTimeChange(videoPlayerState?.currentTime)
    }, [videoPlayerState])

    return (
        <div onContextMenu={e => e.preventDefault()}>

            <Player key={altKey} ref={playerRef} preload="auto" startTime={startTime + (shouldOffset ? (1 / fps) : 0)} autoPlay={false} muted={true} src={link} poster={POSTER} fluid={false} height={height} width="auto">
                <Shortcut clickable={true} />
                <BigPlayButton position="center" />
                {/* <PlayToggle/> */}
                <ControlBar autoHide={false} disableDefaultControls={control} disableCompletely={disableControlsFully}>

                    <a style={{ cursor: 'pointer', color: "white", padding: '10px', textDecoration: 'none' }} title="Previous frame" role="button" onClick={handlePrevFrame}>{!control ? '<' : ''}</a>
                    <span style={{ color: "white", padding: '10px', }}>{videoPlayerState === null ? '' : 'Frame: ' + Math.floor(fps * videoPlayerState?.currentTime)}</span>
                    {/* <ReplayControl seconds={5} order={3} />
                <ForwardControl seconds={5} order={3} /> */}
                    <a style={{ cursor: 'pointer', color: "white", padding: '10px', textDecoration: 'none' }} title="Next frame" role="button" onClick={handleNextFrame}>{!control ? '>' : ''}</a>
                    {control ? <></> : <PlaybackRateMenuButton rates={[5, 4, 2, 1, 0.5, 0.1]} />}

                    <VolumeMenuButton disabled />
                    {/* <CurrentTimeDisplay/> */}
                </ControlBar>
            </Player>

        </div>
    )
}
export default SurfaceEvaluationVideoPlayer;
import React from 'react'
import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import Chip from '@mui/material/Chip';
const AssetTableFilter = (props) => {
  const {qualities,handleQuality} = props;
  return (
    <Box style={{backgroundColor:''}} sx={{ display: { xs: 'none', md: 'flex' }, flexDirection: 'row', alignItems: 'center', justifyContent: 'start', padding: '20px' }}>
      <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>Filters applied ({qualities.length}/3) : </Typography>
      <Box sx={{ marginLeft: '10px' }}>
        <Chip size='small' label={qualities.includes(2) ? "Bad X" : "Bad"} variant="outlined" onClick={() => handleQuality(2)} sx={{ cursor: 'pointer', borderColor: '#1538B2', backgroundColor: `rgba(21,56,78,0.17)`, color: '#1538B2' }} />
        <Chip size='small' label={qualities.includes(1) ? "Medium X" : "Medium"} variant="outlined" onClick={() => handleQuality(1)} sx={{ marginLeft: '10px', cursor: 'pointer', borderColor: '#1538B2', backgroundColor: `rgba(21,56,78,0.17)`, color: '#1538B2' }} />
        <Chip size='small' label={qualities.includes(0) ? "Good X" : "Good"} variant="outlined" onClick={() => handleQuality(0)} sx={{ marginLeft: '10px', cursor: 'pointer', borderColor: '#1538B2', backgroundColor: `rgba(21,56,78,0.17)`, color: '#1538B2' }} />
      </Box>
    </Box>
  )
}
export default AssetTableFilter
import React, { useEffect, useState } from 'react'
import { Box, Button, Container, Grid, CircularProgress, FormControlLabel, RadioGroup, Radio, FormControl } from '@mui/material';
import { connect } from "react-redux";
import { get } from "lodash";
import { makeInferenceRequest, makeVisualizerInferenceRequest, makeAdvanceInferenceRequest, removeInferenceErrorAndMessage, removeVideoEstimationErrorAndMessage, videoEstimationCost, makeHaltProcessingRequestOnProject, setupProjectBack, updateProcessingTime, makeAdvanceInferenceRequestWithTransferCredit, clearUpdateProjectState } from '../../../../../../../store/actions/projectActions';
import { removeTransferCreditsToProjectJarErrorAndMessage, transferCreditsToProjectJar } from '../../../../../../../store/actions/paymentActions';
import commonNames from '../../../../../../../constants/commonNames.json'
import * as ROUTES from "../../../../../../../constants/routes";
import { Link } from 'react-router-dom';
import AddNewCard from '../../../../account/subpages/AddNewCard';
import Error from '../../../../../../cidgets/Error';
import CustomProgress from '../../../../../../cidgets/CustomProgress';
import CreditTransferComponent from '../../../../../../cidgets/CreditTransferComponent';
import AssetAnalysis from '../../../../../../../assets/AssetAnalysis.jpg'
import SurfaceAnalysis from '../../../../../../../assets/SurfaceAnalysis.jpg'
import BasicImaging from "../../../../../../../assets/BasicImaging.png";
import Scan3d from "../../../../../../../assets/scan3d.jpg";
import ImageAnalysis from "../../../../../../../assets/ImageAnalysis.jpg";
import PlansListAdapter from '../../../../account/subpages/pricingPlansComponents/PlansListAdapter';
import SimpleDialogBox from '../../../../../../cidgets/SimpleDialogBox';
import { useAccessControlContext } from '../../../../../../../contexts/AccessControlProvider';
import AccessControlAccountError from '../../../../../../cidgets/AccessControlAccountError';
import SmartUtils from '../../../../../../../helpers/SmartUtils';
import countries from '../../../../../../../constants/countries.json';

const RunInferenceStep = (props) => {
  const { permissions, role } = useAccessControlContext();
  const { canPayForProjects, canBuyCredits, canRunAnalysis } = permissions;

  const { project, proid, drProjectData, company, store, charges, chargesV3, payment, setActiveStep, companyPricingData, setupProjectBack } = props;
  const { transferingCreditsLoader, transferingCreditsMessage, transferingCreditsIsDoneAndSent } = payment;
  const { videoEstimationLoader, videoEstimationIsDoneAndSent, videoEstimationMessage, haltLoader } = project;
  const { inferenceMessage, infernenceIsDoneAndSent, inferenceLoader } = project;
  const { approxCreditsRequired, projectCreditJar, projectType, isForked, calculatingPrice, baseCreditAmountRequired } = drProjectData;
  const isBaseCreditAmountRequired = drProjectData.baseCreditAmountRequired ? true : false;
  const processingTimeCost = calculatingPrice ?? false;
  const payFromSubscription = drProjectData.payFromSubscription ? drProjectData.payFromSubscription : false;
  const paymentSubscriptionId = drProjectData.paymentSubscriptionId ? drProjectData.paymentSubscriptionId : false;
  const [openTranferCreditDialog, setOpenTranferCreditDialog] = useState(false);
  const { userData } = props
  const { country, countryName } = userData;
  const [selectedOption, setSelectedOption] = useState(drProjectData.processingTime);
  const [exchangeRate, setExchangeRate] = useState(null);
  const [currencyCode, setCurrencyCode] = useState('');
  const selectedCountry = countryName || country
  const [surchargeData, setSurchargeData] = useState({});

  useEffect(() => {
    setSelectedOption(drProjectData.processingTime);
  }, [drProjectData.processingTime]);

  useEffect(() => {
    const fetchCountryCurrency = async () => {
      try {
        const country = countries.find(country => country.name.common === selectedCountry);
        // //console.log(country, 'country123')
        if (country) {
          const code = Object.keys(country.currencies)[0];
          // //console.log(code,'usman');
          setCurrencyCode(code);
        } else {
          setCurrencyCode('');
          console.error('Country not found');
        }
      } catch (error) {
        console.error('Error fetching country data:', error);
      }
    };

    fetchCountryCurrency();
  }, [selectedCountry]);

  useEffect(() => {
    const fetchExchangeRate = async () => {
      try {
        const response = await fetch('https://open.er-api.com/v6/latest/GBP');
        const data = await response.json();
        const countryExchangeRate = data.rates[currencyCode];
        // //console.log(countryExchangeRate,'ABC')
        setExchangeRate(countryExchangeRate);
      }
      catch (error) {
        console.error('Error fetching exchange rate:', error);
      }
    };

    fetchExchangeRate();
  }, [currencyCode]);

  const getSurchargeData = (projectType) => {
    // Check in companyPricingData.projectSurcharge
    if (companyPricingData && companyPricingData.projectSurcharge && companyPricingData.projectSurcharge[projectType]) {
      return companyPricingData.projectSurcharge[projectType];
    }

    // Check in companyPricingData.surcharge
    if (companyPricingData && companyPricingData.surcharge) {
      return companyPricingData.surcharge;
    }

    // Check in chargesV3.projectSurcharge
    if (chargesV3 && chargesV3.projectSurcharge && chargesV3.projectSurcharge[projectType]) {
      return chargesV3.projectSurcharge[projectType];
    }

    // Default to chargesV3.priorityMultipliers
    if (chargesV3 && chargesV3.priorityMultipliers) {
      return chargesV3.priorityMultipliers;
    }

    // Default return if no conditions are met
    return null;
  };

  useEffect(() => {
    const sortOrder = ["oneWeek", "twoDays", "oneDay", "twoWeeks"];
    const sortedData = {};
    let data = getSurchargeData(projectType);
    sortOrder.forEach(key => {
      if (data[key] !== undefined) {
        sortedData[key] = data[key];
      }
    });
    setSurchargeData(sortedData);
  }, [projectType]);

  const convertGBPToAUD = (gbpAmount, surchargeRate) => {
    //console.log(gbpAmount, surchargeRate, 'jkl')
    if (exchangeRate === null) return 'Loading...';
    const baseAmount = gbpAmount * exchangeRate;
    //const surchargeAmount = baseAmount * surchargeRate;
    const result = baseAmount * (1 + surchargeRate);
    // return (baseAmount + surchargeAmount).toFixed(2);
    //console.log(result, 'mno')
    return result.toFixed(2);
  };

  const handleCheckboxChange = (event) => {
    setSelectedOption(event.target.value);
    // //console.log(proid, event.target.value, 'none')
    props.updateProcessingTime(proid, event.target.value)
  };

  useEffect(() => {
    if (infernenceIsDoneAndSent === true && inferenceLoader === false) {
      setOpenTranferCreditDialog(false);
    }
  }, [infernenceIsDoneAndSent])
  useEffect(() => {
    props.removeVideoEstimationErrorAndMessage();
  }, []);

  if (company === null) {
    return <></>
  }
  const { credits, isStripeSetupComplete = false } = company;
  const customCardPadding = 15;

  const projectTypeName = {
    0: {
      name: "Asset",
      displayName: "Asset analysis",
      logo: AssetAnalysis,
      text: "Detect and geo locate linear transportation assets like lampposts, traffic signs, marker posts, etc.",
    },
    1: {
      name: "3D Scanning",
      displayName: "3D scanning",
      logo: Scan3d,
      text: "Detection, classification, and depth and surface level measurements of carriage way distresses from a rear facing video stream.",
    },
    2: {
      name: "360 Tagger",
      displayName: "360 tagger",
      logo: ImageAnalysis,
      text: "Detect and geo locate linear transportation assets like lampposts, traffic signs, marker posts, etc.",
    },
    3: {
      name: "Safety",
      displayName: "Safety analysis",
      logo: AssetAnalysis,
      text: "Detect and geo locate linear transportation assets like lampposts, traffic signs, marker posts, etc.",
    },
    4: {
      name: "Advanced Imaging",
      displayName: "Advanced imaging",
      logo: SurfaceAnalysis,
      text: "Detection, classification, and surface level measurements of carriage way distresses using from a rear facing video stream. No depth measurements are available",
    },
    5: {
      name: "Basic Imaging",
      displayName: "Basic imaging",
      logo: BasicImaging,
      text: "Detection, classification, and coarse measurements of carriageway distresses from a front facing video stream. No depth measurements are available",
    }
  };
  const initialScreenTitle = `${drProjectData.projectType === 2 ? 'Setting up' : 'Running'} ${projectTypeName[drProjectData['projectType']].name} requires action`
  const initialScreenBody = payFromSubscription ? `${drProjectData.projectType === 2 ? 'To process the video(s)' : 'To perform the analysis'}, you need ${approxCreditsRequired || 0} credits, and you have ${companyPricingData.subscriptions[drProjectData.projectType][`${paymentSubscriptionId}`]['remainingCredits']} credits in your subscription wallet.`
    : `${drProjectData.projectType === 2 ? 'To process the video(s)' : 'To perform the analysis'}, you need ${approxCreditsRequired || 0} credits, and you have ${credits % 1 == 0 ? credits : Number(credits).toFixed(2) || 0} credits in your wallet.`
  const projectStatus = drProjectData.status;
  const isHalted = drProjectData.isHalted ? drProjectData.isHalted : false;//false
  const isHaltPossible = drProjectData.isHaltPossible ? drProjectData.isHaltPossible : false;//true
  const isHaltInProcess = drProjectData.isHaltInProcess ? drProjectData.isHaltInProcess : false;
  const heading = projectTypeName[drProjectData['projectType']].displayName;
  const logo = projectTypeName[drProjectData['projectType']].logo;
  const description = projectTypeName[drProjectData['projectType']].text;
  // const isInferenceRequestGen = projectStatus == 6 || projectStatus == 7 ? true : false;
  const productList = Object.values(store);
  const handleCloseTranferCreditDialog = () => {
    setOpenTranferCreditDialog(false);
  };
  const handleTransferCreditToProjectJar = () => {
    props.removeTransferCreditsToProjectJarErrorAndMessage();
    setOpenTranferCreditDialog(true);
  }
  const handleVideoEstimation = (proid) => {
    props.removeVideoEstimationErrorAndMessage();
    props.videoEstimationCost(proid);
  }
  const dynamicButton = <>
    {
      approxCreditsRequired === undefined || Number(approxCreditsRequired) === 0 ?
        <>
          <p style={{ color: '#B00020' }}>The video estimation cost is not there, please run estimation cost again.
            <br />
            {
              videoEstimationLoader ? <>
                <Button variant="contained" disabled sx={{
                  marginTop: '20px',
                  textTransform: 'initial', backgroundColor: '#025196', color: '#ffffff',
                  "&.Mui-disabled": {
                    backgroundColor: '#025196',
                    color: '#ffffff'
                  }
                }}>
                  <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} /><span style={{ paddingLeft: '15px' }}>Estimating...</span>
                </Button>
              </>
                :
                < Button variant="contained" sx={{
                  marginTop: '20px',
                  textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                  '&:hover': {
                    backgroundColor: '#025196',
                    color: '#FFFFFF'
                  }
                }}
                  onClick={() => { handleVideoEstimation(proid) }}
                >Run video estimation</Button>

            }
            <Button size="medium" variant="contained"
              onClick={() => {
                setupProjectBack(proid);
                props.clearUpdateProjectState();
                setActiveStep((SmartUtils.supportCCIProject(projectType) ? 5 : (projectType === 5 ? 5 : 4)))

              }}
              sx={{
                marginTop: '20px',
                marginLeft: '5px',
                textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                '&:hover': {
                  backgroundColor: '#025196',
                  color: '#FFFFFF'
                }
              }}>Back</Button>

            <br />
            <p style={{ color: 'black', marginTop: '10px' }}>
              {videoEstimationIsDoneAndSent === false ? <Error errorMessage={videoEstimationMessage} /> : <></>}
              <div style={{ marginTop: '10px' }}>
                <Link
                  style={{ textDecoration: 'underline', color: 'black', cursor: 'pointer', marginTop: '0px' }}
                  to={`/p${ROUTES.CONTACT}`}
                  state={{ proid: proid, isProject: true }}
                > Click here</Link> to contact customer support team.
              </div></p>
          </p>



        </> : (credits === 0 &&
          companyPricingData.subscriptions[drProjectData.projectType] &&
          companyPricingData.subscriptions[drProjectData.projectType][`${paymentSubscriptionId}`]?.remainingCredits > 0) ||
          credits >= Number(approxCreditsRequired) ?
          <>
            < Button variant="contained"
              disabled={!canPayForProjects}
              sx={{
                marginTop: '0px',
                textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                '&:hover': {
                  backgroundColor: '#025196',
                  color: '#FFFFFF'
                }
              }}
              onClick={handleTransferCreditToProjectJar}
            >
              {/* Analyze this project using {Number(approxCreditsRequired)} credits */}
              Take action
            </Button>
            <Button size="medium" variant="contained"
              onClick={() => {
                setupProjectBack(proid);
                props.clearUpdateProjectState();
                setActiveStep((SmartUtils.supportCCIProject(projectType) ? 6 : (projectType === 5 ? 6 : 4)))

              }}
              sx={{
                marginLeft: '5px',
                textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                '&:hover': {
                  backgroundColor: '#025196',
                  color: '#FFFFFF'
                }
              }}>Back</Button>
          </> :
          !canBuyCredits ? <>
            <AccessControlAccountError access={role} isDialog={true} />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button size="medium" variant="contained"
                onClick={() => {
                  setupProjectBack(proid);
                  props.clearUpdateProjectState();
                  setActiveStep((SmartUtils.supportCCIProject(projectType) ? 6 : (projectType === 5 ? 6 : 4)))

                }}
                sx={{
                  marginLeft: '5px',
                  textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                  '&:hover': {
                    backgroundColor: '#025196',
                    color: '#FFFFFF'
                  }
                }}>Back</Button>
            </div>

          </> :
            <>
              <div style={{ display: 'flex', justifyContent: 'start' }}>
                <Button size="medium" variant="contained"
                  onClick={() => {
                    setupProjectBack(proid);
                    props.clearUpdateProjectState();
                    setActiveStep((SmartUtils.supportCCIProject(projectType) ? 6 : (projectType === 5 ? 6 : 4)))

                  }}
                  sx={{
                    marginRight: '5px',
                    textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                    '&:hover': {
                      backgroundColor: '#025196',
                      color: '#FFFFFF'
                    }
                  }}>Back</Button>
                <PlansListAdapter productList={productList} charges={charges} proid={proid} isProjectCreationScreen={true}
                  companyCredits={credits % 1 === 0 ? credits : Number(credits).toFixed(2)} approxCreditsRequired={approxCreditsRequired} />
              </div>

            </>

    }
  </>

  const mainContent = <div style={{
    height: `calc(100% - ${customCardPadding * 2}px)`,
    //  height: `${isInferenceRequestGen ? inferenceReqGenDivHeight : 50}vh`,
    display: 'flex', alignItems: 'center', justifyContent: 'center'
  }}>
    <Box
      sx={{
        width: 'fit-content',
        height: 'fit-content',
        backgroundColor: 'white',
        borderRadius: '20px',
        padding: `${customCardPadding}px`,
        border: '1px solid grey',
      }}
    >
      <div style={{
        padding: '40px',
        height: `calc(100% - ${customCardPadding * 2}px)`,
        backgroundColor: 'transparent', overflow: 'auto'
      }}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <div style={{ marginLeft: '0px', height: '100%', backgroundColor: 'transparent' }}>
              {processingTimeCost ?
                <>
                  <Box sx={{ marginBottom: '16px' }}>
                    <span style={{ fontWeight: 'bold', fontSize: '17px' }}>{initialScreenTitle}</span>
                  </Box>
                  <Box sx={{ marginBottom: '16px' }}>
                    <CircularProgress variant="indeterminate" size={15} sx={{ color: '#000000' }} /><span style={{ paddingLeft: '15px' }}>Please wait while we recalculate the price</span>
                    {/* <span style={{ fontSize: '14px' }}>Please wait while we recalculate the price</span> */}
                  </Box>
                </>

                :
                <>
                  <Box sx={{ marginBottom: '16px' }}>
                    <span style={{ fontWeight: 'bold', fontSize: '17px' }}>{initialScreenTitle}</span>
                  </Box>
                  <Box sx={{ marginBottom: '16px' }}>
                    <span style={{ fontSize: '14px' }}>{approxCreditsRequired === undefined || Number(approxCreditsRequired) === 0 ? '' : initialScreenBody}</span>

                    <div style={{ marginTop: '20px' }}>
                      {isBaseCreditAmountRequired && projectType !== 2 && !payFromSubscription && Object.entries(surchargeData).length > 0 &&
                        < FormControl >
                          <RadioGroup
                            aria-label="timeDuration"
                            name="timeDuration"
                            value={selectedOption}
                            onChange={handleCheckboxChange}
                          >
                            {Object.entries(surchargeData).map(([duration, surcharge]) => {
                              const label = duration.replace('oneWeek', '1 week [default]')
                                .replace('twoDays', '2 work days [25% surcharge]')
                                .replace('oneDay', '1 work day [50% surcharge]')
                                .replace('twoWeeks', '2 weeks');
                              return (
                                <FormControlLabel
                                  key={duration}
                                  value={duration}
                                  control={<Radio id={`option-${duration}`} />}
                                  label={`${label} (approx ${convertGBPToAUD(baseCreditAmountRequired, surcharge)} ${currencyCode}) `}
                                />
                              );
                            })}
                          </RadioGroup>
                        </FormControl>
                      }
                    </div>

                  </Box>
                  <Box sx={{ marginBottom: '16px' }}>
                    {dynamicButton}
                  </Box>
                </>}
            </div>
          </Grid>
        </Grid>
      </div>
    </Box>
  </div >

  const initialScreen = <>
    {
      companyPricingData?.subscriptions[drProjectData?.projectType] &&
        companyPricingData?.subscriptions[drProjectData?.projectType][`${paymentSubscriptionId}`]?.remainingCredits &&
        companyPricingData?.subscriptions[drProjectData?.projectType][`${paymentSubscriptionId}`].remainingCredits > 0 &&
        credits === 0 && !isStripeSetupComplete ?
        <> {mainContent}</> :
        credits > 0 && !isStripeSetupComplete ?
          <>{mainContent}</> :
          !isStripeSetupComplete ?
            !canBuyCredits ? <>
              <AccessControlAccountError access={role} isDialog={true} />
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button size="medium" variant="contained"
                  onClick={() => {
                    setupProjectBack(proid);
                    props.clearUpdateProjectState();
                    setActiveStep((SmartUtils.supportCCIProject(projectType) ? 6 : (projectType === 5 ? 6 : 4)))
                  }}
                  sx={{
                    marginLeft: '5px',
                    textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                    '&:hover': {
                      backgroundColor: '#025196',
                      color: '#FFFFFF'
                    }
                  }}>Back</Button>
              </div>
            </>
              :
              <Container sx={{ paddingBlock: '30px' }}><Container><AddNewCard buttonText={`Add a new card to purchase ${Number(approxCreditsRequired ?? 0)} ${commonNames.credits}`} /></Container></Container>

            :
            <>{mainContent}</>

    }
  </>
  // if (analysisRequests === undefined || analysisRequests.length === 0) {
  //   return projectCreditJar ? <div style={{ height: '70vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>Your project has been placed in the analysis queue automatically.</div> : <>
  //     <SimpleDialogBox
  //       open={openTranferCreditDialog}
  //       onClose={handleCloseTranferCreditDialog}
  //       width={800}
  //       title={`Confirm ${commonNames.credits} transfer`}
  //       bodyWidget={
  //         <CreditTransferComponent creditValue={approxCreditsRequired} />
  //       }
  //       showError={transferingCreditsIsDoneAndSent === false ? true : false}
  //       errorMessage={transferingCreditsIsDoneAndSent === false ? transferingCreditsMessage : ''}
  //       showPositive
  //       positiveButtonWidget={
  //         transferingCreditsLoader ? <>
  //           <Button variant="contained" disabled sx={{
  //             textTransform: 'initial', backgroundColor: '#025196', color: '#ffffff',
  //             "&.Mui-disabled": {
  //               backgroundColor: '#025196',
  //               color: '#ffffff'
  //             }
  //           }}>
  //             <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} /><span style={{ paddingLeft: '15px' }}>Transferring...</span>
  //           </Button>
  //         </>
  //           :
  //           < Button variant="contained" onClick={() => { props.transferCreditsToProjectJar(proid, approxCreditsRequired) }} sx={{
  //             marginTop: '0px',
  //             textTransform: 'initial', backgroundColor: '#025196', color: '#ffffff',
  //             '&:hover': {
  //               backgroundColor: '#025196',
  //               color: '#ffffff'
  //             }
  //           }}>Confirm</Button>

  //       }

  //     />
  //     {initialScreen}
  //   </>
  // }
  // const analysisRequestsStatus = analysisRequests.length > 0 ? analysisRequests[0]['status'] : "";
  // const isAnalysisrequestsStatusFail = analysisRequests.length > 0 ? Number(analysisRequestsStatus) === -1 || Number(analysisRequestsStatus) === -2 ? true : false : '';
  // const analysisRequestsStatusMessageList = analysisRequests.length > 0 ? analysisRequests[0]['messages'] ? analysisRequests[0]['messages'] : [] : [];
  // const analysisRequestsStatusMessageListReverse = analysisRequestsStatusMessageList.length > 0 ? Object.values(analysisRequestsStatusMessageList).reverse() : [];
  // const analysisRequestsStatusMessage = analysisRequestsStatusMessageListReverse.length > 0 ? analysisRequestsStatusMessageListReverse[0] : '';
  return (
    <>
      <SimpleDialogBox
        open={openTranferCreditDialog}
        onClose={handleCloseTranferCreditDialog}
        width={800}
        title={`Confirm ${commonNames.credits} transfer`}
        bodyWidget={
          <CreditTransferComponent creditValue={approxCreditsRequired} payFromSubscription={payFromSubscription} projectType={drProjectData.projectType} />
        }
        showError={infernenceIsDoneAndSent === false ? true : false}
        errorMessage={infernenceIsDoneAndSent === false ? inferenceMessage : ''}
        showPositive
        positiveButtonWidget={
          inferenceLoader ? <>
            <Button variant="contained" disabled sx={{
              textTransform: 'initial', backgroundColor: '#025196', color: '#ffffff',
              "&.Mui-disabled": {
                backgroundColor: '#025196',
                color: '#ffffff'
              }
            }}>
              <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} /><span style={{ paddingLeft: '15px' }}>Transferring...</span>
            </Button>
          </>
            :
            < Button variant="contained"
              onClick={async () => {
                await props.removeInferenceErrorAndMessage();
                props.makeAdvanceInferenceRequestWithTransferCredit(proid);
              }}
              sx={{
                marginTop: '0px',
                textTransform: 'initial', backgroundColor: '#025196', color: '#ffffff',
                '&:hover': {
                  backgroundColor: '#025196',
                  color: '#ffffff'
                }
              }}>Confirm</Button>

        }

      />
      {
        projectCreditJar ?
          <Grid container >
            <Grid item xs={12}>
              <div style={{ backgroundColor: '', height: `calc(${100}vh - 20vh)`, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div
                  style={{ height: `100%`, width: '100%', display: 'flex', justifyContent: 'center', marginTop: '0px' }}
                >
                  <Box
                    sx={{
                      minWidth: '500px',
                      maxWidth: '1000px',
                      width: '100vw',
                      height: 'fit-content',
                      backgroundColor: 'white',
                      borderRadius: '20px',
                      padding: `${customCardPadding}px`,
                      border: '1px solid grey',
                    }}
                  >
                    <div style={{
                      height: `calc(100% - ${customCardPadding * 2}px)`,
                      backgroundColor: '', overflow: 'auto'
                    }}>
                      {
                        // inferenceLoader ?
                        //   <Grid container spacing={0}>
                        //     <Grid item xs={12} style={{
                        //       backgroundColor: '',
                        //       height: '100%',
                        //       padding: '50px',
                        //       borderTopLeftRadius: '20px', borderBottomLeftRadius: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center'
                        //     }}>
                        //       <CustomProgress size={30} text={"An analysis request is being created"} />
                        //     </Grid>
                        //   </Grid> :
                        projectStatus == 6 ?
                          <Grid container spacing={0}>
                            <Grid item xs={12} style={{
                              height: `calc(100% - ${customCardPadding * 2}px)`,
                              padding: '50px',
                              backgroundColor: 'transparent', borderTopLeftRadius: '20px', borderBottomLeftRadius: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center'
                            }}>
                              <div style={{ height: '100%', marginLeft: '20px' }}>
                                <Box sx={{ marginBottom: '0px' }}>
                                  <span style={{ fontWeight: 'bold' }}>{projectTypeName[drProjectData['projectType']].name} analysis request</span>
                                </Box>
                                <Box sx={{ marginBottom: '16px' }}>
                                  <span>Your request has been created, will commence processing shortly.</span>
                                </Box>
                                <Box sx={{}}>
                                  <CustomProgress size={30} />
                                </Box>
                              </div>
                            </Grid>
                          </Grid>
                          : projectStatus === 7 ?
                            <Grid container spacing={0}>
                              <Grid item xs={12} style={{
                                height: `calc(100% - ${customCardPadding * 2}px)`,
                                padding: '50px',
                                backgroundColor: 'transparent', borderTopLeftRadius: '20px', borderBottomLeftRadius: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center'
                              }}>
                                <div style={{ height: '100%', marginLeft: '20px' }}>
                                  <Box sx={{ marginBottom: '0px', display: 'flex', justifyContent: 'center' }}>
                                    <span style={{ fontWeight: 'bold' }}>{projectTypeName[drProjectData['projectType']].name} analysis request</span>
                                  </Box>
                                  <Box sx={{ marginBottom: '16px', display: 'flex', justifyContent: 'center' }}>
                                    {/* isHalted = false isHaltPossible = false and isHaltinProcess  */}
                                    {isHalted === false && isHaltPossible === false && isHaltInProcess === true ?
                                      <span>Your halting request on this analysis has initiated.</span>
                                      : isHalted === false ? isHaltPossible === true ?
                                        <span>Your analysis on this project has started.</span>
                                        : <span>Your analysis on this project has initiated and will start soon.</span> :
                                        <span>Your analysis on this project has halted.</span>
                                    }

                                  </Box>
                                  <Box sx={{ marginBottom: '5px' }}>
                                    {isHalted === false && isHaltPossible === false && isHaltInProcess === true ?
                                      <CustomProgress size={30} />
                                      : isHalted === false ? isHaltPossible === true ?
                                        <CustomProgress size={30} />
                                        : <CustomProgress size={30} /> :
                                        <></>
                                    }
                                  </Box>
                                  <Box sx={{ marginBottom: '0px', display: 'flex', justifyContent: 'center' }}>
                                    {isHalted === false && isHaltPossible === false && isHaltInProcess === true ?
                                      <></>
                                      : isHalted === false ? isHaltPossible === true ?
                                        haltLoader ?
                                          <>
                                            < Button variant="contained" disabled sx={{
                                              textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                                              "&.Mui-disabled": {
                                                backgroundColor: '#025196',
                                                color: '#FFFFFF'
                                              }
                                            }}>
                                              <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} /><span style={{ paddingLeft: '15px' }}>Halting...</span>
                                            </Button>
                                          </>
                                          :
                                          <Button size="medium" variant="contained"
                                            disabled={isForked ? true : false}
                                            onClick={() => { props.makeHaltProcessingRequestOnProject(proid) }}
                                            sx={{
                                              textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                                              '&:hover': {
                                                backgroundColor: '#025196',
                                                color: '#FFFFFF'
                                              }
                                            }}>Halt processing</Button>
                                        : <></> :
                                        inferenceLoader ?
                                          <>
                                            < Button variant="contained" disabled sx={{
                                              textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                                              "&.Mui-disabled": {
                                                backgroundColor: '#025196',
                                                color: '#FFFFFF'
                                              }
                                            }}>
                                              <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} /><span style={{ paddingLeft: '15px' }}>Resuming...</span>
                                            </Button>
                                          </>
                                          :

                                          <>
                                            <Button size="medium" variant="contained"
                                              disabled={isForked ? true : false}
                                              onClick={() => {
                                                props.removeInferenceErrorAndMessage();
                                                props.makeAdvanceInferenceRequest(proid);
                                              }}
                                              sx={{
                                                textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                                                '&:hover': {
                                                  backgroundColor: '#025196',
                                                  color: '#FFFFFF'
                                                }
                                              }}>Resume processing</Button>
                                          </>
                                    }
                                  </Box>
                                  <div style={{ marginTop: '20px', color: '#d32f2f' }}>{infernenceIsDoneAndSent === false ? inferenceMessage : ''}</div>
                                </div>
                              </Grid>
                            </Grid>

                            :
                            <Grid container spacing={0} sx={{ width: 'fit-content' }}>

                              <Grid item xs={4} style={{
                                height: `calc(100% - ${customCardPadding * 2}px)`,
                                backgroundColor: 'transparent', borderTopLeftRadius: '20px', borderBottomLeftRadius: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center'
                              }}>
                                <img src={logo} style={{
                                  height: `calc(10% - ${customCardPadding * 2}px)`,
                                  width: '100%'
                                }} />
                              </Grid>

                              <Grid item xs={8} style={{
                                height: `calc(100% - ${customCardPadding * 2}px)`,
                                backgroundColor: 'transparent', display: 'flex', alignItems: 'start'
                              }}>

                                <div style={{ marginLeft: '20px', height: '100%', backgroundColor: 'transparent' }}>
                                  <Box sx={{ marginBottom: '16px' }}>
                                    <span style={{ fontWeight: 'bold' }}>{heading}</span>
                                  </Box>
                                  <Box sx={{ marginBottom: '16px' }}>
                                    <span>
                                      {description}
                                    </span>
                                  </Box>
                                  <Box sx={{ marginBottom: '16px' }}>
                                    {projectStatus === -6 ?
                                      projectType === 4 || projectType === 1 ?
                                        <>
                                          {
                                            inferenceLoader ?
                                              <>
                                                < Button variant="contained" disabled sx={{
                                                  textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                                                  "&.Mui-disabled": {
                                                    backgroundColor: '#025196',
                                                    color: '#FFFFFF'
                                                  }
                                                }}>
                                                  <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} /><span style={{ paddingLeft: '15px' }}>Analyzing...</span>
                                                </Button>
                                              </>
                                              :

                                              <Button size="medium" variant="contained" disabled={!canRunAnalysis ? true : isForked ? true : false}
                                                onClick={() => {
                                                  props.removeInferenceErrorAndMessage();
                                                  props.makeAdvanceInferenceRequest(proid);
                                                }}
                                                sx={{
                                                  textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                                                  '&:hover': {
                                                    backgroundColor: '#025196',
                                                    color: '#FFFFFF'
                                                  }
                                                }}>Analyze</Button>
                                          }
                                          <br />
                                          <span style={{ marginTop: '20px', color: '#d32f2f' }}>{drProjectData.analysisError ?? ''}</span>
                                        </> :

                                        <>
                                          {
                                            inferenceLoader ?
                                              <>
                                                < Button variant="contained" disabled sx={{
                                                  textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                                                  "&.Mui-disabled": {
                                                    backgroundColor: '#025196',
                                                    color: '#FFFFFF'
                                                  }
                                                }}>
                                                  <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} /><span style={{ paddingLeft: '15px' }}>Analyzing...</span>
                                                </Button>
                                              </>
                                              :

                                              <Button size="medium" variant="contained"
                                                disabled={!canRunAnalysis ? true : isForked ? true : false}
                                                onClick={() => {
                                                  props.removeInferenceErrorAndMessage();
                                                  props.makeAdvanceInferenceRequest(proid);
                                                }}
                                                sx={{
                                                  textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                                                  '&:hover': {
                                                    backgroundColor: '#025196',
                                                    color: '#FFFFFF'
                                                  }
                                                }}>Analyze</Button>
                                          }
                                          <br />
                                          <span style={{ marginTop: '20px', color: '#d32f2f' }}>{drProjectData.analysisError ?? ''}</span>
                                        </>
                                      :
                                      projectType === 4 || projectType === 1 ?
                                        inferenceLoader ?
                                          <>
                                            < Button variant="contained" disabled sx={{
                                              textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                                              "&.Mui-disabled": {
                                                backgroundColor: '#025196',
                                                color: '#FFFFFF'
                                              }
                                            }}>
                                              <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} /><span style={{ paddingLeft: '15px' }}>Analyzing...</span>
                                            </Button>
                                          </>
                                          :
                                          <>
                                            <Button size="medium" variant="contained"
                                              disabled={!canRunAnalysis ? true : isForked ? true : false}
                                              onClick={() => {
                                                props.removeInferenceErrorAndMessage();
                                                props.makeAdvanceInferenceRequest(proid);
                                              }}
                                              sx={{
                                                textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                                                '&:hover': {
                                                  backgroundColor: '#025196',
                                                  color: '#FFFFFF'
                                                }
                                              }}>Analyze</Button>
                                            {/* <Button size="medium" variant="contained"
                                              onClick={() => { setShowMap(true) }}
                                              sx={{
                                                marginLeft: '5px',
                                                textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                                                '&:hover': {
                                                  backgroundColor: '#025196',
                                                  color: '#FFFFFF'
                                                }
                                              }}>View gps</Button> */}
                                          </>

                                        :
                                        inferenceLoader ?
                                          <>
                                            < Button variant="contained" disabled sx={{
                                              textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                                              "&.Mui-disabled": {
                                                backgroundColor: '#025196',
                                                color: '#FFFFFF'
                                              }
                                            }}>
                                              <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} /><span style={{ paddingLeft: '15px' }}>Analyzing...</span>
                                            </Button>
                                          </>
                                          :
                                          <>
                                            <Button size="medium" variant="contained"
                                              disabled={!canRunAnalysis ? true : isForked ? true : false}
                                              onClick={() => {
                                                props.removeInferenceErrorAndMessage();
                                                props.makeAdvanceInferenceRequest(proid);
                                              }}
                                              sx={{
                                                textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                                                '&:hover': {
                                                  backgroundColor: '#025196',
                                                  color: '#FFFFFF'
                                                }
                                              }}>Analyze
                                            </Button>
                                            {/* <Button size="medium" variant="contained"
                                              onClick={() => { setShowMap(true) }}
                                              sx={{
                                                marginLeft: '5px',
                                                textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                                                '&:hover': {
                                                  backgroundColor: '#025196',
                                                  color: '#FFFFFF'
                                                }
                                              }}>View gps</Button> */}
                                          </>
                                    }
                                  </Box>
                                  <Box sx={{ marginBottom: '0px' }}>
                                    <span>
                                      {/* {isAnalysisrequestsStatusFail === '' ? '' : isAnalysisrequestsStatusFail ? <Error errorMessage={analysisRequestsStatusMessage} /> : ''} */}
                                    </span>
                                  </Box>
                                </div>
                              </Grid>
                            </Grid>
                      }
                    </div>
                  </Box>
                </div>
              </div>
            </Grid >
          </Grid >
          : <>{initialScreen}</>}
    </>

  )
}
const mapStateToProps = (state, ownProps) => {
  const { proid } = ownProps;
  return {
    payment: state.payment,
    store: state.firestore.data.Store,
    companyPricingData: state.firestore.data.CompanyPricingData,
    // analysisRequests: state.firestore.ordered.AnalysisRequests,
    charges: state.firestore.data.Charges,
    chargesV3: state.firestore.data.ChargesV3,
    company: state.firestore.data.Company,
    project: state.project,
    userData: state.firebase.profile,
    drProjectData: get(
      state.firestore.data,
      `DrProject.${proid}`
    ),
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    clearUpdateProjectState: () => dispatch(clearUpdateProjectState()),
    transferCreditsToProjectJar: (proid) => dispatch(transferCreditsToProjectJar(proid)),
    videoEstimationCost: (proid) => dispatch(videoEstimationCost(proid)),
    removeTransferCreditsToProjectJarErrorAndMessage: () => dispatch(removeTransferCreditsToProjectJarErrorAndMessage()),
    removeVideoEstimationErrorAndMessage: () => dispatch(removeVideoEstimationErrorAndMessage()),
    removeInferenceErrorAndMessage: () => dispatch(removeInferenceErrorAndMessage()),
    makeInferenceRequest: (proid) => dispatch(makeInferenceRequest(proid)),
    makeAdvanceInferenceRequest: (proid) => dispatch(makeAdvanceInferenceRequest(proid)),
    makeHaltProcessingRequestOnProject: (proid) => dispatch(makeHaltProcessingRequestOnProject(proid)),
    makeVisualizerInferenceRequest: (proid) => dispatch(makeVisualizerInferenceRequest(proid)),
    setupProjectBack: (proid) => dispatch(setupProjectBack(proid)),
    updateProcessingTime: (proid, processingTime) => dispatch(updateProcessingTime(proid, processingTime)),
    makeAdvanceInferenceRequestWithTransferCredit: (proid) => dispatch(makeAdvanceInferenceRequestWithTransferCredit(proid)),

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RunInferenceStep);
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Toolbar, Container, TextField, Select, MenuItem, Button, FormControl, InputLabel, TextareaAutosize, Grid, Card, Typography, CardContent, CardHeader, Avatar, IconButton, CircularProgress } from '@mui/material';
import { makeStyles } from "@mui/styles"
import { customerSupport, removeCustomerSupportErrorAndMessage } from '../../store/actions/authActions';
import Error from '../cidgets/Error';
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    header: {
        backgroundColor: 'rgba(0,0,0,0.03)',
        color: 'black',
        borderBottom: `1px solid transparent`
    },
    cardContent: {
        paddingTop: theme.spacing(2)
    }
}));

const ContactSupport = (props) => {
    const location = useLocation();
    const classes = useStyles();
    const { appBarHeight, isSignedIn, appBarHeightSignedOut, auth } = props;
    const { customerSupportMessage, customerSupportIsDoneAndSent, customerSupportLoader } = auth;
    const [name, setName] = useState();
    const [projectID, setProjectID] = useState(location.state === null ? '' : location.state.proid);
    const [email, setEmail] = useState('');
    const [errorType, setErrorType] = useState(location.state === null ? '' : location.state.isProject ? '1' : '');
    const [message, setMessage] = useState('');

    useEffect(() => {
        props.removeCustomerSupportErrorAndMessage();
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        props.removeCustomerSupportErrorAndMessage();
        props.customerSupport(errorType, name, email, projectID, message);
    };

    return (
        <>
            {isSignedIn ? <Toolbar /> : <></>}
            <Grid container justifyContent="center" alignItems="center" sx={{ height: isSignedIn ? `calc(100vh - ${appBarHeight}px)` : `calc(100vh - ${appBarHeightSignedOut}px)` }}>
                <Grid item xs={10} sm={8} md={6} lg={4}>
                    <Card>
                        <CardHeader
                            className={classes.header}
                            sx={{ backgroundColor: 'rgba(0,0,0,0.03)' }}
                            title="Reason for contacting"
                            titleTypographyProps={{ color: 'black', fontWeight: 'bold', fontSize: '18px', display: 'flex', justifyContent: 'center' }}
                        />
                        <CardContent className={classes.cardContent}>
                            <form onSubmit={handleSubmit}>
                                <FormControl fullWidth required>
                                    <label>Reason to contact:</label>
                                    <Select sx={{ fontSize: '15px' }} value={errorType} onChange={(e) => setErrorType(e.target.value)}>
                                        <MenuItem value="0">Account</MenuItem>
                                        <MenuItem sx={{ display: isSignedIn ? 'flex' : 'none' }} value="1">Project</MenuItem>
                                        <MenuItem sx={{ display: isSignedIn ? 'flex' : 'none' }} value="2">Report</MenuItem>
                                        <MenuItem value="999">General</MenuItem>
                                    </Select>
                                </FormControl>
                                {isSignedIn && (errorType === '1' || errorType === '2') &&
                                    <>
                                        <label style={{ marginTop: '5px' }}>Project ID:</label>
                                        <TextField
                                            sx={{ input: { fontSize: '15px' } }}
                                            value={projectID}
                                            onChange={(e) => setProjectID(e.target.value)}
                                            fullWidth
                                            required
                                        />
                                    </>
                                }
                                {!isSignedIn &&
                                    <>
                                        <label style={{ marginTop: '5px' }}>Full name:</label>
                                        <TextField
                                            sx={{ input: { fontSize: '15px' } }}
                                            label=""
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            fullWidth
                                            required
                                        />
                                        <label style={{ marginTop: '5px' }}>Email:</label>
                                        <TextField
                                            sx={{ input: { fontSize: '15px' } }}
                                            label=""
                                            type="email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            fullWidth
                                            required
                                        />
                                    </>
                                }
                                <label style={{ marginTop: '5px' }}>Message:</label>
                                <TextareaAutosize
                                    placeholder=""
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    minRows={5}
                                    maxLength={500}
                                    fullWidth
                                    required
                                    style={{ width: '100%', overflow: 'auto', fontSize: '15px' }}
                                />
                                {customerSupportLoader ?
                                    <>
                                        <Button variant="contained" disabled sx={{
                                            textTransform: 'initial',
                                            backgroundColor: '#025196',
                                            color: '#ffffff',
                                            "&.Mui-disabled": {
                                                backgroundColor: '#025196',
                                                color: '#ffffff'
                                            }
                                        }}>
                                            <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} /><span style={{ paddingLeft: '15px' }}>Submitting...</span>
                                        </Button>
                                    </>
                                    :
                                    <Button type="submit" variant="contained" sx={{
                                        marginTop: '10px',
                                        textTransform: 'initial',
                                        backgroundColor: '#025196',
                                        color: '#ffffff',
                                        '&:hover': {
                                            backgroundColor: '#025196',
                                            color: '#ffffff'
                                        }
                                    }}>
                                        Submit
                                    </Button>
                                }
                                {customerSupportIsDoneAndSent === true &&
                                    <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>{customerSupportMessage}</div>
                                }
                                {customerSupportIsDoneAndSent === false &&
                                    <div style={{ marginTop: '10px' }}><Error errorMessage={customerSupportMessage} /></div>
                                }
                            </form>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid >
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        customerSupport: (reasonToContact, name, email, proid, message) => dispatch(customerSupport(reasonToContact, name, email, proid, message)),
        removeCustomerSupportErrorAndMessage: () => dispatch(removeCustomerSupportErrorAndMessage()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactSupport);

import { useState } from "react"
import SimpleDialogBox from "../../../../cidgets/SimpleDialogBox";
import { Menu, MenuItem } from "@mui/material";

const DrawBBox = ({ data, handleBboxDelete, dialog = false, xy = [], wh = [], shouldScale = true, pred = false, scale = 1 }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        if (dialog) {
            setAnchorEl(event.currentTarget);
        }
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleOpenDialog = () => {
        if (dialog) {
            setOpenDialog(true);
        }
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    if (xy.length !== 2 || wh.length !== 2) {
        // //////console.log("lkjkjkjk")
        return <></>
    }

    const x1 = xy[0] / (shouldScale ? scale : 1)
    const y1 = xy[1] / (shouldScale ? scale : 1)
    const x2 = (wh[0] + xy[0]) / (shouldScale ? scale : 1)
    const y2 = (wh[1] + xy[1]) / (shouldScale ? scale : 1)
    return <>
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
        >
            <MenuItem onClick={() => { handleBboxDelete(data) }}>Edit</MenuItem>
            <MenuItem onClick={handleClose}>Delete</MenuItem>
        </Menu>
        <SimpleDialogBox
            open={openDialog}
            onClose={handleCloseDialog}
            title="What if you can move it?"
            cancelText="Close"
            desc="Something is cooking... And it is almost ready for you!"
            bodyWidget={
                <>

                </>
            }
            width='600'
        />
        <path d={`M ${x1}, ${y1} ${x1}, ${y2} ${x2}, ${y2} ${x2}, ${y1} z`} className='bbox-path' onClick={handleClick} />
    </>
}

export default DrawBBox;
import React, { useState } from "react";
import { Link } from 'react-router-dom'
import { Box, Card, Chip, Grid, Button, Typography, Container } from "@mui/material";
import releaseNotes from "../../../../constants/releaseNotes.json"
import ModelViewer from "../../../cidgets/3D/ModelViewer";
import ControlCameraOutlinedIcon from '@mui/icons-material/ControlCameraOutlined';


const Greetings = (props) => {
  const [modelHintLayer, setModelHintLayer] = useState(true)
  const { appBarHeightSignedOut } = props;
  const modelStyling = {
    "container": {
      position: "relative",
      width: "100%",
      height: "90%",
      backgroundColor: "rgba(255, 255, 0, 0.0)",
      // backgroundColor: "green",
    },

    "backgroundDiv": {
      position: "absolute",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
      backgroundColor: "#fafafa",
      // backgroundColor: "purple",
      zIndex: 2, /* Set a lower z-index to place it below the overlay */
      display: 'flex',
      justifyContent: 'space-around',
    },

    "overlayDiv": {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "500px",
      height: "500px",
      // mixBlendMode: "exclusion",
      backgroundColor: "rgba(0, 0, 0, 0.0)",
    }
  }
  const cardLightStyle = {
    "border": "1px solid grey",
    "borderRadius": "8px",
    "padding": "8px",
    "backgroundColor": '#f5f1f1'
  }
  const wnpStyle = { marginBlock: 4 }
  const showWnp = releaseNotes.notes.length > 0 && !releaseNotes.forceNoShow
  return (
    <Container maxWidth="xl" >
      <div style={{ paddingBlock: '20px', height: `calc(100vh - ${appBarHeightSignedOut}px)`, backgroundColor: '#FAFAFA', }}>
        <Grid container spacing={0} sx={{ height: `calc(100vh - ${appBarHeightSignedOut}px - 20px)`, backgroundColor: '' }}>
          <Grid item xs={12} sm={12} md={7} lg={6} xl={6} style={{ backgroundColor: '', height: `calc(100vh - ${appBarHeightSignedOut}px - 20px)` }}>
            <Typography className="greeting-main" variant="h2" sx={{ height: '40%', backgroundColor: '', fontWeight: 'bold', fontSize: { xs: 36, sm: 41, md: 49, lg: 49, xl: 56 } }}>
              {releaseNotes.greeting}
            </Typography>
            {!showWnp ? <></> : <Card sx={{ ...cardLightStyle, height: '40%', marginTop: '0px' }} elevation={0}>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                {releaseNotes.title}{releaseNotes.dev ? <Chip size="medium" variant="error" sx={{ marginLeft: '0px', backgroundColor: 'rgba(255, 0,0, 0.2)', color: 'darkred', border: '1px solid red' }} label={releaseNotes.dev ? "Development" : ""} /> : <></>}
              </Typography>
              <p style={{ ...wnpStyle, fontSize: '13px', marginLeft: '0px' }}>Release {releaseNotes.version}</p>
              <div style={{ margin: '0px' }}></div>
              {releaseNotes.notes.map((eachNote, i) => <Typography variant="body2" key={i} sx={{ fontSize: { xs: '12px', sm: '14px', md: '13px', lg: '16px' } }}>* {eachNote}</Typography>)}
            </Card>
            }
            <Box sx={{ height: '20%' }}>
              <Box sx={{ height: '50%', display: 'flex', alignItems: 'center', padding: '0px' }}>
                <Link to='/a/register' style={{ textDecoration: 'none' }}>
                  <Button variant="outlined" style={{ borderColor: '#000000', color: '#000000', textTransform: 'initial', cursor: 'pointer', paddingInline: '30px', fontWeight: 'bold', fontSize: '16px' }}>Register now!</Button>
                </Link>
              </Box>
              <Box sx={{ height: '50%', display: 'flex', alignItems: 'start', padding: '0px' }}>
                <Link to='/a/login' style={{ color: 'black', backgroundColor: '' }}>
                  <Typography variant="body1" sx={{ marginTop: '0px', color: 'black' }}>
                    Or, <span style={{ textDecoration: 'underline' }}>log in</span> to your existing account
                  </Typography>
                </Link>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={0} sm={0} md={5} lg={6} xl={6} style={{ backgroundColor: '', height: `calc(100vh - ${appBarHeightSignedOut}px - 20px)` }}>
            <Card sx={{
              // height: `calc(100vh - ${appBarHeightSignedOut}px - 20px - 20px)`,
              height: '100%',
              display: { xs: "none", sm: "none", md: 'block' }, borderRadius: '50px',
              backgroundColor: 'transparent', padding: '0px', border: '0px solid rgba(0,0,0,0.3)'
            }} elevation={0}>
              <div style={modelStyling.container}>
                <div style={modelStyling.backgroundDiv}>
                  <ModelViewer />
                </div>
                <div style={{ zIndex: modelHintLayer ? 3 : 1, ...modelStyling.overlayDiv, display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'black' }} onMouseOverCapture={() => { setModelHintLayer(false) }} onMouseUpCapture={() => { setModelHintLayer(true) }}>
                  <Card sx={{ width: '55px', height: '55px', padding: '0px', backgroundColor: 'rgba(255,255,255,0.75)', borderRadius: 50 }} elevation={10}>
                    <ControlCameraOutlinedIcon sx={{ fontSize: '25px' }} />
                  </Card>
                </div>
              </div>
              <Typography sx={{ height: '10%', fontSize: '14px', color: 'grey', marginTop: '0px' }} style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                Interactive reconstruction from motion by {releaseNotes.name}'s 3D Engine
              </Typography>
              {/* <img src={GreetingImage} width="500px" height="auto" alt=" no image" /> */}
            </Card>
          </Grid>
        </Grid>
      </div >
    </Container>
  );
};
export default Greetings;
import {
  Button,
  Card,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { grey } from "@mui/material/colors";
import HomeIcon from "@mui/icons-material/Home";
import PlaceIcon from "@mui/icons-material/Place";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import { alpha, styled } from "@mui/material/styles";
import { connect } from "react-redux";
import {
  clearCreateSuperSectionErrorAndMsg,
  createSuperSection,
} from "../../../../../store/actions/networkActions";
import SimpleDialogBox from "../../../../cidgets/SimpleDialogBox";
import DistanceTimeCalcHelper from "../../../../../helpers/Distance_Time_CalcHelper";

const BlackSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: grey[900],
    "&:hover": {
      backgroundColor: alpha(grey[900], theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: grey[900],
  },
}));
const label = { inputProps: { "aria-label": "Color switch demo" } };

const AddNewSuperSectionOption = (props) => {
  const {
    onClose,
    onSpecificRouteLocation,
    onChangeFields,
    onDelete,
    goToCenterOfUserLocation,
    onClear,
    onAddNewFeild,
    onDrag,
    calculateRoute,
    handleChange,
    handlePaste,
    isValidCoordinate
  } = props;
  const { fields, markerPositions, draggable } = props;

  const [showFeildError, setShowFeildError] = useState(false);
  const [superSectionTitleError, setSuperSectionTitleError] = useState(false);
  const [superSectionTitle, setSuperSectionTitle] = useState("");
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    if (props.network.createSuperSectionIsDoneAndSent === true) {
      onClose();
      props.clearCreateSuperSectionErrorAndMsg();
    }
  }, [props.network.createSuperSectionIsDoneAndSent]);
  const onChange = (id, event) => {
    setShowFeildError(false);
    const data = event.target.value;
    const isDataCorrect = `${data}`.includes(",");
    if (isDataCorrect) {
      const dataList = `${data}`.split(",");
      const dataObject = {
        lat: dataList[0],
        lng: dataList[1],
      };
      const newFields = fields.map((field) => {
        if (field.id === id) {
          return { ...field, value: dataObject };
        }
        return field;
      });
      onChangeFields(newFields);
    } else {
      setShowFeildError(true);
    }
  };

   

  const createSuperSection = () => {
    props.clearCreateSuperSectionErrorAndMsg();
    setSuperSectionTitleError(false);
    if (superSectionTitle.length <= 0) {
      setSuperSectionTitleError(true);
    } else {
      props.createSuperSection(
        JSON.stringify(props.project.routeCalculationData),
        superSectionTitle
      );
    }
  };
  const onDialogOpen = () => {
    props.clearCreateSuperSectionErrorAndMsg();
    setOpenDialog(true);
  };
  const onDialogClose = () => {
    setSuperSectionTitle("");
    setSuperSectionTitleError(false);
    setOpenDialog(false);
  };
  return (
    <>
      <SimpleDialogBox
        open={openDialog}
        onClose={onDialogClose}
        title="Supersection title"
        cancelText="Close"
        showError={
          props.network.createSuperSectionIsDoneAndSent === false ? true : false
        }
        errorMessage={
          props.network.createSuperSectionIsDoneAndSent === false
            ? props.network.createSuperSectionMessage
            : ""
        }
        bodyWidget={
          <>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Title"
                  id="fullWidth"
                  value={superSectionTitle}
                  disabled={props.network.createSuperSectionLoader}
                  error={superSectionTitleError}
                  helperText={superSectionTitleError ? "Invalid title" : ""}
                  onChange={(e) => {
                    setSuperSectionTitle(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
          </>
        }
        width={700}
        showPositive
        positiveButtonWidget={
          props.network.createSuperSectionLoader ? (
            <Button
              variant="contained"
              disabled
              size="small"
              sx={{
                marginLeft: "20px",
                textTransform: "initial",
                backgroundColor: "#025196",
                color: "#ffffff",
                "&.Mui-disabled": {
                  backgroundColor: "#025196",
                  color: "#ffffff",
                },
              }}
            >
              <CircularProgress
                variant="indeterminate"
                size={15}
                sx={{ color: "white" }}
              />
              <span style={{ paddingLeft: "15px" }}>Saving...</span>
            </Button>
          ) : (
            <Button
              variant="contained"
              
              sx={{
                textTransform: "initial",
                color: "#ffffff",
                backgroundColor: "#025196",
                ":hover": {
                  backgroundColor: "#025196",
                  color: "#ffffff",
                },
              }}
              onClick={createSuperSection}
            >
              Save
            </Button>
          )
        }
      />
      <Card sx={{ height: "calc(100vh - 0px)"}}>
        <Grid container spacing={0} sx={{ height: "8%", background: "" }}>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "end",
              paddingLeft: "10px",
            }}
          >
            <h6 style={{ fontWeight: "bold", fontSize: "17px" }}>Parameters</h6>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "end",
              paddingRight: "10px",
            }}
          >
            <Tooltip title="Back to saved supersections" arrow placement="top">
              <IconButton aria-label="saved" onClick={onClose}>
                <HomeIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Divider light sx={{ width: "100%" }} />
        </Grid>

        <div
          style={{
            height: "8%",
            marginTop: "0px",
            marginBottom: "0px",
            background: "",
            paddingLeft: "10px",
          }}
        >
          <Typography variant="body1">Coordinates</Typography>
          <Typography
            variant="body1"
            style={{ color: "grey", marginTop: "5px" }}
          >
            Up to 25 waypoints (i.e longitude,latitude pairs).
          </Typography>
        </div>
        <div
          style={{
            height: Object.values(fields).length > 10 ? "55%" : "auto",
            background: "",
            overflowX: "hidden",
          }}
        >
          {fields.map((field) => 
         {
            const isFilled = field.value.lat !== "" && field.value.lng !== "";
            const lngError =  field.value.lng && !isValidCoordinate(0, field.value.lng)
            return (
            <Grid
              container
              spacing={0}
              sx={{
                padding: `10px ${
                  field.value.lat !== "" && field.value.lng !== ""
                    ? "10px"
                    : "20px"
                } 0px 10px`,
              }}
            >
              <Grid
                item
                xs={1}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >
                {field.value.lat !== "" && field.value.lng !== "" ? (
                  <Tooltip title={"Navigate to this location"} placement="top">
                    <PlaceIcon
                      fontSize="medium"
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        onSpecificRouteLocation(
                          field.value.lat,
                          field.value.lng
                        );
                      }}
                    />
                  </Tooltip>
                ) : (
                  <EditIcon fontSize="medium" />
                )}
              </Grid>
              <Grid
                item
                xs={field.value.lat !== "" && field.value.lng !== "" ? 10 : 11}
                sx={{
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "end",
                }}
              >
                <TextField
                  label="Latitude"
                  variant="outlined"
                  fullWidth
                  disabled={isFilled}
                  value={field.value.lat}
                  // onChange={(e) =>
                  //   handleChange(field.id, "lat", e.target.value)
                  // }
                  onPaste={(e) => handlePaste(e, field.id)}
                  error={
                    field.value.lat && !isValidCoordinate(field.value.lat, 0)
                  }
                  helperText={
                    field.value.lat && !isValidCoordinate(field.value.lat, 0)
                      ? "Invalid Latitude (-90 to 90)"
                      : ""
                  }
                />
                 <TextField
              label="Longitude"
              variant="outlined"
              fullWidth
              value={field.value.lng}
              // onChange={(e) => handleChange(field.id, "lng", e.target.value)}
              onPaste={(e) => handlePaste(e, field.id)}
              error={lngError}
              helperText={
                lngError
                  ? "Invalid Longitude (-180 to 180)"
                  : ""
              }
              disabled={isFilled}
              sx={{ ml: 1 }}
            />
                {/* <TextField
                                    error={showFeildError}
                                    size='small'
                                    id="text-field"
                                    disabled
                                    type='input'
                                    value={Object.values(field.value).length === 2 ? field.value.lat !== '' && field.value.lng !== '' ? `${Number(field.value.lat).toFixed(6)}, ${Number(field.value.lng).toFixed(6)}` : '' : ''}
                                    onChange={(e) => onChange(field.id, e)}
                                    sx={{
                                        backgroundColor: grey[100], marginBottom: '3px', width: '100%', '& .MuiInputBase-input': {
                                            padding: '4.5px 14px'
                                        },
                                    }}
                                /> */}
              </Grid>
              <Grid
                item
                xs={field.value.lat !== "" && field.value.lng !== "" ? 1 : 0}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >
                {field.value.lat !== "" && field.value.lng !== "" ? (
                  <IconButton
                    aria-label="remove marker"
                    onClick={() => onDelete(field.id)}
                    sx={{
                      color: "black",
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                  >
                    <CloseIcon fontSize="medium" />
                  </IconButton>
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
          )}
          )}
        </div>
        <div
          style={{
            marginTop: "0px",
            height: "5%",
            background: "",
            padding: "10px",
          }}
        >
          <Grid container spacing={0}>
            <Grid
              item
              xs={1}
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              <Tooltip title={"Current location"} placement="bottom">
                <MyLocationIcon
                  fontSize="small"
                  sx={{ cursor: "pointer" }}
                  onClick={goToCenterOfUserLocation}
                />
              </Tooltip>
            </Grid>
            <Grid item xs={5}>
              <Chip
                // disabled={
                //   Object.values(markerPositions).length < 2 ||
                //   Object.values(fields).length === 25 ||
                //   Object.values(fields).length >
                //     Object.values(markerPositions).length
                //     ? true
                //     : false
                // }
                label="Add a coordinate"
                variant="outlined"
                onClick={onAddNewFeild}
              />
            </Grid>

            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <Typography
                variant="body1"
                onClick={onClear}
                sx={{
                  cursor: "pointer",
                  textDecoration: "none",
                  paddingRight: "10px",
                }}
              >
                Clear coordinates
              </Typography>
            </Grid>
          </Grid>
        </div>
        <div
          style={{
            marginTop: "0px",
            height: "5%",
            background: "",
            padding: "10px",
          }}
        >
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                paddingLeft: "10px",
              }}
            >
              <FormControl component="fieldset">
                <FormGroup
                  aria-label="position"
                  row
                  style={{ marginTop: "10px" }}
                >
                  <FormControlLabel
                    value=""
                    control={
                      <BlackSwitch
                        {...label}
                        size="small"
                        sx={{ color: "black" }}
                        checked={draggable}
                        onChange={onDrag}
                      />
                    }
                    label="Switch to drag markers"
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>
        </div>
        <div
          style={{
            marginTop: "0px",
            height: "5%",
            background: "",
            padding: "10px",
          }}
        >
          <Grid container spacing={0}>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                paddingLeft: "10px",
              }}
            >
              <>
                {Object.values(markerPositions).length < 2 ? (
                  <></>
                ) : props.project.routeCalculationLoader ? (
                  <Button
                    variant="contained"
                    disabled
                    size="small"
                    sx={{
                      textTransform: "none",
                      // paddingInline: '18px',
                      // marginInline: '10px',
                      display: { xs: "none", sm: "none", md: "flex" },
                      color: "#ffffff",
                      backgroundColor: "#025196",
                      ":hover": {
                        backgroundColor: "#025196",
                        color: "#ffffff",
                      },
                      "&.Mui-disabled": {
                        backgroundColor: "#025196",
                        color: "#ffffff",
                      },
                      marginTop: "15px",
                    }}
                  >
                    <CircularProgress
                      variant="indeterminate"
                      size={15}
                      sx={{ color: "white" }}
                    />
                    <span style={{ paddingLeft: "15px" }}>Evaluating...</span>
                  </Button>
                ) : (
                  <Button
                    size="small"
                    variant="contained"
                    sx={{
                      textTransform: "none",
                      // paddingInline: '18px',
                      // marginInline: '10px',
                      display: { xs: "none", sm: "none", md: "flex" },
                      color: "#ffffff",
                      backgroundColor: "#025196",
                      ":hover": {
                        backgroundColor: "#025196",
                        color: "#ffffff",
                      },
                      "&.Mui-disabled": {
                        backgroundColor: "#025196",
                        color: "#ffffff",
                      },
                      marginTop: "15px",
                    }}
                    disabled={Object.values(markerPositions).length < 2}
                    onClick={calculateRoute}
                  >
                    Evaluate route
                  </Button>
                )}

                {props.project.routeCalculationData === null ? (
                  <></>
                ) : (
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      textTransform: "none",
                      // paddingInline: '18px',
                      // marginInline: '10px',
                      display: { xs: "none", sm: "none", md: "flex" },
                      color: "#ffffff",
                      backgroundColor: "#025196",
                      ":hover": {
                        backgroundColor: "#025196",
                        color: "#ffffff",
                      },
                      "&.Mui-disabled": {
                        backgroundColor: "#025196",
                        color: "#ffffff",
                      },
                      marginTop: "15px",
                      marginLeft: "10px",
                    }}
                    onClick={onDialogOpen}
                  >
                    Save route
                  </Button>
                )}
              </>
            </Grid>
          </Grid>
        </div>
        <div
          style={{
            marginTop: "0px",
            height: props.project.routeCalculationData !== null ? "10%" : "0%",
            display: "flex",
            alignItems: "center",
            padding: "20px 0px 0px 20px",
          }}
        >
          {props.project.routeCalculationData === null ? (
            <></>
          ) : (
            <>
              <Typography variant="body1">
                Route distance:{" "}
                {DistanceTimeCalcHelper.calculateDistance(
                  props.project.routeCalculationData.distance
                )}
              </Typography>
            </>
          )}
        </div>
      </Card>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    project: state.project,
    network: state.network,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    createSuperSection: (routePlannerData, superSectionTitle) =>
      dispatch(createSuperSection(routePlannerData, superSectionTitle)),
    clearCreateSuperSectionErrorAndMsg: () =>
      dispatch(clearCreateSuperSectionErrorAndMsg()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddNewSuperSectionOption);

import { LayerGroup, LayersControl, MapContainer, Marker, Polyline, Popup, TileLayer, useMap, Tooltip, Circle } from 'react-leaflet'
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

import marker from '../../../../../assets/pin_sm.svg';
import React from 'react';

const PinOnMap = (props) => {
    const { pinned, geoKey = 'geo', isAddingAsset, gpsObj, isViewingAsset, gps } = props;
    const iconSize = [26 / 1, 38 / 1];
    const DrIcon = new L.Icon({
        iconUrl: marker,
        iconRetinaUrl: marker,
        iconAnchor: [iconSize[0] / 2, iconSize[1]],
        popupAnchor: [-0, -0],
        iconSize: iconSize,
    });

    let DefaultIcon = L.icon({
        iconUrl: icon,
        shadowUrl: iconShadow
    });
    L.Marker.prototype.options.icon = DrIcon;
    return (
        <div style={{ height: '100%' }}>
            <MapContainer attributionControl={false} center={isViewingAsset ? [gps[0], gps[1]] : isAddingAsset ? [gpsObj.lat, gpsObj.lng] : pinned.pred[geoKey]} zoom={16} scrollWheelZoom={true} maxZoom={18}>
                <TileLayer
                    attribution=''
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker key={isViewingAsset ? gps : isAddingAsset ? gpsObj : pinned.id} position={isViewingAsset ? [gps[0], gps[1]] : isAddingAsset ? [gpsObj.lat, gpsObj.lng] : pinned.pred[geoKey]}>
                    {isAddingAsset ? <Tooltip>{new Date(gpsObj.time * 1000).toISOString().slice(11, 19)}</Tooltip> : <></>}
                </Marker>
            </MapContainer>
        </div>
    )
}
export default PinOnMap;

import { Grid, Card, Button, CircularProgress } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import SurfaceEvaluationTopbar from "./surfaceEvaluationComponents/SurfaceEvaluationTopbar";
import SurfaceEvaluationVideoPlayer from "./subpages/afterInference/SurfaceEvaluationVideoPlayer";
import SmartUtils from "../../../../helpers/SmartUtils";
import CustomProgress from "../../../cidgets/CustomProgress";
import SegmentShifterV2 from "./surfaceEvaluationComponents/SegmentShifterV2";
import DistressMap from "./surfaceEvaluationComponents/DistressMap";
import {
  advancedAnalysisSetting,
  analysisSetting,
  depthCrossSectionalProfile,
  editorAddDistress,
  editorUpdateDistress,
  getDownSamplingData,
  moveDistressMultipleToArchive,
  moveDistressToArchive,
  saveDepthCrossSectionalPoints,
  setDefaultFilters,
} from "../../../../store/actions/projectActions";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CustomizedDividers from "./subpages/potholeInspection/CustomizedDividers";
import EditOptions from "./subpages/potholeInspection/EditOptions";
import DistressEditorPlayer from "./surfaceEvaluationComponents/DistressEditorPlayer";
import SimpleDialogBox from "../../../cidgets/SimpleDialogBox";
import CustomAlert from "../../../cidgets/CustomAlert";
import { cloudFunction } from "../../../../config/firebaseConfig";

const RATE = 0.1;
const AfterSurfaceEvaluationInferenceDashboardV3 = (props) => {
  const { secId } = useParams();
  let navigate = useNavigate();
  const {
    proid,
    drProjectData,
    distressProjectPredictions,
    link,
    datastore,
    project,
  } = props;
  const {
    distressArchiveMessage,
    distressArchiveIsDoneAndSent,
    showDistressArchiveLoader,
    updateDistressArchiveMessage,
    updateDistressArchiveIsDoneAndSent,
    addDistressArchiveMessage,
    addDistressArchiveIsDoneAndSent,
  } = project;
  const { distressData } = datastore;
  const { dataInfoV2 } = distressData;
  const { defectName } = dataInfoV2;
  const { dimensions } = drProjectData;
  const { height, width } = dimensions;
  const [sections, setSections] = React.useState(0);
  const [isWholeDistressVisualization, setIsWholeDistressVisualization] =
    useState(false);
  const handleDataVisualizationChange = (event) => {
    setIsWholeDistressVisualization(event.target.checked);
  };

  const [isDepthCrossSectional, setIsDepthCrossSectional] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [segmentIndex, setSegmentIndex] = useState(0);
  const [pinned, setPinned] = useState(null);
  const defaultCrossSectionalPoints = [
    pinned?.LRWidth * 0.5,
    pinned?.LRHeight * 0.5,
    pinned?.LRWidth * 0.8,
    pinned?.LRHeight * 0.8,
  ];
  const [crossSectionalPoints, setCrossSectionalPoints] = useState(defaultCrossSectionalPoints);
  const [sectionLength, setSectionLength] = useState(10);
  const [vidDivDimen, setVidDivDimen] = useState({ height: 1, width: 1 });
  const su = new SmartUtils();
  const overallCustomBorderPadding = 16;
  const cardWhiteStyle = {
    borderRadius: "15px",
  };
  const [distressPinned, setDistressPinned] = useState(null);
  const realFps =
    "fps" in drProjectData ? drProjectData.fps : 59.94005994005994;
  const FPS = Number(realFps).toFixed(0);
  const originalVideoHeight = drProjectData?.dimensions?.height ?? 0;
  const originalVideoWidth = drProjectData?.dimensions?.width ?? 0;
  const headerRef = useRef();
  const headerHeight = headerRef?.current?.clientHeight ?? 0;
  const videoSectionHeight = 50;
  const mapSectionHeight = 50;
  const videoRef = useRef();
  const playerRef = useRef(null);

  useEffect(() => {
    const fetchDefaultDistressFilter = async () => {
      await cloudFunction
        .httpsCallable("filtering-getDefaultFiltersOnCall")({
          projectType: drProjectData.projectType,
        })
        .then((res) => {
          props.setDefaultFilters(proid);
          const defaultFilters = res.data.defaultFilters;
          const advancedFilters = drProjectData.advancedFilters
            ? drProjectData.advancedFilters
            : {};
          const appliedFiltersInitial = {
            ...defaultFilters,
            ...advancedFilters,
          };
          setAppliedFilters(appliedFiltersInitial);
        })
        .catch((error) => {
          props.setDefaultFilters(proid);
        });
    };
    if (!drProjectData.isDefaultFilter) {
      fetchDefaultDistressFilter();
    }
  }, [drProjectData.isDefaultFilter, proid]);
  const defaultFilters = drProjectData.defaultFilters
    ? drProjectData.defaultFilters
    : {};
  const advancedFilters = drProjectData.advancedFilters
    ? drProjectData.advancedFilters
    : {};
  const appliedFiltersInitial = {
    ...defaultFilters,
    ...advancedFilters,
  };
  //////////////////// Fast edit ////////////////////////////////////////////////

  const comparePlayerRef = useRef(null);
  const vidDivRef = useRef(null);

  const [appliedFilters, setAppliedFilters] = useState(appliedFiltersInitial);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [rectangles, setRectangles] = useState([]);
  const [editorMode, setEditorMode] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteBboxList, setDeleteBboxList] = useState([]);
  const [deleteMultipleBbox, setDeleteMultipleBbox] = useState(false);
  const [showPointer, setShowPointer] = useState(false);
  const [isAddingDistress, setIsAddingDistress] = useState(false);
  const [inputFrame, setInputFrame] = useState(0);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [filteredFrameData, setFilteredFrameData] = useState([]);
  const [selectedRecId, setSelectedRecId] = useState(null);
  const [openDistressDialog, setOpenDistressDialog] = useState(false);
  const [svgDimensions, setSvgDimensions] = useState({
    height: 1,
    width: 1,
    scale: 1,
    offsetTop: 0,
    offsetLeft: 0,
  });
  const [fieldValues, setFieldValues] = useState({
    sectionId: "",
    gpsLat: "",
    gpsLng: "",
    defectId: "",
    basicImagingClassState: "",
    frameNumberState: "",
    classification: "",
  });
  const [imageDimensions, setImageDimensions] = useState({
    height: 1,
    width: 1,
  });
  const [stitchCurrentTime, setStitchCurrentTime] = useState(0);
  const [openArchiveAlert, setOpenArchiveAlert] = useState(false);
  const [openEditAlert, setOpenEditAlert] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const totalFrames = drProjectData.stitchFrame
    ? drProjectData.stitchFrame-1
    : drProjectData.frames;
  const stitchVideoHeight = drProjectData.stitchHeight ?? 0;
  const stitchlVideoWidth = drProjectData.stitchWidth ?? 0;
  const stitchFps = drProjectData.stitchFps ? drProjectData.stitchFps : FPS;
  const wholeProjectDetections = Object.values(distressProjectPredictions).sort(
    (a, b) => a.endIndex - b.endIndex
  );
  const [selectedDefects, setSelectedDefects] = useState(
    drProjectData.selectedDefects ? drProjectData.selectedDefects : []
  );

  const handleClearSelection = () => {
    setRectangles((prevRectangles) => {
      const newRectangles = [...deleteBboxList, ...prevRectangles];
      return newRectangles;
    });
    setDeleteBboxList([]);
    setDeleteMultipleBbox(false);
    setDeleteDialog(false);
  };
  const handleFastDistress = () => {
    setEditorMode(true);
    setIsEditMode(false);
    setSelectedId(null);
    setRectangles([]);
  };
  const handleOpenDeleteDialog = () => {
    setDeleteDialog(true);
  };
  const handleCloseDeleteDialog = () => {
    setDeleteDialog(false);
  };
  const closeBoundingBox = () => {
    setShowPointer(false);
    setIsAddingDistress(false);
  };
  const handleSelectDelete = () => {
    setDeleteMultipleBbox(true);
    closeBoundingBox();
  };
  const handleDeselectDelete = () => {
    setDeleteMultipleBbox(false);
  };
  const addNewBoundingBox = () => {
    setShowPointer(true);
    setIsAddingDistress(true);
  };
  const handleEditorNextFrame = () => {
    const secondPerFrame = 1 / stitchFps;
    setInputFrame((prevFrame) => prevFrame + secondPerFrame);
    comparePlayerRef.current.forward(secondPerFrame);
  };
  const handleEditorPrevFrame = () => {
    const secondPerFrame = 1 / stitchFps;
    setInputFrame((prevFrame) => prevFrame - secondPerFrame);
    comparePlayerRef.current.replay(secondPerFrame);
  };
  const handleInputFrame = (e) => {
    const newFrame = parseInt(e.target.value);
    if (newFrame < 0) {
      setInputFrame(0);
      comparePlayerRef.current.seek(0);
    } else if (newFrame > totalFrames) {
      // alert(`Frame can be between 0 and ${totalFrames}`)
      setInputFrame(totalFrames / stitchFps);
      comparePlayerRef?.current?.seek(totalFrames / stitchFps);
    } else {
      setInputFrame(newFrame / stitchFps);
      comparePlayerRef?.current?.seek(newFrame / stitchFps);
    }
  };
  const handleDelete = () => {
    const updatedRectangles = rectangles.filter(
      (rect) => rect.id !== selectedRecId
    );
    const data = filteredFrameData.filter(
      (data) => data.defectId === selectedRecId
    );
    if (data.length > 0) {
      props.moveDistressToArchive(proid, data[0].sectionId, data[0].defectId);
    }
    setRectangles(updatedRectangles);
    setSelectedRecId(null);
    setSelectedId(null);
    setOpenDistressDialog(false);
    setIsEditMode(false);
    if (isAddingDistress) {
      setShowPointer(true);
    }
  };
  const handleChange = (prop) => (event) => {
    setFieldValues({ ...fieldValues, [prop]: event.target.value });
  };
  const applyFiltersToSections = (sections, filters) => {
    return sections.map((section) => {
      const filteredDetections = Object.values(section.detections).filter(
        (defect) => {
          // Bypass filtering if defect has `userByAdded`
          if (defect.addedByUser) return true;

          const defectFilters =
            filters[defect?.versioning?.defectName ?? defect.defectName]; // Get filters for the defect type
          if (!defectFilters) return true; // Include if no filters defined for this type
          return defectFilters.every(
            ({ selectedProperty, crackType, operator, value }) => {
              // Check crackType if provided
              if (crackType !== undefined) {
                const defectType = defect.versioning
                  ? Number(defect.versioning.type)
                  : Number(defect.type);
                if (defectType !== Number(crackType)) return true; // Exclude if crackType doesn't match
              }
              const adjustedValue =
                selectedProperty === "depth" ? value / 1000 : value;
              // Apply the filter condition
              switch (operator) {
                case ">":
                  return defect[selectedProperty] > adjustedValue;
                case "<":
                  return defect[selectedProperty] < adjustedValue;
                case "=":
                  return defect[selectedProperty] == adjustedValue; // Loose equality for value comparison
                case ">=":
                  return defect[selectedProperty] >= adjustedValue;
                case "<=":
                  return defect[selectedProperty] <= adjustedValue;
                default:
                  return true; // Skip unrecognized operators
              }
            }
          );
        }
      );

      // Include section with filtered detections or reset to empty object if none match
      // const filteredDetections=Object.values(section.detections)
      return {
        ...section,
        detections:
          filteredDetections.length > 0
            ? filteredDetections.reduce((acc, defect, index) => {
                acc[index] = defect;
                return acc;
              }, {})
            : {},
      };
    });
  };
  const onStitchCurrentTimeChange = (currentTime) => {
    setStitchCurrentTime(Number(currentTime));
  };
  const handleDeleteBbox = (data) => {
    setRectangles((prevRectangles) =>
      prevRectangles.filter((rect) => rect.id !== data.id)
    );
    const deleteDetectionData = filteredFrameData.filter(
      (eachDetection) => eachDetection.defectId === data.id
    );
    if (deleteDetectionData.length > 0) {
      setDeleteBboxList((prevDeleteBboxList) => {
        const newList = [
          {
            ...data,
            detectionId:
              drProjectData.projectType === 5
                ? deleteDetectionData[0]["id"]
                : deleteDetectionData[0]["defectId"],
            sectionId: deleteDetectionData[0]["sectionId"],
          },
          ...prevDeleteBboxList,
        ];
        return newList;
      });
    } else {
      alert(`Couldn't find the data for deletion`);
    }
  };
  const handleSelectedDefectData = (data) => {
    if (selectedDefects.length > 0) {
      const finalFilteredData = data.map((section) => {
        const filteredDetections = Object.values(section.detections).filter(
          (value) =>
            selectedDefects.includes(
              value?.versioning?.defectName ?? value.defectName
            )
        );
        return {
          ...section,
          detections: filteredDetections,
        };
      });
      if (Object.values(appliedFilters).length > 0) {
        const getFilteredAppliedData = applyFiltersToSections(
          finalFilteredData,
          appliedFilters
        );
        return getFilteredAppliedData;
      }
      return finalFilteredData;
    } else if (Object.values(appliedFilters).length > 0) {
      const getFilteredAppliedData = applyFiltersToSections(
        data,
        appliedFilters
      );
      return getFilteredAppliedData;
    }
    return data;
  };

  useEffect(() => {
    const time = Math.floor(stitchFps * stitchCurrentTime);
    const filteredList = wholeProjectDetections[time];
    const appliedFilteredList =
      filteredList && handleSelectedDefectData([filteredList]);
    if (
      appliedFilteredList &&
      Object.values(appliedFilteredList[0].detections).length > 0
    ) {
      const sectionId = appliedFilteredList[0].sectionId;
      const detectionsList = Object.values(
        appliedFilteredList[0].detections
      ).map((detection) => ({
        ...detection,
        ...detection?.versioning,
        sectionId,
      }));
      setFilteredFrameData(detectionsList);
    } else {
      setFilteredFrameData([]);
    }
  }, [
    stitchCurrentTime,
    appliedFilters,
    distressProjectPredictions,
    selectedDefects,
  ]);
  // useEffect(() => {
  //     const time = Math.floor(stitchFps * stitchCurrentTime);
  //     const filteredList = wholeProjectDetections[time];
  //     const appliedFilteredList = filteredList && applyFiltersToSections([filteredList], appliedFilters)[0];
  //     if (appliedFilteredList && appliedFilteredList.detections) {
  //         const sectionId = appliedFilteredList.sectionId;
  //         const detectionsList = Object.values(appliedFilteredList.detections).map(detection => ({
  //             ...detection,
  //             ...detection?.versioning,
  //             sectionId
  //         }));
  //         setFilteredFrameData(detectionsList);
  //     } else {
  //         setFilteredFrameData([]);
  //     }
  // }, [stitchCurrentTime, appliedFilters, distressProjectPredictions]);
  useEffect(() => {
    setImageDimensions({
      height: stitchVideoHeight,
      width: stitchlVideoWidth,
    });
  }, [vidDivDimen]);
  useEffect(() => {
    const divHeight = vidDivRef?.current?.clientHeight;
    const divWidth = vidDivRef?.current?.clientWidth - 0;
    const imageHeight = stitchVideoHeight;
    const imageWidth = stitchlVideoWidth;
    const asDiv = divHeight / divWidth;
    const asImg = imageHeight / imageWidth;
    const ratio = asDiv / asImg;
    let svgWidth = 0;
    let svgHeight = 0;
    if (ratio >= 1) {
      svgWidth = divWidth;
      svgHeight = asImg * divWidth;
    } else {
      svgHeight = divHeight;
      svgWidth = (1 / asImg) * divHeight;
    }
    setSvgDimensions({
      width: svgWidth,
      height: svgHeight,
      scale: imageHeight / svgHeight,
      offsetTop: (divHeight - svgHeight) / 2,
      offsetLeft: (divWidth - svgWidth) / 2,
    });
  }, [imageDimensions]);

  useEffect(() => {
    if (!isNaN(stitchCurrentTime)) {
      const videoTime = Math.floor(stitchCurrentTime * stitchFps);
      if (!isNaN(inputFrame)) {
        if (videoTime !== inputFrame) {
          setInputFrame(videoTime);
        }
      }
    }
  }, [stitchCurrentTime]);
  useEffect(() => {
    if (distressArchiveIsDoneAndSent === true) {
      setOpenArchiveAlert(true);
      setDeleteBboxList([]);
      handleCloseDeleteDialog();
    }
  }, [distressArchiveIsDoneAndSent]);

  useEffect(() => {
    if (updateDistressArchiveIsDoneAndSent === true) {
      setOpenEditAlert(true);
    }
  }, [updateDistressArchiveIsDoneAndSent]);
  useEffect(() => {
    if (addDistressArchiveIsDoneAndSent === true) {
      setOpenAlert(true);
    }
  }, [addDistressArchiveIsDoneAndSent]);
  ///////////////////////////////////////////////////////////////////////

  const getVidWindowSize = () => {
    const newHeight = videoRef?.current?.clientHeight;
    const newWidth = videoRef?.current?.clientWidth;
    setVidDivDimen({
      height: newHeight,
      width: newWidth,
    });
  };
  useEffect(() => {
    window.addEventListener(
      "load",
      setVidDivDimen(
        videoRef?.current === null ? 0 : videoRef?.current?.clientHeight
      )
    );
    window.addEventListener("resize", getVidWindowSize, true);
  }, []);
  useEffect(() => {
    props.getDownSamplingData(proid);
  }, []);
  const handleDepthCrossSectional = () => {
    setIsDepthCrossSectional(true);
  };
  const handleCloseDepthCrossSectional = () => {
    setIsDepthCrossSectional(false);
  };
  const onCurrentTimeChange = (currentTime) => {
    setCurrentTime(Number(currentTime - 1 / FPS).toFixed(5));
  };
  const handlePrevFrame = () => {
    playerRef?.current?.replay(1 / FPS);
  };

  const handleNextFrame = () => {
    playerRef?.current?.forward(1 / FPS);
  };

  const handleSeekTo = (timeSeek) => {
    // console.log(timeSeek, 'check34')
    playerRef?.current?.seek(timeSeek);
  };

  const handlePinned = (data) => {
    //////console.log(data, 'check12')
    const check =
      data === null
        ? false
        : pinned === null
        ? false
        : data.sectionId === pinned.sectionId;
    playerRef.current?.pause();
    if (data) {
      handleSeekTo(data.initIndex / FPS);
    }
    // console.log(check, 'check121')
    // setPinned(check ? null : data)
  };

  // let gpsData = []
  let gpsDataCombined = [];
  let detectionsList = [];
  let predictions = Object.values(distressProjectPredictions);
  // const defectNames = SmartUtils.getDefectNamesList(drProjectData.projectType);

  const [conditionMethod, setConditionMethod] = useState(
    drProjectData.conditionMethod
      ? drProjectData.conditionMethod
      : drProjectData.projectType === 4
      ? drProjectData.conditionIndex
        ? drProjectData.conditionIndex[0]
        : -1
      : drProjectData.conditionIndex
      ? drProjectData.conditionIndex[0]
      : 0
  );
  useEffect(() => {
    props.analysisSetting(selectedDefects, conditionMethod, proid);
  }, [selectedDefects, conditionMethod]);
  // const [filteredGpsData, setFilteredGpsData] = useState(predictions);
  const [filteredGpsData, setFilteredGpsData] = useState(
    Object.values(distressProjectPredictions)
  );
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  useEffect(() => {
    if (selectedDefects.length > 0) {
      handleGpsDataFilterChange({ target: { value: selectedDefects } });
    } else {
      const predictionsArray = Object.values(distressProjectPredictions);
      if (Object.values(appliedFilters).length > 0) {
        const getFilteredAppliedData = applyFiltersToSections(
          predictionsArray,
          appliedFilters
        );
        setFilteredGpsData(getFilteredAppliedData);
        return;
      }
      setFilteredGpsData(predictionsArray);
    }
  }, [distressProjectPredictions, appliedFilters, selectedDefects]);
  useEffect(() => {
    props.advancedAnalysisSetting(appliedFilters, proid);
  }, [appliedFilters]);
  const handleGpsDataFilterChange = (event) => {
    const selectedDefectIds = event.target.value;
    setSelectedDefects(selectedDefectIds);
    // setIsFilterOpen(true);
    const finalFilteredData = Object.values(distressProjectPredictions).map(
      (section) => {
        const filteredDetections = Object.fromEntries(
          Object.entries(section.detections).filter(([key, value]) =>
            selectedDefectIds.includes(
              value?.versioning?.defectName ?? value.defectName
            )
          )
        );
        return {
          ...section,
          detections: filteredDetections,
        };
      }
    );
    if (Object.values(appliedFilters).length > 0) {
      const getFilteredAppliedData = applyFiltersToSections(
        finalFilteredData,
        appliedFilters
      );
      setFilteredGpsData(getFilteredAppliedData);
      return;
    }
    setFilteredGpsData(finalFilteredData);
  };
  const getSelectedDefectNames = () => {
    return Object.values(defectName)
      .filter((defect) => selectedDefects.includes(defect.key))
      .map((defect) => defect.name);
  };

  filteredGpsData.sort((a, b) => {
    return a.endIndex - b.endIndex;
  });
  // //////console.log(predictions, "section0x")
  Object.values(filteredGpsData).forEach((section) => {
    // //////console.log(section, 'sec123')
    detectionsList = [...detectionsList, Object.values(section.detections)];

    Object.values(section.detections).forEach((eachData) => {
      // //////console.log(eachData, 'eachdata12')
      gpsDataCombined = [
        ...gpsDataCombined,
        {
          sectionId: section.sectionId,
          lat: eachData.lat,
          lng: eachData.lng,
          time: Date.now(),
          defectName: eachData.defectName,
          geoTime: (eachData.frameNumber / FPS).toFixed(5),
        },
      ];
    });
  });

  // //////console.log(gpsDataCombined, 'gpsDataCombined')

  const doesGpsDataCombinedExists = gpsDataCombined?.length > 0;
  const startEndLocation = {
    startLat: doesGpsDataCombinedExists ? gpsDataCombined.at(0).lat : 0,
    startLng: doesGpsDataCombinedExists ? gpsDataCombined.at(0).lng : 0,
    endLat: doesGpsDataCombinedExists ? gpsDataCombined.at(-1).lat : 0,
    endLng: doesGpsDataCombinedExists ? gpsDataCombined.at(-1).lng : 0,
  };

  const onSegmentChange = (segmentObj) => {
    playerRef?.current?.pause();
    const startTime = segmentObj.initIndex / FPS;
    playerRef?.current?.seek(startTime + 1 / FPS);
  };

  const handleSectionChange = (event) => {
    const sectionIndex = event.target.value;
    navigate(`/home/pr/${proid}/${sectionIndex}`);
    // //////console.log(sectionIndex, 'sectionIndex12')
    const currentSection = Object.values(filteredGpsData)[sectionIndex];
    const crossSectionalParameters =
      currentSection.crossSectionalParameters ?? false;
    const sectionId = currentSection["sectionId"];

    if (crossSectionalParameters) {
      const points = [
        Number(crossSectionalParameters["x1"]),
        Number(crossSectionalParameters["y1"]),
        Number(crossSectionalParameters["x2"]),
        Number(crossSectionalParameters["y2"]),
      ];
      setCrossSectionalPoints(points);
      props.depthCrossSectionalProfile(
        {
          x1: Number(crossSectionalParameters["x1"]),
          y1: Number(crossSectionalParameters["y1"]),
          x2: Number(crossSectionalParameters["x2"]),
          y2: Number(crossSectionalParameters["y2"]),
        },
        proid,
        sectionId
      );
    } else {
      const defaultPoints = {
        x1: Number(defaultCrossSectionalPoints[0]),
        y1: Number(defaultCrossSectionalPoints[1]),
        x2: Number(defaultCrossSectionalPoints[2]),
        y2: Number(defaultCrossSectionalPoints[3]),
      };
      setCrossSectionalPoints(defaultCrossSectionalPoints);
      props.depthCrossSectionalProfile(defaultPoints, proid, sectionId);
    }

    setSections(sectionIndex);
    setSegmentIndex(sectionIndex);
    handlePinned(Object.values(filteredGpsData)[sectionIndex]);
    onSegmentChange(Object.values(filteredGpsData)[sectionIndex]);
  };
  const handleSectionChangeMap = (sectionIndex) => {
    navigate(`/home/pr/${proid}/${sectionIndex}`);
    const currentSection = Object.values(filteredGpsData)[sectionIndex];
    const crossSectionalParameters =
      currentSection.crossSectionalParameters ?? false;
    const sectionId = currentSection["sectionId"];

    if (crossSectionalParameters) {
      const points = [
        Number(crossSectionalParameters["x1"]),
        Number(crossSectionalParameters["y1"]),
        Number(crossSectionalParameters["x2"]),
        Number(crossSectionalParameters["y2"]),
      ];
      setCrossSectionalPoints(points);
      props.depthCrossSectionalProfile(
        {
          x1: Number(crossSectionalParameters["x1"]),
          y1: Number(crossSectionalParameters["y1"]),
          x2: Number(crossSectionalParameters["x2"]),
          y2: Number(crossSectionalParameters["y2"]),
        },
        proid,
        sectionId
      );
    } else {
      const defaultPoints = {
        x1: Number(defaultCrossSectionalPoints[0]),
        y1: Number(defaultCrossSectionalPoints[1]),
        x2: Number(defaultCrossSectionalPoints[2]),
        y2: Number(defaultCrossSectionalPoints[3]),
      };
      setCrossSectionalPoints(defaultCrossSectionalPoints);
      props.depthCrossSectionalProfile(defaultPoints, proid, sectionId);
    }

    setSections(sectionIndex);
    setSegmentIndex(sectionIndex);
    handlePinned(Object.values(filteredGpsData)[sectionIndex]);
    onSegmentChange(Object.values(filteredGpsData)[sectionIndex]);
  };

  return (
    <>
      <CustomAlert
        open={openAlert}
        onClose={() => {
          setOpenAlert(false);
        }}
        text={addDistressArchiveMessage}
        severity={addDistressArchiveIsDoneAndSent ? "success" : "error"}
      />
      <CustomAlert
        open={openEditAlert}
        onClose={() => {
          setOpenEditAlert(false);
        }}
        text={updateDistressArchiveMessage}
        severity={updateDistressArchiveIsDoneAndSent ? "success" : "error"}
      />
      <CustomAlert
        open={openArchiveAlert}
        onClose={() => {
          setOpenArchiveAlert(false);
        }}
        text={distressArchiveMessage}
        severity={distressArchiveIsDoneAndSent ? "success" : "error"}
      />
      <SimpleDialogBox
        showError={distressArchiveIsDoneAndSent === true ? false : true}
        errorMessage={
          distressArchiveIsDoneAndSent === true ? "" : distressArchiveMessage
        }
        open={deleteDialog}
        onClose={handleCloseDeleteDialog}
        title="Archive distresses"
        bodyWidget={<>Are you sure you want to delete distress?</>}
        showPositive
        width={1000}
        positiveButtonWidget={
          <>
            <Button
              size="medium"
              variant="contained"
              onClick={handleClearSelection}
              sx={{
                whiteSpace: "nowrap",
                marginRight: "8px",
                textTransform: "initial",
                backgroundColor: "#025196",
                color: "#FFFFFF",
                "&:hover": {
                  backgroundColor: "#025196",
                  color: "#FFFFFF",
                },
              }}
            >
              Remove selections
            </Button>
            {showDistressArchiveLoader ? (
              <>
                <Button
                  variant="contained"
                  disabled
                  sx={{
                    textTransform: "initial",
                    backgroundColor: "#025196",
                    color: "#FFFFFF",
                    "&.Mui-disabled": {
                      backgroundColor: "#025196",
                      color: "#FFFFFF",
                    },
                  }}
                >
                  <CircularProgress
                    variant="indeterminate"
                    size={15}
                    sx={{ color: "black" }}
                  />
                  <span style={{ paddingLeft: "15px" }}>Deleting...</span>
                </Button>
              </>
            ) : (
              <Button
                size="medium"
                variant="contained"
                onClick={() =>
                  props.moveDistressMultipleToArchive(proid, deleteBboxList)
                }
                sx={{
                  whiteSpace: "nowrap",
                  textTransform: "initial",
                  backgroundColor: "#025196",
                  color: "#FFFFFF",
                  "&:hover": {
                    backgroundColor: "#025196",
                    color: "#FFFFFF",
                  },
                }}
              >
                Delete
              </Button>
            )}
          </>
        }
      />
      <div
        style={{ height: "100vh", width: "100vw", backgroundColor: "#E1E3E2" }}
      >
        {editorMode ? (
          <div style={{ height: "100vh", width: "100vw" }}>
            <div
              style={{
                height: "10vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div>
                {isEditMode ? (
                  <></>
                ) : (
                  <CustomizedDividers
                    onOpenDeleteDialog={handleOpenDeleteDialog}
                    deleteBboxList={deleteBboxList}
                    deleteMultipleBbox={deleteMultipleBbox}
                    onSelectDelete={handleSelectDelete}
                    onDeselectDelete={handleDeselectDelete}
                    inputFrame={inputFrame}
                    setCurrentTime={setStitchCurrentTime}
                    totalFrames={totalFrames}
                    addNewBoundingBox={addNewBoundingBox}
                    playerRef={comparePlayerRef}
                    currentTime={stitchCurrentTime}
                    fps={stitchFps}
                    handlePrevFrame={handleEditorPrevFrame}
                    setPlaybackRate={setPlaybackRate}
                    playbackRate={playbackRate}
                    handleNextFrame={handleEditorNextFrame}
                    setEditorMode={setEditorMode}
                    closeBoundingBox={closeBoundingBox}
                    isAddingDistress={isAddingDistress}
                    onFrameChange={handleInputFrame}
                    dataInfo={dataInfoV2}
                  />
                )}
              </div>
              {isEditMode ? (
                <div>
                  <EditOptions
                    handleDelete={handleDelete}
                    fps={stitchFps}
                    allPolyPoints={gpsDataCombined}
                    currentTime={stitchCurrentTime}
                    projectPredictions={filteredGpsData}
                    svgDimensions={svgDimensions}
                    originalVideoWidth={stitchlVideoWidth}
                    proid={proid}
                    rectangles={rectangles}
                    addDistressV2={props.editorAddDistress}
                    updateDistressV2={props.editorUpdateDistress}
                    fieldValues={fieldValues}
                    isAddingDistress={isAddingDistress}
                    setShowPointer={setShowPointer}
                    setFieldValues={setFieldValues}
                    setSelectedId={setSelectedId}
                    setIsEditMode={setIsEditMode}
                    handleChange={handleChange}
                    dataInfo={dataInfoV2}
                    drProjectData={drProjectData}
                    currentFrame={
                      wholeProjectDetections[
                        Math.floor(stitchFps * stitchCurrentTime)
                      ]
                    }
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
            <div
              id="asset-player"
              style={{
                padding: `${0}px`,
                height: `calc(${90}vh  - ${0}px - ${0 * 2}px - ${0 * 2}px))`,
                backgroundColor: "",
              }}
            >
              <Card
                elevation={0}
                sx={{
                  height: `calc(${90}vh  - ${0}px - ${0 * 2}px - ${0 * 2}px))`,
                  backgroundColor: "",
                }}
              >
                <div
                  ref={vidDivRef}
                  style={{
                    height: `calc(${90}vh  - ${0}px - ${0 * 2}px - ${0 * 2}px)`,
                    backgroundColor: "transparent",
                    width: "100%",
                  }}
                >
                  <DistressEditorPlayer
                    startTime={0}
                    vidDivRef={vidDivRef}
                    drProjectData={drProjectData}
                    playerRef={comparePlayerRef}
                    onCurrentTimeChange={onStitchCurrentTimeChange}
                    playbackRate={playbackRate}
                    filteredFrameData={filteredFrameData}
                    fps={stitchFps}
                    svgDimensions={svgDimensions}
                    originalVideoWidth={stitchlVideoWidth}
                    distressData={distressData}
                    rectangles={rectangles}
                    setRectangles={setRectangles}
                    showPointer={showPointer}
                    isEditMode={isEditMode}
                    selectedId={selectedId}
                    deleteMultipleBbox={deleteMultipleBbox}
                    onSelectDelete={handleDeleteBbox}
                    setFieldValues={setFieldValues}
                    setSelectedRecId={setSelectedRecId}
                    setOpenDistressDialog={setOpenDistressDialog}
                    setSelectedId={setSelectedId}
                    setIsEditMode={setIsEditMode}
                    setShowPointer={setShowPointer}
                    openDistressDialog={openDistressDialog}
                    selectedRecId={selectedRecId}
                    handleDelete={handleDelete}
                  />
                </div>
              </Card>
            </div>
          </div>
        ) : (
          <Grid container spacing={0} sx={{ height: `calc(100vh - ${0}px)` }}>
            <Grid
              item
              xs={4}
              sm={4}
              md={5}
              lg={6}
              xl={6}
              sx={{ backgroundColor: "" }}
            >
              {/* heaader */}
              <div ref={headerRef} style={{ backgroundColor: "" }}>
                <div
                  style={{
                    padding: su._createPadding(
                      overallCustomBorderPadding,
                      "trl"
                    ),
                  }}
                >
                  <SurfaceEvaluationTopbar
                    handleFastDistress={handleFastDistress}
                    filteredGpsData={filteredGpsData}
                    isFilterOpen={isFilterOpen}
                    selectedDefects={selectedDefects}
                    handleGpsDataFilterChange={handleGpsDataFilterChange}
                    getSelectedDefectNames={getSelectedDefectNames}
                    defectNames={defectName}
                    startEndLocation={startEndLocation}
                    proid={proid}
                    drProjectData={drProjectData}
                    distressProjectPredictions={filteredGpsData}
                    conditionMethod={conditionMethod}
                    setConditionMethod={setConditionMethod}
                    sectionLength={sectionLength}
                    setSectionLength={setSectionLength}
                    setFilteredGpsData={setFilteredGpsData}
                    appliedFilters={appliedFilters}
                    setAppliedFilters={setAppliedFilters}
                  />
                </div>
              </div>
              {/* video player & map */}
              {headerHeight === 0 ? (
                <CustomProgress />
              ) : (
                <div
                  style={{
                    height: `calc(100vh - ${headerHeight}px)`,
                    backgroundColor: "",
                  }}
                >
                  <div
                    style={{
                      height: `${videoSectionHeight}%`,
                      backgroundColor: "",
                      padding: su._createPadding(
                        overallCustomBorderPadding,
                        "trbl"
                      ),
                    }}
                  >
                    <div
                      ref={videoRef}
                      style={{ height: "100%", backgroundColor: "" }}
                    >
                      <Card sx={{ ...cardWhiteStyle }}>
                        <SurfaceEvaluationVideoPlayer
                          onCurrentTimeChange={onCurrentTimeChange}
                          playerRef={playerRef}
                          defaultRate={RATE}
                          startTime={
                            filteredGpsData.length > 0
                              ? filteredGpsData[Number(secId ?? 0)]
                                ? filteredGpsData[Number(secId ?? 0)][
                                    "initIndex"
                                  ] / FPS
                                : filteredGpsData[0]["initIndex"] / FPS
                              : 0
                          }
                          fps={FPS}
                          handleNextFrame={handleNextFrame}
                          handlePrevFrame={handlePrevFrame}
                          link={link}
                          height={
                            videoRef.current === null
                              ? 0
                              : videoRef.current?.clientHeight
                          }
                          control={false}
                        />
                      </Card>
                    </div>
                  </div>
                  <div
                    style={{
                      height: `${mapSectionHeight}%`,
                      backgroundColor: "",
                      padding: su._createPadding(
                        overallCustomBorderPadding,
                        "rbl"
                      ),
                    }}
                  >
                    <div style={{ height: "100%", backgroundColor: "" }}>
                      <Card sx={{ ...cardWhiteStyle, height: "100%" }}>
                        <DistressMap
                          isWholeDistressVisualization={
                            isWholeDistressVisualization
                          }
                          distressPinned={distressPinned}
                          setDistressPinned={setDistressPinned}
                          drProjectData={drProjectData}
                          predictions={predictions}
                          segmentIndex={segmentIndex}
                          FPS={FPS}
                          distressProjectPredictions={filteredGpsData}
                          zoom={18}
                          //   polyPoints={gpsData}
                          handleSectionChangeMap={handleSectionChangeMap}
                          pinned={pinned}
                          handleSeekTo={handleSeekTo}
                          handlePinned={handlePinned}
                          conditionMethod={conditionMethod}
                        />
                      </Card>
                    </div>
                  </div>
                </div>
              )}
            </Grid>
            <Grid
              item
              xs={8}
              sm={8}
              md={7}
              lg={6}
              xl={6}
              sx={{ backgroundColor: "" }}
            >
              <div
                style={{
                  padding: SmartUtils.edgeInsets(
                    overallCustomBorderPadding,
                    "tbr"
                  ),
                  height: "100%",
                }}
              >
                <Card
                  sx={{
                    borderRadius: SmartUtils.edgeInsets(15, "tblr"),
                    height: "100%",
                  }}
                >
                  <SegmentShifterV2
                    handleSeekTo={handleSeekTo}
                    isWholeDistressVisualization={isWholeDistressVisualization}
                    handleDataVisualizationChange={
                      handleDataVisualizationChange
                    }
                    distressPinned={distressPinned}
                    setDistressPinned={setDistressPinned}
                    setPinned={setPinned}
                    isDepthCrossSectional={isDepthCrossSectional}
                    handleDepthCrossSectional={handleDepthCrossSectional}
                    handleCloseDepthCrossSectional={
                      handleCloseDepthCrossSectional
                    }
                    segmentIndex={segmentIndex}
                    setSegmentIndex={setSegmentIndex}
                    predictions={filteredGpsData}
                    pinned={pinned}
                    originalVideoHeight={originalVideoHeight}
                    originalVideoWidth={originalVideoWidth}
                    link={link}
                    FPS={FPS}
                    playerRef={playerRef}
                    proid={proid}
                    drProjectData={drProjectData}
                    handleSectionChange={handleSectionChange}
                    sections={sections}
                    setSections={setSections}
                    crossSectionalPoints={crossSectionalPoints}
                    setCrossSectionalPoints={setCrossSectionalPoints}
                    defaultCrossSectionalPoints={defaultCrossSectionalPoints}
                    currentTime={currentTime}
                    overallCustomBorderPadding={overallCustomBorderPadding}
                    conditionMethod={conditionMethod}
                  />
                </Card>
              </div>
            </Grid>
          </Grid>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    project: state.project,
    datastore: state.datastore,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    depthCrossSectionalProfile: (points, proid, sectionId) =>
      dispatch(depthCrossSectionalProfile(points, proid, sectionId)),
    saveDepthCrossSectionalPoints: (points, proid, sectionId) =>
      dispatch(saveDepthCrossSectionalPoints(points, proid, sectionId)),
    getDownSamplingData: (proid) => dispatch(getDownSamplingData(proid)),
    analysisSetting: (selectedDefects, conditionMethod, proid) =>
      dispatch(analysisSetting(selectedDefects, conditionMethod, proid)),
    advancedAnalysisSetting: (appliedFilters, proid) =>
      dispatch(advancedAnalysisSetting(appliedFilters, proid)),
    setDefaultFilters: (defaultFilters, proid) =>
      dispatch(setDefaultFilters(defaultFilters, proid)),
    editorAddDistress: (
      proid,
      sectionId,
      bbox,
      defectClass,
      transversePosition,
      defectProperties
    ) =>
      dispatch(
        editorAddDistress(
          proid,
          sectionId,
          bbox,
          defectClass,
          transversePosition,
          defectProperties
        )
      ),
    editorUpdateDistress: (
      proid,
      sectionId,
      detectionId,
      bbox,
      defectClass,
      defectProperties
    ) =>
      dispatch(
        editorUpdateDistress(
          proid,
          sectionId,
          detectionId,
          bbox,
          defectClass,
          defectProperties
        )
      ),
    moveDistressToArchive: (proid, sectionId, detectionId) =>
      dispatch(moveDistressToArchive(proid, sectionId, detectionId)),
    moveDistressMultipleToArchive: (proid, detections) =>
      dispatch(moveDistressMultipleToArchive(proid, detections)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AfterSurfaceEvaluationInferenceDashboardV3);

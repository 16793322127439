import "leaflet/dist/leaflet.css";
import L from 'leaflet';
import { Alert, Button, CircularProgress, Grid, Snackbar, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { MapContainer, Marker, Polyline, useMapEvents } from "react-leaflet";
import EditGpsHeader from "./EditGpsHeader";
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import MarkerClusterGroup from "react-leaflet-cluster";
import markerImage1 from '../../../../../../../assets/pin_sm1.svg';
import markerImage2 from '../../../../../../../assets/pin_sm2.svg';
import markerImage3 from '../../../../../../../assets/pin_sm3.svg';
import markerImage from '../../../../../../../assets/pin_sm.svg';
import highlightMarkerImage from '../../../../../../../assets/pin_sm_highlight.svg';
import { downsampleGpsOnDistance, runGpsModificationFunction, runGpsModificationStartEnd } from "../../../../../../../helpers/GpsEditCalcHelper";
export default function EditGpsScreen(props) {
    const { selectedMarkerPoint, handleSelectFrame, handleFrame, afterInference, originalAllCoordinates, handleMapZoom,
        removeUploadEditGpsMsgAndError, uploadEditGpsLoader, uploadEditGpsIsDoneAndSent, setDisableHeader, showButton, proid, filteredCoordinates, setGpsMode,
        uploadEditGpsData, uploadEditGpsDataAfterInference, zoomLevel, drProjectData, visualizerProjectPredictions } = props
    const iconSize = [26 / 1, 38 / 1];
    const [pointA, setPointA] = useState(null);
    const [pointB, setPointB] = useState(null);
    const [startPoint, setStartPoint] = useState(null);
    const [endPoint, setEndPoint] = useState(null);
    const [newPointsAdd, setNewPointsAdd] = useState([]);
    const [modifiedFilteredCoordinates, setModifiedFilteredCorrdinates] = useState([...filteredCoordinates]);
    const centerCoordinate = filteredCoordinates[0];
    const [addMarkerPolyline, setAddMarkerPolyline] = useState([]);
    const individualPolylinePositions = modifiedFilteredCoordinates.map(coordinate => [coordinate.lat, coordinate.lng]);
    const userAddMarkerPolyline = addMarkerPolyline.length > 0 && addMarkerPolyline.map(each => [each.lat, each.lng])
    const [mapKey, setMapKey] = useState(0);
    const [addPointKey, setAddPointKey] = useState(0);
    const [downSampleCoordinates, setDownSampleCorrdinates] = useState([]);
    const [addPointA, setAddPointA] = useState(false);
    const [addPointB, setAddPointB] = useState(false);
    const [addUserPoint, setAddUserPoint] = useState(false);
    const [startPointAIndex, setStartPointAIndex] = useState(null);
    const [endPointBIndex, setEndPointBIndex] = useState(null);
    const [isSaveConfirm, setIsSaveConfirm] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    // AAMIR
    const [dragEnabled, setDragEnabled] = useState(false);

    const toggleDragEnabled = (e) => {
        setDragEnabled(!dragEnabled);
    }
    // ////console.log(modifiedFilteredCoordinates, newPointsAdd, startPoint, endPoint,'prac')
    const handleOpenAlert = () => {
        setOpenAlert(true);
    }
    const handleCloseAlert = () => {
        setOpenAlert(false);
    }
    useEffect(() => {
        removeUploadEditGpsMsgAndError();
    }, [])


    const clearStateValues = () => {
        setPointA(null);
        setPointB(null);
        setStartPoint(null);
        setEndPoint(null);
        setNewPointsAdd([]);
        setAddMarkerPolyline([]);
        setAddPointA(false);
        setAddPointB(false);
        setAddUserPoint(false);
        setStartPointAIndex(null);
        setEndPointBIndex(null);
        setAddPointKey((prevKey) => prevKey + 1);
        setMapKey((prevKey) => prevKey + 1);
    }
    const handleReset = () => {
        setModifiedFilteredCorrdinates([...filteredCoordinates]);
        setIsSaveConfirm(false);
        // setMapKey((prevKey) => prevKey + 1);
        clearStateValues();
    }
    useEffect(() => {
        if (uploadEditGpsIsDoneAndSent === true) {
            clearStateValues();
            setIsSaveConfirm(false);
            removeUploadEditGpsMsgAndError();
        }
    }, [uploadEditGpsIsDoneAndSent])
    useEffect(() => {
        if (modifiedFilteredCoordinates) {
            const updatedDownSampleCoordinates = downsampleGpsOnDistance(modifiedFilteredCoordinates, 5);
            setDownSampleCorrdinates(updatedDownSampleCoordinates);
        }
    }, [modifiedFilteredCoordinates])

    // //console.log(addMarkerPolyline, "addMarkerPolyline")

    const handlePointClick = (e, index) => {

        if (addPointA) {
            if (pointA === null) {
                if (endPointBIndex !== null) {
                    if (index === endPointBIndex) {
                        handleOpenAlert();
                        setAlertMessage(`Start pin cannot be same as end pin`)
                    }
                    else {
                        setStartPoint(modifiedFilteredCoordinates.findIndex(each => each.millis === downSampleCoordinates[index].millis));
                        setPointA(e.latlng);
                        setAddPointA(false);
                        setStartPointAIndex(index);
                        // setAddPointB(true);
                    }
                }
                else {
                    setStartPoint(modifiedFilteredCoordinates.findIndex(each => each.millis === downSampleCoordinates[index].millis));
                    setPointA(e.latlng);
                    setAddPointA(false);
                    setStartPointAIndex(index);
                    // setAddPointB(true);
                }

            }
            else {
                handleOpenAlert();
                setAlertMessage(`Start pin is already selected`);
            }
        }
        else if (addPointB) {
            if (pointB === null) {
                // a=2 b=3 a=2  b=1
                if (index === startPointAIndex) {
                    handleOpenAlert();
                    setAlertMessage(`End pin cannot be same as start pin`);
                }
                else {
                    setEndPoint(modifiedFilteredCoordinates.findIndex(each => each.millis === downSampleCoordinates[index].millis));
                    setPointB(e.latlng);
                    setAddPointB(false);
                    setEndPointBIndex(index);
                    // setAddPointA(true);
                }
            }
            else {
                handleOpenAlert();
                setAlertMessage(`End pin is already selected`);
            }
        }
        else {
            setAlertMessage(`No selection enabled so this pin cannot be selected`);
            handleOpenAlert();

        }
    }

    const handleDrag = (event, index) => {
        // console.log("HANDLE_DRAG_END", index, event)
        // console.log("HANDLE_DRAG_END_2", modifiedFilteredCoordinates[index], event.target._latlng)
        // TODO: INTEGRATE GPS MODIFICATION FUNCTIONALITY HERE

        let updatedGpsEdit = runGpsModificationStartEnd(modifiedFilteredCoordinates, event.target._latlng, modifiedFilteredCoordinates.findIndex(x => x.millis === downSampleCoordinates[index].millis), modifiedFilteredCoordinates.findIndex(x => x.millis === downSampleCoordinates[index === 0 ? index + 1 : index - 1].millis));
        setModifiedFilteredCorrdinates(updatedGpsEdit);
        setMapKey((prevKey) => prevKey + 1);
        setIsSaveConfirm(true);
        clearStateValues();
    }
    const handleAddPoint = (e) => {
        if (addUserPoint) {
            if (pointA !== null && pointB !== null) {

                const newMarker = e.latlng;
                const updateNewPointsAdd = [...newPointsAdd, newMarker];
                setNewPointsAdd(updateNewPointsAdd);

                const updatedPointPolylineData = [pointA, ...updateNewPointsAdd, pointB]
                setAddMarkerPolyline(updatedPointPolylineData);
                // setAddPointKey((prevKey) => prevKey + 1);
                setMapKey((prevKey) => prevKey + 1);
                // setAddUserPoint(false);

            }
        }
        else {
            if (pointA && pointB) {
                handleOpenAlert();
                setAlertMessage(`Enable pin addition to add a pin`);
            }
        }
    }
    const handleRemovePoints = (type) => {
        if (type === 1) {
            setStartPoint(null);
            setPointA(null);
            setAddPointA(false);
            setStartPointAIndex(null);

            setNewPointsAdd([]);
            setAddMarkerPolyline([]);
            setAddPointKey((prevKey) => prevKey + 1);
        }
        else if (type === 2) {
            setEndPoint(null);
            setPointB(null);
            setAddPointB(false);
            setEndPointBIndex(null);

            setNewPointsAdd([]);
            setAddMarkerPolyline([]);
            setAddPointKey((prevKey) => prevKey + 1);
        }
    }
    const handleRemoveAllPoints = () => {
        setNewPointsAdd([]);
        setAddMarkerPolyline([]);
        setAddPointKey((prevKey) => prevKey + 1);
    };
    const MapClickHandler = () => {
        useMapEvents({
            click: handleAddPoint,
        });
        return null;
    };
    const handleGpsEdit = () => {
        if (startPoint === null || endPoint === null || newPointsAdd.length === 0) {
            handleOpenAlert();
            setAlertMessage(`No selection found to adjust route`);
        }
        else {
            let updatedGpsEdit = runGpsModificationFunction(modifiedFilteredCoordinates, newPointsAdd, startPoint, endPoint);
            setModifiedFilteredCorrdinates(updatedGpsEdit);
            setMapKey((prevKey) => prevKey + 1);
            setIsSaveConfirm(true);
            clearStateValues();
        }
    }
    const handleConfirm = () => {
        let startIndex = originalAllCoordinates.findIndex((obj) => obj.millis === modifiedFilteredCoordinates[0].millis);
        let updatedAllCoordinates = originalAllCoordinates;
        modifiedFilteredCoordinates.map((each, index) => {
            updatedAllCoordinates[startIndex + index]['lat'] = each.lat
            updatedAllCoordinates[startIndex + index]['lng'] = each.lng
        });
        if (visualizerProjectPredictions.length > 0) {
            uploadEditGpsDataAfterInference(updatedAllCoordinates, proid, drProjectData.projectType);
        }
        else {
            uploadEditGpsData(updatedAllCoordinates, proid, drProjectData.projectType);
        }
    }
    const handleRemove = (setIndex) => {
        const updatedMarkerList = newPointsAdd.filter((each, index) => index !== setIndex)
        setNewPointsAdd(updatedMarkerList);
        const updatedPointPolylineData = [pointA, ...updatedMarkerList, pointB]
        setAddMarkerPolyline(updatedPointPolylineData);
        setAddPointKey((prevKey) => prevKey + 1);
    };
    // console.log(addPointKey, mapKey, "keyss")
    return (
        <>
            <Snackbar open={openAlert} autoHideDuration={3000} onClose={handleCloseAlert}>
                <Alert
                    onClose={handleCloseAlert}
                    severity="warning"
                    variant="outlined"
                    sx={{ width: '100%' }}
                >
                    {alertMessage}
                </Alert>
            </Snackbar>
            <div style={{ padding: afterInference ? '0px' : '10px' }}>
                <div style={{ height: '10vh', background: '' }}>
                    <EditGpsHeader
                        addUserPoint={addUserPoint}
                        addPointA={addPointA}
                        addPointB={addPointB}
                        pointA={pointA}
                        pointB={pointB}
                        setAddPointA={setAddPointA}
                        setAddPointB={setAddPointB}
                        setAddUserPoint={setAddUserPoint}
                        handleRemovePoints={handleRemovePoints} handleRemove={handleRemove} newPointsAdd={newPointsAdd} setNewPointsAdd={setNewPointsAdd} setAddPointKey={setAddPointKey} handleRemoveAllPoints={handleRemoveAllPoints}
                        toggleDragging={toggleDragEnabled} dragEnabled={dragEnabled}
                        afterInference={afterInference}
                    />
                </div>
                <div style={{ cursor: 'default', height: `calc(${afterInference ? '80vh' : showButton ? '80vh' : '63vh'} - 20px - 5px)`, width: '100%', marginTop: '0px', background: "" }}>
                    <MapContainer attributionControl={false} maxZoom={25} center={[centerCoordinate.lat, centerCoordinate.lng]} zoom={zoomLevel} style={{ cursor: 'default', height: `calc(${afterInference ? '80vh' : showButton ? '80vh' : '63vh'} - 20px - 5px)`, width: '100%', marginTop: '0px', background: "" }}
                        whenReady={(map) => {
                            map.target.on('zoom', handleMapZoom);
                        }}
                    >
                        <ReactLeafletGoogleLayer apiKey='AIzaSyA1Zq5IDPlPl_i4MnG9wCBg8s6XQrfB9Ig' type={'roadmap'} maxZoom={25} maxNativeZoom={22} />
                        <MapClickHandler />
                        <Polyline
                            key={addPointKey}
                            positions={userAddMarkerPolyline}
                            color="darkorange" weight={5} stroke={true}
                        />
                        <Polyline key={mapKey} positions={individualPolylinePositions} color="green" />
                        <MarkerClusterGroup chunkedLoading disableClusteringAtZoom={20} >
                            {downSampleCoordinates.map((coordinate, index) => {
                                const match = selectedMarkerPoint?.millis === coordinate.millis;
                                return (
                                    <Marker
                                        icon={L.icon({
                                            iconAnchor: [iconSize[0] / 2, iconSize[1]],
                                            popupAnchor: [-0, -0],
                                            iconUrl:
                                                coordinate.lat === pointA?.lat && coordinate.lng === pointA?.lng
                                                    ? markerImage1
                                                    : coordinate.lat === pointB?.lat && coordinate.lng === pointB?.lng
                                                        ? markerImage2
                                                        : match ? highlightMarkerImage : markerImage,
                                            iconSize: iconSize,
                                        })}
                                        key={index + mapKey} position={[coordinate.lat, coordinate.lng]}
                                        draggable={dragEnabled &&
                                            (index === 0 || index === (downSampleCoordinates.length - 1)
                                            )}
                                        eventHandlers={{
                                            click: (event) => {
                                                handleFrame(coordinate);
                                                handleSelectFrame(coordinate);
                                                handlePointClick(event, index);
                                            },
                                            // click: (event) => handlePointClick(event, index),
                                            dragend: (event) => handleDrag(event, index)
                                        }}
                                    >
                                        {/* {frameLink && (
                                        <div className="inset-image-container">
                                            <MuiTooltip
                                                title={"Click to close"}
                                                arrow
                                            >
                                                <img
                                                    src={frameLink}
                                                    alt="Frame"
                                                    className="inset-image"
                                                // onClick={() => { setFrameLink('') }}
                                                />
                                            </MuiTooltip>

                                        </div>
                                    )} */}
                                    </Marker>
                                )
                            }
                            )}
                        </MarkerClusterGroup>
                        {pointA && (
                            <Marker position={pointA} title="Start point" icon={L.icon({
                                iconAnchor: [iconSize[0] / 2, iconSize[1]],
                                popupAnchor: [-0, -0],
                                iconUrl: markerImage1,
                                iconSize: iconSize,

                            })}>
                            </Marker>
                        )}
                        {pointB && (
                            <Marker position={pointB} title="End point" icon={L.icon({
                                iconAnchor: [iconSize[0] / 2, iconSize[1]],
                                popupAnchor: [-0, -0],
                                iconUrl: markerImage2,
                                iconSize: iconSize,

                            })}>
                            </Marker>
                        )}

                        {newPointsAdd.map((eachPoint, index) => (
                            <Marker
                                icon={L.icon({
                                    iconAnchor: [iconSize[0] / 2, iconSize[1]],
                                    popupAnchor: [-0, -0],
                                    iconUrl: markerImage3,
                                    iconSize: iconSize,

                                })}
                                title={`Marker ${index + 1}`}
                                key={index + addPointKey} position={[eachPoint.lat, eachPoint.lng]}
                            />
                        ))}
                    </MapContainer>
                </div>
                <div style={{ height: 'calc(10vh - 0px)', width: '100%', background: '' }}>
                    <Grid container spacing={0} sx={{ height: '100%', }}>
                        <Grid item xs={12} sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Tooltip title={"Adjust the newly created path"} arrow placement='top'>
                                <Button
                                    disabled={startPoint === null || endPoint === null || newPointsAdd.length === 0}
                                    onClick={() => { handleGpsEdit() }}
                                    variant="contained"
                                    sx={{
                                        textTransform: "initial",
                                        backgroundColor: "#025196",
                                        color: "#FFFFFF",
                                        "&:hover": {
                                            backgroundColor: "#025196",
                                            color: "#FFFFFF",
                                        },
                                    }}
                                >
                                    Adjust path
                                </Button>
                            </Tooltip >
                            <Tooltip title={"Rest to original path"} arrow placement='top'>
                                <Button
                                    onClick={() => { handleReset() }}
                                    disabled={isSaveConfirm ? false : true}
                                    variant="contained"
                                    sx={{
                                        marginLeft: '10px',
                                        textTransform: "initial",
                                        backgroundColor: "#025196",
                                        color: "#FFFFFF",
                                        "&:hover": {
                                            backgroundColor: "#025196",
                                            color: "#FFFFFF",
                                        },
                                    }}
                                >
                                    Reset path
                                </Button>
                            </Tooltip>
                            {uploadEditGpsLoader ?
                                < Button variant="contained" disabled sx={{
                                    marginLeft: '10px',
                                    textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                                    "&.Mui-disabled": {
                                        backgroundColor: '#025196',
                                        color: '#FFFFFF'
                                    }
                                }}>
                                    <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} /><span style={{ paddingLeft: '15px' }}>Saving...</span>
                                </Button>
                                :
                                <Tooltip title={"Save the new adjusted path"} arrow placement='top'>
                                    <Button
                                        onClick={() => { handleConfirm() }}
                                        disabled={isSaveConfirm ? false : true}
                                        variant="contained"
                                        sx={{
                                            marginLeft: '10px',
                                            textTransform: "initial",
                                            backgroundColor: "#025196",
                                            color: "#FFFFFF",
                                            "&:hover": {
                                                backgroundColor: "#025196",
                                                color: "#FFFFFF",
                                            },
                                        }}
                                    >
                                        Save
                                    </Button>
                                </Tooltip>

                            }
                            <Tooltip title={"Back to view GPS"} arrow placement='top'>
                                <Button
                                    onClick={() => { setGpsMode(false); setDisableHeader(false) }}
                                    variant="contained"
                                    sx={{
                                        marginLeft: '10px',
                                        textTransform: "initial",
                                        backgroundColor: "#025196",
                                        color: "#FFFFFF",
                                        "&:hover": {
                                            backgroundColor: "#025196",
                                            color: "#FFFFFF",
                                        },
                                    }}
                                >
                                    Back
                                </Button>
                            </Tooltip>

                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    )
}
import PlanDetailsCard from "./PlanDetailsCard"
import PlanCustomTokens from "./PlanCustomTokens";
const PlansListAdapter = (props) => {
    const { productList, charges, isProjectCreationScreen,approxCreditsRequired,proid,companyCredits } = props;
    return (
        productList?.map((eachPlan, i) => {
            return <PlanCustomTokens productDetails={eachPlan} key={eachPlan.id} companyCredits={companyCredits} proid={proid} charges={charges} approxCreditsRequired={approxCreditsRequired} isProjectCreationScreen={isProjectCreationScreen} />
            // <PlanDetailsCard PlanDetails={eachPlan} key={eachPlan.id} />
        })
    );
}

export default PlansListAdapter;
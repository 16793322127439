import { Autocomplete, Checkbox, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import CustomConditionIndex from '../supersections/CustomConditionIndex';
import { clearSetupNetworkAndMsg } from '../../../../../../store/actions/networkActions';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import CustomProgress from '../../../../../cidgets/CustomProgress';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const NetworkSetting = (props) => {
    const { drNetworkData, superSections, allProjects } = props;
    const { showProjectAlert, setShowProjectAlert, selectedProjectList, setSelectedProjectList, projectList, setProjectList } = props;
    const { showSuperSectionAlert, setShowSuperSectionAlert, superSectionList, setSuperSectionList, conditionsList, conditionMethod, handleConditionMethodChange } = props;
    const { projectType } = drNetworkData;

    useEffect(() => {
        if (drNetworkData && allProjects) {
            if (drNetworkData.typeOfRoad) {
                const filteredProjectList = allProjects.filter(eachProj => drNetworkData.projectType === eachProj.projectType && drNetworkData.typeOfRoad === eachProj.typeOfRoad);
                setProjectList(filteredProjectList);
            }
            else {
                const filteredProjectList = allProjects.filter(eachProj => drNetworkData.projectType === eachProj.projectType);
                setProjectList(filteredProjectList);
            }

            if (drNetworkData.associatedProjects) {
                const selectedProjectList = allProjects.filter(eachProj => drNetworkData.associatedProjects.includes(eachProj.proid));
                setSelectedProjectList(selectedProjectList);
            }
        }
    }, [drNetworkData, allProjects])
    if (drNetworkData.recallibrating) {
        return (<div style={{ height: '100%' }}><CustomProgress text={"Network recallibrating"} /></div>)
    }
    return (
        <>
            <Autocomplete
                multiple
                limitTags={4}
                id="projectList"
                options={projectList}
                disableCloseOnSelect
                getOptionLabel={(option) => option.projectTitle}
                getOptionSelected={(option, value) => option.proid === value.proid}
                sx={{ marginTop: '16px' }}
                value={selectedProjectList}
                onChange={(event, newValue) => {
                    setShowProjectAlert(false);
                    setSelectedProjectList(newValue);
                }}
                renderOption={(props, option, { selected }) => (
                    <li {...props}>
                        <Checkbox icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }} checked={selected} />
                        {option.projectTitle}
                    </li>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Select projects"
                        error={showProjectAlert}
                        helperText={showProjectAlert ? "Please make sure to select at least one project" : ""}
                    />
                )}
            />
            {superSections ? <Autocomplete
                multiple
                limitTags={4}
                id="ssList"
                options={superSections}
                disableCloseOnSelect
                getOptionLabel={(option) => option.superSectionTitle}
                getOptionSelected={(option, value) => option.superSectionId === value.superSectionId}
                sx={{ marginTop: '16px' }}
                value={superSectionList}
                onChange={(event, newValue) => {
                    setShowSuperSectionAlert(false);
                    setSuperSectionList(newValue);
                }}
                renderOption={(props, option, { selected }) => (
                    <li {...props}>
                        <Checkbox icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }} checked={selected} />
                        {option.superSectionTitle}
                    </li>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Select supersection"
                        error={showSuperSectionAlert}
                        helperText={showSuperSectionAlert ? "Please make sure to select at least one supersection" : ""}
                    />
                )}
            /> : <></>}
            <FormControl size="small" sx={{ width: "100%", marginTop: 3 }}>
                <InputLabel id="condition-method-label">Condition evaluation method</InputLabel>
                {projectType != 4 ?
                    <Select
                        labelId="condition-method-label"
                        id="condition-method"
                        value={conditionsList.length === 0 ? -1 : conditionMethod}
                        label="Condition evaluation method"
                        onChange={handleConditionMethodChange}
                    >
                        <MenuItem value={0}>
                            Road condition index
                        </MenuItem>
                        <MenuItem value={1}>
                            Pavement condition index
                        </MenuItem>
                        <MenuItem value={2}>
                            Custom condition index
                        </MenuItem>
                        <MenuItem value={-1}>None</MenuItem>
                    </Select>
                    : <Select
                        labelId="condition-method-label"
                        id="condition-method"
                        value={conditionMethod}
                        // value={conditionsList.length === 0 ? -1 : conditionMethod}
                        label="Condition evaluation method"
                        onChange={handleConditionMethodChange}
                    >
                        <MenuItem value={1}>
                            Pavement condition index
                        </MenuItem>
                        <MenuItem value={2}>
                            Custom condition index
                        </MenuItem>
                        <MenuItem value={-1}>None</MenuItem>
                    </Select>}
            </FormControl>
            {conditionMethod === 2 ? <CustomConditionIndex height="100%" drNetworkData={drNetworkData} /> : <></>}
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        superSections: state.firestore.ordered.SuperSections,
        allProjects: state.firestore.ordered.Projects,
        userData: state.firebase.profile,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        clearSetupNetworkAndMsg: () => dispatch(clearSetupNetworkAndMsg()),
    };
};
// export default connect(mapStateToProps, mapDispatchToProps)(NetworkSetting);
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect((props) => {
        var coid = props.userData.coid;
        return [
            {
                collection: 'Projects',
                where: [['coid', '==', `${coid}`], ['archived', '==', false]],
                orderBy: [['dateCreated', 'desc']],
            }
        ]
    })
)(NetworkSetting);

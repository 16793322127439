import firebase from "firebase";
import { useApi } from "../../chooks/useApi";
import { db } from "../LocalDatabase";
const baseUrl = "https://dr-utils-2zusqszkmq-uc.a.run.app"

export const getAssetData = () => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({
            type: 'START_DOWNLOADING_ASSET_DATA'
        })
        const assetDataArray = await db.assetData.toArray();
        const assetDataLen = assetDataArray.length;
        // //////console.log(assetDataLen, "actionasset", (assetDataLen <= 0))
        if (assetDataLen <= 0) {
            fetch(baseUrl + '/getAllData')
                .then((response) => response.json())
                .then(async (assetData) => {
                    await db.assetData.add({
                        assetData,
                    });
                    return dispatch({ type: 'SET_ASSET_DATA', assetData });

                })
                .catch((error) => {
                    return dispatch({ type: 'SET_ASSET_DATA' });
                });
        }
    };
}
export const getDataInfo = () => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({
            type: 'START_DOWNLOADING_DATA_INFO'
        })
        const dataInfoArray = await db.dataInfo.toArray();
        const dataInfoLen = dataInfoArray.length;
        if (dataInfoLen <= 0) {
            fetch(baseUrl + '/dataInfo')
                .then((response) => response.json())
                .then(async (dataInfo) => {
                    await db.dataInfo.add({
                        dataInfo,
                    });
                    return dispatch({ type: 'SET_DATA_INFO', dataInfo });

                })
                .catch((error) => {
                    return dispatch({ type: 'SET_DATA_INFO' });
                });
        }
    };
}
export const getDataInfoV2 = () => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({
            type: 'START_DOWNLOADING_DATA_INFO'
        })
        //    Step 1: Check if datainfo exist in DB
        //   Step2: If exist return datainfo
        const dataInfoArray = await db.dataInfo.toArray();
        const dataInfoLen = dataInfoArray.length;
        if (dataInfoLen > 0) {
            // //////console.log("db data");
            const dataInfo = dataInfoArray[0]['dataInfo'];
            return dispatch({ type: 'SET_DATA_INFO', dataInfo });
        }
        // Step 3: Else fetch from api, update the DB, return datainfo
        else {
            fetch(baseUrl + '/dataInfo')
                .then((response) => response.json())
                .then(async (dataInfo) => {
                    // //////console.log("api ", db.dataInfo);
                    await db.dataInfo.add({
                        dataInfo,
                    });
                    return dispatch({ type: 'SET_DATA_INFO', dataInfo });

                })
                .catch((error) => {
                    const dataInfo = null;
                    return dispatch({ type: 'SET_DATA_INFO', dataInfo });
                });
        }


    };
}
export const getAssetDataV2 = () => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({
            type: 'START_DOWNLOADING_ASSET_DATA'
        })
        const assetDataArray = await db.assetData.toArray();
        const assetDataLen = assetDataArray.length;
        if (assetDataLen > 0) {
            const assetData = assetDataArray[0]['assetData'];
            return dispatch({ type: 'SET_ASSET_DATA', assetData });
        }
        else {
            fetch(baseUrl + '/getAllData')
                .then((response) => response.json())
                .then(async (assetData) => {
                    // //////console.log("api asset",db.assetData)
                    await db.assetData.add({
                        assetData,
                    });
                    return dispatch({ type: 'SET_ASSET_DATA', assetData });

                })
                .catch((error) => {
                    const assetData = [];
                    return dispatch({ type: 'SET_ASSET_DATA', assetData });
                });
        }

    };
}
export const getDistressData = () => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase();
        dispatch({
            type: 'START_DOWNLOADING_DISTRESS_DATA'
        })

        fetch('https://us-central1-deeprowserverless.cloudfunctions.net/project-getDistressLegend')
            .then((response) => response.json())
            .then(async (response) => {
                // //////console.log(response, "response")
                const distressData = response.responseMsg;
                return dispatch({ type: 'SET_DISTRESS_DATA', distressData });
            })
            .catch((error) => {
                const distressData = [];
                return dispatch({ type: 'SET_DISTRESS_DATA', distressData });
            });

    };
}
export const getSafetyData = () => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({
            type: 'START_DOWNLOADING_SAFETY_DATA'
        })
        fetch('https://us-central1-deeprowserverless.cloudfunctions.net/project-getSafetyLegend')
            .then((response) => response.json())
            .then(async (response) => {
                // //////console.log(response, "response")
                const safetyData = response.responseMsg;
                return dispatch({ type: 'SET_SAFETY_DATA', safetyData });
            })
            .catch((error) => {
                const safetyData = [];
                return dispatch({ type: 'SET_SAFETY_DATA', safetyData });
            });

    };
}
export const getBasicImagingData = () => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({
            type: 'START_DOWNLOADING_BASIC_IMAGING_DATA'
        })
        fetch('https://us-central1-deeprowserverless.cloudfunctions.net/project-getBasicImagingLegend')
            .then((response) => response.json())
            .then(async (response) => {
                // //////console.log(response, "response")
                const basicImagingData = response.responseMsg;
                return dispatch({ type: 'SET_BASIC_IMAGING_DATA', basicImagingData });
            })
            .catch((error) => {
                const basicImagingData = [];
                return dispatch({ type: 'SET_BASIC_IMAGING_DATA', basicImagingData });
            });

    };
}
export const getAccessManagementData = () => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({
            type: 'START_DOWNLOADING_ACCESS_MANAGEMENT_DATA'
        })
        fetch('https://us-central1-deeprowserverless.cloudfunctions.net/userAccessControlCallableV2-getUACLegends')
            .then((response) => response.json())
            .then(async (response) => {
                // //////console.log(response, "response")
                const accessManagementData = response.responseMsg;
                return dispatch({ type: 'SET_ACCESS_MANAGEMENT_DATA', accessManagementData });
            })
            .catch((error) => {
                const accessManagementData = [];
                return dispatch({ type: 'SET_ACCESS_MANAGEMENT_DATA', accessManagementData });
            });

    };
}
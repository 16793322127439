import { Card, Grid, Paper, Typography, styled } from "@mui/material";
import React from "react";

const PinnedDistressFields = (props) => {
  const { dataInfo, distressPinned, drProjectData } = props;
  const {
    defectName,
    profile,
    severity,
    transversePosition,
    type,
    wheelPath,
    waterPumping,
  } = dataInfo;

  const showDepthFieldList = ["0", "4"];
  const showTypeFieldList = ["1"];
  const showProfileFieldList = ["2"];
  const showWidthFieldList = ["4"];
  const showWheelPathFieldList = ["4"];
  const showNegativeStatusFieldList = ["-1"];
  const Item = styled(Paper)(({ theme }) => ({
    boxShadow: "0px 0px transparent",
    width: "100%",
    backgroundColor: "transparent",
    ...theme.typography.body2,
    padding: theme.spacing(0.5),
  }));
  const fieldStyle = {
    border: "2px solid grey",
    borderRadius: "5px",
    padding: "5px",
    fontSize: "0.8rem",
    textTransform: "capitalize",
    overflow: "auto",
  };
  return (
    <Grid container>
      <Grid item xs={6}>
        <div style={{ backgroundColor: "transparent", overflow: "auto" }}>
          <Item>
            <Grid container>
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                  Defect category:
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Card sx={fieldStyle}>
                  <Typography variant="inherit">
                    {`${
                      defectName[
                        distressPinned?.versioning?.defectName ??
                          distressPinned?.defectName
                      ]["name"]
                    }`}
                  </Typography>
                </Card>
              </Grid>
            </Grid>
          </Item>
          {!showNegativeStatusFieldList.includes(`${distressPinned?.area}`) ? (
            <Item>
              <Grid container>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    Area (m<sup>2</sup>):
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Card sx={fieldStyle}>
                    <Typography variant="inherit">
                      {Number(
                        distressPinned?.versioning?.area ?? distressPinned?.area
                      ).toFixed(2)}
                    </Typography>
                  </Card>
                </Grid>
              </Grid>
            </Item>
          ) : (
            <></>
          )}
          <Item>
            <Grid container>
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                  Transverse position:
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Card sx={fieldStyle}>
                  <Typography variant="inherit">
                    {`${
                      transversePosition[
                        distressPinned?.versioning?.transversePosition ??
                          distressPinned?.transversePosition
                      ]["name"]
                    }`}
                  </Typography>
                </Card>
              </Grid>
            </Grid>
          </Item>
          {showTypeFieldList.includes(
            `${
              distressPinned?.versioning?.defectName ??
              distressPinned.defectName
            }`
          ) &&
          !showNegativeStatusFieldList.includes(
            `${distressPinned?.versioning?.type ?? distressPinned?.type}`
          ) ? (
            <Item>
              <Grid container>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    Type:
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Card sx={fieldStyle}>
                    <Typography variant="inherit">
                      {`${
                        type[
                          distressPinned?.versioning?.type ??
                            distressPinned?.type
                        ]["name"]
                      }`}
                    </Typography>
                  </Card>
                </Grid>
              </Grid>
            </Item>
          ) : (
            <></>
          )}
          <Item>
            <Grid container>
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                  Severity:
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Card sx={fieldStyle}>
                  <Typography variant="inherit">
                    {`${
                      severity[
                        distressPinned?.versioning?.severity ??
                          distressPinned?.severity
                      ]["name"]
                    }`}
                  </Typography>
                </Card>
              </Grid>
            </Grid>
          </Item>
          {showDepthFieldList.includes(
            `${
              distressPinned?.versioning?.defectName ??
              distressPinned.defectName
            }`
          ) &&
          !showNegativeStatusFieldList.includes(
            `${distressPinned?.versioning?.depth ?? distressPinned?.depth}`
          ) ? (
            <Item>
              {drProjectData.projectType === 4 ? (
                <></>
              ) : (
                <Grid container>
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                      Depth (mm):
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Card sx={fieldStyle}>
                      <Typography variant="inherit">
                        {Number(
                          distressPinned?.versioning?.depth ??
                            distressPinned?.depth * 1000
                        ).toFixed(2)}
                      </Typography>
                    </Card>
                  </Grid>
                </Grid>
              )}
            </Item>
          ) : (
            <></>
          )}
          {showProfileFieldList.includes(
            `${
              distressPinned?.versioning?.defectName ??
              distressPinned.defectName
            }`
          ) &&
          !showNegativeStatusFieldList.includes(
            `${distressPinned?.versioning?.profile ?? distressPinned?.profile}`
          ) ? (
            <Item>
              <Grid container>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    Profile:
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Card sx={fieldStyle}>
                    <Typography variant="inherit">
                      {`${
                        profile[
                          distressPinned?.versioning?.profile ??
                            distressPinned?.profile
                        ]["name"]
                      }`}
                    </Typography>
                  </Card>
                </Grid>
              </Grid>
            </Item>
          ) : (
            <></>
          )}
          {showWidthFieldList.includes(
            `${
              distressPinned?.versioning?.defectName ??
              distressPinned.defectName
            }`
          ) &&
          !showNegativeStatusFieldList.includes(
            `${distressPinned?.versioning?.width ?? distressPinned?.width}`
          ) ? (
            <Item>
              <Grid container>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    Width:
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Card sx={fieldStyle}>
                    <Typography variant="inherit">
                      {Number(
                        distressPinned?.versioning?.width ??
                          distressPinned?.width
                      ).toFixed(2)}
                    </Typography>
                  </Card>
                </Grid>
              </Grid>
            </Item>
          ) : (
            <></>
          )}
             <Item>
            <Grid container>
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                  Water pumping:
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Card sx={fieldStyle}>
                  <Typography variant="inherit">
                    {`${
                      waterPumping[
                        distressPinned?.versioning?.waterPumping ??
                          distressPinned?.waterPumping ??
                          -1
                      ]["name"]
                    }`}
                  </Typography>
                </Card>
              </Grid>
            </Grid>
          </Item>
        </div>
      </Grid>
      {/* Section 2 */}
      <Grid item xs={6}>
        <div style={{ backgroundColor: "transparent", overflow: "auto" }}>
          <Item>
            <Grid container>
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                  GPS location:
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Card sx={fieldStyle}>
                  <Typography variant="inherit">
                    {`${distressPinned?.geo[0]},${distressPinned?.geo[1]}`}
                  </Typography>
                </Card>
              </Grid>
            </Grid>
          </Item>
          {!showNegativeStatusFieldList.includes(
            `${
              distressPinned?.versioning?.transverseSpan ??
              distressPinned?.transverseSpan
            }`
          ) ? (
            <Item>
              <Grid container>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    Transverse span (m):
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Card sx={fieldStyle}>
                    <Typography variant="inherit">
                      {Number(
                        distressPinned?.versioning?.transverseSpan ??
                          distressPinned?.transverseSpan
                      ).toFixed(2)}
                    </Typography>
                  </Card>
                </Grid>
              </Grid>
            </Item>
          ) : (
            <></>
          )}
          {!showNegativeStatusFieldList.includes(
            `${
              distressPinned?.versioning?.longitudinalSpan ??
              distressPinned?.longitudinalSpan
            }`
          ) ? (
            <Item>
              <Grid container>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    Longitudinal span (m):
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Card sx={fieldStyle}>
                    <Typography variant="inherit">
                      {Number(
                        distressPinned?.versioning?.longitudinalSpan ??
                          distressPinned?.longitudinalSpan
                      ).toFixed(2)}
                    </Typography>
                  </Card>
                </Grid>
              </Grid>
            </Item>
          ) : (
            <></>
          )}
       
        
          {showWheelPathFieldList.includes(
            `${
              distressPinned?.versioning?.defectName ??
              distressPinned.defectName
            }`
          ) &&
          !showNegativeStatusFieldList.includes(
            `${
              distressPinned?.versioning?.wheelPath ?? distressPinned?.wheelPath
            }`
          ) ? (
            <Item>
              <Grid container>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    Wheel path:
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Card sx={fieldStyle}>
                    <Typography variant="inherit">
                      {`${
                        wheelPath[
                          distressPinned?.versioning?.wheelPath ??
                            distressPinned?.wheelPath
                        ]["name"]
                      }`}
                    </Typography>
                  </Card>
                </Grid>
              </Grid>
            </Item>
          ) : (
            <></>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default PinnedDistressFields;

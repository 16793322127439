import React, { useEffect, useState } from 'react'
import LinkedAccountSetup from './addNewAccountComponents/LinkedAccountSetup'
import LinkedAccountRegisterForm from './addNewAccountComponents/LinkedAccountRegisterForm'
import LinkedAccountVerify from './addNewAccountComponents/LinkedAccountVerify'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import { Box, Button, CircularProgress, Grid, Tooltip, Typography } from '@mui/material'
import AccessCodeTable from './addNewAccountComponents/AccessCodeTable'
import { addAccessCode, deleteLinkedAccount, removeAccessCode, removeDeleteLinkedAccountMessage, updateAccessCode } from '../../../../../store/actions/authActions'
import SimpleDialogBox from '../../../../cidgets/SimpleDialogBox'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
const AddLinkedAccount = (props) => {
  const { auth, userData, linkedAccountUserData, datastore } = props;
  const { accessManagementData } = datastore;
  const { registerLinkedAccountIsDoneAndSent, addAccessCodeLoader, addAccessCodeIsDoneAndSent, deleteLinkedAccountIsDoneAndSent,
    updateAccessCodeLoader, updateAccessCodeIsDoneAndSent, removeAccessCodeLoader, removeAccessCodeIsDoneAndSent, deleteLinkedAccountLoader } = auth;
  const [activeStep, setActiveStep] = useState(0);
  const step = [<LinkedAccountRegisterForm />, <LinkedAccountVerify setActiveStep={setActiveStep} linkedAccountUserData={linkedAccountUserData} />];

  const [openDelete, setOpenDelete] = useState(false);
  useEffect(() => {
    if (registerLinkedAccountIsDoneAndSent === true) {
      setActiveStep(1);
    }
  }, [registerLinkedAccountIsDoneAndSent])

  useEffect(() => {
    if (deleteLinkedAccountIsDoneAndSent === true) {
      handleDeleteCloseModal();
      setActiveStep(0)
    }
  }, [deleteLinkedAccountIsDoneAndSent])

  const handleDeleteOpenModal = () => {
    props.removeDeleteLinkedAccountMessage();
    setOpenDelete(true);
  }
  const handleDeleteCloseModal = () => {
    setOpenDelete(false);
  }
  const handleDelete = () => {
    props.deleteLinkedAccount(userData.uid);
  }
  useEffect(() => {
    if (linkedAccountUserData) {
      if (linkedAccountUserData.disabled) {
        setActiveStep(1);
      }
    }
  }, [linkedAccountUserData])
  return (
    <>
      <SimpleDialogBox
        open={openDelete}
        onClose={handleDeleteCloseModal}
        title="Delete linked account"
        shouldTitleAppear={true}
        cancelText="Close"
        bodyWidget={
          <Typography variant='body2'>Are you sure you want to delete?</Typography>
        }
        width='600'
        showPositive
        positiveButtonWidget={<>
          {deleteLinkedAccountLoader ?
            <>
              <Button variant="contained" disabled sx={{
                textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                "&.Mui-disabled": {
                  backgroundColor: '#025196',
                  color: '#FFFFFF'
                }
              }}>
                <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} /><span style={{ paddingLeft: '15px' }}>Deleting...</span>
              </Button>
            </>
            :

            <Button size="medium" variant="contained"
              onClick={handleDelete}
              sx={{
                textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                '&:hover': {
                  backgroundColor: '#025196',
                  color: '#FFFFFF'
                }
              }}>Delete</Button>
          }
        </>}
      />
      <div style={{ height: '100%' }}>
        {linkedAccountUserData?.disabled === false && userData.linkedAccounts && userData.linkedAccounts.length > 0 ? <>
          <div style={{ height: '20%', background: '' }}>
            <Grid container spacing={0}>
              <Grid item xs={7}>
                <Typography variant='body1'>Name: {linkedAccountUserData?.name}</Typography>
                <Typography variant='body1'>Email: {linkedAccountUserData?.email}</Typography>
                <Button size="medium" variant="contained"
                  onClick={handleDeleteOpenModal}
                  sx={{
                    marginTop: '10px',
                    textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                    '&:hover': {
                      backgroundColor: '#025196',
                      color: '#FFFFFF'
                    }
                  }}>Delete linked account</Button>
              </Grid>
              <Grid item xs={5} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                <Box >
                  <Tooltip placement='bottom-end' arrow title={
                    <ul style={{ fontSize: '12px' }}>
                      <li>This account will be used to share work with your employees</li>
                      <li>Use the box below to add employees to your linked account</li>
                      <li>When you provide the name of a new employee a verification code will be displayed</li>
                      <li>Supply this code to your employee, together with the email address and the password that you used to create the linked account</li>
                    </ul>
                  }>
                    <HelpOutlineIcon fontSize='medium' />
                  </Tooltip>
                </Box>
              </Grid>
            </Grid>
          </div>
          <div style={{ height: '80%', background: '', overflow: 'auto', display: 'flex', alignItems: 'start', justifyContent: 'center', marginTop: '10px', }}>
            <AccessCodeTable
              accessManagementData={accessManagementData}
              addAccessCodeIsDoneAndSent={addAccessCodeIsDoneAndSent} addAccessCodeLoader={addAccessCodeLoader}
              accessCodesList={linkedAccountUserData?.accessCodes} userData={userData} addAccessCode={props.addAccessCode}
              updateAccessCodeLoader={updateAccessCodeLoader} updateAccessCodeIsDoneAndSent={updateAccessCodeIsDoneAndSent} updateAccessCode={props.updateAccessCode}
              removeAccessCodeLoader={removeAccessCodeLoader} removeAccessCodeIsDoneAndSent={removeAccessCodeIsDoneAndSent} removeAccessCode={props.removeAccessCode}
            />
          </div>
        </> :
          <>
            <div style={{ height: '20%', background: '', display: 'flex', alignItems: 'center' }}>
              <LinkedAccountSetup activeStep={activeStep} setActiveStep={setActiveStep} />
            </div>
            <div style={{ height: '80%', background: '', }}>
              {step[activeStep]}
            </div></>
        }
      </div >
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    userData: state.firebase.profile,
    linkedAccountUserData: state.firestore.data.linkedAccoundUserData,
    datastore: state.datastore,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addAccessCode: (uid, name, role) => dispatch(addAccessCode(uid, name, role)),
    updateAccessCode: (uid, name, accessCode, role) => dispatch(updateAccessCode(uid, name, accessCode, role)),
    removeAccessCode: (uid, accessCode) => dispatch(removeAccessCode(uid, accessCode)),
    deleteLinkedAccount: (uid) => dispatch(deleteLinkedAccount(uid)),
    removeDeleteLinkedAccountMessage: () => dispatch(removeDeleteLinkedAccountMessage()),
  };
};
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    const { userData } = props;
    return [
      {
        collection: "Users",
        storeAs: "linkedAccoundUserData",
        doc: `${userData.linkedAccounts ? userData?.linkedAccounts[0] ?? `-1` : `-1`}`,
      }
    ];
  })
)(AddLinkedAccount);

// export default connect(mapStateToProps)(AddLinkedAccount);

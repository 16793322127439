import { Box, Button, Card, Grid, IconButton, InputBase } from "@mui/material";
import { useState } from "react";
import { dateConverter } from "../home/components/account/components/helper/Helper";
import OrderDetailsCard from "../home/components/account/subpages/orderHistoryComponents/OrderDetailsCard";
import React from 'react'
import SimpleDialogBox from "./SimpleDialogBox";
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';

const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    // marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(3),
        width: "auto",
    },
    cursor: "pointer",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,

        transition: theme.transitions.create("width"),
        width: "100%",
        // [theme.breakpoints.up("lg")]: {
        //     width: "50ch",
        // },
        // [theme.breakpoints.down("md")]: {
        //     width: "15ch",
        // },
        // [theme.breakpoints.only("md")]: {
        //     width: "15ch",
        // },
        cursor: "pointer",
        display: "flex",
        alignItems: "start",
        justifyContent: "start",
    },
}));
const OrderSearchButton = (props) => {
    const { companyOrdersHistory } = props;
    const [openSearch, setOpenSearch] = useState(false);

    const handleSearchOpen = () => {
        setOpenSearch(true);
    };

    const handleSearchClose = () => {
        setOpenSearch(false);
    };
    return (
        <>
            <SimpleDialogBox
                open={openSearch}
                onClose={handleSearchClose}
                title="Finder"
                shouldTitleAppear={false}
                cancelText="Close"
                bodyWidget={
                    <OrderSearch companyOrdersHistory={companyOrdersHistory} />
                }

                width='600'
            />
            <Search
                onClick={handleSearchOpen}
                sx={{
                    display: { xs: "flex", sm: "flex", md: 'flex' },
                    alignItems: 'center',
                    justifyContent: 'start',
                    backgroundColor: '#f5f1f1',
                    border: '2px solid #e1e3e2',
                    borderRadius: '10px',

                    color: 'grey',
                    '&:hover': {
                        backgroundColor: '#ebedec',
                    },
                }}
            >
                <SearchIconWrapper>
                    <SearchIcon sx={{}} />
                </SearchIconWrapper>
                {/* <div style={{display:'inline-block',marginLeft:'50px'}}>Search</div> */}
                <StyledInputBase
                    type='button'
                    value="Order search..."
                    onClick={handleSearchOpen}
                    placeholder="Search…"
                    sx={{ fontSize: '15px', }}
                    inputProps={{ 'aria-label': 'search' }}
                // onChange={handleChange}
                />
                {/* <ControlChip text={keyShortcut.toUpperCase()} size={12} marginInline={2} /> */}
            </Search>
        </>
    )
}


const OrderSearch = (props) => {

    const cardLightStyle = {
        "border": "0.5px solid grey",
        // border: '1px solid #e1e3e2',
        "borderRadius": "5px",
        "paddingInline": "15px",
        "paddingBlock": '5px',
        "backgroundColor": '#fff',
    }

    const { companyOrdersHistory } = props;
    const [orderIDToFilter, setOrderIDToFilter] = useState("");
    const [filteredOrdersList, setFilteredOrderList] = useState([]);
    const [message, setMessage] = useState("");
    const checkValidSearch = () => {
        return (
            (orderIDToFilter === "" || orderIDToFilter.startsWith(" ")) ===
            false
        );
    };

    const filterBySearch = (s) => {
        return s.orderID
            .toLowerCase()
            .startsWith(orderIDToFilter.toLowerCase());
    };

    const handleChange = (e) => {
        setOrderIDToFilter(e.target.value);
    };
    const filterList = () => {
        const filteredList = Object.values(companyOrdersHistory)
            .filter((eachOrder) => eachOrder.orderId === orderIDToFilter)
            .map((eachOrder, index) => (
                // //////console.log(each)
                {
                itemName: eachOrder.itemName,
                date: dateConverter(eachOrder.timestamp.seconds * 1000).parsedDate,
                orderId: eachOrder.orderId,
            }));
        // //////console.log(orderIDToFilter, "orderIDToFilter", filteredList)
        // //////console.log(filteredList, "list")
        if (filteredList.length <= 0) {
            setMessage('No order found.')
            setFilteredOrderList(filteredList)
        }
        else {
            setFilteredOrderList(filteredList)
        }

    }

    // //////console.log(filteredOrdersList, "filteredOrdersList")
    return (
        <Box>

            <Grid container spacing={0}>
                <Grid item xs={11}>
                    <Card sx={cardLightStyle} elevation={0}>
                        <InputBase
                            fullWidth
                            sx={{ input: { fontSize: '15px', '&::placeholder': { fontSize: '15px' } } }}
                            placeholder="Enter order ID"
                            id="orderIDToFilter"
                            onChange={handleChange}
                            autoFocus
                        />
                    </Card>
                </Grid>
                <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'end' }}>
                    <IconButton aria-label="orderid" disabled={!checkValidSearch()}
                        onClick={filterList}>
                        <SearchIcon />
                    </IconButton>
                </Grid>
            </Grid>

            {filteredOrdersList.length <= 0 ? message === '' ? <></> : <p style={{ marginTop: '10px', marginLeft: '10px', display: 'flex', justifyContent: 'start' }}>{message}</p> : filteredOrdersList?.map((eachOrder, i) => {
                return <OrderDetailsCard orderDetails={eachOrder} key={eachOrder.id} />;
            })}
        </Box>
    );
};

export default OrderSearchButton;

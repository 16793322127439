import * as React from 'react';
import { connect } from "react-redux";
import { useEffect, useState } from 'react';
import { Tooltip, Stack, Box } from '@mui/material';

const SafetyFrameList = ({ safetyData, datastore, playerRef, detections = [], pinned, handlePinned, fps }) => {
    const { defectNames } = safetyData;
    const [videoPlayerState, setVideoPlayerState] = useState(null)

    useEffect(() => {
        if (playerRef) {
            playerRef.current?.subscribeToStateChange(handleBind)
        }
    });

    const handleBind = (state) => {
        setVideoPlayerState(state);
    }

    useEffect(() => {
        return () => {
            if (playerRef.current) {
                playerRef.current = null;
            }
        };
    }, [playerRef]);

    const handlePinning = (e) => {
        handlePinned(e)
        handleSeekTo(Number(e.pred.frameTime).toFixed(3) + (1 / fps))
    }

    const handleSeekTo = (timeSeek) => {
        playerRef.current.seek(timeSeek)
    }

    const getEstimatedFrameNumber = (frameTime, debug = false) => {
        const frameNumber = Math.round(fps * frameTime)
        if (debug) {
            // //////console.log(frameNumber, frameTime, 'getEstimatedFrameNumber')
        }
        return frameNumber
    }

    return (
        <div style={{ overflowX: 'auto', overflowY: 'scroll', width: '100%', height: '100%', display: 'flex', justifyContent: 'center' }}>
            <Box sx={{}}>
                {/* <div style={{ width: '100%', overflow: 'auto', height: '100%', display: 'flex', justifyContent: 'center', padding: '50px' }}> */}
                <Stack spacing={2} sx={{ padding: '0px' }}>
                    {detections.filter((e) => e.frameNumber < getEstimatedFrameNumber(videoPlayerState?.currentTime)).sort((a, b) => b.frameTime - a.frameTime).map((e, i) => {
                        var { frameNumber, defectName } = e;
                        const obj = {
                            pred: e,
                        }
                        // //////console.log('obj==========>',obj.meta)
                        const check = obj.pred.id === pinned?.pred?.id ?? ''
                        const imBase64 = `data:image/png;base64, ${obj?.pred?.cropb64 ?? ''}`;
                        return <Tooltip placement='right' key={obj.pred.id} arrow title={`${defectNames[defectName]} (Frame: ${frameNumber})`} ><img key={obj.pred.id} onClick={() => { handlePinning(obj) }} src={imBase64} width="50px" style={{ aspectRatio: "1", cursor: 'pointer', objectFit: 'cover', border: check ? '3px solid #60EE00' : '', borderRadius: check ? '3px' : '' }} /></Tooltip>
                    })}
                </Stack>
                {/* </div> */}
            </Box>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        datastore: state.datastore,
    };
};
export default connect(mapStateToProps)(SafetyFrameList);



import moment from 'moment'
const DateParser = (dateCreated, doWhat = 0) => {
    const helpMap = {
        "parseFirestoreDate": 0,
        "howAgo": 1,
        "currentDate": 2,
        "parseMillis": 3,
        "parseMillisExtended": 4,
        "parseMillisTime": 5,
        "parseFirestoreDateNewFormat": 6,
        "localTime": 7
    }

    const parseFirestoreDate = () => {
        var parsedDate = new Date(
            dateCreated ? dateCreated.seconds + dateCreated.nanoseconds * 10 ** -9 : ""
        );
        // //////console.log(parsedDate, dateCreated)
        var date = moment(parsedDate * 1000).format("MMM Do, YYYY");
        return date
    }
    const parseFirestoreDateNewFormat = () => {
        var parsedDate = new Date(
            dateCreated ? dateCreated.seconds + dateCreated.nanoseconds * 10 ** -9 : ""
        );
        // //////console.log(parsedDate, dateCreated)
        var date = moment(parsedDate * 1000).format("YYYY-MM-DD");
        return date
    }

    const parseMillisDate = () => {
        var parsedDate = new Date(dateCreated / 1000);
        // //////console.log(parsedDate, dateCreated)
        var date = moment(parsedDate * 1000).format("MMM Do, YYYY");
        return date
    }

    const parseMillisDateTime = () => {
        const parsedDate = new Date(dateCreated / 1000);
        const date = moment(parsedDate * 1000).format("MMM Do, YYYY");
        const time = moment(parsedDate * 1000).format("h:mm:ss A");
        return { date, time };
    };
    const parseMillisTime = () => {
        var parsedDate = new Date(
            dateCreated ? dateCreated.seconds + dateCreated.nanoseconds * 10 ** -9 : ""
        );
        // const parsedDate = new Date(dateCreated / 1000);
        const time = moment(parsedDate * 1000).format("h:mm:ss A");
        return time;
    };

    const currentDate = () => {
        var date = moment().format("MMMM Do YYYY");
        return date;
    }

    const howAgo = () => {
        return moment(dateCreated?.toDate()).fromNow()
    }
    const localTime = () => {
        const localDateAndTime = `${dateCreated}`.split('+')[0];
        const time = localDateAndTime.split('T')[1];
        // var time = moment(dateCreated).format("h:mm:ss A");
        return time;
    }

    if (doWhat === 0) {
        // //////console.log(dateCreated)
        return parseFirestoreDate()
    } else if (doWhat === 1) {
        // //////console.log(dateCreated)
        return howAgo()
    }
    else if (doWhat === 2) {
        // //////console.log(dateCreated)
        return currentDate()
    }
    else if (doWhat === 3) {
        return parseMillisDate()
    }
    else if (doWhat === 4) {
        return parseMillisDateTime()
    }
    else if (doWhat === 5) {
        return parseMillisTime()
    }
    else if (doWhat === 6) {
        return parseFirestoreDateNewFormat()
    }
    else if (doWhat === 7) {
        return localTime()
    }
    else {
        return JSON.stringify(helpMap)
    }
}

export default DateParser;
import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import VisualizerMap from "./components/VisualizerMap";
import { get } from "lodash";
import { removeAddNewAssetErrorAndMessage } from "../../../../store/actions/projectActions";
import VideoJS from "./components/VideoJS";
import TopbarVisualizer from "../../../parents/topbar/TopbarVisualizer";
import MarkerDetails from "./360Components/MarkerDetails";
import { Box, Grid, Card } from "@mui/material";
import GpsEditDashboard from "./360Components/GpsEditDashboard.jsx";


const AfterImageInferenceDashboard = (props) => {
  const {
    datastore,
    drProjectData,
    proid,
    filteredGpsData360,
    option,
    markerDetections,
    segmentIndex,
    markerData,
  } = props;
  const cardWhiteStyle = { borderRadius: "15px", };
  const { assetData, dataInfo } = datastore;

  const videoRef = useRef();
  const playersRef = useRef(null);
  const headerRef = useRef(null);
  const [isMarkerSelected, setIsMarkerSelected] = useState(false);
  const [isMarker, setIsMarker] = useState(false);
  const [pinned, setPinned] = useState(null);
  const [markerInfo, setMarkerInfo] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState(["All"]);
  const [selectedDistance, setSelectedDistance] = useState(drProjectData.distanceInterval ? drProjectData.distanceInterval : 2);
  const filteredMarkerData = everyNthElement(markerData, selectedDistance).filter(item => item !== undefined && item !== null);;
  const filteredMarkerDetections = everyNthElement(markerDetections, selectedDistance).filter(item => item !== undefined && item !== null);;
  const [vidDivDimen, setVidDivDimen] = useState({ height: 1, width: 1 });
  var videoHeight = markerInfo !== null && pinned !== null ? 65 : 100;
  var mapHeight = markerInfo !== null && pinned !== null ? 35 : 0;
  let assetVideoPlayerPadding;
  const overallCustomBorderPadding = (assetVideoPlayerPadding = 16);
  const realFps = "fps" in drProjectData ? drProjectData.fps : 23.976023976023978;
  const fps = Number(realFps).toFixed(0);
  const headerHeight = headerRef?.current?.clientHeight ?? 0;
  const [currentTime, setCurrentTime] = useState(0);
  const [previousTime, setPreviousTime] = useState(null);
  const [nearestValues, setNearestValues] = useState(null);
  const [categoryDetails, setCategoryDetails] = useState([{
    "categoryId": "All",
    "name": "All"
  }]);
  const [openEditGpsDialog, setOpenEditGpsDialog] = useState(false);
  useEffect(() => {
    if (isMarker) {
      if (markerInfo) {
        const currentMakerData = Object.values(filteredMarkerDetections)[pinned];
        const diff = (currentMakerData.diff) / 1000;
        setMarkerInfo(currentMakerData);
        setCurrentTime(diff);
        setIsMarkerSelected(true);
      }
    }
  }, [isMarker, markerInfo, filteredMarkerDetections, pinned]);

  useEffect(() => {
    if (currentTime !== previousTime && !isMarkerSelected) {
      const calculateDifference = (diff) => Math.abs(currentTime - diff / 1000);
      let minDifference = Infinity;
      let nearestEntry = null;
      if (filteredMarkerData) {
        filteredMarkerData.forEach((entry) => {
          const diff = entry.diff; // 24800
          const difference = calculateDifference(diff);
          if (difference < minDifference) {
            minDifference = difference;
            nearestEntry = entry;
          }
        });
      }

      if (nearestEntry) {
        const { lat, lng } = nearestEntry;
        setNearestValues({ lat, lng });
      }
      setPreviousTime(currentTime);
    }
  }, [currentTime, isMarkerSelected, previousTime, filteredMarkerData]);

  useEffect(() => {
    window.addEventListener("load", setVidDivDimen(videoRef?.current === null ? 0 : videoRef?.current?.clientHeight))
    window.addEventListener("resize", getVidWindowSize, true);
  }, [videoHeight, openEditGpsDialog]);

  const handleValueChange = (currentTime) => {
    setCurrentTime(currentTime);
  }
  const handleFrameClose = () => {
    setIsMarker(false);
    setIsMarkerSelected(false);
    setMarkerInfo(null);
    setPinned(null);
    videoHeight = 100;
    mapHeight = 0;
    const player = playersRef.current;
    if (player) {
      player.pause();
      player.controls(true);
    }
  };
  function everyNthElement(inputArray, n) {
    const result = new Array(Math.ceil(inputArray.length / n));
    for (let i = n - 1, j = 0; i < inputArray.length; i += n, j++) {
      result[j] = inputArray[i];
    }
    return result;
  }
  const handlePlayerReady = ((player) => {
    player.on("waiting", () => { });
    player.on("dispose", () => { });
  });

  const handleMapFrameChange = (path, index) => {
    const { lat, lng, diff } = path;
    setIsMarker(true);
    setPinned(index);
    setMarkerInfo(path);
    const player = playersRef.current;
    if (player) {
      player.currentTime(diff / 1000);
      player.pause();
      player.controls(false);
    }
    setNearestValues({ lat, lng });
  };
  const handleFrameClick = (currentTime, sectionId) => {
    const player = playersRef.current;
    setCurrentTime(currentTime);
    setIsMarkerSelected(true);
    if (player) {
      player.currentTime(currentTime);
    }
  };

  const getVidWindowSize = () => {
    const newHeight = videoRef?.current?.clientHeight;
    const newWidth = videoRef?.current?.clientWidth;
    setVidDivDimen({
      height: newHeight,
      width: newWidth,
    });
  };

  return (
    <>
      {openEditGpsDialog ?
        <GpsEditDashboard
          setOpenEditGpsDialog={setOpenEditGpsDialog}
          overallCustomBorderPadding={overallCustomBorderPadding}
          assetVideoPlayerPadding={assetVideoPlayerPadding}
          cardWhiteStyle={cardWhiteStyle}
          proid={proid}
          filteredGpsData360={filteredGpsData360}
          drProjectData={drProjectData}
        />
        : <div
          style={{ height: "100vh", width: "100vw", backgroundColor: "#E1E3E2" }}
        >
          <Grid container spacing={0}>
            <Grid item xs={6}
              sx={{
                backgroundColor: "transparent",
                height: `calc(100vh - ${0}px)`,
              }}
            >
              {/* Topbar */}
              <div
                ref={headerRef}
                style={{
                  padding: `${overallCustomBorderPadding}px 0px ${overallCustomBorderPadding}px ${overallCustomBorderPadding}px`,
                  backgroundColor: "",
                }}
              >
                <TopbarVisualizer
                  proid={proid}
                  assetData={assetData}
                  dataInfo={dataInfo}
                  drProjectData={drProjectData}
                  selectedDistance={selectedDistance}
                  setSelectedDistance={setSelectedDistance}
                  setCategoryDetails={setCategoryDetails}
                  selectedCategories={selectedCategories}
                  setSelectedCategories={setSelectedCategories}
                  filteredGpsData360={filteredGpsData360}
                  setOpenEditGpsDialog={setOpenEditGpsDialog}
                />
              </div>
              {/* Map */}
              <div
                style={{
                  height: `calc(100vh - ${headerHeight}px)`,
                  backgroundColor: "",
                }}
              >
                <div
                  style={{
                    height: `calc(100vh  - ${headerHeight}px - ${overallCustomBorderPadding}px)`,
                    paddingLeft: `${overallCustomBorderPadding}px`,
                    paddingBottom: `${overallCustomBorderPadding}px`,
                    backgroundColor: "",
                  }}
                >
                  <Card
                    elevation={4}
                    sx={{
                      backgroundColor: "",
                      height: `calc(100vh  - ${headerHeight}px - ${overallCustomBorderPadding}px)`,
                    }}
                  >
                    <VisualizerMap
                      selectedCategories={selectedCategories}
                      nearestValues={nearestValues}
                      filteredGpsData360={filteredGpsData360}
                      currentTime={currentTime}
                      markerDetections={filteredMarkerDetections}
                      handleFrameClick={handleFrameClick}
                      handleMapFrameChange={handleMapFrameChange}
                    />
                  </Card>
                </div>
              </div>
            </Grid>
            <Grid item xs={6}
              sx={{
                backgroundColor: "",
                height: "100%",
              }}
            >

              <div
                id="asset-player"
                style={{
                  padding: markerInfo !== null && pinned !== null ? `${assetVideoPlayerPadding}px` : `${assetVideoPlayerPadding}px ${assetVideoPlayerPadding}px 0px ${assetVideoPlayerPadding}px `,
                  height: `calc(${videoHeight}vh - ${assetVideoPlayerPadding}px)`,
                }}
              >

                <div ref={videoRef} style={{ height: '100%', backgroundColor: '' }}>
                  <Card elevation={4} sx={{
                    ...cardWhiteStyle,
                  }}>
                    <VideoJS
                      drProjectData={drProjectData}
                      segmentIndex={segmentIndex}
                      cusHeight={videoRef.current === null ? 0 : videoRef?.current?.clientHeight}
                      fps={fps}
                      options={option}
                      onReady={handlePlayerReady}
                      onValueChange={handleValueChange}
                      playerRef={playersRef}
                      currentTime={currentTime}
                      isMarkerSelected={isMarkerSelected}
                      cusKey={segmentIndex}
                    />
                  </Card>
                </div>
              </div>

              <Box
                id="asset-map"
                sx={{ padding: `${0}px ${overallCustomBorderPadding}px ${0}px ${overallCustomBorderPadding}px`, width: "100%", }}
              >
                <Card elevation={4} sx={{
                  ...cardWhiteStyle,
                  height: `calc(${mapHeight}vh - ${0}px)`,
                }}>
                  <div style={{ height: '100%' }}>
                    {pinned !== null && markerInfo !== null ? (
                      <div style={{ height: "100%", backgroundColor: "#FAFAFA", }}>
                        <MarkerDetails
                          handleFrameClose={handleFrameClose}
                          pinned={pinned}
                          markerInfo={markerInfo}
                          proid={proid}
                          categoryDetails={categoryDetails}
                          setCategoryDetails={setCategoryDetails}
                          playerRef={playersRef}
                        />
                      </div>
                    ) :
                      <></>
                    }
                  </div>
                </Card>
              </Box>
            </Grid >
          </Grid >
        </div >}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { proid } = ownProps;
  return {
    datastore: state.datastore,
    drProjectData: get(state.firestore.data, `DrProject.${proid}`),
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    removeAddNewAssetErrorAndMessage: () =>
      dispatch(removeAddNewAssetErrorAndMessage()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AfterImageInferenceDashboard);
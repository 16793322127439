import React from 'react';
import { Container, Toolbar } from '@mui/material';
import AccessControlAccountError from '../cidgets/AccessControlAccountError';
import { useAccessControlContext } from '../../contexts/AccessControlProvider';
import { useNetworkContext } from '../../contexts/NetworkProvider';
import AllNetwork from './components/network/AllNetwork';

const NetworkDashboard = (props) => {
    const { isNetworkView } = useNetworkContext();
    const { permissions, role } = useAccessControlContext();
    const { canSeeAllProjects } = permissions;
    const { appBarHeight, companyData } = props;
    return (
        <>
            {companyData ? <Container maxWidth="xl">
                <Toolbar />
                {!canSeeAllProjects ? <AccessControlAccountError access={role} /> : <AllNetwork />}
            </Container> : <></>}
        </>
    )
}

export default (NetworkDashboard);
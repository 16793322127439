import { Card, Grid, MenuItem, Paper, TextField, Typography } from '@mui/material';
import React from 'react'
import { styled } from '@mui/material/styles';

const SafetyAddNewFields = ({ fieldValues, handleChange, safetyDataInfo }) => {
    const allowedVegetationProblemsType = ['0'];
    const allowedCarriagewayDefectsType = ['1'];
    const allowedDepthProperty = ['1'];

    const fieldStyle = {
        "border": "2px solid grey",
        "borderRadius": "5px",
        "padding": '5px',
        "fontSize": '0.8rem',
        "textTransform": 'capitalize',
        "overflow": 'auto',
    }
    const divFieldStyles = {
        "border": '1px solid rgba(0, 0, 0, 0.23)',
        "borderRadius": "4px",
        "padding": '10px 8px',
        "fontSize": '0.95rem',
        "textTransform": 'capitalize',
        "overflow": 'auto',
        // "backgroundColor": '#f5f5f5',
        "color": 'rgba(0,0,0,1)',

    };
    const Item = styled(Paper)(({ theme }) => ({
        boxShadow: '0px 0px transparent',
        width: '100%',
        backgroundColor: 'transparent',
        ...theme.typography.body2,
        padding: theme.spacing(0.5),
    }));

    const sideMap = {
        "map": (() => {
            const usefulKeys = ['0', '1'];
            return usefulKeys.reduce((result, key) => {
                result[key] = safetyDataInfo['side'][key]['name'];
                return result;
            }, {});
        })(),
        "keys": Object.keys(safetyDataInfo['side']),
        "values": Object.values(safetyDataInfo['side']).map(value => value.name),
        "usefulKeys": ['0', '1'],
    }
    const safetyClassesMap = {
        "map": (() => {
            const usefulKeys = ['0', '1'];
            return usefulKeys.reduce((result, key) => {
                result[key] = safetyDataInfo['defectName'][key]['name'];
                return result;
            }, {});
        })(),
        "keys": Object.keys(safetyDataInfo['defectName']),
        "values": Object.values(safetyDataInfo['defectName']).map(value => value.name),
        "usefulKeys": ['0', '1'],
        // 'safetyClassesWhereNameWillNotShow': ['0', '2', '3', '4', '998', '999'],
        // "notTrafficSign": ['0', '2', '3', '4', '998', '999'],
        "trafficSign": ['2'],
    }

    const lateralPositionMap = {
        "map": (() => {
            const usefulKeys = ['0', '1', '2', '3', '4', '5'];
            return usefulKeys.reduce((result, key) => {
                result[key] = safetyDataInfo['lateralPosition'][key]['name'];
                return result;
            }, {});
        })(),
        "keys": Object.keys(safetyDataInfo['lateralPosition']),
        "values": Object.values(safetyDataInfo['lateralPosition']).map(value => value.name),
        "usefulKeys": ['0', '1', '2', '3', '4', '5'],
    }
    const typeMap = {
        "map": (() => {
            const usefulKeys = ['0', '1'];
            return usefulKeys.reduce((result, key) => {
                result[key] = safetyDataInfo['type'][key]['name'];
                return result;
            }, {});
        })(),
        "keys": Object.keys(safetyDataInfo['type']),
        "values": Object.values(safetyDataInfo['type']).map(value => value.name),
        "usefulKeys": ['0', '1'],
    }
    const lateralPositionClasses = lateralPositionMap.keys.filter(e => lateralPositionMap.usefulKeys.includes(e)).map((e, i) => {
        return <MenuItem key={e} value={e} sx={{ textTransform: 'capitalize' }}>
            {`${lateralPositionMap.values[i]}`.replace('_', ' ')}
        </MenuItem>
    })
    const typeClasses = typeMap.keys.filter(e => typeMap.usefulKeys.includes(e)).map((e, i) => {
        return <MenuItem key={e} value={e} sx={{ textTransform: 'capitalize' }}>
            {`${typeMap.values[i]}`.replace('_', ' ')}
        </MenuItem>
    })
    const sideClasses = sideMap.keys.filter(e => sideMap.usefulKeys.includes(e)).map((e, i) => {
        return <MenuItem key={e} value={e} sx={{ textTransform: 'capitalize' }}>
            {`${sideMap.values[i]}`.replace('_', ' ')}
        </MenuItem>
    })
    const safetyClasses = safetyClassesMap.keys.filter(e => safetyClassesMap.usefulKeys.includes(e)).map((e, i) => {
        return <MenuItem key={e} value={e} sx={{ textTransform: 'capitalize' }}>
            {`${safetyClassesMap.values[i]}`.replace('_', ' ')}
        </MenuItem>
    })
    return (
        <div style={{ backgroundColor: 'transparent', overflow: 'auto' }}>
            <Item>
                <Grid container>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                    }} >
                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                            Feature category:
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <TextField
                            id="asset class"
                            select
                            value={fieldValues.safetyClassState}
                            onChange={handleChange("safetyClassState")}
                            size="small"
                            sx={{ overflow: 'auto', textTransform: 'capitalize', width: '100%' }}
                        >
                            {safetyClasses}
                        </TextField>
                    </Grid>
                </Grid>
            </Item>
            {allowedVegetationProblemsType.includes(`${fieldValues.safetyClassState}`) ?
                <Item>
                    <Grid container>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                        }} >
                            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                Side:
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <TextField
                                id="side class"
                                select
                                value={fieldValues.sideState}
                                onChange={handleChange("sideState")}
                                size="small"
                                sx={{ overflow: 'auto', textTransform: 'capitalize', width: '100%' }}
                            >
                                {sideClasses}
                            </TextField>
                        </Grid>
                    </Grid>
                </Item>
                : <></>}
            {allowedVegetationProblemsType.includes(`${fieldValues.safetyClassState}`) ?
                <Item>
                    <Grid container>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                        }} >
                            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                Clearance height (m):
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <div style={divFieldStyles}>
                                <Typography variant="inherit">
                                    {fieldValues.clearanceHeightState}
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                </Item>
                : <></>}
            {allowedVegetationProblemsType.includes(`${fieldValues.safetyClassState}`) ?
                <Item>
                    <Grid container>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                        }} >
                            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                Distance from the lane edge (m):
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <div style={divFieldStyles}>
                                <Typography variant="inherit">
                                    {fieldValues.distanceFromTheLaneEdgeState}
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                </Item>
                : <></>}
            {allowedCarriagewayDefectsType.includes(`${fieldValues.safetyClassState}`) ?
                <Item>
                    <Grid container>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                        }} >
                            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                Type:
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <TextField
                                id="type"
                                select
                                value={fieldValues.typeState}
                                onChange={handleChange("typeState")}
                                size="small"
                                sx={{ overflow: 'auto', textTransform: 'capitalize', width: '100%' }}
                            >
                                {typeClasses}
                            </TextField>
                        </Grid>
                    </Grid>
                </Item>
                : <></>}
            {allowedCarriagewayDefectsType.includes(`${fieldValues.safetyClassState}`) ?
                <Item>
                    <Grid container>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                        }} >
                            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                Lateral position:
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <TextField
                                id="type"
                                select
                                value={fieldValues.lateralPositionState}
                                onChange={handleChange("lateralPositionState")}
                                size="small"
                                sx={{ overflow: 'auto', textTransform: 'capitalize', width: '100%' }}
                            >
                                {lateralPositionClasses}
                            </TextField>
                        </Grid>
                    </Grid>
                </Item>
                : <></>}

            {allowedCarriagewayDefectsType.includes(`${fieldValues.safetyClassState}`) ?
                allowedDepthProperty.includes(`${fieldValues.typeState}`) ?
                    <Item>
                        <Grid container>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                            }} >
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                    Depth (m):
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <div style={divFieldStyles}>
                                    <Typography variant="inherit">
                                        {fieldValues.depthState}
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                    </Item>
                    : <></>
                : <></>}

            {allowedCarriagewayDefectsType.includes(`${fieldValues.safetyClassState}`) ?
                <Item>
                    <Grid container>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                        }} >
                            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                Length (m):
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <div style={divFieldStyles}>
                                <Typography variant="inherit">
                                    {fieldValues.lengthState}
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                </Item>
                : <></>}
            {allowedCarriagewayDefectsType.includes(`${fieldValues.safetyClassState}`) ?
                <Item>
                    <Grid container>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                        }} >
                            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                Width (m):
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <div style={divFieldStyles}>
                                <Typography variant="inherit">
                                    {fieldValues.widthState}
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                </Item>
                : <></>}

            <Item>
                <Grid container>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}
                        sx={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                        }} >
                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                            GPS location:
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}  >
                        <div style={divFieldStyles}>
                            <Typography variant="inherit">
                                {fieldValues.gpsCoordinatesState}
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </Item>
        </div>
    )
}

export default SafetyAddNewFields
import { List, ListItem, ListItemIcon, Tooltip, Typography, Button, CircularProgress } from "@mui/material";
import { Home, Settings } from "@mui/icons-material";
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import SimpleDialogBox from '../../../../cidgets/SimpleDialogBox';
import NetworkSetting from './defectsListView/NetworkSetting';
import { connect } from 'react-redux';
import { clearSetupNetworkAndMsg, setupNetwork } from '../../../../../store/actions/networkActions';
import Overview from './Overview';
import AssessmentIcon from '@mui/icons-material/Assessment';
import RouteIcon from '@mui/icons-material/Route';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';

const Sidebar = (props) => {
    const { showList, setShowList, drNetworkData, netid, network, allNetworkDefects, superSections, conditionsList, conditionMethod,
        handleConditionMethodChange } = props;
    const { setupNetworkLoader, setupNetworkMessage, setupNetworkIsDoneAndSent } = network;
    let navigate = useNavigate();

    const [openDialog, setOpenDialog] = useState(false);
    const [openOverviewDialog, setOpenOverviewDialog] = useState(false);
    const [showProjectAlert, setShowProjectAlert] = useState(false);
    const [selectedProjectList, setSelectedProjectList] = useState([]);
    const [projectList, setProjectList] = useState([]); const [showSuperSectionAlert, setShowSuperSectionAlert] = useState(false);
    const [superSectionList, setSuperSectionList] = useState([]);
    
    useEffect(() => {
        if (drNetworkData && superSections) {
            if (drNetworkData.associatedSuperSections) {
                const superSectionList = superSections.filter(ss => drNetworkData.associatedSuperSections.includes(ss.id));
                setSuperSectionList(superSectionList);
            }
        }
    }, [drNetworkData, superSections])
    useEffect(() => {
        if (setupNetworkIsDoneAndSent === true) {
            handleCloseDialog();
        }
    }, [setupNetworkIsDoneAndSent])

    const handleOpenDialog = () => {
        props.clearSetupNetworkAndMsg();
        setOpenDialog(true);
    }
    const handleCloseDialog = () => {
        setShowProjectAlert(false);
        setSelectedProjectList([]);
        setProjectList([]);
        setShowSuperSectionAlert(false);
        setOpenDialog(false);
    }
    const handleOpenOverViewDialog = () => {
        setOpenOverviewDialog(true);
    }
    const handleCloseOverviewDialog = () => {
        setOpenOverviewDialog(false);
    }
    const handleNetwork = () => {
        setShowProjectAlert(false);
        setShowSuperSectionAlert(false);

        if (superSectionList.length <= 0 && selectedProjectList.length <= 0) {
            setShowProjectAlert(true);
            setShowSuperSectionAlert(true);
        }
        else if (superSectionList.length <= 0) {
            setShowSuperSectionAlert(true);
        }
        else if (selectedProjectList.length <= 0) {
            setShowProjectAlert(true);
        }
        else {
            const ssList = superSectionList.map((each) => each.superSectionId);
            const proidList = selectedProjectList.map((each) => each.proid);
            props.setupNetwork(netid, ssList, proidList);
        }
    }
    const handleNavigate = () => {
        navigate('/home/dashboard-network');
        handleCloseDialog();
    }
    const handleNavigateSS = () => {
        navigate('/home/super-section', { state: { selectedNetworkId: netid } });
        handleCloseDialog();
    }
    return (
        <>
            <SimpleDialogBox
                open={openDialog}
                onClose={handleCloseDialog}
                title={"Network setting"}
                cancelText="Close"
                bodyWidget={
                    <NetworkSetting drNetworkData={drNetworkData}
                        showProjectAlert={showProjectAlert} setShowProjectAlert={setShowProjectAlert}
                        selectedProjectList={selectedProjectList} setSelectedProjectList={setSelectedProjectList}
                        projectList={projectList} setProjectList={setProjectList}
                        showSuperSectionAlert={showSuperSectionAlert} setShowSuperSectionAlert={setShowSuperSectionAlert}
                        superSectionList={superSectionList} setSuperSectionList={setSuperSectionList}
                        conditionsList={conditionsList}
                        conditionMethod={conditionMethod}
                        handleConditionMethodChange={handleConditionMethodChange}
                    />
                }
                width={900}
                showError={setupNetworkIsDoneAndSent === true ? false : true}
                errorMessage={setupNetworkIsDoneAndSent === true ? "" : setupNetworkMessage}
                showPositive={!drNetworkData.recallibrating}
                positiveButtonWidget={
                    <>
                        {setupNetworkLoader === true ? (
                            <>
                                <Button
                                    variant="contained"
                                    disabled
                                    sx={{
                                        "&.Mui-disabled": {
                                            backgroundColor: "#025196",
                                            color: "#ffffff",
                                        },
                                        marginInline: "2px",
                                        textTransform: "initial",
                                        color: "#025196",
                                        backgroundColor: "#025196",
                                        ":hover": {
                                            backgroundColor: "#025196",
                                        },
                                    }}
                                >
                                    <CircularProgress
                                        variant="indeterminate"
                                        size={15}
                                        sx={{ color: "white" }}
                                    />
                                    <span style={{ paddingLeft: "15px" }}>
                                        Processing...
                                    </span>
                                </Button>
                            </>
                        ) : (
                            <Button
                                variant="contained"
                                sx={{
                                    marginInline: "2px",
                                    textTransform: "initial",
                                    backgroundColor: "#025196",
                                    ":hover": {
                                        backgroundColor: "#025196",
                                    },
                                }}
                                onClick={handleNetwork}
                            >
                                Process
                            </Button>
                        )}
                        {superSections ? <></> : < Button
                            variant="contained"
                            sx={{

                                whiteSpace: 'nowrap',
                                marginInline: "10px",
                                textTransform: "initial",
                                backgroundColor: "#025196",
                                ":hover": {
                                    backgroundColor: "#025196",
                                },
                            }}
                            onClick={handleNavigate}
                        >
                            Supersections
                        </Button>}
                    </>
                }
            />
            <SimpleDialogBox
                open={openOverviewDialog}
                onClose={handleCloseOverviewDialog}
                title={"Overview"}
                cancelText="Close"
                bodyWidget={
                    <Overview allNetworkDefects={allNetworkDefects} />
                }
                width={800}
            />
            <div style={{ width: 80, height: "100vh", position: "fixed", top: 0, left: 0, backgroundColor: "#fff", boxShadow: "2px 0 5px rgba(0,0,0,0.1)", zIndex: 1000 }}>
                <List>
                    <Tooltip title="Go back to network dashboard" arrow placement="right">
                        <ListItem
                            onClick={handleNavigate}
                            sx={{
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                py: 2,
                                cursor: 'pointer',
                            }}
                        >
                            <ListItemIcon sx={{ minWidth: 'auto', justifyContent: 'center' }}>
                                <Home />
                            </ListItemIcon>
                            <Typography variant="caption" sx={{ mt: 0.5, color: "#3c4043" }}>Dashboard</Typography>
                        </ListItem>
                    </Tooltip>
                    <Tooltip title="Supersection" arrow placement="right">
                        <ListItem
                            onClick={handleNavigateSS}
                            sx={{
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                py: 2,
                                cursor: 'pointer',

                            }}
                        >
                            <ListItemIcon sx={{ minWidth: 'auto', justifyContent: 'center' }}>
                                <RouteIcon />
                            </ListItemIcon>
                            <Typography variant="caption" sx={{ mt: 0.5, color: "#3c4043" }}>Supersection</Typography>
                        </ListItem>
                    </Tooltip>
                    <Tooltip title={"Settings"} arrow placement="right">
                        <ListItem
                            onClick={handleOpenDialog}
                            sx={{
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                py: 2,
                                cursor: 'pointer',
                            }}
                        >
                            <ListItemIcon sx={{ minWidth: 'auto', justifyContent: 'center' }}>
                                <Settings />
                            </ListItemIcon>
                            <Typography variant="caption" sx={{ mt: 0.5, color: "#3c4043" }}>Settings</Typography>
                        </ListItem>
                    </Tooltip>
                    <Tooltip title={"Overview"} arrow placement="right">
                        <ListItem
                            onClick={handleOpenOverViewDialog}
                            sx={{
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                py: 2,
                                cursor: 'pointer',
                            }}
                        >
                            <ListItemIcon sx={{ minWidth: 'auto', justifyContent: 'center' }}>
                                <AssessmentIcon />
                            </ListItemIcon>
                            <Typography variant="caption" sx={{ mt: 0.5, color: "#3c4043" }}>Overview</Typography>
                        </ListItem>
                    </Tooltip>

                    <Tooltip title={showList ? "Hide supersections list" : "Show supersections list"} arrow placement="right">
                        <ListItem
                            onClick={() => { setShowList(!showList) }}
                            sx={{
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                py: 2,
                                cursor: 'pointer',
                            }}
                        >
                            <ListItemIcon sx={{ minWidth: 'auto', justifyContent: 'center' }}>
                                {showList ? <FilterListOffIcon /> : <FormatListBulletedIcon />}
                            </ListItemIcon>
                            <Typography variant="caption" sx={{ mt: 0.5, color: "#3c4043" }}>List</Typography>
                        </ListItem>
                    </Tooltip>

                </List>
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        network: state.network,
        superSections: state.firestore.ordered.SuperSections,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setupNetwork: (networkId, ssList, proidList) => dispatch(setupNetwork(networkId, ssList, proidList)),
        clearSetupNetworkAndMsg: () => dispatch(clearSetupNetworkAndMsg()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
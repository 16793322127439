import { Button, Card, ListItem, ListItemButton, ListItemText, Tooltip, Typography, styled, tooltipClasses } from "@mui/material";
import SimpleDialogBox from "../../../../../cidgets/SimpleDialogBox";
import { useState, useEffect, useMemo } from "react";
import { archiveBasicImagingFeature } from "../../../../../../store/actions/projectActions";
import { connect } from "react-redux";
import CustomTooltip from "../../../../../cidgets/CustomTooltip";
import { GlobalHotKeys } from "react-hotkeys";
import SmartUtils from "../../../../../../helpers/SmartUtils";
import { storage } from '../../../../../../config/firebaseConfig';
const ArchivedBasicImagingViewer = ({ basicImagingData = [], detections = [], archiveBasicImagingFeature }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [archivedDefectImageURLs, setArchivedDefectImageURLs] = useState([]);
    const handleOpen = () => {
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const preventDefaultHandlers = (handlers) => {
        const newHandlers = {};
        for (const [action, handler] of Object.entries(handlers)) {
            newHandlers[action] = (event) => {
                if (event) {
                    event.preventDefault();
                }
                handler();
            };
        }
        return newHandlers;
    };
    const keyShortcut = 'b'
    const keyMap = {
        BIN_MAC: "command+" + keyShortcut,
        BIN_WIN: "ctrl+" + keyShortcut,
    };

    const handlers = preventDefaultHandlers({
        BIN_MAC: handleOpen,
        BIN_WIN: handleOpen,
    });

    let archivedDetections = useMemo(() => {
        return detections.filter((eachDetection) => {
            return eachDetection.archived === true
        })
    }, [detections])
    const su = new SmartUtils()
    const [os, setOs] = useState({})

    useEffect(() => {
        const fetchData = async () => {
            const data = await su._checkOS(navigator);
            setOs(data);
        }

        fetchData()
    }, [])
    useEffect(() => {
        const generateDefectImageURLs = async () => {

            const urls = {};
            await Promise.all(archivedDetections.map(async defect => {
                const { defectIdNum, defectImagePath } = defect;
                try {
                    const storageRef = storage.ref(defectImagePath);
                    const downloadURL = await storageRef.getDownloadURL();
                    urls[defectIdNum] = downloadURL;
                } catch (error) {
                    console.error(`Error fetching download URL for defect with ID ${defectIdNum}:`, error);
                    urls[defectIdNum] = null;
                }
            }));
            setArchivedDefectImageURLs(urls);
        };

        generateDefectImageURLs();
    }, [archivedDetections]);
    const { ctrl } = su._platformKeys(os?.platform)

    return (
        <>
            <GlobalHotKeys keyMap={keyMap} handlers={handlers}></GlobalHotKeys>
            <SimpleDialogBox
                open={openDialog}
                onClose={handleClose}
                title="Archived features"
                cancelText="Close"
                desc={archivedDetections.length > 0 ? "Unarchive an feature by clicking on it" : "There is nothing here!"}
                bodyWidget={
                    <ArchivedFeatures archiveBasicImagingFeature={archiveBasicImagingFeature} archivedDefectImageURLs={archivedDefectImageURLs} basicImagingData={basicImagingData} archivedDetections={archivedDetections} />
                }
                width='600'
            />
            <Tooltip title={`Or use ${ctrl.name}${ctrl.plus}B to view bin`} placement="left" arrow><Button onClick={handleOpen} sx={{ marginRight: '10px', textTransform: 'none', color: '#1976D2', fontWeight: '700' }}>Feature archive</Button></Tooltip>
        </>
    );
}

const ArchivedFeatures = (props) => {
    const { basicImagingData, archivedDetections = [], archiveBasicImagingFeature, archivedDefectImageURLs } = props;
    const { defectNames } = basicImagingData;
    const cardStyle = {
        "border": "0.5px solid grey",
        "borderRadius": "8px",
        "backgroundColor": '#fff',
        "marginBlock": '10px',
        "marginTop": '20px',
    }

    const handleClick = (did) => {
        archiveBasicImagingFeature(did, false);
    }
    return (
        <div>
            {archivedDetections.map((e, i) => {

                const obj = {
                    pred: e,
                }
                const imBase64 = `data:image/png;base64, ${obj?.pred?.cropb64 ?? ''}`;
                return <Card key={obj.pred.id} sx={cardStyle} elevation={0}>
                    <ListItem
                        dense
                        disablePadding
                        sx={{ paddingBlock: '5px' }}
                        secondaryAction={
                            <>
                                <CustomTooltip
                                    placement="right"
                                    arrow
                                    title={
                                        <Card elevation={10}>
                                            <img src={archivedDefectImageURLs[obj.pred.defectIdNum]} width="100px" style={{ maxHeight: '200px' }} />
                                        </Card>
                                    }
                                    widget={<img src={archivedDefectImageURLs[obj.pred.defectIdNum]} height={50} width={50} style={{ objectFit: 'cover' }} />}
                                />

                            </>
                        }
                    >
                        <ListItemButton
                            title={`Unarchive ${defectNames[obj.pred.defectName]}`}
                            onClick={() => { handleClick(e.did) }}
                        >
                            <ListItemText
                                primary={
                                    <>
                                        <Typography
                                            variant="p"
                                            sx={{ fontWeight: 'bold' }}
                                        >{i + 1}. {defectNames[obj.pred.defectName]}</Typography>
                                    </>
                                }
                                secondary={
                                    <span>
                                        GPS: ({obj.pred.geo[0]}, {obj.pred.geo[1]})
                                    </span>
                                }
                            />
                        </ListItemButton>
                    </ListItem>
                </Card>
            })}
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        datastore: state.datastore,
        project: state.project,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        archiveBasicImagingFeature: (did, archive) => dispatch(archiveBasicImagingFeature(did, archive)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ArchivedBasicImagingViewer);
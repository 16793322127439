import React from 'react'
import { Route, Routes } from 'react-router-dom';
import * as ROUTES from '../../../constants/routes'
import Privacy from '../../general/Privacy';
import Terms from '../../general/Terms';
import ContactSupport from '../../general/ContactSupport';
const GeneralController = (props) => {
  const { appBarHeight, isSignedIn,appBarHeightSignedOut } = props;
  return (
    <div>
      <Routes>
        <Route path={ROUTES.MANUAL} element={<></>} />
        <Route path={ROUTES.CONTACT} element={<ContactSupport appBarHeight={appBarHeight} isSignedIn={isSignedIn} appBarHeightSignedOut={appBarHeightSignedOut} />} />
        <Route path={ROUTES.PRIVACY_DATA} element={<Privacy />} />
        <Route path={ROUTES.TERMS} element={<Terms />} />
        <Route path={ROUTES.ABOUT} element={<></>} />
      </Routes>
    </div>
  );
}

export default GeneralController
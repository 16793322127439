import React from 'react'
import SetupNetwork from '../SetupNetwork'
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { get } from 'lodash';
import AfterCallibratingDashboard from './AfterCallibratingDashboard';
import CustomProgress from '../../../../cidgets/CustomProgress';
const NetworkDashboardController = (props) => {
    const { netid, drNetworkData } = props;
    if (!drNetworkData) {
        return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CustomProgress text={"Loading network"} />
        </div>
    }
    const associatedProjects = drNetworkData.associatedProjects ?? [];
    const associatedSuperSections = drNetworkData.associatedSuperSections ?? [];
    return (
        <>
            {associatedProjects.length <= 0 && associatedSuperSections.length <= 0 ?
                <SetupNetwork drNetworkData={drNetworkData} netid={netid} /> :
                <AfterCallibratingDashboard drNetworkData={drNetworkData} netid={netid}/>
            }
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    const { netid } = ownProps;
    return {
        userData: state.firebase.profile,
        drNetworkData: get(
            state.firestore.data,
            `DrNetwork.${netid}`
        ),
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect((props) => {
        const { netid, userData } = props;
        return [
            {
                collection: "DatabaseInstances",
                where: [['dbInstanceId', '==', netid], ['coid', '==', userData?.coid]],
                storeAs: "DrNetwork",
            }
        ];
    })
)(NetworkDashboardController);

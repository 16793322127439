import React from 'react'
import LoginForm from '../forms/LoginForm';
import { connect } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import * as ROUTES from '../../../../constants/routes';
import Grid from '@mui/material/Grid'
import { Navigate } from 'react-router-dom';
import { Box, Container } from '@mui/material';

const LoginPage = (props) => {
    const { isSignedIn, themeRed, isEmailVerified } = props;
    const { authPageStyle } = themeRed;
    const location = useLocation()

    if (isSignedIn && !isEmailVerified) {
        return <Navigate to="/a/verify" state={{ from: location }} replace />;
    }
    else {
        return (
            // <Container sx={{paddingInline: {xs: 5, sm: 5, md: 20, lg: 40} }}>
            //     <Box sx={{
            //         display: 'flex', justifyContent: 'center', alignItems: 'center', height: `calc(100vh - ${props.appBarHeightSignedOut}px)`, backgroundColor: '#FAFAFA'
            //     }}>
            //         <LoginForm />
            //     </Box>
            // </Container>

            <Container>
            <Grid
              container
              // spacing={5}
              // rowSpacing={100}
              columnSpacing={17}
              direction="row"
              sx={{ height: `calc(100vh - ${props.appBarHeightSignedOut}px)`, paddingInline: {xs: 5, sm: 5, md: 18, lg: 36}  }}
            >
              
              <Grid item xs={12} sx={{ backgroundColor: "#FAFAFA", display: 'flex', alignItems: 'center', justifyContent: 'center',  }}>
    
              <LoginForm />
              </Grid>
            </Grid>
          </Container>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isSignedIn: !state.firebase.auth.isEmpty,
        themeRed: state.themeRed
    }
}

export default connect(mapStateToProps)(LoginPage);
import React, { useEffect, useState } from 'react';
import { MapContainer, Polyline, Tooltip, ZoomControl } from 'react-leaflet';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import { cloudFunction } from '../../../../../config/firebaseConfig';
import SmartUtils from '../../../../../helpers/SmartUtils';
import { useTooltipContext } from '../../../../../contexts/TooltipContext';
import InfoIcon from '@mui/icons-material/Info';
import { IconButton, List, ListItem, ListItemIcon, ListItemText, Tooltip as MuiTooltip } from '@mui/material';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

const SuperSectionDefectsMap = (props) => {
  const { conditionsList, superSectionList, conditionMethod, handlePolylineClick, showList, pinned, setPinned, drNetworkData } = props;
  const [superSectionCoordinatesList, setSuperSectionCoordinatesList] = useState([]);
  const [mapCenter, setMapCenter] = useState([51.505, -0.09]);
  const [mapKey, setMapKey] = useState(0);
  const typeOfRoad = drNetworkData.typeOfRoad ?? null;
  useEffect(() => {
    setTimeout(() => {
      const element = document.getElementById(pinned?.superSectionId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      } else {
        console.log('Element not found for id:', pinned?.superSectionId);
      }
    }, 3000)
  }, [pinned, showList, conditionMethod])
  useEffect(() => {
    const fetchEncodedGeometries = async () => {
      if (superSectionList.length === 0) return;

      const encodedGeometries = await Promise.all(superSectionList.map(async (superSection) => {
        try {
          const response = await cloudFunction.httpsCallable('routePlanner-geometryCoversion')({
            encodedGeometry: superSection.encodedGeometry,
          });

          // Return an object containing superSectionId and coordinates
          return {
            superSectionId: superSection.superSectionId,
            superSectionTitle: superSection.superSectionTitle,
            coordinates: response.data.coordinates, // Assuming the response contains the converted geometry
          };

        } catch (error) {
          console.error('Error converting geometry:', error);
          return {
            superSectionId: superSection.superSectionId,
            coordinates: null,
          };
        }
      }));

      setSuperSectionCoordinatesList(encodedGeometries);

      if (pinned === null) {
        localStorage.setItem('pinned', JSON.stringify(encodedGeometries[0]));
        setPinned(encodedGeometries[0]);
      }

      if (encodedGeometries.length > 0 && encodedGeometries[0]?.coordinates?.coordinatesArray.length > 0) {
        const firstCoords = encodedGeometries[0].coordinates.coordinatesArray[0];
        const coordsEqual = (coords1, coords2) => coords1[0] === coords2[0] && coords1[1] === coords2[1];
        if (pinned) {
          if (!coordsEqual(pinned.coordinates.coordinatesArray[0], mapCenter)) {
            setMapCenter([pinned.coordinates.coordinatesArray[0][0], pinned.coordinates.coordinatesArray[0][1]]);
            setMapKey(mapKey + 1);
          }
        } else {
          if (!coordsEqual(firstCoords, mapCenter)) {
            setMapCenter([firstCoords[0], firstCoords[1]]);
            setMapKey(mapKey + 1);
          }
        }
      }
    };

    fetchEncodedGeometries();
  }, [superSectionList]);
  useEffect(() => {
    setMapKey(prevKey => prevKey + 1);
  }, [conditionMethod]);

  const getColorForPolyline = (superSectionId) => {
    const filteredConditions = conditionsList.filter((cond) => cond.superSectionId === superSectionId);
    let color = '#025196';
    if (conditionMethod === 0) {
      const rciValue = filteredConditions.find(condition => condition.key === 0)?.value;
      const typeOfRoad = filteredConditions.find(condition => condition.key === 0)?.typeOfRoad;
      if (rciValue !== undefined) {
        color = SmartUtils.rciColor(rciValue, typeOfRoad);
      }
    } else if (conditionMethod === 1) {
      const pciValue = filteredConditions.find(condition => condition.key === 1)?.value;
      if (pciValue !== undefined) {
        color = SmartUtils.pciColor(pciValue);
      }
    } else if (conditionMethod === 2) {
      const cciValue = filteredConditions.find(condition => condition.key === 2)?.value;
      const cciInverted = filteredConditions.find(condition => condition.key === 2)?.cciInverted;
      if (cciValue !== undefined) {
        color = SmartUtils.cciColor(cciValue, cciInverted);
      }
      else {
        color = '#025196';
      }
    } else {
      color = '#025196';
    }

    return color;
  };
  const LegendsCard = () => {
    const { handleTooltipToggle } = useTooltipContext();
    const tooltipContent = (
      <>
        {conditionMethod === 0 ? (
          typeOfRoad === 'cycleTracks' ? (
            <>
              <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <div
                  style={{
                    width: '12px',
                    height: '12px',
                    borderRadius: '50%',
                    backgroundColor: 'green',
                  }}
                ></div>
                <div style={{ fontSize: '12px', fontWeight: 500 }}>Excellent: 0-18 (Adjusted)</div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <div
                  style={{
                    width: '12px',
                    height: '12px',
                    borderRadius: '50%',
                    backgroundColor: 'darkorange',
                  }}
                ></div>
                <div style={{ fontSize: '12px', fontWeight: 500 }}>Good: 18-44 (Adjusted)</div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <div
                  style={{
                    width: '12px',
                    height: '12px',
                    borderRadius: '50%',
                    backgroundColor: 'red',
                  }}
                ></div>
                <div style={{ fontSize: '12px', fontWeight: 500 }}>Above poor: 44-above (Adjusted)</div>
              </div>
            </>
          ) : (
            <>
              <div style={{ display: 'flex', alignItems: 'center', gap: '8px', fontSize: '12px', fontWeight: 600 }}>
                Adjusted RCI
              </div>
              <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <div
                  style={{
                    width: '12px',
                    height: '12px',
                    borderRadius: '50%',
                    backgroundColor: 'green',
                  }}
                ></div>
                <div style={{ fontSize: '12px', fontWeight: 500 }}>Excellent: 0-31 (Adjusted)</div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <div
                  style={{
                    width: '12px',
                    height: '12px',
                    borderRadius: '50%',
                    backgroundColor: 'darkorange',
                  }}
                ></div>
                <div style={{ fontSize: '12px', fontWeight: 500 }}>Good: 31-76 (Adjusted)</div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <div
                  style={{
                    width: '12px',
                    height: '12px',
                    borderRadius: '50%',
                    backgroundColor: 'red',
                  }}
                ></div>
                <div style={{ fontSize: '12px', fontWeight: 500 }}>Above poor: 76-above (Adjusted)</div>
              </div>
            </>
          )
        ) : conditionMethod === 2 ? (
          // cciInverted ?
          //     <>

          //         <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          //             <div
          //                 style={{
          //                     width: '12px',
          //                     height: '12px',
          //                     borderRadius: '50%',
          //                     backgroundColor: 'darkgreen',
          //                 }}
          //             ></div>
          //             <div style={{ fontSize: '12px', fontWeight: 500 }}>Good: 0-20</div>
          //         </div>
          //         <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          //             <div
          //                 style={{
          //                     width: '12px',
          //                     height: '12px',
          //                     borderRadius: '50%',
          //                     backgroundColor: '#57C018',
          //                 }}
          //             ></div>
          //             <div style={{ fontSize: '12px', fontWeight: 500 }}>Satisfactory: 21-40</div>
          //         </div>
          //         <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          //             <div
          //                 style={{
          //                     width: '12px',
          //                     height: '12px',
          //                     borderRadius: '50%',
          //                     backgroundColor: 'yellow',
          //                 }}
          //             ></div>
          //             <div style={{ fontSize: '12px', fontWeight: 500 }}>Fair: 41-60</div>
          //         </div>
          //         <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          //             <div
          //                 style={{
          //                     width: '12px',
          //                     height: '12px',
          //                     borderRadius: '50%',
          //                     backgroundColor: 'darkorange',
          //                 }}
          //             ></div>
          //             <div style={{ fontSize: '12px', fontWeight: 500 }}>Poor : 61-80</div>
          //         </div>
          //         <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          //             <div
          //                 style={{
          //                     width: '12px',
          //                     height: '12px',
          //                     borderRadius: '50%',
          //                     backgroundColor: 'red',
          //                 }}
          //             ></div>
          //             <div style={{ fontSize: '12px', fontWeight: 500 }}>Serious : 81-100</div>
          //         </div>
          //     </>
          //     :
          <>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px', fontSize: '12px', fontWeight: 600 }}>
              Custom condition index
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <div
                style={{
                  width: '12px',
                  height: '12px',
                  borderRadius: '50%',
                  backgroundColor: 'red',
                }}
              ></div>
              <div style={{ fontSize: '12px', fontWeight: 500 }}>Serious: 0-20</div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <div
                style={{
                  width: '12px',
                  height: '12px',
                  borderRadius: '50%',
                  backgroundColor: 'darkorange',
                }}
              ></div>
              <div style={{ fontSize: '12px', fontWeight: 500 }}>Poor: 21-40</div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <div
                style={{
                  width: '12px',
                  height: '12px',
                  borderRadius: '50%',
                  backgroundColor: 'yellow',
                }}
              ></div>
              <div style={{ fontSize: '12px', fontWeight: 500 }}>Fair: 41-60</div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <div
                style={{
                  width: '12px',
                  height: '12px',
                  borderRadius: '50%',
                  backgroundColor: '#57C018',
                }}
              ></div>
              <div style={{ fontSize: '12px', fontWeight: 500 }}>Satisfactory: 61-80</div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <div
                style={{
                  width: '12px',
                  height: '12px',
                  borderRadius: '50%',
                  backgroundColor: 'darkgreen',
                }}
              ></div>
              <div style={{ fontSize: '12px', fontWeight: 500 }}>Good: 81-100</div>
            </div>
          </>
        ) :
          conditionMethod === 1 ? (
            <>
              <div style={{ display: 'flex', alignItems: 'center', gap: '8px', fontSize: '12px', fontWeight: 600 }}>
                Pavement condition index
              </div>
              <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <div
                  style={{
                    width: '12px',
                    height: '12px',
                    borderRadius: '50%',
                    backgroundColor: 'red',
                  }}
                ></div>
                <div style={{ fontSize: '12px', fontWeight: 500 }}>Serious: 0-20</div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <div
                  style={{
                    width: '12px',
                    height: '12px',
                    borderRadius: '50%',
                    backgroundColor: 'darkorange',
                  }}
                ></div>
                <div style={{ fontSize: '12px', fontWeight: 500 }}>Poor: 21-40</div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <div
                  style={{
                    width: '12px',
                    height: '12px',
                    borderRadius: '50%',
                    backgroundColor: 'yellow',
                  }}
                ></div>
                <div style={{ fontSize: '12px', fontWeight: 500 }}>Fair: 41-60</div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <div
                  style={{
                    width: '12px',
                    height: '12px',
                    borderRadius: '50%',
                    backgroundColor: '#57C018',
                  }}
                ></div>
                <div style={{ fontSize: '12px', fontWeight: 500 }}>Satisfactory: 61-80</div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <div
                  style={{
                    width: '12px',
                    height: '12px',
                    borderRadius: '50%',
                    backgroundColor: 'darkgreen',
                  }}
                ></div>
                <div style={{ fontSize: '12px', fontWeight: 500 }}>Good: 81-100</div>
              </div>
            </>
          )
            :
            (
              <></>
            )}
      </>
    );
    return (
      <div
        style={{
          position: 'absolute',
          bottom: '30px',
          left: '20px',
          zIndex: 1000,
          backgroundColor: 'white',
          padding: '10px',
          borderRadius: '15px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '8px',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        }}
      >
        <MuiTooltip
          title={tooltipContent}
          arrow
        >
          <InfoIcon fontSize="medium" onClick={handleTooltipToggle} sx={{ cursor: 'pointer' }} />
        </MuiTooltip>
      </div>
    );
  };
  const handleMapCenter = (coords) => {
    localStorage.setItem('pinned', JSON.stringify(coords));
    setPinned(coords);
    const points = coords.coordinates.coordinatesArray[0]
    setMapCenter([points[0], points[1]]);
    setMapKey(mapKey + 1);
  }
  const superSectionListComponent = <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
    {superSectionCoordinatesList.map((coords, index) => {
      const polylineColor = getColorForPolyline(coords.superSectionId);
      return (
        <ListItem key={coords.superSectionId}
          id={coords.superSectionId} sx={{ transition: 'all ease 0.0s', backgroundColor: pinned?.superSectionId === coords.superSectionId ? 'rgba(0, 123, 255, 0.2)' : '' }}>
          <ListItemIcon>
            <div
              style={{
                width: '14px',
                height: '14px',
                borderRadius: '50%',
                backgroundColor: polylineColor,
              }}
            ></div>
          </ListItemIcon>
          <ListItemText primary={coords.superSectionTitle} />
          <MuiTooltip title={`Click to redirect ${coords.superSectionTitle} on map`} arrow placement='left-end'>
            <IconButton sx={{ marginLeft: '16px' }} edge="end" onClick={() => { handleMapCenter(coords) }} >
              <MyLocationIcon />
            </IconButton>
          </MuiTooltip>
          <MuiTooltip title={`Click to open ${coords.superSectionTitle} `} arrow placement='right-end'>
            <IconButton sx={{ marginLeft: '8px' }} edge="end" onClick={() => { handlePolylineClick(coords) }} >
              <ArrowCircleRightIcon />
            </IconButton>
          </MuiTooltip >
        </ListItem>
      );
    })}
  </List>

  return (
    <div style={{ height: '100vh', marginLeft: 80 }}>
      <MapContainer zoomControl={false} key={mapKey} attributionControl={false} center={mapCenter} zoom={16} scrollWheelZoom={true} maxZoom={19}>
        <ReactLeafletGoogleLayer apiKey='AIzaSyA1Zq5IDPlPl_i4MnG9wCBg8s6XQrfB9Ig' type={'roadmap'} maxZoom={25} maxNativeZoom={22} />
        <ZoomControl position="bottomright" />
        {conditionMethod !== -1 ? <LegendsCard /> : <></>}
        {showList && superSectionCoordinatesList.length > 0 && <div
          style={{
            position: 'absolute',
            top: '10px',
            left: '10px',
            zIndex: 1000,
            backgroundColor: 'white',
            padding: '10px',
            borderRadius: '15px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '8px',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            cursor: 'pointer',
            overflow: 'auto',
            maxHeight: '50%',
            padding: '10px'
          }}
        >
          {superSectionListComponent}
        </div>}
        {
          superSectionCoordinatesList.length > 0 && (
            superSectionCoordinatesList.map((coords, index) => {
              const polylineColor = getColorForPolyline(coords.superSectionId);
              return (
                <Polyline
                  key={index}
                  positions={coords.coordinates.coordinatesArray}
                  color={polylineColor}
                  weight={pinned?.superSectionId === coords.superSectionId ? 10 : 5}
                  eventHandlers={{
                    click: (event) => {
                      handlePolylineClick(coords);
                    },
                  }}
                >
                  <Tooltip sticky>Click to open {coords.superSectionTitle}`</Tooltip>
                </Polyline>

              );
            })
          )
        }
      </MapContainer >
    </div >
  );
};
export default SuperSectionDefectsMap;

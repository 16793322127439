import { TextField } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';

const EditNetworkDetails = (props) => {
  const { networkTitle, showTitleAlert, onChange } = props;
  return (
    <>
      <TextField
        InputProps={{
          inputMode: 'text',
        }}
        error={showTitleAlert}
        helperText={showTitleAlert ? "Please enter network title" : ""}
        disabled={props.network.updateNetworkLoader}
        fullWidth label="Network title" id="network" value={networkTitle}
        onChange={onChange} />

    </>
  )
};
const mapStateToProps = (state) => {
  return {
    network: state.network,
  };
};
export default connect(mapStateToProps, null)(EditNetworkDetails);
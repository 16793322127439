import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';


const CustomSeparator = ({ widget = <></>, icon = <></> }) => {

    return (
        <Breadcrumbs
            separator={icon}
            aria-label="breadcrumb"
        >
            {widget}
        </Breadcrumbs>
    )
}

export default CustomSeparator
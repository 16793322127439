import { LayerGroup, LayersControl, MapContainer, Marker, Polyline, Popup, TileLayer, useMap, Tooltip, Circle, useMapEvent, Rectangle } from 'react-leaflet'
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import L, { LatLng } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { useEventHandlers } from '@react-leaflet/core'
import marker from '../../../../../../assets/pin_sm.svg';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CircularProgress } from '@mui/material';

const POSITION_CLASSES = {
    bottomleft: 'leaflet-bottom leaflet-left',
    bottomright: 'leaflet-bottom leaflet-right',
    topleft: 'leaflet-top leaflet-left',
    topright: 'leaflet-top leaflet-right',
}

const BOUNDS_STYLE = { weight: 1, color: 'black' }

var currentTime = 0
const SafetyMap = ({ safetyData, distressDataList = [], fps = 24, dataList = [], zoom = 10, assetData = [], polyPoints = [], allPolyPoints = [], pathColor = '#025196', center = [], geoKey = "geo", pinned, handleSeekTo, handlePinned, playerRef, checkData = true, radius = 10 }) => {
    const { defectNames } = safetyData;
    const mapRef = useRef('null')
    const [videoPlayerState, setVideoPlayerState] = useState(null)
    const handleBind = (state) => {
        const calcFps = fps

        setVideoPlayerState(state);

        // //////console.log('currentatime', currentTime)
        // const isVideoActive = state?.seeking === false && state?.waiting === false && ((pinned?.pred?.frameTime + 1 / calcFps) - state?.currentTime) <= 1 / fps
        // if (isVideoActive) {
        //     const hasTimeChanged = ((state?.currentTime ?? 0) !== (currentTime))
        //     if (hasTimeChanged) {
        //         // //////console.log('me update gusta', state?.currentTime ?? 0, currentTime, hasTimeChanged)
        //         currentTime = state?.currentTime ?? 0
        //         // //////console.log('time up', currentTime, hasTimeChanged)
        //         setVideoPlayerState(state);
        //     }
        // }
    }

    useEffect(() => {
        if (playerRef) {
            playerRef?.current?.subscribeToStateChange(handleBind)
        }
    });
    if (defectNames === undefined) {
        return <></>
    }
    // useEffect(() => {
    //     return () => {
    //         if (playerRef.current) {
    //             playerRef.current = null;
    //         }
    //     };
    // }, [playerRef]);



    const SetView = ({ pinned }) => {
        const latLng = pinned?.pred?.geo ?? [0, 0]
        const map = useMap()
        if (latLng[0] === 0 && latLng[1] === 0) {
            return null
        }
        map.setView(new LatLng(latLng[0], latLng[1]), 17, {
            animate: true,
        })
        return null
    }

    const MinimapBounds = ({ parentMap, zoom }) => {
        const minimap = useMap()

        // Clicking a point on the minimap sets the parent's map center
        const onClick = useCallback(
            (e) => {
                parentMap.setView(e.latlng, parentMap.getZoom())
            },
            [parentMap],
        )
        useMapEvent('click', onClick)

        // Keep track of bounds in state to trigger renders
        const [bounds, setBounds] = useState(parentMap.getBounds())
        const onChange = useCallback(() => {
            setBounds(parentMap.getBounds())
            // Update the minimap's view to match the parent map's center and zoom
            minimap.setView(parentMap.getCenter(), zoom)
        }, [minimap, parentMap, zoom])

        // Listen to events on the parent map
        const handlers = useMemo(() => ({ move: onChange, zoom: onChange }), [])
        useEventHandlers({ instance: parentMap }, handlers)

        return <Rectangle bounds={bounds} pathOptions={BOUNDS_STYLE} />
    }

    const MinimapControl = ({ position, zoom }) => {
        const parentMap = useMap()
        const mapZoom = zoom || 0

        // Memoize the minimap so it's not affected by position changes
        const minimap = useMemo(
            () => (
                <MapContainer
                    style={{ height: 80, width: 80 }}
                    center={parentMap.getCenter()}
                    zoom={mapZoom}
                    dragging={false}
                    doubleClickZoom={false}
                    scrollWheelZoom={false}
                    attributionControl={false}
                    zoomControl={false}>
                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                    <MinimapBounds parentMap={parentMap} zoom={mapZoom} />
                </MapContainer>
            ),
            [],
        )

        const positionClass =
            (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright
        return (
            <div className={positionClass}>
                <div className="leaflet-control leaflet-bar">{minimap}</div>
            </div>
        )
    }

    const iconSize = [26 / 1, 38 / 1];
    const DrIcon = new L.Icon({
        iconUrl: marker,
        iconRetinaUrl: marker,
        iconAnchor: [iconSize[0] / 2, iconSize[1]],
        popupAnchor: [-0, -0],
        iconSize: iconSize,
    });

    let DefaultIcon = L.icon({
        iconUrl: icon,
        shadowUrl: iconShadow
    });

    L.Marker.prototype.options.icon = DrIcon;

    // //////console.log(assetData.length)

    if (checkData) {
        if (assetData.length === 0) {
            return <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress sx={{ color: 'green' }} />
            </div>
        }
    }

    // //////console.log(dataList
    // )

    const centerCoor = allPolyPoints[0]
    // return <p></p>
    const pathOptions = { color: pathColor }
    const pathPolyPoints = polyPoints.length === 0 ? dataList.map((e) => e[geoKey]) : polyPoints
    // //////console.log(pathPolyPoints, "pathPolyPoints")
    // pathPolyPoints.map((e)=> //////console.log(e,"assete"));
    const mapCenter = center.length === 2 ? center : [centerCoor.lat, centerCoor.lng]
    // //////console.log(mapCenter)

    const videoTime = videoPlayerState?.currentTime
    const gpsObj = allPolyPoints.length !== 0 ? allPolyPoints.reduce((prev, curr) => {
        return (Math.abs(curr.time * 1000 - videoTime * 1000) < Math.abs(prev.time * 1000 - videoTime * 1000) ? curr : prev);
    }) : [];
    // //////console.log(gpsObj,"gps")


    const LegendsCard = () => {
        const [showLegends, setShowLegends] = useState(false);

        const toggleLegends = () => {
            setShowLegends(!showLegends);
        };

        return (
            <div
                style={{
                    position: 'absolute',
                    bottom: '10px',
                    right: '10px',
                    zIndex: 1000,
                    backgroundColor: 'white',
                    padding: '10px',
                    borderRadius: '15px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '8px',
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                }}
            >
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <div
                        style={{
                            width: '12px',
                            height: '12px',
                            borderRadius: '50%',
                            backgroundColor: 'red',
                        }}
                    ></div>
                    <div style={{ fontSize: '12px', fontWeight: 500 }}>Left Side</div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <div
                        style={{
                            width: '12px',
                            height: '12px',
                            borderRadius: '50%',
                            backgroundColor: 'blue',
                        }}
                    ></div>
                    <div style={{ fontSize: '12px', fontWeight: 500 }}>Right Side</div>
                </div>
            </div>

        );
    };



    return (
        <div style={{ height: '100%' }}>
            <MapContainer ref={mapRef} attributionControl={false} center={mapCenter} zoom={zoom} scrollWheelZoom={true} maxZoom={25}>
                <SetView pinned={pinned} />
                {/* {pinned ? <MinimapControl position="bottomleft" zoom={12} /> : <></>} */}
                <TileLayer
                    attribution=''
                    // url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
                    // detectRetina={true}
                    maxZoom={25}
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {/* Render the LegendsCard */}
                {/* <LegendsCard /> */}

                <LayersControl position="topright">
                    <LayersControl.Overlay name="Road" checked>
                        <LayerGroup>
                            {pathPolyPoints.length === 0 ? <Polyline weight={5} stroke={1000} positions={allPolyPoints} pathOptions={{ color: 'gray' }} >
                            </Polyline> : pathPolyPoints.map((e, i) => <Polyline weight={5} stroke={1000} key={i} positions={e} pathOptions={{ color: 'gray' }} >
                                <Tooltip>Section {i + 1}</Tooltip>
                            </Polyline>)}
                            {/* <Polyline positions={pathPolyPoints} pathOptions={pathOptions} /> */}
                        </LayerGroup>
                    </LayersControl.Overlay>
                    {/* <LayersControl.Overlay name="Segments" checked={false}>
                        <LayerGroup>
                            {pathPolyPoints.map((e, i) => <Polyline weight={5} stroke={1000} key={i} positions={e} pathOptions={{ color: colors[i] }} >
                                <Tooltip>Section {i + 1}</Tooltip>
                            </Polyline>)}
                        </LayerGroup>
                    </LayersControl.Overlay> */}

                    <LayersControl.Overlay name="Current location" checked={true}>
                        <LayerGroup>
                            <Circle
                                center={gpsObj}
                                pathOptions={{ fillColor: 'white', color: 'black', fillOpacity: 1, stroke: true, fill: true }}
                                radius={radius}>
                                <Tooltip>{new Date(gpsObj.time * 1000).toISOString().slice(11, 19)}</Tooltip>
                            </Circle>
                        </LayerGroup>
                    </LayersControl.Overlay>
                    {/* <LayersControl.Overlay name="Pinned marker" checked>
                        <LayerGroup>
                            {pinned === null ? <></> : geoKey in pinned.pred ?
                                // <Marker key={pinned.id} position={pinned[geoKey]}>

                                // </Marker>
                                <Circle
                                    center={pinned.pred[geoKey]}
                                    pathOptions={{ fillColor: 'black', color: 'black', strokeWidth: 10 }}
                                    radius={5}>
                                    <Tooltip>{pinned?.meta?.name ?? '<unknown>'}</Tooltip>
                                </Circle>
                                : <></>}
                        </LayerGroup>
                    </LayersControl.Overlay> */}
                    {distressDataList.length === 0 ?
                        <LayersControl.Overlay name="Features" checked={true}>
                            <LayerGroup>
                                {dataList.map((e, i) => {
                                    const { id, assetId } = e
                                    // //////console.log(assetId, assetData.filter((e) => `${e.id}`.split('_')[0] === `${assetId}`.split('_')[0]))
                                    // const data = assetData.filter((e) => `${e.id}`.split('_')[0] === `${assetId}`.split('_')[0])[0]
                                    const obj = {
                                        pred: e,
                                        // meta: data

                                    }
                                    // const imBase64 = `data:image/png;base64, ${data?.base64 ?? ''}`;
                                    const roadSideColor = {
                                        "0": 'blue',
                                        "1": 'red',
                                        "-1": 'purple',
                                    }
                                    const circleColor = obj.pred.roadSide in roadSideColor ? roadSideColor[obj.pred.roadSide] : roadSideColor['-1']
                                    // //////console.log(imBase64)
                                    const zoomRadius = {
                                        '15': 3.5,
                                        '16': 3,
                                        '17': 2.5,
                                        '18': 1.2,
                                        '19': 0.5,
                                        '-1': 10,
                                    }
                                    return (
                                        // <Marker key={id} position={e[geoKey]} riseOnHover={true} onClick={() => { //////console.log("fgdjhgjs") }} eventHandlers={{
                                        //     click: () => {
                                        //         handleSeekTo(e['frameTime']);
                                        //         handlePinned(obj)
                                        //     }
                                        // }}>

                                        <Circle
                                            key={id}
                                            eventHandlers={{
                                                click: () => {
                                                    handleSeekTo(e['frameTime']);
                                                    handlePinned(obj)
                                                }
                                            }}
                                            center={obj.pred[geoKey]}
                                            // radius={x.accuracy_level == "HIGH" ?  100 :  x.accuracy_level == "MEDIUM" ? 500 : 1000}
                                            pathOptions={{ fillColor: circleColor, color: (pinned?.pred?.id ?? "-1") === obj.pred.id ? 'greenyellow' : circleColor, fillOpacity: 1, stroke: true, fill: true }}
                                            radius={((pinned?.pred?.id ?? "-1") === obj.pred.id ? 2 : 0) + (`${mapRef?.current?._zoom}` in zoomRadius ? zoomRadius[`${mapRef?.current?._zoom}`] : zoomRadius['-1'])}
                                        >
                                            <Tooltip>{defectNames[obj?.pred?.defectName] ?? '<unknown>'}</Tooltip>

                                        </Circle>
                                    );
                                })}
                            </LayerGroup>
                        </LayersControl.Overlay>
                        : <></>}
                </LayersControl>

            </MapContainer>
        </div>
    );
}

export default SafetyMap;
import "leaflet/dist/leaflet.css";
import React, { useState, useEffect } from 'react';
import { MapContainer, Polyline, Marker } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import { Button, Pagination, Grid, CircularProgress, Tabs, Tab, IconButton } from '@mui/material';
import markerImage from '../../../../../../../assets/pin_sm.svg';
import highlightMarkerImage from '../../../../../../../assets/pin_sm_highlight.svg';
import L from 'leaflet';
import CustomProgress from '../../../../../../cidgets/CustomProgress';
import { Link } from 'react-router-dom';
import * as ROUTES from "../../../../../../../constants/routes";
import { downsampleGpsOnDistance } from '../../../../../../../helpers/GpsEditCalcHelper';
import { connect } from 'react-redux';
import { uploadEditGpsData, removeUploadEditGpsMsgAndError, gpsEditNext, deleteVideos, clearReuploadVideoState, uploadEditGpsDataAfterInference } from '../../../../../../../store/actions/projectActions';
import SimpleDialogBox from '../../../../../../cidgets/SimpleDialogBox';
import { makeStyles } from "@mui/styles";
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import { useAccessControlContext } from '../../../../../../../contexts/AccessControlProvider';
import AccessControlAccountError from '../../../../../../cidgets/AccessControlAccountError';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DeleteVideos from './DeleteVideos';
import Error from '../../../../../../cidgets/Error';
import EditGpsScreen from './EditGpsScreen';


const EditGpsMap = (props) => {
    const useStyles = makeStyles((theme) => ({
        tab: {
            minWidth: 'auto',
            textTransform: 'none',
            fontWeight: 'bold',
            fontSize: '14px',
            '&:hover': {
                backgroundColor: 'rgba(128, 128, 128, 0.2)',
                borderTopLeftRadius: '10px',
                borderTopRightRadius: '10px',
            },
        },
        activeTab: {
            color: "white",
            backgroundColor: "#025196",
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
            borderTop: '3px solid #025196',
            borderRight: '3px solid #025196',
            borderLeft: '3px solid #025196',
            borderBottom: 'none',
            '&:hover': {
                backgroundColor: "#025196",
            }
        },

    }));

    const { handleFrame = () => { }, handleSelectFrame = () => { }, selectedMarkerPoint, afterInference = false, handleDashboardBackButton = null, visualizerProjectPredictions, showButton = true, removeUploadEditGpsMsgAndError, project, setDisableHeader, uploadEditGpsData, uploadEditGpsDataAfterInference, allCoordinates, proid, setActiveStep, gpsEditNext, drProjectData } = props;
    const { permissions, role } = useAccessControlContext();
    const { canViewGps, canUpdateGps } = permissions;
    const [loading, setLoading] = useState(true);
    const itemsPerPage = 5000;
    const [currentPage, setCurrentPage] = useState(1);
    const [newVideosList, setNewVideosList] = useState(null);
    const [markers, setMarkers] = useState([]);
    const [gpsMode, setGpsMode] = useState(false);
    const [viewDownsampleCoordinates, setViewDownsampleCoordinates] = useState([]);
    const { uploadEditGpsLoader, uploadEditGpsIsDoneAndSent, deleteVideosMessage, deleteVideosLoader, deleteVideosIsDoneAndSent } = project;
    const [zoomLevel, setZoomLevel] = useState(18);
    const [tabValue, setTabValue] = useState(0);
    const GpsPerPage = 4;
    const [segmentIndex, setSegmentIndex] = useState(0);
    const videosList = drProjectData.videos ? Object.values(drProjectData?.videos) : [];
    videosList.sort((a, b) => a.correctedOrder - b.correctedOrder);
    const [selectVideo, setSelectVideo] = useState(videosList[0]);
    const [videoFilteredCoordinates, setVideoFilteredCoordinates] = useState([]);
    const classes = useStyles();
    const [newCurrentPage, setNewCurrentPage] = useState(0);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const totalPages = Math.ceil(videosList?.length / GpsPerPage);
    const handleMapZoom = (event) => {
        setZoomLevel(event.target._zoom);
    };
    useEffect(() => {
        if (markers.length > 0) {
            const updatedDownSampleCoordinates = downsampleGpsOnDistance(markers, 5);
            setViewDownsampleCoordinates(updatedDownSampleCoordinates);
        }
    }, [markers])
    useEffect(() => {
        const fetchData = async () => {
            setTimeout(() => {
                setLoading(false);
            }, 5000);
        };
        // Check if data is initially null or undefined
        if (allCoordinates === null || allCoordinates === undefined || allCoordinates.length === 0) {
            fetchData();
        } else {
            setLoading(false);
        }
    }, [allCoordinates]);
    useEffect(() => {
        if (allCoordinates && allCoordinates.length > 0) {
            const filteredData = allCoordinates.filter(
                (eachCoordinate) => eachCoordinate.videoIndex === segmentIndex
            );
            setVideoFilteredCoordinates(filteredData);
        }
    }, [allCoordinates, segmentIndex]);
    useEffect(() => {
        if (videoFilteredCoordinates) {
            const startIndex = (currentPage - 1) * itemsPerPage;
            const endIndex = startIndex + itemsPerPage;
            const coordinatesSubset = videoFilteredCoordinates.slice(startIndex, endIndex);
            setMarkers(coordinatesSubset);
        }
    }, [currentPage, videoFilteredCoordinates]);
    const handleTabChangeV2 = (event, newValue) => {
        if (videoFilteredCoordinates.length > newValue) {

            setTabValue(newValue);
            setSegmentIndex(newValue);
            setSelectVideo(videosList[newValue]);
        }
        else {

            setActiveStep(4);
            gpsEditNext(proid);
        }
    }

    const handlePreviousPage = () => {
        if (newCurrentPage > 0) {
            setNewCurrentPage(newCurrentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (newCurrentPage < totalPages - 1) {
            setNewCurrentPage(newCurrentPage + 1);
        }
    };


    const currentVideos = videosList.slice(
        newCurrentPage * GpsPerPage,
        (newCurrentPage + 1) * GpsPerPage
    );
    const tabs = Object.values(currentVideos).map((path, index) => (
        <Tab
            key={index}
            value={index + newCurrentPage * GpsPerPage}
            label={path.name}
            className={`${classes.tab} ${segmentIndex === (index + newCurrentPage * GpsPerPage) ? classes.activeTab : ''
                }`}
            sx={{
                width: "25%",
                textTransform: 'none',
                fontWeight: 'bold',
            }}
        />
    ));
    // const calculateBoundingBox = (coordinates) => {
    //     const lats = coordinates.map(coord => coord.lat);
    //     const lngs = coordinates.map(coord => coord.lng);
    //     const minLat = Math.min(...lats);
    //     const maxLat = Math.max(...lats);
    //     const minLng = Math.min(...lngs);
    //     const maxLng = Math.max(...lngs);
    //     return { minLat, maxLat, minLng, maxLng };
    // };
    if (allCoordinates === null || allCoordinates === undefined || allCoordinates.length === 0) {
        if (loading) {
            return (
                <div style={{ height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CustomProgress text={"Loading gps"} />
                </div>
            )
        }
        else {
            return (
                <div style={{ height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ textAlign: 'center' }}>
                        <h6>No gps data found!</h6>
                        <Link
                            style={{ color: 'black', cursor: 'pointer', marginTop: '0px', textDecoration: 'underline' }}
                            to={`/p${ROUTES.CONTACT}`}
                            state={{ proid: proid, isProject: true }}
                        >  Click here</Link>&nbsp;to contact customer support team.
                        <div style={{ textAlign: 'center', marginTop: '10px' }}>
                            <Button onClick={() => {
                                gpsEditNext(proid);
                                setActiveStep(4);
                            }} variant='contained' sx={{
                                textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                                '&:hover': {
                                    backgroundColor: '#025196',
                                    color: '#FFFFFF'
                                }
                            }}
                            >Next</Button>
                        </div>
                    </div>
                </div>
            )
        }
    }
    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };
    if (markers.length <= 0) {
        return <></>
    }
    const centerCoordinate = markers[0];
    const polylinePositions = videoFilteredCoordinates.map(coordinate => [coordinate.lat, coordinate.lng]);
    const individualPolylinePositions = markers.map(coordinate => [coordinate.lat, coordinate.lng]);
    const iconSize = [26 / 1, 38 / 1];
    const paginationLength = Math.ceil(videoFilteredCoordinates.length / itemsPerPage);

    const handleDelete = () => {
        props.deleteVideos(proid, newVideosList);
    }
    const onDelete = (newList) => {
        setNewVideosList(drProjectData.videos.length === newList.length ? null : newList);
    }
    const handleBack = () => {
        props.clearReuploadVideoState(proid);
        setActiveStep(0);
    }
    return (
        <>
            <SimpleDialogBox
                open={openDeleteDialog}
                onClose={() => { setOpenDeleteDialog(false) }}
                title={<Error errorMessage={"Deleting videos will reset the GPS edit and video selection of the videos"} />}
                bodyWidget={<DeleteVideos videoList={drProjectData.videos} onDelete={onDelete} />}
                showError={deleteVideosIsDoneAndSent === true ? false : true}
                errorMessage={deleteVideosIsDoneAndSent === true ? "" : deleteVideosMessage}
                width={700}
                showPositive
                positiveButtonWidget={<>
                    {deleteVideosLoader ?
                        <>
                            <Button variant="contained" disabled sx={{
                                textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                                "&.Mui-disabled": {
                                    backgroundColor: '#025196',
                                    color: '#FFFFFF'
                                }
                            }}>
                                <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} /><span style={{ paddingLeft: '15px' }}>Deleting...</span>
                            </Button>
                        </>
                        :

                        <Button size="medium" variant="contained"
                            onClick={handleDelete}
                            disabled={newVideosList === null}
                            sx={{
                                textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                                '&:hover': {
                                    backgroundColor: '#025196',
                                    color: '#FFFFFF'
                                }
                            }}>Delete</Button>
                    }
                </>}
            />
            <>{
                !canViewGps ?
                    <AccessControlAccountError access={role} />
                    :
                    gpsMode ?
                        !canUpdateGps ?
                            <AccessControlAccountError access={role} />
                            :
                            <EditGpsScreen
                                drProjectData={drProjectData}
                                handleMapZoom={handleMapZoom}
                                zoomLevel={zoomLevel}
                                removeUploadEditGpsMsgAndError={removeUploadEditGpsMsgAndError}
                                uploadEditGpsLoader={uploadEditGpsLoader}
                                uploadEditGpsIsDoneAndSent={uploadEditGpsIsDoneAndSent}
                                setDisableHeader={setDisableHeader}
                                uploadEditGpsData={uploadEditGpsData}
                                uploadEditGpsDataAfterInference={uploadEditGpsDataAfterInference}
                                visualizerProjectPredictions={visualizerProjectPredictions}
                                // allCoordinates={videoFilteredCoordinates}
                                originalAllCoordinates={allCoordinates}
                                proid={proid}
                                setGpsMode={setGpsMode}
                                filteredCoordinates={markers}
                                showButton={showButton}
                                afterInference={afterInference}
                                handleFrame={handleFrame}
                                selectedMarkerPoint={selectedMarkerPoint}
                                handleSelectFrame={handleSelectFrame}
                            />
                        :

                        <div style={{ padding: '0px' }}>

                            <div style={{ height: afterInference ? 'calc(10vh - 0px)' : 'calc(15vh - 0px)', width: '100%', background: '' }}>
                                <div
                                    style={{
                                        display: 'flex', alignItems: 'center',
                                        backgroundColor: '#EFEFEF',
                                        boxShadow: '0px 0px 10px rgba(64, 64, 64, 0.1)',
                                        width: '100%',
                                        borderBottomLeftRadius: '0',
                                        borderBottomRightRadius: '0',
                                        height: '100%'
                                    }}>
                                    <IconButton onClick={handlePreviousPage} disabled={newCurrentPage === 0}>
                                        <ArrowBackIosIcon />
                                    </IconButton >

                                    <Tabs
                                        value={tabValue}
                                        onChange={handleTabChangeV2}
                                        sx={{ width: '100%' }}
                                        indicatorColor=''
                                        textColor=''
                                    >
                                        {tabs}
                                    </Tabs>

                                    <div style={{ marginLeft: 'auto' }}>
                                        <IconButton onClick={handleNextPage} disabled={newCurrentPage === totalPages - 1} >
                                            <ArrowForwardIosIcon />
                                        </IconButton >
                                    </div>
                                </div>

                            </div>
                            <div style={{ background: '', height: afterInference ? 'calc(75vh - 0px)' : 'calc(50vh - 0px)', width: '100%', backgroundColor: '' }}>
                                <MapContainer key={`${centerCoordinate.lat}-${centerCoordinate.lng}`} attributionControl={false} center={[centerCoordinate.lat, centerCoordinate.lng]} zoom={zoomLevel} style={{ background: '', height: afterInference ? 'calc(75vh - 0px)' : 'calc(50vh - 0px)', width: '100%' }}
                                    whenReady={(map) => {
                                        // Attach zoom event listener
                                        map.target.on('zoom', handleMapZoom);

                                        // Calculate bounding box and fit bounds
                                        // const bbox = calculateBoundingBox(videoFilteredCoordinates);
                                        // const bounds = [[bbox.minLat, bbox.minLng], [bbox.maxLat, bbox.maxLng]];
                                        // map.target.fitBounds(bounds);

                                        // // Update the initial zoom level based on the fitted bounds
                                        // setZoomLevel(map.target.getZoom());
                                    }}>

                                    <ReactLeafletGoogleLayer apiKey='AIzaSyA1Zq5IDPlPl_i4MnG9wCBg8s6XQrfB9Ig' type={'roadmap'} maxZoom={25} maxNativeZoom={22} />
                                    <Polyline positions={polylinePositions} color="#025196" />
                                    <Polyline positions={individualPolylinePositions} color="green" />
                                    <MarkerClusterGroup chunkedLoading>
                                        {viewDownsampleCoordinates.map((coordinate, index) => {
                                            const match = selectedMarkerPoint?.millis === coordinate.millis;
                                            return (
                                                <Marker
                                                    eventHandlers={{
                                                        click: () => {
                                                            handleFrame(coordinate);
                                                            handleSelectFrame(coordinate);
                                                        }
                                                    }}
                                                    icon={L.icon({
                                                        iconAnchor: [iconSize[0] / 2, iconSize[1]],
                                                        popupAnchor: [-0, -0],
                                                        iconUrl: match ? highlightMarkerImage : markerImage,
                                                        iconSize: iconSize,
                                                    })}
                                                    key={index} position={[coordinate.lat, coordinate.lng]}
                                                >
                                                    {/* {frameLink && (
                                                    <div className="inset-image-container">
                                                        <MuiTooltip
                                                            title={"Click to close"}
                                                            arrow
                                                        >
                                                            <img
                                                                src={frameLink}
                                                                alt="Frame"
                                                                className="inset-image"
                                                                onClick={() => { setFrameLink('') }}
                                                            />
                                                        </MuiTooltip>

                                                    </div>
                                                )} */}
                                                </Marker>
                                            )
                                        }
                                        )}
                                    </MarkerClusterGroup>
                                </MapContainer>
                            </div>
                            {paginationLength > 1 ?
                                <div style={{ height: 'calc(7vh - 0px)', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'end', background: '' }}>
                                    <Pagination
                                        size='large'
                                        variant='outlined'
                                        count={Math.ceil(videoFilteredCoordinates.length / itemsPerPage)}
                                        page={currentPage}
                                        onChange={handlePageChange}
                                    />
                                </div>
                                : <></>}
                            <div style={{ height: paginationLength > 1 ? 'calc(8vh - 0px)' : `calc(${afterInference ? '15vh' : showButton ? '15vh' : '8vh'} - 0px)`, width: '100%', background: '' }}>
                                <Grid container spacing={0} sx={{ height: '100%', }}>
                                    <Grid item xs={12} sx={{ paddingTop: '15px', height: '100%', display: 'flex', alignItems: 'start', justifyContent: 'center' }}>
                                        {drProjectData.projectType !== 2 ? <Button
                                            onClick={handleBack}
                                            // disabled={!canUpdateGps}
                                            variant="contained"
                                            sx={{
                                                display: showButton ? 'flex' : 'none',
                                                textTransform: "initial",
                                                backgroundColor: "#025196",
                                                color: "#FFFFFF",
                                                "&:hover": {
                                                    backgroundColor: "#025196",
                                                    color: "#FFFFFF",
                                                },
                                            }}
                                        >
                                            Back
                                        </Button> : <></>}
                                        {/* Only after infernece back button support */}
                                        {afterInference ? <Button
                                            onClick={handleDashboardBackButton}
                                            variant="contained"
                                            sx={{
                                                textTransform: "initial",
                                                backgroundColor: "#025196",
                                                color: "#FFFFFF",
                                                "&:hover": {
                                                    backgroundColor: "#025196",
                                                    color: "#FFFFFF",
                                                },
                                            }}
                                        >
                                            Back
                                        </Button> : <></>}
                                        <Button
                                            onClick={() => { setGpsMode(true); setDisableHeader(true) }}
                                            disabled={!canUpdateGps}
                                            variant="contained"
                                            sx={{
                                                marginLeft: '10px',
                                                textTransform: "initial",
                                                backgroundColor: "#025196",
                                                color: "#FFFFFF",
                                                "&:hover": {
                                                    backgroundColor: "#025196",
                                                    color: "#FFFFFF",
                                                },
                                            }}
                                        >
                                            Edit GPS
                                        </Button>
                                        {drProjectData.projectType !== 2 ? <Button
                                            onClick={() => { setOpenDeleteDialog(true) }}
                                            disabled={!canUpdateGps}
                                            variant="contained"
                                            sx={{
                                                display: showButton ? 'flex' : 'none',
                                                marginLeft: '10px',
                                                textTransform: "initial",
                                                backgroundColor: "#025196",
                                                color: "#FFFFFF",
                                                "&:hover": {
                                                    backgroundColor: "#025196",
                                                    color: "#FFFFFF",
                                                },
                                            }}
                                        >
                                            Delete videos
                                        </Button> : <></>}
                                        <Button
                                            onClick={() => {
                                                gpsEditNext(proid);
                                                setActiveStep(4);
                                            }}
                                            variant="contained"
                                            sx={{
                                                display: showButton ? 'flex' : 'none',
                                                marginLeft: '10px',
                                                textTransform: "initial",
                                                backgroundColor: "#025196",
                                                color: "#FFFFFF",
                                                "&:hover": {
                                                    backgroundColor: "#025196",
                                                    color: "#FFFFFF",
                                                },
                                            }}
                                        >
                                            Next
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>

                        </div >
            }
            </>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        project: state.project,
        visualizerProjectPredictions: state.firestore.ordered.VisualizerProjectPredictions,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        uploadEditGpsData: (gpsList, proid, projectType) => dispatch(uploadEditGpsData(gpsList, proid, projectType)),
        uploadEditGpsDataAfterInference: (gpsList, proid, projectType) => dispatch(uploadEditGpsDataAfterInference(gpsList, proid, projectType)),
        removeUploadEditGpsMsgAndError: () => dispatch(removeUploadEditGpsMsgAndError()),
        gpsEditNext: (proid) => dispatch(gpsEditNext(proid)),
        deleteVideos: (proid, newList) => dispatch(deleteVideos(proid, newList)),
        clearReuploadVideoState: (proid) => dispatch(clearReuploadVideoState(proid)),

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditGpsMap);
import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { Alert, List, ListItem, ListItemIcon, ListItemText, Snackbar } from "@mui/material";
import VideoPreviewPlayer from '../../../components/VideoPreviewPlayer';
import { cancelVideoUpload, clearVideoState, removeUploadVideoErrorAndMessage, uploadAllVideos, uploadAllVideosV2, uploadVideoNext } from '../../../../../../../store/actions/projectActions';
import { connect } from "react-redux";
import { Button } from '@mui/material'
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import CircularProgress from "@mui/material/CircularProgress";
import SmartUtils from '../../../../../../../helpers/SmartUtils';
import CustomProgress from '../../../../../../cidgets/CustomProgress';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import YouTubeIcon from '@mui/icons-material/YouTube';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { useAccessControlContext } from '../../../../../../../contexts/AccessControlProvider';
import AccessControlAccountError from '../../../../../../cidgets/AccessControlAccountError';
import Error from '../../../../../../cidgets/Error';
function CircularProgressWithLabel(props) {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress size={50} variant="determinate" {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography variant="caption" component="div" color="text.secondary">
                    {`${Math.round(props.value)}%`}
                </Typography>
            </Box>
        </Box>
    );
}
CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     * @default 0
     */
    value: PropTypes.number.isRequired,
};
function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} color="success" sx={{ backgroundColor: '#E1E3E2' }} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}
LinearProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
};

const UploadVideoStepVisualizer = (props) => {
    const { permissions, role } = useAccessControlContext();
    const { canUploadVideo } = permissions;
    const [videoFiles, setVideoFiles] = useState([]);
    const [image, setImage] = useState(null);
    const [link, setLink] = useState('');
    const { project, themeColors, charges, proid, drProjectData, height = "80vh", activeStep } = props;
    const { status, thumbnail, videoMessage, isVideoUploadingOnDB, gpsApprovalMessage, videos } = drProjectData;
    const { uploadVideoStatus, uploadVideoStatusAction } = project;
    const [videoDuration, setVideoDuration] = useState(null);
    const [showZeroByteAlert, setShowZeroByteAlert] = useState(false);
    const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
        accept: {
            'video/mp4': ['.mp4'],
            'video/x-msvideo': ['.avi'],
            // 'video/x-msvideo': ['.360'],
        },
        noClick: true,
        noKeyboard: true,
        onDrop: (acceptedFiles) => {
            // Filter out 0-byte files
            const nonEmptyFiles = acceptedFiles.filter(file => file.size > 0);

            // Show alert if there are 0-byte files
            if (acceptedFiles.length !== nonEmptyFiles.length) {
                setShowZeroByteAlert(true);
            }

            setVideoFiles((prevFiles) => [...prevFiles, ...nonEmptyFiles]);
        },
    });
    const handleAlertClose = () => {
        setShowZeroByteAlert(false);
    };
    const removeFile = file => () => {
        setVideoFiles(prevFiles => prevFiles.filter(prevFile => prevFile !== file));

    }
    const uploadAllVideos = (proid) => {
        const videosCount = drProjectData.videosCount ? drProjectData.videosCount : drProjectData.videos ? drProjectData.videos.length : 0;
        if (drProjectData.projectType === 2) {
            props.uploadAllVideos(proid, videoFiles);
        }
        else {
            props.uploadAllVideosV2(proid, videoFiles, drProjectData?.videos, videosCount);
        }
    };
    const removeAll = () => {
        setVideoFiles([])
    }

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const items = Array.from(videoFiles);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setVideoFiles(items);
    };

    const convertSizeToConvention = (byteSize, index = 0) => {
        const prefixList = ['', 'K', 'M', 'G', 'T', 'P']
        let size = parseInt(byteSize) / 1024
        // //////console.log(byteSize)
        if (parseInt(byteSize) < 1024 || index === (prefixList.length - 1)) {
            return `${byteSize.toFixed(2)} ${prefixList[index]}B`
        } else {
            return convertSizeToConvention(size, index + 1)
        }
    }
    useEffect(() => {
        props.removeUploadVideoErrorAndMessage();
    }, [])

    // useEffect(() => {
    //     if (activeStep === 0) {
    //         if (Object.values(uploadVideoStatus).length === 0) {
    //             props.clearVideoState(proid);
    //         }
    //     }
    // }, [uploadVideoStatus])
    const uploadVideo = (proid, videoFile) => {
        // const thumbnail = image;
        // //////console.log(thumbnail)
        // props.uploadVideo(proid, videoFile, thumbnail);
        // props.uploadVideo(proid, videoFile);
        // //////console.log(proid, videoFile, 'rani')
    }

    useEffect(() => {
        if (status === -1) {
            setVideoFiles([]);
            props.clearVideoState(proid);
        }
    }, [status])

    useEffect(() => {
        if (acceptedFiles.length > 0) {
            var video = document.createElement('video');
            video.preload = 'metadata';

            video.onloadedmetadata = function () {
                window.URL.revokeObjectURL(video.src);
                var duration = video.duration;
                acceptedFiles[0].duration = duration;
                // //////console.log(duration, "dur", acceptedFiles)
                setVideoDuration(duration)
            }
            video.src = URL.createObjectURL(acceptedFiles[0]);
        }
    }, [acceptedFiles])

    // const isVideoUploading = proid in uploadVideoStatus ? uploadVideoStatus[proid]['isVideoUploading'] : null
    // const errorMessage = proid in uploadVideoStatus ? uploadVideoStatus[proid]['errorMessage'] : null
    const su = new SmartUtils();
    const files =
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                        {videoFiles.map((file, index) => (
                            <Draggable key={file.name} draggableId={file.name} index={index}>
                                {(provided) => (
                                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                        <Stack spacing={0} sx={{ height: '100%', padding: '10px', backgroundColor: '' }}>
                                            <List key={index} sx={{ backgroundColor: 'white', borderRadius: '20px' }}>
                                                <ListItem disablePadding={false}>
                                                    <ListItemIcon>
                                                        <YouTubeIcon />
                                                    </ListItemIcon>
                                                    <ListItemText sx={{ cursor: 'pointer' }} primary={'Video filename: ' + file.name} secondary={<a style={{ color: 'inherit' }} onClick={removeFile(file)}>
                                                        <b>Remove video</b>
                                                    </a>} />
                                                </ListItem>
                                            </List>
                                        </Stack>
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>

    // //////console.log("uploaded videos", uploadVideoStatus, videoFiles)
    return (
        !canUploadVideo ?
            <AccessControlAccountError access={role} />
            :
            <>
                <Snackbar open={showZeroByteAlert} autoHideDuration={5000} onClose={handleAlertClose}>
                    <Alert
                        onClose={handleAlertClose}
                        severity="error"
                        variant="outlined"
                        sx={{ width: '100%' }}
                    >
                        Video file(s) attached have no data, try re-attaching by clicking instead not drag and drop
                    </Alert>
                </Snackbar>
                <Grid container spacing={0} >
                    <Grid item xs={12} style={{ backgroundColor: '', margin: '0px' }}>
                        <div style={{ backgroundColor: '', height: `${height}`, display: 'flex', justifyContent: 'center', alignItems: 'start' }}>
                            <div style={{
                                padding: '20px',
                                height: `${height}`,
                                // display: 'flex',
                                // alignItems: 'center',
                                // justifyContent: 'center',
                                backgroundColor: ''
                            }} {...getRootProps({ className: 'dropzone' })}>
                                <Box
                                    sx={{
                                        padding: '10px',
                                        minWidth: '500px',
                                        maxWidth: '1000px',
                                        width: '100vw',
                                        height: videoFiles.length > 0 ? `calc(${height} - 60px)` : 'calc(70vh - 60px)',
                                        // height: videos ? videos.length > 0 ? `calc(${height} - 60px)` : videoFiles.length > 0 ? `calc(${height} - 60px)` : 'calc(40vh - 60px)' : videoFiles.length > 0 ? `calc(${height} - 60px)` : 'calc(40vh - 60px)',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        backgroundColor: '',
                                        cursor: status !== 1 ? 'pointer' : isVideoUploadingOnDB || videoFiles.length > 0 ? '' : 'pointer',
                                        border: '2px dashed grey',
                                        borderRadius: '20px',
                                        color: 'grey',
                                    }}
                                    onClick={isVideoUploadingOnDB || videoFiles.length > 0 ? null : open}
                                >

                                    {status === 1 || videoFiles.length !== 0 ?
                                        <div style={{ height: `calc(${height} - 60px)`, width: '100%', backgroundColor: '', padding: '0px' }}>

                                            <Grid container spacing={0} sx={{ height: videoMessage ? '35%' : '75%', backgroundColor: '', overflow: 'auto' }}>
                                                <Grid item xs={12} sx={{ height: '100%', backgroundColor: '', display: videoMessage ? 'flex' : '', justifyContent: videoMessage ? 'center' : '', alignItems: videoMessage ? 'center' : '' }}>
                                                    {videoMessage ? <CustomProgress text={"Video uploading"} /> : files}
                                                </Grid>
                                            </Grid>
                                            {videoMessage ? proid in uploadVideoStatus ?
                                                <div style={{ height: '5%', display: 'flex', justifyContent: 'start', alignItems: 'center', paddingLeft: '10px' }}>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        <Error errorMessage={"DO NOT close the browser tab while a video is uploading. To close the tab safely, click the X in the top right corner."}/>
                                                        {/* <b>WARNING:</b> */}
                                                    </Typography>
                                                    {/* <Typography variant="subtitle1" gutterBottom>
                                                        <b>&nbsp;</b>
                                                    </Typography> */}
                                                    {/* <StyledOutLineButton text='Cancel videos upload' onClick={() => { props.cancelVideoUpload(proid) }} /> */}
                                                </div>
                                                : <div style={{ height: '5%', display: 'flex', justifyContent: 'start', alignItems: 'center', paddingLeft: '10px' }}>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        <b>Note:</b>
                                                    </Typography>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        <b>&nbsp;Videos are uploading please wait...</b>
                                                    </Typography>
                                                </div>
                                                : <div style={{ height: '5%', display: 'flex', justifyContent: 'start', alignItems: 'center', paddingLeft: '10px' }}>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        <b>Note:</b>
                                                    </Typography>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        <b>&nbsp;You can drag cards to arrange the video order</b>
                                                    </Typography>
                                                </div>}
                                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: videoMessage ? '60%' : '20%', backgroundColor: '', overflow: 'auto' }}>
                                                {status === 1 ? (
                                                    <Box sx={{ width: '100%', marginTop: '0px' }}>
                                                        {videoFiles.length > 0 ? videoFiles.map((file, index) => {
                                                            return (<>
                                                                {proid in uploadVideoStatus ?
                                                                    <List key={index} sx={{ backgroundColor: 'white', borderRadius: '20px', margin: '10px' }}>
                                                                        <ListItem disablePadding={false}>
                                                                            <ListItemIcon>
                                                                                <UploadFileIcon />
                                                                            </ListItemIcon>
                                                                            <ListItemText primary={'Video name: ' + file.name}
                                                                                secondary={
                                                                                    <>
                                                                                        {"Time remaining " + SmartUtils.formatTime(Number(proid in uploadVideoStatus ? uploadVideoStatus[proid][`${file.name}`] ? uploadVideoStatus[proid][`${file.name}`]['estimatedTimeRemaining'] : null : null))}
                                                                                        {/* <br />
                                                                                <Link href="#!" onClick={() => { props.cancelVideoUpload(proid, file.name) }}>Cancel</Link> */}
                                                                                    </>}
                                                                            />

                                                                            {proid in uploadVideoStatus ?
                                                                                uploadVideoStatus[proid][`${file.name}`] ?
                                                                                    uploadVideoStatus[proid][`${file.name}`]['progress'] === null || uploadVideoStatus[proid][`${file.name}`]['progress'] === undefined ? <></> :
                                                                                        <CircularProgressWithLabel value={uploadVideoStatus[proid][`${file.name}`]['progress']} /> :
                                                                                    <></>
                                                                                // uploadVideoStatus[proid][`individual${file.name}`]['progress'] === null ? <></> : <CircularProgressWithLabel value={uploadVideoStatus[proid][`individual${file.name}`]['progress']} />
                                                                                :
                                                                                <></>}

                                                                        </ListItem>

                                                                    </List>
                                                                    : <></>
                                                                }

                                                                {/* {proid in uploadVideoStatus ? uploadVideoStatus[proid][`${file.name}`] ? uploadVideoStatus[proid][`${file.name}`]['progress'] === null ? <></> : <LinearProgressWithLabel value={uploadVideoStatus[proid][`${file.name}`]['progress']} /> : <></> : <></>} */}

                                                            </>
                                                            )

                                                        }) :

                                                            videos.map((file, index) => {
                                                                return (<>
                                                                    {proid in uploadVideoStatus ?
                                                                        <List key={index} sx={{ backgroundColor: 'white', borderRadius: '20px', margin: '10px' }}>
                                                                            <ListItem disablePadding={false}>
                                                                                <ListItemIcon>
                                                                                    <UploadFileIcon />
                                                                                </ListItemIcon>
                                                                                <ListItemText primary={'Video filename: ' + file.name} secondary={"Time remaining " + SmartUtils.formatTime(Number(proid in uploadVideoStatus ? uploadVideoStatus[proid][`${file.name}`] ? uploadVideoStatus[proid][`${file.name}`]['estimatedTimeRemaining'] : null : null))}
                                                                                />
                                                                                {proid in uploadVideoStatus ?
                                                                                    uploadVideoStatus[proid][`${file.name}`] ?
                                                                                        uploadVideoStatus[proid][`${file.name}`]['progress'] === null || uploadVideoStatus[proid][`${file.name}`]['progress'] === undefined ? <></> :
                                                                                            <CircularProgressWithLabel value={uploadVideoStatus[proid][`${file.name}`]['progress']} /> :
                                                                                        <></>
                                                                                    // uploadVideoStatus[proid][`individual${file.name}`]['progress'] === null ? <></> : <CircularProgressWithLabel value={uploadVideoStatus[proid][`individual${file.name}`]['progress']} />
                                                                                    :
                                                                                    <></>}

                                                                            </ListItem>
                                                                        </List>
                                                                        : <></>
                                                                    }
                                                                </>
                                                                )

                                                            })

                                                        }
                                                    </Box>
                                                ) : (
                                                    <>
                                                        <Grid container spacing={0} sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                            <Grid xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                <Button size="large" variant="contained" onClick={open} sx={{
                                                                    textTransform: 'initial', backgroundColor: '#025196', color: '#ffffff',
                                                                    '&:hover': {
                                                                        backgroundColor: '#025196',
                                                                        color: '#ffffff'
                                                                    }
                                                                }}>
                                                                    Attach more videos
                                                                </Button>
                                                                <Button size="large" variant="contained"
                                                                    onClick={() => { uploadAllVideos(proid, videoFiles[0]) }}
                                                                    sx={{
                                                                        marginLeft: '10px',
                                                                        textTransform: 'initial', backgroundColor: '#025196', color: '#ffffff',
                                                                        '&:hover': {
                                                                            backgroundColor: '#025196',
                                                                            color: '#ffffff'
                                                                        }
                                                                    }}>
                                                                    Upload videos
                                                                </Button>
                                                                {status === 0 && videos && videos.length > 0 && <Button size="large" variant="contained"
                                                                    onClick={() => { props.uploadVideoNext(proid) }}
                                                                    sx={{
                                                                        marginLeft: '10px',
                                                                        textTransform: 'initial', backgroundColor: '#025196', color: '#ffffff',
                                                                        '&:hover': {
                                                                            backgroundColor: '#025196',
                                                                            color: '#ffffff'
                                                                        }
                                                                    }}>
                                                                    Back to Edit GPS
                                                                </Button>}
                                                            </Grid>


                                                        </Grid>
                                                    </>
                                                )}
                                            </div>

                                        </div>
                                        :
                                        <>
                                            <input {...getInputProps()} />
                                            <Stack>
                                                <p style={{ display: 'flex', justifyContent: 'center' }}>Drag and drop or click here to select video file(s)</p>

                                                {/* <p>Drag and drop one or multiple video files. You can also click here to select a file(s)</p> */}
                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <em>(MP4 and AVI files only)</em>
                                                    {/* <em>(Compatibility with MP4 and AVI files only)</em> */}
                                                </div>
                                                <p style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>{status === -1 ? <Error errorMessage={gpsApprovalMessage ? gpsApprovalMessage : 'Video uploading failed'} /> : <></>}</p>
                                            </Stack>
                                        </>}
                                </Box>
                                {status === 0 && videos && videos.length > 0 && videoFiles.length === 0 &&
                                    <div style={{ height: '10vh', marginTop: '20px' }}>
                                        <Grid container spacing={0} sx={{ height: '100%', display: 'flex', alignItems: 'start', justifyContent: 'center' }}>
                                            <Grid xs={12} sx={{ display: 'flex', alignItems: 'start', justifyContent: 'center' }}>
                                                <Button variant="contained"
                                                    onClick={() => { props.uploadVideoNext(proid) }}
                                                    sx={{
                                                        textTransform: 'initial', backgroundColor: '#025196', color: '#ffffff',
                                                        '&:hover': {
                                                            backgroundColor: '#025196',
                                                            color: '#ffffff'
                                                        }
                                                    }}>
                                                    Back to Edit GPS
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </div>
                                }

                            </div>
                        </div>
                    </Grid >
                </Grid >
            </>


    )
}
const mapStateToProps = (state, ownProps) => {
    const { proid } = ownProps
    return {
        themeColors: state.themeRed,
        project: state.project,
        charges: state.firestore.data.Charges,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        cancelVideoUpload: (proid, fileName) => dispatch(cancelVideoUpload(proid, fileName)),
        uploadAllVideosV2: (proid, videoFile, videoList, videoLength) => dispatch(uploadAllVideosV2(proid, videoFile, videoList, videoLength)),
        uploadAllVideos: (proid, videoFile) => dispatch(uploadAllVideos(proid, videoFile)),
        removeUploadVideoErrorAndMessage: () => dispatch(removeUploadVideoErrorAndMessage()),
        clearVideoState: (proid) => dispatch(clearVideoState(proid)),
        uploadVideoNext: (proid) => dispatch(uploadVideoNext(proid)),
    };

};

export default connect(mapStateToProps, mapDispatchToProps)(UploadVideoStepVisualizer);

// --- PARENT ROUTES
export const UNKNOWN = '*'
export const HOME = 'home/*';
export const AUTH = 'a/*';
export const PAGES = 'p/*';

//  AUTH ROUTES
export const REGISTER = '/register';
export const LOGIN = '/login';
export const FORGOT_PASS = '/forgot-pass';
export const VERIFY_ACCOUNT = '/verify';


// GENERAL
export const CONTACT = '/contact';
export const ABOUT = '/about';
export const PRIVACY_DATA = '/privacy';
export const TERMS = '/terms';
export const MANUAL = '/manual';
// HOME
export const HOME_DASHBOARD = 'dashboard';
export const HOME_DASHBOARD_NETWORK = 'dashboard-network';

export const HOME_PROJECT = 'pr';
export const HOME_NETWORK = 'net';
export const IND_PROJECT = ':proid';
export const IND_NETWORK = ':netid';

export const IND_PROJECT_SEC = ':secId'
export const IND_PROJECT_OUTPUT = ":outputId"
export const IND_SUPERSECTION = ":ssId"
export const PAYMENT = 'payment';
export const ACCOUNT = 'account';
export const SURFACE_VIZ = 'sv';
export const CONTROL_ROOM = 'control-room';
export const ROUTE_PLANNER = "route-planner";
export const SUPER_SECTION = "super-section";
export const BASIC_IMAGING = "basicimaging-defects";
export const ROAD_CONDITION_INDEX = "rci"
export const FULLSCREEN_360_VIDEOPLAYER = 'fvp'

import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import SuperSectionDefectsMap from './SuperSectionDefectsMap';
import { compose } from 'redux';
import Sidebar from './Sidebar';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { useNavigate } from 'react-router-dom';

const AfterCalibratingDashboard = (props) => {
  const { drNetworkData, netid, superSections, allNetworkDefects } = props;
  const savedPinned = JSON.parse(localStorage.getItem('pinned'));
  const [conditionMethod, setConditionMethod] = useState(1);
  const [conditionsList, setConditionsList] = useState([]);
  const [superSectionList, setSuperSectionList] = useState([]);
  const [showList, setShowList] = useState(true);
  const [pinned, setPinned] = useState(savedPinned ?? null);
  const navigate = useNavigate();

  useEffect(() => {
    if (drNetworkData && superSections) {
      if (drNetworkData.associatedSuperSections) {
        const superSectionList = superSections.filter((ss) => drNetworkData.associatedSuperSections.includes(ss.id));
        setSuperSectionList(superSectionList);
      }
    }
  }, [drNetworkData, superSections]);

  useEffect(() => {
    const processAllConditions = (drNetworkData, superSections) => {
      const { condition, defaultCondition, weightedCondition, projectType, typeOfRoad } = drNetworkData;
      const output = [];
      superSections.forEach(superSection => {
        const superSectionId = superSection.superSectionId;
        if (projectType === 4) {
          if (defaultCondition && defaultCondition[superSectionId] !== null) {
            output.push({ key: 1, value: defaultCondition[superSectionId], name: 'Pavement Condition Index', superSectionId, typeOfRoad: typeOfRoad, cciInverted: superSection.cciInverted });
          }

          if (weightedCondition && weightedCondition[superSectionId] !== null) {
            output.push({ key: 2, value: weightedCondition[superSectionId], name: 'Custom Condition Index', superSectionId, typeOfRoad: typeOfRoad, cciInverted: superSection.cciInverted });
          }
        } else {
          if (condition && condition[superSectionId] !== null) {
            output.push({ key: 0, value: condition[superSectionId], name: 'Road Condition Index', superSectionId, typeOfRoad: typeOfRoad, cciInverted: superSection.cciInverted });
          }

          if (defaultCondition && defaultCondition[superSectionId] !== null) {
            output.push({ key: 1, value: defaultCondition[superSectionId], name: 'Pavement Condition Index', superSectionId, typeOfRoad: typeOfRoad, cciInverted: superSection.cciInverted });
          }

          if (weightedCondition && weightedCondition[superSectionId] !== null) {
            output.push({ key: 2, value: weightedCondition[superSectionId], name: 'Custom Condition Index', superSectionId, typeOfRoad: typeOfRoad, cciInverted: superSection.cciInverted });
          }
        }
      });

      return output;
    };
    const result = processAllConditions(drNetworkData, superSectionList);
    setConditionsList(result);
  }, [drNetworkData, superSectionList]);

  const handleConditionMethodChange = (event) => {
    const method = event.target.value;
    setConditionMethod(method);
    // props.superSectionSetting(method, drNetworkData.dbInstanceId);
  }
  const handlePolylineClick = (coords) => {
    const selectedSuperSectionId = coords.superSectionId;
    localStorage.setItem('pinned', JSON.stringify(coords));
    setPinned(coords);
    navigate(`/home/net/${netid}/${selectedSuperSectionId}`, {
      replace: true,
      state: {
        drNetworkData,
        superSections,
        allNetworkDefects,
        netid,
        selectedSuperSectionId,
        conditionMethod,
      },
    });
  };
  return (
    <div style={{ height: '100vh' }}>
      <Grid container>
        <Grid item xs={12}>
          <Sidebar
            showList={showList}
            setShowList={setShowList}
            drNetworkData={drNetworkData}
            netid={netid}
            allNetworkDefects={allNetworkDefects}
            handleConditionMethodChange={handleConditionMethodChange}
            conditionsList={conditionsList}
            conditionMethod={conditionMethod}
          />
          <div style={{ height: '100vh' }}>
            <SuperSectionDefectsMap
              pinned={pinned}
              setPinned={setPinned}
              showList={showList}
              drNetworkData={drNetworkData}
              handlePolylineClick={handlePolylineClick}
              conditionMethod={conditionMethod}
              conditionsList={conditionsList}
              superSectionList={superSectionList} 
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    allNetworkDefects: state.firestore.ordered.NetworkDefects,
    superSections: state.firestore.ordered.SuperSections,
    userData: state.firebase.profile,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    const { netid } = props;
    const coid = props.userData.coid ?? '-1';
    return [
      {
        collection: `NetworkDefects`,
        where: [['networkId', '==', netid]],
        orderBy: ['frameNumber'],
      },
      {
        collection: `SuperSections`,
        where: [['coid', '==', `${coid}`]],
        orderBy: [['creationTime', 'desc']],
      }
    ];
  })
)(AfterCalibratingDashboard);

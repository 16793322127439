import React, { useEffect } from 'react'
import { MapContainer, Marker, Polyline, Popup, TileLayer, ZoomControl, useMap, ScaleControl } from 'react-leaflet'
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';


const Recenter = ({ lat, lng }) => {
    const map = useMap();
    useEffect(() => {
        map.setView([lat, lng]);
    }, [lat, lng, map]);
    return null;
}
const RoutePlannerMap = ({ isUserLocation, routeCalculationData, draggable, userLocation,
    handleMarkerPositionChange,
    markerPositions = [], MapClickHandler }) => {

    const getMarkerColor = (index, totalMarkers) => {
        if (index === 0) return "green";
        if (index === Number(totalMarkers - 1)) return "red";
        return "grey";
    };

    const handleDragEnd = (e, id) => {
        handleMarkerPositionChange(id, e.target._latlng);
    };
    const iconSize = [26 / 1, 38 / 1];
    return (
        <>
            <MapContainer
                center={[userLocation.lat, userLocation.lng]}
                style={{ height: '100vh', cursor: 'default' }}
                zoom={18}
                zoomControl={false}
                doubleClickZoom={false}

            >

                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <Recenter lat={userLocation.lat} lng={userLocation.lng} />
                <ZoomControl position="bottomright" />
                <ScaleControl position="bottomleft">
                </ScaleControl>
                <MapClickHandler />
                {isUserLocation ? <Popup position={userLocation} closeButton={false} className="custom-popup">
                    {/* This is the text overlay */}
                    <div>
                        <p>Click on the map to see waypoint</p>
                    </div>
                </Popup> : <></>}
                {/* <div className="button-container">
                    <Button variant="contained" color="primary" onClick={getToCenterOfUserLocation}>
                        <PersonPinCircle />
                    </Button>
                </div> */}
                {markerPositions.map((position, index) => {

                    // README: DONOT REMOVE THIS CODE (ABDULLAH`)

                    //    return <RoutePlannerCustomMarker
                    //       markerIcon={new L.Icon({ iconUrl: `https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-${getMarkerColor(index, markerPositions.length)}.png`, iconSize: [25, 41], iconAnchor: [12, 41], popupAnchor: [1, -34], shadowSize: [41, 41] })}
                    //         onMarkerRemove={handleMarkerRemove}
                    //         markerPositions={markerPositions}
                    // //         id={position.id} onPositionChange={handleMarkerPositionChange} setIsDragging={setIsDragging} isDragging={isDragging} index={index} position={position} />

                    // L.Marker.prototype.options.icon = L.icon({
                    //     iconUrl: `https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-${getMarkerColor(index, markerPositions.length)}.png`,
                    //     shadowUrl: iconShadow,
                    //     iconSize: [32, 37],
                    //     iconAnchor: [16, 37],
                    // });
                    return <Marker
                        eventHandlers={{
                            dragend: (e) => handleDragEnd(e, position.id),
                        }}
                        autoPan={true}
                        autoPanOnFocus={true}
                        interactive={true}
                        title={index + 1}
                        draggable={draggable}
                        key={index}
                        position={position}
                        icon={new L.Icon({ iconUrl: `https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-${getMarkerColor(index, markerPositions.length)}.png`, iconRetinaUrl: `https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-${getMarkerColor(index, markerPositions.length)}.png`, iconSize: iconSize, iconAnchor: [iconSize[0] / 2, iconSize[1]], popupAnchor: [-0, -0] })}
                    >

                    </Marker>
                })}
                {routeCalculationData === null ? <></> : <Polyline pathOptions={{ color: 'green' }} positions={routeCalculationData.coordinatesArray} />}

            </MapContainer>
        </>
    )
}

export default RoutePlannerMap

import React from 'react'
import { connect } from 'react-redux';
import CustomOpenStreetMap from '../../../../cidgets/CustomOpenStreetMap';
import { Grid } from '@mui/material';
import CustomProgress from '../../../../cidgets/CustomProgress';

const SavedSuperSectionMap = (props) => {
    const { project } = props;
    const { convertedCoordinatesData, convertEncodedGeometryIsDoneAndSent, convertEncodedGeometryLoader } = project;

    if (convertEncodedGeometryLoader === false && convertEncodedGeometryIsDoneAndSent === false) {
        return <div style={{ height: '100vh' }}>No Data found!</div>
    }
    if (convertEncodedGeometryLoader === true) {
        return <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CustomProgress text={"Loading supersection please wait"} />
        </div>
    }
    return (
        <Grid container>
            <Grid item xs={12}>
                <CustomOpenStreetMap showButton={false} showPolyline={true} data={convertedCoordinatesData.coordinatesArray} />
            </Grid>
        </Grid>
    )
}

const mapStateToProps = (state) => {
    return {
        project: state.project,
    };
};

export default connect(mapStateToProps)(SavedSuperSectionMap);

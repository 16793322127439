const initState = {
    assetData: [],
    distressData: [],
    safetyData: [],
    basicImagingData: [],
    accessManagementData: [],
    dataInfo: {},
    isAssetDataDownload: false,
    isDataInfoDownload: false,
    isDistressDataDownload: false,
    isSafetyDataDownload: false,
    isBasicImagingDataDownload: false,
    isAccessManagementDataDownload: false,
    isAssetDataDownloading: false,
    isDataInfoDownloading: false,
    isDistressDataDownloading: false,
    isSafetyDataDownloading: false,
    isBasicImagingDataDownloading: false,
    isAccessManagementDataDownloading: false,
}
const DatastoreReducer = (state = initState, action) => {
    if (action.type === "SET_ASSET_DATA") {
        return {
            ...state,
            assetData: action.assetData,
            isAssetDataDownload: true,
            isAssetDataDownloading: false
        }
    }
    else if (action.type === "SET_DATA_INFO") {
        return {
            ...state,
            dataInfo: action.dataInfo,
            isDataInfoDownload: true,
            isDataInfoDownloading: false,
        }
    }
    else if (action.type === "SET_DISTRESS_DATA") {
        return {
            ...state,
            distressData: action.distressData,
            isDataInfoDownload: true,
            isDataInfoDownloading: false,
        }
    }
    else if (action.type === "SET_SAFETY_DATA") {
        return {
            ...state,
            safetyData: action.safetyData,
            isSafetyDataDownload: true,
            isSafetyDataDownloading: false,
        }
    }
    else if (action.type === "SET_BASIC_IMAGING_DATA") {
        return {
            ...state,
            basicImagingData: action.basicImagingData,
            isBasicImagingDataDownload: true,
            isBasicImagingDataDownloading: false,
        }
    }
    else if (action.type === "SET_ACCESS_MANAGEMENT_DATA") {
        return {
            ...state,
            accessManagementData: action.accessManagementData,
            isAccessManagementDataDownload: true,
            isAccessManagementDataDownloading: false,
        }
    }
    else if (action.type === "START_DOWNLOADING_ASSET_DATA") {
        return {
            ...state,
            isAssetDataDownloading: true,
        }
    }
    else if (action.type === "START_DOWNLOADING_DATA_INFO") {
        return {
            ...state,
            isDataInfoDownloading: true,
        }
    }
    else if (action.type === "START_DOWNLOADING_DISTRESS_DATA") {
        return {
            ...state,
            isDistressDataDownloading: true,
        }
    }
    else if (action.type === "START_DOWNLOADING_SAFETY_DATA") {
        return {
            ...state,
            isSafetyDataDownloading: true,
        }
    }
    else if (action.type === "START_DOWNLOADING_BASIC_IMAGING_DATA") {
        return {
            ...state,
            isBasicImagingDataDownloading: true,
        }
    }
    else if (action.type === "START_DOWNLOADING_ACCESS_MANAGEMENT_DATA") {
        return {
            ...state,
            isAccessManagementDataDownloading: true,
        }
    }
    return state;
}
export default DatastoreReducer
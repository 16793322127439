import React from 'react'
import ErrorIcon from '@mui/icons-material/Error';
const Error = ({ errorMessage }) => {
    return (
        <>
            {errorMessage != '' ? <span style={{ color: '#B00020', display: 'flex' }}><ErrorIcon sx={{ color: '#B00020' }} />&nbsp;{errorMessage}</span> : <></>}
        </>
    )
}

export default Error
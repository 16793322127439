import { IconButton, InputLabel, List, Collapse, ListItem, ListItemText, MenuItem, TextField, Tooltip, Typography, Select } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DateParser from '../../../../../helpers/DateParser';
import ProjectParser from '../../../../../helpers/ProjectParser';
import SmartUtils from '../../../../../helpers/SmartUtils';
import AddIcon from "@mui/icons-material/Add";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { v4 as uuidv4 } from 'uuid';
import Delete from '@mui/icons-material/Delete';
import InputAdornment from "@mui/material/InputAdornment";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

function ProjectDetails({ drProjectData, proid, error,
    setError,
    drivingSideError,
    setDrivingSideError,
    surveyDateError,
    setSurveyDateError,
    processingTimeError,
    setProcessingTimeError,
    typeOfRoadError,
    setTypeOfRoadError,
    values,
    setValues,
    customCategories,
    setCustomCategories,
    companyPricingData,
    chargesV3,
    processingTime, setProcessingTime
}) {
    const { projectType, status } = drProjectData
    const [isListOpen, setIsListOpen] = useState(false);


    const [categoryInput, setCategoryInput] = useState("");
    const [openProjectList, setOpenProjectList] = useState(false);
    const [surchargeData, setSurchargeData] = useState({});
   

    const getSurchargeData = (projectType) => {
        // Check in companyPricingData.projectSurcharge
        if (companyPricingData && companyPricingData.projectSurcharge && companyPricingData.projectSurcharge[projectType]) {
          return companyPricingData.projectSurcharge[projectType];
        }
        
        // Check in companyPricingData.surcharge
        if (companyPricingData && companyPricingData.surcharge) {
          return companyPricingData.surcharge;
        }
      
        // Check in chargesV3.projectSurcharge
        if (chargesV3 && chargesV3.projectSurcharge && chargesV3.projectSurcharge[projectType]) {
          return chargesV3.projectSurcharge[projectType];
        }
      
        // Default to chargesV3.priorityMultipliers
        if (chargesV3 && chargesV3.priorityMultipliers) {
          return chargesV3.priorityMultipliers;
        }
      
        // Default return if no conditions are met
        return {};
      };

    useEffect(() => {
        const data = getSurchargeData(projectType);
        setSurchargeData(data);
    }, [projectType]);

    const handleChange = (prop) => (e) => {
        e.preventDefault();
        setError(false);
        setDrivingSideError(false);
        setSurveyDateError(false);
        setProcessingTimeError(false);
        setTypeOfRoadError(false);
        setValues({ ...values, [prop]: e.target.value });
    };


    const handleAddCategory = () => {
        if (categoryInput.trim() !== "") {
            const id = uuidv4();
            setCustomCategories({
                ...customCategories,
                [id]: { categoryId: id, name: categoryInput }
            });
            setCategoryInput("");
            setIsListOpen(true);
        }

    };
    const handleDeleteCategory = (id) => {
        const updatedCategories = { ...customCategories };
        delete updatedCategories[id];
        setCustomCategories(updatedCategories);
    };
    const toggleList = () => {
        setIsListOpen(!isListOpen);
    };

    const handleToggle = () => {
        setOpenProjectList(!openProjectList);
    };


    return (

        <>

            <InputLabel sx={{ marginTop: "20px" }} error={error} id="driving-side-label" >
                {error ? "Enter a project title*" : "Project title*"}
            </InputLabel>
            <TextField sx={{ margin: "0px" }} error={error} required onChange={handleChange("projectName")}
                margin="dense"
                id="Project title"
                type="text"
                fullWidth
                value={values.projectName}
            />

            {projectType === 2 ? (
                <>

                    <InputLabel
                        sx={{
                            marginTop: "20px",
                        }}
                        error={surveyDateError}
                        id="survey-date-label"
                    >
                        {" "}
                        {surveyDateError
                            ? "Select Survey date*"
                            : "Survey date*"}
                    </InputLabel>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker', 'DatePicker']}>
                            <DatePicker
                                label="Date"
                                value={dayjs(values.surveyDate)}
                                onChange={(newValue) => setValues({ ...values, surveyDate: dayjs(newValue).format('MM/DD/YYYY') })}
                            // disablePast
                            />
                        </DemoContainer>
                    </LocalizationProvider>


                    <InputLabel sx={{ marginTop: "20px" }} id="customCategory-label" >
                        Define tag categories
                    </InputLabel>
                    <TextField
                        sx={{ margin: '0px' }}
                        margin="dense"
                        id="customCategory"
                        type="text"
                        fullWidth
                        value={categoryInput}
                        onChange={(e) => setCategoryInput(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Tooltip title="Add Category" arrow>
                                        <IconButton
                                            onClick={handleAddCategory}
                                            edge="end"
                                            aria-label="Add Category"
                                            sx={{
                                                color: "#1982C4",
                                            }}
                                        >
                                            <AddIcon sx={{
                                                color: "#1E1E1E",
                                                cursor: "pointer",
                                            }} />
                                        </IconButton>
                                    </Tooltip>
                                    {Object.keys(customCategories).length > 0 && (

                                        <IconButton onClick={toggleList} sx={{
                                            color: "#1E1E1E",
                                            cursor: "pointer",
                                        }}>
                                            {isListOpen ?
                                                <Tooltip title="Hide Categories" arrow>
                                                    <ExpandLess />
                                                </Tooltip> :
                                                <Tooltip title="Show Categories" arrow>
                                                    <ExpandMore />
                                                </Tooltip>}
                                        </IconButton>
                                    )}
                                </InputAdornment>
                            ),
                        }}
                    />
                    {Object.keys(customCategories).length > 0 && (
                        <Collapse in={isListOpen}>
                            <List
                                sx={{
                                    border: '1px dashed  #D4D4D4',
                                    padding: '16px',
                                    borderRadius: '5px',
                                    marginBottom: '8px',
                                    marginTop: '5px',
                                    height: '100px',
                                    overflow: 'auto',
                                }}
                            >
                                {Object.keys(customCategories).map(id => (
                                    <ListItem
                                        key={id} // Ensure a unique "key" prop

                                    >
                                        <ListItemText primary={customCategories[id].name}
                                            sx={{
                                                borderBottom: '1px solid   #D4D4D4',
                                                '&:last-child': {
                                                    borderBottom: 'none',
                                                },
                                            }}
                                        />
                                        <Tooltip title="Delete Category" arrow>
                                            <IconButton
                                                aria-label="Delete"

                                                onClick={() => handleDeleteCategory(id)}
                                            >
                                                <Delete />
                                            </IconButton>
                                        </Tooltip>
                                    </ListItem>
                                ))}
                            </List>
                        </Collapse>
                    )}
                </>
            ) : (
                <>
                    <InputLabel
                        sx={{
                            marginTop: "20px",
                        }}
                        error={drivingSideError}
                        id="driving-side-label"
                    >
                        {drivingSideError
                            ? "Select side of driving*"
                            : "Side of driving*"}
                    </InputLabel>
                    <Select
                        labelId="driving-side-label"
                        className="capitalize-text"
                        id="driving-side"
                        value={values.drivingSide}
                        onChange={handleChange("drivingSide")}
                        fullWidth // set full width
                        disabled={status !== 5}
                    >
                        <MenuItem className="capitalize-text" value="0">
                            left
                        </MenuItem>
                        <MenuItem className="capitalize-text" value="1">
                            right
                        </MenuItem>
                    </Select>

                    <InputLabel
                        sx={{
                            marginTop: "20px",
                        }}
                        error={processingTimeError}
                        id="processing-time-label"
                    >
                        {" "}
                        {processingTimeError
                            ? "Select Processing timeline*"
                            : "Processing timeline choice*"}
                    </InputLabel>
                    <Select
                        required
                        labelId="processing-time-label"
                        // className="capitalize-text"
                        id="processing-time"
                        value={processingTime}
                        onChange={(event) => { setProcessingTime(event.target.value) }}
                        // onChange={handleChange("processingTime")}
                        fullWidth // set full width
                        disabled={status !== 5}
                    >
                        {Object.entries(surchargeData).map(([duration, surcharge]) => {
                            const label = duration.replace('oneDay', '1 day')
                                .replace('twoWeeks', '2 weeks')
                                .replace('twoDays', '2 days')
                                .replace('oneWeek', '1 week');
                            return (
                                <MenuItem className="" value={duration}>
                                    {label}
                                </MenuItem>
                            );
                        })}
                    </Select>


                    <InputLabel
                        sx={{
                            marginTop: "20px",
                        }}
                        error={surveyDateError}
                        id="survey-date-label"
                    >
                        {" "}
                        {surveyDateError
                            ? "Select Survey date*"
                            : "Survey date*"}
                    </InputLabel>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker', 'DatePicker']}>
                            <DatePicker
                                label="Date"
                                value={dayjs(values.surveyDate)}
                                onChange={(newValue) => setValues({ ...values, surveyDate: dayjs(newValue).format('MM/DD/YYYY') })}
                            // disablePast
                            />
                        </DemoContainer>
                    </LocalizationProvider>




                    {projectType === 1 ? (
                        <>
                            <InputLabel
                                sx={{
                                    marginTop: "20px",
                                }}
                                error={typeOfRoadError}
                                id="processing-time-label"
                            >
                                {" "}
                                {typeOfRoadError
                                    ? "Select Type of road*"
                                    : "Type of road*"}
                            </InputLabel>
                            <Select
                                required
                                labelId="road-type-label"
                                // className="capitalize-text"
                                id="road-type"
                                value={values.typeOfRoad}
                                onChange={handleChange("typeOfRoad")}
                                fullWidth // set full width
                                disabled={status !== 5}
                            >
                                <MenuItem className="" value="carriageWay">
                                    Carriageway
                                </MenuItem>
                                <MenuItem className="" value="cycleTracks">
                                    Cycleway
                                </MenuItem>
                            </Select>
                        </>
                    )
                        :
                        <> </>
                    }

                </>
            )}

            <Typography variant="body1" sx={{ marginTop: "20px" }}>
                {openProjectList ? (
                    "Collapse to hide optional fields"
                ) : (
                    "Expand to view optional fields"
                )}
                <IconButton onClick={handleToggle}>
                    {openProjectList ? <Tooltip title="Close"><ExpandLess /></Tooltip> : <Tooltip title="Expand to view optional fields"><ExpandMore /></Tooltip>}
                </IconButton>
            </Typography>

            <Collapse in={openProjectList} timeout="auto" unmountOnExit>

                <InputLabel sx={{ marginTop: "20px" }}>
                    Client name
                </InputLabel>
                <TextField sx={{ margin: "0px" }} onChange={handleChange("clientName")}
                    margin="dense"
                    id="Client Name"
                    type="text"
                    fullWidth
                    value={values.clientName}
                />

                <InputLabel sx={{ marginTop: "20px" }}>
                    Identification number
                </InputLabel>
                <TextField sx={{ margin: "0px" }} onChange={handleChange("identificationNo")}
                    margin="dense"
                    id="Identification Number"
                    type="text"
                    fullWidth
                    value={values.identificationNo}
                />

                <InputLabel sx={{ marginTop: "20px" }}>
                    Street name
                </InputLabel>
                <TextField sx={{ margin: "0px" }} onChange={handleChange("streetName")}
                    margin="dense"
                    id="Street Name"
                    type="text"
                    fullWidth
                    value={values.streetName}
                />

                <InputLabel sx={{ marginTop: "20px" }}>
                    Starting landmark
                </InputLabel>
                <TextField sx={{ margin: "0px" }} onChange={handleChange("startingAddress")}
                    margin="dense"
                    id="Starting Address"
                    type="text"
                    fullWidth
                    value={values.startingAddress}
                />

                <InputLabel sx={{ marginTop: "20px" }}>
                    Ending landmark
                </InputLabel>
                <TextField sx={{ margin: "0px" }} onChange={handleChange("endingAddress")}
                    margin="dense"
                    id="Ending Address"
                    type="text"
                    fullWidth
                    value={values.endingAddress}
                />

                {projectType !== 2 && (

                    <>
                        <InputLabel sx={{ marginTop: "20px" }}>
                            Direction of traffic
                        </InputLabel>
                        <TextField sx={{ margin: "0px" }} onChange={handleChange("direction")}
                            margin="dense"
                            id="Direction"
                            type="text"
                            fullWidth
                            value={values.direction}
                        />
                        {(projectType !== 0 && projectType !== 3) && (
                            <>
                                <InputLabel sx={{ marginTop: "20px" }}>
                                    Number of lanes
                                </InputLabel>
                                <TextField sx={{ margin: "0px" }} onChange={handleChange("totalLanes")}
                                    margin="dense"
                                    id="Total Lanes"
                                    type="text"
                                    fullWidth
                                    value={values.totalLanes}
                                />

                                <InputLabel sx={{ marginTop: "20px" }}>
                                    Current lane number
                                </InputLabel>
                                <TextField sx={{ margin: "0px" }} onChange={handleChange("laneNumber")}
                                    margin="dense"
                                    id="Lane Number"
                                    type="text"
                                    fullWidth
                                    value={values.laneNumber}
                                />
                            </>
                        )}
                    </>
                )}

            </Collapse>

        </>
    );
}

export default ProjectDetails;
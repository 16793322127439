import {
    Button, Card, CircularProgress, IconButton, ListItem, ListItemButton, ListItemIcon, Table, TableBody, TableRow, TableCell, ListItemText,
    TableContainer, Typography, Grid, Box
} from "@mui/material";
import CustomProgress from "../../../../../cidgets/CustomProgress";
import partypopper from "../../../../../../assets/partypopper.png";
import SimpleDialogBox from "../../../../../cidgets/SimpleDialogBox";
import React, { useState, useEffect } from "react";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { customerBuyConsumable, openStripeBuyProductModal, confirmConsumablePurchase, clearProductRequest } from "../../../../../../store/actions/paymentActions"
import { connect } from 'react-redux';
import {
    ElementsConsumer,
} from "@stripe/react-stripe-js";
import commonNames from '../../../../../../constants/commonNames.json'
const CheckOutForm = (props) => {

    const { processStatus, setProcessStatus, setOrderId } = props;
    const { stripePaymentIntent } = props.payment;
    const { coid, stripeDefaultPmid } = props.companyData;

    useEffect(() => {
        if (processStatus.paymentIntent) {
            setOrderId(stripePaymentIntent?.orderId);
            props.confirmConsumablePurchase(stripePaymentIntent?.orderId);
        }
    }, [processStatus])

    const handleSubmit = async (event) => {
        // //////console.log("CARD ATTACH SUBMIT");

        // Block native form submission.
        event.preventDefault();
        const { stripe, elements } = props;
        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }
        // //////console.log("hello");
        setProcessStatus({
            isProcessing: true,
        })
        const { error, paymentIntent } = await stripe.confirmCardPayment(`${stripePaymentIntent.paymentIntentCS}`, {
            payment_method: stripeDefaultPmid,
        });
        //    
        // //////console.log("payment Intent" + paymentIntent);
        // //////console.log(paymentIntent, "errorcheck")
        if (error) {
            setProcessStatus({
                isProcessing: false,
                isProcessed: false,
                errorMessage: error ? "Authorization has failed. Please try again." : "",
                error: error,
                paymentIntent: paymentIntent,
                message: ''
            })
        }
        else {
            setProcessStatus({
                isProcessing: true,
                isProcessed: true,
                errorMessage: "",
                error: "",
                paymentIntent: paymentIntent,
                message: 'Please wait confirming your payment...'
            })
        }

    }
    const { isProcessing, isProcessed, errorMessage } = processStatus;
    const { stripe, payment } = props;
    const { isBuyProdActing, isBuyProdActed, buyProdActMessage } = payment;
    const relevantComponent = !isProcessed ? !isProcessing ? <Button type="submit" disabled={!stripe}
        variant="contained" sx={{
            "&.Mui-disabled": {
                backgroundColor: '#025196',
                color: '#ffffff'
            },
            marginInline: '2px',
            textTransform: 'initial',

            backgroundColor: '#025196', ':hover': {
                backgroundColor: '#025196'
            }
        }}
    >
        Authorize payment</Button> : <Button variant="contained" disabled sx={{
            "&.Mui-disabled": {
                backgroundColor: '#025196',
                color: '#ffffff'
            },
            marginInline: '2px',
            textTransform: 'initial',
            backgroundColor: '#025196', ':hover': {
                backgroundColor: '#025196'
            }
        }}
        >
        <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} /><span style={{ paddingLeft: '15px' }}> Authorizing...</span>
    </Button> : <React.Fragment />
    return (
        <React.Fragment>
            <form onSubmit={handleSubmit}>
                {relevantComponent}
            </form>
        </React.Fragment>

    )
}

const PlanDetailsCard = (props) => {
    useEffect(() => {
        props.openStripeBuyProductModal();
    }, []);
    const { PlanDetails } = props

    const handleClick = () => {
        handleOpenDialog()

    }
    const [orderId, setOrderId] = useState("");
    const [processStatus, setProcessStatus] = useState({
        isProcessing: false,
        isProcessed: false,
        errorMessage: "",
        error: null,
        paymentIntent: null,
        message: ''
    })
    const { isProcessing, isProcessed, errorMessage } = processStatus;
    const handleProdBuySubmit = (event) => {
        // //////console.log("PROD BUY SUBMIT")
        event.preventDefault();
        const { productId } = PlanDetails
        // //////console.log(productId, "productId");
        props.customerBuyConsumable(productId)
    }
    const { confirmConsumablePurchase, companyData, userData, payment } = props;
    const { isBuyProdActing, isBuyProdActed, buyProdActMessage, stripePaymentIntent } = payment;
    // //////console.log(PlanDetails, "planDetails");
    const relevantMessage = isBuyProdActed ? `${buyProdActMessage}` : isBuyProdActing ? "Processing..." : buyProdActMessage
    const relevantButton = !isBuyProdActed ? !isBuyProdActing ? <Button onClick={handleProdBuySubmit} disabled={isBuyProdActing}
        variant="contained" sx={{
            "&.Mui-disabled": {
                backgroundColor: '#025196',
                color: '#ffffff'
            },
            marginInline: '2px',
            textTransform: 'initial',
            backgroundColor: '#025196',
            ':hover': {
                backgroundColor: '#025196'
            }
        }}>Buy now</Button> : <Button variant="contained" disabled sx={{
            "&.Mui-disabled": {
                backgroundColor: '#025196',
                color: '#ffffff'
            },
            marginInline: '2px',
            textTransform: 'initial',
            backgroundColor: '#025196', ':hover': {
                backgroundColor: '#025196'
            }
        }}
        >
        <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} /><span style={{ paddingLeft: '15px' }}>
            Processing...
        </span>
    </Button> : <React.Fragment />
    // //////console.log("stripePaymentIntent");
    let relevantComponentBody = <React.Fragment />;
    if (stripePaymentIntent === "") {
        relevantComponentBody = relevantButton;
    } else if (stripePaymentIntent === "-1") {
        relevantComponentBody = <p>{buyProdActMessage}</p>;
    } else {
        relevantComponentBody = <ElementsConsumer>
            {({ elements, stripe }) => (
                <CheckOutForm elements={elements} stripe={stripe} userData={userData} companyData={companyData} payment={payment} confirmConsumablePurchase={confirmConsumablePurchase}
                    processStatus={processStatus} setProcessStatus={setProcessStatus}
                    orderId={orderId} setOrderId={setOrderId}
                />
            )}
        </ElementsConsumer>;
    }

    const cardStyle = {
        "border": "0.5px solid grey",
        "borderRadius": "8px",
        "backgroundColor": '#fafafa',
        "marginBlock": '10px',

    }

    // DIALOG CONTROLS
    ///////////////////////////////////////////////////////////
    const [openDialog, setOpenDialog] = useState(false);

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        props.clearProductRequest();
        setProcessStatus({
            isProcessing: false,
            isProcessed: false,
            errorMessage: "",
            error: null,
            paymentIntent: null,
            message: ''
        })
        setOpenDialog(false);
    };
    ////////////////////////////////////////////////////////////

    const { productName, productDescription } = PlanDetails;
    const kilometers = 10;
    ////////////////////////////////////////////plandetails table//////////////
    const productData = [
        { label: 'Product ID:', value: PlanDetails.productId },
        { label: 'Product name:', value: PlanDetails.productName },
        { label: 'Product description:', value: PlanDetails.productDescription },
        { label: 'Base price:', value: PlanDetails.basePrice },

    ];

    //////////////product/////////////////
    const productList = <div>
        <TableContainer>
            <Table sx={{ minWidth: 250 }} aria-label="Order table" >
                <TableBody>
                    {productData.map((row) => (
                        <TableRow key={row.label}>
                            <TableCell component="th" scope="row">
                                {row.label}
                            </TableCell>
                            <TableCell align="left">{row.value}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </div>
    //////////////product/////////////////
    const productPurchased = <div  >
        <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",

        }}>  <img src={partypopper} alt="party-popper" />
        </div>
        <Typography variant="h5" textAlign="center" sx={{ marginTop: "10px" }}>{PlanDetails.tokens} {commonNames.credits} added!</Typography>
        <Typography variant="body1" textAlign="center" sx={{ marginTop: "10px" }}>You now have  {Number(companyData?.credits).toFixed(2)} {commonNames.credits} and can analyze about {kilometers} kilometers of road</Typography>
        <Typography variant="body2" textAlign="center" sx={{
            marginTop: "20px", marginBottom: "10px", fontWeight: 'bold', color: "gray"
        }}>Order ID is {orderId}
            {/* <a href="">{orderId}</a> */}
        </Typography>
        <Typography variant="body2" textAlign="center">Click <a href="/p/contact">here</a>  to contact support if you want to get further details</Typography>
    </div >

    // //////console.log(relevantMessage === `${buyProdActMessage}`, "cond", relevantMessage, buyProdActMessage)
    return (

        <>
            {/* dialog widget */}
            <SimpleDialogBox
                open={openDialog}
                onClose={handleCloseDialog}
                title={

                    relevantMessage === 'Processing...'
                        ? processStatus.message !== ''
                            ? processStatus.message
                            : <>{productName}</>
                        : relevantMessage === ''
                            ? <>{productName}</>
                            : isProcessed
                                ? <>Order Confirmation</>
                                : <>{productName}</>
                    // Please wait confirming your payment...
                }
                width={800}
                showError={errorMessage != '' ? true : false}
                errorMessage={errorMessage}
                bodyWidget={
                    relevantMessage === 'Processing...'
                        ? processStatus.message === 'Please wait confirming your payment...'
                            ? <CustomProgress />
                            : <>{productList}</>
                        : relevantMessage === ''
                            ? <>{productList}</>
                            : relevantMessage === `Manual action is required`
                                ? <>{productList}</> : <>{productPurchased}</>
                }
                showPositive
                positiveButtonWidget={
                    PlanDetails.stripeProductId !== "" ? relevantComponentBody : <p>Product not available yet!</p>
                }
            />

            <Card sx={cardStyle} elevation={0}>
                <ListItem
                    dense
                    disablePadding
                    secondaryAction={
                        <ListItemIcon title={""}>
                            <IconButton onClick={() => { handleClick(PlanDetails) }}>
                                <ChevronRightIcon />
                            </IconButton>
                        </ListItemIcon>
                    }
                >
                    <ListItemButton
                        sx={{ paddingBlock: '8px', }}
                        onClick={() => { handleClick(PlanDetails) }}
                    >
                        <ListItemText
                            primary={
                                <>
                                    <Typography
                                        variant="p"
                                        sx={{ fontWeight: 'bold' }}
                                    >{productName}
                                    </Typography>
                                </>
                            }
                            secondary={
                                <span>{productDescription}</span>
                            }
                        />
                    </ListItemButton>
                </ListItem>
            </Card>

        </>
    )
}
const mapStateToProps = (state) => {
    return {
        userData: state.firebase.profile,
        userId: state.firebase.auth.uid,
        productOrder: state.firestore.data.ProductOrder,
        companyData: state.firestore.data.Company,
        payment: state.payment,
        commons: state.commons,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        openStripeBuyProductModal: () => dispatch(openStripeBuyProductModal()),
        customerBuyConsumable: (productId) => dispatch(customerBuyConsumable(productId)),
        confirmConsumablePurchase: (orderId, coid) => dispatch(confirmConsumablePurchase(orderId)),
        clearProductRequest: () => dispatch(clearProductRequest())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlanDetailsCard);
import ReactPannellum from "react-pannellum";
const config = {
    type: 'equirectangular',
    autoLoad: true,
    showZoomCtrl: true,
    autoRotate: 0
};
export default function ImageViewer({ frameLink, height }) {
    return (
            <ReactPannellum
                key={frameLink.frameLink}
                style={{ height: height }}
                id={frameLink.frameLink}
                sceneId={frameLink.frameLink}
                imageSource={frameLink.frameLink}
                config={config}
            />
    )
}
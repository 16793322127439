import { useState } from "react";
import { Card, Grid } from "@mui/material";
import ImageViewer from "./ImageViewer";
import { connect } from "react-redux";
import EditGpsMap from "../subpages/beforeInference/subpages/EditGpsMap.js";

function GpsEditDashboard({ setOpenEditGpsDialog, visualizerProjectPredictions, proid,
    filteredGpsData360, drProjectData, overallCustomBorderPadding, assetVideoPlayerPadding, cardWhiteStyle }) {

    const originalFramesList = visualizerProjectPredictions.length > 0 ? visualizerProjectPredictions.flatMap(obj => Object.values(obj.markerData)).sort((a, b) => a.serialNumber - b.serialNumber).map((each) => each) : [];
    const [frameLink, setFrameLink] = useState(null);
    const [selectedMarkerPoint, setSelectedMarkerPoint] = useState(null);

    const handleFrame = (coordinate) => {
        const originalFrameIndex = filteredGpsData360.findIndex((each) => each.millis === coordinate.millis && each.videoIndex === coordinate.videoIndex)
        if (originalFramesList.length > 0) {
            setFrameLink(originalFramesList[originalFrameIndex]);
        }
    }
    const handleSelectFrame = (coordinate) => {
        if (selectedMarkerPoint) {
            if (selectedMarkerPoint.millis === coordinate.millis) {
                setFrameLink(null);
                setSelectedMarkerPoint(null);
            }
            else {
                setSelectedMarkerPoint(coordinate);
            }
        }
        else {
            setSelectedMarkerPoint(coordinate);
        }
    }

    return (
        <div style={{ height: "100vh", width: "100vw", backgroundColor: "#E1E3E2" }}>
            <Grid container spacing={0}>
                <Grid item xs={frameLink ? 7 : 12} sx={{ height: '100vh' }}>
                    <div style={{ height: '100vh', paddingTop: frameLink ? `${overallCustomBorderPadding}px` : '0px', }}>
                        <div style={{
                            height: `calc(100vh - ${frameLink ? overallCustomBorderPadding * 2 : 0}px)`,
                            paddingLeft: frameLink ? `${overallCustomBorderPadding}px` : '0px',
                            paddingBottom: frameLink ? `${overallCustomBorderPadding}px` : '0px',
                        }}>
                            <Card elevation={4} sx={{ height: `calc(100vh - ${frameLink ? overallCustomBorderPadding * 2 : 0}px)` }}>
                                <EditGpsMap
                                    handleSelectFrame={handleSelectFrame}
                                    selectedMarkerPoint={selectedMarkerPoint}
                                    handleFrame={handleFrame}
                                    handleDashboardBackButton={() => setOpenEditGpsDialog(false)}
                                    afterInference={true} showButton={false} setDisableHeader={false} setActiveStep={null}
                                    proid={proid} allCoordinates={filteredGpsData360} projectType={drProjectData.projectType}
                                    drProjectData={drProjectData} />
                            </Card>
                        </div>
                    </div>
                </Grid>

                {frameLink && <Grid item xs={5} sx={{ height: "100%" }}>
                    <div style={{
                        padding: `${assetVideoPlayerPadding}px ${assetVideoPlayerPadding}px 0px ${assetVideoPlayerPadding}px `,
                        height: `calc(${100}vh - ${assetVideoPlayerPadding * 2}px)`,
                    }}>
                        <div style={{ height: `calc(${100}vh - ${assetVideoPlayerPadding * 2}px)` }}>
                            <Card elevation={4} sx={{
                                height: '100%',
                                ...cardWhiteStyle,
                            }}>
                                <ImageViewer frameLink={frameLink} height={`calc(${100}vh - ${assetVideoPlayerPadding * 2}px)`} />
                            </Card>
                        </div>
                    </div>
                </Grid >}
            </Grid >
        </div >
    )
}
const mapStateToProps = (state) => {
    return {
        project: state.project,
        visualizerProjectPredictions: state.firestore.ordered.VisualizerProjectPredictions
    };
};
export default connect(mapStateToProps)(GpsEditDashboard);
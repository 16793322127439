const initState = {
  requiresAction: false,
  stripeSetupIntent: "",
  stripePaymentIntent: "",
  stripeSetupIntentLoader: false,

  stripePaymentIsDoneAndSent: null,
  stripePaymentMessage: '',

  isPaymentMethodActing: false,
  isPaymentMethodActed: false,
  paymentMessage: "",
  receiptGenerationMessage: "",
  receiptGenerationLoader: null,
  receiptGenerationIsDoneAndSent: null,
  isBuyProdActing: false,
  isBuyProdActed: false,
  buyProdActMessage: "",
  isDoneAndSent: false,
  isSending: false,
  transferingCreditsLoader: null,
  transferingCreditsMessage: '',
  transferingCreditsIsDoneAndSent: null
};

const paymentReducer = (state = initState, action) => {
  // SECTION 1
  if (action.type === "OPEN_STRIPE_PM_ACT_REQUEST" || action.type === "OPEN_STRIPE_PM_ACT_COMPLETE" || action.type === "OPEN_STRIPE_PM_ACT_FAILED") {
    return {
      ...state,
      isPaymentMethodActing: false,
      isPaymentMethodActed: false,
      stripeSetupIntentLoader: action.stripeSetupIntentLoader,
      paymentMessage: action.paymentMessage,
      stripeSetupIntent: action.stripeSetupIntent,
    };
  } else if (action.type === "STRIPE_PM_ACTING") {
    return {
      ...state,
      isPaymentMethodActing: true,
      isPaymentMethodActed: false,
      paymentMessage: "",
    };
  } else if (action.type === "STRIPE_PM_ACTED") {
    return {
      ...state,
      isPaymentMethodActing: false,
      isPaymentMethodActed: true,
      paymentMessage: action.paymentMessage,
    };
  } else if (action.type === "STRIPE_PM_ACT_FAILED") {
    return {
      ...state,
      isPaymentMethodActing: false,
      isPaymentMethodActed: false,
      paymentMessage: action.paymentMessage,
    };
  }

  // SECTION 2
  else if (action.type === "OPEN_STRIPE_PROD_ACT") {
    return {
      ...state,
      isBuyProdActing: false,
      isBuyProdActed: false,
      buyProdActMessage: action.buyProdActMessage,
      stripePaymentIntent: action.stripePaymentIntent,
    };
  } else if (action.type === "STRIPE_PROD_ACTING") {
    return {
      ...state,
      isBuyProdActing: true,
      isBuyProdActed: false,
      buyProdActMessage: "",
    };
  } else if (action.type === "STRIPE_PROD_ACTED") {
    return {
      ...state,
      isBuyProdActing: false,
      isBuyProdActed: true,
      buyProdActMessage: action.buyProdActMessage,
      stripePaymentIntent: action.stripePaymentIntent,
      stripePaymentIsDoneAndSent: action.stripePaymentIsDoneAndSent,
      stripePaymentMessage: action.stripePaymentMessage
    };
  } else if (action.type === "STRIPE_PROD_ACT_FAILED") {
    return {
      ...state,
      isBuyProdActing: false,
      isBuyProdActed: false,
      buyProdActMessage: action.buyProdActMessage,
      stripePaymentIntent: action.stripePaymentIntent,
      stripePaymentIsDoneAndSent: action.stripePaymentIsDoneAndSent,
      stripePaymentMessage: action.stripePaymentMessage
    };
  } else if (action.type === "REFRESH_STRIPE_PROD_ACT") {
    return {
      ...state,
      isBuyProdActing: false,
      isBuyProdActed: false,
    };
  }
  //  INVOICE
  // else if (action.type === "INVOICE_PROD_ACTING") {
  //   return {
  //     ...state,
  //     isBuyProdActing: true,
  //     isBuyProdActed: false,
  //     buyProdActMessage: "",
  //   };
  // } else if (action.type === "INVOICE_PROD_ACTED") {
  //   return {
  //     ...state,
  //     isBuyProdActing: false,
  //     isBuyProdActed: true,
  //     buyProdActMessage: action.buyProdActMessage,

  //   };

  // }
  else if (action.type === "CLEAR_RECEIPT_REQUEST") {
    return {
      ...state,
      receiptGenerationLoader: null,
      receiptGenerationIsDoneAndSent: null,
      receiptGenerationMessage: "",
    };
  }
  else if (action.type === "CLEAR_PRODUCT_REQUEST") {
    return {
      ...state,
      isBuyProdActing: false,
      isBuyProdActed: false,
      buyProdActMessage: "",
      stripePaymentIntent: "",
      stripePaymentMessage: '',
      stripePaymentIsDoneAndSent: null,
    };
  }
  // else if (action.type === "INVOICE_PROD_ACT_FAILED") {
  //   return {
  //     ...state,
  //     isBuyProdActing: false,
  //     isBuyProdActed: false,
  //     buyProdActMessage: action.buyProdActMessage,
  //     stripePaymentIntent: action.stripePaymentIntent,
  //   };
  // }
  else if (action.type === "TRANSFER_CREDITS_TO_PROJECTJAR_ACTING" || action.type === "TRANSFER_CREDITS_TO_PROJECTJAR_ACTED" || action.type === "TRANSFER_CREDITS_TO_PROJECTJAR_ACT_FAILED") {
    //////console.log(action)
    return {
      ...state,
      transferingCreditsMessage: action.transferingCreditsMessage,
      transferingCreditsLoader: action.transferingCreditsLoader,
      transferingCreditsIsDoneAndSent: action.transferingCreditsIsDoneAndSent ?? false,
    }
  }
  else if (action.type === "CLEAR_TRANSFER_CREDITS_TO_PROJECTJAR_ERROR_MSG") {
    //////console.log(action)
    return {
      ...state,
      transferingCreditsMessage: '',
      transferingCreditsLoader: null,
      transferingCreditsIsDoneAndSent: null,
    }
  }


  // receipt generation 
  else if (action.type === "RECEIPT_GENERATING" || action.type === "RECEIPT_GENERATED" || action.type === "RECEIPT_GENERATION_FAILED") {
    return {
      ...state,
      receiptGenerationLoader: action.receiptGenerationLoader,
      receiptGenerationIsDoneAndSent: action.receiptGenerationIsDoneAndSent ?? false,
      receiptGenerationMessage: action.receiptGenerationMessage,
    };
  }
  return state;
};

export default paymentReducer;

import { get } from 'lodash';
import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import CustomProgress from '../../../../cidgets/CustomProgress';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import * as ROUTES from "../../../../../constants/routes";
import { useGenerateSignedUrl } from '../../../../../chooks/useGenerateSignedUrl';
import DateParser from '../../../../../helpers/DateParser';
import POSTER from '../../../../../assets/bg/POSTER1.png';
import videojs from "video.js";
import "videojs-vr";
import "video.js/dist/video-js.css";

const FullScreen360VideoPlayer = (props) => {
    const { drProjectData, proid } = props;
    const [loading, setLoading] = useState(true);
    const playerRef = useRef(null);
    const videoRef = useRef(null);

    const { status, error, data } = useGenerateSignedUrl(drProjectData?.compiledVideoPath ?? "")
    const isFetched = status === 'fetched';
    useEffect(() => {
        const fetchData = async () => {
            setTimeout(() => {
                setLoading(false);
            }, 5000);
        };
        // Check if data is initially null or undefined
        if (drProjectData === undefined) {
            fetchData();
        } else {
            setLoading(false);
        }
    }, [drProjectData]);

    useEffect(() => {
        const videoElement = videoRef.current;
        if (!videoElement) return;

        const player = (playerRef.current = videojs(videoElement, options));

        player.one('play', () => {
            player.pause();
        });

        player.vr({ projection: "360" });

        const zoomHandlerWeb = (event) => {
            event.preventDefault();
            const vr = player.vr();
            const isWheelUp = event.deltaY < 0;
            const zoomStep = 10;  // Adjust this value to increase/decrease zoom sensitivity
            let fov = vr.camera.fov - (isWheelUp ? -zoomStep : zoomStep);

            if (fov < 30) {
                fov = 30;
            }

            if (fov > 115) {
                fov = 115;
            }

            vr.camera.fov = fov;
            vr.camera.updateProjectionMatrix();
        };

        const $video = player.el();
        $video.addEventListener('wheel', zoomHandlerWeb);

        return () => {
            $video.removeEventListener('wheel', zoomHandlerWeb);
        };
    }, [videoRef, data]);

    useEffect(() => {
        const player = playerRef.current;
        return () => {
            if (player) {
                player.dispose();
                playerRef.current = null;
            }
        };
    }, []);



    const options = {
        autoplay: true,
        controls: true,
        responsive: true,
        muted: true,
        fluid: true,
        preload: "auto",
        sources: [
            {
                src: data,
                type: "video/mp4",
            },
        ],
    };

    if (drProjectData === undefined) {
        if (loading) {
            return (
                <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CustomProgress text={"Loading data"} />
                </div>
            )
        }
        else {
            return <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ textAlign: 'center' }}>
                    <h6>No data found!</h6>
                    <Link
                        style={{ textDecoration: 'underline', color: 'black', cursor: 'pointer', marginTop: '0px' }}
                        to={`/p${ROUTES.CONTACT}`}
                        state={{ proid: proid, isProject: true }}
                    >  Click here</Link>&nbsp;to contact customer support team.
                    <div style={{ textAlign: 'center', marginTop: '10px' }}>
                        <Link
                            to={`/home/${ROUTES.HOME_DASHBOARD}`}
                        >
                            <Button variant='contained' sx={{
                                textTransform: 'initial', backgroundColor: '#025196', color: '#ffffff',
                                '&:hover': {
                                    backgroundColor: '#025196',
                                    color: '#ffffff'
                                }
                            }}
                            >Go back to home</Button>
                        </Link>
                    </div>
                </div>
            </div>
        }
    }

    if (drProjectData !== undefined && drProjectData.projectType !== 2) {
        if (loading) {
            return (
                <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CustomProgress text={"Loading data"} />
                </div>
            )
        }
        else {
            return <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ textAlign: 'center' }}>
                    <h6>This feature is not supported for this project type</h6>
                    <div style={{ textAlign: 'center', marginTop: '10px' }}>
                        <Link
                            to={`/home/${ROUTES.HOME_DASHBOARD}`}
                        >
                            <Button variant='contained' sx={{
                                textTransform: 'initial', backgroundColor: '#025196', color: '#ffffff',
                                '&:hover': {
                                    backgroundColor: '#025196',
                                    color: '#ffffff'
                                }
                            }}
                            >Go back to home</Button>
                        </Link>
                    </div>
                </div>
            </div>
        }
    }
    const { dateCreated } = drProjectData;
    const date = dateCreated ? DateParser(dateCreated, 0) : DateParser(dateCreated, 2);

    return (
        <div style={{ padding: '5px' }}>{
            isFetched ? <>
                <div data-vjs-player style={{ height: `calc(100vh - 10px)`, position: 'relative' }}>
                    <video
                        poster={POSTER}
                        height={`calc(100vh - 10px)`}
                        ref={videoRef}
                        className="video-js vjs-big-play-centered"
                        controlsList="nodownload"
                        crossOrigin="anonymous"
                        style={{ padding: '0px', height: `calc(100vh - 10px)` }}
                    />
                    <div
                        className="overlay"
                        style={{
                            position: 'absolute',
                            top: '10px',
                            left: '10px',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            color: 'white',
                            padding: '10px',
                            borderRadius: '5px',
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: '14px'
                        }}
                    >
                        {date}
                    </div>
                </div >
            </> :
                <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CustomProgress text={"Loading video"} />
                </div>
        }</div>
    )
}
const mapStateToProps = (state, ownProps) => {
    const { proid } = ownProps;
    return {
        drProjectData: get(
            state.firestore.data,
            `DrProject.${proid}`
        ),
    }
}
export default compose(
    connect(mapStateToProps),
    firestoreConnect((props) => {
        const { userData, proid } = props;
        return [
            {
                collection: "Projects",
                where: [['proid', '==', proid], ['coid', '==', userData?.coid]],
                storeAs: "DrProject",
            },

        ];
    })
)(FullScreen360VideoPlayer);

import { Alert, Box, Button, Card, Checkbox, CircularProgress, Collapse, Divider, Grid, IconButton, ListItem, ListItemIcon, ListItemText, Snackbar, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { connect } from 'react-redux'
import BasicImagingMapView from './BasicImagingMapView';
import BasicImagingTopbar from './BasicImagingTopbar';
import BasicImagingTable from './BasicImagingTable';
import PotholeTableFilters from '../potholeInspection/PotholeTableFilters';
import ArchivedBasicImagingViewer from './ArchivedBasicImagingViewer';
import SimpleDialogBox from '../../../../../cidgets/SimpleDialogBox';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { approveRejectFeatures, assignedFeatures, clearApproveRejectState, clearAssignedFeaturesState } from '../../../../../../store/actions/projectActions';
import AssignedBasicImagingTable from './AssignedBasicImagingTable';
import CompletedBasicImagingTable from './CompletedBasicImagingTable';
import ApprovedBasicImagingTable from './ApprovedBasicImagingTable';
import RejectedBasicImagingTable from './RejectedBasicImagingTable';
import { storage } from '../../../../../../config/firebaseConfig';
import { useAccessControlContext } from '../../../../../../contexts/AccessControlProvider';
import AccessControlAccountError from '../../../../../cidgets/AccessControlAccountError';
const BasicImagingGlobalController = (props) => {
    const { permissions, role } = useAccessControlContext();
    const { canApproveGlobalDefect, canAssignGlobalDefect, canSeeGlobalDefects, canUnarchiveGlobalDefect } = permissions;
    const getCurrentDate = () => {
        const currentDate = new Date();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const day = currentDate.getDate().toString().padStart(2, '0');
        const year = currentDate.getFullYear().toString();
        return `${month}/${day}/${year}`;
    }
    const statusMapping = {
        "Un-Assigned": 0,
        "Assigned": 1,
        "Compeleted": 2,
        "Approved": 3,
        "Rejected": 4
    }
    const { basicImagingGlobalDefects, datastore, linkedAccountUserData, project } = props;
    const { assignedFeatureLoader, assignedFeatureIsDoneAndSent, assignedFeatureMessage } = project;
    const { approveRejectMessage, approveRejectLoader, approveRejectIsDoneAndSent } = project;
    const { basicImagingData } = datastore;
    const { dataInfo: basicImagingDataInfo } = basicImagingData;
    const [defectImageURLs, setDefectImageURLs] = useState([]);

    const [headerDivDimen, setHeaderDivDimen] = useState({ height: 1, width: 1 })
    const [filteredData, setFilteredData] = useState([]);
    const [qualities, setQualities] = useState([])

    const [deadlineDate, setDeadlineDate] = useState(getCurrentDate());
    const [description, setDescription] = useState('');

    const [selectedList, setSelectedList] = useState([]);
    const [selectedValue, setSelectedValue] = useState('Un-Assigned');
    const [openAssignedList, setOpenAssignedList] = useState(false);
    const [selectedProject, setSelectedProject] = useState('');
    const [uniqueProjects, setUniqueProjects] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [open, setOpen] = useState(false);
    const [openApprovedDialog, setOpenApprovedDialog] = useState(false);
    const [openRejectedDialog, setOpenRejectedDialog] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const headerRef = useRef();
    const headerHeight = headerRef?.current?.clientHeight ?? 0;
    const overallCustomBorderPadding = 16;
    const tableHeight = 90;
    const mapHeight = 100;
    const cardWhiteStyle = {
        "borderRadius": "15px",
    }
    const calculateQuality = (score) => {
        // 0: low ,1:med,2:high
        if (score === 0) {
            return 2
        }

        if (score === 1) {
            return 1
        }

        if (score === 2) {
            return 0
        }
    }
    const featureStatus = statusMapping[selectedValue];
    const predictions = Object.values(basicImagingGlobalDefects);
    let detections = predictions.filter((eachDetection) => {
        return eachDetection.archived === false
    })
    detections.sort((a, b) => {
        return a.defectIdNum - b.defectIdNum
    })
    const timeList = useMemo(() => {
        return qualities.length === 0 ? detections : detections.filter((e) => qualities.includes(calculateQuality(e.priority)));
    }, [basicImagingGlobalDefects, qualities]);
    // const timeList = qualities.length === 0 ? detections : detections.filter((e) => qualities.includes(calculateQuality(e.priority)));

    useEffect(() => {
        const generateDefectImageURLs = async () => {

            const urls = {};
            await Promise.all(timeList.map(async defect => {
                const { defectIdNum, defectImagePath } = defect;
                try {
                    const storageRef = storage.ref(defectImagePath);
                    const downloadURL = await storageRef.getDownloadURL();
                    urls[defectIdNum] = downloadURL;
                } catch (error) {
                    console.error(`Error fetching download URL for defect with ID ${defectIdNum}:`, error);
                    urls[defectIdNum] = null;
                }
            }));
            setDefectImageURLs(urls);
        };

        generateDefectImageURLs();
    }, [timeList]);

    const priorityMap = {
        "map": basicImagingDataInfo['priority'],
        "keys": Object.keys(basicImagingDataInfo['priority']),
        "values": Object.values(basicImagingDataInfo['priority']),
        "usefulKeys": ['0', '1', '2'],
    }
    const statusMap = {
        "map": basicImagingDataInfo['status'],
        "keys": Object.keys(basicImagingDataInfo['status']),
        "values": Object.values(basicImagingDataInfo['status']),
        "usefulKeys": ['0', '1', '2', '3', '-3'],
    }
    const getVidWindowSize = () => {
        const newHeight = headerRef?.current?.clientHeight;
        const newWidth = headerRef?.current?.clientWidth;
        setHeaderDivDimen({
            height: newHeight,
            width: newWidth
        });
    };

    const handleQuality = (index) => {
        if (qualities.includes(index)) {
            setQualities([...qualities].filter((e) => e !== index))
        } else {
            setQualities([...qualities, index])
        }
    }
    const handleChange = (event) => {
        setSelectedValue(event.target.value);
        setSelectAll(false);
        setSelectedItems([]);
    };
    const handleProjectChange = (event) => {
        setSelectedProject(event.target.value);
        setSelectAll(false);
        setSelectedItems([]);
    };
    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedItems([]);
        } else {
            const allDefectIds = filteredData.map(e => e.did);
            setSelectedItems(allDefectIds);
        }
        setSelectAll(!selectAll);
    };
    useEffect(() => {
        window.addEventListener("load", setHeaderDivDimen(headerRef?.current?.clientHeight))
        window.addEventListener("resize", getVidWindowSize, true);
    }, [])
    // this useeffect is called on project based or status filtering.
    useEffect(() => {
        const filteredItems = timeList.filter(item => {
            if (selectedValue !== '' && selectedProject !== '') {
                return (
                    statusMap?.map[`${item.status}` === "-3" ? "4" : `${item.status}`]?.name === selectedValue &&
                    item.proid === selectedProject
                );
            } else if (selectedValue) {
                return statusMap?.map[`${item.status}` === "-3" ? "4" : `${item.status}`]?.name === selectedValue
            } else if (selectedProject) {
                return item.proid === selectedProject;
            }
            return true;
        });
        setFilteredData(filteredItems);
    }, [selectedValue, selectedProject, basicImagingGlobalDefects, timeList]);
    useEffect(() => {
        if (assignedFeatureIsDoneAndSent === true) {
            setOpen(false);
            setSelectedItems([]);
            setSelectAll(false);
            props.clearAssignedFeaturesState();
        }
    }, [assignedFeatureIsDoneAndSent])
    useEffect(() => {
        if (approveRejectIsDoneAndSent === true) {
            setOpenApprovedDialog(false);
            setOpenRejectedDialog(false);
            setSelectedItems([]);
            setSelectAll(false);
            props.clearApproveRejectState();
        }
    }, [approveRejectIsDoneAndSent])
    useEffect(() => {
        if (timeList) {
            const uniqueProjectsSet = new Set();

            timeList.forEach(item => {
                const { proid, projectTitle } = item;
                uniqueProjectsSet.add(`${proid}-${projectTitle}`);
            });
            const uniqueProjectsArray = Array.from(uniqueProjectsSet)
            setUniqueProjects(uniqueProjectsArray);
        }
    }, []);
    const handleToggle = () => {
        setOpenAssignedList(!openAssignedList);
    };
    const handleCheckboxToggle = (value) => () => {
        const selectedIndex = selectedList.indexOf(value);
        let newSelected = [...selectedList];
        if (newSelected.length === 0) {
            if (selectedIndex === -1) {
                newSelected.push(value);
            }
        }
        else {
            if (selectedIndex === -1) {
                newSelected = [];
                newSelected.push(value);
            }
            else {
                newSelected = [];
            }

        }
        setSelectedList(newSelected.length === 0 ? [] : [newSelected[0]]);
    };
    const handleAssigned = () => {
        if (selectedList.length === 0) {
            setAlertMessage('Please select assignee to assign this feature')
            setOpenAlert(true);
        }
        else {
            props.assignedFeatures(selectedItems, linkedAccountUserData?.uid, selectedList, deadlineDate);
        }
    }
    const handleApproved = () => {
        props.approveRejectFeatures(selectedItems, true);
    }
    const handleRejected = () => {
        props.approveRejectFeatures(selectedItems, false);
    }
    const handleOpenAssignFeature = () => {
        props.clearAssignedFeaturesState();
        setOpenAssignedList(false);
        setSelectedList([]);
        setDeadlineDate(getCurrentDate())
        setOpen(true);
    }
    const handleApprove = () => {
        setOpenApprovedDialog(true);
    }
    const handleReject = () => {
        setOpenRejectedDialog(true);
    }
    const featureStatusTableComponent = [
        <BasicImagingTable defectImageURLs={defectImageURLs} handleSelectAll={handleSelectAll} statusMap={statusMap} selectAll={selectAll} selectedItems={selectedItems} setSelectedItems={setSelectedItems} priorityMap={priorityMap} basicImagingData={basicImagingData} dataList={filteredData} />,
        <AssignedBasicImagingTable defectImageURLs={defectImageURLs} getCurrentDate={getCurrentDate} linkedAccountUserData={linkedAccountUserData} handleSelectAll={handleSelectAll} statusMap={statusMap} selectAll={selectAll} selectedItems={selectedItems} setSelectedItems={setSelectedItems} priorityMap={priorityMap} basicImagingData={basicImagingData} dataList={filteredData} />,
        <CompletedBasicImagingTable defectImageURLs={defectImageURLs} getCurrentDate={getCurrentDate} linkedAccountUserData={linkedAccountUserData} handleSelectAll={handleSelectAll} statusMap={statusMap} selectAll={selectAll} selectedItems={selectedItems} setSelectedItems={setSelectedItems} priorityMap={priorityMap} basicImagingData={basicImagingData} dataList={filteredData} />,
        <ApprovedBasicImagingTable defectImageURLs={defectImageURLs} getCurrentDate={getCurrentDate} linkedAccountUserData={linkedAccountUserData} handleSelectAll={handleSelectAll} statusMap={statusMap} selectAll={selectAll} selectedItems={selectedItems} setSelectedItems={setSelectedItems} priorityMap={priorityMap} basicImagingData={basicImagingData} dataList={filteredData} />,
        <RejectedBasicImagingTable defectImageURLs={defectImageURLs} getCurrentDate={getCurrentDate} linkedAccountUserData={linkedAccountUserData} handleSelectAll={handleSelectAll} statusMap={statusMap} selectAll={selectAll} selectedItems={selectedItems} setSelectedItems={setSelectedItems} priorityMap={priorityMap} basicImagingData={basicImagingData} dataList={filteredData} />
    ];
    if (!basicImagingDataInfo) {
        return <></>
    }
    return (
        !canSeeGlobalDefects ?
            <AccessControlAccountError access={role} />
            :
            <>
                <Snackbar open={openAlert} autoHideDuration={3000} onClose={() => { setOpenAlert(false) }}>
                    <Alert
                        onClose={() => { setOpenAlert(false) }}
                        severity="error"
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        {alertMessage}
                    </Alert>
                </Snackbar>
                <SimpleDialogBox
                    open={open}
                    onClose={() => { setOpen(false) }}
                    title={`${featureStatus === 1 || featureStatus === 4 ? 'Reassign' : 'Assign'} ${selectedItems.length === 1 ? "Feature" : "Features"}`}
                    shouldTitleAppear={true}
                    showError={assignedFeatureIsDoneAndSent === false ? true : false}
                    errorMessage={assignedFeatureIsDoneAndSent === false ? assignedFeatureMessage : ''}
                    cancelText="Close"
                    bodyWidget={
                        <>
                            <Grid container spacing={0}>
                                <Box sx={{ marginBottom: '10px' }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker', 'DatePicker']}>
                                            <DatePicker
                                                label="Deadline"
                                                value={dayjs(deadlineDate)}
                                                onChange={(newValue) => setDeadlineDate(dayjs(newValue).format('MM/DD/YYYY'))}
                                                disablePast
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Box>
                                <Card elevation={0} sx={{ borderRadius: openAssignedList ? '15px 15px 0px 0px' : '15px', display: 'flex', alignItems: 'center', padding: '8px 16px 8px 16px' }}>
                                    <Grid item xs={11}>
                                        <Typography>Assignees</Typography>
                                    </Grid>
                                    <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <IconButton onClick={handleToggle}>
                                            {openAssignedList ? <Tooltip title="Close"><ExpandLess /></Tooltip>
                                                : <Tooltip title="Expand to see employee list"><ExpandMore /></Tooltip>}
                                        </IconButton>
                                    </Grid>
                                </Card>
                            </Grid>
                            <Card elevation={1} sx={{ borderRadius: '0px 0px 15px 15px' }}>
                                <Collapse key={openAssignedList} in={openAssignedList} timeout="auto" unmountOnExit>
                                    {/* <BasicImagingFeatureAssigned setMirrorProjectEmail={setMirrorProjectEmail} linkedAccountUserData={linkedAccountUserData}></BasicImagingFeatureAssigned> */}
                                    {linkedAccountUserData && Object.values(linkedAccountUserData?.accessCodes).map((each, i) => (
                                        <ListItem key={i}>
                                            <ListItemIcon>
                                                <Checkbox edge="start" tabIndex={-1} disableRipple
                                                    onChange={handleCheckboxToggle(`${each.accessCode}`)}
                                                    checked={selectedList.length === 0 ? false : selectedList.indexOf(`${each.accessCode}`) !== -1}
                                                />
                                            </ListItemIcon>
                                            <ListItemText primary={each.name} />
                                        </ListItem>
                                    ))}
                                </Collapse>
                            </Card>
                        </>

                    }
                    width='700'
                    showPositive
                    positiveButtonWidget={
                        <>
                            {assignedFeatureLoader ?
                                <>
                                    <Button variant="contained" disabled sx={{
                                        textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                                        "&.Mui-disabled": {
                                            backgroundColor: '#025196',
                                            color: '#FFFFFF'
                                        }
                                    }}>
                                        <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} /><span style={{ paddingLeft: '15px' }}>Assigning...</span>
                                    </Button>
                                </>
                                :

                                <Button size="medium" variant="contained"
                                    onClick={handleAssigned}
                                    sx={{
                                        textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                                        '&:hover': {
                                            backgroundColor: '#025196',
                                            color: '#FFFFFF'
                                        }
                                    }}>Confirm</Button>
                            }
                        </>
                    }
                />
                <SimpleDialogBox
                    open={openApprovedDialog}
                    onClose={() => { setOpenApprovedDialog(false) }}
                    title={`Approve ${selectedItems.length === 1 ? "Feature" : "Features"}`}
                    shouldTitleAppear={true}
                    cancelText="Close"
                    showError={approveRejectIsDoneAndSent === false ? true : false}
                    errorMessage={approveRejectIsDoneAndSent === false ? approveRejectMessage : ''}
                    bodyWidget={
                        <>Are you sure you want to approve?</>
                    }
                    width='700'
                    showPositive
                    positiveButtonWidget={
                        <>
                            {approveRejectLoader ?
                                <>
                                    <Button variant="contained" disabled sx={{
                                        textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                                        "&.Mui-disabled": {
                                            backgroundColor: '#025196',
                                            color: '#FFFFFF'
                                        }
                                    }}>
                                        <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} /><span style={{ paddingLeft: '15px' }}>Approving...</span>
                                    </Button>
                                </>
                                :

                                <Button size="medium" variant="contained"
                                    onClick={handleApproved}
                                    sx={{
                                        textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                                        '&:hover': {
                                            backgroundColor: '#025196',
                                            color: '#FFFFFF'
                                        }
                                    }}>Confirm</Button>
                            }
                        </>
                    }
                />
                <SimpleDialogBox
                    open={openRejectedDialog}
                    onClose={() => { setOpenRejectedDialog(false) }}
                    title={`Reject ${selectedItems.length === 1 ? "Feature" : "Features"}`}
                    shouldTitleAppear={true}
                    cancelText="Close"
                    showError={approveRejectIsDoneAndSent === false ? true : false}
                    errorMessage={approveRejectIsDoneAndSent === false ? approveRejectMessage : ''}
                    bodyWidget={
                        <>Are you sure you want to reject?</>
                    }
                    width='700'
                    showPositive
                    positiveButtonWidget={
                        <>
                            {approveRejectLoader ?
                                <>
                                    <Button variant="contained" disabled sx={{
                                        textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                                        "&.Mui-disabled": {
                                            backgroundColor: '#025196',
                                            color: '#FFFFFF'
                                        }
                                    }}>
                                        <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} /><span style={{ paddingLeft: '15px' }}>Rejecting...</span>
                                    </Button>
                                </>
                                :

                                <Button size="medium" variant="contained"
                                    onClick={handleRejected}
                                    sx={{
                                        textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                                        '&:hover': {
                                            backgroundColor: '#025196',
                                            color: '#FFFFFF'
                                        }
                                    }}>Confirm</Button>
                            }
                        </>
                    }
                />
                <Box sx={{
                    height: '100vh', width: '100vw',
                    backgroundColor: '#E1E3E2',
                }}>
                    <Grid container spacing={0}>
                        <Grid item xs={7} sx={{ backgroundColor: 'transparent', height: `calc(100vh - ${0}px)`, padding: '' }}>
                            <div ref={headerRef} style={{ padding: `${overallCustomBorderPadding}px 0px ${overallCustomBorderPadding}px ${overallCustomBorderPadding}px`, backgroundColor: '' }}>
                                <BasicImagingTopbar basicImagingDataInfo={basicImagingDataInfo} detections={detections} uniqueProjects={uniqueProjects} selectedProject={selectedProject} handleProjectChange={handleProjectChange}
                                    filteredData={filteredData} selectedValue={selectedValue} handleChange={handleChange} />
                            </div>
                            <div style={{ height: `calc(100vh - ${headerHeight}px)`, backgroundColor: '' }}>
                                <div style={{ height: `calc(100vh  - ${headerHeight}px - ${overallCustomBorderPadding}px)`, paddingLeft: `${overallCustomBorderPadding}px`, paddingBottom: `${overallCustomBorderPadding}px`, backgroundColor: '' }}>
                                    <Card elevation={4} sx={{ ...cardWhiteStyle, height: `calc(100vh  - ${headerHeight}px - ${overallCustomBorderPadding}px)` }}>
                                        <div style={{ height: `${tableHeight}%`, backgroundColor: '', }}>
                                            {featureStatusTableComponent[featureStatus]}
                                        </div>
                                        <div style={{ height: `10%`, backgroundColor: '', }}>
                                            <Divider light />
                                            <Grid container>
                                                <Grid item xs={8}>
                                                    {selectedItems.length > 0 ?
                                                        <Box style={{ backgroundColor: '' }} sx={{ display: { xs: 'none', md: 'flex' }, flexDirection: 'row', alignItems: 'center', justifyContent: 'start', padding: '20px' }}>
                                                            {featureStatus === 2 ?
                                                                <>
                                                                    <Button variant="contained" size="small"
                                                                        disabled={!canApproveGlobalDefect}
                                                                        sx={{
                                                                            whiteSpace: 'nowrap', display: 'flex', alignItems: 'center',
                                                                            marginInline: '10px',
                                                                            textTransform: 'initial',
                                                                            backgroundColor: '#025196', ':hover': {
                                                                                backgroundColor: '#025196'
                                                                            },
                                                                        }} onClick={() => { handleApprove() }}>Approve {selectedItems.length === 1 ? 'feature' : 'features'}</Button>
                                                                    <Button variant="contained" size="small"
                                                                        disabled={!canApproveGlobalDefect}
                                                                        sx={{
                                                                            whiteSpace: 'nowrap', display: 'flex', alignItems: 'center',
                                                                            marginInline: '10px',
                                                                            textTransform: 'initial',
                                                                            backgroundColor: '#025196', ':hover': {
                                                                                backgroundColor: '#025196'
                                                                            },
                                                                        }} onClick={() => { handleReject() }}>Reject {selectedItems.length === 1 ? 'feature' : 'features'}</Button>
                                                                </>
                                                                :
                                                                <Button variant="contained" size="small"
                                                                    disabled={!canAssignGlobalDefect}
                                                                    sx={{
                                                                        whiteSpace: 'nowrap', display: 'flex', alignItems: 'center',
                                                                        marginInline: '10px',
                                                                        textTransform: 'initial',
                                                                        backgroundColor: '#025196', ':hover': {
                                                                            backgroundColor: '#025196'
                                                                        },
                                                                    }} onClick={() => { handleOpenAssignFeature() }}>{featureStatus === 1 || featureStatus === 4 ? "Reassign" : "Assign"} {selectedItems.length === 1 ? 'feature' : 'features'}</Button>
                                                            }

                                                        </Box>
                                                        :
                                                        <PotholeTableFilters qualities={qualities} handleQuality={handleQuality} />
                                                    }
                                                </Grid>
                                                <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                                                    {!canUnarchiveGlobalDefect ? <></> : <ArchivedBasicImagingViewer basicImagingData={basicImagingData} detections={Object.values(basicImagingGlobalDefects)} />}
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Card>
                                </div>
                            </div>

                        </Grid>
                        <Grid item xs={5} sx={{ backgroundColor: 'transparent', height: `calc(100vh - ${0}px)` }}>
                            <div id='asset-map' style={{ padding: `${overallCustomBorderPadding}px ${overallCustomBorderPadding}px ${overallCustomBorderPadding}px ${overallCustomBorderPadding}px`, height: `calc(${mapHeight}vh - ${overallCustomBorderPadding * 2}px)`, backgroundColor: 'transparent' }}>
                                <Card elevation={4} sx={{ ...cardWhiteStyle, background: 'transparent' }}>
                                    <div style={{ height: `calc(${mapHeight}vh - ${overallCustomBorderPadding * 2}px)` }}>
                                        <BasicImagingMapView filteredData={filteredData} basicImagingGlobalDefects={basicImagingGlobalDefects} />
                                    </div>
                                </Card>
                            </div>
                        </Grid>
                    </Grid>
                </Box >
            </>
    )
}
const mapStateToProps = (state) => {
    return {
        basicImagingGlobalDefects: state.firestore.data.Defects,
        datastore: state.datastore,
        project: state.project,
        userData: state.firebase.profile,
        linkedAccountUserData: state.firestore.data.linkedAccoundUserData,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        assignedFeatures: (didList, uid, code, deadline) => dispatch(assignedFeatures(didList, uid, code, deadline)),
        clearAssignedFeaturesState: () => dispatch(clearAssignedFeaturesState()),
        approveRejectFeatures: (didList, approval) => dispatch(approveRejectFeatures(didList, approval)),
        clearApproveRejectState: () => dispatch(clearApproveRejectState()),
    };
};
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect((props) => {
        const { userData } = props;
        return [
            {
                collection: "Users",
                storeAs: "linkedAccoundUserData",
                doc: `${userData.linkedAccounts ? userData?.linkedAccounts[0] ?? `-1` : `-1`}`,
            }
        ];
    })
)(BasicImagingGlobalController);

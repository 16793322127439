import React, { useEffect, useRef, useState } from 'react'
import { LayerGroup, LayersControl, MapContainer, Polyline, TileLayer, Tooltip, Circle, useMap, Marker } from 'react-leaflet'
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import marker from '../../../../../../assets/pin_sm.svg';
import { Card, CircularProgress } from '@mui/material';
import { connect } from 'react-redux';
import SmartUtils from '../../../../../../helpers/SmartUtils';
import InfoIcon from '@mui/icons-material/Info';
import { Tooltip as MuiTooltip } from '@mui/material';
const BasicImagingMapView = (props) => {
    const { basicImagingGlobalDefects, filteredData } = props;
    const iconSize = [26 / 1, 38 / 1];

    const mapCenter = filteredData.length > 0 ? [filteredData[0]['geoLat'], filteredData[0]['geoLong']] :
        [Object.values(basicImagingGlobalDefects)[0]['geoLat'], Object.values(basicImagingGlobalDefects)[0]['geoLong']];
    return (
        <div style={{ height: '100%' }}>
            <MapContainer attributionControl={false} center={mapCenter} zoom={16} scrollWheelZoom={true} maxZoom={25}>
                <TileLayer
                    attribution=''
                    maxZoom={25}
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {filteredData.map((e, i) => {
                    const position = [e.geoLat, e.geoLong];
                    const statusColor = SmartUtils.statusIndicatorColor(e.status);
                    const customIcon = new L.Icon({
                        iconUrl: `data:image/svg+xml,${encodeURIComponent(
                            `<svg width="26" height="38" viewBox="0 0 26 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.6364 38L7 21.75L19.75 22.25L13.6364 38Z" fill="${statusColor}" />
                            <circle cx="13" cy="13" r="13" fill="${statusColor}" />
                            <circle cx="12.75" cy="12.75" r="9.25" fill="white" />
                          </svg>
                        ` )}`,
                        iconSize: iconSize,
                        iconAnchor: [iconSize[0] / 2, iconSize[1]],
                        popupAnchor: [-0, -0],
                    });
                    return (
                        <Marker
                            key={i}
                            position={position}
                            icon={customIcon}
                        >
                            <Tooltip>{e.defectIdNum}</Tooltip>
                        </Marker>
                    );
                })}
            </MapContainer >
        </div>
    )
}

export default BasicImagingMapView
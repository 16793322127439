import { Card, Divider, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, Stack, Toolbar, Tooltip, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import RouteIcon from '@mui/icons-material/Route';
import React from 'react';
import DistanceTimeCalcHelper from '../../../../../helpers/Distance_Time_CalcHelper';
import HomeIcon from '@mui/icons-material/Home';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { useLocation, useNavigate } from 'react-router-dom';
import * as ROUTES from '../../../../../constants/routes';
import HubIcon from '@mui/icons-material/Hub';
const SavedSuperSectionList = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const selectedNetworkId = location.state?.selectedNetworkId ? true : false;
  const { data, onListViewClick, onDeleteListItem, handleCloseSavedMapWidget, showSavedSuperSectionMap, onNavigate } = props;
  const cardStyle = {
    height: "100vh",
    background: '#FFFFFF',
    padding: '5px'
  };
  return (
    <>
      <Card sx={cardStyle}>
        <Grid container spacing={0} sx={{ height: '8%', background: '' }}>
          <Grid item xs={6} sx={{ background: '', display: 'flex', justifyContent: 'start', alignItems: 'end', paddingLeft: '14px' }}>
            <h6 style={{ fontWeight: 'bold', fontSize: '17px' }}>Saved supersection</h6>
          </Grid>
          <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'end', paddingRight: '14px' }}>
            <Tooltip title="Go to dashboard to view all your networks" arrow placement='top'>
              <IconButton aria-label="route"
                onClick={() => { navigate(`/home/${ROUTES.HOME_DASHBOARD_NETWORK}`) }}
              >
                <HomeIcon />
              </IconButton>
            </Tooltip>

            {selectedNetworkId ? <Tooltip title="Go back to network" arrow placement='top'>
              <IconButton aria-label="route"
                onClick={() => { navigate(`/home/net/${location.state.selectedNetworkId}`) }}
              >
                <HubIcon />
              </IconButton>
            </Tooltip> : <></>}
            {showSavedSuperSectionMap ? <Tooltip title="Add new supersection" arrow placement='top'>
              <IconButton aria-label="route"
                onClick={onNavigate}
              >
                <AddIcon />
              </IconButton>
            </Tooltip> : <></>}
            {showSavedSuperSectionMap ? <Tooltip title="Close map view" arrow placement='top'>
              <IconButton aria-label="route"
                onClick={handleCloseSavedMapWidget}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip> : <></>}
          </Grid>
          <Divider light sx={{ width: '100%' }} />
        </Grid>
        <Grid container spacing={0} sx={{ height: '92%', overflow: 'auto' }}>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'start', alignItems: 'start' }}>
            <List component="nav" sx={{ height: '100%', width: '100%' }}>
              {data && data.length > 0 ? data.map((item, index) => (
                <>
                  <ListItem key={index} sx={{ cursor: 'pointer' }}>
                    <ListItemText
                      onClick={() => onListViewClick(item.encodedGeometry)}
                      primary={<Typography variant="body1" sx={{ fontWeight: 'bold', color: '#000000' }}>
                        {item.superSectionTitle ?? 'Unknown'}
                      </Typography>}
                      secondary={
                        <Typography variant="body2" sx={{ color: '#000000' }}>
                          {`This route encompasses a distance of ${DistanceTimeCalcHelper.calculateDistance(item.distance)}`}
                        </Typography>
                      }
                    />
                    <ListItemIcon sx={{ background: '', display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                      <Tooltip title="Delete" arrow placement='left'>
                        <IconButton onClick={() => { onDeleteListItem(item.superSectionId) }} >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </ListItemIcon>
                  </ListItem >
                  <Divider component="li" />
                </>
              )) : <div style={{ width: '100%', height: '70vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Stack direction="column">
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <RouteIcon sx={{ fontSize: '70px', filter: 'invert(5%) sepia(58%) saturate(2690%) hue-rotate(221deg) brightness(93%) contrast(116%)', }} />
                  </div>
                  <Toolbar>
                    <Typography align="center" style={{ fontSize: '16px', padding: '40px' }}>
                      No existing supersection found. To begin, create a new supersection
                    </Typography>
                  </Toolbar>
                </Stack>
              </div >}
            </List>
          </Grid>
        </Grid>
      </Card >
    </>
  )
}

export default SavedSuperSectionList




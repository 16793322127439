// reducers/firestoreReducer.js
const initialState = {
    superSectionId: null
  };
  
  const firestoreReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'UPDATE_FIRESTORE_QUERY':
        return {
          ...state,
          superSectionId: action.payload
        };
      default:
        return state;
    }
  };
  
  export default firestoreReducer;
  
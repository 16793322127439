import React from 'react';
import Slider from '@mui/material/Slider';
import { useState } from 'react';
import { useEffect } from 'react';

const SimpleSlider = ({ defaultValue = 0, minValue = 0, maxValue = 10, minCredit = 10, maxCredit = 10000, onValueChange = (value = 0) => { } }) => {
    const [value, setValue] = useState(defaultValue);
    const [mappedValue, setMappedValue] = useState(10)

    useEffect(() => {
        onValueChange(mappedValue)
    }, [mappedValue])

    const formulaOne = (value) => {
        // 0 - 49
        return roundToNearest(Number(valueMapping(value, 0, (maxValue / 2) - 1, minCredit, 1000).toFixed(0)), 10)
    }

    const formulaTwo = (value) => {
        // 50 - 100 
        const newValue = roundToNearest(Number(valueMapping(value, (maxValue / 2), maxValue, 1200, maxCredit - 1000).toFixed(0)), 10)
        return newValue >= maxCredit ? maxCredit : newValue;
    }

    const valueMapping = (value, inMin, inMax, outMin, outMax) => {
        return (value - inMin) * (outMax - inMin) / (inMax - inMin) + outMin;
    }

    const applyFormula = (value = 0) => {
        return value >= 0 && value < maxValue / 2 ? formulaOne(value) : formulaTwo(value)
    }

    const handleChange = (event, newValue) => {
        setValue(newValue)
        setMappedValue(applyFormula(newValue))
    };

    // const handleValueLabelFormat = () => {
    //     return Math.round(value); // display rounded linear value

    // };
    const handleValueLabelFormat = (value) => {
        return applyFormula(value);
    };
    const roundToNearest = (value, multiple) => {
        return Math.round(value / multiple) * multiple;
    }

    const generateMarks = () => {
        const totalSliderMarks = [...Array(Number(maxValue)).keys()]
        const nonLinearMarks = totalSliderMarks.map((e) => {
            const value = applyFormula(e)
            return {
                value: e,
                label: value.toString()
            }
        })
        // //////console.log(nonLinearMarks.some((e) => e.label !== "10000"))
        if (nonLinearMarks.some((e) => e.label !== `${maxCredit}`)) {
            nonLinearMarks.push({
                value: 10,
                label: `${maxCredit}`
            })
        }
        // //////console.log(nonLinearMarks)
        return nonLinearMarks
    };

    return (
        <>

            <Slider
                min={minValue} max={maxValue}
                marks={generateMarks()}
                value={value}
                onChange={handleChange}
                valueLabelDisplay="auto"
                valueLabelFormat={handleValueLabelFormat}
                step={0.01}
                sx={{
                    width: '100%',
                    // mt: 2,
                    // px: 2,
                    color: '#025196', // set the background color of the slider
                    '& .MuiSlider-thumb': {
                        height: 16, // Set the height of the thumb
                        width: 16, // Set the width of the thumb
                    },
                    '& .MuiSlider-mark': {
                        backgroundColor: '#025196',
                        height: 7,
                        width: 2,
                        boxShadow: '0px 0px 5px 2px #fafafa', // Add a box shadow to increase stickiness

                    },
                    '& .MuiSlider-markLabel': {
                        color: '#000000',
                        fontWeight: 'normal',
                    },
                }}
            />
            {/* <p>{mappedValue}</p> */}
        </>
    );
}

export default SimpleSlider;

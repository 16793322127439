import {
    Button, Card, Chip, FormControl, FormControlLabel, FormGroup, Grid, IconButton, Switch,
    TextField, Typography, CircularProgress, Tooltip, Divider
} from '@mui/material'
import React, { useState } from 'react'
import { grey } from '@mui/material/colors';
import EditIcon from '@mui/icons-material/Edit';
import PlaceIcon from '@mui/icons-material/Place';
import CloseIcon from '@mui/icons-material/Close';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { alpha, styled } from '@mui/material/styles';
import HomeIcon from '@mui/icons-material/Home';
import SimpleDialogBox from '../../../cidgets/SimpleDialogBox';
import moment from 'moment';
const BlackSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: grey[900],
        '&:hover': {
            backgroundColor: alpha(grey[900], theme.palette.action.hoverOpacity),
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: grey[900],
    },
}));
const label = { inputProps: { 'aria-label': 'Color switch demo' } };
const RoutePlannerCard = ({ getToSpecificRouteLocation, routeTitleError, setRouteTitleError,
    saveRouteCalculationToDbIsDoneAndSent, saveRouteCalculationToDbMessage, routeTitle,
    setRouteTitle, saveRouteCalculation,
    routeCalculationData, setDraggable, draggable,
    showFeildError, getToCenterOfUserLocation, handleClearFields, handleAddField, fields,
    handleChange, handleMarkerRemove, markerPositions = [], routeCalculationLoader,
    calculateRoute, saveRouteCalculationToDbLoader, savedRouteScreen }) => {
    const cardStyle = {
        height: "calc(100vh - 0px)",
        padding: '0px',
    };
    const convertSecondsToMinutes = (seconds) => {
        const duration = moment.duration(seconds, 'seconds');
        return duration.humanize();
    };
    const formatDistance = (value, unit) => {
        return `${Number(value).toFixed(2)} ${unit}`;
    };

    const metersToKilometers = (meters) => {
        const kilometers = meters / 1000;
        return formatDistance(kilometers, 'km');
    };

    const calculateDistance = (distance) => {
        return distance > 1000 ? metersToKilometers(distance) : formatDistance(distance, 'm');
    };

    const [titleRouteDialog, setTitleRouteDialog] = useState(false);
    const handleOpenDialog = () => {
        setRouteTitle('');
        setRouteTitleError(false);
        setTitleRouteDialog(true);
    }
    const handleCloseDialog = () => {
        setTitleRouteDialog(false);
    }
    const handleRouteTitleChange = (e) => {
        setRouteTitleError(false);
        setRouteTitle(e.target.value);
    }
    return (
        <>
            <SimpleDialogBox
                open={titleRouteDialog}
                onClose={handleCloseDialog}
                title="Route name"
                cancelText="Close"
                showError={saveRouteCalculationToDbIsDoneAndSent === false ? true : false}
                errorMessage={saveRouteCalculationToDbIsDoneAndSent === false ? saveRouteCalculationToDbMessage : ''}
                bodyWidget={
                    <>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <TextField fullWidth label="Name" id="fullWidth" value={routeTitle}
                                    disabled={saveRouteCalculationToDbLoader}
                                    error={routeTitleError}
                                    helperText={routeTitleError ? "Invalid title" : ''}
                                    onChange={(e) => { handleRouteTitleChange(e) }} />
                            </Grid>
                        </Grid>
                    </>
                }
                width={700}
                showPositive
                positiveButtonWidget={
                    saveRouteCalculationToDbLoader ? (
                        <Button
                            variant="contained"
                            disabled
                            size="small"
                            sx={{
                                marginLeft: '20px',
                                textTransform: 'initial',
                                backgroundColor: '#025196',
                                color: '#ffffff',
                                "&.Mui-disabled": {
                                    backgroundColor: '#025196',
                                    color: '#ffffff',
                                }
                            }}
                        >
                            <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} />
                            <span style={{ paddingLeft: '15px' }}>Saving...</span>
                        </Button>
                    ) : (
                        <Button
                            variant='contained'
                            size="small"
                            sx={{
                                marginLeft: '20px',
                                textTransform: 'none',
                                paddingInline: '18px',
                                marginInline: '10px',
                                display: { xs: "none", sm: "none", md: 'flex' },
                                color: '#ffffff',
                                backgroundColor: '#025196', ':hover': {
                                    backgroundColor: '#025196',
                                    color: '#ffffff'
                                },
                            }}
                            onClick={saveRouteCalculation}
                        >
                            Save
                        </Button>
                    )
                }
            />

            <Card sx={cardStyle}>

                <Grid container spacing={0} sx={{ height: '8%', background: '' }}>
                    <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'start', alignItems: 'end', paddingLeft: '10px' }}>
                        <Typography style={{ fontWeight: 'bold' }} variant='body1'>Parameters</Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'end' }}>

                        <Tooltip title="Back to saved routes" arrow placement='top'>
                            <IconButton aria-label="saved" onClick={savedRouteScreen}>
                                <HomeIcon />
                            </IconButton>
                        </Tooltip>

                    </Grid>
                    <Divider light sx={{ width: '100%' }} />
                </Grid>

                <div style={{
                    height: '8%', marginTop: '0px', marginBottom: '0px', background: '', paddingLeft: '10px'
                }}>

                    <Typography variant="body1">Coordinates</Typography>
                    <Typography variant="body1" style={{ color: "grey", marginTop: "5px" }}>Up to 25 waypoints (i.e longitude,latitude pairs).</Typography>
                </div >
                <div style={{ height: Object.values(fields).length > 10 ? '55%' : 'auto', background: '', overflowX: 'hidden', padding: '10px' }}>

                    {fields.map(field => (
                        <Grid container spacing={0} sx={{}}>
                            <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                                {field.value.lat !== '' && field.value.lng !== '' ?
                                    <Tooltip title={'Navigate to this location'} placement='top'>
                                        <PlaceIcon fontSize='small' sx={{ cursor: 'pointer' }} onClick={() => { getToSpecificRouteLocation(field.value.lat, field.value.lng) }} />
                                    </Tooltip>
                                    : <EditIcon fontSize='small' />}
                            </Grid>
                            <Grid item
                                xs={field.value.lat !== '' && field.value.lng !== '' ? 10 : 11}
                                sx={{ display: 'flex', alignItems: 'start', justifyContent: 'center' }}>
                                <TextField
                                    error={showFeildError}
                                    size='small'
                                    id="text-field"
                                    disabled
                                    type='input'
                                    value={Object.values(field.value).length === 2 ? field.value.lat !== '' && field.value.lng !== '' ? `${Number(field.value.lat).toFixed(6)}, ${Number(field.value.lng).toFixed(6)}` : '' : ''}
                                    onChange={(event) => {
                                        handleChange(field.id, event)
                                    }}
                                    sx={{
                                        backgroundColor: grey[100], marginBottom: '3px', width: '100%', '& .MuiInputBase-input': {
                                            padding: '4.5px 14px'
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item
                                xs={field.value.lat !== '' && field.value.lng !== '' ? 1 : 0}
                                sx={{ display: 'flex', alignItems: 'start', justifyContent: 'start' }}>
                                {field.value.lat !== '' && field.value.lng !== '' ? <IconButton
                                    aria-label="remove marker"
                                    onClick={() => { handleMarkerRemove(field.id) }}
                                    sx={{
                                        color: 'black', cursor: 'pointer', '&:hover': {
                                            backgroundColor: 'transparent',


                                        },
                                    }}

                                >
                                    <CloseIcon fontSize='small' />
                                </IconButton> : <></>}
                            </Grid>
                        </Grid>
                    ))}
                </div>
                <div style={{ marginTop: '0px', height: '5%', background: '', padding: '10px' }}>
                    <Grid container spacing={0}>
                        <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                            <Tooltip title={'Current location'} placement='bottom'>
                                <MyLocationIcon fontSize='small' sx={{ cursor: 'pointer' }} onClick={getToCenterOfUserLocation} />
                            </Tooltip>
                        </Grid>
                        <Grid item xs={5}>
                            <Chip disabled={Object.values(markerPositions).length < 2 || Object.values(fields).length === 25 || Object.values(fields).length > Object.values(markerPositions).length ? true : false} label="Add a coordinate" variant="outlined" onClick={handleAddField} />
                        </Grid>

                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                            <Typography
                                variant="body1"
                                onClick={handleClearFields}
                                sx={{
                                    cursor: "pointer",
                                    textDecoration: "none",

                                }}
                            >
                                Clear coordinates
                            </Typography>
                        </Grid>

                    </Grid>
                </div>
                <div style={{ marginTop: '0px', height: '5%', background: '', padding: '10px' }}>
                    <Grid container spacing={0}>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                            <FormControl component="fieldset">
                                <FormGroup aria-label="position" row
                                    style={{ marginTop: "10px" }}
                                >
                                    <FormControlLabel
                                        value=""
                                        control={
                                            <BlackSwitch {...label} size='small' sx={{ color: 'black' }} checked={draggable}
                                                onChange={() => { setDraggable(!draggable) }} />}
                                        label="Switch to drag markers"
                                        labelPlacement="end"

                                    />
                                </FormGroup>
                            </FormControl>
                        </Grid>

                    </Grid>
                </div>
                <div style={{ marginTop: '0px', height: '5%', background: '', padding: '10px' }}>
                    <Grid container spacing={0}>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                            <>
                                {Object.values(markerPositions).length < 2 ? <></> : routeCalculationLoader ? (
                                    <Button
                                        variant="contained"
                                        disabled
                                        size="small"
                                        sx={{
                                            textTransform: 'initial',
                                            backgroundColor: '#025196',
                                            color: '#ffffff',
                                            "&.Mui-disabled": {
                                                backgroundColor: '#025196',
                                                color: '#ffffff'
                                            }
                                        }}
                                    >
                                        <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} />
                                        <span style={{ paddingLeft: '15px' }}>Evaluating...</span>
                                    </Button>
                                ) : (
                                    <Button
                                        size="small"
                                        variant="contained" sx={{
                                            textTransform: 'none',
                                            paddingInline: '18px',
                                            marginInline: '10px',
                                            display: { xs: "none", sm: "none", md: 'flex' },
                                            color: '#ffffff',
                                            backgroundColor: '#025196', ':hover': {
                                                backgroundColor: '#025196',
                                                color: '#ffffff'
                                            },
                                            "&.Mui-disabled": {
                                                backgroundColor: '#025196',
                                                color: '#ffffff'
                                            },
                                            marginTop: "5px"
                                        }}
                                        disabled={Object.values(markerPositions).length < 2}
                                        onClick={calculateRoute}
                                    >
                                        Evaluate route
                                    </Button>
                                )}

                                {routeCalculationData === null ? <></> : (
                                    <Button
                                        variant='contained'
                                        size="small"
                                        sx={{
                                            marginLeft: '20px',
                                            textTransform: 'none',
                                            paddingInline: '18px',
                                            marginInline: '10px',
                                            display: { xs: "none", sm: "none", md: 'flex' },
                                            color: '#ffffff',
                                            backgroundColor: '#025196', ':hover': {
                                                backgroundColor: '#025196',
                                                color: '#ffffff'
                                            },
                                        }}
                                        onClick={handleOpenDialog}
                                    >
                                        Save route
                                    </Button>

                                )}
                            </>
                        </Grid>

                    </Grid>
                </div>
                <div style={{ marginTop: '0px', height: routeCalculationData !== null ? '10%' : '0%', background: '', padding: '10px' }}>
                    {routeCalculationData === null ? <></> :
                        <>
                            <Typography variant='body2' sx={{ marginTop: '5px', }}>
                                Route distance: {calculateDistance(routeCalculationData.distance)}
                            </Typography>
                            <Typography variant='body2'>
                                Estimated survey time: {convertSecondsToMinutes(routeCalculationData.duration)}
                            </Typography>
                        </>
                    }
                </div>
            </Card >

        </>
    )
}

export default RoutePlannerCard
import { Button, Card, Grid, Tab, Tabs, IconButton, Slider, Box, CircularProgress, Tooltip, Badge, Snackbar, Alert, } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import LineGraph from '../../../surfaceEvaluationComponents/LineGraph';
import SmartUtils from '../../../../../../../helpers/SmartUtils';
import { useGenerateSignedUrl } from '../../../../../../../chooks/useGenerateSignedUrl';
import { makeStyles } from "@mui/styles";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { connect } from 'react-redux';
import { removeVideoSelectionErrorAndMessage, videoSelectorBack, videoSelectorNext, removeWholeVideoSelectionErrorAndMessage, videoSelection, wholeVideoSelection, deleteVideos, clearDeleteVideos } from "../../../../../../../store/actions/projectActions";
import VideoSelectorPlayer from './VideoSelectorPlayer';
import VideoSelectorList from './VideoSelectorList';
import SimpleDialogBox from "../../../../../../cidgets/SimpleDialogBox";
import VideoSelectorRecordWarningList from '../VideoSelectorRecordWarningList';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import { storage } from '../../../../../../../config/firebaseConfig';
import { useAccessControlContext } from '../../../../../../../contexts/AccessControlProvider';
import AccessControlAccountError from '../../../../../../cidgets/AccessControlAccountError';
import DeleteVideos from './DeleteVideos';
import { Circle, MapContainer, Polyline, TileLayer, useMap, Tooltip as MapTooltip } from 'react-leaflet';
import InfoIcon from '@mui/icons-material/Info';
import Error from '../../../../../../cidgets/Error';

const useStyles = makeStyles((theme) => ({
    tab: {
        minWidth: 'auto',
        textTransform: 'none',
        fontWeight: 'bold',
        fontSize: '14px',
        '&:hover': {
            backgroundColor: 'rgba(128, 128, 128, 0.2)',
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
        },
    },
    activeTab: {
        color: "white",
        backgroundColor: "#025196",
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        borderTop: '3px solid #025196',
        borderRight: '3px solid #025196',
        borderLeft: '3px solid #025196',
        borderBottom: 'none',
        '&:hover': {
            backgroundColor: "#025196",
        }
    },

}));

const VideoSelectorMap = ({ filteredGpsData, currentTime, videoSelectionDisOut }) => {
    const centerCoordinate = filteredGpsData[0];
    const polylinePositions = filteredGpsData.map(coordinate => [coordinate.lat, coordinate.lng]);
    const currentTimeNumber = parseFloat(currentTime);
    const getCurrentPosition = () => {
        let belowArray = filteredGpsData?.filter(each => each.frameTime <= currentTimeNumber);
        let aboveArray = filteredGpsData?.filter(each => each.frameTime >= currentTimeNumber);
        return belowArray.length > 0 ? belowArray.pop() : aboveArray[0];
    }
    const selectedGpsList = videoSelectionDisOut ? videoSelectionDisOut.map((range) => ({
        range,
        color: range.color,
        frames: filteredGpsData.filter((frame) => frame.frameTime >= range.startTime && frame.frameTime <= range.endTime)
    })) : [];
    const Recenter = ({ lat, lng }) => {
        const map = useMap();
        useEffect(() => {
            map.setView([lat, lng]);
        }, [lat, lng]);
        return null;
    }
    const LegendsCard = () => {
        const tooltipContent = (
            <>
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <div
                        style={{
                            width: '12px',
                            height: '12px',
                            borderRadius: '50%',
                            backgroundColor: 'green',
                        }}
                    ></div>
                    <div style={{ fontSize: '12px', fontWeight: 500 }}>This section will be analysed</div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <div
                        style={{
                            width: '12px',
                            height: '12px',
                            borderRadius: '50%',
                            backgroundColor: 'darkorange',
                        }}
                    ></div>
                    <div style={{ fontSize: '12px', fontWeight: 500 }}>{`This section length is insufficient (<50 m) for analysis`}</div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <div
                        style={{
                            width: '12px',
                            height: '12px',
                            borderRadius: '50%',
                            backgroundColor: 'red',
                        }}
                    ></div>
                    <div style={{ fontSize: '12px', fontWeight: 500 }}>You have excluded this section from the analysis</div>
                </div>
            </>
        );
        return (
            <div
                style={{
                    position: 'absolute',
                    bottom: '10px',
                    right: '10px',
                    zIndex: 1000,
                    backgroundColor: 'white',
                    padding: '10px',
                    borderRadius: '15px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '8px',
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                    color: '#025196',
                }}
            >
                <Tooltip
                    title={tooltipContent}
                    arrow
                >
                    <InfoIcon fontSize="medium" sx={{ cursor: 'pointer' }} />
                </Tooltip>
            </div>
        );
    };

    return (
        <MapContainer attributionControl={false} maxZoom={18} center={[centerCoordinate.lat, centerCoordinate.lng]}
            zoom={18} style={{ cursor: 'default', height: `100%`, width: '100%' }}>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <Recenter lat={getCurrentPosition()?.lat} lng={getCurrentPosition()?.lng} />
            <LegendsCard />
            <Circle
                center={[getCurrentPosition()?.lat, getCurrentPosition()?.lng]}
                pathOptions={{ fillColor: 'white', color: 'black', fillOpacity: 1, stroke: true, fill: true }}
                radius={3}>
            </Circle>
            {selectedGpsList ?
                selectedGpsList.map((range, i) => (
                    <Polyline key={i + range.color} positions={range.frames.map(frame => [frame.lat, frame.lng])} color={range.color}>
                        <MapTooltip sticky>Distance {range.range.distance}</MapTooltip>
                    </Polyline>
                ))
                : <Polyline positions={polylinePositions} color="#025196" />}
        </MapContainer>
    )
}
const VideoSelectorStep = (props) => {
    const { permissions, role } = useAccessControlContext();
    const { canViewVideoSelection, canUpdateVideoSelection } = permissions;
    const RATE = 0.1;
    const minDistance = 0;
    const { drProjectData, gpsData, project, proid, setActiveStep, videoSelectorBack, videoSelectorNext } = props;
    const [showListError, setShowListError] = useState(false);
    const [distanceErrorMessage, setDistanceErrorMessage] = useState([]);
    const [openVideoSelDistanceErrorMsg, setOpenVideoSelDistanceErrorMsg] = useState(false);
    const [openVideoSelDistanceMsg, setOpenVideoSelDistanceMsg] = useState("");
    const { deleteVideosMessage, deleteVideosLoader, deleteVideosIsDoneAndSent } = project;
    const { selectVideoLoader, selectVideoIsDoneAndSent, wholeSelectVideoLoader, wholeSelectVideoIsDoneAndSent } = project;
    const realFps = 'fps' in drProjectData ? drProjectData.fps : 59.94005994005994;
    const [vidDivDimen, setVidDivDimen] = useState({ height: 1, width: 1 });
    const FPS = Number(realFps).toFixed(0);
    const videoRef = useRef();
    const tabRef = useRef();
    const sliderRef = useRef();
    const playerRef = useRef(null);
    const [currentTime, setCurrentTime] = useState(0);
    const su = new SmartUtils();
    const overallCustomBorderPadding = 16;
    const classes = useStyles();
    const videosList = drProjectData.videos ? Object.values(drProjectData?.videos) : [];
    videosList.sort((a, b) => a.correctedOrder - b.correctedOrder);
    const secondsToMinutes = (seconds) => Math.floor(seconds / 60);
    const secondsToHours = (seconds) => Math.floor(seconds / 3600);
    const [videoSelectionDisOut, setVideoSelectionDisOut] = useState([]);
    const handleOpenVideoSelDistanceErrorMsg = () => {
        setOpenVideoSelDistanceErrorMsg(true);
    };
    const handleCloseVideoSelDistanceErrorMsg = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenVideoSelDistanceErrorMsg(false);
    };

    async function getJsonFromStorage(filePath) {
        if (filePath === "") return false;
        const storageRef = storage.ref(filePath);
        const downloadURL = await storageRef.getDownloadURL();
        const res = await fetch(downloadURL);
        const data = await res.json();
        return data;
    }

    // video selection is not there-> disable range []
    // particular video video selection-> disable range []
    // particular video video selection but front end data not present-> disable range []
    const [segmentIndex, setSegmentIndex] = useState(0)
    const [selectVideo, setSelectVideo] = useState(videosList[0]);
    const [videoSelectionJsonData, setVideoSelectionJsonData] = useState([]);
    const videoSelectionPath = 'videoSelectionPath' in drProjectData ? drProjectData.videoSelectionPath : {};
    const currentVideoSelectionPath = selectVideo ? videoSelectionPath ? videoSelectionPath[selectVideo.correctedOrder] ?? "" : "" : "";
    const [storedValues, setStoredValues] = useState([]);
    const [disabledRange, setDisabledRange] = useState([]);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [idCounter, setIdCounter] = useState(1);

    const { status, error, data } = useGenerateSignedUrl(
        selectVideo ? selectVideo.videoPath : "", false
    );
    const isFetched = status === 'fetched';
    const getVideoSelectionData = async () => {
        try {
            const jsonData = await getJsonFromStorage(currentVideoSelectionPath);
            if (jsonData === false) {
                setDisabledRange([]);
                setVideoSelectionJsonData([]);
                const output = calcWidthAndDistance(originalFilteredGpsData, [], Math.round(selectVideo.frames / FPS));
                setVideoSelectionDisOut(output);
                setStoredValues([]);
                setIdCounter(1);
            }
            else {
                setDisabledRange(jsonData.frontEndData);
                setVideoSelectionJsonData(jsonData.frontEndData);
                const updatedStoredValues = [...jsonData.frontEndData].reduce((acc, obj) => [...acc, {
                    id: acc.length + 1,
                    startFrame: obj[0] * FPS,
                    endFrame: obj[1] * FPS,
                    startTimeMillis: obj[0] * 1000,
                    endTimeMillis: obj[1] * 1000
                }], []);
                const output = calcWidthAndDistance(originalFilteredGpsData, updatedStoredValues, Math.round(selectVideo.frames / FPS));
                setVideoSelectionDisOut(output);
                setStoredValues(updatedStoredValues);
                setIdCounter(jsonData.frontEndData.length + 1);
            }
        } catch (error) {
            console.error("Error fetching JSON data:", error);
        }
    }

    const [currentPage, setCurrentPage] = useState(0);
    const videosPerPage = 3;
    const totalPages = Math.ceil(videosList.length / videosPerPage);
    const currentVideos = videosList.slice(
        currentPage * videosPerPage,
        (currentPage + 1) * videosPerPage
    );
    const [filteredGpsData, setFilteredGpsData] = useState([]);
    const [originalFilteredGpsData, setOriginalFilteredGpsData] = useState([]);
    function everyNthElement(inputArray, n) {
        const result = new Array(Math.ceil(inputArray.length / n));
        for (let i = n - 1, j = 0; i < inputArray.length; i += n, j++) {
            result[j] = inputArray[i];
        }
        return result;
    }

    const handleTabChange = (event, newValue) => {
        setNewTabValue(newValue);
        // //////console.log(newValue, videoSelectionJsonData, disabledRange, JSON.stringify(videoSelectionJsonData) === JSON.stringify(disabledRange), "handleTabChange")
        if (JSON.stringify(videoSelectionJsonData) === JSON.stringify(disabledRange)) {
            if (videosList.length > newValue) {
                setTabValue(newValue);
                setSegmentIndex(newValue);
                setSelectVideo(videosList[newValue]);
                setDisabledRange([]);
                setStoredValues([]);
                setValue([0, videosList[newValue].frames / FPS])
            }
            else {
                setActiveStep(5);
                videoSelectorNext(proid)
            }
        }
        else {
            if (videosList.length > newValue) {
                if (storedValues.length > 0) {
                    handleOpenSkipDialog();
                }
                else {
                    setTabValue(newValue);
                    setSegmentIndex(newValue);
                    setSelectVideo(videosList[newValue]);
                    setDisabledRange([]);
                    setStoredValues([]);
                    setValue([0, videosList[newValue].frames / FPS])
                }
            }
            else {
                if (storedValues.length > 0) {
                    handleOpenSkipDialog();
                }
                else {
                    setActiveStep(5);
                    videoSelectorNext(proid)
                }
            }
        }
    };
    useEffect(() => {
        if (gpsData) {
            const newFilteredGpsData = gpsData?.filter(item => item.videoIndex === segmentIndex);
            setOriginalFilteredGpsData(newFilteredGpsData);
            const downSampledData = everyNthElement(newFilteredGpsData, 10).filter(item => item !== undefined && item !== null)
            setFilteredGpsData(downSampledData);
        }
    }, [selectVideo, gpsData]);
    useEffect(() => {
        props.removeVideoSelectionErrorAndMessage();
        props.removeWholeVideoSelectionErrorAndMessage();
    }, [])
    useEffect(() => {
        if (selectVideoIsDoneAndSent === true || wholeSelectVideoIsDoneAndSent === true) {
            if (videosList.length > segmentIndex + 1) {
                setTabValue(segmentIndex + 1);
                setSegmentIndex(segmentIndex + 1);
                setSelectVideo(videosList[segmentIndex + 1]);
                setDisabledRange([]);
                setStoredValues([]);
                setValue([0, videosList[segmentIndex + 1].frames / FPS]);
                props.removeVideoSelectionErrorAndMessage();
                props.removeWholeVideoSelectionErrorAndMessage();
            }
            else {
                setActiveStep(5);
                props.removeVideoSelectionErrorAndMessage();
                props.removeWholeVideoSelectionErrorAndMessage();
            }
        }
    }, [selectVideoIsDoneAndSent, wholeSelectVideoIsDoneAndSent])
    const getVidWindowSize = () => {
        const newHeight = videoRef?.current?.clientHeight;
        const newWidth = videoRef?.current?.clientWidth;
        setVidDivDimen({
            height: newHeight,
            width: newWidth
        });
    };
    useEffect(() => {
        window.addEventListener("load", setVidDivDimen(videoRef?.current === null ? 0 : videoRef?.current?.clientHeight))
        window.addEventListener("resize", getVidWindowSize, true);
    }, []);

    useEffect(() => {
        if (originalFilteredGpsData ? originalFilteredGpsData.length > 0 : false) {
            handleUpdateVideoSelDisOut([]);
        }
    }, [originalFilteredGpsData])

    useEffect(() => {
        if (originalFilteredGpsData.length > 0) {
            getVideoSelectionData();
        }
    }, [selectVideo, originalFilteredGpsData])
    // const handleTabClick = (index) => {
    //     const sectionIndex = index;
    //     setSegmentIndex(sectionIndex);
    //     setSelectVideo(videosList[sectionIndex])
    // };
    const [tabValue, setTabValue] = useState(0);
    const [mapGraphValue, mapGraphSetValue] = useState('graph');

    const handleChangeMapGraphView = (event, newValue) => {
        mapGraphSetValue(newValue);
    };

    // Readme: to handle force skip
    const [newTabValue, setNewTabValue] = useState(0);
    const [value, setValue] = useState([0, selectVideo?.frames / FPS]);

    const tabs = Object.values(currentVideos).map((path, index) => (
        <Tab
            key={index}
            value={index + currentPage * videosPerPage}
            label={path.name}
            // label={`Video ${(index + currentPage * videosPerPage) + 1}`}
            // onClick={() => handleTabClick(index + currentPage * videosPerPage)}
            className={`${classes.tab} ${segmentIndex === (index + currentPage * videosPerPage) ? classes.activeTab : ''
                }`}
            sx={{
                width: "33.33%",
                textTransform: 'none',
                fontWeight: 'bold',
            }}
        />
    ));

    const handlePreviousPage = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages - 1) {
            setCurrentPage(currentPage + 1);
        }
    };
    const onCurrentTimeChange = (currentTime) => {
        setCurrentTime(Number(currentTime - 1 / FPS).toFixed(5))
    }
    const handlePrevFrame = () => {
        playerRef?.current?.replay(1 / FPS)
    }

    const handleNextFrame = () => {
        playerRef?.current?.forward(1 / FPS)
    }

    function valuetext(value) {
        return `${value} sec`;
    }

    // const handleChange = (event, newValue) => {
    //     // //////console.log(newValue, 'abc')
    //     setValue(newValue);
    //     playerRef?.current?.seek(Number(newValue[0]).toFixed(5))
    // };
    const handleChange = (event, newValue, activeThumb) => {

        if (!Array.isArray(newValue)) {
            return;
        }
        const isValueInDisabledRange1 = disabledRange.some(range => newValue[0] >= range[0] && newValue[0] <= range[1]);
        const isValueInDisabledRange2 = disabledRange.some(range => newValue[1] >= range[0] && newValue[1] <= range[1]);
        if (activeThumb === 0) {
            if (isValueInDisabledRange1) {
                // setValue([disabledRange[1] + ministance, value[1]]);
                playerRef?.current?.seek(Number(newValue[0]).toFixed(5))
            } else {
                // setValue([Math.min(newValue[0], value[1] - minDistance), value[1]]);
                setValue([newValue[0], newValue[1]]);
                playerRef?.current?.seek(Number(newValue[0]).toFixed(5))
            }
        } else {
            if (isValueInDisabledRange2) {
                // setValue([disabledRange[1] + minDistance, value[1]]);
                playerRef?.current?.seek(Number(newValue[1]).toFixed(5))
            } else {
                setValue([newValue[0], newValue[1]]);
                // setValue([value[0], value[1]]);
                // setValue([value[0], Math.max(newValue[1], value[0] + minDistance)]);
                playerRef?.current?.seek(Number(newValue[1]).toFixed(5))
            }

        }
        // if (activeThumb === 0) {
        //     playerRef?.current?.seek(Number(newValue[0]).toFixed(5))
        //     setValue([Math.min(newValue[0], value[1] - minDistance), value[1]]);
        // } else {
        //     playerRef?.current?.seek(Number(newValue[1]).toFixed(5))
        //     setValue([value[0], Math.max(newValue[1], value[0] + minDistance)]);
        // }
    };

    const handleStoreValues = () => {
        const newValue = {
            id: disabledRange.length + 1,
            startTimeMillis: value[0] * 1000,
            startFrame: Math.round(value[0] * FPS),
            endTimeMillis: value[1] * 1000,
            endFrame: Math.round(value[1] * FPS)
        };
        const isValid = checkIfVideoSelectionIsValid(originalFilteredGpsData, [...storedValues, newValue], Math.round(selectVideo.frames / FPS));
        if (isValid.isDoneAndSent === true) {
            if (isValid.distanceErrorMessages.length > 0) {
                // isValid.distanceErrorMessages.map((message) => alert(message))
                setDistanceErrorMessage(isValid.distanceErrorMessages);
                setDisabledRange([...disabledRange, value]);
                const updatedStoredValues = [...disabledRange, value].reduce((acc, obj) => [...acc, {
                    id: acc.length + 1,
                    startFrame: obj[0] * FPS,
                    endFrame: obj[1] * FPS,
                    startTimeMillis: obj[0] * 1000,
                    endTimeMillis: obj[1] * 1000
                }], []);
                const output = calcWidthAndDistance(originalFilteredGpsData, updatedStoredValues, Math.round(selectVideo.frames / FPS));
                // //////console.log(output, "output1")
                setVideoSelectionDisOut(output);
                setStoredValues(updatedStoredValues);
                // setStoredValues([...storedValues, newValue]);
                setIdCounter(idCounter + 1);
            }
            else {
                setDisabledRange([...disabledRange, value]);
                // setStoredValues([...storedValues, newValue]);
                const updatedStoredValues = [...disabledRange, value].reduce((acc, obj) => [...acc, {
                    id: acc.length + 1,
                    startFrame: obj[0] * FPS,
                    endFrame: obj[1] * FPS,
                    startTimeMillis: obj[0] * 1000,
                    endTimeMillis: obj[1] * 1000
                }], []);
                const output = calcWidthAndDistance(originalFilteredGpsData, updatedStoredValues, Math.round(selectVideo.frames / FPS))
                // //////console.log(output, "output1")
                setVideoSelectionDisOut(output);
                setStoredValues(updatedStoredValues);
                setIdCounter(idCounter + 1);
            }
        }
        else {
            handleOpenVideoSelDistanceErrorMsg();
            setOpenVideoSelDistanceMsg(isValid.responseMsg)
            // alert(`${isValid.responseMsg}`)
        }
        // // setValue([value[0], value[1]]);
    };

    useEffect(() => {
        if (isPlaying) {
            const isValueInDisabledRange = disabledRange.some(range => Math.round(Number(currentTime)) >= range[0] && Math.round(Number(currentTime)) <= range[1]);
            if (isValueInDisabledRange) {
                const foundRange = disabledRange.find(range => Math.round(Number(currentTime)) >= range[0] && Math.round(Number(currentTime)) <= range[1]);
                const rangeIndex = disabledRange.indexOf(foundRange);
                if (rangeIndex !== -1) {
                    playerRef?.current?.seek(disabledRange[rangeIndex][1] + minDistance);
                }
            }
            else {
                setValue([Math.round(currentTime), selectVideo.frames / FPS]);
            }
        }
    }, [currentTime]);
    const handleClick = () => {
        if (storedValues.length > 0) {
            const vsData = generateFinalVideoSelectionData(videoSelectionDisOut, disabledRange, FPS);
            const videoSelectionData =
            {
                "info": {
                    "proid": proid,
                    "filename": selectVideo.name.split(".")[0],
                    "videoLink": selectVideo.videoLink,
                },
                ...vsData
            }
            // //////console.log(videoSelectionData, "videoSelectionData");
            props.videoSelection(videoSelectionData);
        }
        else {
            // handleOpenVideoSelDistanceErrorMsg();
            // setOpenVideoSelDistanceMsg(`No excluded zone found.`)
            handleWholeVideoSelection();
        }
    }
    // Readme: this is alternate method to handle whole video selection.
    const handleWholeVideoSelection = () => {
        const vsData = generateFinalVideoSelectionData([], [], FPS);
        const videoSelectionData =
        {
            "info": {
                "proid": proid,
                "filename": selectVideo.name.split(".")[0],
                "videoLink": selectVideo.videoLink,
            },
            ...vsData
        }
        props.videoSelection(videoSelectionData);
    }
    const handleReset = () => {
        const vsData = generateFinalVideoSelectionData([], [], FPS);
        const videoSelectionData =
        {
            "info": {
                "proid": proid,
                "filename": selectVideo.name.split(".")[0],
                "videoLink": selectVideo.videoLink,
            },
            ...vsData
        }
        props.wholeVideoSelection(videoSelectionData);
    }
    const graphHeigth = tabRef?.current?.clientHeight + videoRef?.current?.clientHeight ?? 0;
    const sliderWidth = sliderRef?.current?.clientWidth ?? 0;
    const [openRangeDialog, setOpenRangeDialog] = useState(false);
    const handleOpenRangeDialog = () => {
        setOpenRangeDialog(true);
    }
    const handleCloseRangeDialog = () => {
        setOpenRangeDialog(false);
    }
    function checkIfVideoSelectionIsValid(filteredGpsData, videoSelectionData, maxVideoTime) {
        // PASS THE FILTERED GPS DATA [ALL DATA NO DOWNSAMPLING] WHOSE VIDEO INDEX MATCHES THE CORRECTED ORDER OF THIS VIDEO
        // PASS THE DATA ARRAY OF VIDEO SELECTION
        videoSelectionData.sort((a, b) => a.id - b.id)

        if (videoSelectionData.length === 0) {
            return {
                isDoneAndSent: true,
                responseMsg: "Video selection is valid"
            }
        }

        // CHECK IF ANY OVERLAPPING IS PRESENT OR NOT
        // CONDITION FOR OVERLAPPING WILL BE IF SO AND EO COMPLETELY ENGULFS SN AND EN || IF SN < S0 < EN || IF SN < EO < EN

        if (videoSelectionData.length > 1) {
            for (const eachVideoSelectionData of videoSelectionData) {
                let localFilterVidSelData = videoSelectionData.filter(each => (each.id > eachVideoSelectionData.id && (
                    (eachVideoSelectionData.startTimeMillis <= each.startTimeMillis && each.startTimeMillis <= eachVideoSelectionData.endTimeMillis) ||
                    (eachVideoSelectionData.startTimeMillis <= each.startTimeMillis && each.endTimeMillis <= eachVideoSelectionData.endTimeMillis) ||
                    (eachVideoSelectionData.startTimeMillis >= each.startTimeMillis && eachVideoSelectionData.endTimeMillis <= each.endTimeMillis)
                )
                ))
                if (localFilterVidSelData.length > 0) {
                    return {
                        isDoneAndSent: false,
                        responseMsg: `Overlap detected between selection ${eachVideoSelectionData.id} and selection ${localFilterVidSelData[0].id}`
                    }
                }
            }
        }

        // FIND ALL THE LIST OF FILTERED GPS DATA THAT WILL BE PROCESSED.
        // CALCULATE DISTANCE OF EACH OF THOSE
        // IF ANY DISTANCE APPEARS TO BE LESS THAN 155M THEN RETURN FALSE

        videoSelectionData.sort((a, b) => a.startTimeMillis - b.startTimeMillis)

        if (videoSelectionData[0].startTimeMillis == 0 && videoSelectionData[0].endTimeMillis == maxVideoTime * 1000) {
            return {
                isDoneAndSent: true,
                responseMsg: "No part of video can be processed.",
                distanceErrorMessages: ['No part of video can be processed.']
            }
        }

        let isGonnaProcess = false

        let distanceErrorMessages = []

        let fromStartToFirstFGL = filteredGpsData.filter(each => each.frameTime < videoSelectionData[0].startTimeMillis / 1000)
        if (fromStartToFirstFGL.length > 0) {
            if (fromStartToFirstFGL.length > 1 ? getDistanceForArray(fromStartToFirstFGL) < 0.05 : false) {
                distanceErrorMessages.push(`Distance between start of the video and selection ${videoSelectionData[0].id} is less than 50m so it will not be processed.`)
            } else {
                isGonnaProcess = true
            }
        }

        for (let i = 0; i < videoSelectionData.length - 1; i++) {
            let startMillis = videoSelectionData[i].endTimeMillis
            let endMillis = videoSelectionData[i + 1].startTimeMillis
            let middleFGL = filteredGpsData.filter(each => (startMillis / 1000 < each.frameTime && each.frameTime < endMillis / 1000))

            let distance = middleFGL.length > 1 ? getDistanceForArray(middleFGL) : 0

            if (middleFGL.length > 1 ? distance < 0.05 : true) {
                distanceErrorMessages.push(`Distance between selection ${videoSelectionData[i].id} and selection ${videoSelectionData[i + 1].id} is less than 50m so it will not be processed.`)
            } else {
                isGonnaProcess = true
            }
        }

        let fromLastFGLtoEnd = filteredGpsData.filter(each => each.frameTime > videoSelectionData[videoSelectionData.length - 1].endTimeMillis / 1000 && each.frameTime <= maxVideoTime)
        if (fromLastFGLtoEnd.length > 0) {
            if (fromLastFGLtoEnd.length > 1 ? getDistanceForArray(fromLastFGLtoEnd) < 0.05 : false) {
                distanceErrorMessages.push(`Distance between selection ${videoSelectionData[videoSelectionData.length - 1].id} and end of the video is less than 50m so it will not be processed.`)
            } else {
                isGonnaProcess = true
            }
        }
        if (isGonnaProcess === false) {
            distanceErrorMessages.push("No part of video can be processed.")
        }
        return {
            isDoneAndSent: true,
            responseMsg: isGonnaProcess == true ? "Video selection is valid" : "No part of video can be processed.",
            distanceErrorMessages
        }
    }
    function getDistanceForArray(gpsData) {
        let finalRslt = {
            distance: 0,
            lat: gpsData[0]['lat'],
            lng: gpsData[0]['lng']
        }
        gpsData.shift()

        let outputResult = gpsData.reduce((acc, each) => {
            return {
                distance: acc.distance + calculateDistance(each.lat, each.lng, acc.lat, acc.lng),
                lat: each.lat,
                lng: each.lng
            }
        }, finalRslt)
        return outputResult.distance
    }
    function calculateDistance(lat1, lon1, lat2, lon2) {
        const R = 6371; // Radius of the Earth in kilometers
        const dLat = (lat2 - lat1) * Math.PI / 180; // Convert degrees to radians
        const dLon = (lon2 - lon1) * Math.PI / 180;
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = R * c; // Distance in kilometers
        return distance;
    }
    function generateFinalVideoSelectionData(lineData, disabledLineData, FPS) {

        if (disabledLineData.length === 0) {
            return {
                data: [],
                frontEndData: []
            }
        }

        let initAcc = {
            finalVSOutput: []
        }

        let finalAcc = lineData.reduce((acc, each) => {
            if (each.originalDistance < 0.05 || (each.excluded ?? false) === true) {
                if (acc.finalVSOutput.length === 0) {
                    return {
                        ...acc,
                        finalVSOutput: [each]
                    }
                }

                let lastFinalVS = acc.finalVSOutput.at(-1)
                if (lastFinalVS.endTime === each.startTime) {
                    lastFinalVS = { ...lastFinalVS, endTime: each.endTime }
                    return {
                        ...acc,
                        finalVSOutput: [...acc.finalVSOutput.slice(0, -1), lastFinalVS]
                    }
                } else {
                    return {
                        ...acc,
                        finalVSOutput: [...acc.finalVSOutput, each]
                    }
                }
            } else {
                return acc
            }
        }, initAcc)

        let { finalVSOutput } = finalAcc

        let vidSelectionDataArray = finalVSOutput.map((each, index) => {
            return {
                "id": index + 1,
                "startTimeMillis": each.startTime * 1000,
                "startFrame": each.startTime * FPS,
                "endTimeMillis": each.endTime * 1000,
                "endFrame": each.endTime * FPS
            }
        })

        return {
            data: vidSelectionDataArray,
            frontEndData: disabledLineData
        }
    }
    function calcWidthAndDistance(filteredGpsData, storedValue, maxVideoTimeInS) {
        storedValue.sort((a, b) => a.startTimeMillis - b.startTimeMillis)

        let allDivisions = Array.from(new Set([0, ...(storedValue.map(each => each.startTimeMillis / 1000)), ...(storedValue.map(each => each.endTimeMillis / 1000)), maxVideoTimeInS]));
        let allStartingValues = (storedValue.map(each => each.startTimeMillis / 1000))

        allDivisions.sort((a, b) => a - b)

        let originalAcc = {
            startTime: allDivisions[0],
            finalResult: []
        }

        allDivisions.shift()

        let finalAcc = allDivisions.reduce((acc, each) => {
            let specificGpsList = filteredGpsData.filter(eachGps => acc.startTime < eachGps.frameTime && eachGps.frameTime < each)
            let distance = specificGpsList.length > 1 ? getDistanceForArray(specificGpsList) : 0
            let color = allStartingValues.includes(acc.startTime) ? 'red' : distance < 0.05 ? 'darkOrange' : 'green'
            let excluded = allStartingValues.includes(acc.startTime) ? true : false
            let widthFactor = (each - acc.startTime) / maxVideoTimeInS

            return {
                startTime: each,
                finalResult: [...acc.finalResult, {
                    id: acc.finalResult.length,
                    originalDistance: distance,
                    distance: distance < 1 ? `${(distance * 1000).toFixed(0)} m` : `${(distance).toFixed(2)} km`,
                    color,
                    widthFactor,
                    startTime: acc.startTime,
                    endTime: each,
                    excluded
                }]
            }
        }, originalAcc)

        return finalAcc.finalResult
    }

    const [skipDialog, setSkipDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [newVideosList, setNewVideosList] = useState(null);
    const handleOpenSkipDialog = () => {
        setSkipDialog(true);
    }
    const handleCloseSkipDialog = () => {
        setSkipDialog(false);
    }
    const handleForceSkip = () => {
        if (videosList.length > newTabValue) {
            setTabValue(newTabValue);
            setSegmentIndex(newTabValue);
            setSelectVideo(videosList[newTabValue]);
            setDisabledRange([]);
            setStoredValues([]);
            setValue([0, videosList[newTabValue].frames / FPS])
            handleCloseSkipDialog();
        }
        else {
            setActiveStep(5);
        }

    }
    const handleUpdateVideoSelDisOut = (updateStoredData) => {
        const output = calcWidthAndDistance(originalFilteredGpsData, updateStoredData, Math.round(selectVideo?.frames / FPS));
        // //////console.log(output, "output")
        const isValidSelection = checkIfVideoSelectionIsValid(originalFilteredGpsData, updateStoredData, Math.round(selectVideo.frames / FPS));
        setVideoSelectionDisOut(output);
        setDistanceErrorMessage(isValidSelection?.distanceErrorMessages ?? []);
    }
    const handleOpenWarningList = () => {
        setShowListError(true);
    }
    const handleCloseWarningList = () => {
        setShowListError(false);
    }
    const handleDelete = () => {
        props.deleteVideos(proid, newVideosList);
    }
    const onDelete = (newList) => {
        setNewVideosList(drProjectData.videos.length === newList.length ? null : newList);
    }
    const handleDeleteDialog = () => {
        props.clearDeleteVideos();
        setOpenDeleteDialog(true);
    }
    return (
        !canViewVideoSelection ?
            <AccessControlAccountError access={role} />
            :
            <>
                <SimpleDialogBox
                    open={openDeleteDialog}
                    onClose={() => { setOpenDeleteDialog(false) }}
                    title={<Error errorMessage={"Deleting videos will reset the GPS edit and video selection of the videos"}/>}
                    bodyWidget={<DeleteVideos videoList={drProjectData.videos} onDelete={onDelete} />}
                    showError={deleteVideosIsDoneAndSent === true ? false : true}
                    errorMessage={deleteVideosIsDoneAndSent === true ? "" : deleteVideosMessage}
                    width={700}
                    showPositive
                    positiveButtonWidget={<>
                        {deleteVideosLoader ?
                            <>
                                <Button variant="contained" disabled sx={{
                                    textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                                    "&.Mui-disabled": {
                                        backgroundColor: '#025196',
                                        color: '#FFFFFF'
                                    }
                                }}>
                                    <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} /><span style={{ paddingLeft: '15px' }}>Deleting...</span>
                                </Button>
                            </>
                            :

                            <Button size="medium" variant="contained"
                                onClick={handleDelete}
                                disabled={newVideosList === null}
                                sx={{
                                    textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                                    '&:hover': {
                                        backgroundColor: '#025196',
                                        color: '#FFFFFF'
                                    }
                                }}>Delete</Button>
                        }
                    </>}
                />
                <Snackbar open={openVideoSelDistanceErrorMsg} autoHideDuration={3000} onClose={handleCloseVideoSelDistanceErrorMsg}>
                    <Alert
                        onClose={handleCloseVideoSelDistanceErrorMsg}
                        severity="error"
                        variant="outlined"
                        sx={{ width: '100%' }}
                    >
                        {openVideoSelDistanceMsg}
                    </Alert>
                </Snackbar>
                <SimpleDialogBox
                    open={skipDialog}
                    onClose={handleCloseSkipDialog}
                    title="Confirm"
                    shouldTitleAppear={true}
                    showCancel={true}
                    bodyWidget={
                        <>You have {storedValues.length} recorded excluded zone(s), do you really want to skip?</>
                    }
                    showPositive
                    positiveButtonWidget={
                        <>
                            {
                                selectVideoLoader ?
                                    <Button
                                        variant="contained"
                                        sx={{
                                            marginLeft: '2px',
                                            textTransform: "initial",
                                            backgroundColor: "#025196",
                                            color: "#FFFFFF",
                                            "&:hover": {
                                                backgroundColor: "#025196",
                                                color: "#FFFFFF",
                                            },
                                        }}
                                    >
                                        <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} /><span style={{ paddingLeft: '15px' }}>Confirming...</span>
                                    </Button>
                                    :
                                    <Button
                                        variant="contained"
                                        onClick={handleForceSkip}
                                        sx={{
                                            marginLeft: '2px',
                                            textTransform: "initial",
                                            backgroundColor: "#025196",
                                            color: "#FFFFFF",
                                            "&:hover": {
                                                backgroundColor: "#025196",
                                                color: "#FFFFFF",
                                            },
                                        }}
                                    >
                                        Confirm
                                    </Button>
                            }
                        </>
                    }
                    width='600'
                />
                <SimpleDialogBox
                    open={openRangeDialog}
                    onClose={handleCloseRangeDialog}
                    title="Excluded zones"
                    shouldTitleAppear={true}
                    showCancel={true}

                    bodyWidget={
                        <VideoSelectorList handleUpdateVideoSelDisOut={handleUpdateVideoSelDisOut} FPS={FPS} setStoredValues={setStoredValues} storedValues={storedValues} disabledRange={disabledRange} setDisabledRange={setDisabledRange} />
                    }
                    width='600'
                />
                <SimpleDialogBox
                    open={showListError}
                    onClose={handleCloseWarningList}
                    title="Warnings"
                    shouldTitleAppear={true}
                    showCancel={true}
                    cancelText='Close'
                    bodyWidget={
                        <VideoSelectorRecordWarningList distanceErrorMessage={distanceErrorMessage} />
                    }
                    width='600'
                />
                <Grid container spacing={0} sx={{ height: '80vh', background: '' }}>
                    <Grid item xs={6} sx={{ backgroundColor: "", height: "40vh" }}>
                        <div ref={videoRef} style={{ backgroundColor: '', height: '100%', padding: SmartUtils.edgeInsets(overallCustomBorderPadding, 'l') }}>
                            <Card sx={{ borderRadius: "15px" }}>
                                <div
                                    ref={tabRef}
                                    style={{
                                        display: 'flex', alignItems: 'center',
                                        backgroundColor: '#EFEFEF',
                                        boxShadow: '0px 0px 10px rgba(64, 64, 64, 0.1)',
                                        width: '100%',
                                        borderBottomLeftRadius: '0',
                                        borderBottomRightRadius: '0',
                                    }}>
                                    <IconButton onClick={handlePreviousPage} disabled={currentPage === 0}>
                                        <ArrowBackIosIcon />
                                    </IconButton >

                                    <Tabs
                                        value={tabValue}
                                        onChange={handleTabChange}
                                        sx={{ width: '100%' }}
                                        indicatorColor=''
                                        textColor=''
                                    >
                                        {tabs}
                                    </Tabs>

                                    <div style={{ marginLeft: 'auto' }}>
                                        <IconButton onClick={handleNextPage} disabled={currentPage === totalPages - 1}>
                                            <ArrowForwardIosIcon />
                                        </IconButton >
                                    </div>
                                </div>
                                {isFetched ?
                                    <VideoSelectorPlayer setIsActive={setIsActive} setIsPlaying={setIsPlaying} shouldOffset={false} onCurrentTimeChange={onCurrentTimeChange} playerRef={playerRef} defaultRate={RATE}
                                        startTime={1 / FPS}
                                        altKey={segmentIndex}
                                        fps={FPS} handleNextFrame={handleNextFrame} handlePrevFrame={handlePrevFrame}
                                        link={data}
                                        height={videoRef.current === null ? 0 : videoRef.current?.clientHeight}
                                        control={false} disableControlsFully={false} /> : <></>}
                            </Card>
                        </div>
                    </Grid>
                    <Grid item xs={6} sx={{ backgroundColor: "", height: "40vh" }}>
                        <div ref={videoRef} style={{ backgroundColor: '', height: '100%', padding: SmartUtils.edgeInsets(overallCustomBorderPadding, 'l') }}>
                            <Card sx={{ borderRadius: "15px" }}>
                                <div
                                    ref={tabRef}
                                    style={{
                                        display: 'flex', alignItems: 'center',
                                        backgroundColor: '#EFEFEF',
                                        boxShadow: '0px 0px 10px rgba(64, 64, 64, 0.1)',
                                        width: '100%',
                                        borderBottomLeftRadius: '0',
                                        borderBottomRightRadius: '0',
                                    }}>
                                    <Tabs
                                        value={mapGraphValue}
                                        onChange={handleChangeMapGraphView}
                                        sx={{ width: '100%' }}
                                        indicatorColor=''
                                        textColor=''
                                    >
                                        <Tab className={`${classes.tab} ${mapGraphValue === "graph" ? classes.activeTab : ''}`}
                                            sx={{
                                                width: "50%",
                                                textTransform: 'none',
                                                fontWeight: 'bold',
                                            }} value="graph" label="Vehicle speed plot" />
                                        <Tab className={`${classes.tab} ${mapGraphValue === "map" ? classes.activeTab : ''}`}
                                            sx={{
                                                width: "50%",
                                                textTransform: 'none',
                                                fontWeight: 'bold',
                                            }} value="map" label="Map" />
                                    </Tabs>
                                </div>
                                <Card sx={{ borderRadius: '0px', height: `${videoRef.current === null ? 0 : videoRef.current?.clientHeight}px`, background: '', width: '100%', }}>
                                    {mapGraphValue === 'map' ?
                                        <VideoSelectorMap videoSelectionDisOut={videoSelectionDisOut} filteredGpsData={filteredGpsData} currentTime={currentTime} />
                                        : videoRef?.current && tabRef?.current ? <LineGraph filteredGpsData={filteredGpsData}
                                            cardHeight={videoRef.current === null ? 0 : videoRef.current?.clientHeight}
                                            currentTime={currentTime}
                                        /> : <></>}
                                </Card>
                            </Card>
                        </div>
                    </Grid>
                    {videoRef?.current && tabRef?.current ?
                        <Grid item xs={12} sx={{
                            backgroundColor: "", height: `calc(40vh - ${tabRef.current.clientHeight}px)`,

                        }}>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'end', height: `45%`, backgroundColor: "" }}>
                                <Slider
                                    ref={sliderRef}
                                    getAriaLabel={() => 'Video time range'}
                                    getAriaValueText={valuetext}
                                    value={value}
                                    onChange={handleChange}
                                    valueLabelDisplay="on"
                                    valueLabelFormat={(value, index) => {
                                        if (value < 60) {
                                            return index === 0 ? `Start: ${Number(value).toFixed(0)} sec` : `End: ${Number(value).toFixed(0)} sec`;
                                        } else if (value < 3600) {
                                            const minutes = secondsToMinutes(value);
                                            const seconds = Number(value % 60).toFixed(0);
                                            return index === 0 ? `Start: ${minutes} mins ${seconds} secs` : `End: ${minutes} mins ${seconds} secs`;
                                        } else {
                                            const hours = secondsToHours(value);
                                            const remainingMinutes = secondsToMinutes(value % 3600);
                                            const seconds = Number(value % 60).toFixed(0);
                                            return index === 0
                                                ? `Start: ${hours} hrs ${remainingMinutes} mins ${seconds} secs`
                                                : `End: ${hours} hrs ${remainingMinutes} mins ${seconds} secs`;
                                        }
                                    }}
                                    max={selectVideo?.frames / FPS}
                                    disabled={!isActive || isPlaying}
                                    min={0}
                                    step={1}
                                    disableSwap={true}
                                    marks={disabledRange.reduce(
                                        (marksArray, range, index) => [
                                            ...marksArray,
                                            { value: range[0], label: `S${index + 1}` },
                                            { value: range[1], label: `E${index + 1}` },
                                        ],
                                        []
                                    )}
                                    track="normal"
                                    sx={{
                                        color: '#025196',
                                        // '& .MuiSlider-track': {
                                        //     background: `linear-gradient(to right, ${gradientStops})`,
                                        // },
                                        '& .MuiSlider-thumb': {
                                            height: 16, // Set the height of the thumb
                                            width: 16, // Set the width of the thumb
                                            // '&:first-child': {
                                            //     backgroundColor: 'yellow', // Specify color for the first thumb
                                            // },
                                            // '&:last-child': {
                                            //     backgroundColor: 'red', // Specify color for the second thumb
                                            // },
                                        },
                                        '& .MuiSlider-mark': {
                                            backgroundColor: 'red',
                                            height: 8,
                                            width: 4,
                                            boxShadow: '0px 0px 5px 2px #fafafa', // Add a box shadow to increase stickiness

                                        },
                                        '& .MuiSlider-markLabel': {
                                            color: '#000000',
                                            fontWeight: 'normal',
                                        },
                                        width: '80%',
                                        // marginLeft: '0px', color: "#041256"
                                    }}

                                >
                                </Slider>
                            </Box>
                            <Tooltip arrow placement='bottom' title={
                                <>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                        <div
                                            style={{
                                                width: '12px',
                                                height: '12px',
                                                borderRadius: '50%',
                                                backgroundColor: 'green',
                                            }}
                                        ></div>
                                        <div style={{ fontSize: '12px', fontWeight: 500 }}>This section will be analysed</div>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                        <div
                                            style={{
                                                width: '12px',
                                                height: '12px',
                                                borderRadius: '50%',
                                                backgroundColor: 'darkorange',
                                            }}
                                        ></div>
                                        <div style={{ fontSize: '12px', fontWeight: 500 }}>{`This section length is insufficient (<50 m) for analysis`}</div>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                        <div
                                            style={{
                                                width: '12px',
                                                height: '12px',
                                                borderRadius: '50%',
                                                backgroundColor: 'red',
                                            }}
                                        ></div>
                                        <div style={{ fontSize: '12px', fontWeight: 500 }}>You have excluded this section from the analysis</div>
                                    </div>
                                </>}
                            >
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'start', height: '5%' }}>
                                    {videoSelectionDisOut.map((eachVideoSelectionData, index) => (
                                        <div key={index} style={{ width: `${sliderWidth * eachVideoSelectionData.widthFactor}px`, background: `${eachVideoSelectionData.color}`, height: '50%' }} />
                                    ))}
                                </Box>
                            </Tooltip>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'start', height: '5%' }}>
                                {videoSelectionDisOut.map((eachVideoSelectionData, index) => (
                                    <div key={index} style={{ textAlign: 'center', width: `${sliderWidth * eachVideoSelectionData.widthFactor}px`, height: '100%' }}>
                                        {eachVideoSelectionData.distance}
                                    </div>
                                ))}
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: "", height: `45%`, marginBottom: '0px', padding: su._createPadding(0, 'r') }}>
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        setActiveStep(3)
                                        videoSelectorBack(proid);
                                    }}
                                    sx={{
                                        textTransform: "initial",
                                        backgroundColor: "#025196",
                                        color: "#FFFFFF",
                                        "&:hover": {
                                            backgroundColor: "#025196",
                                            color: "#FFFFFF",
                                        },
                                    }}
                                >
                                    Back
                                </Button>
                                {/* {
                                wholeSelectVideoLoader ?
                                    <Button
                                        variant="contained"
                                        sx={{
                                            marginLeft: '5px',
                                            textTransform: "initial",
                                            backgroundColor: "#025196",
                                            color: "#FFFFFF",
                                            "&:hover": {
                                                backgroundColor: "#025196",
                                                color: "#FFFFFF",
                                            },
                                        }}
                                    >
                                        <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} /><span style={{ paddingLeft: '15px' }}>Confirming...</span>
                                    </Button>
                                    :
                                    <Button
                                        variant="contained"
                                        onClick={handleReset}
                                        sx={{
                                            marginLeft: '5px',
                                            textTransform: "initial",
                                            backgroundColor: "#025196",
                                            color: "#FFFFFF",
                                            "&:hover": {
                                                backgroundColor: "#025196",
                                                color: "#FFFFFF",
                                            },
                                        }}
                                    >
                                        Process whole video
                                    </Button>
                            } */}
                                <Button
                                    disabled={!canUpdateVideoSelection}
                                    variant="contained"
                                    sx={{
                                        marginLeft: '10px',
                                        textTransform: "initial",
                                        backgroundColor: "#025196",
                                        color: "#FFFFFF",
                                        "&:hover": {
                                            backgroundColor: "#025196",
                                            color: "#FFFFFF",
                                        },
                                    }}
                                    onClick={handleStoreValues}>
                                    Record
                                </Button>
                                {
                                    selectVideoLoader ?
                                        <Button
                                            variant="contained"
                                            sx={{
                                                marginLeft: '10px',
                                                textTransform: "initial",
                                                backgroundColor: "#025196",
                                                color: "#FFFFFF",
                                                "&:hover": {
                                                    backgroundColor: "#025196",
                                                    color: "#FFFFFF",
                                                },
                                            }}
                                        >
                                            <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} /><span style={{ paddingLeft: '15px' }}>Confirming...</span>
                                        </Button>
                                        :
                                        <Button
                                            variant="contained"
                                            // || storedValues.length === 0
                                            disabled={!canUpdateVideoSelection}
                                            onClick={handleClick}
                                            sx={{
                                                marginLeft: '10px',
                                                textTransform: "initial",
                                                backgroundColor: "#025196",
                                                color: "#FFFFFF",
                                                "&:hover": {
                                                    backgroundColor: "#025196",
                                                    color: "#FFFFFF",
                                                },
                                            }}
                                        >
                                            Confirm
                                        </Button>
                                }
                                <Button
                                    variant="contained"
                                    disabled={!canUpdateVideoSelection}
                                    sx={{
                                        marginLeft: '10px',
                                        textTransform: "initial",
                                        backgroundColor: "#025196",
                                        color: "#FFFFFF",
                                        "&:hover": {
                                            backgroundColor: "#025196",
                                            color: "#FFFFFF",
                                        },
                                    }}
                                    onClick={handleOpenRangeDialog}>
                                    Excluded zones
                                </Button>
                                <Button
                                    onClick={handleDeleteDialog}
                                    variant="contained"
                                    sx={{
                                        marginLeft: '10px',
                                        textTransform: "initial",
                                        backgroundColor: "#025196",
                                        color: "#FFFFFF",
                                        "&:hover": {
                                            backgroundColor: "#025196",
                                            color: "#FFFFFF",
                                        },
                                    }}
                                >
                                    Delete videos
                                </Button>
                                <Button
                                    variant="contained"
                                    sx={{
                                        marginLeft: '10px',
                                        textTransform: "initial",
                                        backgroundColor: "#025196",
                                        color: "#FFFFFF",
                                        "&:hover": {
                                            backgroundColor: "#025196",
                                            color: "#FFFFFF",
                                        },
                                    }}
                                    onClick={() => handleTabChange({}, segmentIndex + 1)}>
                                    Skip
                                </Button>
                                <Button
                                    variant="contained"
                                    sx={{
                                        marginLeft: '10px',
                                        textTransform: "initial",
                                        backgroundColor: "#025196",
                                        color: "#FFFFFF",
                                        "&:hover": {
                                            backgroundColor: "#025196",
                                            color: "#FFFFFF",
                                        },
                                    }}
                                    onClick={() => handleTabChange({}, videosList.length)}>
                                    Skip all videos
                                </Button>
                                {
                                    distanceErrorMessage.length > 0 ? <IconButton
                                        size="large"
                                        aria-label="show new notifications"
                                        sx={{ '&:hover': { backgroundColor: 'tranparent', color: 'black', }, backgroundColor: 'tranparent', color: 'black', marginInline: '8px' }}
                                        onClick={handleOpenWarningList}
                                    >
                                        <Badge
                                            badgeContent={distanceErrorMessage.length}
                                            // variant="dot"
                                            color="error"
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                        >
                                            <WarningAmberIcon sx={{ '&:hover': { backgroundColor: 'tranparent', color: 'black', }, color: 'black' }} />
                                        </Badge>
                                    </IconButton> : <></>
                                }
                            </Box>
                        </Grid>
                        : <></>}

                </Grid>
            </>
    );
}

const mapStateToProps = (state, ownProps) => {
    const { proid } = ownProps;
    return {
        project: state.project,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        videoSelection: (videoSelectionData) => dispatch(videoSelection(videoSelectionData)),
        wholeVideoSelection: (videoSelectionData) => dispatch(wholeVideoSelection(videoSelectionData)),
        removeVideoSelectionErrorAndMessage: () => dispatch(removeVideoSelectionErrorAndMessage()),
        removeWholeVideoSelectionErrorAndMessage: () => dispatch(removeWholeVideoSelectionErrorAndMessage()),
        videoSelectorBack: (proid) => dispatch(videoSelectorBack(proid)),
        videoSelectorNext: (proid) => dispatch(videoSelectorNext(proid)),
        deleteVideos: (proid, newList) => dispatch(deleteVideos(proid, newList)),
        clearDeleteVideos: () => dispatch(clearDeleteVideos()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoSelectorStep);

import { toLatLon, fromLatLon } from 'utm';

function downsampleGpsOnDistance(gpsData, distance) {
    let initialOutput = {
        finalGpsData: [gpsData[0]],
        lastObj: gpsData[0],
        commulativeDistance: 0
    }

    let processingData = gpsData.slice(1, -1)

    let finalOutput = processingData.reduce((acc, obj) => {
        let currentDistance = acc.commulativeDistance + calculateDistance(acc.lastObj.lat, acc.lastObj.lng, obj.lat, obj.lng)
        if (currentDistance > distance) {
            return {
                ...acc,
                finalGpsData: [...acc.finalGpsData, obj],
                lastObj: obj,
                commulativeDistance: 0
            }
        } else {
            return {
                ...acc,
                lastObj: obj,
                commulativeDistance: currentDistance
            }
        }
    }, initialOutput)

    return [...finalOutput.finalGpsData.slice(0, -1), gpsData.at(-1)]

}

function runGpsModificationStartEnd(fullFilteredGpsArray, modifiedPoint, temp_startIndex, temp_endIndex) {

    let startIndex = temp_startIndex
    let endIndex = temp_endIndex
    let firstEdited = true;

    if (startIndex > endIndex) {
        startIndex = temp_endIndex
        endIndex = temp_startIndex
        firstEdited = false
    }

    let filteredGpsArray = fullFilteredGpsArray.slice(startIndex, endIndex + 1)

    let userProvidedPointers_temp = [firstEdited == true ? modifiedPoint : filteredGpsArray.at(0),
    firstEdited == false ? modifiedPoint : filteredGpsArray.at(-1)]

    let userProvidedPointers = [userProvidedPointers_temp[0],
    { lat: ((userProvidedPointers_temp[0].lat + userProvidedPointers_temp[1].lat) / 2), lng: ((userProvidedPointers_temp[0].lng + userProvidedPointers_temp[1].lng) / 2) },
    userProvidedPointers_temp[1]]
    let filteredGpsLatLong = filteredGpsArray.map(each => [each.lat, each.lng])
    let userProvidedGpsLatLong = userProvidedPointers.map(each => [each.lat, each.lng])
    let outputFilteredGpsLatLong_temp = returnNewGpsArray(filteredGpsLatLong, userProvidedGpsLatLong)
    let outputFilteredGpsLatLong = [...outputFilteredGpsLatLong_temp, filteredGpsLatLong.at(-1)]
    let newFilteredGpsArray = fullFilteredGpsArray.map((each, index) => {
        if (index >= startIndex && index <= endIndex) {
            return { ...each, lat: outputFilteredGpsLatLong[index - startIndex][0], lng: outputFilteredGpsLatLong[index - startIndex][1] }
        }
        else {
            return each;
        }
    })
    return newFilteredGpsArray
}

function runGpsModificationFunction(fullFilteredGpsArray, temp_userProvidedLatLong, temp_startIndex, temp_endIndex) {

    let startIndex = temp_startIndex
    let endIndex = temp_endIndex
    let userProvidedLatLong = temp_userProvidedLatLong.slice()

    if (startIndex > endIndex) {
        startIndex = temp_endIndex
        endIndex = temp_startIndex
        userProvidedLatLong = temp_userProvidedLatLong.slice().reverse()
    }

    let filteredGpsArray = fullFilteredGpsArray.slice(startIndex, endIndex + 1)
    let userProvidedPointers = [filteredGpsArray.at(0), ...userProvidedLatLong, filteredGpsArray.at(-1)]
    let filteredGpsLatLong = filteredGpsArray.map(each => [each.lat, each.lng])
    let userProvidedGpsLatLong = userProvidedPointers.map(each => [each.lat, each.lng])
    let outputFilteredGpsLatLong = returnNewGpsArray(filteredGpsLatLong, userProvidedGpsLatLong)
    let newFilteredGpsArray = fullFilteredGpsArray.map((each, index) => {
        if (index >= startIndex && index < endIndex) {
            return { ...each, lat: outputFilteredGpsLatLong[index - startIndex][0], lng: outputFilteredGpsLatLong[index - startIndex][1] }
        }
        else {
            return each;
        }
    })
    return newFilteredGpsArray
}

function returnNewGpsArray(originalGps, userProvidedPoints) {
    // INPUT REQUIREMENTS
    // BOTH INPUTS ARE IN LAT LONG
    // A => B ===================> A = ORIGINAL_GPS[0], B = ORIGINAL_GPS[-1]
    // 0TH AND -1ST INDEX OF BOTH ARRAY SHOULD BE SAME
    // ORIGINAL GPS =[[LAT1, LONG1], [LAT2, LONG2] ..... [LATn, LONGn]]
    // USER PROVIDED POINTS = [[LAT1, LONG1], [LAT2, LONG2] ..... [LATn, LONGn]]

    let utmOriginalArray = originalGps.map(each => latLonToUTM(each))
    let utmUserProvidedArray = userProvidedPoints.map(each => latLonToUTM(each))

    let { totalDistance: totalDistanceOrig, inBetweenGpsDistance: inBetweenGpsDistanceOrig } = getDistanceOf2dGpsArray(originalGps)
    let { totalDistance: totalDistanceUser, inBetweenGpsDistance: inBetweenGpsDistanceUser } = getDistanceOf2dGpsArray(userProvidedPoints)


    // R = DISTANCE RATIO ARRAY ORIGINAL ,,, 1 => original, 2 = user provided
    let R1 = inBetweenGpsDistanceOrig.map(each => (each / totalDistanceOrig))
    let R2 = inBetweenGpsDistanceUser.map(each => (each / totalDistanceUser))

    let newR2 = [...R2]
    newR2.shift()

    let initialIndicesObject = {
        initialValue: R2[0],
        outputData: []
    }


    let finalIndicesObject = newR2.reduce((acc, each) => {
        let minR2 = acc.initialValue
        let maxR2 = each

        let R1InBetween = R1.filter(each => minR2 < each && each <= maxR2)
        let filteredUserUtmPoints = utmUserProvidedArray.slice(R2.indexOf(minR2), R2.indexOf(maxR2) + 1)

        let startUtmX = filteredUserUtmPoints[0][1]
        let startUtmY = filteredUserUtmPoints[0][2]
        let endUtmX = filteredUserUtmPoints[1][1]
        let endUtmY = filteredUserUtmPoints[1][2]
        let zone = filteredUserUtmPoints[0][0]
        let zoneLetter = filteredUserUtmPoints[0][3]

        let newUtmPoints = R1InBetween.map(r1j => {
            return [zone, genUtmFormula(r1j, minR2, maxR2, startUtmX, endUtmX), genUtmFormula(r1j, minR2, maxR2, startUtmY, endUtmY), zoneLetter]
        })

        return {
            initialValue: each,
            outputData: [...acc.outputData, ...newUtmPoints]
        }

    }, initialIndicesObject)

    let { initialValue, outputData } = finalIndicesObject

    return [utmUserProvidedArray[0], ...outputData].map(each => UTMToLatLon(each))
}

function genUtmFormula(r1j, minR2, maxR2, startUtm, endUtm) {
    return (((r1j - minR2) / (maxR2 - minR2)) * endUtm) + (((maxR2 - r1j) / (maxR2 - minR2)) * startUtm)
}

function getDistanceOf2dGpsArray(gpsArray2d) {
    let acc = {
        currentGps: gpsArray2d[0],
        totalDistance: 0,
        inBetweenGpsDistance: [0]
    }

    let newGpsArray = gpsArray2d.slice(1)

    let finalAcc = newGpsArray.reduce((acc, obj) => {
        let distance = calculateDistance(acc.currentGps[0], acc.currentGps[1], obj[0], obj[1])
        return {
            currentGps: obj,
            totalDistance: acc.totalDistance + distance,
            inBetweenGpsDistance: [...acc.inBetweenGpsDistance, acc.totalDistance + distance]
        }
    }, acc)

    return {
        ...finalAcc,
        currentGps: undefined
    }
}

function calculateDistance(lat1, lon1, lat2, lon2) {
    const R = 6371; // Radius of the Earth in kilometers
    const dLat = (lat2 - lat1) * Math.PI / 180; // Convert degrees to radians
    const dLon = (lon2 - lon1) * Math.PI / 180;
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in kilometers
    return distance * 1000;
}

function latLonToUTM(gpsCoord) {
    let latitude = gpsCoord[0]
    let longitude = gpsCoord[1]
    const utmCoordinates = fromLatLon(latitude, longitude);
    return [utmCoordinates.zoneNum, utmCoordinates.easting, utmCoordinates.northing, utmCoordinates.zoneLetter];
}

function UTMToLatLon(utmArray) {
    let zone = utmArray[0]
    let easting = utmArray[1]
    let northing = utmArray[2]
    let zoneLetter = utmArray[3]
    const latLong = toLatLon(easting, northing, zone, zoneLetter);
    return [latLong.latitude, latLong.longitude];
}

export { runGpsModificationStartEnd, runGpsModificationFunction, downsampleGpsOnDistance }

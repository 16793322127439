import React from 'react'
import FullScreen360VideoPlayer from './FullScreen360VideoPlayer'
import { useParams } from 'react-router-dom';

const FullScreen360VideoPlayerController = (props) => {
    const { proid } = useParams();
    return (
        <FullScreen360VideoPlayer proid={proid} userData={props.userData} />
    )
}

export default FullScreen360VideoPlayerController
import { Chip, Tooltip } from "@mui/material";
import AdjustIcon from '@mui/icons-material/Adjust';

const NetworkStatusIndicator = ({ status = 999, empty = false }) => {

    const statusMap = {
        "0": "Netwok created",
        "999": ""
    }
    if (empty) {
        return <Tooltip title={statusMap[`${status}`]}><AdjustIcon sx={status < 0 ? { color: '#C70202' } : { color: '#025196' }} /></Tooltip>
    }
    return <Chip variant="outlined" label={empty ? "" : statusMap[`${status}`]}
        sx={{
            borderRadius: '', fontSize: '12px', ...status < 0 ?
                { backgroundColor: '#ffffff', color: '#C70202', fontWeight: 'bold', border: '1px solid #C70202' } :
                { backgroundColor: '#ffffff', color: '#025196', fontWeight: 'bold', border: '1px solid #025196' }
        }}
        size="small"
    />
}

export default NetworkStatusIndicator
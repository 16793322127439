import React, { useState, useEffect } from 'react';
import { Button, IconButton, Menu, Card, MenuItem, CardMedia, Checkbox, CircularProgress, Collapse, List, ListItem, ListItemIcon, ListItemText, TextField, Tooltip, Typography, Grid, Divider } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Link } from "react-router-dom";
import * as ROUTES from "../../../../constants/routes";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { removeInferenceErrorAndMessage, deleteProject, makeHaltProcessingRequestOnProject, makeAdvanceInferenceRequest, mirrorProject, resetMirrorProjectErrOrMsg, saveMirrorProjectEmail, removeHaltErrorAndMessage, updateProject, } from "../../../../store/actions/projectActions";
import ProjectStatusIndicator from '../../../cidgets/ProjectStatusIndicator';
import SimpleDialogBox from '../../../cidgets/SimpleDialogBox';
import DateParser from '../../../../helpers/DateParser';
import ProjectParser from '../../../../helpers/ProjectParser';
import SmartUtils from '../../../../helpers/SmartUtils';
import AnalysisTypeIndicator from '../../../cidgets/AnalysisTypeIndicator';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { get } from "lodash";
import ProjectDetails from './surfaceEvaluationComponents/ProjectDetails';
import { useAccessControlContext } from '../../../../contexts/AccessControlProvider';


const ProjectBriefCardV2 = (props) => {
    const { permissions } = useAccessControlContext();
    const { canArchiveProject, canHaltProject, canMirrorProject, canEditProjectInformationBeforeAnalysis, canEditProjectInformationAfterAnalysis } = permissions;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openMirrorProjectDialog, setOpenMirrorProjectDialog] = useState(false);
    const [openHaltDialog, setOpenHaltDialog] = useState(false);
    const [openResumeDialog, setOpenResumeDialog] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [mirrorProjectEmail, setMirrorProjectEmail] = useState('');
    const [errorMirrorProjectEmail, setErrorMirrorProjectEmail] = useState(false);
    const [openInfoDialog, setOpenInfoDialog] = useState(false);
    const [openMirroProjectList, setOpenMirroProjectList] = useState(false);
    const [openDetails, setOpenDetails] = useState(false);

    const { projectCard, userData, project, cardRef } = props;
    const { haltLoader, inferenceLoader, haltIsDoneAndSent, infernenceIsDoneAndSent, inferenceMessage, haltMessage, isProjectUpdating } = project;
    const { creatorUid, projectTitle, identificationNo, dateCreated = '', proid, status, thumbnail, camDir = "0", camPos = "0", roadSide = "0", fps = "", projectType, isForked = false, forkedFrom = "-1" } = projectCard;
    const date = dateCreated ? DateParser(dateCreated, 0) : DateParser(dateCreated, 2)
    // //////console.log(projectCard.proid, 'projectCard')
    const titleStyle = { fontWeight: 'bold', fontSize: '14px' }
    const su = new SmartUtils()
    const isHalted = projectCard.isHalted ? projectCard.isHalted : false;//false
    const isHaltPossible = projectCard.isHaltPossible ? projectCard.isHaltPossible : false;//true
    const isHaltInProcess = projectCard.isHaltInProcess ? projectCard.isHaltInProcess : false;
    const haltResume = [7, 8];

    const storedProid = localStorage.getItem('proid');
    // //////console.log(storedProid, 'storedProid')

    const [customCategories, setCustomCategories] = useState([]);
    const [error, setError] = useState(false);
    const [drivingSideError, setDrivingSideError] = useState(false);
    const [surveyDateError, setSurveyDateError] = useState(false);
    const [processingTimeError, setProcessingTimeError] = useState(false);
    const [typeOfRoadError, setTypeOfRoadError] = useState(false);
    const [processingTime, setProcessingTime] = useState(projectCard.processingTime ?? '');
    const [values, setValues] = useState({
        projectName: projectCard.projectTitle,
        drivingSide: projectCard.roadSide,
        typeOfRoad: projectCard.typeOfRoad ?? "",
        cameraPosition: "",
        cameraDirection: "",
        cameraMountingHeight: "",
        cameraInclination: "",
        surveyDate: projectCard.surveyDate ?? "",
        // processingTime: projectCard.processingTime ?? "",
        identificationNo: projectCard.identificationNo ?? "",
        clientName: projectCard.clientName ?? "",
        streetName: projectCard.streetName ?? "",
        startingAddress: projectCard.startingAddress ?? "",
        endingAddress: projectCard.endingAddress ?? "",
        direction: projectCard.direction ?? "",
        totalLanes: projectCard.totalLanes ?? "",
        laneNumber: projectCard.laneNumber ?? ""
    });

    useEffect(() => {
        setProcessingTime(projectCard.processingTime ?? '')
        setValues({
            projectName: projectCard.projectTitle,
            drivingSide: projectCard.roadSide,
            typeOfRoad: projectCard.typeOfRoad ?? "",
            projectType: projectCard.projectType,
            cameraPosition: "",
            cameraDirection: "",
            cameraMountingHeight: "",
            cameraInclination: "",
            surveyDate: projectCard.surveyDate ?? "",
            // processingTime: projectCard.processingTime ?? "",
            identificationNo: projectCard.identificationNo ?? "",
            clientName: projectCard.clientName ?? "",
            streetName: projectCard.streetName ?? "",
            startingAddress: projectCard.startingAddress ?? "",
            endingAddress: projectCard.endingAddress ?? "",
            direction: projectCard.direction ?? "",
            totalLanes: projectCard.totalLanes ?? "",
            laneNumber: projectCard.laneNumber ?? "",
        });
    }, [projectCard])
    const handleDetailsOpen = () => {
        setOpenDetails(true);
    };

    const handleDetailsClose = () => {
        setOpenDetails(false);
    };

    const checkEmailInArray = (email, emailArray) => {
        if (!emailArray || emailArray.length === 0) {
            return false;
        }
        // Check if the email exists in the array
        return emailArray.includes(email);
    };
    useEffect(() => {
        if (props.project.mirrorProjectIsDoneAndSent === true) {
            const isEmailInArray = checkEmailInArray(mirrorProjectEmail, userData.userEmailList ?? []);
            if (!isEmailInArray) {
                if (mirrorProjectEmail !== '') {
                    props.saveMirrorProjectEmail(userData.uid, mirrorProjectEmail)
                }
            }
            handleCloseMirrorProjectDialog();
        }
    }, [props.project.mirrorProjectIsDoneAndSent])

    useEffect(() => {
        const element = document.getElementById(storedProid);
        element?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }, [])

    useEffect(() => {
        if (selectedItems.length > 0) {
            setMirrorProjectEmail(selectedItems[0])
        }
    }, [selectedItems])

    useEffect(() => {
        if (infernenceIsDoneAndSent) {
            handleCloseResumeDialog();
        }
    }, [infernenceIsDoneAndSent])
    useEffect(() => {
        if (props.project.isProjectDeleting === false) {
            handleCloseDeleteDialog();
        }
    }, [props.project.isProjectDeleting])
    useEffect(() => {
        if (haltIsDoneAndSent === true) {
            handleCloseHaltDialog();
        }
    }, [haltIsDoneAndSent])

    useEffect(() => {
        if (isProjectUpdating === false) {
            handleDetailsClose();
        }
    }, [isProjectUpdating])

    const openEditDeleteMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const closeEditDeleteMenu = () => {
        setAnchorEl(null);
    };
    const handleOpenMirrorProjectDialog = () => {
        props.resetMirrorProjectErrOrMsg();
        setErrorMirrorProjectEmail(false);
        setOpenMirrorProjectDialog(true);
        setMirrorProjectEmail("");
        setSelectedItems([]);
    };
    const handleCloseMirrorProjectDialog = () => {
        setOpenMirrorProjectDialog(false);
        closeEditDeleteMenu();
    };
    const handleOpenDeleteDialog = () => {
        closeEditDeleteMenu();
        setOpenDeleteDialog(true);
    };
    const handleDeleteSubmit = () => {
        props.deleteProject(proid);
        // handleCloseDeleteDialog();
    }
    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
        closeEditDeleteMenu();
    };
    const handleOpenInfoDialog = () => {
        closeEditDeleteMenu();
        setOpenInfoDialog(true);
        setOpenDetails(true);
    };
    const handleCloseInfoDialog = () => {
        setOpenInfoDialog(false);
    };
    const handleOpenHaltDialog = () => {
        props.removeHaltErrorAndMessage();
        setOpenHaltDialog(true);
        closeEditDeleteMenu();
    }
    const handleCloseHaltDialog = () => {
        setOpenHaltDialog(false);
        closeEditDeleteMenu();
    }
    const handleHalt = () => {
        props.makeHaltProcessingRequestOnProject(proid);
    }
    const handleOpenResumeDialog = () => {
        props.removeInferenceErrorAndMessage();
        setOpenResumeDialog(true);
        closeEditDeleteMenu();
    }
    const handleCloseResumeDialog = () => {
        setOpenResumeDialog(false);
        closeEditDeleteMenu();
    }
    const handleResume = () => {
        props.makeAdvanceInferenceRequest(proid);
    }
    const handleMirrorProject = () => {
        setErrorMirrorProjectEmail(false);
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValid = emailRegex.test(mirrorProjectEmail);
        if (isValid) {
            props.mirrorProject(proid, mirrorProjectEmail);
        }
        else {
            setErrorMirrorProjectEmail(true);
        }
    }
    const handleMirrorEmailChange = (e) => {
        setErrorMirrorProjectEmail(false);
        setMirrorProjectEmail(e.target.value)
    }
    const handleToggle = () => {
        setOpenMirroProjectList(!openMirroProjectList);
    };
    const handleCheckboxToggle = (value) => () => {
        const selectedIndex = selectedItems.indexOf(value);
        const newSelected = [...selectedItems];
        if (newSelected.length === 0) {
            if (selectedIndex === -1) {
                newSelected.push(value);
            }
        }
        else {
            if (selectedIndex !== -1) {
                setMirrorProjectEmail("");
                newSelected.splice(selectedIndex, 1);
            }
            else {
                newSelected.splice(0, 1);
                newSelected.push(value);
            }
        }
        setSelectedItems(newSelected);
    };

    const handleSubmit = (e) => {
        if (projectType === 1 || projectType === 3 || projectType === 4 || projectType === 5) {

            if (values.projectName.length === 0) {
                setError(true);
            }
            else if (values.drivingSide === "") {
                setDrivingSideError(true);
            }
            else if (processingTime === "") {
                setProcessingTimeError(true);
            }

            else if (values.surveyDate === "") {
                setSurveyDateError(true);
            }
            // else if (values.cameraPosition === "") {
            //   setCameraPositionError(true);
            // }
            // else if (values.cameraDirection === "") {
            //   setCameraDirectionError(true);
            // }

            else {
                // //////console.log("project data: ", values, projectType)
                e.preventDefault();
                props.updateProject(
                    proid,
                    {
                        identificationNo: values.identificationNo,
                        clientName: values.clientName,
                        streetName: values.streetName,
                        startingAddress: values.startingAddress,
                        endingAddress: values.endingAddress,
                        direction: values.direction,
                        totalLanes: values.totalLanes,
                        laneNumber: values.laneNumber,
                        projectTitle: values.projectName,
                        roadSide: values.drivingSide,
                        processingTime: processingTime,
                        surveyDate: values.surveyDate,
                        typeOfRoad: values.typeOfRoad,
                        projectType: projectType,
                        categories: customCategories,
                    });
            }
        }

        else {
            if (values.projectName.length === 0) {
                setError(true);
            }
            else {
                e.preventDefault();
                props.updateProject(
                    proid,
                    {
                        identificationNo: values.identificationNo,
                        clientName: values.clientName,
                        streetName: values.streetName,
                        startingAddress: values.startingAddress,
                        endingAddress: values.endingAddress,
                        direction: values.direction,
                        totalLanes: values.totalLanes,
                        laneNumber: values.laneNumber,
                        projectTitle: values.projectName,
                        roadSide: values.drivingSide,
                        processingTime: processingTime,
                        surveyDate: values.surveyDate,
                        typeOfRoad: values.typeOfRoad,
                        projectType: projectType,
                        categories: customCategories,
                    });

            }

        }
    };

    return (
        <>
            <SimpleDialogBox
                open={openDetails}
                onClose={handleDetailsClose}
                title="Project details "
                width={600}
                bodyWidget={
                    <ProjectDetails drProjectData={projectCard} proid={projectCard.proid} projectType={projectType} error={error}
                        setError={setError}
                        drivingSideError={drivingSideError}
                        setDrivingSideError={setDrivingSideError}
                        surveyDateError={surveyDateError}
                        setSurveyDateError={setSurveyDateError}
                        processingTimeError={processingTimeError}
                        setProcessingTimeError={setProcessingTimeError}
                        typeOfRoadError={typeOfRoadError}
                        setTypeOfRoadError={setTypeOfRoadError}
                        values={values}
                        setValues={setValues}
                        customCategories={customCategories}
                        setCustomCategories={setCustomCategories}
                        companyPricingData={props.companyPricingData}
                        chargesV3={props.chargesV3}
                        processingTime={processingTime}
                        setProcessingTime={setProcessingTime}
                    />
                }
                showPositive
                positiveButtonWidget={
                    isProjectUpdating === true ? (
                        <>
                            <Button
                                variant="contained"
                                disabled
                                sx={{
                                    "&.Mui-disabled": {
                                        backgroundColor: "#025196",
                                        color: "#ffffff",
                                    },
                                    marginInline: "2px",
                                    textTransform: "initial",
                                    color: "#025196",
                                    backgroundColor: "#025196",
                                    ":hover": {
                                        backgroundColor: "#025196",
                                    },
                                }}
                            >
                                <CircularProgress
                                    variant="indeterminate"
                                    size={15}
                                    sx={{ color: "white" }}
                                />
                                <span style={{ paddingLeft: "15px" }}>
                                    Updating details...
                                </span>
                            </Button>
                        </>
                    ) : (
                        <Button
                            variant="contained"
                            disabled={projectType === undefined ? true : false}
                            sx={{
                                marginInline: "2px",
                                textTransform: "initial",
                                backgroundColor: "#025196",
                                ":hover": {
                                    backgroundColor: "#025196",
                                },
                            }}
                            onClick={handleSubmit}
                        >
                            Update details
                        </Button>
                    )
                }
            />


            <SimpleDialogBox
                open={openDeleteDialog}
                onClose={handleCloseDeleteDialog}
                title="Archive project"
                cancelText="Close"
                desc={`Are you sure you want to archive your project? Archiving will move it to the designated folder for 30 days before permanent deletion. You can always request project unarchiving later through our contact form.`}
                bodyWidget={
                    <></>
                }
                width={600}
                showPositive
                positiveButtonWidget={
                    props.project.isProjectDeleting === true ?
                        <>
                            <Button variant="contained" disabled sx={{
                                "&.Mui-disabled": {
                                    backgroundColor: '#025196',
                                    color: '#ffffff'
                                },
                                marginInline: '2px',
                                textTransform: 'initial',
                                color: '#025196',
                                backgroundColor: '#025196', ':hover': {
                                    backgroundColor: '#025196'
                                }
                            }} >
                                <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} /><span style={{ paddingLeft: '15px' }}>Archiving project...</span>
                            </Button>
                        </> :
                        <Button variant="contained" sx={{
                            marginInline: '2px',
                            textTransform: 'initial',
                            backgroundColor: '#025196', ':hover': {
                                backgroundColor: '#025196'
                            },
                        }} onClick={handleDeleteSubmit}>Archive now</Button>
                }
            />
            {/* Halt Dialog */}
            <SimpleDialogBox
                open={openHaltDialog}
                onClose={handleCloseHaltDialog}
                title="Halt project"
                cancelText="Close"
                desc={`Are you sure you want to halt your project?`}
                bodyWidget={
                    <></>
                }
                width={600}
                showPositive
                showError={haltIsDoneAndSent === true ? false : true}
                errorMessage={haltIsDoneAndSent === true ? "" : haltMessage}
                positiveButtonWidget={
                    haltLoader ?
                        <>
                            <Button variant="contained" disabled sx={{
                                "&.Mui-disabled": {
                                    backgroundColor: '#025196',
                                    color: '#FFFFFF'
                                },
                                marginInline: '2px',
                                textTransform: 'initial',
                                color: '#FFFFFF',
                                backgroundColor: '#025196', ':hover': {
                                    backgroundColor: '#025196'
                                }
                            }} >
                                <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} /><span style={{ paddingLeft: '15px' }}>Halting...</span>
                            </Button>
                        </> :
                        <Button variant="contained" sx={{
                            marginInline: '2px',
                            textTransform: 'initial',
                            color: '#FFFFFF',
                            backgroundColor: '#025196', ':hover': {
                                backgroundColor: '#025196'
                            },
                        }} onClick={() => { handleHalt() }}>Halt</Button>
                }
            />
            {/* Reume Dialog */}
            <SimpleDialogBox
                open={openResumeDialog}
                onClose={handleCloseResumeDialog}
                title="Resume project"
                cancelText="Close"
                desc={`Are you sure you want to resume your project?`}
                bodyWidget={
                    <></>
                }
                showError={infernenceIsDoneAndSent === true ? false : true}
                errorMessage={infernenceIsDoneAndSent === true ? "" : inferenceMessage}
                width={600}
                showPositive
                positiveButtonWidget={
                    inferenceLoader ?
                        <>
                            <Button variant="contained" disabled sx={{
                                "&.Mui-disabled": {
                                    backgroundColor: '#025196',
                                    color: '#FFFFFF'
                                },
                                marginInline: '2px',
                                textTransform: 'initial',
                                color: '#FFFFFF',
                                backgroundColor: '#025196', ':hover': {
                                    backgroundColor: '#025196'
                                }
                            }} >
                                <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} /><span style={{ paddingLeft: '15px' }}>Resuming...</span>
                            </Button>
                        </> :
                        <Button variant="contained" sx={{
                            marginInline: '2px',
                            textTransform: 'initial',
                            color: '#FFFFFF',
                            backgroundColor: '#025196', ':hover': {
                                backgroundColor: '#025196'
                            },
                        }} onClick={() => { handleResume() }}>Resume</Button>
                }
            />
            <SimpleDialogBox
                open={openMirrorProjectDialog}
                onClose={handleCloseMirrorProjectDialog}
                title="Mirror project"
                cancelText="Close"
                desc={`Please provide any email for project mirroring.`}
                showError={props.project.mirrorProjectIsDoneAndSent === false ? true : false}
                errorMessage={props.project.mirrorProjectIsDoneAndSent === false ? props.project.mirrorProjectMessage : ''}
                bodyWidget={
                    <>
                        <Grid container spacing={0}>
                            <Grid item xs={userData.userEmailList ? 11 : 12}>
                                <TextField fullWidth label="Email" id="fullWidth" value={mirrorProjectEmail} disabled={props.project.mirrorProjectLoader} error={errorMirrorProjectEmail} helperText={errorMirrorProjectEmail ? "Invalid email" : ''} onChange={(e) => { handleMirrorEmailChange(e) }} />
                            </Grid>
                            <Grid item xs={userData.userEmailList ? 1 : 0} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {userData.userEmailList ? <IconButton onClick={handleToggle}>
                                    {openMirroProjectList ? <Tooltip title="Close"><ExpandLess /></Tooltip> : <Tooltip title="Expand to see existing emails"><ExpandMore /></Tooltip>}
                                </IconButton> : <></>}
                            </Grid>
                        </Grid>
                        <Collapse in={openMirroProjectList} timeout="auto" unmountOnExit>
                            <List>
                                {userData.userEmailList && userData.userEmailList.map((user, i) => {
                                    return (
                                        <ListItem key={i}>
                                            <ListItemIcon>
                                                <Checkbox edge="start" tabIndex={-1} disableRipple
                                                    onChange={handleCheckboxToggle(`${user}`)}
                                                    checked={selectedItems.indexOf(`${user}`) !== -1}
                                                />
                                            </ListItemIcon>
                                            <ListItemText primary={user} />
                                        </ListItem>
                                    )
                                })
                                }
                            </List>
                        </Collapse>
                    </>
                }
                width={700}
                showPositive
                positiveButtonWidget={
                    props.project.mirrorProjectLoader ?
                        <>
                            <Button variant="contained" disabled sx={{
                                "&.Mui-disabled": {
                                    backgroundColor: '#025196',
                                    color: '#ffffff'
                                },
                                marginInline: '2px',
                                textTransform: 'initial',
                                color: '#025196',
                                backgroundColor: '#025196', ':hover': {
                                    backgroundColor: '#025196'
                                }
                            }} >
                                {/* <CustomProgress text={"Mirroring the project"} /> */}
                                <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} /><span style={{ paddingLeft: '15px' }}>Mirroring...</span>
                            </Button>
                        </> :
                        <Button variant="contained" sx={{
                            marginInline: '2px',
                            textTransform: 'initial',
                            backgroundColor: '#025196', ':hover': {
                                backgroundColor: '#025196'
                            },
                        }} onClick={handleMirrorProject}>Accept</Button>
                }
            />
            <Card
                key={projectCard.proid}
                id={projectCard.proid}
                ref={cardRef}
                sx={{
                    borderRadius: '20px', ':hover': {
                        boxShadow: 20,
                    },
                    outline: storedProid === projectCard.proid ? '4px solid #60EE00' : 'none'
                }}
                elevation={3} >
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={closeEditDeleteMenu}
                >
                    {((status >= 1 && status <= 5 && canEditProjectInformationBeforeAnalysis) ||
                        (status >= 8 && status <= 9 && canEditProjectInformationAfterAnalysis)) && (
                            <MenuItem
                                onClick={() => {
                                    handleOpenInfoDialog();
                                }}
                            >
                                Show information
                            </MenuItem>
                        )}
                    {canMirrorProject ? <MenuItem
                        onClick={handleOpenMirrorProjectDialog}
                    >
                        Create a mirror
                    </MenuItem> : <></>}
                    {/* {isForked ? <></> : <MenuItem
                        onClick={handleOpenMirrorProjectDialog}
                    >
                        Create a mirror
                    </MenuItem>} */}
                    {canArchiveProject ? <MenuItem
                        onClick={() => {
                            handleOpenDeleteDialog();
                        }}
                    >
                        Archive project
                    </MenuItem> : <></>}
                    {!isForked && haltResume.includes(status) && !isHaltInProcess && (
                        isHalted && !isHaltPossible ? (
                            canHaltProject ? <MenuItem onClick={handleOpenResumeDialog}>Resume project</MenuItem> : <></>
                        ) : (
                            canHaltProject ? <MenuItem onClick={handleOpenHaltDialog}>Halt Project</MenuItem> : <></>
                        )
                    )}
                    {/* {isForked ? <></> : !haltResume.includes(status) ? <></> : isHaltInProcess ?
                        <></>
                        : isHalted === true ?
                            isHaltPossible === false ? <MenuItem
                                onClick={() => {
                                    handleOpenResumeDialog();
                                }}
                            >
                                Resume project
                            </MenuItem> :
                                <MenuItem
                                    onClick={() => {
                                        handleOpenResumeDialog();
                                    }}
                                >
                                    Resume project
                                </MenuItem>
                            :
                            <MenuItem
                                onClick={() => {
                                    handleOpenHaltDialog();
                                }}
                            >
                                Halt Project
                            </MenuItem>} */}
                </Menu>
                <Link
                    className='on-cardhover'
                    style={{ color: 'black', cursor: 'pointer' }}
                    // to={`/home/${ROUTES.HOME_PROJECT}/${proid}`}
                    to={projectType === 0 || projectType === 3 ? `/home/${ROUTES.HOME_PROJECT}/${proid}/1` :
                        projectType === 2 ? `/home/${ROUTES.HOME_PROJECT}/${proid}/2` :
                            `/home/${ROUTES.HOME_PROJECT}/${proid}/0`}
                    // onClick={() => props.removeInferenceErrorAndMessage()}
                    onClick={() => {
                        localStorage.setItem('proid', projectCard.proid);
                        props.removeInferenceErrorAndMessage();
                    }}
                    onContextMenu={(e) => {
                        localStorage.setItem('proid', projectCard.proid);
                    }}
                >
                    <div style={{
                        aspectRatio: '1.7', minWidth: '300px', width: '350px',
                        position: 'relative',
                    }}>
                        <CardMedia
                            sx={{
                                aspectRatio: '1.7', minWidth: '300px', width: '350px',
                                position: 'absolute', top: 0, left: '0%', backgroundColor: ''
                            }}
                            src='project'
                            image={thumbnail ?? ''}
                            title={projectTitle}
                        />
                        <div style={{
                            position: 'absolute', top: 10, left: '3%',
                            height: '100%', backgroundColor: 'transparent',
                        }}>
                            {/* <AnalysisTypeIndicator type={projectType}/>
                            <span style={{margin: '2px'}}></span> */}
                            <ProjectStatusIndicator status={status} projectCard={projectCard} />
                            {/* <Chip variant="outlined" label={statusMap[`${status}`]} sx={status < 0 ? {backgroundColor: '#ffffff', color: '#C70202', fontWeight: 'bold', border: '1px solid #C70202' } : {backgroundColor: '#ffffff', color: '#041256', fontWeight: 'bold',border: '1px solid #041256'}} size="small" /> */}
                        </div>
                    </div>
                </Link>
                {/* <CardContent> */}
                <ListItem
                    dense
                    // disablePadding
                    sx={{ paddingLeft: '17px', paddingTop: '13px', paddingBottom: '13px' }}
                    secondaryAction={
                        <IconButton
                            title='View options'
                            variant="contained"
                            onClick={openEditDeleteMenu}
                        >
                            <MoreVertIcon />
                        </IconButton>
                    }
                >
                    <ListItemIcon sx={{ backgroundColor: '' }}>
                        <AnalysisTypeIndicator type={projectType} size={8} hasBorder={false} weight={'bold'} />
                    </ListItemIcon>
                    <ListItemText
                        primaryTypographyProps={{ style: { whiteSpace: "normal", marginLeft: '5px', marginRight: '15px' } }}
                        secondaryTypographyProps={{ style: { whiteSpace: "normal", marginLeft: '5px' } }}
                        // disableTypography={false}
                        primary={
                            <>
                                <span style={{ fontWeight: 'bold', display: 'flex', justifyContent: 'start', alignItems: 'center', marginTop: '0px' }}>

                                    <Link
                                        className='on-cardhover'
                                        style={{ cursor: 'pointer', backgroundColor: 'white', color: '#1e1e1e' }}
                                        // onClick={() =>  props.removeInferenceErrorAndMessage()}
                                        onClick={() => {
                                            localStorage.setItem('proid', projectCard.proid);
                                            props.removeInferenceErrorAndMessage();
                                        }}
                                        onContextMenu={(e) => {
                                            localStorage.setItem('proid', projectCard.proid);
                                        }}
                                        to={projectType === 0 || projectType === 3 ? `/home/${ROUTES.HOME_PROJECT}/${proid}/1` :
                                            projectType === 2 ? `/home/${ROUTES.HOME_PROJECT}/${proid}/2` :
                                                `/home/${ROUTES.HOME_PROJECT}/${proid}/0`}
                                    >

                                        <Tooltip title={projectTitle} placement='top'>
                                            <Typography
                                                // noWrap
                                                variant="p"
                                                maxHeight={5}
                                                sx={{ fontWeight: 'bold' }}
                                            >{`${projectTitle}`.length > 18 ? `${projectTitle}`.slice(0, 18).concat("...") : projectTitle}</Typography>
                                        </Tooltip>

                                    </Link>

                                </span>
                            </>
                        }
                        secondary={
                            <>

                                <span style={{ fontSize: '14px', marginLeft: '0px', marginBottom: '0px' }}>{date}</span>
                                {isForked ? <Tooltip title="A mirrored project is your private copy of an existing project" arrow><span style={{ color: 'grey', fontWeight: 'lighter', fontStyle: 'italic', cursor: 'pointer' }}> — Mirrored</span></Tooltip> : <></>}
                                {
                                    identificationNo !== '' && (
                                        <>
                                            <br />

                                            <span style={{ marginBottom: '10px' }}>
                                                {`${identificationNo}`.length > 18 ? `${identificationNo}`.slice(0, 18).concat("...") : identificationNo}
                                            </span>
                                        </>
                                    )
                                }
                            </>
                        }
                    />
                </ListItem>
            </Card>
        </>
    );
}
const mapStateToProps = (state) => {
    return {
        project: state.project,
        userData: state.firebase.profile,
        companyPricingData: state.firestore.data.CompanyPricingData,
        chargesV3: state.firestore.data.ChargesV3,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // setCurrentProjectId: (proid) => dispatch(setCurrentProjectId(proid)),
        removeInferenceErrorAndMessage: () => dispatch(removeInferenceErrorAndMessage()),
        removeHaltErrorAndMessage: () => dispatch(removeHaltErrorAndMessage()),
        deleteProject: (proid) => dispatch(deleteProject(proid)),
        mirrorProject: (proid, creatorUid) => dispatch(mirrorProject(proid, creatorUid)),
        saveMirrorProjectEmail: (uid, email) => dispatch(saveMirrorProjectEmail(uid, email)),
        resetMirrorProjectErrOrMsg: () => dispatch(resetMirrorProjectErrOrMsg()),
        // updateProject: (proid, projectName) =>
        //     dispatch(updateProject(proid, projectName)),
        makeHaltProcessingRequestOnProject: (proid) => dispatch(makeHaltProcessingRequestOnProject(proid)),
        makeAdvanceInferenceRequest: (proid) => dispatch(makeAdvanceInferenceRequest(proid)),
        updateProject: (proid, projectData) => dispatch(updateProject(proid, projectData)),
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect((props) => {
        // const { proid } = props.projectCard;
        return [
            // {
            //     collection: "InferenceRequests",
            //     where: [["proid", "==", proid]],
            //     orderBy: ["timeReqGen", "desc"],
            //     storeAs: `InferenceDocs_${proid}`,
            // },
        ];
    })
)(ProjectBriefCardV2);
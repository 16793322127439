import { Grid, Typography } from '@mui/material';
import navigationMap from './navigationMap.json'
import { ContactSupportOutlined } from '@mui/icons-material';
import OrderSearchButton from '../../../../cidgets/OrderSearchButton';

const AccountComponentTitle = (props) => {
    const { currentNavKey, companyOrdersHistory } = props;
    const navigationObject = navigationMap.navigations[currentNavKey]
    // //////console.log(currentNavKey, "key")
    if (currentNavKey === '2') {
        return (
            <Grid container spacing={0}>
                <Grid item xs={9}>
                    <Typography variant="h5" style={{ fontWeight: '650', fontSize: "17px" }}>
                        {navigationObject.name}
                    </Typography>
                    <Typography variant="body2" style={{ fontColor: 'gray' }}>
                        {navigationObject.desc}
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    {companyOrdersHistory.length <= 0 ? <></> : <OrderSearchButton companyOrdersHistory={companyOrdersHistory} />}
                </Grid>
            </Grid>
        )
    }
    else {
        return (
            <>
                <Typography variant="h5" style={{ fontWeight: '650', fontSize: "17px" }}>
                    {navigationObject.name}
                </Typography>
                <Typography variant="body2" style={{ fontColor: 'gray' }}>
                    {navigationObject.desc}
                </Typography>
            </>
        );
    }

}

export default AccountComponentTitle;
import { Button, Card, CircularProgress, IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import SimpleDialogBox from "../../../../../cidgets/SimpleDialogBox";
import { connect } from 'react-redux';
import * as React from "react";
import { useState, useEffect, useRef } from "react";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { openStripeBuyProductModal, requestReceiptGeneration, clearReceiptRequest } from "../../../../../../store/actions/paymentActions";
import commonNames from '../../../../../../constants/commonNames.json'
const OrderDetailsCard = (props) => {
    const { orderDetails, payment } = props;
    // //////console.log(orderDetails, 'order123')
    const { itemName, date, orderId, credits } = orderDetails;
    const [isReceiptGenerated, setIsReceiptGenerated] = useState(false);
    // //////console.log(payment, "payment");
    const { isBuyProdActed, isBuyProdActing, buyProdActMessage, receiptGenerationMessage, isDoneAndSent, isSending, receiptGenerationLoader } = payment;

    // //////console.log("buy", isBuyProdActed, isBuyProdActing, buyProdActMessage);
    const handleClick = () => {
        handleOpenDialog()
    }
    // const generateInvoice = () => {
    //     //////console.log("GET INVOICE", orderId);
    //     const { coid } = props.companyData;
    //     props.requestOrderInvoice(orderId, coid);
    // }

    const generateReceipt = () => {
        // //////console.log("GET RECEIPT", orderId);
        // //////console.log("messageBefore", receiptGenerationMessage);
        props.requestReceiptGeneration(orderId);
    };
    const cardStyle = {
        "border": "0.5px solid grey",
        "borderRadius": "8px",
        "backgroundColor": '#fafafa',
        "marginBlock": '10px',
    }

    // const orderData = [
    //     { label: 'Order ID:', value: receiptGenerationMessage },
    //     { label: 'Product name:', value: receiptGenerationMessage },
    //     { label: 'Total paid:', value: receiptGenerationMessage },
    //     { label: 'Payment due:', value: buyProdActMessage.paymentDue },
    //     { label: 'Invoice generated:', value: buyProdActMessage.invoiceGenerated },
    // ];
    //////////////INVOICE/////////////////
    // const invoiceList = <div>
    //     <TableContainer>
    //         <Table sx={{ minWidth: 250 }} aria-label="Order table" >
    //             <TableBody>
    //                 {orderData.map((row) => (
    //                     <TableRow key={row.label}>
    //                         <TableCell component="th" scope="row">
    //                             {row.label}
    //                         </TableCell>
    //                         <TableCell align="left">{row.value}</TableCell>
    //                     </TableRow>
    //                 ))}
    //             </TableBody>
    //         </Table>
    //     </TableContainer>
    // </div>
    // //////console.log(orderDetails);
    const showOrderDetails = [
        { label: 'Order ID:', value: orderDetails.orderId },
        { label: 'Product name:', value: orderDetails.itemName },
        { label: 'Order date:', value: orderDetails.date },

    ]
    const orderList =
        <div>
            <TableContainer>
                <Table sx={{ minWidth: 250 }} aria-label="Order table" >
                    <TableBody>
                        {showOrderDetails.map((row) => (
                            <TableRow key={row.label}>
                                <TableCell component="th" scope="row">
                                    {row.label}
                                </TableCell>
                                <TableCell align="left">{row.value}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    /////////////////////Message//////////////
    // const relevantMessage = !isBuyProdActed ? !isBuyProdActing ? `` : "Processing..." : invoiceList
    // DIALOG CONTROLS
    ///////////////////////////////////////////////////////////
    const [openDialog, setOpenDialog] = useState(false);

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        props.clearReceiptRequest();
        setOpenDialog(false);

    };
    ////////////////////////////////////////////////////////////

    return (
        <>
            {/* dialog widget */}
            <SimpleDialogBox
                open={openDialog}
                onClose={handleCloseDialog}
                title={
                    // relevantMessage === invoiceList ? (
                    //     <>
                    //         Invoice for {credits} credits
                    //     </>
                    // ) : (
                    <>
                        {itemName}
                    </>
                    // )

                }
                width={800}
                bodyWidget={
                    // relevantMessage === invoiceList ? (
                    //     <>
                    //         <p>{relevantMessage}</p>
                    //     </>
                    // ) : (
                    <>
                        {orderList}
                    </>
                    // )
                }
                showPositive
                positiveButtonWidget={
                    receiptGenerationLoader ?
                        <Button variant="contained" disabled sx={{
                            "&.Mui-disabled": {
                                backgroundColor: '#025196',
                                color: '#ffffff'
                            },
                            marginInline: '2px',
                            textTransform: 'initial',
                            color: '#025196',
                            backgroundColor: '#025196', ':hover': {
                                backgroundColor: '#025196'
                            }
                        }} >
                            <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} /><span style={{ paddingLeft: '15px' }}>Generating Receipt...</span>
                        </Button>
                        :
                        <>
                            <Button variant="contained"
                                disabled={orderDetails === undefined ? true : false}
                                sx={{
                                    marginInline: '2px',
                                    textTransform: 'initial',
                                    backgroundColor: '#025196', ':hover': {
                                        backgroundColor: '#025196'
                                    },
                                }} onClick={generateReceipt}>Generate Receipt
                            </Button>

                        </>
                }
            />
            <Card sx={cardStyle} elevation={0}>
                <ListItem
                    dense
                    disablePadding
                    secondaryAction={
                        <ListItemIcon title={""}>
                            <IconButton onClick={() => { handleClick(orderDetails) }}>
                                <ChevronRightIcon />
                            </IconButton>
                        </ListItemIcon>
                    }
                >
                    <ListItemButton
                        sx={{ paddingBlock: '8px', }}
                        onClick={() => { handleClick(orderDetails) }}
                    >
                        <ListItemText
                            primary={
                                <Typography variant="p" sx={{ fontWeight: 'bold' }}>
                                    {credits} {commonNames.credits}
                                </Typography>
                            }
                            secondary={
                                <>
                                    <span style={{ display: 'block' }}>{orderId}</span>
                                    <span style={{ display: 'block' }}>{date}</span>
                                </>
                            }
                        />



                    </ListItemButton>
                </ListItem>
            </Card>

        </>
    )
}
const mapStateToProps = (state) => {
    return {
        userData: state.firebase.profile,
        userId: state.firebase.auth.uid,
        companyData: state.firestore.data.Company,
        payment: state.payment,
        companyOrdersHistory: state.firestore.ordered.CompanyOrdersHistory,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        // requestOrderInvoice: (orderId, coid) => dispatch(requestOrderInvoice(orderId, coid)),
        requestReceiptGeneration: (orderId) => dispatch(requestReceiptGeneration(orderId)),
        openStripeBuyProductModal: () => dispatch(openStripeBuyProductModal()),
        clearReceiptRequest: () => dispatch(clearReceiptRequest()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(OrderDetailsCard);
import React, { useEffect, useState } from 'react';
import { Circle, LayerGroup, LayersControl, MapContainer, Polyline, TileLayer, Tooltip, useMap } from 'react-leaflet';
import { connect } from 'react-redux';
import { convertEncodedGeometry } from '../../../../../../store/actions/projectActions';
import { cloudFunction, storage } from '../../../../../../config/firebaseConfig';
import SimpleDialogBox from '../../../../../cidgets/SimpleDialogBox';
import { Tooltip as MuiTooltip } from '@mui/material'
import SmartUtils from '../../../../../../helpers/SmartUtils';
import InfoIcon from '@mui/icons-material/Info';
import { useTooltipContext } from '../../../../../../contexts/TooltipContext';
const IndividualSuperSectionMap = (props) => {
    const { conditionMethod, conditionsList, allNetworkDefects, drNetworkData, superSections, defectPinned, datastore, setDefectPinned, selectedSuperSection } = props;
    const { distressData } = datastore;
    const [superSectionCoordinatesList, setSuperSectionCoordinatesList] = useState([]);
    const [mapCenter, setMapCenter] = useState([51.505, -0.09]);
    const [mapKey, setMapKey] = useState(0);
    const [defectImageURLs, setDefectImageURLs] = useState({});
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const [color, setColor] = useState('#025196');
    const [selectedConditionValue, setSelectedConditionValue] = useState('N.A');
    const typeOfRoad = drNetworkData.typeOfRoad ?? null;
    const cciInverted = selectedSuperSection.cciInverted ?? false;

    const handleDialogOpen = (imageUrl) => {
        setSelectedImage(imageUrl);
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };
    useEffect(() => {
        const generateDefectImageURLs = async () => {
            const urls = {};
            await Promise.all(allNetworkDefects.map(async (defect) => {
                const { defectId, defectImagePath } = defect;
                try {
                    const storageRef = storage.ref(defectImagePath);
                    const downloadURL = await storageRef.getDownloadURL();
                    urls[defectId] = downloadURL;
                } catch (error) {
                    // console.error(`Error fetching download URL for defect with ID ${defectId}:`, error);
                    urls[defectId] = null;
                }
            }));
            setDefectImageURLs(urls);
        };
        generateDefectImageURLs();
    }, [allNetworkDefects]);
    useEffect(() => {
        if (conditionMethod === 0) {
            const rciValue = conditionsList.find(condition => condition.key === 0)?.value;
            if (rciValue !== undefined) {
                setSelectedConditionValue(rciValue);
                setColor(SmartUtils.rciColor(rciValue, typeOfRoad));
            }
        } else if (conditionMethod === 1) {
            const pciValue = conditionsList.find(condition => condition.key === 1)?.value;
            if (pciValue !== undefined) {
                setSelectedConditionValue(pciValue);
                setColor(SmartUtils.pciColor(pciValue));
            }
        } else if (conditionMethod === 2) {
            const cciValue = conditionsList.find(condition => condition.key === 2)?.value;
            if (cciValue !== undefined) {
                setSelectedConditionValue(cciValue);
                setColor(SmartUtils.cciColor(cciValue, cciInverted));
            }
        } else {
            setColor('#025196');
        }
    }, [conditionMethod, conditionsList]);
    useEffect(() => {
        if (defectPinned) {
            setMapCenter([defectPinned.lat, defectPinned.lng]);
        } else {
            if (superSections.length <= 0 && allNetworkDefects.length > 0) {
                setMapCenter([allNetworkDefects[0].lat, allNetworkDefects[0].lng]);
            }
        }
    }, [defectPinned, superSections, allNetworkDefects]);

    useEffect(() => {
        const fetchEncodedGeometries = async () => {
            if (superSections.length === 0) return;

            const encodedGeometries = await Promise.all(superSections.map(async (superSection) => {
                try {
                    const response = await cloudFunction.httpsCallable('routePlanner-geometryCoversion')({
                        encodedGeometry: superSection.encodedGeometry,
                    });
                    return response.data.coordinates; // Assuming the response contains the converted geometry
                } catch (error) {
                    // console.error('Error converting geometry:', error);
                    return null; // Or handle error as required
                }
            }));
            setSuperSectionCoordinatesList(encodedGeometries);

            if (encodedGeometries.length > 0 && encodedGeometries[0]?.coordinatesArray.length > 0) {
                const firstCoords = encodedGeometries[0].coordinatesArray[0];
                setMapCenter([firstCoords[0], firstCoords[1]]);
                setMapKey(mapKey + 1);
            }
        };

        fetchEncodedGeometries();
    }, [selectedSuperSection]);
    if (distressData?.length === 0) {
        return <>Loading...</>;
    }

    const { dataInfoV2: dataInfo } = distressData;
    const { defectName } = dataInfo;
    if (dataInfo === undefined || dataInfo === null) {
        return <></>;
    }

    const { type } = dataInfo;

    const Recenter = ({ lat, lng }) => {
        const map = useMap();
        useEffect(() => {
            map.setView([lat, lng]);
        }, [lat, lng]);
        return null;
    };

    const handlePinned = (data) => {
        setDefectPinned(data);
    };
    const LegendsCard = () => {
        const { showTooltip, handleTooltipToggle } = useTooltipContext();
        const tooltipContent = (
            <>
                {conditionMethod === 0 ? (
                    typeOfRoad === 'cycleTracks' ? (
                        <>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px', fontSize: '12px', fontWeight: 600 }}>
                                {selectedSuperSection.superSectionTitle ?? ''} - Adjusted RCI (except texture & rutting) : {selectedConditionValue !== 'N.A' ? Math.round(selectedConditionValue) : selectedConditionValue}
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <div
                                    style={{
                                        width: '12px',
                                        height: '12px',
                                        borderRadius: '50%',
                                        backgroundColor: 'green',
                                    }}
                                ></div>
                                <div style={{ fontSize: '12px', fontWeight: 500 }}>Excellent: 0-18 (Adjusted)</div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <div
                                    style={{
                                        width: '12px',
                                        height: '12px',
                                        borderRadius: '50%',
                                        backgroundColor: 'darkorange',
                                    }}
                                ></div>
                                <div style={{ fontSize: '12px', fontWeight: 500 }}>Good: 18-44 (Adjusted)</div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <div
                                    style={{
                                        width: '12px',
                                        height: '12px',
                                        borderRadius: '50%',
                                        backgroundColor: 'red',
                                    }}
                                ></div>
                                <div style={{ fontSize: '12px', fontWeight: 500 }}>Above poor: 44-above (Adjusted)</div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px', fontSize: '12px', fontWeight: 600 }}>
                                {selectedSuperSection.superSectionTitle ?? ''} - Adjusted RCI (except texture) : {selectedConditionValue !== 'N.A' ? Math.round(selectedConditionValue) : selectedConditionValue}
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <div
                                    style={{
                                        width: '12px',
                                        height: '12px',
                                        borderRadius: '50%',
                                        backgroundColor: 'green',
                                    }}
                                ></div>
                                <div style={{ fontSize: '12px', fontWeight: 500 }}>Excellent: 0-31 (Adjusted)</div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <div
                                    style={{
                                        width: '12px',
                                        height: '12px',
                                        borderRadius: '50%',
                                        backgroundColor: 'darkorange',
                                    }}
                                ></div>
                                <div style={{ fontSize: '12px', fontWeight: 500 }}>Good: 31-76 (Adjusted)</div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <div
                                    style={{
                                        width: '12px',
                                        height: '12px',
                                        borderRadius: '50%',
                                        backgroundColor: 'red',
                                    }}
                                ></div>
                                <div style={{ fontSize: '12px', fontWeight: 500 }}>Above poor: 76-above (Adjusted)</div>
                            </div>
                        </>
                    )
                ) : conditionMethod === 2 ? (
                    cciInverted ?
                        <>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px', fontSize: '12px', fontWeight: 600 }}>
                                {selectedSuperSection.superSectionTitle ?? ''} - Custom condition index  : {selectedConditionValue !== 'N.A' ? Math.round(selectedConditionValue) : selectedConditionValue}
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <div
                                    style={{
                                        width: '12px',
                                        height: '12px',
                                        borderRadius: '50%',
                                        backgroundColor: 'darkgreen',
                                    }}
                                ></div>
                                <div style={{ fontSize: '12px', fontWeight: 500 }}>Good: 0-20</div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <div
                                    style={{
                                        width: '12px',
                                        height: '12px',
                                        borderRadius: '50%',
                                        backgroundColor: '#57C018',
                                    }}
                                ></div>
                                <div style={{ fontSize: '12px', fontWeight: 500 }}>Satisfactory: 21-40</div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <div
                                    style={{
                                        width: '12px',
                                        height: '12px',
                                        borderRadius: '50%',
                                        backgroundColor: 'yellow',
                                    }}
                                ></div>
                                <div style={{ fontSize: '12px', fontWeight: 500 }}>Fair: 41-60</div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <div
                                    style={{
                                        width: '12px',
                                        height: '12px',
                                        borderRadius: '50%',
                                        backgroundColor: 'darkorange',
                                    }}
                                ></div>
                                <div style={{ fontSize: '12px', fontWeight: 500 }}>Poor : 61-80</div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <div
                                    style={{
                                        width: '12px',
                                        height: '12px',
                                        borderRadius: '50%',
                                        backgroundColor: 'red',
                                    }}
                                ></div>
                                <div style={{ fontSize: '12px', fontWeight: 500 }}>Serious : 81-100</div>
                            </div>
                        </>
                        :
                        <>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px', fontSize: '12px', fontWeight: 600 }}>
                                {selectedSuperSection.superSectionTitle ?? ''} - Custom condition index   : {selectedConditionValue !== 'N.A' ? Math.round(selectedConditionValue) : selectedConditionValue}
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <div
                                    style={{
                                        width: '12px',
                                        height: '12px',
                                        borderRadius: '50%',
                                        backgroundColor: 'red',
                                    }}
                                ></div>
                                <div style={{ fontSize: '12px', fontWeight: 500 }}>Serious: 0-20</div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <div
                                    style={{
                                        width: '12px',
                                        height: '12px',
                                        borderRadius: '50%',
                                        backgroundColor: 'darkorange',
                                    }}
                                ></div>
                                <div style={{ fontSize: '12px', fontWeight: 500 }}>Poor: 21-40</div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <div
                                    style={{
                                        width: '12px',
                                        height: '12px',
                                        borderRadius: '50%',
                                        backgroundColor: 'yellow',
                                    }}
                                ></div>
                                <div style={{ fontSize: '12px', fontWeight: 500 }}>Fair: 41-60</div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <div
                                    style={{
                                        width: '12px',
                                        height: '12px',
                                        borderRadius: '50%',
                                        backgroundColor: '#57C018',
                                    }}
                                ></div>
                                <div style={{ fontSize: '12px', fontWeight: 500 }}>Satisfactory: 61-80</div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <div
                                    style={{
                                        width: '12px',
                                        height: '12px',
                                        borderRadius: '50%',
                                        backgroundColor: 'darkgreen',
                                    }}
                                ></div>
                                <div style={{ fontSize: '12px', fontWeight: 500 }}>Good: 81-100</div>
                            </div>
                        </>
                ) :
                    conditionMethod === 1 ? (
                        <>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px', fontSize: '12px', fontWeight: 600 }}>
                                {selectedSuperSection.superSectionTitle ?? ''} - Pavement condition index   : {selectedConditionValue !== 'N.A' ? Math.round(selectedConditionValue) : selectedConditionValue}
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <div
                                    style={{
                                        width: '12px',
                                        height: '12px',
                                        borderRadius: '50%',
                                        backgroundColor: 'red',
                                    }}
                                ></div>
                                <div style={{ fontSize: '12px', fontWeight: 500 }}>Serious: 0-20</div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <div
                                    style={{
                                        width: '12px',
                                        height: '12px',
                                        borderRadius: '50%',
                                        backgroundColor: 'darkorange',
                                    }}
                                ></div>
                                <div style={{ fontSize: '12px', fontWeight: 500 }}>Poor: 21-40</div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <div
                                    style={{
                                        width: '12px',
                                        height: '12px',
                                        borderRadius: '50%',
                                        backgroundColor: 'yellow',
                                    }}
                                ></div>
                                <div style={{ fontSize: '12px', fontWeight: 500 }}>Fair: 41-60</div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <div
                                    style={{
                                        width: '12px',
                                        height: '12px',
                                        borderRadius: '50%',
                                        backgroundColor: '#57C018',
                                    }}
                                ></div>
                                <div style={{ fontSize: '12px', fontWeight: 500 }}>Satisfactory: 61-80</div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <div
                                    style={{
                                        width: '12px',
                                        height: '12px',
                                        borderRadius: '50%',
                                        backgroundColor: 'darkgreen',
                                    }}
                                ></div>
                                <div style={{ fontSize: '12px', fontWeight: 500 }}>Good: 81-100</div>
                            </div>
                        </>
                    )
                        :
                        (
                            <></>
                        )}
            </>
        );

        return (
            <div
                style={{
                    position: 'absolute',
                    bottom: '10px',
                    right: '10px',
                    zIndex: 1000,
                    backgroundColor: 'white',
                    padding: '10px',
                    borderRadius: '15px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '8px',
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                }}
            >
                <MuiTooltip
                    // open={showTooltip}
                    title={tooltipContent}
                    arrow
                >
                    <InfoIcon fontSize="medium" onClick={handleTooltipToggle} sx={{ cursor: 'pointer' }} />
                </MuiTooltip>
            </div>
        );
    };
    return (
        <div style={{ height: '100%', position: 'relative' }}>
            <MapContainer key={mapKey} attributionControl={false} center={mapCenter} zoom={19} scrollWheelZoom={true} maxZoom={19}>
                <TileLayer attribution='' maxZoom={19} url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <Recenter lat={mapCenter[0]} lng={mapCenter[1]} />
                {conditionMethod !== -1 ? <LegendsCard /> : <></>}
                <LayersControl position="topright">
                    {superSectionCoordinatesList.length > 0 && (
                        <LayersControl.Overlay name="Selected Supersection road" checked={true}>
                            <LayerGroup key={mapKey}>
                                {superSectionCoordinatesList.map((coords, index) => (
                                    <Polyline key={index} positions={coords.coordinatesArray} color={color} weight={3} />
                                ))}
                            </LayerGroup>
                        </LayersControl.Overlay>
                    )}
                    <LayersControl.Overlay name="Selected supersection distress" checked={true}>
                        <LayerGroup
                        // key={polylineKey}
                        >
                            {allNetworkDefects.map((e, i) => (
                                <Circle
                                    key={i}
                                    eventHandlers={{
                                        click: () => {
                                            handlePinned(e);
                                        },
                                    }}
                                    center={[e.lat, e.lng]}
                                    pathOptions={{
                                        fillColor: defectPinned?.defectId === e.defectId ? 'rgba(57, 1, 120,0.9)' : color,
                                        color: defectPinned?.defectId === e.defectId ? 'rgba(57, 1, 120,0.9)' : color,
                                        fillOpacity: 1,
                                        stroke: true,
                                        fill: true,
                                    }}
                                    radius={1}
                                >
                                    <Tooltip>
                                        {Number(e.defectName) === 1 ? (Number(e.type) === -1 || e.type?.length === 0 ? '' : type[e.type]?.name) : ''}
                                        {e.defectName === -1 ? null : defectName[e.defectName]['name']}
                                    </Tooltip>
                                    {defectPinned && defectPinned.defectId === e.defectId && defectImageURLs[e?.defectId] && (
                                        <div className="inset-image-container">
                                            <MuiTooltip
                                                title={"See full image"}
                                                arrow
                                            >
                                                <img
                                                    src={defectImageURLs[e.defectId]}
                                                    alt="Defect"
                                                    className="inset-image"
                                                    onClick={() => handleDialogOpen(defectImageURLs[e.defectId])}

                                                />
                                            </MuiTooltip>
                                        </div>
                                    )}
                                </Circle>
                            ))}
                        </LayerGroup>
                    </LayersControl.Overlay>
                </LayersControl>
            </MapContainer>

            <SimpleDialogBox
                open={dialogOpen} onClose={handleDialogClose}
                width={900}
                title="Defect image"
                bodyWidget={<>
                    <img src={selectedImage} alt="Defect Full" style={{ width: '100%', height: 'auto' }} />
                </>}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        datastore: state.datastore,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        convertEncodedGeometry: (encodedGeometry) => dispatch(convertEncodedGeometry(encodedGeometry)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(IndividualSuperSectionMap);

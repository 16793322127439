import React, { useEffect, useState, useMemo } from "react";
import { Card, CardContent, Chip, Grid, Tooltip, Typography, Button, TextField, Select, MenuItem, InputLabel, CircularProgress, } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { makeStyles } from "@mui/styles";
import SimpleDialogBox from "../../../../cidgets/SimpleDialogBox";
import { addFeature, editFeature, archiveFeature, unarchiveFeature } from "../../../../../store/actions/projectActions";
import { connect } from "react-redux";
import { get } from "lodash";
import DateParser from '../../../../../helpers/DateParser';
import { useAccessControlContext } from "../../../../../contexts/AccessControlProvider";

const useStyles = makeStyles((theme) => ({
  cardRow: {
    display: "flex",
    justifyContent: "start",
    width: "100%",
  },
  chip: {
    // borderRadius: theme.spacing(3),
    fontWeight: "bold",
    padding: theme.spacing(1),
  },
  good: {
    color: "#025196",
    border: "1px solid #025196",
    backgroundColor: "#fff",
  },
  bad: {
    color: "#ED1E24",
    border: "1px solid #ED1E24",
    backgroundColor: "#fff",
  },
  medium: {
    color: "#FF6B00",
    border: "1px solid #FF6B00",
    backgroundColor: "#fff",
  },
}));

const cardStyle = {
  "border": "0.5px solid grey",
  "borderRadius": "8px",
  "backgroundColor": '#fff',
  "marginBlock": '10px',
  "marginTop": '20px',
}

const MarkerDetails = (props) => {
  const { permissions } = useAccessControlContext();
  const { canAddTag360, canArchiveTag360, canEditTag360, canUnarchiveTag360 } = permissions;
  const { handleFrameClose, markerInfo, drProjectData, project, proid, categoryDetails, playerRef } = props;
  const { showAddFeatureArchiveLoader, showEditFeatureArchiveLoader, showArchiveFeatureArchiveLoader, isAddFeatureModalClose, isArchiveFeatureModalClose, isEditFeatureModalClose } = project;



  useEffect(() => {
    if (isAddFeatureModalClose) {
      handleCloseDialog();
    }
  }, [isAddFeatureModalClose]);

  useEffect(() => {
    if (isArchiveFeatureModalClose) {
      handleCloseArchiveDialog();
    }
  }, [isArchiveFeatureModalClose]);

  useEffect(() => {
    if (isEditFeatureModalClose) {
      handleCloseEditDialog();
    }
  }, [isEditFeatureModalClose]);

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isArchiveDialogOpen, setIsArchiveDialogOpen] = useState(false);
  const [isUnarchiveDialogOpen, setIsUnarchiveDialogOpen] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const [categoryInput, setCategoryInput] = useState({
    name: "",
    categoryId: ""
  });
  const [condition, setcondition] = useState("");
  const [comments, setcomments] = useState("");
  const [editingFeature, setEditingFeature] = useState(null);
  const [archivingFeature, setArchivingFeature] = useState(null);
  const [tiltAngle, setTiltAngle] = useState(0);
  const [panAngle, setPanAngle] = useState(0);
  const [depthAngle, setDepthAngle] = useState(0);
  const [zoomLevel, setZoomLevel] = useState(0);
  const classes = useStyles();
  const { diff, lat, lng, id, features, sectionId, serialNumber } = markerInfo
  const { categories } = drProjectData;

  const featureTags = useMemo(() => (features !== null && features !== undefined ? Object.values(features) : []), [features]);
  const featureArray = useMemo(() => featureTags.filter((eachFeature) => eachFeature.archive === '0'), [featureTags]);
  const archivedTags = useMemo(() => featureTags.filter((eachFeature) => eachFeature.archive === '1'), [featureTags]);
  const filteredCategoryDetails = useMemo(() => Object.values(categoryDetails ?? {}), [categoryDetails]);
  const filteredFeatureArray = useMemo(() => {
    const containsAllCategory = filteredCategoryDetails.some((category) => category.categoryId === "All");
    if (containsAllCategory) {
      return featureArray.sort((a, b) => parseInt(b.timeStamp) - parseInt(a.timeStamp));
    }
    return featureArray
      .filter((eachMarkerData) => {
        if (filteredCategoryDetails.length === 0) {
          return false;
        }
        const foundIndex = filteredCategoryDetails.findIndex(
          (eachCategory) => eachCategory.categoryId === eachMarkerData.featureCategory
        );
        return foundIndex !== -1;
      })
      .sort((a, b) => parseInt(b.timeStamp) - parseInt(a.timeStamp));
  }, [featureArray, filteredCategoryDetails]);
  const categoryNames = useMemo(() => {
    const categoriesArray = categories ? Object.values(categories).map(category => ({
      name: category.name,
      categoryId: category.categoryId
    })) : [];
    categoriesArray.sort((a, b) => a.name.localeCompare(b.name));
    return categoriesArray;
  }, [categories]);

  const addFeature = () => {
    if (categoryInput.categoryId.trim() === "") {
      alert("Please select a category.");
    }
    else {
      props.addFeature(proid, sectionId, id, categoryInput.categoryId, condition, comments, panAngle, tiltAngle, depthAngle, zoomLevel);
    }
  }
  const formatTime = (seconds) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);

    return `${hrs.toString().padStart(2, "0")}:${mins
      .toString()
      .padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const addNewFeature = () => {
    setOpenDialog(true);
    setcondition('');
    setcomments('');
    setCategoryInput({
      name: "",
      categoryId: ""
    });
  }
  const handleCloseEditDialog = () => {
    setIsEditDialogOpen(false);
  };
  const handleCloseArchiveDialog = () => {
    setIsArchiveDialogOpen(false);
  };
  const handleCloseUnarchiveDialog = () => {
    setIsUnarchiveDialogOpen(false);
  };
  const handleSaveEdit = () => {
    props.editFeature(proid, sectionId, id, editingFeature, panAngle, tiltAngle, depthAngle, zoomLevel);
  };
  const handleSaveArchive = () => {
    props.archiveFeature(proid, sectionId, id, archivingFeature);
  };
  const handleSaveUnarchive = (featureData) => {
    props.unarchiveFeature(proid, sectionId, id, featureData);
  };
  const handleEditClick = (featureData) => {
    setEditingFeature(featureData);
    setIsEditDialogOpen(true);
  };
  const handleArchiveClick = (featureData) => {
    setArchivingFeature(featureData.id);
    setIsArchiveDialogOpen(true);
  };
  const handleUnarchiveClick = () => {
    setIsUnarchiveDialogOpen(true);
  };
  const handleCategoryChange = (event) => {
    const categoryId = event.target.value;
    const selectedCat = categoryNames.find((cat) => cat.categoryId === categoryId);
    setCategoryInput(selectedCat);
  };
  const handleCameraChangePosition = (eachMarkerData) => {
    if (playerRef.current) {
      const player = playerRef.current;
      if (player.vr().camera) {
        player.vr().camera.position.set(eachMarkerData.pan, eachMarkerData.tilt, eachMarkerData.depth);
        player.vr().controls3d.update();

        player.vr().camera.fov = eachMarkerData.zoom;
        player.vr().camera.updateProjectionMatrix();
      }
    }
  };
  const handleCameraGetPosition = () => {
    if (playerRef.current) {
      const player = playerRef.current;
      if (player.vr().camera) {
        setTiltAngle(player.vr().camera.position.y);
        setPanAngle(player.vr().camera.position.x);
        setDepthAngle(player.vr().camera.position.z);
        setZoomLevel(player.vr().camera.fov);

      }
    }
  };

  return (
    <div style={{ height: "100%", }}>
      <Grid
        container
        spacing={0}
        sx={{ marginBottom: "20px", alignItems: "center", height: "10%", padding: "20px" }}
      >
        <Grid item xs={5} sx={{ backgroundColor: '' }}>
          <Typography sx={{ fontWeight: "bold" }} variant="body1">
            Tags in thumbnail {serialNumber}
          </Typography>
          <Typography sx={{ fontWeight: "" }} variant="body2">
            Showing {filteredFeatureArray.length} tags
          </Typography>
        </Grid>
        <Grid
          item
          xs={7}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "",
          }}
        >
          <Typography
            sx={{ fontWeight: "", marginRight: "16px" }}
            variant="body1"
          >
            {formatTime(diff / 1000)}
          </Typography>

          <Button
            disabled={!canAddTag360}
            variant="contained"
            size="small"
            sx={{
              marginInline: "10px",
              textTransform: "initial",
              backgroundColor: "#025196",
              ":hover": {
                backgroundColor: "#025196",
              },
            }}
            // onClick={addNewFeature}

            onClick={() => {
              addNewFeature();
              handleCameraGetPosition();
            }}
          >
            Add tag
          </Button>

          <Button
            disabled={!canUnarchiveTag360}
            variant="contained"
            size="small"
            sx={{
              marginInline: "10px",
              textTransform: "initial",
              backgroundColor: "#025196",
              ":hover": {
                backgroundColor: "#025196",
              },
            }}
            onClick={handleUnarchiveClick}
          >
            Tag bin
          </Button>
          <Tooltip title="Unpin this thumbnail">
            <ClearIcon
              sx={{
                color: "#1E1E1E",
                cursor: "pointer",
              }}
              onClick={() => handleFrameClose()}
            />
          </Tooltip>
        </Grid>
      </Grid>

      <div style={{ padding: "20px", height: "90%", overflow: "auto" }}>
        {
          filteredFeatureArray
            .map((eachMarkerData, index) => {
              const formattedDateTime = DateParser(eachMarkerData.timeStamp, 4);
              return (
                <Card
                  key={index}
                  sx={{ marginBottom: "20px", borderRadius: "10px", backgroundColor: '' }}
                  elevation={1}
                >
                  <CardContent>
                    <div className={classes.cardRow}>
                      <Typography variant="body2" sx={{ fontWeight: "bold" }}>{`${index + 1
                        }. ${categories ? eachMarkerData.featureCategory ? categories[eachMarkerData.featureCategory]['name'] : '' : ''}`}</Typography>
                    </div>
                    <div
                      className={classes.cardRow}
                      style={{ paddingLeft: "15px" }}
                    >
                      <Grid container alignItems="center">
                        <Typography variant="body2" sx={{ marginLeft: "0px" }}>
                          GPS: {lat},{lng}
                        </Typography>
                      </Grid>

                      {eachMarkerData.condition !== '' ? (
                        <Chip
                          size="small"
                          sx={{}}
                          variant="outlined"
                          className={`${classes.chip} ${eachMarkerData.condition === "Good"
                            ? classes.good
                            : eachMarkerData.condition === "Bad"
                              ? classes.bad
                              : classes.medium
                            }`}
                          label={eachMarkerData.condition}
                        />
                      )
                        : <></>
                      }

                    </div>


                    <div style={{ paddingLeft: "15px" }}>
                      <Grid container alignItems="center">
                        <Typography variant="body2" sx={{ marginLeft: "0px" }}>
                          Date & Time: {formattedDateTime.date}, {formattedDateTime.time}
                        </Typography>
                      </Grid>
                    </div>

                    {eachMarkerData.freeText !== '' ? (
                      <div style={{ paddingLeft: "15px" }}>
                        <Grid container alignItems="center">
                          <Typography variant="body2" sx={{ marginLeft: "0px" }}>
                            Comments: {eachMarkerData.freeText}
                          </Typography>
                        </Grid>
                      </div>
                    )
                      :
                      <></>
                    }

                  </CardContent>
                  <div style={{ marginLeft: "20px", marginBottom: "15px" }}>

                    <Button variant="contained"
                      size="small"
                      sx={{
                        marginInline: "10px",
                        textTransform: "initial",
                        backgroundColor: "#025196",
                        ":hover": {
                          backgroundColor: "#025196",
                        },
                      }}
                      onClick={() => handleCameraChangePosition(eachMarkerData)}>
                      Zoom in on tag
                    </Button>

                    <Button variant="contained"
                      disabled={!canEditTag360}
                      size="small"
                      sx={{
                        marginInline: "10px",
                        textTransform: "initial",
                        backgroundColor: "#025196",
                        ":hover": {
                          backgroundColor: "#025196",
                        },
                      }}
                      onClick={() => {
                        handleEditClick(eachMarkerData);
                        // handleCameraGetPosition();
                      }}
                    >

                      Edit tag
                    </Button>

                    <Button variant="contained"
                      disabled={!canArchiveTag360}
                      size="small"
                      sx={{
                        marginInline: "10px",
                        textTransform: "initial",
                        backgroundColor: "#025196",
                        ":hover": {
                          backgroundColor: "#025196",
                        },
                      }}
                      onClick={() => handleArchiveClick(eachMarkerData)}>
                      Move to bin
                    </Button>

                  </div>
                </Card>
              );
            })}
      </div>

      <SimpleDialogBox
        open={openDialog}
        onClose={handleCloseDialog}
        title="Add tag"
        cancelText="Cancel"
        bodyWidget={
          <>
            <InputLabel
              sx={{
                marginTop: "20px",
              }}
              id="customCategory-label"
            >
              Tag categories
            </InputLabel>

            <Select
              labelId="customCategory-label"
              id="customCategory"
              value={categoryInput.categoryId}
              // onChange={(e) => setCategoryInput(e.target.value)}
              onChange={
                handleCategoryChange
              }
              fullWidth
              sx={{
                "& .MuiSelect-select.MuiSelect-select": {
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                },
              }}
            >

              {categoryNames.map((categoryName, index) => (
                <MenuItem key={categoryName.categoryId} value={categoryName.categoryId}>
                  {categoryName.name}
                </MenuItem>
              ))}

            </Select>

            <InputLabel
              sx={{
                marginTop: "20px",
              }}
              id="condition"
            >
              Condition (optional)
            </InputLabel>
            <Select
              labelId="condition-label"
              id="condition"
              value={condition}
              onChange={(e) => setcondition(e.target.value)}
              fullWidth
            >
              <MenuItem value="Good">Good</MenuItem>
              <MenuItem value="Medium">Medium</MenuItem>
              <MenuItem value="Bad">Bad</MenuItem>
            </Select>

            <InputLabel
              sx={{
                marginTop: "20px",
              }}
              id="comments"
            >
              Comments (optional)
            </InputLabel>
            <TextField
              variant="outlined"
              fullWidth
              value={comments}
              onChange={(e) => setcomments(e.target.value)}
              margin="dense"
            />
          </>
        }
        width='600'
        showPositive
        positiveText='Add'
        positiveButtonWidget={
          showAddFeatureArchiveLoader ?
            <>
              <Button variant="contained" disabled sx={{
                "&.Mui-disabled": {
                  backgroundColor: '#025196',
                  color: '#ffffff'
                },
                marginInline: '2px',
                textTransform: 'initial',
                color: '#025196',
                backgroundColor: '#025196', ':hover': {
                  backgroundColor: '#025196'
                }
              }} >
                <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} /><span style={{ paddingLeft: '15px' }}>Adding...</span>
              </Button>
            </>
            :
            <>
              <Button variant="contained" sx={{
                marginInline: '2px',
                textTransform: 'initial',
                backgroundColor: '#025196', ':hover': {
                  backgroundColor: '#025196'
                },
              }}
                onClick={addFeature}
              > Add </Button>
            </>
        }
      />

      <SimpleDialogBox
        open={isEditDialogOpen}
        onClose={handleCloseEditDialog}
        title="Edit tag"
        cancelText="Cancel"
        bodyWidget={
          <>
            <InputLabel
              sx={{
                marginTop: "20px",
              }}
              id="customCategory-label"
            >
              Tag categories
            </InputLabel>

            <Select
              labelId="customCategory-label"
              id="customCategory"
              value={editingFeature ? editingFeature.featureCategory : ''}

              onChange={(e) => setEditingFeature({ ...editingFeature, featureCategory: e.target.value })}
              fullWidth
              sx={{
                "& .MuiSelect-select.MuiSelect-select": {
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                },
              }}
            >

              {categoryNames.map((categoryName, index) => (
                <MenuItem key={categoryName.categoryId} value={categoryName.categoryId}>
                  {categoryName.name}
                </MenuItem>
              ))}

            </Select>

            <InputLabel
              sx={{
                marginTop: "20px",
              }}
              id="condition"
            >
              Condition (optional)
            </InputLabel>
            <Select
              labelId="condition-label"
              id="condition"
              value={editingFeature ? editingFeature.condition : ''}
              onChange={(e) => setEditingFeature({ ...editingFeature, condition: e.target.value })}
              fullWidth
            >
              <MenuItem value="Good">Good</MenuItem>
              <MenuItem value="Medium">Medium</MenuItem>
              <MenuItem value="Bad">Bad</MenuItem>
            </Select>

            <InputLabel
              sx={{
                marginTop: "20px",
              }}
              id="comments"
            >
              Comments (optional)
            </InputLabel>
            <TextField
              variant="outlined"
              fullWidth
              value={editingFeature ? editingFeature.freeText : ''}
              onChange={(e) => setEditingFeature({ ...editingFeature, freeText: e.target.value })}
              margin="dense"
            />
          </>
        }
        width='600'
        showPositive
        positiveText='Edit'
        positiveButtonWidget={
          showEditFeatureArchiveLoader ?
            <>
              <Button variant="contained" disabled sx={{
                "&.Mui-disabled": {
                  backgroundColor: '#025196',
                  color: '#ffffff'
                },
                marginInline: '2px',
                textTransform: 'initial',
                color: '#025196',
                backgroundColor: '#025196', ':hover': {
                  backgroundColor: '#025196'
                }
              }} >
                <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} /><span style={{ paddingLeft: '15px' }}>Editing...</span>
              </Button>
            </>
            :
            <>
              <Button variant="contained" sx={{
                marginInline: '2px',
                textTransform: 'initial',
                backgroundColor: '#025196', ':hover': {
                  backgroundColor: '#025196'
                },
              }}
                onClick={handleSaveEdit}
              > Edit </Button>
            </>
        }
      />

      <SimpleDialogBox
        open={isArchiveDialogOpen}
        onClose={handleCloseArchiveDialog}
        title={'Confirm delete'}
        cancelText="Cancel"
        bodyWidget={
          <p>Are you sure you want to move this tag to the bin?</p>
        }
        width='600'
        showPositive
        positiveText='Archive'
        positiveButtonWidget={
          showArchiveFeatureArchiveLoader ?
            <>
              <Button variant="contained" disabled sx={{
                "&.Mui-disabled": {
                  backgroundColor: '#025196',
                  color: '#ffffff'
                },
                marginInline: '2px',
                textTransform: 'initial',
                color: '#025196',
                backgroundColor: '#025196', ':hover': {
                  backgroundColor: '#025196'
                }
              }} >
                <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} /><span style={{ paddingLeft: '15px' }}>Archiving...</span>
              </Button>
            </>
            :
            <>
              <Button variant="contained" sx={{
                marginInline: '2px',
                textTransform: 'initial',
                backgroundColor: '#025196', ':hover': {
                  backgroundColor: '#025196'
                },
              }}
                onClick={handleSaveArchive}
              > Accept </Button>
            </>
        }
      />

      <SimpleDialogBox
        open={isUnarchiveDialogOpen}
        onClose={handleCloseUnarchiveDialog}
        title="Archived tags"
        cancelText="Close"
        desc={archivedTags.length > 0 ? "Unarchive a tag by clicking on it" : "There is nothing here!"}
        bodyWidget={
          archivedTags.map((eachMarkerData, index) => {
            const formattedDateTime = DateParser(eachMarkerData.timeStamp, 4);

            // //////console.log(eachMarkerData, 'unarchiving data')
            return (
              <div style={{ cursor: "pointer" }} onClick={() => { handleSaveUnarchive(eachMarkerData.id) }}>
                <Card sx={cardStyle} elevation={0}>
                  <CardContent>
                    <div className={classes.cardRow}>
                      <Typography variant="body2" sx={{ fontWeight: "bold" }}>{`${index + 1
                        }. ${categories ? eachMarkerData.featureCategory ? categories[eachMarkerData.featureCategory]['name'] : '' : ''}`}</Typography>
                    </div>
                    <div
                      className={classes.cardRow}
                      style={{ paddingLeft: "15px" }}
                    >
                      <Grid container alignItems="center">
                        <Typography variant="body2" sx={{ marginLeft: "0px" }}>
                          GPS: {lat},{lng}
                        </Typography>
                      </Grid>

                      {eachMarkerData.condition !== '' ? (
                        <Chip
                          size="small"
                          sx={{}}
                          variant="outlined"
                          className={`${classes.chip} ${eachMarkerData.condition === "Good"
                            ? classes.good
                            : eachMarkerData.condition === "Bad"
                              ? classes.bad
                              : classes.medium
                            }`}
                          label={eachMarkerData.condition}
                        />
                      )
                        : <></>
                      }

                    </div>


                    <div style={{ paddingLeft: "15px" }}>
                      <Grid container alignItems="center">
                        <Typography variant="body2" sx={{ marginLeft: "0px" }}>
                          Date & Time: {formattedDateTime.date}, {formattedDateTime.time}
                        </Typography>
                      </Grid>
                    </div>

                    {eachMarkerData.freeText !== '' ? (
                      <div style={{ paddingLeft: "15px" }}>
                        <Grid container alignItems="center">
                          <Typography variant="body2" sx={{ marginLeft: "0px" }}>
                            Comments: {eachMarkerData.freeText}
                          </Typography>
                        </Grid>
                      </div>
                    )
                      :
                      <></>
                    }

                  </CardContent>
                </Card>
              </div>
            );
          })
        }
        width='600'
      />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { proid } = ownProps;
  return {
    datastore: state.datastore,
    project: state.project,
    drProjectData: get(state.firestore.data, `DrProject.${proid}`),
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addFeature: (proid, sectionId, id, categoryInput, condition, comments, panAngle, tiltAngle, depthAngle, zoomLevel) => dispatch(addFeature(proid, sectionId, id, categoryInput, condition, comments, panAngle, tiltAngle, depthAngle, zoomLevel)),
    editFeature: (proid, sectionId, id, editingFeature, panAngle, tiltAngle, depthAngle, zoomLevel) => dispatch(editFeature(proid, sectionId, id, editingFeature, panAngle, tiltAngle, depthAngle, zoomLevel)),
    archiveFeature: (proid, sectionId, id, archivingFeature) => dispatch(archiveFeature(proid, sectionId, id, archivingFeature)),
    unarchiveFeature: (proid, sectionId, id, featureData) => dispatch(unarchiveFeature(proid, sectionId, id, featureData))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MarkerDetails);
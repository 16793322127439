import { storage, cloudFunction } from "../../config/firebaseConfig";

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

//////////////////////////////////////////////////////////////////////////////////////////////
/// README: SECTION 1 => HANDLE CURRENT COMPANY DATA
///
export const setCurrentCompanyId = (coid) => {
  return {
    type: "SET_CURRENT_COMPANY_ID",
    coid,
  };
};

//////////////////////////////////////////////////////////////////////////////////////////////
/// README: SECTION 2 => NEW COMPANY CREATION AND UPDATES
///

export const createCompanyViaApi = (
  userModelJson,
  companyModelJson,
  createdVia,
  selectedDp
) => {
  return async (dispatch, getState, { getFirestore }) => {
    /// dispatch to indicate company creation started
    dispatch({
      type: "ADD_NEW_COMPANY",
      capiMessage: "request created and is processing...",
      showCapiLoader: true,
      capid: 0,
    });
    // async call to upload new shape
    const firestore = getFirestore();

    cloudFunction
      .httpsCallable("company-createNewCompany")({
        userModelJson: userModelJson,
        companyModelJson: companyModelJson,
        createdVia: createdVia,
      })
      .then(async (res) => {


        if (!res.data.isDoneAndSent) {
          // //////console.log(res.data.responseMsg);
        } else {
          // upload dp
          var dpUrl = "";
          if (selectedDp !== null) {
            var result = await addCompanyDp(
              res.data.responseMsg.coid,
              selectedDp
            );
            if (result != null) {
              // //////console.log(`Available at: ${result.substring(0, 5)}`);
              dpUrl = result;
            }
          }
          // //////console.log(dpUrl)
          /// dispatch to indicate successful file uploaded
          dispatch({ type: "COMPANY_DP_ADDED" });
        }

        /// dispatch to indicate successful request
        dispatch({
          type: "NEW_COMPANY_ADDED",
          coid: res.data.responseMsg.coid,
          // type: "CAPI_CALL_COMPLETED",
          capiMessage: res.data.responseMsg,
          showCapiLoader: false,
          capid: -1,
        });
      })
      .catch((error) => {
        // //////console.log(error.message);
        /// dispatch to indicate unsuccessful request
        dispatch({
          type: "CAPI_CALL_ERROR",
          capiMessage: error.message,
          showCapiLoader: false,
          capid: -1,
        });
      });
  };
};

export const createCompany = (userData, companyData, selectedDp) => {
  // //////console.log(companyData)
  return async (dispatch, getState, { getFirestore }) => {
    /// dispatch to indicate company creation started
    dispatch({ type: "ADD_NEW_COMPANY" });

    // async call to upload new shape
    const firestore = getFirestore();
    // // generate id
    const newCoid = "webcom_" + userData.uid;
    const employeeid = "emp_" + firestore.collection("Companies").doc().id;
    const ueid = userData.uid + employeeid;
    const visibility = false;
    // upload dp
    var dpUrl = "";
    if (selectedDp !== null) {
      var result = await addCompanyDp(newCoid, selectedDp);
      if (result != null) {
        // //////console.log(`Available at: ${result.substring(0, 5)}`);
        dpUrl = result;
      }
    }
    // await sleep(3000);

    /// dispatch to indicate successful file uploaded
    dispatch({ type: "COMPANY_DP_ADDED" });

    // create company document
    await firestore
      .collection("Companies")
      .doc(newCoid)
      .set({
        showFreshIntroMob: true,
        showFreshIntroWeb: true,
        isFreeTrialAvailed: false,
        companyName: `${companyData.companyName}` ?? "",
        companyNameLC: `${companyData.companyName}`.toLowerCase() ?? "",
        creatorUid: `${userData.uid}` ?? "",
        creatorEmployeeid: `${employeeid}` ?? "",
        coid: `${newCoid}`,
        isStripeSetupComplete: false,
        stripeCusId: "",
        accessList: firestore.FieldValue.arrayUnion(`${ueid}`),
        accessMap: {
          [ueid]: {
            access: 0,
            name: `${userData.name}` ?? "",
            uid: `${userData.uid}` ?? "",
            employeeid: `${employeeid}` ?? "",
          },
        },
        allMembers: {
          [`${userData.uid}`]: employeeid,
        },
        visibility: visibility,
        profilePic: `${dpUrl}` ?? "",
        email: `${companyData.email}` ?? "",
        address: `${companyData.address}` ?? "",
        phone: `${companyData.phone}` ?? "",
        credit: "",
        discount: "0",
        dateCreated: firestore.FieldValue.serverTimestamp(),
        totalActiveCompanyMembers: 1,
      });

    // add project to company briefings
    await firestore
      .collection(`Companies/${newCoid}/Briefings`)
      .doc("projects")
      .set({
        all: {},
      });

    // add company to global collection
    if (visibility) {
      await firestore
        .collection("Global")
        .doc("companies")
        .update({
          [`all.${newCoid}`]: {
            coid: `${newCoid}`,
            companyName: `${companyData.companyName}` ?? "",
            companyNameLC: `${companyData.companyName}`.toLowerCase() ?? "",
            creatorUid: `${userData.uid}` ?? "",
          },
        });
    }

    // add coid and employeeid to user document marking user as creator
    await firestore
      .collection("Users")
      .doc(userData.uid)
      .update({
        coid: `${newCoid}`,
        employeeid: `${employeeid}`,
      });

    // await sleep(3000);

    /// finally dispatch success action
    return dispatch({
      type: "NEW_COMPANY_ADDED",
      coid: newCoid,
    });
  };
};

/////////////////////{Update Company}////////////////////////////////////////////////////////

export const openCompanyUpdateModal = () => {
  return {
    type: "OPEN_COMPANY_UPDATE_MODAL",
  };
};

export const updateCompany = (newCoid, selectedDp, phone, address) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    /// dispatch to indicate user update started
    dispatch({ type: "UPDATE_COMPANY" });

    // async call to update the user to firebase
    const firestore = getFirestore();

    // upload dp
    var dpUrl = "";
    if (selectedDp !== null) {
      var result = await addCompanyDp(newCoid, selectedDp);
      if (result != null) {
        // //////console.log(`Available at: ${result.substring(0, 5)}`);
        dpUrl = result;

        await firestore
          .collection("Companies")
          .doc(newCoid)
          .update({
            profilePic: `${dpUrl}`,
          });
      }
    }

    /// dispatch to indicate successful file uploaded
    dispatch({ type: "COMPANY_DP_UPDATED" });

    await firestore
      .collection("Companies")
      .doc(newCoid)
      .update({
        phone: `${phone}` ?? "",
        address: `${address}` ?? "",
      });

    /// finally dispatch success action
    return dispatch({
      type: "COMPANY_UPDATED",
    });
  };
};

//////////////////////////////////////////////////////////////////////////////////////////////
/// README: SECTION 3 => ADD FILES UTILS
///
async function addCompanyDp(newCoid, selectedFile) {
  // //////console.log(`Uploading: dp`);
  var metadata = {
    contentType: "image/jpg",
  };

  var storageReference = storage.ref(`Companies/${newCoid}/cdp/cdp.jpg`);
  var uploadTask = await storageReference.put(selectedFile, metadata);
  return storageReference
    .getDownloadURL()
    .then((downloadURL) => {
      return downloadURL;
    })
    .catch((err) => {
      console.error(err);
      return null;
    });
}

//////////////////////////////////////////////////////////////////////////////////////////////
/// README: SECTION 4 => API CALLS FOR COMPANY MANAGEMENT
///

// 0
export const makeNewCreator = (employeeMapJson, companyModelJson) => {
  return async (dispatch, getState) => {
    /// dispatch to indicate initiation of request
    dispatch({
      type: "CAPI_CALL_INIT",
      capiMessage: "request created and is processing...",
      showCapiLoader: true,
      capid: 0,
    });

    cloudFunction
      .httpsCallable("company-transferCreatorRole")({
        employeeMapJson,
        companyModelJson,
      })
      .then((res) => {
        /// dispatch to indicate successful request
        dispatch({
          type: "CAPI_CALL_COMPLETED",
          capiMessage: res.data.responseMsg,
          showCapiLoader: false,
          capid: -1,
        });
      })
      .catch((error) => {
        /// dispatch to indicate unsuccessful request
        dispatch({
          type: "CAPI_CALL_ERROR",
          capiMessage: error,
          showCapiLoader: false,
          capid: -1,
        });
      });
  };
};

// 1
export const grantMeProjectAccess = (
  creatorUserModelJson,
  companyModelJson,
  projectModelJson
) => {
  return async (dispatch, getState) => {
    /// dispatch to indicate initiation of request
    dispatch({
      type: "CAPI_CALL_INIT",
      capiMessage: "request created and is processing...",
      showCapiLoader: true,
      capid: 1,
    });

    cloudFunction
      .httpsCallable("project-grantCompanyCreatorAdminAccessToProject ")({
        creatorUserModelJson,
        companyModelJson,
        projectModelJson,
      })
      .then((res) => {
        /// dispatch to indicate successful request
        dispatch({
          type: "CAPI_CALL_COMPLETED",
          capiMessage: res.data.responseMsg,
          showCapiLoader: false,
          capid: -1,
        });
      })
      .catch((error) => {
        /// dispatch to indicate unsuccessful request
        dispatch({
          type: "CAPI_CALL_ERROR",
          capiMessage: error,
          showCapiLoader: false,
          capid: -1,
        });
      });
  };
};

// 2
export const updateMemberRoleInCompany = (
  changerEmployeeMapJson,
  toBeChangedEmployeeMapJson,
  companyModelJson,
  newAccessLevel
) => {
  return async (dispatch, getState) => {
    /// dispatch to indicate initiation of request
    dispatch({
      type: "CAPI_CALL_INIT",
      capiMessage: "request created and is processing...",
      showCapiLoader: true,
      capid: 2,
    });

    cloudFunction
      .httpsCallable("company-updateMemberRoleInCompany")({
        changerEmployeeMapJson,
        toBeChangedEmployeeMapJson,
        companyModelJson,
        newAccessLevel,
      })
      .then((res) => {
        /// dispatch to indicate successful request
        dispatch({
          type: "CAPI_CALL_COMPLETED",
          capiMessage: res.data.responseMsg,
          showCapiLoader: false,
          capid: -1,
        });
      })
      .catch((error) => {
        /// dispatch to indicate unsuccessful request
        dispatch({
          type: "CAPI_CALL_ERROR",
          capiMessage: error,
          showCapiLoader: false,
          capid: -1,
        });
      });
  };
};

// 3
export const forceRemoveMemberFromCompany = (
  changerEmployeeMapJson,
  toBeChangedEmployeeMapJson,
  companyModelJson
) => {
  return async (dispatch, getState) => {
    /// dispatch to indicate initiation of request
    dispatch({
      type: "CAPI_CALL_INIT",
      capiMessage: "request created and is processing...",
      showCapiLoader: true,
      capid: 3,
    });

    cloudFunction
      .httpsCallable("company-getRemovedFromCompanyByAdmin")({
        changerEmployeeMapJson,
        toBeChangedEmployeeMapJson,
        companyModelJson,
      })
      .then((res) => {
        /// dispatch to indicate successful request
        dispatch({
          type: "CAPI_CALL_COMPLETED",
          capiMessage: res.data.responseMsg,
          showCapiLoader: false,
          capid: -1,
        });
      })
      .catch((error) => {
        /// dispatch to indicate unsuccessful request
        dispatch({
          type: "CAPI_CALL_ERROR",
          capiMessage: error,
          showCapiLoader: false,
          capid: -1,
        });
      });
  };
};

// 4
export const leaveFromCompany = (requestMakerJson, companyModelJson) => {
  return async (dispatch, getState) => {
    /// dispatch to indicate initiation of request
    dispatch({
      type: "CAPI_CALL_INIT",
      capiMessage: "request created and is processing...",
      showCapiLoader: true,
      capid: 4,
    });

    cloudFunction
      .httpsCallable("company-getRemovedFromCompany")({
        requestMakerJson,
        companyModelJson,
      })
      .then((res) => {
        /// dispatch to indicate successful request
        dispatch({
          type: "CAPI_CALL_COMPLETED",
          capiMessage: res.data.responseMsg,
          showCapiLoader: false,
          capid: -1,
        });
      })
      .catch((error) => {
        /// dispatch to indicate unsuccessful request
        dispatch({
          type: "CAPI_CALL_ERROR",
          capiMessage: error,
          showCapiLoader: false,
          capid: -1,
        });
      });
  };
};

// 5
export const companyVisibility = (
  userModelJson,
  companyModelJson,
  newVisibility
) => {
  return async (dispatch, getState) => {
    /// dispatch to indicate initiation of request
    dispatch({
      type: "CAPI_CALL_INIT",
      capiMessage: "request created and is processing...",
      showCapiLoader: true,
      capid: 5,
    });
    cloudFunction
      .httpsCallable("company-setCompanyVisibility")({
        userModelJson,
        companyModelJson,
        newVisibility,
      })
      .then((res) => {
        /// dispatch to indicate successful request
        dispatch({
          type: "CAPI_CALL_COMPLETED",
          capiMessage: res.data.responseMsg,
          showCapiLoader: false,
          capid: -1,
        });
      })
      .catch((error) => {
        /// dispatch to indicate unsuccessful request
        dispatch({
          type: "CAPI_CALL_ERROR",
          capiMessage: error,
          showCapiLoader: false,
          capid: -1,
        });
      });
  };
};

// 6
export const includeIntoCompanyAccept = (
  requestMakerJson,
  companyModelJson,
  selectedLevel,
  isReassignId
) => {
  return async (dispatch, getState) => {
    /// dispatch to indicate initiation of request
    dispatch({
      type: "CAPI_CALL_INIT",
      capiMessage: "request created and is processing...",
      showCapiLoader: true,
      capid: 6,
    });
    cloudFunction
      .httpsCallable("company-includeIntoCompanyAccept")({
        requestMakerJson,
        companyModelJson,
        selectedLevel,
        isReassignId,
      })
      .then((res) => {
        /// dispatch to indicate successful request
        dispatch({
          type: "CAPI_CALL_COMPLETED",
          capiMessage: res.data.responseMsg,
          showCapiLoader: false,
          capid: -1,
        });
      })
      .catch((error) => {
        /// dispatch to indicate unsuccessful request
        dispatch({
          type: "CAPI_CALL_ERROR",
          capiMessage: error,
          showCapiLoader: false,
          capid: -1,
        });
      });
  };
};

///
export const openCompanyDashboardModal = () => {
  return {
    type: "OPEN_COMPANY_DASHBOARD_MODAL",
  };
};
export const closeCompanyDashboardModals = () => {
  return {
    type: "CLOSE_COMPANY_DASHBOARD_MODAL",
  };
};

export const openCompanyModal = () => {
  return {
    type: "OPEN_COMPANY_MODAL",
  };
};

export const updateCompanyDetails = (coid, image, companyName, fullName, uid) => {
  //console.log(image, companyName, fullName, "canada")
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({
      type: 'START_UPDATE_DETAILS'
    })

    let imagePath, storageReference;

    if (image) {
      const fileExtension = image.name.split('.').pop();
      imagePath = `Companies/${coid}/logo.${fileExtension}`;
      storageReference = storage.ref(imagePath);

      storageReference.put(image)
        .then(async (snapshot) => {
          await fireStore.collection('Companies').doc(coid).update({
            imagePath: `${imagePath}` ?? "",
            imageName: `${image.name}`
          });
          // //console.log('Uploaded a file!', snapshot);
        })
        .catch((error) => {
          // console.error('Error uploading file:', error);
        });
    }

    const fireStore = getFirestore();

    await fireStore.collection('Companies').doc(coid).update({
      companyName: `${companyName}` ?? "",
    });

    await fireStore.collection('Users').doc(uid).update({
      organizationName: `${companyName}` ?? "",
    });

    // if (image) {
    //   await fireStore.collection('Companies').doc(coid).update({
    //     imagePath: `${imagePath}` ?? "",
    //     imageName: `${image.name}`
    //   });
    // }

    await fireStore.collection("Users").doc(uid).update({
      name: fullName,
      nameInitial: fullName[0]
    });

    return dispatch({ type: 'DETAILS_UPDATED' });
  };
};
import { Button, Card, CardContent, Typography, Slider, CircularProgress, Grid, Table, TableBody, TableRow, TableCell, TableContainer } from "@mui/material";
import SimpleDialogBox from "../../../../../cidgets/SimpleDialogBox";
import React, { useState, useEffect } from "react";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { customerBuyConsumable, openStripeBuyProductModal, confirmConsumablePurchase, clearProductRequest } from "../../../../../../store/actions/paymentActions"
import { connect } from 'react-redux';
import CustomProgress from "../../../../../cidgets/CustomProgress";
import partypopper from "../../../../../../assets/partypopper.png";
import * as ROUTES from "../../../../../../constants/routes";
import {
    ElementsConsumer,
} from "@stripe/react-stripe-js";
import commonNames from '../../../../../../constants/commonNames.json'
import { Link } from "react-router-dom";
import SmartUtils from "../../../../../../helpers/SmartUtils";
import RegularSlider from "../../../../../cidgets/RegularSlider";
import InvoiceComponent from "../../../../../cidgets/InvoiceComponent";
import SimpleSlider from '../../../../../cidgets/SimpleSlider';

const CheckOutForm = (props) => {

    const { processStatus, setProcessStatus, setOrderId } = props;
    const { stripePaymentIntent } = props.payment;
    const { coid, stripeDefaultPmid } = props.companyData;

    useEffect(() => {
        if (processStatus.paymentIntent) {
            setOrderId(stripePaymentIntent?.orderId);
            props.confirmConsumablePurchase(stripePaymentIntent?.orderId);
        }
    }, [processStatus])

    const handleSubmit = async (event) => {
        // //////console.log("CARD ATTACH SUBMIT");

        // Block native form submission.
        event.preventDefault();
        const { stripe, elements } = props;
        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }
        // //////console.log("hello");
        setProcessStatus({
            isProcessing: true,
        })
        const { error, paymentIntent } = await stripe.confirmCardPayment(`${stripePaymentIntent.paymentIntentCS}`, {
            payment_method: stripeDefaultPmid,
        });
        //    
        // //////console.log("payment Intent" + paymentIntent);
        // //////console.log(paymentIntent, "errorcheck")
        if (error) {
            setProcessStatus({
                isProcessing: false,
                isProcessed: false,
                errorMessage: error ? "Authorization has failed. Please try again." : "",
                error: error,
                paymentIntent: paymentIntent,
                message: ''
            })
        }
        else {
            setProcessStatus({
                isProcessing: true,
                isProcessed: true,
                errorMessage: "",
                error: "",
                paymentIntent: paymentIntent,
                message: 'Please wait confirming your payment...'
            })
        }

    }
    const { isProcessing, isProcessed, errorMessage } = processStatus;
    const { stripe, payment } = props;
    const { isBuyProdActing, isBuyProdActed, buyProdActMessage } = payment;
    const relevantComponent = !isProcessed ? !isProcessing ? <Button type="submit" disabled={!stripe}
        variant="contained" sx={{
            "&.Mui-disabled": {
                backgroundColor: '#025196',
                color: '#ffffff'
            },
            marginInline: '2px',
            textTransform: 'initial',

            backgroundColor: '#025196', ':hover': {
                backgroundColor: '#025196'
            }
        }}
    >
        Authorize payment</Button> : <Button variant="contained" disabled sx={{
            "&.Mui-disabled": {
                backgroundColor: '#025196',
                color: '#ffffff'
            },
            marginInline: '2px',
            textTransform: 'initial',
            backgroundColor: '#025196', ':hover': {
                backgroundColor: '#025196'
            }
        }}
        >
        <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} /><span style={{ paddingLeft: '15px' }}> Authorizing...</span>
    </Button> : <React.Fragment />
    return (
        <React.Fragment>
            <form onSubmit={handleSubmit}>
                {relevantComponent}
            </form>
        </React.Fragment>

    )
}


const PlanCustomTokens = (props) => {
    // //////console.log("hello plan");
    const { charges, isProjectCreationScreen, approxCreditsRequired, proid, companyCredits } = props;
    // //////console.log(isProjectCreationScreen, approxCreditsRequired, proid, companyCredits, "Plan");
    const { productDetails } = props
    // //////console.log(isProjectCreationScreen, approxCreditsRequired, companyCredits, "database")
    const totalCredits = isProjectCreationScreen ? Number(approxCreditsRequired) === undefined || Number(approxCreditsRequired) === 0 ? -1 : companyCredits === undefined || companyCredits === 0 ? Number(approxCreditsRequired) : Number(approxCreditsRequired) - Number(companyCredits) : '';
    const cardStyle = {
        "border": "0.5px solid grey",
        "borderRadius": "8px",
        "backgroundColor": '#fafafa',
        "marginBlock": '10px',

    }
    useEffect(() => {
        props.openStripeBuyProductModal();
    }, []);

    const [credits, setCredits] = useState(10);

    const handleSliderChange = (newValue) => {
        setCredits(newValue);
    };


    const [isBuying, setIsBuying] = useState(false);
    const handleClick = () => {
        handleOpenDialog()

    }
    const buyTokens = () => {
        setIsBuying(true);
    }

    const { minPrice, maxPrice } = productDetails;

    const { pricePerToken, currency } = charges
    const pricePerCredit = pricePerToken[currency]
    const priceInCurrency = ((Number(isProjectCreationScreen ? totalCredits : credits) * pricePerCredit));
    // //////console.log(priceInCurrency, "priceInCurrency", pricePerCredit)
    const price = Number((Number(priceInCurrency) * 100).toFixed(2)); // in lower denomination like cents/pence

    const minCredits = minPrice / pricePerCredit
    const maxCredits = maxPrice / pricePerCredit

    const [orderId, setOrderId] = useState("");
    const [processStatus, setProcessStatus] = useState({
        isProcessing: false,
        isProcessed: false,
        errorMessage: "",
        error: null,
        paymentIntent: null,
        message: ''
    })
    const { isProcessing, isProcessed, errorMessage } = processStatus;
    const handleProdBuySubmit = (event) => {
        // //////console.log("PROD BUY SUBMIT")
        props.clearProductRequest();
        event.preventDefault();
        const { productId } = productDetails
        // //////console.log(productId, "productId");
        if (isProjectCreationScreen) {
            props.customerBuyConsumable(productId, totalCredits, proid)
        }
        else {
            props.customerBuyConsumable(productId, credits, undefined)
        }

    }
    const { confirmConsumablePurchase, companyData, userData, payment } = props;
    const { isBuyProdActing, isBuyProdActed, buyProdActMessage, stripePaymentIntent, stripePaymentIsDoneAndSent, stripePaymentMessage } = payment;
    // //////console.log(productDetails, "productDetails");
    const relevantMessage = isBuyProdActed ? `${buyProdActMessage}` : isBuyProdActing ? "Processing..." : buyProdActMessage
    const relevantButton = !isBuyProdActed || stripePaymentIsDoneAndSent === false ? !isBuyProdActing || stripePaymentIsDoneAndSent === false ? <Button onClick={handleProdBuySubmit} disabled={isBuyProdActing}
        variant="contained" sx={{
            "&.Mui-disabled": {
                backgroundColor: '#025196',
                color: '#ffffff'
            },
            whiteSpace: 'nowrap',
            marginInline: '2px',
            textTransform: 'initial',
            backgroundColor: '#025196',
            ':hover': {
                backgroundColor: '#025196'
            }
        }}>Confirm this purchase</Button> : <Button variant="contained" disabled sx={{
            "&.Mui-disabled": {
                backgroundColor: '#025196',
                color: '#ffffff'
            },
            marginInline: '2px',
            textTransform: 'initial',
            backgroundColor: '#025196', ':hover': {
                backgroundColor: '#025196'
            }
        }}
        >
        <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} /><span style={{ paddingLeft: '15px' }}>
            Processing...
        </span>
    </Button> : <React.Fragment />
    // //////console.log("stripePaymentIntent");
    let relevantComponentBody = <React.Fragment />;
    if (stripePaymentIntent === "") {
        relevantComponentBody = relevantButton;
    } else if (stripePaymentIntent === "-1") {
        // relevantComponentBody = <p>{buyProdActMessage}</p>;
        relevantComponentBody = relevantButton;
    } else {
        relevantComponentBody = <ElementsConsumer>
            {({ elements, stripe }) => (
                <CheckOutForm elements={elements} stripe={stripe} userData={userData} companyData={companyData} payment={payment} confirmConsumablePurchase={confirmConsumablePurchase}
                    processStatus={processStatus} setProcessStatus={setProcessStatus}
                    orderId={orderId} setOrderId={setOrderId}
                />
            )}
        </ElementsConsumer>;
    }
    // DIALOG CONTROLS
    ///////////////////////////////////////////////////////////
    const [openDialog, setOpenDialog] = useState(false);

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };
    const handleCloseDialog = () => {
        // setIsBuying(false);
        props.clearProductRequest();
        setProcessStatus({
            isProcessing: false,
            isProcessed: false,
            errorMessage: "",
            error: null,
            paymentIntent: null,
            message: ''
        })
        setOpenDialog(false);
    };


    const productData = [
        { label: `${commonNames.Credits}:`, value: `${isProjectCreationScreen ? totalCredits === -1 ? 0 : totalCredits : credits}` },
        { label: 'Product description:', value: `Each ${commonNames.credit} corresponds to 1 GBP.` },
        // { label: 'Base price:', value: `${tokens}00 GBP` },
    ];

    //////////////product/////////////////
    const productList = <>
        <InvoiceComponent organizationName={companyData.companyName} credits={Number(isProjectCreationScreen ? totalCredits === -1 ? 0 : Number(totalCredits).toFixed(2) : Number(credits).toFixed(2))} currency={currency} priceInCurrency={Number(priceInCurrency).toFixed(2)} />
    </>
    // const productList = <div>
    //     <TableContainer>
    //         <Table sx={{ minWidth: 250 }} aria-label="Order table" >
    //             <TableBody>
    //                 {productData.map((row) => (
    //                     <TableRow key={row.label}>
    //                         <TableCell component="th" scope="row" style={{ borderBottom: 'none' }}>
    //                             {row.label}
    //                         </TableCell >
    //                         <TableCell style={{ borderBottom: 'none' }} align="left">{row.value}</TableCell>
    //                     </TableRow>
    //                 ))}
    //             </TableBody>
    //         </Table>
    //     </TableContainer>
    // </div>
    //////////////product/////////////////
    const productPurchased = <div  >
        <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",

        }}>  <img src={partypopper} alt="party-popper" />
        </div>
        <Typography variant="h6" textAlign="center" sx={{ marginTop: "10px" }}>{commonNames.Credits} added!</Typography>
        <Typography variant="body1" textAlign="center" sx={{ marginTop: "10px" }}>You now have {Number(companyData?.credits).toFixed(2)} {commonNames.credits}</Typography>
        <Typography variant="body2" textAlign="center" sx={{
            marginTop: "20px", marginBottom: "10px", fontWeight: 'bold', color: "gray"
        }}>Order ID is {orderId}
            {/* <a href="">{orderId}</a> */}
        </Typography>
        <Typography variant="body2" textAlign="center">Click <a href="/p/contact">here</a>  to contact support if you want to get further details</Typography>
    </div >
    // //////console.log(stripePaymentIsDoneAndSent, stripePaymentMessage, "datax", relevantMessage)
    const productName = `Confirm purchase of ${isProjectCreationScreen ? totalCredits === -1 ? 0 : Number(totalCredits).toFixed(2) : Number(credits).toFixed(2)} ${commonNames.credits}`
    return (
        <>
            {/* dialog widget */}
            <SimpleDialogBox
                open={openDialog}
                onClose={handleCloseDialog}
                title={

                    relevantMessage === 'Processing...'
                        ? processStatus.message !== ''
                            ? processStatus.message
                            : <>{productName}</>
                        : relevantMessage === ''
                            ? <>{productName}</>
                            : isProcessed
                                ? <>Order confirmation</>
                                : <>{productName}</>
                    // Please wait confirming your payment...
                }
                width={800}
                showError={stripePaymentIsDoneAndSent === false || errorMessage != '' ? true : false}
                errorMessage={stripePaymentIsDoneAndSent === false ? stripePaymentMessage : errorMessage != '' ? errorMessage : ''}
                bodyWidget={
                    relevantMessage === 'Processing...'
                        ? processStatus.message === 'Please wait confirming your payment...'
                            ? <CustomProgress />
                            : <>{productList}</>
                        : relevantMessage === ''
                            ? <>{productList}</>
                            : relevantMessage === `Manual action is required`
                                ? <>{productList}</> : <>{isProjectCreationScreen ? stripePaymentIsDoneAndSent === false ? productList : <><CustomProgress text={'Please wait'} /></> : stripePaymentIsDoneAndSent === false ? productList : productPurchased}</>
                }
                cancelText="Close"
                showPositive
                positiveButtonWidget={
                    stripePaymentIsDoneAndSent === false ? relevantButton : productDetails.stripeProductId !== "" ? relevantComponentBody : <p>Product not available yet!</p>
                }
            />
            {isProjectCreationScreen ?
                totalCredits === -1
                    ? <p style={{ color: '#B00020' }}>The video estimation cost is not there, please contact support team
                        <Link
                            style={{ textDecoration: 'underline', color: 'black', cursor: 'pointer' }}
                            to={`/p/${ROUTES.CONTACT}`}
                        > click here</Link>.
                    </p>
                    :
                    <Button variant="contained" onClick={() => { handleClick() }} sx={{
                        "&.Mui-disabled": {
                            backgroundColor: '#025196',
                            color: '#ffffff'
                        },
                        marginTop: '0px',
                        // marginInline: '2px',
                        textTransform: 'initial',
                        backgroundColor: '#025196',
                        color: '#ffffff',
                        ':hover': {
                            backgroundColor: '#025196',
                            color: '#ffffff'
                        }
                    }}>Buy {Number(totalCredits).toFixed(2)} {commonNames.credits} now</Button>
                : <Card sx={cardStyle} elevation={0}>
                    <CardContent sx={{ flex: 1 }}>

                        <Typography variant="p" sx={{ fontWeight: 'bold' }}>
                            Pick your required number of {commonNames.credits}
                        </Typography>

                        <Typography variant="body2" style={{ fontColor: 'gray' }}>
                            Each {commonNames.credit}  costs  &#163;1
                        </Typography>


                        {/* <div style={{ paddingInline: '8px', paddingBlock: '15px' }}> */}
                        <div style={{ padding: 15 }}>
                            <SimpleSlider
                                onValueChange={(newvalue) => handleSliderChange(newvalue)}
                            />


                            {/* <div style={{ padding: '20px' }}>
      <Typography id="credits-slider" gutterBottom>
        Credits: {calculateValue(credits)}
      </Typography>
      <Slider
        value={credits}
        max={maxCredits}
        step={credits <= 1000 ? 2 : 1000}
        onChange={handleChange}
        valueLabelDisplay="auto"
        aria-labelledby="credits-slider"
      /> */}
                            {/* <Slider
                                value={credits}
                                onChange={handleSliderChange}
                                aria-label={`Custom ${commonNames.credits} slider`}
                                valueLabelDisplay="auto"
                                marks={marks}
                                step={10}
                                scale={(value) => Math.exp(value)}
                                sx={{
                                    width: '100%',
                                    // mt: 2,
                                    // px: 2,
                                    color: '#0C153E', // set the background color of the slider
                                    '& .MuiSlider-thumb': {
                                        height: 16, // Set the height of the thumb
                                        width: 16, // Set the width of the thumb
                                    },
                                }}
                                min={values.at(0)}
                                max={values.at(-1)}
                            /> */}
                        </div>
                        <Grid container spacing={2}>
                            <Grid item xs={9}>

                            </Grid>
                            <Grid item xs={3} style={{
                                display: "flex", justifyContent: "flex-end"
                            }}>
                                <Button className="button"
                                    variant="contained" onClick={() => { handleClick() }} sx={{
                                        "&.Mui-disabled": {
                                            backgroundColor: '#025196',
                                            color: '#ffffff'
                                        },
                                        marginInline: '2px',
                                        textTransform: 'initial',
                                        backgroundColor: '#025196',
                                        ':hover': {
                                            backgroundColor: '#025196'
                                        }
                                    }}>Buy {credits} {commonNames.credits} now</Button>
                            </Grid>
                        </Grid>
                    </CardContent>

                </Card>
            }


        </>
    );
}


const mapStateToProps = (state) => {
    return {
        userData: state.firebase.profile,
        userId: state.firebase.auth.uid,
        productOrder: state.firestore.data.ProductOrder,
        companyData: state.firestore.data.Company,
        payment: state.payment,
        commons: state.commons,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        openStripeBuyProductModal: () => dispatch(openStripeBuyProductModal()),
        customerBuyConsumable: (productId, credits, proid) => dispatch(customerBuyConsumable(productId, credits, proid)),
        confirmConsumablePurchase: (orderId, coid) => dispatch(confirmConsumablePurchase(orderId)),
        clearProductRequest: () => dispatch(clearProductRequest())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlanCustomTokens)
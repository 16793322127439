import { Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import newlogo from "../../../../../../assets/bg/roadGaugeLogo2.png";
import BasicImagingMapView from './BasicImagingMapView';
const BasicImagingDefectDetails = (props) => {
    const { pinned, statusMap, priorityMap, defectNames, defectImageURLs } = props;
    if (pinned.length === 0) {
        return <></>
    }
    return (
        <>
            <Grid container spacing={0}>
                <Grid item xs={5} sx={{ background: '', padding: '10px' }}>
                    <Stack sx={{ height: '100%' }} spacing={1}>
                        <div style={{ display: 'flex', justifyContent: 'start' }}>
                            <img src={defectImageURLs[pinned.defectIdNum]} height={100} width={100} style={{ objectFit: 'cover' }} />
                        </div>
                        <div style={{ height: '100%' }}>
                            <BasicImagingMapView filteredData={[pinned]} />
                        </div>
                    </Stack>

                </Grid>
                <Grid item xs={7} sx={{ background: '', padding: '10px' }}>
                    <Typography sx={{ color: '#8F8F8F', fontSize: '15px' }}>Project name</Typography>
                    <Typography sx={{ color: '#130101', fontWeight: 'bold', fontSize: '17px', marginBottom: '10px' }}>{pinned.projectTitle}</Typography>

                    <Typography sx={{ color: '#8F8F8F', fontSize: '15px', fontWeight: 'bold' }}>Category</Typography>
                    <Typography sx={{ color: '#130101', fontWeight: 'bold', fontSize: '17px', marginBottom: '10px' }}>{defectNames ? defectNames[pinned.defectName] : <span style={{ color: '#000000', fontWeight: '400' }}><i>{'unnamed'}</i></span>}</Typography>

                    <Typography sx={{ color: '#8F8F8F', fontSize: '15px' }}>Defect ID</Typography>
                    <Typography sx={{ color: '#130101', fontWeight: 'bold', fontSize: '17px', marginBottom: '10px' }}>{pinned.defectIdNum}</Typography>

                    <Typography sx={{ color: '#8F8F8F', fontSize: '15px' }}>Priority</Typography>
                    <Typography sx={{ color: '#130101', fontWeight: 'bold', fontSize: '17px', marginBottom: '10px' }}>{priorityMap?.map[pinned.priority].name}</Typography>

                    <Typography sx={{ color: '#8F8F8F', fontSize: '15px' }}>Status</Typography>
                    <Typography sx={{ color: '#130101', fontWeight: 'bold', fontSize: '17px', marginBottom: '10px' }}>{`${statusMap?.map[pinned.status === -3 ? "4" : pinned.status].name}`}</Typography>

                    <Typography sx={{ color: '#8F8F8F', fontSize: '15px' }}>GPS coordinates</Typography>
                    <Typography sx={{ color: '#130101', fontWeight: 'bold', fontSize: '17px', marginBottom: '10px' }}>{Number(pinned.geoLat).toFixed(5)},{Number(pinned.geoLong).toFixed(5)}</Typography>

                    {/* <Typography sx={{ color: '#8F8F8F', fontSize: '15px' }}>Comments</Typography>
                    {Object.values(pinned.commentsMap).map((each) => (
                        <Typography sx={{ color: '#130101', fontWeight: 'bold', fontSize: '17px' }}>
                            {each}
                        </Typography>
                    ))} */}

                </Grid>
            </Grid >
        </>
    )
}

export default BasicImagingDefectDetails
import React, { useState, useRef, useEffect } from "react"
import './table.css'
import { Button, Card, Checkbox, CircularProgress } from '@mui/material'
import CustomTooltip from '../../../../../cidgets/CustomTooltip';
import SimpleDialogBox from "../../../../../cidgets/SimpleDialogBox";
import BasicImagingDefectDetails from "./BasicImagingDefectDetails";
import { archiveBasicImagingFeature, clearBasicImagingArchiveState } from "../../../../../../store/actions/projectActions";
import { connect } from "react-redux";
import { useAccessControlContext } from "../../../../../../contexts/AccessControlProvider";

const RejectedBasicImagingTable = (props) => {
  const { permissions } = useAccessControlContext();
  const { canArchiveGlobalDefect } = permissions;
  let { userData, defectImageURLs, priorityMap, basicImagingData, dataList = [], disableHeader = false, handleSelectAll, linkedAccountUserData, getCurrentDate } = props;
  // const { accessCodes, deletedCodes } = linkedAccountUserData;
  const { selectedItems, setSelectedItems, selectAll, statusMap, project } = props;
  const { archiveFeatureMessage, archiveFeatureIsDoneAndSent, archiveFeatureLoader } = project;
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const [open, setOpen] = useState(false);
  const tableRef = useRef(null);
  const { defectNames } = basicImagingData;
  const [pinned, setPinned] = useState([]);
  const style = {
    "paddingLeft": "15px",
    "paddingRight": "15px",
    "width": "100%",
    "height": "100%",
    "overflow": "auto",
  }
  const handleCheckboxChange = (did) => {
    setSelectedItems((prevSelected) => {
      if (prevSelected.includes(did)) {
        // If already selected, remove from the list
        return prevSelected.filter((id) => id !== did);
      } else {
        // If not selected, add to the list
        return [...prevSelected, did];
      }
    });
  };
  const handlePinned = (e) => {
    props.clearBasicImagingArchiveState();
    setOpen(true);
    setPinned(e);
  }
  const handleClose = () => {
    setOpen(false);
    setPinned([]);
  }
  const handleArchive = () => {
    props.archiveBasicImagingFeature(pinned.did, true);
  }
  useEffect(() => {
    if (archiveFeatureIsDoneAndSent === true) {
      setOpen(false);
      props.clearBasicImagingArchiveState();

    }
  }, [archiveFeatureIsDoneAndSent])
  return (
    <>
      <SimpleDialogBox
        open={open}
        onClose={handleClose}
        cancelText="Close"
        title={"Feature Details"}
        showError={archiveFeatureIsDoneAndSent === false ? true : false}
        errorMessage={archiveFeatureIsDoneAndSent === false ? archiveFeatureMessage : ''}
        width={700}
        bodyWidget={
          <BasicImagingDefectDetails defectImageURLs={defectImageURLs} pinned={pinned} statusMap={statusMap} priorityMap={priorityMap} defectNames={defectNames} />
        }
        showPositive
        positiveButtonWidget={
          <>
            {archiveFeatureLoader ?
              <>
                <Button variant="contained" disabled sx={{
                  textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                  "&.Mui-disabled": {
                    backgroundColor: '#025196',
                    color: '#FFFFFF'
                  }
                }}>
                  <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} /><span style={{ paddingLeft: '15px' }}>Archiving...</span>
                </Button>
              </>
              :

              <Button size="medium" variant="contained"
                disabled={!canArchiveGlobalDefect}
                onClick={handleArchive}
                sx={{
                  textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                  '&:hover': {
                    backgroundColor: '#025196',
                    color: '#FFFFFF'
                  }
                }}>Archive</Button>
            }
          </>
        }
      />
      <div style={style}>
        {dataList.length > 0 ?
          <table className="assetTable">
            {disableHeader ? <></> : <thead style={{}}>
              <tr>
                <th className="" style={{ width: '10%', fontWeight: '700' }}>Feature ID</th>
                <th className="" style={{ width: '15%', fontWeight: '700' }}>Feature</th>
                <th className="" style={{ width: '15%', fontWeight: '700' }}>Category</th>
                <th className="" style={{ width: '15%', fontWeight: '700' }}>Priority</th>
                <th className="" style={{ width: '25%', fontWeight: '700' }}>Assignee</th>
                <th className="" style={{ width: '15%', fontWeight: '700' }}>Deadline</th>
                <th className="" style={{ width: '5%', fontWeight: '700' }}>
                  <Checkbox
                    disableRipple
                    checked={selectAll}
                    onChange={handleSelectAll}
                    inputProps={{ 'aria-label': 'controlled' }}
                    sx={{
                      '&.Mui-checked': {
                        color: '#f0f0f0',
                        '& .MuiSvgIcon-root': {
                          backgroundColor: '#606060',
                        },
                      },
                    }}
                  />
                </th>
              </tr>
            </thead>
            }
            <tbody ref={tableRef}>
              {dataList.map((e, i) => {
                const { defectIdNum, defectName, priority, did, assignedCode, deadline } = e;
                const assigneeName =
                linkedAccountUserData ?
                    linkedAccountUserData.accessCodes[assignedCode]?.name ?
                        linkedAccountUserData.accessCodes[assignedCode]?.name :
                        linkedAccountUserData.deletedCodes[assignedCode]?.name ?? '-'
                    :
                    userData.accessCodes[assignedCode]?.name ? userData.accessCodes[assignedCode]?.name :
                        userData.deletedCodes[assignedCode]?.name ?? '-'
                const isSelected = selectedItems.includes(did);
                return <tr
                  key={i}
                  // id={defectIdNum}
                  role="button"
                // onClick={() => { handlePinned(e) }}
                >
                  <td style={{ width: '10%', fontWeight: '400' }} onClick={() => { handlePinned(e) }}>
                    <div style={{
                      width: '40px', height: '40px', borderRadius: '50%',
                      display: 'flex', alignItems: 'center',
                      justifyContent: 'center',
                      color: "black",
                      fontSize: '16px',
                      fontWeight: '400'
                    }}>{defectIdNum}</div>
                  </td>
                  <td style={{ width: '15%', fontWeight: '400' }}
                    onClick={() => { handlePinned(e) }}
                  >
                    <CustomTooltip
                      placement="top"
                      arrow
                      title={
                        <Card
                          elevation={5}>
                          <img src={defectImageURLs[defectIdNum]} width="100px" style={{ maxHeight: '200px' }} />
                        </Card>
                      }
                      widget={
                        <img src={defectImageURLs[defectIdNum]} height={50} width={50} style={{ objectFit: 'cover' }} />
                      }
                    />
                  </td>
                  <td
                    onClick={() => { handlePinned(e) }}
                    style={{ width: '15%', fontWeight: '400', color: '#000000' }} className="capitalize-text">
                    {defectNames ? defectNames[defectName] : <span style={{ color: '#000000', fontWeight: '400' }}><i>{'unnamed'}</i></span>}
                  </td>
                  <td
                    onClick={() => { handlePinned(e) }}
                    style={{ width: '15%', color: '#000000', fontWeight: '400' }} className="capitalize-text">
                    {`${priorityMap?.map[priority].name}`}
                  </td>
                  <td
                    onClick={() => { handlePinned(e) }}
                    style={{ width: '25%', color: '#000000', fontWeight: '400' }} className="capitalize-text">
                    {assigneeName}
                  </td>
                  <td
                    onClick={() => { handlePinned(e) }}
                    style={{ width: '15%', color: '#000000', fontWeight: '400' }} className="capitalize-text">
                    {deadline}
                  </td>
                  <td

                    style={{ width: '5%', color: '#000000', fontWeight: '400' }} className="capitalize-text">
                    <Checkbox
                      disableRipple
                      disableTouchRipple
                      disableFocusRipple

                      // disabled={selectAll}
                      checked={isSelected}
                      onChange={() => handleCheckboxChange(did)}
                      {...label}
                      sx={{
                        '&.Mui-checked': {
                          color: '#f0f0f0',
                          '& .MuiSvgIcon-root': {
                            backgroundColor: '#606060',
                          },
                        },
                      }}
                    />
                  </td>
                </tr>
              })}
            </tbody>
          </table> : <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>No defects data found</div>
        }

      </div >
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    project: state.project,
    userData: state.firebase.profile,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    archiveBasicImagingFeature: (did, archive) => dispatch(archiveBasicImagingFeature(did, archive)),
    clearBasicImagingArchiveState: () => dispatch(clearBasicImagingArchiveState()),

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RejectedBasicImagingTable);
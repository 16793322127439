import React, { createContext, useState, useEffect, useContext } from 'react';

const distressControlContext = createContext();
export const useAccessControlContext = () => {
    return useContext(distressControlContext);
};
const DistressContextProvider = ({ children }) => {
    const [updateList, setUpdateList] = useState(null);
    const [editList, setEditList] = useState(null);
    const [deleteList, setDeleteList] = useState(null);
    return (
        <distressControlContext.Provider value={{ updateList, editList, deleteList }}>
            {children}
        </distressControlContext.Provider>
    );
};
export default DistressContextProvider;
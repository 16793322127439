import { Button, Card, ListItem, ListItemButton, ListItemText, Tooltip, Typography, styled, tooltipClasses } from "@mui/material";
import SimpleDialogBox from "../../../../../cidgets/SimpleDialogBox";
import { useState, useEffect } from "react";
import { unarchiveAsset } from "../../../../../../store/actions/projectActions";
import { connect } from "react-redux";
import CustomTooltip from "../../../../../cidgets/CustomTooltip";
import { useKeyPress } from "../../../../../../chooks/useKeyPress";
import ControlChip from "../../../../../cidgets/ControlChip";
import { HotKeys, GlobalHotKeys } from "react-hotkeys";
import SmartUtils from "../../../../../../helpers/SmartUtils";

const ArchivedAssetsViewer = ({ assetData = [], detections = [], proid, unarchiveAsset }) => {
    // //////console.log(assetData,"assetData")
    const [openDialog, setOpenDialog] = useState(false);

    const handleOpen = () => {
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const preventDefaultHandlers = (handlers) => {
        const newHandlers = {};
        for (const [action, handler] of Object.entries(handlers)) {
            newHandlers[action] = (event) => {
                if (event) {
                    event.preventDefault();
                }
                handler();
            };
        }
        return newHandlers;
    };
    const keyShortcut = 'b'
    const keyMap = {
        BIN_MAC: "command+" + keyShortcut,
        BIN_WIN: "ctrl+" + keyShortcut,
    };

    const handlers = preventDefaultHandlers({
        BIN_MAC: handleOpen,
        BIN_WIN: handleOpen,
    });

    let archivedDetections = detections.filter((eachDetection) => {
        return eachDetection.archived === "1"
    })

    // //////console.log(archivedDetections)

    const su = new SmartUtils()
    const [os, setOs] = useState({})

    useEffect(() => {
        const fetchData = async () => {
            const data = await su._checkOS(navigator);
            setOs(data);
        }

        fetchData()
    }, [])

    const { ctrl } = su._platformKeys(os?.platform)

    return (
        <>
            <GlobalHotKeys keyMap={keyMap} handlers={handlers}></GlobalHotKeys>
            <SimpleDialogBox
                open={openDialog}
                onClose={handleClose}
                title="Archived assets"
                cancelText="Close"
                desc={archivedDetections.length > 0 ? "Unarchive an asset by clicking on it" : "There is nothing here!"}
                bodyWidget={
                    <ArchivedAssets archivedDetections={archivedDetections} assetData={assetData} proid={proid} unarchiveAsset={unarchiveAsset} />
                }
                width='600'
            />
            <Tooltip title={`Or use ${ctrl.name}${ctrl.plus}B to view bin`} placement="left" arrow><Button onClick={handleOpen} sx={{ marginRight: '10px', textTransform: 'none' }}>Assets bin</Button></Tooltip>
        </>
    );
}

const ArchivedAssets = (props) => {
    const { archivedDetections = [], assetData = [], proid, unarchiveAsset } = props;

    const cardStyle = {
        "border": "0.5px solid grey",
        "borderRadius": "8px",
        "backgroundColor": '#fff',
        "marginBlock": '10px',
        "marginTop": '20px',
    }

    const handleClick = (proid, e) => {
        // //////console.log(proid, e, "proid")
        unarchiveAsset(proid, e.sectionId, e.id);
    }

    const getAssetMeta = (assetId, debug = false) => {
        const assetMeta = assetData.filter((e) => `${e.id}`.split('_')[0] === `${assetId}`.split('_')[0])[0]
        if (debug) {
            // //////console.log(assetMeta, "getAssetMeta")
        }
        return assetMeta
    }

    return (
        <div>
            {archivedDetections.map((e, i) => {
                const obj = {
                    pred: e,
                    meta: getAssetMeta(e.assetId)
                }
                const imBase64 = `data:image/png;base64, ${obj?.pred?.cropb64 ?? ''}`;
                return <Card key={obj.pred.id} sx={cardStyle} elevation={0}>
                    <ListItem
                        dense
                        disablePadding
                        sx={{ paddingBlock: '5px' }}
                        secondaryAction={
                            <>
                                <CustomTooltip
                                    placement="right"
                                    arrow
                                    title={
                                        <Card elevation={10}>
                                            <img src={imBase64} width="100px" />
                                        </Card>
                                    }
                                    widget={<img src={imBase64} />}
                                />

                            </>
                        }
                    >
                        <ListItemButton
                            title={`Unarchive ${obj.meta.name}`}
                            onClick={() => { handleClick(proid, e) }}
                        >
                            <ListItemText
                                primary={
                                    <>
                                        <Typography
                                            variant="p"
                                            sx={{ fontWeight: 'bold' }}
                                        >{i + 1}. {obj.meta.name}</Typography>
                                    </>
                                }
                                secondary={
                                    <span>
                                        GPS: ({obj.pred.geo[0]}, {obj.pred.geo[1]})
                                    </span>
                                }
                            />
                        </ListItemButton>
                    </ListItem>
                </Card>
            })}
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        datastore: state.datastore,
        project: state.project,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        // setCurrentProjectId: (proid) => dispatch(setCurrentProjectId(proid)),
        unarchiveAsset: (proid, sectionId, detectionId) => dispatch(unarchiveAsset(proid, sectionId, detectionId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ArchivedAssetsViewer);
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import { Button, CircularProgress, MenuItem, Select, Snackbar, TextField, FormControl, InputLabel } from '@mui/material';
import SimpleDialogBox from '../../../../../cidgets/SimpleDialogBox';

function createData(id, name, role, activeUser) {
    return {
        id,
        name,
        role,
        activeUser
    };
}
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}
const headCells = [
    {
        id: 'id',
        numeric: false,
        disablePadding: true,
        label: 'Code',
    },
    {
        id: 'name',
        numeric: true,
        disablePadding: true,
        label: 'Name',
    },
    {
        id: 'role',
        numeric: true,
        disablePadding: true,
        label: 'Role',
    },
    // {
    //     id: 'activeUser',
    //     numeric: true,
    //     disablePadding: true,
    //     label: 'Active',
    // }
];

function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'left' : 'center'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {headCell.id === 'name' ? (
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                            </TableSortLabel>
                        ) : (
                            headCell.label
                        )}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead >
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
    const {
        roles,
        searchTerm, handleSearchChange,
        addAccessCode, setSelected, selectedAccessCodesList,
        updateAccessCodeLoader, updateAccessCodeIsDoneAndSent, updateAccessCode,
        removeAccessCodeLoader, removeAccessCodeIsDoneAndSent, removeAccessCode,
        addAccessCodeIsDoneAndSent, numSelected, addAccessCodeLoader, userData, selected } = props;
    const { uid } = userData;
    const [openAddAccess, setOpenAddAccess] = useState(false);
    const [openUpdateAccess, setOpenUpdateAccess] = useState(false);
    const [openDeleteAccess, setOpenDeleteAccess] = useState(false);
    const [employeeName, setEmployeeName] = useState("");
    const [employeeNameError, setEmployeeNameError] = useState(false);
    const [updateEmployeeName, setUpdateEmployeeName] = useState('');
    const [updateEmployeeNameError, setUpdateEmployeeNameError] = useState(false);
    const [role, setRole] = useState('');
    const [roleError, setRoleError] = useState(false);
    useEffect(() => {
        if (addAccessCodeIsDoneAndSent === true) {
            handleAddAccessClose();
        }
    }, [addAccessCodeIsDoneAndSent])
    useEffect(() => {
        if (updateAccessCodeIsDoneAndSent === true) {
            setSelected([]);
            handleUpdateAccessClose();
        }
    }, [updateAccessCodeIsDoneAndSent])
    useEffect(() => {
        if (removeAccessCodeIsDoneAndSent === true) {
            setSelected([]);
            handleDeleteAccessClose()
        }
    }, [removeAccessCodeIsDoneAndSent])
    useEffect(() => {
        if (selectedAccessCodesList) {
            setUpdateEmployeeName(selectedAccessCodesList.name);
            setRole(selectedAccessCodesList.role);
        }
    }, [selectedAccessCodesList])
    const handleAddAccessClose = () => {
        setOpenAddAccess(false);
    }
    const handleAddAccessOpen = () => {
        // setRole('');
        setEmployeeName('');
        setRoleError(false);
        setEmployeeNameError(false);
        setOpenAddAccess(true);
    }
    const handleUpdateAccessClose = () => {
        setOpenUpdateAccess(false);
    }
    const handleUpdateAccessOpen = () => {
        // setUpdateEmployeeName('');
        setUpdateEmployeeName(selectedAccessCodesList.name);
        setRole(selectedAccessCodesList.role);
        setOpenUpdateAccess(true);
    }
    const handleDeleteAccessClose = () => {
        setOpenDeleteAccess(false);
    }
    const handleDeleteAccessOpen = () => {
        setOpenDeleteAccess(true);
    }
    const handleAddNameChange = (event) => {
        setEmployeeNameError(false);
        setEmployeeName(event.target.value);
    }
    const handleUpdateNameChange = (event) => {
        setUpdateEmployeeNameError(false);
        setUpdateEmployeeName(event.target.value);
    }
    const handleAddName = () => {
        if (employeeName.length > 0 && role !== '') {
            addAccessCode(uid, employeeName, role);
        }
        else {
            if (role === '') {
                setRoleError(true);
            }
            else if (employeeName.length === 0) {
                setEmployeeNameError(true);
            }
        }
    }
    const handleUpdateName = () => {
        if (updateEmployeeName.length > 0 && role !== '') {
            updateAccessCode(uid, updateEmployeeName, selected[0], role);
        }
        else {
            if (role === '') {
                setRoleError(true);
            }
            else if (employeeName.length === 0) {
                setEmployeeNameError(true);
            }
        }
    }
    const handleDeleteName = () => {
        removeAccessCode(uid, selected[0]);
    }


    const handleRoleChange = (event) => {
        setRoleError(false);
        setRole(event.target.value);
    };
    return (
        <>
            <SimpleDialogBox
                open={openAddAccess}
                onClose={handleAddAccessClose}
                title="Add employee"
                shouldTitleAppear={true}
                cancelText="Close"
                bodyWidget={
                    <>
                        <FormControl size="small" sx={{ width: "100%" }}>
                            <InputLabel htmlFor="role-select">Select role</InputLabel>
                            <Select
                                // size="medium"
                                sx={{
                                    width: '100%',
                                    backgroundColor: "transparent",
                                    color: "#000000",
                                    textTransform: "initial",
                                    paddingInline: "8px",
                                    overflow: "auto",
                                    "&:hover": {
                                        backgroundColor: "transparent",
                                        color: "#000000",
                                    },
                                    marginBottom: '10px'
                                }}
                                value={role}
                                onChange={handleRoleChange}
                                // fullWidth
                                label="Select Role"
                                error={roleError}
                                disabled={addAccessCodeLoader}
                                inputProps={{
                                    name: 'role',
                                    id: 'role-select',
                                }}

                            >
                                {/* <MenuItem disabled value="">
                                    <em>Select Role</em>
                                </MenuItem> */}
                                {roles.map((roleData) => (
                                    <MenuItem key={roleData.key} value={roleData.key}>
                                        {roleData.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField size='small' required fullWidth label="Name" id="employeeName" error={employeeNameError} helperText={employeeNameError ? "Invalid name" : ''} value={employeeName} disabled={addAccessCodeLoader} onChange={handleAddNameChange} />
                    </>
                }
                width='600'
                showPositive
                positiveButtonWidget={<>
                    {addAccessCodeLoader ?
                        <>
                            <Button variant="contained" disabled sx={{
                                textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                                "&.Mui-disabled": {
                                    backgroundColor: '#025196',
                                    color: '#FFFFFF'
                                }
                            }}>
                                <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} /><span style={{ paddingLeft: '15px' }}>Adding...</span>
                            </Button>
                        </>
                        :

                        <Button size="medium" variant="contained"
                            onClick={handleAddName}
                            sx={{
                                textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                                '&:hover': {
                                    backgroundColor: '#025196',
                                    color: '#FFFFFF'
                                }
                            }}>Add</Button>
                    }
                </>}
            />
            <SimpleDialogBox
                open={openUpdateAccess}
                onClose={handleUpdateAccessClose}
                title="Update employee"
                shouldTitleAppear={true}
                cancelText="Close"
                bodyWidget={
                    <>
                        <FormControl size="small" sx={{ width: "100%" }}>
                            <InputLabel htmlFor="role-select">Select role</InputLabel>
                            <Select
                                value={role}
                                onChange={handleRoleChange}
                                label="Select Role"
                                sx={{
                                    width: '100%',
                                    backgroundColor: "transparent",
                                    color: "#000000",
                                    textTransform: "initial",
                                    paddingInline: "8px",
                                    overflow: "auto",
                                    "&:hover": {
                                        backgroundColor: "transparent",
                                        color: "#000000",
                                    },
                                    marginBottom: '10px'
                                }}
                                error={roleError}
                                disabled={updateAccessCodeLoader}
                                inputProps={{
                                    name: 'role',
                                    id: 'role-select',
                                }}
                            >
                                {/* <MenuItem disabled value="">
                                <em>Select Role</em>
                            </MenuItem> */}
                                {roles.map((roleData) => (
                                    <MenuItem key={roleData.key} value={roleData.key}>
                                        {roleData.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField size='small' fullWidth label="Name" id="updateEmployeeName" error={updateEmployeeNameError} helperText={updateEmployeeNameError ? "Invalid name" : ''} value={updateEmployeeName} disabled={updateAccessCodeLoader} onChange={handleUpdateNameChange} />
                    </>

                }
                width='600'
                showPositive
                positiveButtonWidget={<>
                    {updateAccessCodeLoader ?
                        <>
                            <Button variant="contained" disabled sx={{
                                textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                                "&.Mui-disabled": {
                                    backgroundColor: '#025196',
                                    color: '#FFFFFF'
                                }
                            }}>
                                <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} /><span style={{ paddingLeft: '15px' }}>Updating...</span>
                            </Button>
                        </>
                        :

                        <Button size="medium" variant="contained"
                            onClick={handleUpdateName}
                            sx={{
                                textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                                '&:hover': {
                                    backgroundColor: '#025196',
                                    color: '#FFFFFF'
                                }
                            }}>Update</Button>
                    }
                </>}
            />
            <SimpleDialogBox
                open={openDeleteAccess}
                onClose={handleDeleteAccessClose}
                title="Delete employee"
                shouldTitleAppear={true}
                cancelText="Close"
                bodyWidget={
                    <Typography variant='body2'>Are you sure you want to delete?</Typography>
                }
                width='600'
                showPositive
                positiveButtonWidget={<>
                    {removeAccessCodeLoader ?
                        <>
                            <Button variant="contained" disabled sx={{
                                textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                                "&.Mui-disabled": {
                                    backgroundColor: '#025196',
                                    color: '#FFFFFF'
                                }
                            }}>
                                <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} /><span style={{ paddingLeft: '15px' }}>Deleting...</span>
                            </Button>
                        </>
                        :

                        <Button size="medium" variant="contained"
                            onClick={handleDeleteName}
                            sx={{
                                textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                                '&:hover': {
                                    backgroundColor: '#025196',
                                    color: '#FFFFFF'
                                }
                            }}>Delete</Button>
                    }
                </>}
            />
            <Toolbar
                sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                }}
            >
                <TextField
                    sx={{ flex: '1 1 100%', marginRight: '20px' }}
                    label="Search by name or code"
                    variant="outlined"
                    margin="normal"
                    disabled={numSelected > 0}
                    value={searchTerm}
                    onChange={handleSearchChange}
                />

                {numSelected > 0 ? (
                    <Box sx={{ display: 'flex', justifyContent: 'end', marginTop: '8px' }}>
                        <Tooltip title="Update employee">
                            <Button size="medium" variant="contained"
                                onClick={handleUpdateAccessOpen}
                                sx={{
                                    marginRight: '10px',
                                    textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                                    '&:hover': {
                                        backgroundColor: '#025196',
                                        color: '#FFFFFF'
                                    }
                                }}>Update</Button>
                        </Tooltip>
                        <Tooltip title="Delete employee">
                            <Button size="medium" variant="contained"
                                onClick={handleDeleteAccessOpen}
                                sx={{
                                    textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                                    '&:hover': {
                                        backgroundColor: '#025196',
                                        color: '#FFFFFF'
                                    }
                                }}>Delete</Button>
                        </Tooltip>
                    </Box>
                ) : (
                    <Box sx={{ display: 'flex', justifyContent: 'end', marginTop: '8px' }}>
                        <Tooltip title="Add employee">
                            <Button size="medium" variant="contained"
                                onClick={handleAddAccessOpen}
                                sx={{
                                    textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                                    '&:hover': {
                                        backgroundColor: '#025196',
                                        color: '#FFFFFF'
                                    }
                                }}>Add</Button>
                        </Tooltip>
                    </Box>
                )}
            </Toolbar>
        </>
    );
}

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

const AccessCodeTable = (props) => {
    const { accessCodesList, userData, addAccessCode, addAccessCodeLoader, addAccessCodeIsDoneAndSent,
        updateAccessCodeLoader, updateAccessCodeIsDoneAndSent, updateAccessCode,
        removeAccessCodeLoader, removeAccessCodeIsDoneAndSent, removeAccessCode, accessManagementData } = props;
    const { roles } = accessManagementData;
    const [open, setOpen] = useState(false)
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('name');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(4);
    const [rows, setRows] = React.useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredRows, setFilteredRows] = useState([]);

    //Dialog states


    useEffect(() => {
        if (accessCodesList !== undefined) {
            const accessCodes = Object.values(accessCodesList);
            const rows = accessCodes.map((eachData) => createData(eachData.accessCode, eachData.name, roles[eachData.role]?.name, eachData.active ? eachData.active : false));
            setRows(rows);
            setFilteredRows(rows);
        }
    }, [accessCodesList]);

    const handleShareClick = (id) => {
        setOpen(true)
        navigator.clipboard.writeText(`${id}`)
    }
    const handleSearchChange = (event) => {
        const term = event.target.value.toLowerCase();
        setSearchTerm(term);
        const filtered = rows.filter((row) => row.name.toLowerCase().includes(term) || row.id.toLowerCase().includes(term));
        setFilteredRows(filtered);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        // //////console.log(property, "property", orderBy, order, isAsc);
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const isSelected = selected.includes(id);
        let newSelected = [];
        if (!isSelected) {
            newSelected = [id];
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    // const visibleRows = React.useMemo(
    //     () =>
    //         stableSort(rows, getComparator(order, orderBy)).slice(
    //             page * rowsPerPage,
    //             page * rowsPerPage + rowsPerPage,
    //         ),
    //     [order, orderBy, page, rowsPerPage, rows],
    // );
    const visibleRows = React.useMemo(
        () =>
            stableSort(filteredRows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage, filteredRows],
    );

    return (
        // <Container maxWidth="sm">
        <>
            <Snackbar
                open={open}
                onClose={() => setOpen(false)}
                autoHideDuration={2000}
                message="Copied the verification code to clipboard"
            />
            <Paper elevation={1} sx={{ width: '50%', background: '#FAFAFA', border: '1px solid #000000' }}>
                <EnhancedTableToolbar
                    roles={roles}
                    searchTerm={searchTerm}
                    selected={selected}
                    handleSearchChange={handleSearchChange}
                    setSelected={setSelected}
                    selectedAccessCodesList={selected.length > 0 ? accessCodesList[selected[0]] : []}
                    updateAccessCodeLoader={updateAccessCodeLoader} updateAccessCodeIsDoneAndSent={updateAccessCodeIsDoneAndSent} updateAccessCode={props.updateAccessCode}
                    removeAccessCodeLoader={removeAccessCodeLoader} removeAccessCodeIsDoneAndSent={removeAccessCodeIsDoneAndSent} removeAccessCode={props.removeAccessCode}
                    addAccessCodeIsDoneAndSent={addAccessCodeIsDoneAndSent} addAccessCodeLoader={addAccessCodeLoader} userData={userData} addAccessCode={addAccessCode} numSelected={selected.length} />
                <Table
                    // sx={{ backgroundColor: "#FAFAFA" }}
                    // sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size={dense ? 'small' : 'medium'}
                >
                    <EnhancedTableHead
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={rows.length}
                    />
                    <TableBody>
                        {visibleRows.map((row, index) => {
                            const isItemSelected = isSelected(row.id);
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                                <TableRow
                                    hover
                                    // onClick={(event) => handleClick(event, row.id)}
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={row.id}
                                    selected={isItemSelected}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    <TableCell padding="checkbox">
                                        <Tooltip title={"Select to update or delete employee details"} placement='top' arrow>
                                            <Checkbox
                                                color="primary"
                                                checked={isItemSelected}
                                                onClick={(event) => handleClick(event, row.id)}
                                                inputProps={{
                                                    'aria-labelledby': labelId,
                                                }}
                                            />
                                        </Tooltip>

                                    </TableCell>
                                    <TableCell component="th" align='center' id={labelId}
                                        scope="row"
                                        padding="none"
                                        onClick={() => { handleShareClick(row.id) }}
                                    >
                                        <Tooltip title={"Click to copy the verification code to your clipboard"} placement='top' arrow>
                                            <div>{row.id}</div>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell
                                        component="th"
                                        align='left'
                                        id={labelId}
                                        scope="row"
                                        padding="none"
                                        onClick={() => { handleShareClick(row.id) }}
                                    >
                                        {row.name}
                                    </TableCell>
                                    <TableCell
                                        component="th"
                                        align='left'
                                        id={labelId}
                                        scope="row"
                                        padding="none"
                                        onClick={() => { handleShareClick(row.id) }}
                                    >
                                        {row.role}
                                    </TableCell>
                                    {/* <TableCell
                                        component="th" align='left' id={labelId}
                                        scope="row"
                                        padding="none"
                                        onClick={() => { handleShareClick(row.id) }}
                                    >
                                        <div style={{ width: '20%', display: 'flex', justifyContent: 'center' }}>
                                            {row.activeUser ? <div
                                                style={{
                                                    width: '12px',
                                                    height: '12px',
                                                    borderRadius: '50%',
                                                    backgroundColor: 'green',
                                                }}
                                            ></div> : <div
                                                style={{
                                                    width: '12px',
                                                    height: '12px',
                                                    borderRadius: '50%',
                                                    backgroundColor: 'red',
                                                }}
                                            ></div>}
                                        </div>
                                    </TableCell> */}

                                </TableRow>
                            );
                        })}
                        {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: (dense ? 33 : 53) * emptyRows,
                                }}
                            >
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[5]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </>
        // </Container>
    );
}

export default AccessCodeTable
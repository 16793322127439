import { Grid, MenuItem, Paper, TextField, Typography, styled } from '@mui/material'

const DistressFieldsAdd = (props) => {
    const { distressData, fieldValues, setFieldValues, drProjectData } = props;
    const { dataInfoV2: dataInfo } = distressData;
    const handleChange = (prop) => (event) => {
        setFieldValues({ ...fieldValues, [prop]: event.target.value });
    };
    const Item = styled(Paper)(({ theme }) => ({
        boxShadow: '0px 0px transparent',
        width: '100%',
        backgroundColor: 'transparent',
        ...theme.typography.body2,
        padding: theme.spacing(0.5),
    }));
    const fieldTextStyle = {
        fontSize: '15px',
        fontWeight: 'bold',
    };
    const editableDefectNameList = drProjectData.projectType === 5 ? ['0', '1', '3', '5', '10', '12', '13'] : Object.values(dataInfo.defectName)
        .filter((each) => each.enabled && each.key !== -1)
        .map((each) => String(each.key));
    const showTypeFieldList = ['1'];
    const showWheelPathFieldList = ['4'];
    const defectNamesMap = {
        "map": dataInfo['defectName'],
        "keys": Object.keys(dataInfo['defectName']),
        "values": Object.values(dataInfo['defectName']),
        "usefulKeys": editableDefectNameList,
    }
    const defectNamesList = defectNamesMap.keys
        .filter((e) => defectNamesMap.usefulKeys.includes(e))
        .filter((e) => editableDefectNameList.includes(e))
        .map((e) => (
            <MenuItem key={e} value={e} sx={{ textTransform: 'capitalize' }}>
                {defectNamesMap.map[e]?.name}
            </MenuItem>
        ));
    // This code will need so donot remove
    // const transversePositionMap = {
    //     "map": dataInfo['transversePosition'],
    //     "keys": Object.keys(dataInfo['transversePosition']),
    //     "values": Object.values(dataInfo['transversePosition']),
    //     "usefulKeys": ['0', '1', '2', '3', '4', '5', '-1'],
    // }
    // const transversePositionList = transversePositionMap.keys.filter(e => transversePositionMap.usefulKeys.includes(e)).map((e, i) => {
    //     return <MenuItem key={e} value={e} sx={{ textTransform: 'capitalize' }}>
    //         {`${transversePositionMap.values[i]['name']}`}
    //     </MenuItem>
    // })
    // //////console.log(transversePositionList, 'which')
    const typeMap = {
        "map": dataInfo['type'],
        "keys": Object.keys(dataInfo['type']),
        "values": Object.values(dataInfo['type']),
        "usefulKeys": ['0', '1', '2', '3', '4', '5', '6', '998', '-1'],
    }
    const typeList = typeMap.keys.filter(e => typeMap.usefulKeys.includes(e)).map((e, i) => {
        return <MenuItem key={e} value={e} sx={{ textTransform: 'capitalize' }}>
            {`${typeMap.values[i]['name']}`}
        </MenuItem>
    })
    const severityMap = {
        "map": dataInfo['severity'],
        "keys": Object.keys(dataInfo['severity']),
        "values": Object.values(dataInfo['severity']),
        "usefulKeys": ['-1', '0', '1', '2'],
    }
    const severityList = severityMap.keys.filter(e => severityMap.usefulKeys.includes(e)).map((e, i) => {
        return <MenuItem key={e} value={e} sx={{ textTransform: 'capitalize' }}>
            {`${severityMap.values[i]['name']}`}
        </MenuItem>
    })
    const waterPumpingMap = {
        "map": dataInfo['waterPumping'],
        "keys": Object.keys(dataInfo['waterPumping']),
        "values": Object.values(dataInfo['waterPumping']),
        "usefulKeys": ['-1', '0', '1']
    }
    const waterPumpingList = waterPumpingMap.keys.filter(e => waterPumpingMap.usefulKeys.includes(e)).map((e, i) => {
        return <MenuItem key={e} value={e} sx={{ textTransform: 'capitalize' }}>
            {`${waterPumpingMap.values[i]['name']}`}
        </MenuItem>
    })
    // const profileMap = {
    //     "map": dataInfo['profile'],
    //     "keys": Object.keys(dataInfo['profile']),
    //     "values": Object.values(dataInfo['profile']),
    //     "usefulKeys": ['0', '1', '2', '-1'],
    // }
    // const profileList = profileMap.keys.filter(e => profileMap.usefulKeys.includes(e)).map((e, i) => {
    //     return <MenuItem key={e} value={e} sx={{ textTransform: 'capitalize' }}>
    //         {`${profileMap.values[i]['name']}`}
    //     </MenuItem>
    // })
    const wheelPathMap = {
        "map": dataInfo['wheelPath'],
        "keys": Object.keys(dataInfo['wheelPath']),
        "values": Object.values(dataInfo['wheelPath']),
        "usefulKeys": ['0', '1', '-1'],
    }
    const wheelPathList = wheelPathMap.keys.filter(e => wheelPathMap.usefulKeys.includes(e)).map((e, i) => {
        return <MenuItem key={e} value={e} sx={{ textTransform: 'capitalize' }}>
            {`${wheelPathMap.values[i]['name']}`}
        </MenuItem>
    })
    return (
        <Grid container>
            <Grid item xs={6} >
                {/* <div style={{ backgroundColor: 'transparent', overflow: 'auto' }}> */}

                <Item>
                    <Grid container>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                        }} >
                            <Typography variant="body1" sx={fieldTextStyle}>
                                Defect category:
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <TextField
                                id="defect name"
                                select
                                value={fieldValues.defectNameState}
                                onChange={handleChange("defectNameState")}
                                size="small"
                                sx={{ textTransform: 'capitalize', width: '100%' }}
                            >
                                {defectNamesList}
                            </TextField>
                        </Grid>
                    </Grid>
                </Item>

                {/* <Item>
                    <Grid container>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                        }} >
                            <Typography variant="body1" sx={fieldTextStyle}>
                                Transverse position:
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <TextField
                                id="transverse position"
                                select
                                value={fieldValues.transversePositionState}
                                onChange={handleChange("transversePositionState")}
                                size="small"
                                sx={{ textTransform: 'capitalize', width: '100%' }}
                            >
                                {transversePositionList}
                            </TextField>
                        </Grid>
                    </Grid>
                </Item> */}

                {showTypeFieldList.includes(`${fieldValues.defectNameState}`) ? <Item>
                    <Grid container>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                        }} >
                            <Typography variant="body1" sx={fieldTextStyle}>
                                Type:
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <TextField
                                id="type"
                                select
                                value={fieldValues.typeState}
                                onChange={handleChange("typeState")}
                                size="small"
                                sx={{ textTransform: 'capitalize', width: '100%' }}
                            >
                                {typeList}
                            </TextField>
                        </Grid>
                    </Grid>
                </Item> : <></>}


            </Grid>
            <Grid item xs={6}>
                <Item>
                    <Grid container>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }} >
                            <Typography variant="body1" sx={fieldTextStyle}>
                                Severity:
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}  >
                            <TextField
                                id="severity"
                                select
                                value={fieldValues.severityState}
                                onChange={handleChange("severityState")}
                                size="small"
                                sx={{ textTransform: 'capitalize', width: '100%' }}
                            >
                                {severityList}
                            </TextField>
                        </Grid>
                    </Grid>
                </Item>
                <Item>
                    <Grid container>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }} >
                            <Typography variant="body1" sx={fieldTextStyle}>
                                Water pumping:
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}  >
                            <TextField
                                id="waterPumping"
                                select
                                value={fieldValues.waterPumpingState}
                                onChange={handleChange("waterPumpingState")}
                                size="small"
                                sx={{ textTransform: 'capitalize', width: '100%' }}
                            >
                                {waterPumpingList}
                            </TextField>
                        </Grid>
                    </Grid>
                </Item>
                {showWheelPathFieldList.includes(`${fieldValues.defectNameState}`) ? <Item>
                    <Grid container>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }} >
                            <Typography variant="body1" sx={fieldTextStyle}>
                                Wheel path:
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}  >
                            <TextField
                                id="wheel path"
                                select
                                value={fieldValues.wheelPathState}
                                onChange={handleChange("wheelPathState")}
                                size="small"
                                sx={{ textTransform: 'capitalize', width: '100%' }}
                            >
                                {wheelPathList}
                            </TextField>
                        </Grid>

                    </Grid>
                </Item> : <></>}
            </Grid>
        </Grid>
    )
}

export default DistressFieldsAdd
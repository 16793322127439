import { Button, Card, CircularProgress, Divider, Grid, Tooltip, Typography } from '@mui/material'
import React, { useRef, useState, useEffect, useMemo } from 'react'
import { connect } from 'react-redux';
import {
  addBasicImagingDefect, addDistressV2, basicImagingValidConfirm, clearAddDefectErrorAndMsg, clearArchiveErrorAndMsg,
  clearUpdateErrorAndMsg, clearValidConfirmErrorAndMsg, moveBasicImagingFeatureToArchive, moveBasicImagingMultipleFeatureToArchive, removeAddNewAssetErrorAndMessage,
  resetArchiveFeature, updateBasicImagingDefect, updateDistressV2
} from '../../../../../../store/actions/projectActions';
import { get } from 'lodash';
import PinnedPothole from './PinnedPothole';
import PotholeTable from './PotholeTable';
import DrawBBox from '../../components/DrawBbox';
import ArchivedPotholeViewer from './ArchivedPotholeViewer';
import PotholeVideoPlayer from './PotholeVideoPlayer';
import PotholeMap from './PotholeMap';
import PotholeTopbar from './PotholeTopbar';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useAccessControlContext } from '../../../../../../contexts/AccessControlProvider';
import { storage } from '../../../../../../config/firebaseConfig';
import SmartUtils from '../../../../../../helpers/SmartUtils';
import EditorVideoPlayer from './EditorVideoPlayer';
import CustomizedDividers from './CustomizedDividers';
import SimpleDialogBox from '../../../../../cidgets/SimpleDialogBox';
import EditOptions from './EditOptions';
import CustomAlert from '../../../../../cidgets/CustomAlert';
import axios from 'axios';
const PotholeInferenceDashboard = (props) => {
  // VARIABLES
  const { permissions } = useAccessControlContext();
  const { canAddDefectBasic, canUnarchiveDefectBasic } = permissions;
  var { datastore, projectPredictions, predictionCropb64List, drProjectData, proid, link, project } = props;
  const { showUpdateDefectLoader, updateDefectDoneAndSent, basicValidConfirmIsDoneAndSent,
    basicFeatureArchiveIsDoneAndSent, basicFeatureArchiveMessage, showBasicFeatureArchiveLoader,
    showBasicValidConfirmLoader,
    addDefectDoneAndSent, showAddDefectLoader, addDefectMessage, addDistressArchiveIsDoneAndSent, addDistressArchiveMessage,
    updateDistressArchiveIsDoneAndSent, updateDistressArchiveMessage } = props.project;
  const { assetData, dataInfo, basicImagingData } = datastore;
  const { dataInfo: basicImagingDataInfo } = basicImagingData;
  const { showAddNewAssetLoader, addNewAssetMessage } = project;
  const [defectImageURLs, setDefectImageURLs] = useState([]);
  const [maskImageURLs, setMaskImageURLs] = useState([]);
  const [isAddingDistress, setIsAddingDistress] = useState(false);
  const [pinned, setPinned] = useState(null);
  const vidDivRef = useRef(null);
  const playerRef = useRef(null);
  const headerRef = useRef(null)
  const comparePlayerRef = useRef(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [openEditAlert, setOpenEditAlert] = useState(false);
  const [openArchiveAlert, setOpenArchiveAlert] = useState(false);
  const [openDistressDialog, setOpenDistressDialog] = useState(false);
  const [fieldValues, setFieldValues] = useState({
    sectionId: '',
    gpsLat: '',
    gpsLng: '',
    defectId: '',
    basicImagingClassState: '',
    frameNumberState: '',
    classification: '',
  });
  const [inputFrame, setInputFrame] = useState(0);
  // const [videoPlayerState, setVideoPlayerState] = useState(null);
  const handleChange = (prop) => (event) => {
    setFieldValues({ ...fieldValues, [prop]: event.target.value });
  };
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [rectangles, setRectangles] = useState([]);
  const [editorMode, setEditorMode] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [vidDivDimen, setVidDivDimen] = useState({ height: 1, width: 1 })
  const [svgDimensions, setSvgDimensions] = useState({ height: 1, width: 1, scale: 1, offsetTop: 0, offsetLeft: 0 });
  const [imageDimensions, setImageDimensions] = useState({ height: 1, width: 1 });
  const [openDialog, setOpenDialog] = React.useState(false);
  const [qualities, setQualities] = useState([])
  const [openAddNewAssetDialog, setOpenAddNewAssetDialog] = useState(false);
  const cardWhiteStyle = { "borderRadius": "15px" }
  const videoHeight = 50;
  const mapHeight = 50;
  let assetVideoPlayerPadding;
  const overallCustomBorderPadding = assetVideoPlayerPadding = 16;
  const realFps = 'fps' in drProjectData ? drProjectData.outFps ? drProjectData.outFps : drProjectData.fps : 23.976023976023978;
  const totalFrames = drProjectData.outFrames ? drProjectData.outFrames-1 : drProjectData.frames;
  const fps = Number(realFps).toFixed(0)
  const originalVideoHeight = drProjectData?.dimensions?.height ?? 0
  const originalVideoWidth = drProjectData?.dimensions?.width ?? 0
  const [sectionIndex, setSectionIndex] = useState(0);
  const [showMaskImage, setShowMaskImage] = useState(false);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [filteredFrameData, setFilteredFrameData] = useState([]);
  const [isQuickEdit, setIsQuickEdit] = useState(false);
  const [confirmSelections, setConfirmSelections] = useState(false);
  const [showPointer, setShowPointer] = useState(false);
  const [selectedRecId, setSelectedRecId] = useState(null);
  const [deleteMultipleBbox, setDeleteMultipleBbox] = useState(false);
  const [deleteBboxList, setDeleteBboxList] = useState([]);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);

  const handleOpenDeleteDialog = () => {
    setDeleteDialog(true);
  }
  const handleCloseDeleteDialog = () => {
    setDeleteDialog(false);
  }
  const handleSelectDelete = () => {
    setDeleteMultipleBbox(true);
    closeBoundingBox();
  }
  const handleDeselectDelete = () => {
    setDeleteMultipleBbox(false);
  }
  const handleClearSelection = () => {
    setRectangles((prevRectangles) => {
      const newRectangles = [...deleteBboxList, ...prevRectangles];
      return newRectangles;
    })
    setDeleteBboxList([]);
    setDeleteMultipleBbox(false);
    setDeleteDialog(false);
  }
  const handleDeleteBbox = (data) => {
    // if (!data.isEditable) {
    //   setShowDeleteAlert(true);
    //   return;
    // }
    setRectangles((prevRectangles) => prevRectangles.filter((rect) => rect.id !== data.id));
    const deleteDetectionData = filteredFrameData.filter((eachDetection) => eachDetection.id === data.id);
    if (deleteDetectionData.length > 0) {
      setDeleteBboxList((prevDeleteBboxList) => {
        const newList = [
          {
            ...data,
            detectionId: deleteDetectionData[0]['id'],
            sectionId: deleteDetectionData[0]['sectionId'],
          }
          , ...prevDeleteBboxList];
        return newList;
      })
    }
    else {
      alert(`Couldn't find the data for deletion`)
    }

  }
  projectPredictions = useMemo(() => Object.values(projectPredictions).sort((a, b) => {
    return a.sectionId - b.sectionId
  }), [projectPredictions])
  const predictions = useMemo(() => [projectPredictions[sectionIndex]], [sectionIndex, projectPredictions]);
  let gpsData = []
  let gpsDataCombined = []
  let rawDetections = []
  let originalDetections = []
  const wholeProjectGps = projectPredictions.map((eachSection) =>
    Object.values(eachSection.GPS)
  );
  const wholeProjectDetections = projectPredictions.map((eachSection) =>
    Object.values(eachSection['detections']).map((eachDetection) => {
      const newDetection = {
        ...eachDetection,
        sectionId: eachSection['id']
      };
      if (eachDetection.versioning) {
        newDetection.typeIndex = eachDetection.versioning.typeIndex || newDetection.typeIndex;
        newDetection.defectName = eachDetection.versioning.defectName || newDetection.defectName;
        newDetection.allBboxes = eachDetection.versioning.allBboxes || newDetection.allBboxes;
      }
      return newDetection;
    })
  );
  predictions.forEach((e) => {
    gpsData = [...gpsData, e['GPS']]
    gpsDataCombined = [...gpsDataCombined, ...e['GPS']]
    rawDetections = [...rawDetections, ...Object.values(e['detections'])]
    const localDetections = Object.values(e['detections']).map((eachDetection) => {
      // Create a new object with properties from eachDetection
      const newDetection = {
        ...eachDetection,
        cropb64: eachDetection['id'] in (predictionCropb64List ?? []) ? predictionCropb64List[eachDetection['id']] : '',
        sectionId: e['id']
      };

      // If versioning exists, add its properties to the newDetection object
      if (eachDetection.versioning) {
        newDetection.typeIndex = eachDetection.versioning.typeIndex || newDetection.typeIndex;
        newDetection.defectName = eachDetection.versioning.defectName || newDetection.defectName;
        newDetection.priority = eachDetection.versioning.priority || newDetection.priority;
        newDetection.isValid = eachDetection.versioning.isValid || newDetection.isValid;
        newDetection.bbox = eachDetection.versioning.bbox || newDetection.bbox;
      }
      return newDetection;
    });

    originalDetections = [...originalDetections, ...localDetections];
  });
  let detections = originalDetections.filter((eachDetection) => {
    return eachDetection.archived === "0"
  })
  detections.sort((a, b) => {
    return a.frameNumber - b.frameNumber
  })
  const doesGpsDataCombinedExists = wholeProjectGps.flat().length > 0
  const startEndLocation = {
    startLat: doesGpsDataCombinedExists ? wholeProjectGps.flat().at(0).lat : 0,
    startLng: doesGpsDataCombinedExists ? wholeProjectGps.flat().at(0).lng : 0,
    endLat: doesGpsDataCombinedExists ? wholeProjectGps.flat().at(-1).lat : 0,
    endLng: doesGpsDataCombinedExists ? wholeProjectGps.flat().at(-1).lng : 0,
  }
  const dataset = assetData;
  const imageBase64 = dataset.filter((e) => `${e.id}`.split('_')[0] === `${pinned?.meta?.id ?? ''}`.split('_')[0])[0]?.base64;
  const timeList = useMemo(() => {
    return qualities.length === 0 ? detections : detections.filter((e) => qualities.includes(calculateQuality(e.priority)));
  }, [projectPredictions, qualities, sectionIndex]);
  const index = (element) => element.id === pinned?.pred?.id;
  var assetIndex = timeList.findIndex(index);
  const headerHeight = headerRef?.current?.clientHeight ?? 0
  const pinnedUiHeight = pinned === null ? 0 : 45;
  const tableHeight = 90 - pinnedUiHeight;
  const isCurrentFramePinned = true;
  const compareWindowMargin = 0;

  // USE-EFFECTS FUNCTIONS
  useEffect(() => {
    const runApiInstance = async () => {
      await axios.get('https://basic-distress-helper-76531320480.us-central1.run.app');
    }
    runApiInstance();
  }, [])
  useEffect(() => {
    if (pinned) {
      const data = projectPredictions.filter((each) => each.id === pinned.pred.sectionId)[0]['detections'][pinned.pred.id];
      if (data.versioning) {
        data.defectName = data.versioning.defectName || data.defectName;
        data.priority = data.versioning.priority || data.priority;
        data.isValid = data.versioning.isValid || data.isValid;
        data.bbox = data.versioning.bbox || data.bbox;
      }
      setPinned(data)
    }
  }, [projectPredictions])
  useEffect(() => {
    const selectedSection = projectPredictions.map((eachObj) => mapIt(eachObj));
    const pickedSections = selectedSection.filter((eachSegment) =>
      SmartUtils.checkInRange(Number((eachSegment.initTime)).toFixed(5), Number((eachSegment.endTime)).toFixed(5), Number(currentTime).toFixed(5)));
    if (pickedSections.length > 0) {
      const sectionId = pickedSections[0].sectionId;
      setSectionIndex(sectionId)
    }
  }, [currentTime])
  useEffect(() => {
    localStorage.removeItem("mainDashboardVideoTime");
    localStorage.removeItem("editorVideoTime");
  }, [])
  useEffect(() => {
    const generateDefectAndMaskImageURLs = async () => {
      const defectUrls = {};
      const maskUrls = {};

      await Promise.all(timeList.map(async (defect) => {
        const { id, defectImagePath, maskPath } = defect;
        if (defectImagePath) {
          try {
            // Fetch defect image URL
            const defectStorageRef = storage.ref(defectImagePath);
            const defectDownloadURL = await defectStorageRef.getDownloadURL();
            defectUrls[id] = defectDownloadURL;
          } catch (error) {
            defectUrls[id] = null;
          }
        }

        if (maskPath) {
          try {
            // Fetch mask image URL
            const maskStorageRef = storage.ref(maskPath);
            const maskDownloadURL = await maskStorageRef.getDownloadURL();
            maskUrls[id] = maskDownloadURL;
          } catch (error) {
            maskUrls[id] = null;
          }
        }
      }));
      setDefectImageURLs(defectUrls);
      setMaskImageURLs(maskUrls);
    };

    generateDefectAndMaskImageURLs();
  }, [timeList]);
  useEffect(() => {
    const filteredList = wholeProjectDetections.flat().filter((each) => Math.floor(fps * currentTime) in (typeof each.allBboxes === 'string' ? JSON.parse(each.allBboxes) : each.allBboxes ?? {}));
    setFilteredFrameData(filteredList);
  }, [currentTime, projectPredictions]);
  useEffect(() => {
    if (showAddNewAssetLoader === false) {
      const data = addNewAssetMessage;
      var obj = null;
      if (data) {
        const assetMeta = getAssetMeta(data.assetId)
        obj = {
          pred: data,
          meta: assetMeta
        }
      }
      handlePinned(obj)
      setOpenAddNewAssetDialog(false);
    }
  }, [showAddNewAssetLoader])
  useEffect(() => {
    window.addEventListener("load", setVidDivDimen(vidDivRef?.current?.clientHeight))
    window.addEventListener("resize", getVidWindowSize, true);
  }, [editorMode, vidDivRef]);
  useEffect(() => {
    const divHeight = vidDivRef?.current?.clientHeight
    const divWidth = vidDivRef?.current?.clientWidth - 0;
    const imageHeight = originalVideoHeight;
    const imageWidth = originalVideoWidth;
    const asDiv = (divHeight / divWidth)
    const asImg = (imageHeight / imageWidth)
    const ratio = asDiv / asImg
    let svgWidth = 0
    let svgHeight = 0
    if (ratio >= 1) {
      svgWidth = divWidth
      svgHeight = asImg * divWidth
    } else {
      svgHeight = divHeight
      svgWidth = (1 / asImg) * divHeight
    }
    setSvgDimensions({
      width: svgWidth,
      height: svgHeight,
      scale: imageHeight / svgHeight,
      offsetTop: (divHeight - svgHeight) / 2,
      offsetLeft: (divWidth - svgWidth) / 2,
    })
  }, [imageDimensions])
  useEffect(() => {
    setImageDimensions({
      height: originalVideoHeight,
      width: originalVideoWidth,
    })
  }, [pinned]);
  useEffect(() => {
    setImageDimensions({
      height: originalVideoHeight,
      width: originalVideoWidth,
    })
  }, [vidDivDimen])
  useEffect(() => {
    if (pinned) {
      let newPred = Object.values(projectPredictions).filter((e) => e.id === pinned.pred.sectionId)[0]['detections'][pinned.pred.id];
      // Make sure newPred is extensible
      newPred = { ...newPred };
      newPred['cropb64'] = predictionCropb64List ? predictionCropb64List[newPred['id']] ? predictionCropb64List[newPred['id']] : '' : '';
      newPred['sectionId'] = pinned.pred.sectionId;
      newPred['roadSide'] = 'versioning' in newPred ? newPred['versioning']['roadSide'] : newPred['roadSide'];
      const assetId = 'versioning' in newPred ? newPred['versioning']['assetId'] : newPred['assetId'];
      const assetMeta = assetData.filter((e) => `${e.id}`.split('_')[0] === `${assetId}`.split('_')[0])[0]
      const obj = {
        pred: newPred,
        meta: assetMeta
      }
      setPinned(obj);
    }
  }, [projectPredictions])
  useEffect(() => {
    if (addDefectDoneAndSent === true) {
      handleCloseAddNewAssetDialog();
      props.clearAddDefectErrorAndMsg();
    }
  }, [addDefectDoneAndSent])
  useEffect(() => {
    if (addDistressArchiveIsDoneAndSent === true) {
      setOpenAlert(true);
    }
  }, [addDistressArchiveIsDoneAndSent])
  useEffect(() => {
    if (updateDistressArchiveIsDoneAndSent === true) {
      setOpenEditAlert(true);
    }
  }, [updateDistressArchiveIsDoneAndSent])
  useEffect(() => {
    if (basicFeatureArchiveIsDoneAndSent === true) {
      setOpenArchiveAlert(true);
      setDeleteBboxList([]);
      // setDeleteMultipleBbox(false);
      handleCloseDeleteDialog();
    }
  }, [basicFeatureArchiveIsDoneAndSent])
  useEffect(() => {
    if (!isNaN(currentTime)) {
      const videoTime = Math.floor(currentTime * fps);
      if (!isNaN(inputFrame)) {
        if (videoTime !== inputFrame) {
          setInputFrame(videoTime);
        }
      }
    }
  }, [currentTime])

  // FUNCTIONS
  const handleChangeSectionIndex = (event) => {
    setPinned(null);
    const sectionIndex = event.target.value;
    const segmentObj = mapIt(projectPredictions[sectionIndex]);
    setSectionIndex(sectionIndex);
    onSegmentChange(segmentObj);
  }
  function mapIt(sectionDocument) {
    let sortedDetections = Object.values(sectionDocument.GPS);
    // sortedDetections.sort((x, y) => x.frameTime - y.frameTime);
    if (sortedDetections.length == 0) {
      return {
        id: sectionDocument.id,
        sectionId: sectionDocument.sectionId,
        initTime: -1,
        endTime: -1,
      };
    }
    return {
      id: sectionDocument.id,
      sectionId: sectionDocument.sectionId,
      initTime: sortedDetections[0].time,
      endTime: sortedDetections.at(-1).time,
    };
  }
  const addNewBoundingBox = () => {
    setShowPointer(true);
    setIsAddingDistress(true);
  };
  const closeBoundingBox = () => {
    setShowPointer(false);
    setIsAddingDistress(false);
  }
  const onSegmentChange = (segmentObj) => {
    playerRef?.current?.pause()
    const startTime = (segmentObj.initTime);
    playerRef?.current?.seek(startTime)
  }
  const onCurrentTimeChange = (currentTime) => {
    setCurrentTime(Number(currentTime).toFixed(5))
  }
  const calculateQuality = (score) => {
    // 0: low ,1:med,2:high
    if (score === 0) {
      return 2
    }

    if (score === 1) {
      return 1
    }

    if (score === 2) {
      return 0
    }
  }
  const handleQuality = (index) => {
    setPinned(null);
    if (qualities.includes(index)) {
      setQualities([...qualities].filter((e) => e !== index))
    } else {
      setQualities([...qualities, index])
    }
  }
  const handleOpenAddNewAssetDialog = () => {
    props.removeAddNewAssetErrorAndMessage();
    setOpenAddNewAssetDialog(true);
  };
  const handleCloseAddNewAssetDialog = () => {
    setOpenAddNewAssetDialog(false);
  };
  const getAssetMeta = (assetId, debug = false) => {
    const assetMeta = assetData.filter((e) => `${e.id}`.split('_')[0] === `${assetId}`.split('_')[0])[0]
    if (debug) {
      // //////console.log(assetMeta, "getAssetMeta")
    }
    return assetMeta
  }
  const nextAsset = () => {
    if (assetIndex < timeList.length) {
      assetIndex = assetIndex + 1;
      const nextPred = timeList[assetIndex];
      // const nextMeta = assetData.filter((e) => `${e.id}`.split('_')[0] === `${nextPred.assetId}`.split('_')[0])[0]
      const obj = {
        pred: nextPred,
        // meta: nextMeta
      }
      handlePinned(obj);
    }
  }
  const previousAsset = () => {
    if (assetIndex >= 0) {
      assetIndex = assetIndex - 1;
      const nextPred = timeList[assetIndex];
      // const nextMeta = assetData.filter((e) => `${e.id}`.split('_')[0] === `${nextPred.assetId}`.split('_')[0])[0]
      const obj = {
        pred: nextPred,
        // meta: nextMeta
      }
      handlePinned(obj);
    }
  }
  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleNextFrame = () => {
    const secondPerFrame = 1 / fps
    setInputFrame((prevFrame) => prevFrame + secondPerFrame)
    playerRef.current.forward(secondPerFrame);
  }
  const handlePrevFrame = () => {
    const secondPerFrame = 1 / fps
    setInputFrame((prevFrame) => prevFrame - secondPerFrame)
    playerRef.current.replay(secondPerFrame);
  }
  const handleEditorNextFrame = () => {
    const secondPerFrame = 1 / fps
    setInputFrame((prevFrame) => prevFrame + secondPerFrame)
    comparePlayerRef.current.forward(secondPerFrame);
  }
  const handleEditorPrevFrame = () => {
    const secondPerFrame = 1 / fps;
    setInputFrame((prevFrame) => prevFrame - secondPerFrame)
    comparePlayerRef.current.replay(secondPerFrame);
  }
  const handleInputFrame = (e) => {
    const newFrame = parseInt(e.target.value);
    if (newFrame < 0) {
      setInputFrame(0);
      comparePlayerRef.current.seek(0);
    }
    else if (newFrame > totalFrames) {
      // alert(`Frame can be between 0 and ${totalFrames}`)
      setInputFrame(totalFrames / fps)
      comparePlayerRef?.current?.seek(totalFrames / fps);
    }
    else {
      setInputFrame(newFrame / fps);
      comparePlayerRef?.current?.seek(newFrame / fps);
    }
  }
  const handlePinned = (data) => {
    const check = data === null ? false : pinned === null ? false : data.pred.id === pinned.pred.id
    playerRef.current?.pause()
    setPinned(check ? null : data)
    if (data) {
      handleSeekTo(Math.floor(data.pred.frameTime))
    }
  }
  const getVidWindowSize = () => {
    const newHeight = vidDivRef?.current?.clientHeight;
    const newWidth = vidDivRef?.current?.clientWidth;
    setVidDivDimen({
      height: newHeight,
      width: newWidth
    });
  };
  const handleSeekTo = (timeSeek) => {
    // + (1 / fps)
    playerRef?.current?.seek(timeSeek)
  }
  const handleFastDistress = () => {
    setEditorMode(true);
    setIsEditMode(false);
    setSelectedId(null);
    setRectangles([]);
  }
  const handleDelete = () => {
    const updatedRectangles = rectangles.filter(rect => rect.id !== selectedRecId);
    const data = filteredFrameData.filter((data) => data.id === selectedRecId);
    if (data.length > 0) {
      props.moveBasicImagingFeatureToArchive(proid, data[0].sectionId, data[0].id);
    }
    setRectangles(updatedRectangles);
    setSelectedRecId(null);
    setSelectedId(null);
    setOpenDistressDialog(false);
    setIsEditMode(false);
    if (isAddingDistress) {
      setShowPointer(true);
    }
  };
  return (
    <>
      <CustomAlert autoHideDuration={1500} open={showDeleteAlert} onClose={() => { setShowDeleteAlert(false) }} text={"Couldn't delete non-editable bounding box"} severity={"error"} />
      <CustomAlert open={openAlert} onClose={() => { setOpenAlert(false) }} text={addDistressArchiveMessage} severity={addDistressArchiveIsDoneAndSent ? "success" : "error"} />
      <CustomAlert open={openEditAlert} onClose={() => { setOpenEditAlert(false) }} text={updateDistressArchiveMessage} severity={updateDistressArchiveIsDoneAndSent ? "success" : "error"} />
      <CustomAlert open={openArchiveAlert} onClose={() => { setOpenArchiveAlert(false) }} text={basicFeatureArchiveMessage} severity={basicFeatureArchiveIsDoneAndSent ? "success" : "error"} />
      <SimpleDialogBox
        showError={basicFeatureArchiveIsDoneAndSent === true ? false : true}
        errorMessage={basicFeatureArchiveIsDoneAndSent === true ? "" : basicFeatureArchiveMessage}
        open={deleteDialog}
        onClose={handleCloseDeleteDialog}
        title='Archive distresses'
        bodyWidget={<>Are you sure you want to archive distresses?</>}
        showPositive
        width={1000}
        positiveButtonWidget={<>
          <Button size="medium" variant="contained"
            onClick={handleClearSelection}
            sx={{
              whiteSpace: "nowrap",
              marginRight: '8px',
              textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
              '&:hover': {
                backgroundColor: '#025196',
                color: '#FFFFFF'
              }
            }}>Remove selections</Button>
          {showBasicFeatureArchiveLoader ?
            <>
              <Button variant="contained" disabled sx={{
                textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                "&.Mui-disabled": {
                  backgroundColor: '#025196',
                  color: '#FFFFFF'
                }
              }}>
                <CircularProgress variant="indeterminate" size={15} sx={{ color: 'black' }} /><span style={{ paddingLeft: '15px' }}>Archiving...</span>
              </Button>
            </>
            :
            <Button size="medium" variant="contained"
              onClick={() => props.moveBasicImagingMultipleFeatureToArchive(proid, deleteBboxList)}
              sx={{
                whiteSpace: "nowrap",
                textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                '&:hover': {
                  backgroundColor: '#025196',
                  color: '#FFFFFF'
                }
              }}>Archive</Button>
          }
        </>
        }
      />


      <div style={{ height: '100vh', width: '100vw', backgroundColor: '#E1E3E2' }}>
        {/* EDITOR MODE OF DISTRESS */}
        {editorMode ? (
          <div style={{ height: '100vh', width: '100vw' }}>

            <div style={{ height: '10vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <div>
                {isEditMode ? <></> : <CustomizedDividers
                  onOpenDeleteDialog={handleOpenDeleteDialog}
                  deleteBboxList={deleteBboxList}
                  deleteMultipleBbox={deleteMultipleBbox}
                  onSelectDelete={handleSelectDelete}
                  onDeselectDelete={handleDeselectDelete}
                  inputFrame={inputFrame}
                  setCurrentTime={setCurrentTime}
                  totalFrames={totalFrames}
                  addNewBoundingBox={addNewBoundingBox}
                  playerRef={comparePlayerRef}
                  currentTime={currentTime}
                  fps={fps}
                  handlePrevFrame={handleEditorPrevFrame}
                  setPlaybackRate={setPlaybackRate}
                  playbackRate={playbackRate}
                  handleNextFrame={handleEditorNextFrame}
                  setEditorMode={setEditorMode}
                  closeBoundingBox={closeBoundingBox}
                  isAddingDistress={isAddingDistress}
                  onFrameChange={handleInputFrame}
                  dataInfo={basicImagingData.dataInfo}
                />}
              </div>
              {isEditMode ? <div>
                <EditOptions
                  drProjectData={drProjectData}
                  handleDelete={handleDelete}
                  fps={fps}
                  allPolyPoints={gpsDataCombined}
                  currentTime={currentTime}
                  projectPredictions={projectPredictions}
                  svgDimensions={svgDimensions}
                  originalVideoWidth={originalVideoWidth}
                  proid={proid}
                  rectangles={rectangles}
                  addDistressV2={props.addDistressV2}
                  updateDistressV2={props.updateDistressV2}
                  fieldValues={fieldValues} isAddingDistress={isAddingDistress} setShowPointer={setShowPointer}
                  setFieldValues={setFieldValues} setSelectedId={setSelectedId}
                  setIsEditMode={setIsEditMode} handleChange={handleChange}
                  dataInfo={basicImagingData.dataInfo}
                />

              </div> : <></>}
            </div>
            <div id='asset-player' style={{ padding: `${0}px`, height: `calc(${90}vh  - ${0}px - ${0 * 2}px - ${0 * 2}px))`, backgroundColor: '' }}>
              <Card elevation={0} sx={{ height: `calc(${90}vh  - ${0}px - ${0 * 2}px - ${0 * 2}px))`, backgroundColor: '' }}>
                <div ref={vidDivRef} style={{ height: `calc(${90}vh  - ${0}px - ${0 * 2}px - ${0 * 2}px)`, backgroundColor: 'transparent', width: '100%' }}>
                  <EditorVideoPlayer
                    deleteMultipleBbox={deleteMultipleBbox}
                    onSelectDelete={handleDeleteBbox}
                    // videoPlayerState={videoPlayerState}
                    // setVideoPlayerState={setVideoPlayerState}
                    handleDelete={handleDelete}
                    selectedRecId={selectedRecId} setSelectedRecId={setSelectedRecId}
                    openDistressDialog={openDistressDialog}
                    setOpenDistressDialog={setOpenDistressDialog}
                    basicImagingData={basicImagingData}
                    showPointer={showPointer}
                    setShowPointer={setShowPointer}
                    setFieldValues={setFieldValues}
                    selectedId={selectedId}
                    setSelectedId={setSelectedId}
                    isEditMode={isEditMode}
                    setIsEditMode={setIsEditMode}
                    svgDimensions={svgDimensions}
                    rectangles={rectangles}
                    setRectangles={setRectangles}
                    fps={fps}
                    playbackRate={playbackRate}
                    playerRef={comparePlayerRef}
                    originalVideoWidth={originalVideoWidth}
                    startTime={0}
                    onCurrentTimeChange={onCurrentTimeChange}
                    vidDivRef={vidDivRef}
                    link={link}
                    filteredFrameData={filteredFrameData}
                  />
                </div>
              </Card>
            </div>
          </div>
        )
          : (
            // MAIN DASHBOARD
            <Grid container spacing={0}>
              <Grid item xs={7} sx={{ backgroundColor: 'transparent', height: `calc(100vh - ${0}px)`, padding: '' }}>
                {/* Topbar */}
                <div ref={headerRef} style={{ padding: `${overallCustomBorderPadding}px 0px ${overallCustomBorderPadding}px ${overallCustomBorderPadding}px`, backgroundColor: '' }}>
                  <PotholeTopbar
                    addNewBoundingBox={addNewBoundingBox}
                    playerRef={playerRef}
                    handlePrevFrame={handleEditorPrevFrame}
                    handleNextFrame={handleEditorNextFrame}
                    currentTime={currentTime}
                    fps={fps}
                    handleFastDistress={handleFastDistress}
                    editorMode={editorMode} setEditorMode={setEditorMode} handleChangeSectionIndex={handleChangeSectionIndex} sectionIndex={sectionIndex} predictions={projectPredictions} drProjectData={drProjectData} basicImagingDataInfo={basicImagingDataInfo}
                    startEndLocation={startEndLocation} proid={proid}
                    // detections={detections}
                    detections={wholeProjectDetections.flat()}
                    assetData={assetData} dataInfo={dataInfo} />
                </div>
                {/* pinned window */}
                <div style={{ height: `calc(100vh - ${headerHeight}px)`, backgroundColor: '' }}>
                  <div style={{ height: `calc(100vh  - ${headerHeight}px - ${overallCustomBorderPadding}px)`, paddingLeft: `${overallCustomBorderPadding}px`, paddingBottom: `${overallCustomBorderPadding}px`, backgroundColor: '' }}>
                    <Card elevation={4} sx={{ ...cardWhiteStyle, height: `calc(100vh  - ${headerHeight}px - ${overallCustomBorderPadding}px)` }}>

                      <div style={{ height: `${pinnedUiHeight}%`, backgroundColor: '', }}>
                        {
                          pinned === null ? <></> :
                            <PinnedPothole
                              isQuickEdit={isQuickEdit} setIsQuickEdit={setIsQuickEdit}
                              defectImageURLs={defectImageURLs}
                              basicFeatureArchiveIsDoneAndSent={basicFeatureArchiveIsDoneAndSent}
                              clearUpdateErrorAndMsg={props.clearUpdateErrorAndMsg}
                              clearArchiveErrorAndMsg={props.clearArchiveErrorAndMsg}
                              drProjectData={drProjectData}
                              updateDefectDoneAndSent={updateDefectDoneAndSent}
                              basicValidConfirmIsDoneAndSent={basicValidConfirmIsDoneAndSent}
                              clearValidConfirmErrorAndMsg={props.clearValidConfirmErrorAndMsg}
                              showUpdateDefectLoader={showUpdateDefectLoader}
                              showBasicValidConfirmLoader={showBasicValidConfirmLoader}
                              updateBasicImagingDefect={props.updateBasicImagingDefect}
                              basicImagingValidConfirm={props.basicImagingValidConfirm}
                              resetArchiveFeature={props.resetArchiveFeature}
                              basicImagingDataInfo={basicImagingDataInfo}
                              originalVideoHeight={originalVideoHeight}
                              originalVideoWidth={originalVideoWidth} proid={proid} qualities={qualities} handleQuality={handleQuality} timeList={timeList} handleClickOpenDialog={handleClickOpenDialog} openDialog={openDialog} handleCloseDialog={handleCloseDialog}
                              previousAsset={previousAsset} assetIndex={assetIndex} nextAsset={nextAsset}
                              handlePinned={handlePinned} fps={fps} realFps={realFps} link={link} pinned={pinned} gpsData={gpsData} gpsDataCombined={gpsDataCombined}
                              imageBase64={imageBase64}
                            />
                        }
                      </div>

                      <div style={{ height: `${tableHeight}%`, backgroundColor: '', }}>
                        <PotholeTable defectImageURLs={defectImageURLs} basicImagingData={basicImagingData}
                          disableHeader={false} playerRef={playerRef} pinned={pinned}
                          dataList={timeList} handlePinned={handlePinned} assetData={dataset}
                        />
                      </div>

                      <div style={{ height: `10%`, backgroundColor: '', display: 'flex', alignItems: 'center' }}>
                        <Divider light />
                        <Grid container>
                          {/* <Grid item xs={7}>
                            <PotholeTableFilters qualities={qualities} handleQuality={handleQuality} />
                          </Grid> */}
                          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                            <Tooltip title="Add new distress" placement="top">
                              <IconButton
                                disabled={!canAddDefectBasic}
                                onClick={handleOpenAddNewAssetDialog}
                                sx={{
                                  marginRight: '10px',

                                }}
                              >
                                <AddCircleIcon />
                              </IconButton>
                            </Tooltip>
                            {canUnarchiveDefectBasic ? (
                              <ArchivedPotholeViewer
                                basicImagingData={basicImagingData}
                                assetData={assetData}
                                detections={originalDetections}
                                proid={proid}
                              />
                            ) : (
                              <></>
                            )}

                            {/* {pinned ? (
                              <Tooltip title={showMaskImage ? "Show map" : "Show mask image"} arrow>
                                <IconButton
                                  onClick={() => setShowMaskImage(!showMaskImage)}
                                  sx={{
                                    marginRight: '10px',

                                  }}
                                >
                                  {showMaskImage ? <MapIcon /> : <BrokenImageIcon />}
                                </IconButton>
                              </Tooltip>
                            ) : (
                              <></>
                            )} */}
                          </Grid>
                        </Grid>
                      </div>
                    </Card>
                  </div>
                </div>
              </Grid>
              <Grid item xs={5} sx={{ backgroundColor: 'transparent', height: `calc(100vh - ${0}px)` }}>
                <div style={{ margin: '0px', backgroundColor: 'transparent', height: `calc(100vh - ${0}px - ${compareWindowMargin * 2}px)` }}>{

                  <Card elevation={0} sx={{ ...cardWhiteStyle, backgroundColor: '#E1E3E2', height: `calc(100vh -  ${compareWindowMargin * 2}px)`, }}>
                    {/* Video player */}
                    <div id='asset-player' style={{ padding: `${assetVideoPlayerPadding}px`, height: `calc(${videoHeight}vh - ${0 * 2}px - ${0 * 2}px))`, backgroundColor: '' }}>
                      <Card elevation={0} sx={{ ...cardWhiteStyle, height: `calc(${videoHeight}vh - ${0 * 2}px - ${0 * 2}px))`, backgroundColor: '' }}>
                        {/* AssetFrameList */}
                        {/* <div style={{ height: `calc(${videoHeight}vh - ${assetVideoPlayerPadding * 2}px - ${0 * 2}px )`, backgroundColor: 'transparent', width: '12%', float: 'left', padding: '10px' }}>
                          <PotholeFrameList defectImageURLs={defectImageURLs} basicImagingData={basicImagingData} handlePinned={handlePinned} playerRef={playerRef} detections={timeList} pinned={pinned} fps={fps} />
                        </div> */}
                        {/* AssetVideoPlayer */}
                        <div ref={vidDivRef} style={{ height: `calc(${videoHeight}vh - ${assetVideoPlayerPadding * 2}px - ${0 * 2}px)`, backgroundColor: 'transparent', width: '100%', float: 'right' }}>
                          <div className="container" style={{ padding: '0px' }}>
                            <div className="inner" style={{ zIndex: 997 }}>
                              <PotholeVideoPlayer
                                projectPredictions={projectPredictions}
                                onCurrentTimeChange={onCurrentTimeChange}
                                clearAddDefectErrorAndMsg={props.clearAddDefectErrorAndMsg}
                                showAddDefectLoader={showAddDefectLoader} addDefectDoneAndSent={addDefectDoneAndSent}
                                addDefectMessage={addDefectMessage}
                                addBasicImagingDefect={props.addBasicImagingDefect}
                                fps={fps}
                                handleNextFrame={handleNextFrame} handlePrevFrame={handlePrevFrame} playerRef={playerRef}
                                originalVideoHeight={originalVideoHeight}
                                originalVideoWidth={originalVideoWidth}
                                allPolyPoints={gpsDataCombined}
                                proid={proid}
                                basicImagingDataInfo={basicImagingDataInfo}
                                openAddNewAssetDialog={openAddNewAssetDialog}
                                handleCloseAddNewAssetDialog={handleCloseAddNewAssetDialog}
                                link={link} height={vidDivRef.current === null ? 0 : vidDivRef.current?.clientHeight}
                                startTime={0} control={pinned} disableControlsFully={pinned} />
                            </div>
                            {pinned ?
                              <div className="inner" style={{ zIndex: 998, width: vidDivRef.current === null ? 0 : vidDivRef.current?.clientWidth }}>
                                <div style={{
                                  height: '100%', width: '100%', backgroundColor: '', textAlign: 'center', display: 'flex',
                                  justifyContent: 'center', alignItems: 'center', marginTop: '-0px'
                                }}>
                                  <svg height={svgDimensions.height} width={svgDimensions.width}

                                    style={{
                                      backgroundColor: 'rgba(34,55,245, 0.0)',
                                    }}
                                  >

                                    {
                                      pinned !== null ? isCurrentFramePinned ? <DrawBBox xy={[...pinned?.pred?.bbox].splice(0, 2)} wh={[...pinned?.pred?.bbox].splice(2, 4)} shouldScale scale={svgDimensions.scale} />
                                        : <></> : <></>
                                    }
                                  </svg>
                                </div>
                              </div> : <></>}
                          </div>
                        </div>
                      </Card>
                    </div>
                    {/* Asset map */}
                    <div id='asset-map'
                      style={{
                        padding: `0px ${overallCustomBorderPadding}px ${overallCustomBorderPadding}px 
              ${overallCustomBorderPadding}px`,
                        height: `calc(${mapHeight}vh - ${overallCustomBorderPadding * 1}px - ${compareWindowMargin * 2}px))`, backgroundColor: 'transparent'
                      }}>

                      <Card elevation={4} sx={{ ...cardWhiteStyle, background: 'white' }}>
                        <div style={{ height: `calc(${mapHeight}vh - ${overallCustomBorderPadding * 1}px - ${compareWindowMargin * 2}px)` }}>
                          {showMaskImage && pinned ?
                            <img src={maskImageURLs[pinned.pred.id]} alt='No mask image found!' width="100%" style={{ objectFit: 'contain' }} /> : gpsDataCombined.length > 0 ?
                              <PotholeMap basicImagingData={basicImagingData} fps={fps} playerRef={playerRef} zoom={15} dataList={timeList} assetData={dataset} polyPoints={[wholeProjectGps]} allPolyPoints={gpsDataCombined} center={gpsDataCombined[-1]} pinned={pinned} handleSeekTo={handleSeekTo} handlePinned={handlePinned} />
                              : <></>}
                        </div>
                      </Card>

                    </div>
                  </Card>
                }

                </div>
              </Grid >
            </Grid >
          )}
      </div >
    </>
  )
}
const mapStateToProps = (state, ownProps) => {
  const { proid } = ownProps
  return {
    project: state.project,
    datastore: state.datastore,
    drProjectData: get(
      state.firestore.data,
      `DrProject.${proid}`
    ),

  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    removeAddNewAssetErrorAndMessage: () => dispatch(removeAddNewAssetErrorAndMessage()),
    resetArchiveFeature: () => dispatch(resetArchiveFeature()),
    basicImagingValidConfirm: (proid, sectionId, detectionId) => dispatch(basicImagingValidConfirm(proid, sectionId, detectionId)),
    updateBasicImagingDefect: (proid, sectionId, detectionId, priority, defectType, validity, bbox) => dispatch(updateBasicImagingDefect(proid, sectionId, detectionId, priority, defectType, validity, bbox)),
    clearUpdateErrorAndMsg: () => dispatch(clearUpdateErrorAndMsg()),
    clearArchiveErrorAndMsg: () => dispatch(clearArchiveErrorAndMsg()),
    clearValidConfirmErrorAndMsg: () => dispatch(clearValidConfirmErrorAndMsg()),
    clearAddDefectErrorAndMsg: () => dispatch(clearAddDefectErrorAndMsg()),
    addBasicImagingDefect: (proid, sectionId, priority, defectType, bbox, gps, frameNumber) => dispatch(addBasicImagingDefect(proid, sectionId, priority, defectType, bbox, gps, frameNumber)),
    addDistressV2: (proid, sectionId, bbox, defectType, frameNumber, gps, type) => dispatch(addDistressV2(proid, sectionId, bbox, defectType, frameNumber, gps, type)),
    updateDistressV2: (proid, sectionId, bbox, defectType, frameNumber, gps, type, defectId) => dispatch(updateDistressV2(proid, sectionId, bbox, defectType, frameNumber, gps, type, defectId)),
    moveBasicImagingFeatureToArchive: (proid, sectionId, detectionId) => dispatch(moveBasicImagingFeatureToArchive(proid, sectionId, detectionId)),
    moveBasicImagingMultipleFeatureToArchive: (proid, detections) => dispatch(moveBasicImagingMultipleFeatureToArchive(proid, detections)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PotholeInferenceDashboard);
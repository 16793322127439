import { Button, CircularProgress, IconButton, List, ListItem, ListItemIcon, ListItemText, Stack, Toolbar, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import Pagination from '@mui/material/Pagination';
import { useMediaQuery } from '@mui/material';
import SimpleDialogBox from "../../../cidgets/SimpleDialogBox";
import RouteIcon from '@mui/icons-material/Route';
import AutorenewIcon from '@mui/icons-material/Autorenew';
const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        height: 'calc(100% - 0px)',
    },
    listItem: {
        display: 'flex',
        alignItems: 'center',
    },
    weatherIcon: {
        width: '30px',
        height: '30px',
        marginRight: '10px',
    },
    temperatureContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    temperature: {
        fontSize: '12px',
        color: '#f36f6f',
        margin: 0,
        marginRight: '10px',
    },
}));
const RoutePlannerList = ({ removeRerunRouteCalculationErrorAndMessage, rerunRouteCalculationToDbMessage, rerunRouteCalculationToDbIsDoneAndSent, rerunRouteCalculationToDbLoader, openRerunRouteDialog, setOpenRerunRouteDialog, setIndvidualSubRouteData, setSelectedRouteData, deleteRouteLoader,
    openDeleteRouteDialog, setOpenDeleteRouteDialog, deleteRoute, rerunRouteCalculationToDb, coid, RoutePlannerData, showRoute,
    setSubRouteDataArray }) => {
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const isMediumScreen = useMediaQuery('(min-width:601px) and (max-width:960px)');
    const isLargeScreen = useMediaQuery('(min-width:961px)');
    const classes = useStyles();
    const itemsPerPage = isSmallScreen ? 1 : isMediumScreen ? 2 : isLargeScreen ? 6 : 7;
    const [currentPage, setCurrentPage] = useState(1);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = Object.values(RoutePlannerData).slice(indexOfFirstItem, indexOfLastItem);
    const [routeId, setRouteId] = useState(null);
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };
    const handleOpenDialog = (id) => {
        setRouteId(id);
        setOpenDeleteRouteDialog(true);
    }
    const handleCloseDialog = () => {
        setRouteId(null);
        setOpenDeleteRouteDialog(false);
    }
    const handleOpenRerunDialog = (id) => {
        removeRerunRouteCalculationErrorAndMessage();
        setRouteId(id);
        setOpenRerunRouteDialog(true);
    }
    const handleCloseRerunDialog = () => {
        setRouteId(null);
        setOpenRerunRouteDialog(false);
    }
    useEffect(() => {
        if (deleteRouteLoader === false) {
            handleCloseDialog();
        }
    }, [deleteRouteLoader])
    useEffect(() => {
        if (rerunRouteCalculationToDbIsDoneAndSent === true) {
            handleCloseRerunDialog();
        }
    }, [rerunRouteCalculationToDbIsDoneAndSent])
    if (RoutePlannerData === null || RoutePlannerData === undefined) {
        return <></>
    }
    const handleRouteClick = (result, encodedGeometry, item) => {
        setSelectedRouteData(item)
        showRoute(encodedGeometry);
        setSubRouteDataArray(result);
        setIndvidualSubRouteData([]);
    };
    const convertSecondsToMinutes = (seconds) => {
        const duration = moment.duration(seconds, 'seconds');
        return duration.humanize();
    };

    const formatDistance = (value, unit) => {
        return `${Number(value).toFixed(2)} ${unit}`;
    };

    // Utility function to convert meters to kilometers
    const metersToKilometers = (meters) => {
        const kilometers = meters / 1000;
        return formatDistance(kilometers, 'km');
    };

    const calculateDistance = (distance) => {
        return distance > 1000 ? metersToKilometers(distance) : formatDistance(distance, 'm');
    };
    return (
        <>
            <SimpleDialogBox
                open={openDeleteRouteDialog}
                onClose={handleCloseDialog}
                title="Delete route"
                shouldTitleAppear={true}
                cancelText="Close"
                bodyWidget={
                    <>
                        Are your sure you want to delete this route?
                    </>
                }
                showPositive
                positiveButtonWidget={
                    <>
                        {
                            deleteRouteLoader ?
                                <>
                                    < Button variant="contained" disabled sx={{
                                        textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                                        "&.Mui-disabled": {
                                            backgroundColor: '#025196',
                                            color: '#FFFFFF'
                                        }
                                    }}>
                                        <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} /><span style={{ paddingLeft: '15px' }}>Deleting...</span>
                                    </Button>
                                </>
                                :
                                <Button size="medium" variant="contained"
                                    onClick={() => deleteRoute(coid, routeId)}
                                    sx={{
                                        textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                                        '&:hover': {
                                            backgroundColor: '#025196',
                                            color: '#FFFFFF'
                                        }
                                    }}>Delete</Button>
                        }
                    </>
                }
                width='600'
            />
            <SimpleDialogBox
                open={openRerunRouteDialog}
                onClose={handleCloseRerunDialog}
                title="Recalculate route"
                shouldTitleAppear={true}
                cancelText="Close"
                bodyWidget={
                    <>
                        Are your sure you want to recalculate this route?
                    </>
                }
                showError={rerunRouteCalculationToDbIsDoneAndSent === false ? true : false}
                errorMessage={rerunRouteCalculationToDbIsDoneAndSent === false ? rerunRouteCalculationToDbMessage : ''}
                showPositive
                positiveButtonWidget={
                    <>
                        {
                            rerunRouteCalculationToDbLoader ?
                                <>
                                    < Button variant="contained" disabled sx={{
                                        textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                                        "&.Mui-disabled": {
                                            backgroundColor: '#025196',
                                            color: '#FFFFFF'
                                        }
                                    }}>
                                        <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} /><span style={{ paddingLeft: '15px' }}>Recalculating..</span>
                                    </Button>
                                </>
                                :
                                <Button size="medium" variant="contained"
                                    onClick={() => rerunRouteCalculationToDb(routeId, coid)}
                                    sx={{
                                        textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                                        '&:hover': {
                                            backgroundColor: '#025196',
                                            color: '#FFFFFF'
                                        }
                                    }}>Recalulate</Button>
                        }
                    </>
                }
                width='700'
            />
            <div className={classes.root}>
                <List component="nav" sx={{ height: '100%' }}>
                    {currentItems.length > 0 ? currentItems.map((item, index) => (
                        <ListItem button key={index} className={classes.listItem}>
                            <ListItemText
                                onClick={() => handleRouteClick(item.result, item.encodedGeometry, item)}
                                primary={<Typography variant="body1" sx={{ fontWeight: 'bold', color: '#000000' }}>
                                    {/* {`Route ${index + indexOfFirstItem + 1}`} */}
                                    {item.title ?? 'Unknown'}
                                </Typography>}
                                secondary={
                                    <Typography variant="body2" sx={{ color: '#000000' }}>
                                        {`This route encompasses a distance of ${calculateDistance(item.distance.value)} and is associated with a duration of ${convertSecondsToMinutes(item.duration.value)}.`}
                                    </Typography>
                                }
                            />
                            <Tooltip title="Delete route" arrow>
                                <ListItemIcon sx={{ background: '', display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                                    <IconButton
                                        onClick={() => { handleOpenDialog(item.id) }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItemIcon>
                            </Tooltip>
                          <Tooltip title="Recalculate route" arrow>
                          <ListItemIcon sx={{ background: '', display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                                <IconButton
                                    onClick={() => { handleOpenRerunDialog(item.id) }}
                                >
                                    <AutorenewIcon />
                                </IconButton>
                            </ListItemIcon>
                          </Tooltip>
                        </ListItem>
                    )) : <div style={{ width: '100%', height: '70vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Stack direction="column">
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {/* <img src={EmptyFolder} alt='Empty Folder' width='150px' height='auto' /> */}
                                <RouteIcon sx={{ fontSize: '70px', filter: 'invert(5%) sepia(58%) saturate(2690%) hue-rotate(221deg) brightness(93%) contrast(116%)', }} />
                            </div>
                            <Toolbar>
                                <Typography align="center" style={{ fontSize: '16px', padding: '40px' }}>
                                    No existing route found. To begin, create a new route
                                </Typography>
                            </Toolbar>
                        </Stack>
                    </div >}
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5px' }}>
                        {Math.ceil(Object.values(RoutePlannerData).length / itemsPerPage) > 1 ? <Pagination
                            count={Math.ceil(Object.values(RoutePlannerData).length / itemsPerPage)}
                            page={currentPage}
                            onChange={handlePageChange}
                        /> : <></>}
                    </div>

                </List>

            </div >
        </>
    );
}

export default RoutePlannerList
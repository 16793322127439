import {
  Switch,
  Button,
  CircularProgress,
  Dialog,
  FormControl,
  Grid,
  IconButton,
  ListItem,
  ListItemText,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import SmartUtils from "../../../../../helpers/SmartUtils";
import commonNames from "../../../../../constants/commonNames.json";
import SimpleDialogBox from "../../../../cidgets/SimpleDialogBox";
import StyledOutlineButton from "../../../../cidgets/StyledOutlineButton";
import DistressDashboardV2 from "./DistressDashboardV2";
import ImageAnnotatorV3 from "./ImageAnnotatorV3";
import DepthAnnotator from "./DepthAnnotator";
import StichedAnnotator from "./StichedAnnotator";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import InfoIcon from "@mui/icons-material/Info";
import {
  depthCrossSectionalProfile,
  saveDepthCrossSectionalPoints,
} from "../../../../../store/actions/projectActions";
import { connect } from "react-redux";
import LineChart from "./LineChart";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useNavigate, useParams } from "react-router-dom";
import CustomProgress from "../../../../cidgets/CustomProgress";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import { useAccessControlContext } from "../../../../../contexts/AccessControlProvider";
import AccessControlAccountError from "../../../../cidgets/AccessControlAccountError";
import AllDistressList from "./AllDistressList";

const SegmentShifterV2 = (props) => {
  const {
    userData,
    isGroupData = false,
    handleSeekTo,
    isWholeDistressVisualization,
    handleDataVisualizationChange,
    distressPinned,
    setDistressPinned,
    project,
    saveDepthCrossSectionalPoints,
    depthCrossSectionalProfile,
    isDepthCrossSectional,
    handleDepthCrossSectional,
    handleCloseDepthCrossSectional,
    segmentIndex,
    handleSectionChange,
    sections,
    setSections,
    crossSectionalPoints,
    setCrossSectionalPoints,
    defaultCrossSectionalPoints,
    setSegmentIndex,
    drProjectData,
    proid,
    playerRef,
    predictions,
    pinned,
    FPS,
    link,
    originalVideoHeight,
    originalVideoWidth,
    overallCustomBorderPadding,
    fps = 30,
    currentTime = 0,
    setPinned,
    conditionMethod,
    datastore,
    selectVideo = null,
  } = props;
  const { distressData } = datastore;
  const { permissions, role } = useAccessControlContext();
  const {
    canViewCrossSectionDistress,
    canSaveCrossSectionDistress,
    canSeeHighResViewerDistress,
    canShowMasksDistress,
    canViewSectionDetailsDistress,
  } = permissions;
  const { secId } = useParams();
  let navigate = useNavigate();
  const { projectType, typeOfRoad, status } = drProjectData;
  const isAdvanceProjectType = projectType === 4 ? true : false;
  const { saveDepthCrossSectionalPointsLoader } = project;
  const [defectNameList, setDefectNameList] = useState([]);
  const [prevSectionId, setPrevSectionId] = useState(null);
  const [polygonRectangleList, setPolygonRectangleList] = useState([]);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [sectionOverviewDialog, setSectionOverviewDialog] =
    React.useState(false);
  const [sectionBBox, setSectionBBox] = useState([]);
  const [showMaskImage, setShowMaskImage] = useState(false);
  const [openFullscreen, setOpenFullscreen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(null);
  const [savePoints, setSavePoints] = useState();
  const [linePoints, setLinePoints] = useState(crossSectionalPoints);
  const [isSectionNotProccessed, setIsSectionNotProccessed] = useState(false);
  const [isSectionRunning, setIsSectionRunning] = useState(false);
  const open = Boolean(menuOpen);
  let detectionsBBox = [];
  let defectNames = [];
  let rectangleList = [];
  const { downSamplingData } = project;

  function getCorner(pivotX, pivotY, diffX, diffY) {
    const distance = Math.sqrt(diffX * diffX + diffY * diffY);

    const angle = Math.atan2(diffY, diffX);

    const x = pivotX + distance * Math.cos(angle);
    const y = pivotY + distance * Math.sin(angle);

    return [x, y];
  }
  const bbox = sectionBBox.map((coords, index) => {
    let fill;
    const defectName = defectNameList[index];
    const isRectangle = polygonRectangleList[index];
    fill = SmartUtils.detectionColor(defectName);

    if (isRectangle === false) {
      const pairs = [];
      for (let i = 0; i < coords.length; i += 2) {
        pairs.push([coords[i], coords[i + 1]]);
      }
      return { defectName, fill, pairs };
    } else {
      const x = coords[0];
      const y = coords[1];
      const width = coords[2];
      const height = coords[3];

      const topLeft = getCorner(x, y, 0, 0);
      const topRight = getCorner(x, y, width, 0);
      const bottomRight = getCorner(x, y, width, height);
      const bottomLeft = getCorner(x, y, 0, height);

      const pairs = [topLeft, topRight, bottomRight, bottomLeft];

      return { defectName, fill, pairs };
    }
  });

  // //////console.log(bbox, 'ali')

  const handleClickOpen = () => {
    setOpenFullscreen(true);
    handleMenuClose();
  };
  const handleClickClose = () => {
    setOpenFullscreen(false);
  };
  const handleMenuClick = (event) => {
    setMenuOpen(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuOpen(null);
  };
  const handleBoxChange = (newValue) => {
    // //////console.log(newValue, "bbox coordinates");
  };

  // ReadMe: this useeffect is used for crossectional things for time.
  useEffect(() => {
    if (Number(secId) !== segmentIndex) {
      if (Object.values(predictions)[Number(secId)]) {
        const segmentId = Number(secId);
        const currentSection = Object.values(predictions)[segmentId];
        const crossSectionalParameters =
          currentSection?.crossSectionalParameters ?? false;
        const sectionId = currentSection["sectionId"];
        const points = {
          x1: Number(crossSectionalPoints[0]),
          y1: Number(crossSectionalPoints[1]),
          x2: Number(crossSectionalPoints[2]),
          y2: Number(crossSectionalPoints[3]),
        };
        depthCrossSectionalProfile(
          crossSectionalParameters
            ? {
                x1: Number(crossSectionalParameters["x1"]),
                y1: Number(crossSectionalParameters["y1"]),
                x2: Number(crossSectionalParameters["x2"]),
                y2: Number(crossSectionalParameters["y2"]),
              }
            : points,
          proid,
          sectionId
        );
      } else {
        navigate(`/home/pr/${proid}/${0}`);
        handleSectionChange({ target: { value: 0 } });
      }
    } else {
      const currentSection = Object.values(predictions)[segmentIndex];
      const crossSectionalParameters =
        currentSection?.crossSectionalParameters ?? false;
      const sectionId = currentSection && currentSection["sectionId"];
      const points = {
        x1: Number(crossSectionalPoints[0]),
        y1: Number(crossSectionalPoints[1]),
        x2: Number(crossSectionalPoints[2]),
        y2: Number(crossSectionalPoints[3]),
      };
      depthCrossSectionalProfile(
        crossSectionalParameters
          ? {
              x1: Number(crossSectionalParameters["x1"]),
              y1: Number(crossSectionalParameters["y1"]),
              x2: Number(crossSectionalParameters["x2"]),
              y2: Number(crossSectionalParameters["y2"]),
            }
          : points,
        proid,
        sectionId
      );
    }
  }, []);
  useEffect(() => {
    setIsSectionNotProccessed(false);
    setIsSectionRunning(false);
    let pickedSections = undefined;
    const frameNum =
      Math.round(FPS * currentTime) < 0 ? 0 : Math.round(FPS * currentTime);
    const frames = drProjectData.frames ?? 0;
    const groupVideosInfo = drProjectData.groupVideosInfo ?? null;

    const frameOffset =
      groupVideosInfo && groupVideosInfo.length > 1 && selectVideo
        ? groupVideosInfo
            .filter(
              (eachVideoObj) => eachVideoObj.groupId < selectVideo.groupId
            )
            .reduce((acc, obj) => acc + obj.frames, 0)
        : 0;
    if (downSamplingData) {
      // if downsampling exist
      if (downSamplingData.isDoneAndSent === true) {
        if (downSamplingData.downsampleIndexes.length > 0) {
          const finalSectionId = getSectionInfo(
            downSamplingData,
            predictions,
            frameNum + frameOffset,
            frames
          );
          pickedSections =
            finalSectionId !== undefined
              ? Object.values(predictions).filter(
                  (obj) => obj.id === finalSectionId
                )
              : undefined;
          if (finalSectionId === "-1") {
            setIsSectionRunning(false);
            setIsSectionNotProccessed(true);
          } else if (finalSectionId === "-2") {
            setIsSectionNotProccessed(false);
            setIsSectionRunning(true);
          } else if (finalSectionId === undefined) {
            setIsSectionNotProccessed(false);
            setIsSectionRunning(true);
          } else {
            setIsSectionNotProccessed(false);
            setIsSectionRunning(false);
            // Readme: if some enter wrong section id from url this cond will be used for this.
            if (Object.values(predictions)[Number(secId)]) {
              const currentSection = pickedSections[0];
              const crossSectionalParameters =
                currentSection?.crossSectionalParameters ?? false;
              const pickedIndex =
                Object.values(predictions).indexOf(currentSection);
              // navigate(`/home/pr/${proid}/${pickedIndex}`);
              setPinned(currentSection);
              setSegmentIndex(pickedIndex);
              setSections(pickedIndex);
              let currentSectionDetections = Object.values(
                currentSection.detections
              );
              currentSectionDetections =
                currentSectionDetections &&
                currentSectionDetections
                  .filter(
                    (eachDetection) => Object.keys(eachDetection).length > 0
                  )
                  .filter((eachDetection) => {
                    return eachDetection.archived
                      ? eachDetection.archived === "0"
                      : eachDetection;
                  });
              currentSectionDetections.sort((a, b) => {
                return isGroupData
                  ? a.groupFrameNumber - b.groupFrameNumber
                  : a.frameNumber - b.frameNumber;
              });
              currentSectionDetections &&
                currentSectionDetections.forEach((eachData) => {
                  detectionsBBox = [
                    ...detectionsBBox,
                    "versioning" in eachData
                      ? "bbox" in eachData["versioning"]
                        ? eachData["versioning"]["bbox"]
                        : eachData["bbox"]
                      : eachData["bbox"],
                  ];
                  defectNames = [
                    ...defectNames,
                    "versioning" in eachData
                      ? "defectName" in eachData["versioning"]
                        ? eachData["versioning"]["defectName"]
                        : eachData["defectName"]
                      : eachData["defectName"],
                  ];
                  rectangleList = [
                    ...rectangleList,
                    "versioning" in eachData
                      ? "isRectangle" in eachData["versioning"]
                        ? eachData["versioning"]["isRectangle"] ?? true
                        : eachData["isRectangle"] ?? true
                      : eachData["isRectangle"] ?? true,
                  ];

                  // const isRectangle = eachData.isRectangle;
                  // const rectangleCondCheck = isRectangle === undefined || isRectangle === true ? true : false;
                  // if (rectangleCondCheck) {
                  //   detectionsBBox = [...detectionsBBox, 'versioning' in eachData ? 'bbox' in eachData['versioning'] ? eachData['versioning']['bbox'] : eachData['bbox'] : eachData['bbox']]
                  //   defectNames = [...defectNames, 'versioning' in eachData ? 'defectName' in eachData['versioning'] ? eachData['versioning']['defectName'] : eachData['defectName'] : eachData['defectName']]
                  // }
                });
              setSectionBBox(detectionsBBox);
              setDefectNameList(defectNames);
              setPolygonRectangleList(rectangleList);

              if (crossSectionalParameters) {
                const points = [
                  Number(crossSectionalParameters["x1"]),
                  Number(crossSectionalParameters["y1"]),
                  Number(crossSectionalParameters["x2"]),
                  Number(crossSectionalParameters["y2"]),
                ];
                setCrossSectionalPoints(points);
              } else {
                setCrossSectionalPoints(defaultCrossSectionalPoints);
              }
              const sectionId = currentSection["sectionId"];
              if (sectionId !== prevSectionId) {
                depthCrossSectionalProfile(
                  crossSectionalParameters
                    ? {
                        x1: Number(crossSectionalParameters["x1"]),
                        y1: Number(crossSectionalParameters["y1"]),
                        x2: Number(crossSectionalParameters["x2"]),
                        y2: Number(crossSectionalParameters["y2"]),
                      }
                    : {
                        x1: defaultCrossSectionalPoints[0],
                        y1: defaultCrossSectionalPoints[1],
                        x2: defaultCrossSectionalPoints[2],
                        y2: defaultCrossSectionalPoints[3],
                      },
                  proid,
                  sectionId
                );
                setPrevSectionId(sectionId);
              }
            } else {
              navigate(`/home/pr/${proid}/${0}`);
              handleSectionChange({ target: { value: 0 } });
            }
          }
        }
      }
      // if downsampling doesnot exist
      else {
        const pickedSections = Object.values(predictions).filter(
          (eachSegment) =>
            SmartUtils.checkInRange(
              Number(eachSegment.initIndex / FPS).toFixed(5),
              Number(eachSegment.endIndex / FPS).toFixed(5),
              Number(currentTime).toFixed(5)
            )
        );
        // //////console.log(pickedSections, "pickedSections");
        if (Number(secId) !== segmentIndex) {
          // //////console.log("indexx-1", Object.values(predictions)[Number(secId)],secId)
          if (Object.values(predictions)[Number(secId)]) {
            // //////console.log("indexx-2")
            const segmentId = Number(secId);
            const currentSection = Object.values(predictions)[segmentId];
            setPinned(currentSection);
            // //////console.log(currentSection, "indexx-3")
            if (currentSection) {
              const crossSectionalParameters =
                currentSection && currentSection?.crossSectionalParameters
                  ? currentSection?.crossSectionalParameters
                  : false;

              if (crossSectionalParameters) {
                const points = [
                  Number(crossSectionalParameters["x1"]),
                  Number(crossSectionalParameters["y1"]),
                  Number(crossSectionalParameters["x2"]),
                  Number(crossSectionalParameters["y2"]),
                ];
                setCrossSectionalPoints(points);
              } else {
                setCrossSectionalPoints(defaultCrossSectionalPoints);
              }
              let currentSectionDetections =
                currentSection && Object.values(currentSection?.detections);
              // //////console.log(currentSectionDetections, 'currentSectionDetections')
              currentSectionDetections = currentSectionDetections
                .filter(
                  (eachDetection) => Object.keys(eachDetection).length > 0
                )
                .filter((eachDetection) => {
                  return eachDetection.archived
                    ? eachDetection.archived === "0"
                    : eachDetection;
                });
              currentSectionDetections.sort((a, b) => {
                return isGroupData
                  ? a.groupFrameNumber - b.groupFrameNumber
                  : a.frameNumber - b.frameNumber;
              });
              currentSectionDetections.forEach((eachData) => {
                detectionsBBox = [
                  ...detectionsBBox,
                  "versioning" in eachData
                    ? "bbox" in eachData["versioning"]
                      ? eachData["versioning"]["bbox"]
                      : eachData["bbox"]
                    : eachData["bbox"],
                ];
                defectNames = [
                  ...defectNames,
                  "versioning" in eachData
                    ? "defectName" in eachData["versioning"]
                      ? eachData["versioning"]["defectName"]
                      : eachData["defectName"]
                    : eachData["defectName"],
                ];
                rectangleList = [
                  ...rectangleList,
                  "versioning" in eachData
                    ? "isRectangle" in eachData["versioning"]
                      ? eachData["versioning"]["isRectangle"] ?? true
                      : eachData["isRectangle"] ?? true
                    : eachData["isRectangle"] ?? true,
                ];
                // const isRectangle = eachData.isRectangle;
                // const rectangleCondCheck = isRectangle === undefined || isRectangle === true ? true : false;
                // if (rectangleCondCheck) {
                //   detectionsBBox = [...detectionsBBox, 'versioning' in eachData ? 'bbox' in eachData['versioning'] ? eachData['versioning']['bbox'] : eachData['bbox'] : eachData['bbox']]
                //   defectNames = [...defectNames, 'versioning' in eachData ? 'defectName' in eachData['versioning'] ? eachData['versioning']['defectName'] : eachData['defectName'] : eachData['defectName']]
                // }
              });
              // pickedSections.sort((a, b) => ((a.endIndex) / (FPS)) - ((b.endIndex) / (FPS)))
              // const pickedSection = pickedSections[0];
              const pickedSection =
                pickedSections === undefined ? undefined : pickedSections[0];
              if (pickedSection !== undefined) {
                const pickedIndex =
                  Object.values(predictions).indexOf(pickedSection);
                // //////console.log(pickedIndex, "indexx");
                if (pickedIndex !== segmentIndex) {
                  const currentSection =
                    Object.values(predictions)[pickedIndex];
                  const sectionId = currentSection["sectionId"];
                  setPinned(currentSection);
                  navigate(`/home/pr/${proid}/${pickedIndex}`);
                  setSections(pickedIndex);
                  setSegmentIndex(pickedIndex);

                  const parameters = currentSection?.crossSectionalParameters
                    ? currentSection?.crossSectionalParameters
                    : false;
                  depthCrossSectionalProfile(
                    parameters
                      ? {
                          x1: Number(parameters["x1"]),
                          y1: Number(parameters["y1"]),
                          x2: Number(parameters["x2"]),
                          y2: Number(parameters["y2"]),
                        }
                      : {
                          x1: defaultCrossSectionalPoints[0],
                          y1: defaultCrossSectionalPoints[1],
                          x2: defaultCrossSectionalPoints[2],
                          y2: defaultCrossSectionalPoints[3],
                        },
                    proid,
                    sectionId
                  );
                }
              }
              setSectionBBox(detectionsBBox);
              setDefectNameList(defectNames);
              setPolygonRectangleList(rectangleList);
            }
          } else {
            navigate(`/home/pr/${proid}/${0}`);
            handleSectionChange({ target: { value: 0 } });
          }
        } else {
          const currentSection = Object.values(predictions)[segmentIndex];
          if (currentSection) {
            const crossSectionalParameters =
              currentSection && currentSection?.crossSectionalParameters
                ? currentSection?.crossSectionalParameters
                : false;

            if (crossSectionalParameters) {
              const points = [
                Number(crossSectionalParameters["x1"]),
                Number(crossSectionalParameters["y1"]),
                Number(crossSectionalParameters["x2"]),
                Number(crossSectionalParameters["y2"]),
              ];
              setCrossSectionalPoints(points);
            } else {
              setCrossSectionalPoints(defaultCrossSectionalPoints);
            }
            let currentSectionDetections =
              currentSection && Object.values(currentSection?.detections);
            // //////console.log(currentSectionDetections, 'currentSectionDetections')
            currentSectionDetections = currentSectionDetections
              .filter((eachDetection) => Object.keys(eachDetection).length > 0)
              .filter((eachDetection) => {
                return eachDetection.archived
                  ? eachDetection.archived === "0"
                  : eachDetection;
              });
            currentSectionDetections.sort((a, b) => {
              return isGroupData
                ? a.groupFrameNumber - b.groupFrameNumber
                : a.frameNumber - b.frameNumber;
            });
            currentSectionDetections.forEach((eachData) => {
              // const isRectangle = eachData.isRectangle;
              // const rectangleCondCheck = isRectangle === undefined || isRectangle === true ? true : false;
              // if (rectangleCondCheck) {
              //   detectionsBBox = [...detectionsBBox, 'versioning' in eachData ? 'bbox' in eachData['versioning'] ? eachData['versioning']['bbox'] : eachData['bbox'] : eachData['bbox']]
              //   defectNames = [...defectNames, 'versioning' in eachData ? 'defectName' in eachData['versioning'] ? eachData['versioning']['defectName'] : eachData['defectName'] : eachData['defectName']]
              // }
              detectionsBBox = [
                ...detectionsBBox,
                "versioning" in eachData
                  ? "bbox" in eachData["versioning"]
                    ? eachData["versioning"]["bbox"]
                    : eachData["bbox"]
                  : eachData["bbox"],
              ];
              defectNames = [
                ...defectNames,
                "versioning" in eachData
                  ? "defectName" in eachData["versioning"]
                    ? eachData["versioning"]["defectName"]
                    : eachData["defectName"]
                  : eachData["defectName"],
              ];
              rectangleList = [
                ...rectangleList,
                "versioning" in eachData
                  ? "isRectangle" in eachData["versioning"]
                    ? eachData["versioning"]["isRectangle"] ?? true
                    : eachData["isRectangle"] ?? true
                  : eachData["isRectangle"] ?? true,
              ];
            });
            // pickedSections.sort((a, b) => ((a.endIndex) / (FPS)) - ((b.endIndex) / (FPS)))
            const pickedSection =
              pickedSections === undefined ? undefined : pickedSections[0];

            if (pickedSection !== undefined) {
              const pickedIndex =
                Object.values(predictions).indexOf(pickedSection);
              // //////console.log(pickedIndex, "indexx");
              if (pickedIndex !== segmentIndex) {
                const currentSection = Object.values(predictions)[pickedIndex];
                const sectionId = currentSection["sectionId"];
                setPinned(currentSection);
                navigate(`/home/pr/${proid}/${pickedIndex}`);
                setSections(pickedIndex);
                setSegmentIndex(pickedIndex);

                const parameters = currentSection?.crossSectionalParameters
                  ? currentSection?.crossSectionalParameters
                  : false;
                depthCrossSectionalProfile(
                  parameters
                    ? {
                        x1: Number(parameters["x1"]),
                        y1: Number(parameters["y1"]),
                        x2: Number(parameters["x2"]),
                        y2: Number(parameters["y2"]),
                      }
                    : {
                        x1: defaultCrossSectionalPoints[0],
                        y1: defaultCrossSectionalPoints[1],
                        x2: defaultCrossSectionalPoints[2],
                        y2: defaultCrossSectionalPoints[3],
                      },
                  proid,
                  sectionId
                );
              }
            }
            setSectionBBox(detectionsBBox);
            setDefectNameList(defectNames);
            setPolygonRectangleList(rectangleList);
          }
        }
      }
    }
  }, [currentTime]);
  useEffect(() => {
    if (Number(secId) !== segmentIndex) {
      if (Object.values(predictions)[Number(secId)]) {
        const currentSection = Object.values(predictions)[Number(secId)];
        const crossSectionalParameters =
          currentSection?.crossSectionalParameters ?? false;
        if (crossSectionalParameters) {
          const points = [
            Number(crossSectionalParameters["x1"]),
            Number(crossSectionalParameters["y1"]),
            Number(crossSectionalParameters["x2"]),
            Number(crossSectionalParameters["y2"]),
          ];
          setCrossSectionalPoints(points);
        } else {
          setCrossSectionalPoints(defaultCrossSectionalPoints);
        }
        let currentSectionDetections =
          currentSection && currentSection.detections
            ? Object.values(currentSection.detections)
            : [];
        currentSectionDetections = currentSectionDetections
          .filter((eachDetection) => Object.keys(eachDetection).length > 0)
          .filter((eachDetection) => {
            return eachDetection.archived
              ? eachDetection.archived === "0"
              : eachDetection;
          });
        currentSectionDetections.sort((a, b) => {
          return isGroupData
            ? a.groupFrameNumber - b.groupFrameNumber
            : a.frameNumber - b.frameNumber;
        });
        currentSectionDetections.forEach((eachData) => {
          detectionsBBox = [
            ...detectionsBBox,
            "versioning" in eachData
              ? "bbox" in eachData["versioning"]
                ? eachData["versioning"]["bbox"]
                : eachData["bbox"]
              : eachData["bbox"],
          ];
          defectNames = [
            ...defectNames,
            "versioning" in eachData
              ? "defectName" in eachData["versioning"]
                ? eachData["versioning"]["defectName"]
                : eachData["defectName"]
              : eachData["defectName"],
          ];
          rectangleList = [
            ...rectangleList,
            "versioning" in eachData
              ? "isRectangle" in eachData["versioning"]
                ? eachData["versioning"]["isRectangle"] ?? true
                : eachData["isRectangle"] ?? true
              : eachData["isRectangle"] ?? true,
          ];
          // const isRectangle = eachData.isRectangle;
          // const rectangleCondCheck = isRectangle === undefined || isRectangle === true ? true : false;
          // if (rectangleCondCheck) {
          //   detectionsBBox = [...detectionsBBox, 'versioning' in eachData ? 'bbox' in eachData['versioning'] ? eachData['versioning']['bbox'] : eachData['bbox'] : eachData['bbox']]
          //   defectNames = [...defectNames, 'versioning' in eachData ? 'defectName' in eachData['versioning'] ? eachData['versioning']['defectName'] : eachData['defectName'] : eachData['defectName']]
          // }
        });
        setPinned(currentSection);
        setSectionBBox(detectionsBBox);
        setDefectNameList(defectNames);
        setPolygonRectangleList(rectangleList);
      } else {
        navigate(`/home/pr/${proid}/${0}`);
        handleSectionChange({ target: { value: 0 } });
      }
    } else {
      const currentSection = Object.values(predictions)[segmentIndex];
      const crossSectionalParameters =
        currentSection?.crossSectionalParameters ?? false;
      if (crossSectionalParameters) {
        const points = [
          Number(crossSectionalParameters["x1"]),
          Number(crossSectionalParameters["y1"]),
          Number(crossSectionalParameters["x2"]),
          Number(crossSectionalParameters["y2"]),
        ];
        setCrossSectionalPoints(points);
      } else {
        setCrossSectionalPoints(defaultCrossSectionalPoints);
      }
      let currentSectionDetections =
        currentSection && currentSection.detections
          ? Object.values(currentSection.detections)
          : [];
      currentSectionDetections = currentSectionDetections
        .filter((eachDetection) => Object.keys(eachDetection).length > 0)
        .filter((eachDetection) => {
          return eachDetection.archived
            ? eachDetection.archived === "0"
            : eachDetection;
        });
      currentSectionDetections.sort((a, b) => {
        return isGroupData
          ? a.groupFrameNumber - b.groupFrameNumber
          : a.frameNumber - b.frameNumber;
      });
      currentSectionDetections.forEach((eachData) => {
        detectionsBBox = [
          ...detectionsBBox,
          "versioning" in eachData
            ? "bbox" in eachData["versioning"]
              ? eachData["versioning"]["bbox"]
              : eachData["bbox"]
            : eachData["bbox"],
        ];
        defectNames = [
          ...defectNames,
          "versioning" in eachData
            ? "defectName" in eachData["versioning"]
              ? eachData["versioning"]["defectName"]
              : eachData["defectName"]
            : eachData["defectName"],
        ];
        rectangleList = [
          ...rectangleList,
          "versioning" in eachData
            ? "isRectangle" in eachData["versioning"]
              ? eachData["versioning"]["isRectangle"] ?? true
              : eachData["isRectangle"] ?? true
            : eachData["isRectangle"] ?? true,
        ];
        // const isRectangle = eachData.isRectangle;
        // const rectangleCondCheck = isRectangle === undefined || isRectangle === true ? true : false;
        // if (rectangleCondCheck) {
        //   detectionsBBox = [...detectionsBBox, 'versioning' in eachData ? 'bbox' in eachData['versioning'] ? eachData['versioning']['bbox'] : eachData['bbox'] : eachData['bbox']]
        //   defectNames = [...defectNames, 'versioning' in eachData ? 'defectName' in eachData['versioning'] ? eachData['versioning']['defectName'] : eachData['defectName'] : eachData['defectName']]
        // }
      });
      setSectionBBox(detectionsBBox);
      setDefectNameList(defectNames);
      setPolygonRectangleList(rectangleList);
      setPinned(currentSection);
    }
  }, [predictions]);
  // ReadMe: this useeffect is used for when someone enter section from url
  useEffect(() => {
    if (Number(secId) !== segmentIndex) {
      handleSectionChange({ target: { value: Number(secId) } });
    }
  }, []);

  //////console.log(polygonRectangleList, 'polygonRectangleList')

  //README: this function is using for downsampling work.
  function getSectionInfo(
    apiData,
    allSectionDocuments,
    frameNumber,
    totalFrameCount
  ) {
    //////console.log(frameNumber, "getSectionInfodata")

    let isPartOfDownsampling = false;
    let isPartOfExistingData = false;
    let finalSectionId = undefined;
    let isPartOfFrameToIgnore = false;

    const allDownsamplingIndex = apiData.downsampleIndexes;
    const framesToIgnore = apiData.framesToIgnore ?? [];
    allDownsamplingIndex.sort((a, b) => a.endIndex - b.endIndex);
    const frameNumberLeverage = 50;
    const subSectionLeverage = 100;
    let downsampleInitIndex = undefined;
    let downsampleEndIndex = undefined;
    let selectedSection = undefined;
    let isOutOfBound = false;
    let isFirstSubSection = false;

    let possibleFrameIgnore = framesToIgnore.filter(
      (each) => each[0] < frameNumber && frameNumber < each[1]
    );

    if (possibleFrameIgnore.length == 0) {
      let possibleSection = allDownsamplingIndex.filter(
        (each) => each.startIndex <= frameNumber && frameNumber < each.endIndex
      );
      if (possibleSection.length > 0) {
        isPartOfDownsampling = true;
        selectedSection = possibleSection[0].sectionid;
        downsampleInitIndex = possibleSection[0].startIndex;
        downsampleEndIndex = possibleSection[0].endIndex;
      } else {
        let possibleSectionNearby = allDownsamplingIndex.filter(
          (each) =>
            each.startIndex <= frameNumber + frameNumberLeverage &&
            frameNumber - frameNumberLeverage < each.endIndex
        );
        if (possibleSectionNearby.length > 0) {
          isPartOfDownsampling = true;
          selectedSection = possibleSectionNearby[0].sectionid;
          downsampleInitIndex = possibleSectionNearby[0].startIndex;
          downsampleEndIndex = possibleSectionNearby[0].endIndex;
          if (frameNumber < possibleSectionNearby[0].initIndex) {
            isFirstSubSection = true;
          } else if (frameNumber > possibleSectionNearby[0].endIndex) {
            isOutOfBound = true;
          }
        }
      }
    } else {
      isPartOfFrameToIgnore = true;
    }

    if (isPartOfDownsampling == true) {
      let filteredSubsectionData = allSectionDocuments.filter(
        (each) => each.sectionId.split("_")[0] === selectedSection
      );
      filteredSubsectionData.sort((a, b) => a.endIndex - b.endIndex);

      if (filteredSubsectionData.length > 0) {
        isPartOfExistingData = true;
        if (isFirstSubSection == true) {
          finalSectionId = filteredSubsectionData[0].sectionId;
        } else if (isOutOfBound == true) {
          finalSectionId =
            filteredSubsectionData[filteredSubsectionData.length - 1].sectionId;
        } else {
          let possibleSubsection = filteredSubsectionData.filter(
            (each) =>
              each.initIndex <= frameNumber && frameNumber < each.endIndex
          );
          if (possibleSubsection.length > 0) {
            finalSectionId = possibleSubsection[0].sectionId;
          } else {
            let possibleSubsectionNearby = filteredSubsectionData.filter(
              (each) =>
                each.initIndex <= frameNumber + subSectionLeverage &&
                frameNumber - subSectionLeverage < each.endIndex
            );
            possibleSubsectionNearby.sort(
              (a, b) =>
                Math.min(
                  Math.abs(a.initIndex - frameNumber),
                  Math.abs(a.endIndex - frameNumber)
                ) -
                Math.min(
                  Math.abs(b.initIndex - frameNumber),
                  Math.abs(b.endIndex - frameNumber)
                )
            );
            finalSectionId =
              possibleSubsectionNearby.length !== 0
                ? possibleSubsectionNearby[0].sectionId
                : Math.abs(frameNumber - downsampleInitIndex) <
                  Math.abs(downsampleEndIndex - frameNumber)
                ? allSectionDocuments[0].sectionId
                : allSectionDocuments[allSectionDocuments.length - 1].sectionId;
          }
        }
      }
    }
    return isPartOfDownsampling === false
      ? "-1"
      : isPartOfExistingData === false
      ? "-2"
      : finalSectionId;
    //   isPartOfDownsampling = false //  wont be processed
    // isPartOfExistingData = false // currently in process
    //   isPartOfDownsampling &&  isPartOfExistingData = true // data is there.
    // isPartOfFrameToIgnore = true  //  wont be processed
  }

  const handleSegmentChange = (up) => {
    const currentSegmentIndex = segmentIndex;
    const newIndex = up
      ? segmentIndex === Object.values(predictions).length - 1
        ? segmentIndex
        : currentSegmentIndex + 1
      : segmentIndex === 0
      ? segmentIndex
      : currentSegmentIndex - 1;
    handleSectionChange({ target: { value: newIndex } });
    navigate(`/home/pr/${proid}/${up ? Number(secId) + 1 : Number(secId) - 1}`);
  };

  const selectedSegment = Object.values(predictions)[segmentIndex];
  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleOpenSectionOverviewDialog = () => {
    setSectionOverviewDialog(true);
  };

  const handleCloseSectionOverviewDialog = () => {
    setSectionOverviewDialog(false);
  };

  const handleSwitchButtonClick = () => {
    setShowMaskImage((prevShowMaskImage) => !prevShowMaskImage);
  };

  const handleLineChange = (value) => {
    // //////console.log(value, "value")
    setCrossSectionalPoints(value);
    setSavePoints(value);
  };

  const handleSubmitPoints = () => {
    const sectionId = Object.values(predictions)[sections]["sectionId"];
    const points = {
      x1: savePoints[0],
      y1: savePoints[1],
      x2: savePoints[2],
      y2: savePoints[3],
    };
    saveDepthCrossSectionalPoints(points, proid, sectionId);
  };

  const segmentSwitcher = (
    <>
      <IconButton
        onClick={() => {
          handleSegmentChange(false);
        }}
        disabled={segmentIndex === 0 ? true : false}
      >
        <Tooltip title="Previous section">
          <KeyboardArrowLeftIcon
            sx={{ color: segmentIndex === 0 ? "grey" : "#1E1E1E" }}
          />
        </Tooltip>
      </IconButton>
      <FormControl
        sx={{
          display: { xs: "none", sm: "none", md: "flex" },
          alignItems: "center",
        }}
        size="small"
      >
        <TextField
          id="section-dropdown"
          select
          value={sections}
          onChange={handleSectionChange}
          size="small"
          sx={{
            border: `1px solid #000000`,
            textTransform: "capitalize",
            width: "100%",
            "&:hover": {
              backgroundColor: "transparent",
              color: "#000000",
            },
            outline: "none",
            color: "#000000",
            borderRadius: "4px",
          }}
        >
          {Object.values(predictions).map((section, index) => (
            <MenuItem
              key={index}
              value={index}
              sx={{ textTransform: "capitalize" }}
            >
              {commonNames.Section} {index + 1}
            </MenuItem>
          ))}
        </TextField>
      </FormControl>

      <FormControl
        sx={{
          display: { xs: "flex", sm: "flex", md: "none" },
          alignItems: "center",
        }}
        size="small"
      >
        <TextField
          id="section-dropdown"
          select
          value={sections}
          onChange={handleSectionChange}
          size="small"
          sx={{
            border: `1px solid #000000`,
            textTransform: "capitalize",
            width: "100%",
            "&:hover": {
              backgroundColor: "transparent",
              color: "#000000",
            },
            outline: "none",
            color: "#000000",
            borderRadius: "4px",
          }}
        >
          {Object.values(predictions).map((section, index) => (
            <MenuItem
              key={index}
              value={index}
              sx={{ textTransform: "capitalize" }}
            >
              {index + 1}
            </MenuItem>
          ))}
        </TextField>
      </FormControl>

      <IconButton
        onClick={() => {
          handleSegmentChange(true);
        }}
        disabled={
          segmentIndex === Object.values(predictions).length - 1 ? true : false
        }
      >
        <Tooltip title="Next section">
          <KeyboardArrowRightIcon
            sx={{
              color:
                segmentIndex === Object.values(predictions).length - 1
                  ? "grey"
                  : "#1E1E1E",
            }}
          />
        </Tooltip>
      </IconButton>
    </>
  );

  const segmentDistressDetailViewer = (
    <>
      {!canViewCrossSectionDistress ? (
        <></>
      ) : isDepthCrossSectional || isAdvanceProjectType ? (
        <></>
      ) : (
        <StyledOutlineButton
          onClick={handleDepthCrossSectional}
          text={"View cross-section"}
          tooltipText={`View and set new cross-section values in this ${commonNames.section}`}
        />
      )}
      {isAdvanceProjectType ? (
        <></>
      ) : !canShowMasksDistress ? (
        <></>
      ) : selectedSegment?.maskPath ? (
        <StyledOutlineButton
          margin={5}
          onClick={handleSwitchButtonClick}
          text={showMaskImage ? "Show stitched" : "Show mask"}
          tooltipText={
            showMaskImage ? "Show stitched image" : "Show mask image"
          }
        />
      ) : (
        <></>
      )}

      {predictions.length > 0 ? (
        !canViewSectionDetailsDistress ? (
          <></>
        ) : (
          <StyledOutlineButton
            onClick={handleClickOpenDialog}
            margin={5}
            text={"View section details"}
            tooltipText={`See distresses in this ${commonNames.section}`}
          />
        )
      ) : (
        <> </>
      )}
      <Dialog fullScreen open={openDialog} onClose={handleCloseDialog}>
        <DistressDashboardV2
          conditionMethod={conditionMethod}
          handleSeekTo={handleSeekTo}
          isGroupData={isGroupData}
          distressPinned={distressPinned}
          setDistressPinned={setDistressPinned}
          originalVideoHeight={originalVideoHeight}
          originalVideoWidth={originalVideoWidth}
          link={link}
          handleCloseDialog={handleCloseDialog}
          fps={FPS}
          segmentPinned={pinned}
          selectedSegment={selectedSegment}
          playerRef={playerRef}
          proid={proid}
          handleSegmentChange={handleSegmentChange}
          segmentIndex={segmentIndex}
          predictions={predictions}
          drProjectData={drProjectData}
          showMaskImage={showMaskImage}
          defectNameList={defectNameList}
        />
      </Dialog>
    </>
  );
  const titleStyle = { fontWeight: "bold", fontSize: "14px" };
  // let score = predictions.length > 0 && Object.values(predictions)[segmentIndex]['info']['condition'];
  let score =
    predictions.length > 0 && predictions[segmentIndex]?.info?.condition;

  const sectionalParameters =
    predictions.length > 0 &&
    Object.values(predictions)[segmentIndex] &&
    Object.values(predictions)[segmentIndex]["sectionalParameters"];
  const edgeDeformation = sectionalParameters
    ? isNaN(
        sectionalParameters["edge_deformation"][
          drProjectData.roadSide === "0" ? "right" : "left"
        ]
      )
      ? "N.A"
      : sectionalParameters["edge_deformation"][
          drProjectData.roadSide === "0" ? "right" : "left"
        ]
    : "N.A";
  const ruttingLeft = sectionalParameters
    ? isNaN(sectionalParameters["rutting"]["left"])
      ? "N.A"
      : sectionalParameters["rutting"]["left"]
    : "N.A";
  const ruttingRight = sectionalParameters
    ? isNaN(sectionalParameters["rutting"]["right"])
      ? "N.A"
      : sectionalParameters["rutting"]["right"]
    : "N.A";
  const lv10m = sectionalParameters
    ? sectionalParameters["longitudinal_variance"]
      ? isNaN(sectionalParameters["longitudinal_variance"]["10m"])
        ? "N.A"
        : sectionalParameters["longitudinal_variance"]["10m"]
      : "N.A"
    : "N.A";
  const lv3m = sectionalParameters
    ? sectionalParameters["longitudinal_variance"]
      ? isNaN(sectionalParameters["longitudinal_variance"]["3m"])
        ? "N.A"
        : sectionalParameters["longitudinal_variance"]["3m"]
      : "N.A"
    : "N.A";
  const laneWidth = sectionalParameters
    ? sectionalParameters["doubleLaneWidth"]
      ? isNaN(sectionalParameters["doubleLaneWidth"])
        ? "N.A"
        : sectionalParameters["doubleLaneWidth"]
      : "N.A"
    : "N.A";
  const shoulderWidth = sectionalParameters
    ? sectionalParameters["shoulderWidth"]
      ? isNaN(sectionalParameters["shoulderWidth"])
        ? "N.A"
        : sectionalParameters["shoulderWidth"]
      : "N.A"
    : "N.A";
  const totalWidth = sectionalParameters
    ? sectionalParameters["singleLaneWidth"]
      ? isNaN(sectionalParameters["singleLaneWidth"])
        ? "N.A"
        : sectionalParameters["singleLaneWidth"]
      : "N.A"
    : "N.A";
  const segmentDetailOverview = (
    <>
      <Tooltip
        title={`View all ${commonNames.section} information`}
        arrow
        placement="bottom"
      >
        <IconButton onClick={handleOpenSectionOverviewDialog}>
          <InfoIcon scale={1} sx={{ color: "black" }} />
        </IconButton>
      </Tooltip>
      <SimpleDialogBox
        open={sectionOverviewDialog}
        onClose={handleCloseSectionOverviewDialog}
        title="Section information"
        width={600}
        cancelText="Close"
        bodyWidget={
          <>
            {pinned && userData.dev && (
              <ListItem disablePadding sx={{ paddingBlock: "5px" }}>
                <ListItemText
                  primary={
                    <Typography variant="p" sx={titleStyle}>
                      Section no:
                    </Typography>
                  }
                  // secondary={`${Number(`${Object.values(predictions)[segmentIndex]['sectionalParameters']['edge_deformation'][drProjectData.roadSide === '0' ? 'right' : 'left']}` * 1000).toFixed(1)} mm`}
                  secondary={pinned?.id ?? "N.A"}
                />
              </ListItem>
            )}
            {isAdvanceProjectType ? (
              <></>
            ) : (
              <>
                {conditionMethod === 0 ? (
                  <ListItem disablePadding sx={{ paddingBlock: "5px" }}>
                    <ListItemText
                      primary={
                        typeOfRoad === "cycleTracks" ? (
                          <Typography variant="p" sx={titleStyle}>
                            Adjusted Road Condition Index - RCI (except texture
                            and rutting):
                          </Typography>
                        ) : (
                          <Typography variant="p" sx={titleStyle}>
                            Adjusted Road Condition Index - RCI (except
                            texture):
                          </Typography>
                        )
                      }
                      // secondary={`${Object.values(predictions)[segmentIndex]['info']['condition']}`}
                      secondary={
                        <Grid container spacing={0}>
                          <Grid
                            item
                            xs={12}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "start",
                            }}
                          >
                            <div
                              style={{
                                width: "12px",
                                height: "12px",
                                borderRadius: "50%",
                                marginRight: "10px",
                                backgroundColor: SmartUtils.rciColor(
                                  score,
                                  typeOfRoad
                                ),
                                // backgroundColor: typeOfRoad === 'cycleTracks' ? score >= 7.8 && score <= 44.4 ? 'darkorange' : score > 44.4 ? 'red' : 'green' :
                                //   score >= 30.5 && score <= 76.2 ? 'darkorange' : score > 76.2 ? 'red' : 'green',
                              }}
                            ></div>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {predictions.length > 0 &&
                                Object.values(predictions)[segmentIndex] &&
                                Math.round(
                                  Object.values(predictions)[segmentIndex][
                                    "info"
                                  ]["condition"]
                                )}
                            </div>
                          </Grid>
                        </Grid>
                      }
                    />
                  </ListItem>
                ) : (
                  <> </>
                )}

                {sectionalParameters ? (
                  <>
                    <ListItem disablePadding sx={{ paddingBlock: "5px" }}>
                      <ListItemText
                        primary={
                          <Typography variant="p" sx={titleStyle}>
                            {edgeDeformation===null ?"Edge drop:": Number(edgeDeformation) > 0
                              ? "Edge drop:"
                              : "Edge rise or grass:"}
                          </Typography>
                        }
                        secondary={
                          edgeDeformation === "N.A"
                            ? edgeDeformation:
                            edgeDeformation === null?"N.A"
                            : `${Math.abs(Number(edgeDeformation)).toFixed(0)} mm`
                        }
                      />
                    </ListItem>

                    {typeOfRoad === undefined ? (
                      <>
                        <ListItem disablePadding sx={{ paddingBlock: "5px" }}>
                          <ListItemText
                            primary={
                              <Typography variant="p" sx={titleStyle}>
                                Left wheelpath ruttting:
                              </Typography>
                            }
                            // secondary={`${Number(`${Object.values(predictions)[segmentIndex]['sectionalParameters']['rutting']['left']}` * 1000).toFixed(1)} mm`}
                            secondary={
                              ruttingLeft === "N.A"
                                ? ruttingLeft
                                : `${Number(ruttingLeft * 1000).toFixed(0)} mm`
                            }
                          />
                        </ListItem>
                        <ListItem disablePadding sx={{ paddingBlock: "5px" }}>
                          <ListItemText
                            primary={
                              <Typography variant="p" sx={titleStyle}>
                                Right wheelpath rutting:
                              </Typography>
                            }
                            // secondary={`${Number(`${Object.values(predictions)[segmentIndex]['sectionalParameters']['rutting']['right']}` * 1000).toFixed(1)} mm`}
                            secondary={
                              ruttingRight === "N.A"
                                ? ruttingRight
                                : `${Number(ruttingRight * 1000).toFixed(0)} mm`
                            }
                          />
                        </ListItem>
                      </>
                    ) : typeOfRoad === "carriageWay" ? (
                      <>
                        <ListItem disablePadding sx={{ paddingBlock: "5px" }}>
                          <ListItemText
                            primary={
                              <Typography variant="p" sx={titleStyle}>
                                Left wheelpath ruttting:
                              </Typography>
                            }
                            // secondary={`${Number(`${Object.values(predictions)[segmentIndex]['sectionalParameters']['rutting']['left']}` * 1000).toFixed(1)} mm`}
                            secondary={
                              ruttingLeft === "N.A"
                                ? ruttingLeft
                                : `${Number(ruttingLeft * 1000).toFixed(0)} mm`
                            }
                          />
                        </ListItem>
                        <ListItem disablePadding sx={{ paddingBlock: "5px" }}>
                          <ListItemText
                            primary={
                              <Typography variant="p" sx={titleStyle}>
                                Right wheelpath rutting:
                              </Typography>
                            }
                            // secondary={`${Number(`${Object.values(predictions)[segmentIndex]['sectionalParameters']['rutting']['right']}` * 1000).toFixed(1)} mm`}
                            secondary={
                              ruttingRight === "N.A"
                                ? ruttingRight
                                : `${Number(ruttingRight * 1000).toFixed(0)} mm`
                            }
                          />
                        </ListItem>
                      </>
                    ) : (
                      <> </>
                    )}
                    <ListItem disablePadding sx={{ paddingBlock: "5px" }}>
                      <ListItemText
                        primary={
                          <Typography variant="p" sx={titleStyle}>
                            Lane width:
                          </Typography>
                        }
                        secondary={
                          laneWidth === "N.A"
                            ? laneWidth
                            : `${Number(laneWidth).toFixed(3)} m`
                        }
                      />
                    </ListItem>
                    <ListItem disablePadding sx={{ paddingBlock: "5px" }}>
                      <ListItemText
                        primary={
                          <Typography variant="p" sx={titleStyle}>
                            Shoulder width:
                          </Typography>
                        }
                        secondary={
                          shoulderWidth === "N.A"
                            ? shoulderWidth
                            : `${Number(shoulderWidth).toFixed(3)} m`
                        }
                      />
                    </ListItem>
                    <ListItem disablePadding sx={{ paddingBlock: "5px" }}>
                      <ListItemText
                        primary={
                          <Typography variant="p" sx={titleStyle}>
                            Total width:
                          </Typography>
                        }
                        secondary={
                          totalWidth === "N.A"
                            ? totalWidth
                            : `${Number(totalWidth).toFixed(3)} m`
                        }
                      />
                    </ListItem>

                    <ListItem disablePadding sx={{ paddingBlock: "5px" }}>
                      <ListItemText
                        primary={
                          <Typography variant="p" sx={titleStyle}>
                            Longitudinal variance (3m):
                          </Typography>
                        }
                        // secondary={`${Number(`${Object.values(predictions)[segmentIndex]['sectionalParameters']['rutting']['right']}` * 1000).toFixed(1)} mm`}
                        secondary={
                          lv3m === "N.A"
                            ? lv3m
                            : `${Number(lv3m).toFixed(1)} mm²`
                        }
                      />
                    </ListItem>
                    <ListItem disablePadding sx={{ paddingBlock: "5px" }}>
                      <ListItemText
                        primary={
                          <Typography variant="p" sx={titleStyle}>
                            Longitudinal variance (10m):
                          </Typography>
                        }
                        secondary={
                          lv10m === "N.A"
                            ? lv10m
                            : `${Number(lv10m).toFixed(1)} mm²`
                        }
                      />
                    </ListItem>
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </>
        }
      />
    </>
  );
  const firstRef = useRef();
  const secondRef = useRef();

  const firstCircleRef = useRef();
  const secondCircleRef = useRef();
  const thirdCircleRef = useRef();
  const fourthCircleRef = useRef();

  const handleZoomIn = () => {
    firstRef?.current?.zoomIn();
    secondRef?.current?.zoomIn();
  };
  const handleZoomOut = () => {
    firstRef?.current?.zoomOut();
    secondRef?.current?.zoomOut();
  };
  const handleRest = () => {
    firstRef?.current?.resetTransform();
    secondRef?.current?.resetTransform();
  };
  const firstHandlePanning = (e) => {
    secondRef?.current?.setTransform(
      e.state.positionX,
      e.state.positionY,
      e.state.scale
    );
  };
  const secondHandlePanning = (e) => {
    firstRef?.current?.setTransform(
      e.state.positionX,
      e.state.positionY,
      e.state.scale
    );
  };
  return (
    <>
      <SimpleDialogBox
        open={openFullscreen}
        onClose={handleClickClose}
        title={
          <Grid container spacing={0}>
            <Grid
              item
              xs={12}
              style={{
                height: `calc(5% - ${0}px)`,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                sx={{
                  color: "black",
                  textTransform: "",
                  marginRight: "10px",
                  fontWeight: "bold",
                  backgroundColor: "rgba(0,0,0,0.01)",
                  ":hover": { backgroundColor: "rgba(0,0,0,0.04)" },
                }}
                variant="contained"
                size="small"
                onClick={handleZoomIn}
              >
                Zoom in +
              </Button>
              <Button
                sx={{
                  color: "black",
                  textTransform: "",
                  marginRight: "10px",
                  fontWeight: "bold",
                  backgroundColor: "rgba(0,0,0,0.01)",
                  ":hover": { backgroundColor: "rgba(0,0,0,0.04)" },
                }}
                variant="contained"
                size="small"
                onClick={handleZoomOut}
              >
                Zoom Out -
              </Button>
              <Button
                sx={{
                  color: "black",
                  textTransform: "",
                  fontWeight: "bold",
                  backgroundColor: "rgba(0,0,0,0.01)",
                  ":hover": { backgroundColor: "rgba(0,0,0,0.04)" },
                }}
                variant="contained"
                size="small"
                onClick={handleRest}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
        }
        width={800}
        minWidth={800}
        cancelText="Close"
        bodyWidget={
          !canSeeHighResViewerDistress ? (
            <AccessControlAccountError access={role} />
          ) : (
            <>
              <Grid container spacing={0}>
                {isAdvanceProjectType ? (
                  <Grid item xs={12} style={{ height: `calc(95% - ${0}px)` }}>
                    <Grid
                      container
                      spacing={0}
                      sx={{ backgroundColor: "", height: `calc(95% - ${0}px)` }}
                    >
                      <Grid
                        item
                        xs={6}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <TransformWrapper
                          ref={firstRef}
                          onPanning={firstHandlePanning}
                          wheel={{ disabled: true }}
                          pinch={{ disabled: true }}
                          doubleClick={{ disabled: true }}
                          disablePadding={true}
                        >
                          <TransformComponent>
                            <ImageAnnotatorV3
                              imageUrl={selectedSegment?.stitchPathHR}
                              imageHeight={selectedSegment?.HRHeight}
                              imageWidth={selectedSegment?.HRWidth}
                              drawMap={showMaskImage ? false : true}
                              editBox={false}
                              onValueChange={(value) => handleBoxChange(value)}
                              nonEditableBbox={bbox}
                            />
                          </TransformComponent>
                        </TransformWrapper>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <TransformWrapper
                          ref={secondRef}
                          onPanning={firstHandlePanning}
                          wheel={{ disabled: true }}
                          pinch={{ disabled: true }}
                          doubleClick={{ disabled: true }}
                          disablePadding={true}
                        >
                          <TransformComponent>
                            <ImageAnnotatorV3
                              imageUrl={selectedSegment?.maskPathHR}
                              imageHeight={selectedSegment?.HRHeight}
                              imageWidth={selectedSegment?.HRWidth}
                              drawMap={showMaskImage ? false : true}
                              editBox={false}
                              onValueChange={(value) => handleBoxChange(value)}
                              nonEditableBbox={bbox}
                            />
                          </TransformComponent>
                        </TransformWrapper>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item xs={12} style={{ height: `calc(95% - ${0}px)` }}>
                    <Grid
                      container
                      spacing={0}
                      sx={{ backgroundColor: "", height: `calc(95% - ${0}px)` }}
                    >
                      <Grid
                        item
                        xs={6}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <TransformWrapper
                          ref={firstRef}
                          onPanning={firstHandlePanning}
                          wheel={{ disabled: true }}
                          pinch={{ disabled: true }}
                          doubleClick={{ disabled: true }}
                          disablePadding={true}
                        >
                          <TransformComponent>
                            <ImageAnnotatorV3
                              imageUrl={
                                showMaskImage
                                  ? selectedSegment?.maskPathHR
                                  : selectedSegment?.stitchPathHR
                              }
                              imageHeight={selectedSegment?.HRHeight}
                              imageWidth={selectedSegment?.HRWidth}
                              drawMap={showMaskImage ? false : true}
                              editBox={false}
                              onValueChange={(value) => handleBoxChange(value)}
                              nonEditableBbox={bbox}
                            />
                          </TransformComponent>
                        </TransformWrapper>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <TransformWrapper
                          ref={secondRef}
                          onPanning={secondHandlePanning}
                          wheel={{ disabled: true }}
                          pinch={{ disabled: true }}
                          doubleClick={{ disabled: true }}
                          disablePadding={true}
                        >
                          <TransformComponent>
                            <ImageAnnotatorV3
                              imageUrl={selectedSegment?.depthMapPathHR}
                              imageHeight={selectedSegment?.HRHeight}
                              imageWidth={selectedSegment?.HRWidth}
                              drawMap={false}
                              editBox={false}
                            />
                            {/* <DepthAnnotator  /> */}
                          </TransformComponent>
                        </TransformWrapper>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </>
          )
        }
      />

      <Grid container spacing={0}>
        {/* Header */}
        {isSectionNotProccessed === true ? (
          <Grid
            item
            xs={12}
            style={{
              height: `calc(100vh - ${overallCustomBorderPadding * 2}px)`,
              backgroundColor: "",
              overflow: "",
            }}
          >
            {/* <div
              style={{
                height: "10vh",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              {segmentSwitcher}
            </div> */}
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              The user has chosen not to process this section of the video
            </div>
          </Grid>
        ) : isSectionRunning === true ? (
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: `calc(100vh - ${overallCustomBorderPadding * 2}px)`,
              backgroundColor: "",
              overflow: "",
            }}
          >
            {status === 8 ? (
              <CustomProgress
                text={"This section is currently being processed"}
              />
            ) : (
              "This section can not be processed"
            )}
          </Grid>
        ) : isWholeDistressVisualization ? (
          <AllDistressList
            isGroupData={isGroupData}
            drProjectData={drProjectData}
            distressPinned={distressPinned}
            setDistressPinned={setDistressPinned}
            handleSeekTo={handleSeekTo}
            fps={FPS}
            distressData={distressData}
            distressProjectPredictions={predictions}
            isWholeDistressVisualization={isWholeDistressVisualization}
            handleDataVisualizationChange={handleDataVisualizationChange}
          />
        ) : (
          <>
            <Grid
              item
              xs={12}
              style={{
                height: `calc(15vh - ${overallCustomBorderPadding}px)`,
                backgroundColor: "",
                overflow: "auto",
              }}
            >
              <Grid
                container
                spacing={0}
                sx={{ height: `calc(15vh - ${overallCustomBorderPadding}px)` }}
              >
                {isDepthCrossSectional ? (
                  <>
                    {predictions.length > 0 ? (
                      <Grid
                        item
                        xs={4}
                        sm={4}
                        md={4}
                        lg={4}
                        xl={4}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "start",
                        }}
                      >
                        {segmentSwitcher}
                      </Grid>
                    ) : (
                      <></>
                    )}
                    <Grid
                      item
                      xs={4}
                      sm={5}
                      md={4}
                      lg={4}
                      xl={4}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                      }}
                    >
                      {segmentDistressDetailViewer}
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sm={2}
                      md={3}
                      lg={3}
                      xl={3}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {saveDepthCrossSectionalPointsLoader ? (
                        <>
                          <Button
                            size="small"
                            variant="outlined"
                            disabled
                            sx={{
                              display: { xs: "none", sm: "none", md: "flex" },
                              "&.Mui-disabled": {
                                color: "#000000",
                              },
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              padding: "5px",
                              border: `1px solid #000000`,
                              outline: "none",
                              "&:focus": {
                                border: `1px solid #000000`,
                              },
                              "&:hover": {
                                backgroundColor: "transparent",
                                color: "#000000",
                              },
                              textTransform: "none",
                              color: "#000000",
                            }}
                          >
                            <CircularProgress
                              variant="indeterminate"
                              size={15}
                              sx={{ color: "#025196" }}
                            />
                            <span style={{ paddingLeft: "15px" }}>
                              Saving...
                            </span>
                          </Button>

                          <CircularProgress
                            variant="indeterminate"
                            size={15}
                            sx={{
                              color: "#025196",
                              display: { xs: "flex", sm: "flex", md: "none" },
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <Button
                            variant="outlined"
                            size="small"
                            disabled={!canSaveCrossSectionDistress}
                            sx={{
                              display: { xs: "none", sm: "none", md: "flex" },
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              border: `1px solid #000000`,
                              outline: "none",
                              "&:focus": {
                                border: `1px solid #000000`,
                              },
                              textTransform: "none",
                              marginLeft: "7px",
                              color: "#000000",
                              "&:hover": {
                                border: `1px solid #000000`,
                                backgroundColor: "transparent",
                              },
                            }}
                            onClick={handleSubmitPoints}
                          >
                            Save
                          </Button>

                          {canSaveCrossSectionDistress ? (
                            <Tooltip
                              title={"Save"}
                              arrow
                              placement="bottom"
                              sx={{
                                display: { xs: "flex", sm: "flex", md: "none" },
                              }}
                            >
                              <SaveIcon
                                sx={{
                                  fontSize: { xs: "16px", sm: "24px" },
                                  display: {
                                    xs: "flex",
                                    sm: "flex",
                                    md: "none",
                                  },
                                  color: "#1E1E1E",
                                  cursor: "pointer",
                                }}
                                onClick={handleSubmitPoints}
                              />
                            </Tooltip>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                      <>
                        <Button
                          variant="outlined"
                          size="small"
                          sx={{
                            display: { xs: "none", sm: "none", md: "flex" },
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            border: `1px solid #000000`,
                            outline: "none",
                            marginLeft: "5px",
                            "&:focus": {
                              border: `1px solid #000000`,
                            },
                            textTransform: "none",
                            color: "#000000",
                            "&:hover": {
                              border: `1px solid #000000`,
                              backgroundColor: "transparent",
                            },
                          }}
                          onClick={handleCloseDepthCrossSectional}
                        >
                          Close
                        </Button>

                        <Tooltip
                          title={"Close"}
                          arrow
                          placement="bottom"
                          sx={{
                            display: { xs: "flex", sm: "flex", md: "none" },
                          }}
                        >
                          <CloseIcon
                            sx={{
                              fontSize: { xs: "16px", sm: "24px" },
                              display: { xs: "flex", sm: "flex", md: "none" },
                              color: "#1E1E1E",
                              cursor: "pointer",
                              marginLeft: "5px",
                            }}
                            onClick={handleCloseDepthCrossSectional}
                          />
                        </Tooltip>
                      </>
                      {/* <StyledOutlineButton onClick={handleSubmitPoints} margin={5} text={"Save points"} tooltipText={`Save these points for cross-sectional profile plotting`} /> */}
                      {/* <StyledOutlineButton onClick={handleCloseDepthCrossSectional} margin={5} text={"Close"} tooltipText={`Close to see ${commonNames.section} details`} /> */}
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      sm={1}
                      md={1}
                      lg={1}
                      xl={1}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {isAdvanceProjectType && !userData.dev ? (
                        <></>
                      ) : (
                        segmentDetailOverview
                      )}
                    </Grid>
                  </>
                ) : (
                  <>
                    {predictions.length > 0 ? (
                      <Grid
                        item
                        xs={4}
                        sm={3}
                        md={4}
                        lg={4}
                        xl={4}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "start",
                          background: "",
                        }}
                      >
                        {segmentSwitcher}
                        {/* <span style={{ paddingInline: '5px' }}></span> */}
                      </Grid>
                    ) : (
                      <> </>
                    )}

                    <Grid
                      item
                      xs={6}
                      sm={7}
                      md={6}
                      lg={6}
                      xl={6}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                        background: "",
                      }}
                    >
                      {segmentDistressDetailViewer}
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                        background: "",
                      }}
                    >
                      {isAdvanceProjectType && !userData.dev ? (
                        <></>
                      ) : (
                        segmentDetailOverview
                      )}
                      <Tooltip title={`Distress driven`} arrow>
                        <Switch
                          checked={isWholeDistressVisualization}
                          onChange={handleDataVisualizationChange}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </Tooltip>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                height: `calc(${
                  isDepthCrossSectional ? "60vh" : "85vh"
                } - ${overallCustomBorderPadding} px)`,
                backgroundColor: "",
                overflow: "",
              }}
            >
              <div
                role="button"
                onClick={isDepthCrossSectional ? null : handleClickOpen}
                style={{
                  height: `calc(${
                    isDepthCrossSectional ? "60vh" : "85vh"
                  } - ${overallCustomBorderPadding}px)`,
                  backgroundColor: "",
                  overflow: "",
                  cursor: "pointer",
                }}
                id="image-div"
                aria-controls={open ? "image-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onContextMenu={(e) => {
                  e.preventDefault();
                  handleMenuClick(e);
                }}
              >
                {isAdvanceProjectType ? (
                  <Grid container spacing={0} sx={{ height: "100%" }}>
                    <Grid
                      item
                      xs={6}
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      {/* <StitchNormalImage defectNameList={defectNameList} sectionBBox={sectionBBox} selectedSegment={selectedSegment} overallCustomBorderPadding={overallCustomBorderPadding} /> */}
                      <ImageAnnotatorV3
                        imageUrl={selectedSegment?.stitchPath}
                        defaultHeight={isDepthCrossSectional ? 60 : 85}
                        defaultPadding={overallCustomBorderPadding}
                        imageHeight={selectedSegment?.HRHeight}
                        imageWidth={selectedSegment?.HRWidth}
                        drawMap={showMaskImage ? false : true}
                        editBox={false}
                        onValueChange={(value) => handleBoxChange(value)}
                        nonEditableBbox={bbox}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <ImageAnnotatorV3
                        distressPinned={distressPinned}
                        imageUrl={selectedSegment?.maskPath}
                        defaultHeight={isDepthCrossSectional ? 60 : 85}
                        defaultPadding={overallCustomBorderPadding}
                        imageHeight={selectedSegment?.HRHeight}
                        imageWidth={selectedSegment?.HRWidth}
                        drawMap={showMaskImage ? false : true}
                        editBox={false}
                        onValueChange={(value) => handleBoxChange(value)}
                        nonEditableBbox={bbox}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container spacing={1} sx={{ height: "100%" }}>
                    <Grid
                      item
                      xs={6}
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      {/* <StitchNormalImage defectNameList={defectNameList} sectionBBox={sectionBBox} selectedSegment={selectedSegment} overallCustomBorderPadding={overallCustomBorderPadding} /> */}
                      {isDepthCrossSectional ? (
                        <StichedAnnotator
                          linePoints={linePoints}
                          setLinePoints={setLinePoints}
                          firstCircleRef={firstCircleRef}
                          secondCircleRef={secondCircleRef}
                          thirdCircleRef={thirdCircleRef}
                          fourthCircleRef={fourthCircleRef}
                          proid={proid}
                          depthCrossSectionalProfile={
                            depthCrossSectionalProfile
                          }
                          predictions={predictions}
                          sections={sections}
                          crossSectionalPoints={crossSectionalPoints}
                          isDepthCrossSectional={isDepthCrossSectional}
                          imageUrl={
                            showMaskImage
                              ? selectedSegment?.maskPath
                              : selectedSegment?.stitchPath
                          }
                          defaultHeight={60}
                          defaultPadding={overallCustomBorderPadding}
                          imageHeight={selectedSegment?.HRHeight}
                          imageWidth={selectedSegment?.HRWidth}
                          onValueChange={(value) => handleLineChange(value)}
                        />
                      ) : (
                        <ImageAnnotatorV3
                          distressPinned={distressPinned}
                          imageUrl={
                            showMaskImage
                              ? selectedSegment?.maskPath
                              : selectedSegment?.stitchPath
                          }
                          defaultHeight={isDepthCrossSectional ? 60 : 85}
                          defaultPadding={overallCustomBorderPadding}
                          imageHeight={selectedSegment?.HRHeight}
                          imageWidth={selectedSegment?.HRWidth}
                          drawMap={showMaskImage ? false : true}
                          editBox={false}
                          onValueChange={(value) => handleBoxChange(value)}
                          nonEditableBbox={bbox}
                        />
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      {/* <DepthMapImage sectionBBox={sectionBBox} selectedSegment={selectedSegment} overallCustomBorderPadding={overallCustomBorderPadding} /> */}
                      {isDepthCrossSectional ? (
                        <DepthAnnotator
                          linePoints={linePoints}
                          setLinePoints={setLinePoints}
                          firstCircleRef={firstCircleRef}
                          secondCircleRef={secondCircleRef}
                          thirdCircleRef={thirdCircleRef}
                          fourthCircleRef={fourthCircleRef}
                          proid={proid}
                          depthCrossSectionalProfile={
                            depthCrossSectionalProfile
                          }
                          predictions={predictions}
                          sections={sections}
                          crossSectionalPoints={crossSectionalPoints}
                          isDepthCrossSectional={isDepthCrossSectional}
                          imageUrl={selectedSegment?.depthMapPath}
                          defaultHeight={60}
                          defaultPadding={overallCustomBorderPadding}
                          imageHeight={selectedSegment?.HRHeight}
                          imageWidth={selectedSegment?.HRWidth}
                          onValueChange={(value) => handleLineChange(value)}
                        />
                      ) : (
                        <ImageAnnotatorV3
                          imageUrl={selectedSegment?.depthMapPath}
                          defaultHeight={85}
                          defaultPadding={overallCustomBorderPadding}
                          imageHeight={selectedSegment?.HRHeight}
                          imageWidth={selectedSegment?.HRWidth}
                          drawMap={false}
                          editBox={false}
                        />
                      )}
                    </Grid>
                  </Grid>
                )}
              </div>
            </Grid>
            {isDepthCrossSectional ? (
              <Grid
                item
                xs={12}
                style={{
                  height: `calc(25vh - ${0}px)`,
                  backgroundColor: "",
                  overflow: "",
                  padding: "10px",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: `calc(25vh - ${20}px)`,
                    backgroundColor: "",
                    overflow: "",
                    display: "",
                    justifyContent: "",
                  }}
                >
                  <LineChart />
                </div>
              </Grid>
            ) : (
              <></>
            )}
          </>
        )}
      </Grid>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    project: state.project,
    datastore: state.datastore,
    userData: state.firebase.profile,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    depthCrossSectionalProfile: (points, proid, sectionId) =>
      dispatch(depthCrossSectionalProfile(points, proid, sectionId)),
    saveDepthCrossSectionalPoints: (points, proid, sectionId) =>
      dispatch(saveDepthCrossSectionalPoints(points, proid, sectionId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SegmentShifterV2);

import React, { useEffect, useState } from 'react';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Box, Button, MenuItem, Typography, IconButton } from '@mui/material';
import FilterDistressInput from './FilterDistressInput';
import SmartUtils from '../../../../../helpers/SmartUtils';
import FilterChips from './FilterChips';
export default function FilterDistressDetails({
    selectedWaterPumping,
    onSelectWaterPumping,
    onSelectTransversePosition,
    selectedTransversePosition,
    dataInfo,
    isOpen,
    selectedFilter,
    handleFilterChange,
    availableFilters,
    selectedRange,
    handleRangeChange,
    value,
    defectKey,
    readOnly,
    defectType,
    handleValueChange, handleCrackChange, selectedCrack, handleApply, handleClearAll, selectedSeverity, onSelectSeverity
}) {
    const [isOpenCrack, setIsOpenCrack] = useState(false);
    const typesCrack = SmartUtils.getListOfObjectValuesData(dataInfo.type);
    const typeTransversePosition = SmartUtils.getListOfObjectValuesData(dataInfo.transversePosition ?? []);
    const typeWaterPumping = SmartUtils.getListOfObjectValuesData(dataInfo.waterPumping ?? []);
    const typeSeverity = SmartUtils.getListOfObjectValuesData(dataInfo.severity ?? []);
    const defectName = SmartUtils.capitalizeFirstLetter(defectType);
    const showRangeAndValueField = SmartUtils.getTypeFieldList();
    const limits = SmartUtils.getLimitList();

    useEffect(() => {
        if (!isOpen) {
            setIsOpenCrack(false);
        }
    }, [isOpen]);
    return (
        <>
            <Box p={3} mt={2} border={1} borderRadius={4} borderColor="grey.600" sx={{
                display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer',
                height: '15px',
            }}
                onClick={() => setIsOpenCrack(!isOpenCrack)}
            >
                <Typography
                    variant="subtitle1"
                >
                    {defectName}
                </Typography>
                <IconButton size="small" edge="end">
                    {isOpenCrack ?
                        <ExpandLessIcon />
                        :
                        <ExpandMoreIcon />
                    }
                </IconButton>
            </Box>
            <Box p={2} mt={2} display={isOpenCrack ? "block" : "none"} border={1} borderRadius={4} borderColor="grey.600">
                {/* <FilterDistressInput
                        id="filter"
                        label="Filter"
                        value={selectedFilter}
                        onChange={handleFilterChange}
                    >
                        {availableFilters.map((filter, index) => (
                            <MenuItem key={index} value={filter}>
                                {filter}
                            </MenuItem>
                        ))}
                    </FilterDistressInput> */}

                {/* Properties Chips  */}
                {availableFilters.map(({ original, display }, index) => (<FilterChips
                    key={index}
                    label={`${display} ${SmartUtils.getPropertyUnit(original)}`} // Display uppercase name
                    onClick={() => !readOnly && handleFilterChange({ target: { value: original } })} // Use original property value
                    clickable={!readOnly}
                    color={selectedFilter === original ? "primary" : 'default'}
                    variant="outlined"
                />)
                )}

                {/* {availableFilters.map((filter, index) => (
                    <FilterChips
                        key={index}
                        label={`${filter} ${SmartUtils.getPropertyUnit(filter)}`}
                        condition={selectedFilter === filter}
                        onClick={() => !readOnly && handleFilterChange({ target: { value: filter } })}
                        clickable={!readOnly}
                    />
                ))} */}

                {defectType === "Crack" ?
                    <FilterDistressInput
                        id="crack-classification"
                        label="Crack type"
                        value={selectedCrack}
                        onChange={handleCrackChange}
                        disabled={selectedFilter === ''}
                    >
                        {typesCrack.map((item) => (
                            <MenuItem key={item.key} value={item.key}>
                                {item.name}
                            </MenuItem>
                        ))}
                    </FilterDistressInput>
                    : <></>}

                {selectedFilter === 'transversePosition' ?
                    <FilterDistressInput
                        id="transverse-position-type"
                        label="Transverse position type"
                        value={selectedTransversePosition}
                        onChange={onSelectTransversePosition}
                        disabled={selectedFilter === ''}
                    >
                        {typeTransversePosition.map((item) => (
                            <MenuItem key={item.key} value={item}>
                                {item.name}
                            </MenuItem>
                        ))}
                    </FilterDistressInput>
                    : <></>}
                {selectedFilter === 'waterPumping' ?
                    <FilterDistressInput
                        id="water-pumping-type"
                        label="Water pumping type"
                        value={selectedWaterPumping}
                        onChange={onSelectWaterPumping}
                        disabled={selectedFilter === ''}
                    >
                        {typeWaterPumping.map((item) => (
                            <MenuItem key={item.key} value={item}>
                                {item.name}
                            </MenuItem>
                        ))}
                    </FilterDistressInput>
                    : <></>}
                {selectedFilter === 'severity' ?
                    <FilterDistressInput
                        id="severity-type"
                        label="Severity type"
                        value={selectedSeverity}
                        onChange={onSelectSeverity}
                        disabled={selectedFilter === ''}
                    >
                        {typeSeverity.map((item) => (
                            <MenuItem key={item.key} value={item}>
                                {item.name}
                            </MenuItem>
                        ))}
                    </FilterDistressInput>
                    : <></>}

                {/* Range */}
                {!showRangeAndValueField.includes(selectedFilter) ?
                    <FilterDistressInput
                        id="range"
                        label="Range"
                        value={selectedRange}
                        onChange={handleRangeChange}
                        disabled={selectedFilter === ''}
                    >
                        {limits.map((range, index) => (
                            <MenuItem key={index} value={range}>
                                {range}
                            </MenuItem>
                        ))}
                    </FilterDistressInput>
                    : <></>}

                {/* Value */}
                {!showRangeAndValueField.includes(selectedFilter) ?
                    <FilterDistressInput
                        inputType="text"
                        label="Value"
                        value={value[defectKey] || ''}
                        onChange={(e) => handleValueChange(defectKey, e.target.value)}
                        disabled={selectedFilter === ''}
                    />
                    : <></>
                }
                {/* Buttons */}
                <Box mt={2} display="flex" justifyContent="end">
                    <Button
                        variant="contained"
                        size="small"
                        sx={{
                            marginInline: '5px',
                            textTransform: 'initial',
                            backgroundColor: '#025196',
                            ':hover': { backgroundColor: '#025196' },
                        }}
                        onClick={() => handleApply(defectKey, selectedFilter)}
                    >
                        Apply
                    </Button>
                    <Button
                        variant="text"
                        size="small"
                        sx={{
                            textTransform: 'initial',
                            ':hover': { backgroundColor: '#025196', color: '#ffffff' },
                        }}
                        onClick={handleClearAll}
                    >
                        Clear
                    </Button>
                </Box>
            </Box >
        </>
    );
}
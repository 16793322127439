import { LayerGroup, LayersControl, MapContainer, Polyline, TileLayer, useMap, Tooltip, Circle, } from 'react-leaflet'
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import marker from '../../../../../../assets/pin_sm.svg';
import { useEffect, useRef, useState } from 'react';
import { CircularProgress } from '@mui/material';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';

const PotholeMap = ({ basicImagingData, distressDataList = [], fps = 24, dataList = [], zoom = 10, assetData = [], polyPoints = [], allPolyPoints = [], pathColor = '#025196', center = [], geoKey = "geo", pinned, handleSeekTo, handlePinned, playerRef, checkData = true, radius = 10 }) => {
    const { defectNames, dataInfo } = basicImagingData;
    const { typeIndex } = dataInfo;
    const mapRef = useRef('null')
    const [videoPlayerState, setVideoPlayerState] = useState(null)
    const handleBind = (state) => {
        setVideoPlayerState(state);
    }

    useEffect(() => {
        if (playerRef) {
            playerRef?.current?.subscribeToStateChange(handleBind)
        }
    });
    if (defectNames === undefined) {
        return <></>
    }
    const Recenter = ({ lat, lng }) => {
        const map = useMap();
        useEffect(() => {
            map.setView([lat, lng]);
        }, [lat, lng]);
        return null;
    }

    const iconSize = [26 / 1, 38 / 1];
    const DrIcon = new L.Icon({
        iconUrl: marker,
        iconRetinaUrl: marker,
        iconAnchor: [iconSize[0] / 2, iconSize[1]],
        popupAnchor: [-0, -0],
        iconSize: iconSize,
    });
    L.Marker.prototype.options.icon = DrIcon;
    if (checkData) {
        if (assetData.length === 0) {
            return <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress sx={{ color: 'green' }} />
            </div>
        }
    }

    const centerCoor = allPolyPoints[0]
    const pathPolyPoints = polyPoints.length === 0 ? dataList.map((e) => e[geoKey]) : polyPoints
    const mapCenter = center.length === 2 ? [center.lat, center.lng] : [centerCoor.lat, centerCoor.lng]
    const videoTime = videoPlayerState?.currentTime
    const gpsObj = allPolyPoints.length !== 0 ? allPolyPoints.reduce((prev, curr) => {
        return (Math.abs(curr.time * 1000 - videoTime * 1000) < Math.abs(prev.time * 1000 - videoTime * 1000) ? curr : prev);
    }) : [];
    return (
        <div style={{ height: '100%' }}>
            <MapContainer ref={mapRef} attributionControl={false} center={mapCenter} zoom={19} scrollWheelZoom={true} maxZoom={20}>
                <TileLayer
                    attribution=''
                    maxZoom={20}
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Recenter lat={mapCenter[0]} lng={mapCenter[1]} />
                <ReactLeafletGoogleLayer apiKey='AIzaSyA1Zq5IDPlPl_i4MnG9wCBg8s6XQrfB9Ig' type={'roadmap'} maxZoom={25} maxNativeZoom={22} />
                <LayersControl position="topright">
                    <LayersControl.Overlay name="Road" checked>
                        <LayerGroup>
                            {pathPolyPoints.length === 0 ? <Polyline weight={5} stroke={1000} positions={allPolyPoints} pathOptions={{ color: 'grey' }} >
                            </Polyline> : pathPolyPoints.map((e, i) => <Polyline weight={8} stroke={1000} key={i} positions={e} pathOptions={{ color: 'grey' }} >
                                <Tooltip>Section {i + 1}</Tooltip>
                            </Polyline>)}
                        </LayerGroup>
                    </LayersControl.Overlay>

                    <LayersControl.Overlay name="Current location" checked={true}>
                        <LayerGroup>
                            <Circle
                                center={gpsObj}
                                pathOptions={{ fillColor: 'white', color: 'black', fillOpacity: 1, stroke: true, fill: true }}
                                radius={1.5}>
                                <Tooltip>{new Date(gpsObj.time * 1000).toISOString().slice(11, 19)}</Tooltip>
                            </Circle>
                        </LayerGroup>
                    </LayersControl.Overlay>

                    {distressDataList.length === 0 ?
                        <LayersControl.Overlay name="Distresses" checked={true}>
                            <LayerGroup>
                                {dataList.map((e, i) => {
                                    const { id, defectName } = e
                                    const defectValue = defectName === 1 ? typeIndex?.[e.typeIndex]?.value : '';
                                    const displayName = defectNames?.[defectName] || 'unnamed';
                                    const obj = {
                                        pred: e,
                                    }
                                    const roadSideColor = {
                                        "0": 'blue',
                                        "1": 'red',
                                        "-1": 'purple',
                                    }
                                    const circleColor = obj.pred.roadSide in roadSideColor ? roadSideColor[obj.pred.roadSide] : roadSideColor['-1']
                                    return (
                                        <Circle
                                            key={id}
                                            eventHandlers={{
                                                click: () => {
                                                    handleSeekTo(e['frameTime']);
                                                    handlePinned(obj)
                                                }
                                            }}
                                            radius={1}
                                            center={obj.pred[geoKey]}
                                            pathOptions={{ fillColor: circleColor, color: (pinned?.pred?.id ?? "-1") === obj.pred.id ? 'greenyellow' : circleColor, fillOpacity: 1, stroke: true, fill: true }}
                                        >
                                            <Tooltip>
                                                {defectNames && typeIndex ? `${defectValue} ${displayName}` : displayName}
                                            </Tooltip>

                                        </Circle>
                                    );
                                })}
                            </LayerGroup>
                        </LayersControl.Overlay>
                        : <></>}
                </LayersControl>

            </MapContainer>
        </div>
    );
}

export default PotholeMap;
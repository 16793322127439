/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 cracks_in_asfalt_road_-_free.glb
Author: matousekfoto (https://sketchfab.com/matousekfoto)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/cracks-in-asfalt-road-free-46f4d5c84885485f8260e3b67420ed66
Title: Cracks in asfalt road - free
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import MODEL from '../../../assets/models/model.glb'

export function Model(props) {
  const { nodes, materials } = useGLTF(MODEL)
  return (
    <group {...props} dispose={null}>
      <group position={[0, -0.12, -0.69]} rotation={[Math.PI / 2, 0, 0]} scale={0.06}>
        <mesh geometry={nodes.meshNode_Material_u1_v1_0.geometry} material={materials.Material_u1_v1} rotation={[-Math.PI, 0, 0]} />
      </group>
    </group>
  )
}

useGLTF.preload(MODEL)

import { Typography } from '@mui/material';
import React from 'react';
import commonNames from '../../constants/commonNames.json';

const CreditTransferComponent = ({ creditValue, payFromSubscription, projectType }) => {
    return (
        <Typography>
            Are you sure you want to proceed with the {projectType === 2 ? 'processing' : 'analysis'} by transferring {creditValue} {commonNames.credits} from your {payFromSubscription ? 'subscription wallet' : 'account'} to the project?
        </Typography>
    );
};


export default CreditTransferComponent;

import React, { useState, useEffect, useRef } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import DateParser from '../../../../helpers/DateParser';
import 'react-big-calendar/lib/css/react-big-calendar.css';
// import { Typography, IconButton, ClickAwayListener, Card } from '@mui/material';
// import ReactAnimatedWeather from 'react-animated-weather';
// import CloseIcon from '@mui/icons-material/Close';
import SmartUtils from '../../../../helpers/SmartUtils';
const localizer = momentLocalizer(moment);
const SavedRoutesPlanningSchedule = ({ routesData, availableViews, defaultView, initialDate, RoutePlannerData }) => {
    const [events, setEvents] = useState([]);
    // const [selectedEvent, setSelectedEvent] = useState(null);
    // const [isEventBoxOpen, setIsEventBoxOpen] = useState(false);
    const [currentDate, setCurrentDate] = useState(initialDate || new Date());
    const divRef = useRef();
    const [divHeight, setDivHeight] = useState(divRef?.current?.clientHeight);
    const [calenderView, setCalendarView] = useState('month');
    useEffect(() => {
        setCurrentDate(initialDate);
    }, [initialDate])

    useEffect(() => {
        window.addEventListener("load", setDivHeight(divRef?.current === null ? 0 : divRef?.current?.clientHeight))
        window.addEventListener("resize", getVidWindowSize, true);
    }, []);

    const getVidWindowSize = () => {
        const newHeight = divRef?.current?.clientHeight;
        setDivHeight(newHeight);
    };

    const handleView = (view) => {
        setCalendarView(view);
    }
    const fetchEventData = async () => {
        const filteredRoutesData = routesData.filter(route => route.score > 50 && route.scoreRev > 50);
        const updatedEvents = await Promise.all(
            filteredRoutesData.map(async (route, i) => {
                const item = RoutePlannerData.find((d) => d.id === route.rpid);
                const startAndEndDate = DateParser(route.date, 6);
                const startTime = route.startLocalTime ? DateParser(route.startLocalTime, 7) : DateParser(route.startTime, 5);
                const endTime = route.endLocalTime ? DateParser(route.endLocalTime, 7) : DateParser(route.endTime, 5);
                const startDateTime = moment(startAndEndDate + ' ' + startTime).toDate();
                const endDateTime = moment(startAndEndDate + ' ' + endTime).toDate();

                return {
                    id: i,
                    title: `${startTime} - ${endTime}`,
                    start: startDateTime,
                    end: endDateTime,
                    description: item ? `${item.title} - Forward direction` : 'Forward direction',
                    descriptionRev: item ? `${item.title} - Reverse direction` : 'Reverse direction',
                    weather: route.weather,
                    className: 'custom-event',
                    score: route.score ?? -1,
                    scoreRev: route.scoreRev ?? -1
                };
            })
        );
        setEvents(updatedEvents);
    };
    useEffect(() => {
        fetchEventData();
    }, [routesData]);

    // const handleEventSelect = (event) => {
    //     setSelectedEvent(event);
    //     setIsEventBoxOpen(true);
    // };

    // const handleCloseEventBox = () => {
    //     setSelectedEvent(null);
    //     setIsEventBoxOpen(false);
    // };
    const eventCellRenderer = ({ event }) => {
        return (
            <div className="custom-event-cell" style={{
                // height: '100%',
                overflow: 'auto',
                // background:'red'
            }}>

                <div style={{ height: '50%' }}>
                    <span style={{ display: 'flex' }}>
                        <div style={{ borderTopLeftRadius: "16px", borderBottomLeftRadius: '16px', height: `${divHeight}px`, width: calenderView !== 'day' ? '10%' : '', background: `${SmartUtils.mapScoreColor(event.score)}` }}></div>
                        <div ref={divRef} className="event-title" style={{ borderTopRightRadius: '8px', borderBottomRightRadius: '8px', display: 'flex', alignItems: 'center', height: "100%", width: calenderView !== 'day' ? '90%' : '', background: `${SmartUtils.mapScoreColor(event.score)}`, padding: '10px', opacity: '0.75', color: 'white' }}>{event.description}</div>
                    </span>
                </div >

                <div style={{ height: '50%', paddingTop: '5px' }}>
                    <span style={{ display: 'flex' }}>
                        <div style={{ borderTopLeftRadius: "16px", borderBottomLeftRadius: '16px', height: `${divHeight}px`, width: calenderView !== 'day' ? '10%' : '', background: `${SmartUtils.mapScoreColor(event.scoreRev)}` }}></div>
                        <div ref={divRef} className="event-title" style={{ borderTopRightRadius: '8px', borderBottomRightRadius: '8px', display: 'flex', alignItems: 'center', height: "100%", width: calenderView !== 'day' ? '90%' : '', background: `${SmartUtils.mapScoreColor(event.scoreRev)}`, padding: '10px', opacity: '0.75', color: 'white' }}>{event.descriptionRev}</div>
                    </span>
                </div>

            </div >
        );
    };
    // function mapWeatherToPackage(weatherDescription) {
    //     const mapping = {
    //         "Sunny": "CLEAR_DAY",
    //         "Clear sky": "CLEAR_DAY",
    //         "Light clouds": "PARTLY_CLOUDY_DAY",
    //         "Partly cloudy": "PARTLY_CLOUDY_DAY",
    //         "Cloudy": "CLOUDY",
    //         "Rain": "RAIN",
    //         "Rain and snow / sleet": "RAIN",
    //         "Snow": "SNOW",
    //         "Rain shower": "RAIN",
    //         "Snow shower": "SNOW",
    //         "Sleet shower": "SLEET",
    //         "Light Fog": "FOG",
    //         "Dense fog": "FOG",
    //         "Freezing rain": "RAIN",
    //         "Thunderstorms": "RAIN",
    //         "Drizzle": "RAIN",
    //         "Sandstorm": "SAND"
    //     };

    //     return mapping[weatherDescription] || "UNKNOWN_PACKAGE_CODE";
    // }
    const handleNavigate = (date, view) => {
        // Set the current date when navigating
        setCurrentDate(date);
    };


    return (
        <div style={{ height: '100vh', padding: '0px', overflow: 'auto' }}>
            <Calendar
                scrollToTime={currentDate}
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                style={{ height: '100vh' }}
                // onSelectEvent={handleEventSelect}
                views={availableViews}
                defaultView={defaultView}
                // date={initialDate}
                // date={moment("2023-8-24").toDate()}
                // toolbar={false}
                // formats={{da}}
                // timeslots={4}
                // step={15}
                date={currentDate}
                onView={handleView}
                onNavigate={handleNavigate}
                components={{
                    event: eventCellRenderer
                }}
            />
            {/* {selectedEvent && isEventBoxOpen && (
                <ClickAwayListener onClickAway={handleCloseEventBox}>
                    <Card
                        sx={{
                            position: 'fixed',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '300px',
                            backgroundColor: '#ffffff',
                            borderRadius: '15px',
                            // boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                            padding: '10px',
                            textAlign: 'start',
                            zIndex: 9999,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <IconButton
                            onClick={handleCloseEventBox}
                            style={{ alignSelf: 'flex-end', cursor: 'pointer', marginBottom: '10px' }}
                        >
                            <CloseIcon />
                        </IconButton>
                        {selectedEvent.weather && (
                            <ReactAnimatedWeather
                                icon={mapWeatherToPackage(selectedEvent.weather.condition)}
                                color={'black'}
                                size={45}
                                animate={true}
                            />
                        )}
                        <Typography variant='body2' sx={{ marginBottom: '10px' }}>
                            This route will be begin at  {moment(selectedEvent.startLocalTime ? selectedEvent.startLocalTime : selectedEvent.start).format('h:mm a')} and will end at  {moment(selectedEvent.endLocalTime ? selectedEvent.endLocalTime : selectedEvent.end).format('h:mm a')} and the weather will be {`${selectedEvent.weather.condition}`.toLocaleLowerCase()}.
                        </Typography>
                    </Card>
                </ClickAwayListener>
            )} */}
        </div>
    )
}

export default SavedRoutesPlanningSchedule
import React, { useState } from 'react';
import { Stage, Layer, Line, Circle, Image } from 'react-konva';
import { useGenerateSignedUrl } from '../../../../../chooks/useGenerateSignedUrl';
import { useEffect } from 'react';
import { useAccessControlContext } from '../../../../../contexts/AccessControlProvider';

const LineDrawing = ({ linePoints, setLinePoints, firstCircleRef, secondCircleRef, thirdCircleRef, fourthCircleRef, defaultHeight, defaultPadding, proid, depthCrossSectionalProfile, predictions, sections, crossSectionalPoints, isDepthCrossSectional, imageUrl, imageWidth, imageHeight, onValueChange = (value = []) => { } }) => {
    // //////console.log(crossSectionalPoints,"crossSectionalPointsDepth");
    const { permissions } = useAccessControlContext();
    const { canChangeCrossSectionDistress } = permissions;
    const [image, setImage] = useState(null);
    const [originalWidth, setOriginalWidth] = useState(0);
    const [originalHeight, setOriginalHeight] = useState(0);
    // const [linePoints, setLinePoints] = useState(crossSectionalPoints);
    const { status, error, data } = useGenerateSignedUrl(imageUrl);
    const isFetched = status === 'fetched'

    useEffect(() => {
        const img = new window.Image();
        img.src = data;
        img.onload = () => {
            setImage(img);
            setOriginalWidth(imageWidth);
            setOriginalHeight(imageHeight);
        };
    }, [data]);

    useEffect(() => {
        window.addEventListener("resize", getVidWindowSize);
    }, [])

    const getVidWindowSize = () => {
        const img = new window.Image();
        img.src = data;
        img.onload = () => {
            setImage(img);
            setOriginalWidth(imageWidth);
            setOriginalHeight(imageHeight);
        };
    };

    const calculateAspectRatio = () => {
        if (image) {
            const stage = document.getElementById('stage');
            const divHeight = stage.clientHeight;
            const divWidth = stage.clientWidth;
            const imageHeight = originalHeight
            const imageWidth = originalWidth
            const asDiv = (divHeight / divWidth)
            const asImg = (imageHeight / imageWidth)
            const ratio = asDiv / asImg
            let svgWidth = 0
            let svgHeight = 0
            if (ratio >= 1) {
                svgWidth = divWidth
                svgHeight = asImg * divWidth
            } else {
                svgHeight = divHeight
                svgWidth = (1 / asImg) * divHeight
            }

            return { width: svgWidth, height: svgHeight };
        }
        return { width: 0, height: 0 };
    };

    const aspectRatio = calculateAspectRatio();
    const scaleFactor = originalHeight / aspectRatio.height;
    // //////console.log(crossSectionalPoints, "crossSectionalPoints", scaleFactor)
    useEffect(() => {
        if (!isNaN(scaleFactor)) {
            updateLinePoints();
        }
    }, [scaleFactor])
    const updateLinePoints = () => {
        if (!isNaN(scaleFactor)) {
            const linePoint = crossSectionalPoints.map((eachPoint) => eachPoint / scaleFactor)
            setLinePoints(linePoint)
        }
    }
    const handleAnchorDrag = (index, event) => {
        // Adjust the coordinates based on the scaleFactor
        let newX = event.target.x();
        let newY = event.target.y();

        if (index === 0) {
            thirdCircleRef?.current.setX(newX);
            thirdCircleRef?.current.setY(newY);
        }
        if (index === 2) {
            fourthCircleRef?.current.setX(newX);
            fourthCircleRef?.current.setY(newY);
        }
        // newX = Math.max(0, Math.min(newX, aspectRatio.width)); // Adjusted for circle radius
        // newY = Math.max(0, Math.min(newY, aspectRatio.height)); // Adjusted for circle radius

        const newLinePoints = [...linePoints];
        newLinePoints[index] = newX;
        newLinePoints[index + 1] = newY;
        setLinePoints(newLinePoints);
        onValueChange(
            [(newLinePoints[0] * scaleFactor), (newLinePoints[1] * scaleFactor), (newLinePoints[2] * scaleFactor), (newLinePoints[3] * scaleFactor)])
        // onValueChange([[(newLinePoints[0] * scaleFactor), (newLinePoints[1] * scaleFactor)], [(newLinePoints[2] * scaleFactor), (newLinePoints[3] * scaleFactor)]])
    };
    const handleDragEnd = () => {
        const sectionId = Object.values(predictions)[sections]['sectionId'];
        // const points = { x1: crossSectionalPoints[0], y1: crossSectionalPoints[1], x2: crossSectionalPoints[2], y2: crossSectionalPoints[3] }
        const points = { x1: (linePoints[0] * scaleFactor), y1: (linePoints[1] * scaleFactor), x2: (linePoints[2] * scaleFactor), y2: (linePoints[3] * scaleFactor) }
        depthCrossSectionalProfile(points, proid, sectionId);
    }
    return (
        <div id='stage' style={{ height: `calc(${defaultHeight}vh - ${defaultPadding}px)`, width: '100%', display: 'flex', justifyContent: 'center', cursor: 'pointer' }}>
            <Stage width={aspectRatio.width}
                height={aspectRatio.height}>
                {isFetched ? (
                    <Layer>
                        <Image
                            image={image}
                            width={aspectRatio.width} height={aspectRatio.height}
                        />
                    </Layer>
                ) : null}
                {isDepthCrossSectional && isFetched ? <Layer>
                    <Line points={[(linePoints[0]), (linePoints[1]), (linePoints[2]), (linePoints[3])]} stroke="yellow" strokeWidth={2} />
                    <Circle

                        ref={firstCircleRef}
                        x={linePoints[0]}
                        y={linePoints[1]}
                        radius={10}
                        fill="green"
                        draggable={canChangeCrossSectionDistress}
                        dragBoundFunc={(pos, e) => {
                            var newX = pos.x
                            var newY = pos.y

                            return (
                                {
                                    x: newX < 0 ? 0 : newX > aspectRatio.width ? aspectRatio.width : newX,
                                    y: newY < 0 ? 0 : newY > aspectRatio.height ? aspectRatio.height : newY,
                                }
                            )
                        }}
                        onDragMove={(event) => handleAnchorDrag(0, event)}
                        onDragEnd={handleDragEnd}

                    />
                    <Circle
                        ref={secondCircleRef}
                        x={linePoints[2]}
                        y={linePoints[3]}
                        radius={10}
                        fill="red"
                        draggable={canChangeCrossSectionDistress}
                        dragBoundFunc={(pos, e) => {
                            var newX = pos.x
                            var newY = pos.y

                            return (
                                {
                                    x: newX < 0 ? 0 : newX > aspectRatio.width ? aspectRatio.width : newX,
                                    y: newY < 0 ? 0 : newY > aspectRatio.height ? aspectRatio.height : newY,
                                }
                            )
                        }}
                        onDragMove={(event) => handleAnchorDrag(2, event)}
                        onDragEnd={handleDragEnd}
                    />
                </Layer> : <></>}
            </Stage>
        </div>
    );
};

export default LineDrawing;
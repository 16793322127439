import { Alert } from '@mui/material'
import React from 'react'

const VideoSelectorRecordWarningList = ({ distanceErrorMessage }) => {
    return (
        <>
            {distanceErrorMessage.map((eachMessage) => (
                <Alert variant="outlined" severity="warning" sx={{ marginBottom: '5px' }}>
                    {eachMessage}
                </Alert>
            ))}
        </>
    )
}

export default VideoSelectorRecordWarningList
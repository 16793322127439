import React, { useState } from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Box, CircularProgress } from '@mui/material';
import { TablePagination } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ControlRoomDetailsBox from './ControlRoomDetailsBox';

const ControlRoomTable = ({ projectData, isCompleted, isQueued, controlRoomData, isLoading }) => {
    const { priority, projectType, status } = controlRoomData;
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [open, setOpen] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleOpen = (row) => {
        setSelectedRows(row);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const formatDate = (timestamp) => {
        if (!timestamp) {
            return "Not specified";
        }
        const date = new Date(timestamp);
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
    };
    if (isLoading) {
        return (
            <Paper
                sx={{
                    width: '100%',
                    height: '100%',
                    padding: "20px",
                    marginTop: "10px",
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <CircularProgress />
            </Paper>
        )
    }
    return (
        <Paper
            sx={{
                width: '100%',
                height: '90%',
                padding: "20px",
                marginTop: "10px",
            }}
        >
            {projectData.length === 0 ? (
                <Box sx={{ marginTop: "15px", display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <Typography variant="h6">There are no projects</Typography>
                </Box>
            ) : (
                <TableContainer sx={{ maxHeight: "500px", overflowY: "auto" }}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: "16px", backgroundColor: "white" }}>Sr No</TableCell>
                                <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: "16px", backgroundColor: "white" }}>Project Title</TableCell>
                                <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: "16px", backgroundColor: "white" }}>Creator Name</TableCell>
                                <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: "16px", backgroundColor: "white" }}>Project Type</TableCell>
                                <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: "16px", backgroundColor: "white" }}>Priority</TableCell>
                                {!isCompleted && (
                                    <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: "16px", backgroundColor: "white" }}>Request Generated Time</TableCell>
                                )}
                                {!isCompleted ? (
                                    <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: "16px", backgroundColor: "white", zIndex: 1000 }}>Details</TableCell>
                                ) : (
                                    <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: "16px", backgroundColor: "white", zIndex: 1000 }}>Proid</TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {projectData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>{row.projectTitle}</TableCell>
                                    <TableCell>{row.creatorName}</TableCell>
                                    <TableCell>{projectType[row.projectType]}</TableCell>
                                    <TableCell>{row.priority != null ? priority[row.priority] : "Not specified"}</TableCell>
                                    {!isCompleted && (
                                        <TableCell>{formatDate(row.requestTime)}</TableCell>
                                    )}
                                    {!isCompleted ? (
                                        <TableCell>
                                            <IconButton
                                                aria-label="details"
                                                disableRipple={true}
                                                sx={{
                                                    border: '1px solid #777777',
                                                    '&:focus': {
                                                        border: '1px solid #777777',
                                                    },
                                                }}
                                                size="small"
                                                onClick={() => handleOpen(row)}
                                            >
                                                <ArrowForwardIosIcon />
                                            </IconButton>
                                        </TableCell>
                                    ) : (
                                        <TableCell>{row.proid}</TableCell>
                                    )}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            <TablePagination
                rowsPerPageOptions={[10, 20, 25]}
                component="div"
                count={projectData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            {
                !isCompleted && (
                    <ControlRoomDetailsBox open={open} handleClose={handleClose} data={selectedRows} isQueued={isQueued} formatDate={formatDate} priority={priority} status={status} projectType={projectType} />
                )
            }
        </Paper >
    );
}
export default ControlRoomTable;
import React, { useEffect, useState } from 'react'
import { Paper, FormControl, FormControlLabel, Radio, IconButton, Divider, Tooltip } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import Aligator from "../../../../../../assets/icons/Alligator.png";
import Bleeding from "../../../../../../assets/icons/Bleeding.png";
import EdgeDeterioration from "../../../../../../assets/icons/Edge deterioration.png";
import CrackSeals from "../../../../../../assets/icons/Crack seals.png";
import Longitudinal from "../../../../../../assets/icons/longitudinal_crack_type_1.png";
import Block from "../../../../../../assets/icons/block_crack.png";
import Transverse from "../../../../../../assets/icons/Lateral.png";
import Pothole from "../../../../../../assets/icons/pothole.png";
import Patch from "../../../../../../assets/icons/patch.png";
import Crack from "../../../../../../assets/icons/crack.png";
import Vegetation from "../../../../../../assets/icons/Vegetation.png";
import SmartUtils from '../../../../../../helpers/SmartUtils';
import Corrugation from '../../../../../../assets/icons/NewIcons/Corrugation.png';
import Depression from '../../../../../../assets/icons/NewIcons/Depression.png';
import Manhole from '../../../../../../assets/icons/NewIcons/Manhole.png';
import DistressPatch from '../../../../../../assets/icons/NewIcons/Patch.png';
import PolishedAggregate from '../../../../../../assets/icons/NewIcons/Polished aggregate.png';
import DistressRavelling from '../../../../../../assets/icons/NewIcons/Ravelling.png';
import Rutting from '../../../../../../assets/icons/NewIcons/Rutting.png';
import EdgeCrack from '../../../../../../assets/icons/NewIcons/Edge crack.png';
import ReflectiveCrack from '../../../../../../assets/icons/NewIcons/Reflective crack.png';
import SlippageCrack from '../../../../../../assets/icons/NewIcons/Slippage crack.png';
import Other from '../../../../../../assets/icons/NewIcons/Other.png';
import WaterBleedingAndPumping from '../../../../../../assets/icons/NewIcons/Water bleeding and pumping.png';
import { DeleteOutline } from '@mui/icons-material';
const EditOptions = ({
    currentFrame = null, drProjectData, svgDimensions, originalVideoWidth, fps, allPolyPoints, currentTime, projectPredictions, updateDistressV2, proid,
    rectangles, addDistressV2, isAddingDistress, setFieldValues, setShowPointer, handleChange, fieldValues, setSelectedId, setIsEditMode,
    dataInfo, handleDelete
}) => {
    const { projectType } = drProjectData;
    const [sectionId, setSectionId] = useState(null);
    const gpsObj = allPolyPoints.length !== 0 ? allPolyPoints.reduce((prev, curr) => {
        return (Math.abs(curr.time * 1000 - currentTime * 1000) < Math.abs(prev.time * 1000 - currentTime * 1000) ? curr : prev);
    }) : [];
    function mapIt(sectionDocument) {
        let sortedDetections = Object.values(sectionDocument.GPS);
        // sortedDetections.sort((x, y) => x.frameTime - y.frameTime);
        if (sortedDetections.length === 0) {
            return {
                id: sectionDocument.id,
                sectionId: sectionDocument.sectionId,
                initTime: -1,
                endTime: -1,
            };
        }
        return {
            id: sectionDocument.id,
            sectionId: sectionDocument.sectionId,
            initTime: sortedDetections[0].time,
            endTime: sortedDetections.at(-1).time,
        };
    }
    useEffect(() => {
        if (projectType === 5 && projectPredictions) {
            const selectedSection = projectPredictions.map((eachObj) => mapIt(eachObj));
            const pickedSections = selectedSection.filter((eachSegment) =>
                SmartUtils.checkInRange(Number((eachSegment.initTime)).toFixed(5), Number((eachSegment.endTime)).toFixed(5), Number(currentTime).toFixed(5)));
            if (pickedSections.length > 0) {
                const sectionId = pickedSections[0]['id'];
                setSectionId(sectionId)
            }
        }
    }, [projectPredictions])
    const handleSubmit = () => {
        const ratio = (svgDimensions.width / originalVideoWidth);
        if (fieldValues.basicImagingClassState !== '') {
            if (fieldValues.basicImagingClassState === 1 && fieldValues.classification === '') {
                alert('Please select classification');
            }
            else {
                if (isAddingDistress) {
                    const { x, y, width, height } = rectangles.find((each) => each.id === fieldValues.defectId);
                    const bbox = [x / ratio, y / ratio, width / ratio, height / ratio];
                    if (projectType === 5) {
                        addDistressV2(proid, sectionId, bbox, fieldValues.basicImagingClassState, Math.floor(fps * currentTime),
                            { gpsLat: gpsObj.lat, gpsLng: gpsObj.lng }, fieldValues.classification);
                    }
                    else {
                        let defectProperties = {};
                        const defectClass = fieldValues.basicImagingClassState;
                        if (defectClass === 0) {
                            defectProperties = {
                                severity: -1
                            }
                        }
                        else if (defectClass === 1) {
                            defectProperties = {
                                type: fieldValues.classification,
                                severity: -1
                            }

                        }
                        else if (defectClass === 2) {
                            defectProperties = {
                                profile: -1,
                                severity: -1
                            }
                        }
                        else if (defectClass === 3) {
                            defectProperties = {
                                severity: -1
                            }
                        }
                        else if (defectClass === 4) {
                            defectProperties = {
                                wheelPath: -1,
                                severity: -1
                            }
                        }
                        else if (defectClass === 5) {
                            defectProperties = {
                                severity: -1
                            }
                        }
                        else if (defectClass === 6) {
                            defectProperties = {
                                severity: -1
                            }
                        }
                        else if (defectClass === 7) {
                            defectProperties = {
                                severity: -1
                            }
                        }
                        else if (defectClass === 8) {
                            defectProperties = {
                                severity: -1
                            }
                        }
                        else if (defectClass === 9) {
                            defectProperties = {
                                severity: -1
                            }
                        }
                        else if (defectClass === 10) {
                            defectProperties = {
                                severity: -1
                            }
                        }
                        else if (defectClass === 11) {
                            defectProperties = {
                                severity: -1
                            }
                        }
                        else if (defectClass === 12) {
                            defectProperties = {
                                severity: -1
                            }
                        }
                        else if (defectClass === 13) {
                            defectProperties = {
                                severity: -1
                            }
                        }
                        addDistressV2(proid, currentFrame.sectionId ?? '', JSON.stringify({ x: bbox[0], y: bbox[1], w: bbox[2], h: bbox[3] }), defectClass, -1, JSON.stringify(defectProperties));
                    }
                    setIsEditMode(false);
                    setSelectedId(null);
                    setShowPointer(true);
                    setFieldValues({
                        basicImagingClassState: '',
                        sideState: '',
                        priorityState: '',
                        lateralPositionState: '',
                        detectionQualityState: 0,
                        recognitionQualityState: 0,
                        roadSideState: '',
                        frameNumberState: '',
                        classification: '',
                    });
                }
                else {
                    const { x, y, width, height } = rectangles.find((each) => each.id === fieldValues.defectId);
                    const bbox = [x / ratio, y / ratio, width / ratio, height / ratio]
                    if (projectType === 5) {
                        updateDistressV2(proid, fieldValues.sectionId, bbox, fieldValues.basicImagingClassState, fieldValues.frameNumberState,
                            { gpsLat: fieldValues.gpsLat, gpsLng: fieldValues.gpsLng }, fieldValues.classification, fieldValues.defectId);
                    }
                    else {
                        let defectProperties = {};
                        const defectClass = fieldValues.basicImagingClassState;
                        if (defectClass === 0) {
                            defectProperties = {
                                severity: -1
                            }
                        }
                        else if (defectClass === 1) {
                            defectProperties = {
                                type: fieldValues.classification,
                                severity: -1
                            }

                        }
                        else if (defectClass === 2) {
                            defectProperties = {
                                profile: -1,
                                severity: -1
                            }
                        }
                        else if (defectClass === 3) {
                            defectProperties = {
                                severity: -1
                            }
                        }
                        else if (defectClass === 4) {
                            defectProperties = {
                                wheelPath: -1,
                                severity: -1
                            }
                        }
                        else if (defectClass === 5) {
                            defectProperties = {
                                severity: -1
                            }
                        }
                        else if (defectClass === 6) {
                            defectProperties = {
                                severity: -1
                            }
                        }
                        else if (defectClass === 7) {
                            defectProperties = {
                                severity: -1
                            }
                        }
                        else if (defectClass === 8) {
                            defectProperties = {
                                severity: -1
                            }
                        }
                        else if (defectClass === 9) {
                            defectProperties = {
                                severity: -1
                            }
                        }
                        else if (defectClass === 10) {
                            defectProperties = {
                                severity: -1
                            }
                        }
                        else if (defectClass === 11) {
                            defectProperties = {
                                severity: -1
                            }
                        }
                        else if (defectClass === 12) {
                            defectProperties = {
                                severity: -1
                            }
                        }
                        else if (defectClass === 13) {
                            defectProperties = {
                                severity: -1
                            }
                        }
                        updateDistressV2(proid, fieldValues.sectionId, fieldValues.defectId, JSON.stringify({ x: bbox[0], y: bbox[1], w: bbox[2], h: bbox[3] }), defectClass, JSON.stringify(defectProperties))
                    }
                    setIsEditMode(false);
                    setSelectedId(null);
                    setFieldValues({
                        basicImagingClassState: '',
                        sideState: '',
                        priorityState: '',
                        lateralPositionState: '',
                        detectionQualityState: 0,
                        recognitionQualityState: 0,
                        roadSideState: '',
                        frameNumberState: '',
                        classification: '',
                    });
                }
            }

        }
        else {
            alert('Please select details')
        }

    }
    const getIconByDefectName = (defectName) => {
        switch (defectName) {
            case 'Pothole': return Pothole;
            case 'Crack': return Crack;
            case 'Subsidence/Depression': return Depression;
            case 'Ravelling': return DistressRavelling;
            case 'Rutting': return Rutting;
            case 'Patch': return DistressPatch;
            case 'Manhole': return Manhole;
            case 'Bleeding': return Bleeding;
            case 'Corrugation/Shoving': return Corrugation;
            case 'Polished aggregate': return PolishedAggregate;
            case 'Water bleeding and pumping': return WaterBleedingAndPumping;
            case 'Vegetation': return Vegetation;
            case 'Edge Deterioration': return EdgeDeterioration;
            case 'Crack Seal': return CrackSeals;
            case 'Patching': return Patch;
            default: return '';
        }
    };
    const getIconByDefectClassification = (defectName) => {
        switch (defectName) {
            case 'Alligator': return Aligator;
            case 'Longitudinal': return Longitudinal;
            case 'Transverse': return Transverse;
            case 'Block': return Block;
            case 'Edge': return EdgeCrack;
            case 'Reflective': return ReflectiveCrack;
            case 'Slippage': return SlippageCrack;
            case 'Other': return Other;
            default: return '';
        }
    };
    return (
        <div>
            <Paper
                elevation={0}
                sx={(theme) => ({
                    display: 'flex',
                    border: `2px solid ${theme.palette.divider}`,
                    flexWrap: 'wrap',
                    backgroundColor: '#f5f5f5',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '4px 8px',
                })}
            >
                <FormControl component="fieldset">
                    <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                        <FormControlLabel sx={{ marginLeft: '5px', marginTop: "5px" }} value="text" disabled label="Distress type:" control={<Radio sx={{ display: 'none' }} />} />
                        {Object.values(dataInfo.defectName).filter(defect => defect.key !== -1 && defect.enabled).map(defect => (
                            <Tooltip key={defect.key} title={defect.name}>
                                <IconButton
                                    onClick={() => handleChange("basicImagingClassState")({ target: { value: defect.key } })}
                                    sx={{
                                        border: (fieldValues.basicImagingClassState) === defect.key ? '2px solid #1976d2' : '2px solid transparent',
                                        boxShadow: fieldValues.basicImagingClassState === defect.key ? '0px 0px 10px rgba(25, 118, 210, 0.5)' : 'none',
                                        borderRadius: '8px',
                                        padding: '4px',
                                    }}
                                >
                                    <img src={getIconByDefectName(defect.name)} alt={defect.name} style={{ width: '24px', height: '24px' }} />
                                </IconButton>
                            </Tooltip>
                        ))}
                    </div>
                </FormControl>
                {/* Display Classification Icons if Defect Type is Crack */}
                {fieldValues.basicImagingClassState === 1 && (
                    <div style={{ display: 'flex', gap: '8px' }}>
                        <Divider flexItem orientation="vertical" sx={{ height: 'auto', mx: 0.5, my: 1, mr: 2 }} />
                        <FormControlLabel sx={{ marginLeft: '5px', marginTop: "5px" }} value="text" disabled label="Classification:" control={<Radio sx={{ display: 'none' }} />} />
                        {projectType === 5 ? dataInfo.typeIndex.map(defect => (
                            <Tooltip key={defect.key} title={defect.value}>
                                <IconButton
                                    onClick={() => handleChange("classification")({ target: { value: defect.key } })}
                                    sx={{
                                        border: Number(fieldValues.classification) === defect.key ? '2px solid #1976d2' : '2px solid transparent',
                                        boxShadow: Number(fieldValues.classification) === defect.key ? '0px 0px 10px rgba(25, 118, 210, 0.5)' : 'none',
                                        borderRadius: '8px',
                                        padding: '4px',
                                    }}
                                >
                                    <img src={getIconByDefectClassification(defect.value)} alt={defect.value} style={{ transform: defect.value === 'Longitudinal' ? 'rotate(90deg)' : 'rotate(0deg)', width: '24px', height: '24px' }} />
                                </IconButton>
                            </Tooltip>
                        )) :
                            Object.values(dataInfo.type).filter(defect => defect.key !== -1).map(defect => (
                                <Tooltip key={defect.key} title={defect.name}>
                                    <IconButton
                                        onClick={() => handleChange("classification")({ target: { value: defect.key } })}
                                        sx={{
                                            border: fieldValues.classification === defect.key ? '2px solid #1976d2' : '2px solid transparent',
                                            boxShadow: fieldValues.classification === defect.key ? '0px 0px 10px rgba(25, 118, 210, 0.5)' : 'none',
                                            borderRadius: '8px',
                                            padding: '4px',
                                        }}
                                    >
                                        <img src={getIconByDefectClassification(defect.name)} alt={defect.name} style={{ transform: defect.name === 'Longitudinal' ? 'rotate(90deg)' : 'rotate(0deg)', width: '24px', height: '24px' }} />
                                    </IconButton>
                                </Tooltip>
                            ))
                        }
                    </div>
                )}
                <Divider flexItem orientation="vertical" sx={{ height: 'auto', mx: 0.5, my: 1, }} />
                <Tooltip title="Delete selected distress" arrow placement='bottom'>
                    <IconButton onClick={handleDelete}>
                        <DeleteOutline sx={{ color: 'black' }} />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Done" arrow placement='bottom'>
                    <IconButton onClick={handleSubmit}>
                        <DoneIcon sx={{ color: 'black' }} />
                    </IconButton>
                </Tooltip>
            </Paper></div>
    )
}

export default EditOptions
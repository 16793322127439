import { FormControl, InputLabel, Select, TextField } from "@mui/material";
export default function FilterDistressInput({ inputType = 'select', id, label, children, ...props }) {
    return (
        <>
            {inputType === 'select' ?
                <FormControl
                    size="small"
                    sx={{ backgroundColor: 'transparent', width: '100%', marginTop: 2 }}
                >
                    <InputLabel htmlFor={id - 'select'}>{label}</InputLabel>
                    <Select
                        {...props}
                        size="small"
                        sx={{
                            width: '100%',
                            backgroundColor: 'transparent',
                            color: '#000000',
                            textTransform: 'initial',
                            paddingInline: '8px',
                            overflow: 'auto',
                            '&:hover': {
                                backgroundColor: 'transparent',
                                color: '#000000',
                            },
                        }}
                        label={label}
                        inputProps={{
                            name: id,
                            id: `${id}-select`,
                        }}
                    >
                        {children}
                    </Select>
                </FormControl>
                :
                <TextField
                    {...props}
                    type="number"
                    variant="outlined"
                    size="small"
                    label={label}
                    sx={{
                        width: '100%',
                        backgroundColor: 'transparent',
                        color: '#000000',
                        textTransform: 'initial',


                        marginTop: "30px",
                        '&:hover': {
                            backgroundColor: 'transparent',
                            color: '#000000',
                        },
                        '& input[type=number]': {
                            '-moz-appearance': 'textfield',
                            '&::-webkit-outer-spin-button': {
                                '-webkit-appearance': 'none',
                                margin: 0,
                            },
                            '&::-webkit-inner-spin-button': {
                                '-webkit-appearance': 'none',
                                margin: 0,
                            },
                        },

                    }}
                    InputProps={{
                        inputProps: {
                            style: {
                                textAlign: "left"
                            },
                        },
                    }}
                />
            }
        </>
    )
}
import { LayerGroup, LayersControl, MapContainer, TileLayer, Tooltip, Circle, useMap } from 'react-leaflet'
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import marker from '../../../../../assets/pin_sm.svg';
import { useEffect, useRef } from 'react';
import { CircularProgress } from '@mui/material';
import { connect } from 'react-redux';
import SmartUtils from '../../../../../helpers/SmartUtils';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
const EditorDistressMap = (props) => {
  const { drProjectData, conditionMethod, FPS, handleSeekTo, isGroupData = false, distressPinned = null, datastore, distressProjectPredictions = [], setDistressPinned, zoom = 10, pinned } = props;
  const iconSize = [26 / 1, 38 / 1];
  const DrIcon = new L.Icon({
    iconUrl: marker,
    iconRetinaUrl: marker,
    iconAnchor: [iconSize[0] / 2, iconSize[1]],
    popupAnchor: [-0, -0],
    iconSize: iconSize,
  });
  L.Marker.prototype.options.icon = DrIcon;
  const { distressData } = datastore;
  const mapRef = useRef('null')
  const currentSection = pinned === null ? distressProjectPredictions[0] : Object.values(distressProjectPredictions).filter(obj => obj.id === pinned.id)[0];
  const mapCenter = [currentSection?.info?.startingPoint?.lat, currentSection?.info?.startingPoint?.lng];
  const { projectType, typeOfRoad } = drProjectData;
  const cciInverted = drProjectData.cciInverted ? drProjectData.cciInverted : false;
  const currentSectionColor =
    SmartUtils.supportCCIProject(projectType) && conditionMethod === 2 ?
      currentSection?.info?.weightedCondition ?
        SmartUtils.cciColor(currentSection?.info?.weightedCondition, cciInverted)
        :
        SmartUtils.cciColor(currentSection?.info?.condition, cciInverted) :

      SmartUtils.supportPCIProject(projectType) && conditionMethod === 1 ?
        currentSection?.info?.defaultCondition ?
          SmartUtils.pciColor(currentSection?.info?.defaultCondition)
          :
          SmartUtils.pciColor(currentSection?.info?.condition)
        :
        SmartUtils.rciColor(currentSection?.info?.condition, typeOfRoad);

  if (distressProjectPredictions.length === 0) {
    return <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <CircularProgress sx={{ color: 'green' }} />
    </div>
  }
  if (distressData === undefined || distressData === null) {
    return <></>
  }
  const { dataInfoV2 } = distressData;
  const { defectName } = dataInfoV2;
  if (defectName === null || defectName === undefined) {
    return <></>
  }

  const Recenter = ({ lat, lng }) => {
    const map = useMap();
    useEffect(() => {
      map.setView([lat, lng]);
    }, [lat, lng]);
    return null;
  }
  return (
    <div style={{ height: '100%' }}>
      <MapContainer ref={mapRef} attributionControl={false} center={mapCenter} zoom={zoom} scrollWheelZoom={true} maxZoom={20}>
        <TileLayer
          attribution=''
          maxZoom={20}
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Recenter lat={mapCenter[0]} lng={mapCenter[1]} />
        <ReactLeafletGoogleLayer apiKey='AIzaSyA1Zq5IDPlPl_i4MnG9wCBg8s6XQrfB9Ig' type={'roadmap'} maxZoom={25} maxNativeZoom={22} />
        <LayersControl position="topright">
          <LayersControl.Overlay name="Current location" checked={true}>
            <LayerGroup>
              <Circle
                center={[currentSection?.info?.startingPoint?.lat, currentSection?.info?.startingPoint?.lng]}
                pathOptions={{ fillColor: 'white', color: 'black', fillOpacity: 1, stroke: true, fill: true }}
                radius={4}>
                <Tooltip>{currentSection?.sectionId}</Tooltip>
              </Circle>
            </LayerGroup>
          </LayersControl.Overlay>
          <LayersControl.Overlay name="Each section distress" checked={true}>
            <LayerGroup>
              {Object.values(currentSection.detections).filter((eachDetection) => {
                return eachDetection.archived ? eachDetection.archived === "0" : eachDetection
              }).map((e, i) => {
                return (
                  <Circle
                    key={e.defectId || i}
                    eventHandlers={{
                      click: () => {
                        handleSeekTo(Number((isGroupData ? e.groupFrameNumber / FPS : e.frameNumber / FPS)).toFixed(5));
                        setDistressPinned(e);
                      }
                    }}
                    center={[e.lat, e.lng]}
                    pathOptions={{
                      fillColor: conditionMethod === -1 ? '#025196' : currentSectionColor,
                      color: (distressPinned?.defectId ?? "-1") === e.defectId ? `rgba(57, 1, 120,0.9)` : conditionMethod === -1 ? '#025196' : currentSectionColor,
                      fillOpacity: 1,
                      stroke: true,
                      fill: true
                    }}
                    radius={1}
                  >
                    <Tooltip>{e.defectName === -1 ? <></> : defectName[e.defectName]['name']}</Tooltip>
                  </Circle>
                );
              })}
            </LayerGroup>
          </LayersControl.Overlay>
        </LayersControl>
      </MapContainer >
    </div >
  )
}
const mapStateToProps = (state) => {
  return {
    datastore: state.datastore,
  };
};
export default connect(mapStateToProps)(EditorDistressMap);
import React from 'react';
import Alert from '@mui/material/Alert';
import { Snackbar } from '@mui/material';


const CustomAlert = (props) => {
    const { severity, text, open, autoHideDuration = 3000, onClose, variant = "standard", sx = {} } = props;
    return (
        <Snackbar open={open} autoHideDuration={autoHideDuration} onClose={onClose}>
            <Alert
                onClose={onClose}
                severity={severity}
                variant={variant}
                sx={{ width: '100%' }}
            >
                {text}
            </Alert>
        </Snackbar>
    )
}

export default CustomAlert
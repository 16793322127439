import { Button, Grid, TextField, Tooltip, Typography } from "@mui/material";
import SimpleDialogBox from "../../../../../../cidgets/SimpleDialogBox";
import { useState } from "react";
import EditGpsMarkerList from "./EditGpsMarkerList";

export default function EditGpsHeader(
    {
        afterInference,
        handleRemovePoints, handleRemove, newPointsAdd, setNewPointsAdd, setAddPointKey,
        addUserPoint, addPointA, addPointB, pointA, pointB, setAddPointA, setAddPointB, setAddUserPoint, toggleDragging, dragEnabled, handleRemoveAllPoints
    }
) {
    const [addMarkersDialog, setAddMarkersDialog] = useState(false);
    const handleOpenDialog = () => {
        setAddMarkersDialog(true);
    }
    const handleCloseDialog = () => {
        setAddMarkersDialog(false);
    }
    return (
        <>
            <SimpleDialogBox
                open={addMarkersDialog}
                onClose={handleCloseDialog}
                title="Remove pins"
                shouldTitleAppear={true}
                cancelText="Close"
                positiveText='Remove all'
                showPositive={true}
                positiveButtonWidget={
                    // pointA !== null || pointB !== null) && (
                    <Button
                        variant="contained" sx={{
                            marginInline: '2px',
                            textTransform: 'initial',
                            backgroundColor: '#025196', ':hover': {
                                backgroundColor: '#025196'
                            },
                        }}
                        disabled={newPointsAdd.length === 0}
                        onClick={handleRemoveAllPoints}
                    >
                        Remove all
                    </Button>

                }
                bodyWidget={
                    <EditGpsMarkerList pointA={pointA} pointB={pointB} handleRemovePoints={handleRemovePoints} handleRemove={handleRemove} newPointsAdd={newPointsAdd} setNewPointsAdd={setNewPointsAdd} setAddPointKey={setAddPointKey} handleRemoveAllPoints={handleRemoveAllPoints} />
                }
                width='600'
            />
            {afterInference ? <Grid container spacing={0}>
                <Grid item xs={12} sx={{ background: '', display: 'flex', alignItems: 'center', height: '10vh', justifyContent: 'center' }}>
                    {pointA === null ?
                        <Tooltip title={"Click on the start pin"} arrow placement='bottom'>
                            <Button
                                disabled={dragEnabled ? true : pointA === null ? false : true}
                                onClick={() => { setAddPointA(!addPointA) }}
                                variant="contained"
                                sx={{
                                    textTransform: "initial",
                                    backgroundColor: "#025196",
                                    color: !addPointA ? "#ffffff" : "#FFFFFF",
                                    "&:hover": {
                                        backgroundColor: "#025196",
                                        color: !addPointA ? "#ffffff" : "#FFFFFF",
                                    },
                                }}
                            >
                                Select start pin
                            </Button>
                        </Tooltip>
                        : <div>
                            <TextField id="outlined-basic" label="Start pin" variant="outlined" disabled value={`${pointA.lat},${pointA.lng}`} />
                        </div>}
                    {pointB === null ?
                        <Tooltip title={"Click on the end pin"} arrow placement='bottom'>
                            <Button
                                disabled={dragEnabled ? true : pointA === null || pointB !== null ? true : false}
                                onClick={() => { setAddPointB(!addPointB) }}
                                variant="contained"
                                sx={{
                                    marginLeft: "10px",
                                    textTransform: "initial",
                                    backgroundColor: "#025196",
                                    color: !addPointB ? "#ffffff" : "#FFFFFF",
                                    "&:hover": {
                                        backgroundColor: "#025196",
                                        color: !addPointB ? "#ffffff" : "#FFFFFF",
                                    },
                                }}
                            >
                                Select end pin
                            </Button>
                        </Tooltip>
                        :
                        <div>
                            <TextField sx={{ marginLeft: '10px' }} id="outlined-basic" label="End pin" variant="outlined" disabled value={`${pointB.lat},${pointB.lng}`} />
                        </div>}
                    <Tooltip title={"Click on the map to define a different path"} arrow placement='bottom'>
                        <Button
                            disabled={pointA === null || pointB === null ? true : false}
                            onClick={() => { setAddUserPoint(!addUserPoint) }}
                            variant="contained"
                            sx={{
                                marginLeft: '10px',
                                textTransform: "initial",
                                backgroundColor: "#025196",
                                color: !addUserPoint ? "#ffffff" : "#FFFFFF",
                                "&:hover": {
                                    backgroundColor: "#025196",
                                    color: !addUserPoint ? "#ffffff" : "#FFFFFF",
                                },
                            }}
                        >
                            Create new path
                        </Button>
                    </Tooltip>
                    {pointA !== null || pointB !== null ?
                        // <Tooltip title={"Remove selected pins"} arrow placement='bottom'>
                        <Button
                            onClick={() => { handleOpenDialog() }}
                            variant="contained"
                            sx={{
                                marginLeft: '10px',
                                textTransform: "initial",
                                backgroundColor: "#025196",
                                color: "#FFFFFF",
                                "&:hover": {
                                    backgroundColor: "#025196",
                                    color: "#FFFFFF",
                                },
                            }}
                        >
                            Remove pins
                        </Button>
                        // </Tooltip>
                        : <></>}

                    <Tooltip title={`${dragEnabled ? 'Disable' : 'Enable'} dragging of first and last pins`} arrow placement='bottom'>
                        <Button
                            onClick={toggleDragging}
                            variant="contained"
                            sx={{
                                marginLeft: "10px",
                                textTransform: "initial",
                                backgroundColor: "#025196",
                                color: !addPointB ? "#ffffff" : "#FFFFFF",
                                "&:hover": {
                                    backgroundColor: "#025196",
                                    color: !addPointB ? "#ffffff" : "#FFFFFF",
                                },
                            }}
                        >
                            {dragEnabled ? "Disable Dragging" : "Enable Dragging"}
                        </Button>
                    </Tooltip>
                </Grid>
            </Grid> :
                <Grid container spacing={0}>
                    <Grid item xs={2} sx={{ background: '', display: 'flex', alignItems: 'center', height: '10vh', paddingLeft: '10px' }}>
                        <div>
                            <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Edit GPS</Typography>
                            <Typography variant='body2' >Edit the GPS tracks</Typography>
                        </div>
                    </Grid>
                    <Grid item xs={8} sx={{ background: '', display: 'flex', alignItems: 'center', height: '10vh', justifyContent: 'center' }}>
                        {pointA === null ?
                            <Tooltip title={"Click on the start pin"} arrow placement='bottom'>
                                <Button
                                    disabled={dragEnabled ? true : pointA === null ? false : true}
                                    onClick={() => { setAddPointA(!addPointA) }}
                                    variant="contained"
                                    sx={{
                                        textTransform: "initial",
                                        backgroundColor: "#025196",
                                        color: !addPointA ? "#ffffff" : "#FFFFFF",
                                        "&:hover": {
                                            backgroundColor: "#025196",
                                            color: !addPointA ? "#ffffff" : "#FFFFFF",
                                        },
                                    }}
                                >
                                    Select start pin
                                </Button>
                            </Tooltip>
                            : <div>
                                <TextField id="outlined-basic" label="Start pin" variant="outlined" disabled value={`${pointA.lat},${pointA.lng}`} />
                            </div>}
                        {pointB === null ?
                            <Tooltip title={"Click on the end pin"} arrow placement='bottom'>
                                <Button
                                    disabled={dragEnabled ? true : pointA === null || pointB !== null ? true : false}
                                    onClick={() => { setAddPointB(!addPointB) }}
                                    variant="contained"
                                    sx={{
                                        marginLeft: "10px",
                                        textTransform: "initial",
                                        backgroundColor: "#025196",
                                        color: !addPointB ? "#ffffff" : "#FFFFFF",
                                        "&:hover": {
                                            backgroundColor: "#025196",
                                            color: !addPointB ? "#ffffff" : "#FFFFFF",
                                        },
                                    }}
                                >
                                    Select end pin
                                </Button>
                            </Tooltip>
                            :
                            <div>
                                <TextField sx={{ marginLeft: '10px' }} id="outlined-basic" label="End pin" variant="outlined" disabled value={`${pointB.lat},${pointB.lng}`} />
                            </div>}
                        <Tooltip title={"Click on the map to define a different path"} arrow placement='bottom'>
                            <Button
                                disabled={pointA === null || pointB === null ? true : false}
                                onClick={() => { setAddUserPoint(!addUserPoint) }}
                                variant="contained"
                                sx={{
                                    marginLeft: '10px',
                                    textTransform: "initial",
                                    backgroundColor: "#025196",
                                    color: !addUserPoint ? "#ffffff" : "#FFFFFF",
                                    "&:hover": {
                                        backgroundColor: "#025196",
                                        color: !addUserPoint ? "#ffffff" : "#FFFFFF",
                                    },
                                }}
                            >
                                Create new path
                            </Button>
                        </Tooltip>
                        {pointA !== null || pointB !== null ?
                            // <Tooltip title={"Remove selected pins"} arrow placement='bottom'>
                            <Button
                                onClick={() => { handleOpenDialog() }}
                                variant="contained"
                                sx={{
                                    marginLeft: '10px',
                                    textTransform: "initial",
                                    backgroundColor: "#025196",
                                    color: "#FFFFFF",
                                    "&:hover": {
                                        backgroundColor: "#025196",
                                        color: "#FFFFFF",
                                    },
                                }}
                            >
                                Remove pins
                            </Button>
                            // </Tooltip>
                            : <></>}

                        <Tooltip title={`${dragEnabled ? 'Disable' : 'Enable'} dragging of first and last pins`} arrow placement='bottom'>
                            <Button
                                onClick={toggleDragging}
                                variant="contained"
                                sx={{
                                    marginLeft: "10px",
                                    textTransform: "initial",
                                    backgroundColor: "#025196",
                                    color: !addPointB ? "#ffffff" : "#FFFFFF",
                                    "&:hover": {
                                        backgroundColor: "#025196",
                                        color: !addPointB ? "#ffffff" : "#FFFFFF",
                                    },
                                }}
                            >
                                {dragEnabled ? "Disable Dragging" : "Enable Dragging"}
                            </Button>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={2}></Grid>
                </Grid>}
        </>
    )
}
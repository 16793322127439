const initState = {
    createNetworkMessage: "",
    createNetworkLoader: null,
    createNetworkIsDoneAndSent: null,

    updateNetworkMessage: "",
    updateNetworkLoader: null,
    updateNetworkIsDoneAndSent: null,

    archiveNetworkMessage: "",
    archiveNetworkLoader: null,
    archiveNetworkIsDoneAndSent: null,

    setupNetworkMessage: "",
    setupNetworkLoader: null,
    setupNetworkIsDoneAndSent: null,

    cciCalcMessage: "",
    cciCalcIsDoneAndSent: null,
    cciCalcLoader: null,

    // README: SUPERSECTION 

    createSuperSectionMessage: "",
    createSuperSectionLoader: null,
    createSuperSectionIsDoneAndSent: null,

    archiveSuperSectionMessage: "",
    archiveSuperSectionLoader: null,
    archiveSuperSectionIsDoneAndSent: null,
}
const NetworkReducer = (state = initState, action) => {
    if (action.type === "CREATE_NETWORK_CALL_INIT" || action.type === "CREATE_NETWORK_CALL_COMPLETED" ||
        action.type === "CREATE_NETWORK_CALL_ERROR") {
        return {
            ...state,
            createNetworkMessage: action.createNetworkMessage,
            createNetworkLoader: action.createNetworkLoader,
            createNetworkIsDoneAndSent: action.createNetworkIsDoneAndSent ?? false,
        }
    }
    else if (action.type === "CLEAR_CREATE_NETWORK_ERROR_AND_MSG") {
        return {
            ...state,
            createNetworkMessage: "",
            createNetworkLoader: null,
            createNetworkIsDoneAndSent: null,
        }
    }
    else if (action.type === "UPDATE_NETWORK_CALL_INIT" || action.type === "UPDATE_NETWORK_CALL_COMPLETED" ||
        action.type === "UPDATE_NETWORK_CALL_ERROR") {
        return {
            ...state,
            updateNetworkMessage: action.updateNetworkMessage,
            updateNetworkLoader: action.updateNetworkLoader,
            updateNetworkIsDoneAndSent: action.updateNetworkIsDoneAndSent ?? false,
        }
    }
    else if (action.type === "CLEAR_UPDATE_NETWORK_ERROR_AND_MSG") {
        return {
            ...state,
            updateNetworkMessage: "",
            updateNetworkLoader: null,
            updateNetworkIsDoneAndSent: null,
        }
    }
    else if (action.type === "ARCHIVE_NETWORK_CALL_INIT" || action.type === "ARCHIVE_NETWORK_CALL_COMPLETED" ||
        action.type === "ARCHIVE_NETWORK_CALL_ERROR") {
        return {
            ...state,
            archiveNetworkMessage: action.archiveNetworkMessage,
            archiveNetworkLoader: action.archiveNetworkLoader,
            archiveNetworkIsDoneAndSent: action.archiveNetworkIsDoneAndSent ?? false,
        }
    }
    else if (action.type === "CLEAR_ARCHIVE_NETWORK_ERROR_AND_MSG") {
        return {
            ...state,
            archiveNetworkMessage: "",
            archiveNetworkLoader: null,
            archiveNetworkIsDoneAndSent: null,
        }
    }
    else if (action.type === "SETUP_NETWORK_CALL_INIT" || action.type === "SETUP_NETWORK_CALL_COMPLETED" ||
        action.type === "SETUP_NETWORK_CALL_ERROR") {
        return {
            ...state,
            setupNetworkMessage: action.setupNetworkMessage,
            setupNetworkLoader: action.setupNetworkLoader,
            setupNetworkIsDoneAndSent: action.setupNetworkIsDoneAndSent ?? false,
        }
    }
    else if (action.type === "CLEAR_SETUP_NETWORK_ERROR_AND_MSG") {
        return {
            ...state,
            setupNetworkMessage: "",
            setupNetworkLoader: null,
            setupNetworkIsDoneAndSent: null,
        }
    }
    else if (action.type === "CCI_CALC_START" || action.type === "CCI_CALC_COMPLETED" || action.type === "CCI_CALC_ERROR") {
        return {
            ...state,
            cciCalcMessage: action.cciCalcMessage,
            cciCalcIsDoneAndSent: action.cciCalcIsDoneAndSent ?? false,
            cciCalcLoader: action.cciCalcLoader,
        }
    }
    else if (action.type === "CLEAR_CCI_CALC") {
        return {
            ...state,
            cciCalcMessage: "",
            cciCalcIsDoneAndSent: null,
            cciCalcLoader: null,
        }
    }
    // README: SUPERSECTION 
    else if (action.type === "CREATE _SUPERSECTION_START" || action.type === "CREATE _SUPERSECTION_COMPLETED" ||
        action.type === "CREATE _SUPERSECTION_ERROR") {
        return {
            ...state,
            createSuperSectionMessage: action.createSuperSectionMessage,
            createSuperSectionLoader: action.createSuperSectionLoader,
            createSuperSectionIsDoneAndSent: action.createSuperSectionIsDoneAndSent ?? false,
        }
    }
    else if (action.type === "ARCHIVE _SUPERSECTION_START" || action.type === "ARCHIVE _SUPERSECTION_COMPLETED" ||
        action.type === "ARCHIVE _SUPERSECTION_ERROR") {
        return {
            ...state,
            archiveSuperSectionMessage: action.archiveSuperSectionMessage,
            archiveSuperSectionLoader: action.archiveSuperSectionLoader,
            archiveSuperSectionIsDoneAndSent: action.archiveSuperSectionIsDoneAndSent ?? false,
        }
    }
    else if (action.type === "CLEAR_SUPERSECTION_ERROR_AND_MSG") {
        return {
            ...state,
            createSuperSectionMessage: "",
            createSuperSectionLoader: null,
            createSuperSectionIsDoneAndSent: null,
        }
    }
    else if (action.type === "CLEAR_ARCHIVE_ERROR_AND_MSG") {
        return {
            ...state,
            archiveSuperSectionMessage: "",
            archiveSuperSectionLoader: null,
            archiveSuperSectionIsDoneAndSent: null,
        }
    }
    return state;
}
export default NetworkReducer;
import React, { useEffect } from 'react'
import { MapContainer, Marker, Polyline, Popup, TileLayer, ZoomControl, useMap, ScaleControl } from 'react-leaflet'
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { connect } from 'react-redux';


const Recenter = ({ lat, lng }) => {
  const map = useMap();
  useEffect(() => {
    map.setView([lat, lng]);
  }, [lat, lng]);
  return null;
}

const AddNewSuperSectionMap = (props) => {

  const getMarkerColor = (index, totalMarkers) => {
    if (index === 0) return "green";
    if (index === Number(totalMarkers - 1)) return "red";
    return "grey";
  };

  const iconSize = [26 / 1, 38 / 1];
  const { userLocation, isUserLocation, draggable, markerPositions } = props;
  const { OnTap, onDragEnd } = props;


  return (
    <MapContainer
      center={[userLocation.lat, userLocation.lng]}
      style={{ height: '100vh', cursor: 'default' }}
      zoom={18}
      zoomControl={false}
      doubleClickZoom={false}

    >

      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <Recenter lat={userLocation.lat} lng={userLocation.lng} />
      <ZoomControl position="bottomright" />
      <ScaleControl position="bottomleft" />
      <OnTap />
      {/* {isUserLocation ? <Popup position={userLocation} closeButton={false} className="custom-popup">
        <div>
          <p>Click on the map to see waypoint</p>
        </div>
      </Popup> : <></>} */}
      {markerPositions.map((position, index) => {
        return <Marker
          eventHandlers={{
            dragend: (e) => onDragEnd(e, position.id),
          }}
          autoPan={true}
          autoPanOnFocus={true}
          interactive={true}
          title={index + 1}
          draggable={draggable}
          key={index}
          position={position}
          icon={new L.Icon({ iconUrl: `https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-${getMarkerColor(index, markerPositions.length)}.png`, iconRetinaUrl: `https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-${getMarkerColor(index, markerPositions.length)}.png`, iconSize: iconSize, iconAnchor: [iconSize[0] / 2, iconSize[1]], popupAnchor: [-0, -0] })}
        >

        </Marker>
      })}
      {props.project.routeCalculationData === null ? <></> : <Polyline pathOptions={{ color: 'green' }} positions={props.project.routeCalculationData.coordinatesArray} />}

    </MapContainer>
  )
}
const mapStateToProps = (state) => {
  return {
    project: state.project,
  };
}
export default connect(mapStateToProps, null)(AddNewSuperSectionMap)
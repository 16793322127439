import React from 'react';
import { Navigate, useLocation, Route, Routes, Outlet } from 'react-router-dom'
import * as ROUTES from '../../../constants/routes'
import HomeDashboard from '../../home/HomeDashboard';
import { connect } from "react-redux";
import ProjectDashboardWrapper from '../../home/components/project/ProjectDashboardWrapper';
import RoutePlannerController from '../../home/components/routeplanner/RoutePlannerController';
import AccountDashboardController from '../../home/components/account/AccountDashboardController';
import DemoAccountError from '../../cidgets/DemoAccountError';
import ProjectDashboardWrapperSection from '../../home/components/project/ProjectDashboardWrapperSection';
import LinkedAccountError from '../../cidgets/LinkedAccountError';
import BasicImagingGlobalController from '../../home/components/project/subpages/basicImagingGlobalComponents/BasicImagingGlobalController';
import { useAccessControlContext } from '../../../contexts/AccessControlProvider';
import ControlRoomController from '../../home/components/controlroom/ControlRoomController';
import { useNetworkContext } from '../../../contexts/NetworkProvider';
import NetworkDashboardWrapper from '../../home/components/network/NetworkDashboardWrapper';
import IndividualSuperSectionController from '../../../components/home/components/network/dashboard/supersections/SuperSectionController';
import RoadConditonIndex from '../../home/components/rci/RoadConditonIndex';
import FullScreen360VideoPlayerController from '../../home/components/project/360Components/FullScreen360VideoPlayerController';
import NetworkDashboard from '../../home/NetworkDashboard';
import SuperSectionController from '../../home/components/super_section/SuperSectionController';

const HomeController = (props) => {
  const { isNetworkView } = useNetworkContext();
  const isSignedIn = props.isSignedIn;
  const isEmailVerified = props.isEmailVerified;

  const { permissions } = useAccessControlContext();
  const { accountScreenAccess, surveyPlannerScreen } = permissions;

  let location = useLocation();
  if (!isSignedIn) {
    return <Navigate to="/a" state={{ from: location }} replace />;
  }

  if (!isEmailVerified) {
    return <Navigate to="/a/verify" state={{ from: location }} replace />;
  }


  const isDemoAccount = props.userData.uid === props.charges?.demoAccount
  const isLinkedAccount = props.userData.linkedToUid ?? false;
  const isDev = props.userData.dev ?? false;
  const accessControlRoom = props.userData.accessControlRoom ?? false;
  return (
    <div>
      <Routes>
        <Route path={ROUTES.HOME_DASHBOARD} element={<HomeDashboard appBarHeight={props.appBarHeight} companyData={props.companyData} />} />
        {/* {!isNetworkView ? */}
        <Route path={ROUTES.HOME_PROJECT} element={<Outlet />}>
          <Route path={`${ROUTES.IND_PROJECT}/${ROUTES.IND_PROJECT_SEC}`} element={<ProjectDashboardWrapper />} />
          <Route path={`${ROUTES.IND_PROJECT}/${ROUTES.IND_PROJECT_SEC}/${ROUTES.IND_PROJECT_OUTPUT}`} element={<ProjectDashboardWrapperSection />} />
          <Route path={`${ROUTES.IND_PROJECT}/${ROUTES.IND_PROJECT_SEC}/${ROUTES.FULLSCREEN_360_VIDEOPLAYER}`} element={<FullScreen360VideoPlayerController userData={props.userData} />} />
        </Route>
        {/* : */}
        <Route path={ROUTES.HOME_DASHBOARD_NETWORK} element={<NetworkDashboard appBarHeight={props.appBarHeight} companyData={props.companyData} />} />
        <Route path={ROUTES.HOME_NETWORK} element={<Outlet />}>
          <Route path={`${ROUTES.IND_NETWORK}`} element={<NetworkDashboardWrapper />} />
          <Route path={`${ROUTES.IND_NETWORK}/${ROUTES.IND_SUPERSECTION}`} element={<IndividualSuperSectionController />} />
        </Route>
        {/* } */}
        <Route path={ROUTES.ACCOUNT} element={isDemoAccount ? <DemoAccountError /> : !accountScreenAccess ? <LinkedAccountError /> : <AccountDashboardController userData={props.userData} companyData={props.companyData} appBarHeight={props.appBarHeight} />} />
        {/* <Route path={ROUTES.SURFACE_VIZ} element={<SurfaceVisualizerDashboard />} /> */}
        <Route path={ROUTES.ROUTE_PLANNER} element={!surveyPlannerScreen ? <LinkedAccountError /> : <RoutePlannerController />} />
        <Route path={ROUTES.SUPER_SECTION} element={<SuperSectionController />} />
        <Route path={ROUTES.CONTROL_ROOM} element={<ControlRoomController accessControlRoom={accessControlRoom} />} />
        {/* <Route path={ROUTES.ROAD_CONDITION_INDEX} element={<RoadConditonIndex />} /> */}
        <Route path={ROUTES.BASIC_IMAGING} element={<BasicImagingGlobalController />} />
        <Route path={ROUTES.UNKNOWN} element={<main style={{ padding: "1rem" }}><p>There's nothing here!</p></main>} />
      </Routes>
      {location.pathname === '/home' || location.pathname === '/home/' ? <Navigate to="/home/dashboard" state={{ from: location }} replace /> : <></>}
    </div >
  );
}

const mapStateToProps = (state) => {
  return {
    userData: state.firebase.profile,
    charges: state.firestore.data.Charges,
    allProjects: state.firestore.data.Projects
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(HomeController);
import { Chip } from "@mui/material";

export default function FilterChips({ label, ...props }) {
    return (
        <Chip
            {...props}
            label={label}
            sx={{
                margin: 0.5,
            }}
        />
    )
}
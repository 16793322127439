import React, { useState, useEffect } from 'react'
import { Container, Tabs, Tab, Typography, TextField, Box, IconButton } from '@mui/material';
import { connect } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ControlRoomTable from './ControlRoomTable';
import { useNavigate } from 'react-router-dom';
import ControlRoomResources from './ControlRoomResources';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { fetchActiveProjects, fetchHaltedProjects, fetchCompletedProjects, getControlRoomData, fetchCrashedProjects, fetchQueuedProjects, fetchDownSamplingProjects } from "../../../../store/actions/controlroomActions";
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from "redux";
const ControlRoomController = (props) => {
    const { accessControlRoom, pipelineResources } = props;
    const { HaltedprojectsData, ActiveprojectsData, CompletedprojectsData, controlRoomData, QueuedprojectsData, CrashedProjectsData, isLoading, DownSamplingProjectsData } = props.controlRoom;
    const [value, setValue] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    useEffect(() => {
        props.fetchActiveProjects();
        props.fetchHaltedProjects();
        props.fetchCompletedProjects();
        props.getControlRoomData();
        props.fetchCrashedProjects();
        props.fetchQueuedProjects();
        props.fetchDownSamplingProjects();
    }, []);
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const theme = createTheme({
        palette: {
            secondary: {
                main: '#025196',
            },
        },
    });

    let navigate = useNavigate();
    const routeChange = () => {
        let path = `/home`;
        navigate(path);
    };

    // Filter projects based on search term
    const filteredActiveProjects = (ActiveprojectsData ?? []).filter((project) =>
        (project.projectTitle && project.projectTitle.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    const filteredHaltedProjects = (HaltedprojectsData ?? []).filter((project) =>
        (project.projectTitle && project.projectTitle.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    const filteredCompletedProjects = (CompletedprojectsData ?? []).filter((project) =>
        (project.projectTitle && project.projectTitle.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    const filteredQueuedProjects = (QueuedprojectsData ?? []).filter((project) =>
        (project.projectTitle && project.projectTitle.toLowerCase().includes(searchTerm.toLowerCase()))
    );
    const filteredCrashedProjects = (CrashedProjectsData ?? []).filter((project) =>
        (project.projectTitle && project.projectTitle.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    return (
        accessControlRoom ? (
            <ThemeProvider theme={theme}>
                <Container maxWidth="xl">
                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                        <IconButton
                            aria-label="details"
                            disableRipple={true}
                            sx={{
                                border: '1px solid #777777',
                                '&:focus': {
                                    border: '1px solid #777777',
                                },
                                marginRight: "10px",
                            }}
                            size="small"
                            onClick={routeChange}
                        >
                            <ArrowBackIosNewIcon />
                        </IconButton>
                        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: "center" }}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                textColor="secondary"
                                indicatorColor="secondary"
                                variant="scrollable"
                                scrollButtons="auto"
                            >
                                <Tab label="Active Projects" style={{ fontSize: '13px' }} />
                                <Tab label="Halted Projects" style={{ fontSize: '13px' }} />
                                <Tab label="Crashed Projects" style={{ fontSize: '13px' }} />
                                <Tab label="Queued Projects" style={{ fontSize: '13px' }} />
                                <Tab label="Completed Projects" style={{ fontSize: '13px' }} />
                                <Tab label="Downsampling Projects" style={{ fontSize: '13px' }} />
                                <Tab label="Resources" style={{ fontSize: '13px' }} />
                            </Tabs>
                        </Box>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            id="search"
                            name="search"
                            size='small'
                            autoFocus
                            placeholder='Search Project Title...'
                            value={searchTerm}
                            onChange={handleSearchChange}
                            InputProps={{
                                startAdornment: (
                                    <SearchIcon
                                        style={{ fill: '#777777' }}
                                    />
                                ),
                            }}
                        />
                    </Box>
                    <div style={{ width: "100%", height: "100%" }}>
                        {value === 0 && <ControlRoomTable projectData={filteredActiveProjects} isQueued={false} isCompleted={false} controlRoomData={controlRoomData}
                            isLoading={isLoading}
                        />}
                        {value === 1 && <ControlRoomTable projectData={filteredHaltedProjects} isQueued={false} isCompleted={false} controlRoomData={controlRoomData}
                            isLoading={isLoading}

                        />}
                        {value === 2 && <ControlRoomTable projectData={filteredCrashedProjects} isQueued={false} isCompleted={false} controlRoomData={controlRoomData}
                            isLoading={isLoading}
                        />}
                        {value === 3 && <ControlRoomTable projectData={filteredQueuedProjects} isQueued={true} isCompleted={false} controlRoomData={controlRoomData}
                            isLoading={isLoading}
                        />}
                        {value === 4 && <ControlRoomTable projectData={filteredCompletedProjects} isQueued={false} isCompleted={true} controlRoomData={controlRoomData}
                            isLoading={isLoading}

                        />}
                        {value === 5 && <ControlRoomTable projectData={DownSamplingProjectsData} isQueued={false} isCompleted={true} controlRoomData={controlRoomData}
                            isLoading={isLoading}

                        />}
                        {value === 6 && <ControlRoomResources pipelineResources={pipelineResources} />}
                    </div>
                </Container>
            </ThemeProvider>
        ) : (
            <Typography variant="h5" component="div" gutterBottom style={{ marginTop: '20px', textAlign: 'center', width: '100%' }}>
                You are not authorized to access this page
            </Typography>
        )
    )
};

const mapStateToProps = (state) => {
    return {
        controlRoom: state.controlRoom,
        pipelineResources: state.firestore.data.PipelineResources,
    };
};


const mapDispatchToProps = (dispatch) => {
    return {
        fetchActiveProjects: () => dispatch(fetchActiveProjects()),
        fetchHaltedProjects: () => dispatch(fetchHaltedProjects()),
        fetchCompletedProjects: () => dispatch(fetchCompletedProjects()),
        getControlRoomData: () => dispatch(getControlRoomData()),
        fetchCrashedProjects: () => dispatch(fetchCrashedProjects()),
        fetchQueuedProjects: () => dispatch(fetchQueuedProjects()),
        fetchDownSamplingProjects: () => dispatch(fetchDownSamplingProjects()),
    };
};
const enhance = compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect(() => {

        return [
            {
                collection: 'PipelineConfigurations',
                doc: 'PipelineResource',
                storeAs: 'PipelineResources',
            },
        ];
    })
);

export default enhance(ControlRoomController);
import { Box, Button, Card, Divider, Grid, Typography, Paper, Tooltip } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import { connect } from "react-redux";
import AssetVideoPlayer from '../../components/AssetVideoPlayer';
import { styled } from '@mui/material/styles';
import PinOnMap from '../../components/PinOnMap';
import { Link } from 'react-router-dom';
import * as ROUTES from "../../../../../../constants/routes";
var currentTime = 0
const IndividualAssetViewer = (props) => {
    const numHeightOfHeader = 8;
    const numHeightOfFooter = 0;
    const customPadding = 15;
    const fieldAreaMargin = 20;

    const playerRef = useRef(null);
    const vidDivRef = useRef(null);
    const ref = useRef(null)
    const imageHeight = ref?.current?.clientHeight ?? 0;

    const [vidDivDimen, setVidDivDimen] = useState({ height: 1, width: 1 })
    const svgScaleFactor = 1
    const { individualDetectionData, datastore, proid, link } = props;
    const { fps, originalVideoHeight, originalVideoWidth } = individualDetectionData;
    const { dataInfo, assetData } = datastore;
    const [imageDimensions, setImageDimensions] = useState({ height: 1, width: 1 });
    const [videoPlayerState, setVideoPlayerState] = useState(null)
    const [svgDimensions, setSvgDimensions] = useState({ height: 1, width: 1, scale: 1, offsetTop: 0, offsetLeft: 0 });



    useEffect(() => {
        if (playerRef) {
            playerRef.current?.subscribeToStateChange(handleBind)
        }
    });

    useEffect(() => {
        return () => {
            if (playerRef.current) {
                playerRef.current = null;
            }
        };
    }, [playerRef]);


    const calcFps = fps;
    const isVideoActive = videoPlayerState?.seeking === false && videoPlayerState?.waiting === false && (((individualDetectionData.frameTime + 1 / calcFps) - videoPlayerState?.currentTime) <= 1 / fps)
    // //////console.log(videoPlayerState?.seeking, videoPlayerState?.waiting, (((individualDetectionData.frameTime + 1 / calcFps) - videoPlayerState?.currentTime) <= 1 / fps), "datacheck")
    const handleBind = (state) => {
        // //////console.log("hello")
        // //////console.log(_.isEqual(state, videoPlayerState));
        // //////console.log(_.omit(state, function(v,k) { return videoPlayerState[k] === v; }))
        // //////console.log('currentatime', currentTime)
        if (((individualDetectionData.frameTime + 1 / calcFps) - state?.currentTime) <= 1 / fps) {
            // //////console.log("bhes update hog=ja kuttay")
            setVideoPlayerState(state);
        }
        const isVideoActive = state?.seeking === false && state?.waiting === false
        // //////console.log(state?.seeking, state?.waiting, "active", (individualDetectionData.frameTime + 1 / calcFps), state?.currentTime)
        if (isVideoActive) {
            const hasTimeChanged = ((state?.currentTime ?? 0) !== (currentTime))
            // //////console.log("Me gusta no update gusta")
            if (hasTimeChanged) {
                // //////console.log('me update gusta', state?.currentTime ?? 0, currentTime, hasTimeChanged)
                currentTime = state?.currentTime ?? 0
                // //////console.log('time up', currentTime, hasTimeChanged)
                setVideoPlayerState(state);
            }
        } else {
            // //////console.log("GHAREEBON!!")
        }
    }

    const getVidWindowSize = () => {
        const newHeight = vidDivRef?.current?.clientHeight;
        const newWidth = vidDivRef?.current?.clientWidth;
        setVidDivDimen({
            height: newHeight,
            width: newWidth
        });
    };

    const getAssetMeta = (assetId, debug = false) => {
        const assetMeta = assetData.filter((e) => `${e.id}`.split('_')[0] === `${assetId}`.split('_')[0])[0]
        if (debug) {
            // //////console.log(assetMeta, "getAssetMeta")
        }
        return assetMeta
    }

    useEffect(() => {
        window.addEventListener("load", setVidDivDimen(vidDivRef.current.clientHeight))
        window.addEventListener("resize", getVidWindowSize, true);
    }, [])
    useEffect(() => {
        const divHeight = vidDivRef?.current?.clientHeight
        const divWidth = vidDivRef?.current?.clientWidth - 0;
        const imageHeight = originalVideoHeight
        const imageWidth = originalVideoWidth
        const asDiv = (divHeight / divWidth)
        const asImg = (imageHeight / imageWidth)
        const ratio = asDiv / asImg
        // //////console.log(asDiv, asImg, ratio, "ratio", vidDivRef);

        let svgWidth = 0
        let svgHeight = 0
        if (ratio >= 1) {
            svgWidth = divWidth
            svgHeight = asImg * divWidth
        } else {
            svgHeight = divHeight
            svgWidth = (1 / asImg) * divHeight
        }
        // //////console.log("Width:", divWidth, imageWidth, svgWidth)
        // //////console.log("Height:", divHeight, imageHeight, svgHeight)
        // //////console.log("Top:", (divHeight - svgHeight) / 2, "left:", (divWidth - svgWidth) / 2)
        setSvgDimensions({
            width: svgWidth,
            height: svgHeight,
            scale: imageHeight / svgHeight,
            offsetTop: (divHeight - svgHeight) / 2,
            offsetLeft: (divWidth - svgWidth) / 2,
        })
    }, [imageDimensions])
    useEffect(() => {
        setImageDimensions({
            height: originalVideoHeight,
            width: originalVideoWidth
        })
    }, [individualDetectionData]);
    useEffect(() => {
        setImageDimensions({
            height: originalVideoHeight,
            width: originalVideoWidth
        })
    }, [vidDivDimen])


    const handleSeekTo = (timeSeek) => {
        if (playerRef) {
            playerRef.current?.seek(timeSeek + (1 / fps))
        }
    }

    if (dataInfo === undefined) {
        return <div ref={vidDivRef}>
            <p>not found</p>
        </div>
    }
    const handleNextFrame = () => {
        const secondPerFrame = 1 / fps
        playerRef.current.forward(secondPerFrame)
    }

    const handlePrevFrame = () => {
        const secondPerFrame = 1 / fps
        playerRef.current.replay(secondPerFrame)
    }
    const cardStyle = {
        "border": "2px solid grey",
        "borderRadius": "8px",
        "backgroundColor": '#f5f1f1'
    }
    const getRectWh = (xy = [], wh = [], shouldScale = true, pred = false) => {
        if (xy.length !== 2 || wh.length !== 2) {
            return <></>
        }
        const x1 = xy[0] / (shouldScale ? svgDimensions.scale : 1)
        const y1 = xy[1] / (shouldScale ? svgDimensions.scale : 1)
        const x2 = (wh[0] + xy[0]) / (shouldScale ? svgDimensions.scale : 1)
        const y2 = (wh[1] + xy[1]) / (shouldScale ? svgDimensions.scale : 1)
        return <path d={`M ${x1}, ${y1} ${x1}, ${y2} ${x2}, ${y2} ${x2}, ${y1} z`} className="bbox-path" />
    }
    const assetMeta = getAssetMeta(individualDetectionData.assetId);
    const roadSideMap = {
        "map": dataInfo['roadSides'],
        "keys": Object.keys(dataInfo['roadSides']),
        "values": Object.values(dataInfo['roadSides']),
        "usefulKeys": ['0', '1'],
    }
    const classesMap = {
        "map": dataInfo['classes'],
        "keys": Object.keys(dataInfo['classes']),
        "values": Object.values(dataInfo['classes']),
        'assetClassesWhereNameWillNotShow': ['0', '2', '3', '4', '998', '999'],
        "usefulKeys": ['0', '1', '2', '3', '4', '998', '999'],
        "notTrafficSign": ['0', '2', '3', '4', '998', '999'],
        "trafficSign": ['1'],
    }
    const isTraficSignClass = !classesMap.assetClassesWhereNameWillNotShow.includes(assetMeta.class);
    const fieldStyle = {
        "border": "2px solid grey",
        "borderRadius": "5px",
        "padding": '5px',
        "fontSize": '0.8rem',
        "textTransform": 'capitalize'
    }
    const Item = styled(Paper)(({ theme }) => ({
        boxShadow: '0px 0px transparent',
        width: '100%',
        backgroundColor: 'transparent',
        ...theme.typography.body2,
        padding: theme.spacing(0.5),
    }));
    return (
        <div style={{ height: '100vh', width: '100vw', backgroundColor: '#FAFAFA' }}>
            {/* header */}
            <div style={{ height: `${numHeightOfHeader}vh`, width: '100vw', backgroundColor: '#FAFAFA', boxShadow: '5px 10px #FAFAFA', padding: `${customPadding}px` }}>
                <Grid container >
                    <Grid item xs={9} sm={9} md={9} lg={9} xl={9} sx={{
                        display: 'flex', justifyContent: 'start',
                    }} >
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                            Asset viewer
                        </Typography>
                    </Grid>
                    <Grid item xs={3} sm={3} md={3} lg={3} xl={3} sx={{
                        display: 'flex', justifyContent: 'end', alignItems: 'center'
                    }}>
                        <Link to={`/home/${ROUTES.HOME_PROJECT}/${proid}`}>
                            <Button variant='contained' sx={{
                                textTransform: 'initial', backgroundColor: '#025196', color: '#ffffff',
                                '&:hover': {
                                    backgroundColor: '#025196',
                                    color: '#ffffff'
                                }
                            }}
                            >Go back to project</Button>
                        </Link>

                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                        <Box sx={{ width: "100%", marginTop: '8px' }}>
                            <Divider ligth />
                        </Box>
                    </Grid>
                </Grid>
            </div>
            {/* Body */}
            <div style={{ height: `${(100) - (numHeightOfHeader + numHeightOfFooter)}vh`, width: '100vw', backgroundColor: 'transparent' }}>
                <Grid container spacing={0}>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{ backgroundColor: 'transparent', height: `${(100) - (numHeightOfHeader + numHeightOfFooter)}vh` }}>

                        <Grid container spacing={0} style={{ padding: `${customPadding}px` }}>
                            <Grid ref={vidDivRef} item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ backgroundColor: 'transparent', height: `${((100) - (numHeightOfHeader + numHeightOfFooter)) / 2}vh` }}>
                                <div className="container" style={{ padding: '0px' }}>
                                    <div className="inner" style={{ zIndex: 997 }}>
                                        <Card sx={cardStyle}>
                                            <AssetVideoPlayer fps={fps} handleNextFrame={handleNextFrame} handlePrevFrame={handlePrevFrame} playerRef={playerRef}
                                                link={link} height={vidDivRef.current === null ? 0 : vidDivRef.current?.clientHeight} disableControlsFully={true} startTime={individualDetectionData.frameTime} shouldOffset={true} />
                                        </Card>
                                    </div>
                                    <div className="inner" style={{ zIndex: 998, width: vidDivRef.current === null ? 0 : vidDivRef.current?.clientWidth }}>
                                        <div style={{
                                            height: '100%', width: '100%', backgroundColor: '', textAlign: 'center', display: 'flex',
                                            justifyContent: 'center', alignItems: 'center', marginTop: '-0px'
                                        }}>
                                            <svg height={svgDimensions.height} width={svgDimensions.width} style={{
                                                backgroundColor: 'rgba(34,55,245, 0.0)',
                                            }}
                                            >
                                                {!isVideoActive ? <></> : getRectWh([...individualDetectionData.bbox].splice(0, 2), [...individualDetectionData.bbox].splice(2, 4))}
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ marginTop: `${fieldAreaMargin}px`, backgroundColor: 'transparent', height: `calc(${((100) - (numHeightOfHeader + numHeightOfFooter)) / 2}vh - ${customPadding * 2}px - ${fieldAreaMargin}px)` }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sx={{ backgroundColor: 'transparent', height: `calc(${((100) - (numHeightOfHeader + numHeightOfFooter)) / 2}vh - ${customPadding * 2}px - ${fieldAreaMargin}px)` }} >

                                        <Card sx={{ ...cardStyle, height: `calc(${((100) - (numHeightOfHeader + numHeightOfFooter)) / 2}vh - ${customPadding * 2}px - ${fieldAreaMargin}px)` }}>
                                            <div style={{ padding: '25px', height: '100%', overflow: 'auto', backgroundColor: 'transparent' }}>
                                                <Grid container>
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                                        <div style={{ backgroundColor: 'transparent', overflow: 'auto' }}>
                                                            <Item>
                                                                <Grid container>
                                                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                                                                        display: "flex",
                                                                        justifyContent: "start",
                                                                        alignItems: "center",
                                                                    }} >
                                                                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                                                            Asset class:
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                                                        <Card sx={fieldStyle}>
                                                                            <Typography variant="inherit">
                                                                                {`${classesMap.map[assetMeta.class]}`.replace('_', ' ')}
                                                                            </Typography>
                                                                        </Card>
                                                                    </Grid>
                                                                </Grid>
                                                            </Item>
                                                            {isTraficSignClass ?
                                                                <Item>
                                                                    <Grid container>
                                                                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "start",
                                                                                alignItems: "center",
                                                                            }}
                                                                        >
                                                                            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                                                                Asset name:
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} >
                                                                            <Card sx={{ ...fieldStyle, overflow: 'auto' }}>
                                                                                <Typography variant="inherit">
                                                                                    {assetMeta.name.length === 0 ? "unnamed" : assetMeta.name}
                                                                                </Typography>
                                                                            </Card>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Item>
                                                                : <></>
                                                            }


                                                            <Item>
                                                                <Grid container>
                                                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}
                                                                        sx={{
                                                                            display: "flex",
                                                                            justifyContent: "start",
                                                                            alignItems: "center",
                                                                        }}
                                                                    >
                                                                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                                                            {isTraficSignClass ? 'Recognition' : 'Detection'} quality:
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}  >
                                                                        <Card sx={fieldStyle}>
                                                                            <Typography variant="inherit">
                                                                                {isTraficSignClass ? individualDetectionData.recognitionScore === '10000.00' ? '100' : individualDetectionData.recognitionScore : individualDetectionData.detectionScore}
                                                                            </Typography>
                                                                        </Card>
                                                                    </Grid>
                                                                </Grid>
                                                            </Item>

                                                            <Item>
                                                                <Grid container>
                                                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}
                                                                        sx={{
                                                                            display: "flex",
                                                                            justifyContent: "start",
                                                                            alignItems: "center",
                                                                        }} >
                                                                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                                                            GPS location:
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}  >
                                                                        <Card sx={fieldStyle}>
                                                                            <Typography variant="inherit">
                                                                                {individualDetectionData.geo[0]},{individualDetectionData.geo[1]}
                                                                            </Typography>
                                                                        </Card>
                                                                    </Grid>
                                                                </Grid>
                                                            </Item>
                                                            <Item>
                                                                <Grid container>
                                                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}
                                                                        sx={{
                                                                            display: "flex",
                                                                            justifyContent: "start",
                                                                            alignItems: "center",
                                                                        }}
                                                                    >
                                                                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                                                            Road side:
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}  >
                                                                        <Card sx={fieldStyle}>
                                                                            <Typography variant="inherit">
                                                                                {roadSideMap.map[individualDetectionData.roadSide]}
                                                                            </Typography>
                                                                        </Card>
                                                                    </Grid>
                                                                </Grid>
                                                            </Item>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Card>

                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{ backgroundColor: 'transparent', height: `${(100) - (numHeightOfHeader + numHeightOfFooter)}vh` }}>
                        <Grid container spacing={0} style={{ padding: `${customPadding}px` }}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ backgroundColor: 'transparent', height: `${((100) - (numHeightOfHeader + numHeightOfFooter)) / 2}vh` }}>
                                <Card sx={cardStyle}>
                                    <div style={{ overflow: 'auto', height: `calc(${((100) - (numHeightOfHeader + numHeightOfFooter)) / 2}vh - ${0 * 2}px - ${0}px)` }}>
                                        <PinOnMap isViewingAsset={true} gps={individualDetectionData.geo} />
                                    </div>
                                </Card>
                            </Grid>

                            <Grid ref={ref} item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ marginTop: `${fieldAreaMargin}px`, backgroundColor: 'transparent', height: `calc(${((100) - (numHeightOfHeader + numHeightOfFooter)) / 2}vh - ${customPadding * 2}px - ${fieldAreaMargin}px)` }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sx={{ backgroundColor: 'transparent', height: `calc(${((100) - (numHeightOfHeader + numHeightOfFooter)) / 2}vh - ${customPadding * 2}px - ${fieldAreaMargin}px)` }} >

                                        <Card sx={{ ...cardStyle, display: 'flex', alignItems: 'center', height: `calc(${((100) - (numHeightOfHeader + numHeightOfFooter)) / 2}vh - ${customPadding * 2}px - ${fieldAreaMargin}px)` }}>
                                            {/* <div style={{ overflow: 'auto', backgroundColor: 'transparent', float: 'left', height: '100%', width: '40%', display: 'flex', alignItems: 'start', justifyContent: 'center' }}> */}
                                            <Grid container spacing={0} sx={{ backgroundColor: 'transparent' }}>
                                                <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'end' }}>
                                                    <Tooltip title="Original image" arrow>
                                                        <div style={{ width: '50%', display: 'table-cell', background: 'transparent', paddingRight: '5px' }}>
                                                            <img style={{
                                                                width: '100%',
                                                                height: `calc(${imageHeight * 0.4}px)`,
                                                                //  height: `calc(${((100) - (numHeightOfHeader + numHeightOfFooter)) / 2}vh - ${customPadding * 2}px - ${fieldAreaMargin}px)`,
                                                                objectFit: 'contain'
                                                            }} src={'data:image/png;base64,' + individualDetectionData.cropb64} />
                                                        </div>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    {individualDetectionData.imageBase64 ?
                                                        <Tooltip title="Recognition" arrow>
                                                            <div style={{ display: 'table-cell', background: 'transparent' }}>
                                                                <img style={{
                                                                    width: '100%',
                                                                    height: `calc(${imageHeight * 0.4}px)`,
                                                                    // height: `calc(${((100) - (numHeightOfHeader + numHeightOfFooter)) / 2}vh - ${customPadding * 2}px - ${fieldAreaMargin}px)`,
                                                                    paddingLeft: '5px', objectFit: 'contain'
                                                                }} src={'data:image/png;base64,' + individualDetectionData.imageBase64 ?? ''} />
                                                            </div>
                                                        </Tooltip>
                                                        :
                                                        <React.Fragment />}
                                                </Grid>
                                            </Grid>

                                            {/* </div> */}
                                        </Card>

                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <div style={{ height: `${numHeightOfFooter}vh`, width: '100vw', backgroundColor: 'transparent' }}>

            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        datastore: state.datastore,
    };
};
export default connect(mapStateToProps)(IndividualAssetViewer);

import React, { useEffect, useMemo, useRef, useState } from "react";
import { Grid, Switch } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import SimpleDialogBox from "../../../../cidgets/SimpleDialogBox";
import PinnedDistressFields from "./PinnedDistressFields";
import ImageAnnotatorV3 from "./ImageAnnotatorV3";
import { Tooltip, Box, Button, Typography } from "@mui/material";
const AllDistressTable = (props) => {
  const {
    isGroupData,
    dataList = [],
    disableHeader = false,
    drProjectData,
    distressPinned,
    setDistressPinned,
    handleSeekTo,
    fps,
    distressData,
  } = props;

  const playerRef = useRef(null);
  const tableRef = useRef(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedDistressPinned, setSelectedDistressPinned] = useState(null);
  const isRectangle = selectedDistressPinned
    ? selectedDistressPinned?.isRectangle
    : null;
  const [showMaskImage, setShowMaskImage] = useState(false);
  const [videoPlayerState, setVideoPlayerState] = useState(null);
  const { projectType } = drProjectData;
  let bbox;
  const isAdvanceProjectType = projectType === 4 ? true : false;
  const rectangleCondCheck = isRectangle === undefined || isRectangle === true;
  const newBbox = rectangleCondCheck
    ? [
        selectedDistressPinned?.versioning?.bbox?.[0] ??
          selectedDistressPinned?.bbox?.[0],
        selectedDistressPinned?.versioning?.bbox?.[1] ??
          selectedDistressPinned?.bbox?.[1],
        selectedDistressPinned?.versioning?.bbox?.[2] ??
          selectedDistressPinned?.bbox?.[2],
        selectedDistressPinned?.versioning?.bbox?.[3] ??
          selectedDistressPinned?.bbox?.[3],
      ]
    : selectedDistressPinned
    ? selectedDistressPinned?.versioning?.bbox ?? selectedDistressPinned?.bbox
    : [];
  const fillMap = {
    0: "#caee00",
    1: "#60EE00",
    3: "#00ee83",
    4: "#00eeda",
    10: "#ee1000",
  };
  const fill = fillMap[selectedDistressPinned?.defectName] || "#025196";
  function getCorner(pivotX, pivotY, diffX, diffY) {
    const distance = Math.sqrt(diffX * diffX + diffY * diffY);

    const angle = Math.atan2(diffY, diffX);

    const x = pivotX + distance * Math.cos(angle);
    const y = pivotY + distance * Math.sin(angle);

    return [x, y];
  }
  function createPairs(boundingBox) {
    const pairs = [];
    for (let i = 0; i < boundingBox.length; i += 2) {
      pairs.push([boundingBox[i], boundingBox[i + 1]]);
    }
    return pairs;
  }
  function calculateRectangleCorners(x, y, width, height) {
    const topLeft = getCorner(x, y, 0, 0);
    const topRight = getCorner(x, y, width, 0);
    const bottomRight = getCorner(x, y, width, height);
    const bottomLeft = getCorner(x, y, 0, height);
    return [topLeft, topRight, bottomRight, bottomLeft];
  }
  if (!isRectangle) {
    const pairs = createPairs(newBbox);
    bbox = [{ defectName: selectedDistressPinned?.defectName, fill, pairs }];
  } else {
    const [x, y, width, height] = newBbox;
    const corners = calculateRectangleCorners(x, y, width, height);
    bbox = [
      { defectName: selectedDistressPinned?.defectName, fill, pairs: corners },
    ];
  }
  useEffect(() => {
    const element = document.getElementById(distressPinned?.defectId);
    element?.scrollIntoView({ behavior: "smooth", block: "center" });
  }, [distressPinned]);

  useEffect(() => {
    if (playerRef) {
      playerRef.current?.subscribeToStateChange(handleBind);
    }
  });

  const handleBind = (state) => {
    setVideoPlayerState(state);
  };

  useEffect(() => {
    return () => {
      if (playerRef.current) {
        playerRef.current = null;
      }
    };
  }, [playerRef]);
  const handlePinning = (data) => {
    const check =
      data === null
        ? false
        : distressPinned === null
        ? false
        : data.defectId === distressPinned.defectId;
    if (data) {
      // handleSeekTo(Number((data.frameNumber / fps)).toFixed(5))
      handleSeekTo(
        Number(
          isGroupData ? data.groupFrameNumber / fps : data.frameNumber / fps
        ).toFixed(5)
      );
    }
    setDistressPinned(check ? null : data);
  };
  const handleOpenModal = (data) => {
    setOpenDialog(true);
    setSelectedDistressPinned(data);
  };
  const handleCloseModal = () => {
    setOpenDialog(false);
    setShowMaskImage(false);
    // setSelectedDistressPinned(null);
  };
  const style = {
    paddingLeft: "15px",
    paddingRight: "15px",
    width: "100%",
    height: "100%",
    overflow: "auto",
  };
  if (distressData?.length === 0) {
    return <>Loading...</>;
  }
  const { dataInfoV2: dataInfo } = distressData;
  const { defectName } = dataInfo;
  if (dataInfo === undefined || dataInfo === null) {
    return <></>;
  }

  const { transversePosition, type } = dataInfo;
  return (
    <>
      <SimpleDialogBox
        open={openDialog}
        onClose={handleCloseModal}
        title="Distress details"
        width={800}
        cancelText="Close"
        bodyWidget={
          <>
            <Box sx={{ marginBottom: "20px" }}>
              <PinnedDistressFields
                distressPinned={selectedDistressPinned}
                dataInfo={dataInfo}
                drProjectData={drProjectData}
              />
            </Box>
            {isAdvanceProjectType ? (
              <Grid container spacing={0} sx={{ height: "100%" }}>
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <ImageAnnotatorV3
                    imageUrl={
                      showMaskImage
                        ? selectedDistressPinned?.maskPath
                        : selectedDistressPinned?.stitchPath
                    }
                    defaultHeight={85}
                    defaultPadding={16}
                    imageHeight={selectedDistressPinned?.HRHeight}
                    imageWidth={selectedDistressPinned?.HRWidth}
                    drawMap={showMaskImage ? false : true}
                    editBox={false}
                    nonEditableBbox={bbox}
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={1} sx={{ height: "100%" }}>
                <Grid
                  item
                  xs={6}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <ImageAnnotatorV3
                    distressPinned={distressPinned}
                    imageUrl={
                      showMaskImage
                        ? selectedDistressPinned?.maskPath
                        : selectedDistressPinned?.stitchPath
                    }
                    defaultHeight={85}
                    defaultPadding={16}
                    imageHeight={selectedDistressPinned?.HRHeight}
                    imageWidth={selectedDistressPinned?.HRWidth}
                    drawMap={showMaskImage ? false : true}
                    editBox={false}
                    nonEditableBbox={bbox}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <ImageAnnotatorV3
                    imageUrl={selectedDistressPinned?.depthMapPath}
                    defaultHeight={85}
                    defaultPadding={16}
                    imageHeight={selectedDistressPinned?.HRHeight}
                    imageWidth={selectedDistressPinned?.HRWidth}
                    drawMap={false}
                    editBox={false}
                  />
                </Grid>
              </Grid>
            )}
          </>
        }
        showPositive
        positiveButtonWidget={
          <>
            <Button
              size="medium"
              variant="contained"
              onClick={() => {
                setShowMaskImage(!showMaskImage);
              }}
              sx={{
                textTransform: "initial",
                backgroundColor: "#025196",
                color: "#FFFFFF",
                "&:hover": {
                  backgroundColor: "#025196",
                  color: "#FFFFFF",
                },
              }}
            >
              Show {showMaskImage ? "stitched" : "mask"}
            </Button>
          </>
        }
      />
      <div style={style}>
        {dataList.length > 0 ? (
          <table className="assetTable">
            {disableHeader ? (
              <></>
            ) : (
              <thead style={{}}>
                <tr>
                  <th className="" style={{ width: "10%" }}>
                    Number
                  </th>
                  <th className="" style={{ width: "25%" }}>
                    Category
                  </th>
                  <th className="" style={{ width: "10%" }}>
                    Transverse position
                  </th>
                  <th className="" style={{ width: "10%" }}>
                    Area (m<sup>2</sup>)
                  </th>
                  {drProjectData.projectType !== 4 && (
                    <th className="" style={{ width: "10%" }}>
                      Depth (mm)
                    </th>
                  )}
                  <th className="" style={{ width: "5%" }}></th>
                </tr>
              </thead>
            )}
            <tbody ref={tableRef}>
              {dataList.map((e, i) => {
                const check = e.defectId === distressPinned?.defectId ?? "";
                return (
                  <tr
                    key={e.defectId}
                    id={e.defectId}
                    style={{
                      transition: "all ease 0.0s",
                      ...(check
                        ? {
                            backgroundColor: "#B0FFA4",
                            border: "2px solid #60EE00",
                            cursor: "pointer",
                          }
                        : { cursor: "pointer" }),
                    }}
                    role="button"
                  >
                    <td
                      style={{ width: "10%" }}
                      onClick={() => {
                        handlePinning(e);
                      }}
                    >
                      {i + 1}
                    </td>
                    <td
                      style={{ width: "25%" }}
                      onClick={() => {
                        handlePinning(e);
                      }}
                      className="capitalize-text"
                    >
                      {Number(
                        e.versioning ? e.versioning.defectName : e.defectName
                      ) === 1
                        ? type[e.type]?.name
                        : ""}{" "}
                      {e.defectName?.length === 0 ? (
                        <span style={{ color: "gray" }}>
                          <i>{"unnamed"}</i>
                        </span>
                      ) : (
                        defectName[
                          e.versioning ? e.versioning.defectName : e.defectName
                        ]["name"]
                      )}
                    </td>
                    <td
                      style={{ width: "10%" }}
                      onClick={() => {
                        handlePinning(e);
                      }}
                      className=""
                    >
                      {
                        transversePosition[
                          e?.versioning?.transversePosition
                            ? e.versioning.transversePosition
                            : e.transversePosition
                        ]?.name
                      }
                    </td>
                    <td
                      style={{ width: "10%" }}
                      onClick={() => {
                        handlePinning(e);
                      }}
                      className=""
                    >
                      {Number(e?.versioning?.area ?e.versioning.area :e.area) === -1
                        ? "-"
                        : Number(e?.versioning?.area ?e.versioning.area :e.area).toFixed(2)}
                    </td>
                    {drProjectData.projectType !== 4 && (
                      <td
                        style={{ width: "10%" }}
                        onClick={() => {
                          handlePinning(e);
                        }}
                        className=""
                      >
                        {Number(e?.versioning?.depth ? e.versioning.depth : e.depth) ===
                        -1
                          ? "N/A"
                          : Number(
                            e?.versioning?.depth ? e.versioning.depth : e.depth * 1000
                            ).toFixed(2)}
                      </td>
                    )}
                    <td
                      style={{ width: "5%" }}
                      className=""
                      onClick={() => {
                        handleOpenModal(e);
                      }}
                    >
                      <Tooltip title={`View distress details`} arrow>
                        <KeyboardArrowRightIcon />
                      </Tooltip>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <div
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            No distresses defect found
          </div>
        )}
      </div>
    </>
  );
};

const AllDistressList = (props) => {
  const {
    isGroupData = false,
    distressProjectPredictions,
    isWholeDistressVisualization,
    handleDataVisualizationChange,
  } = props;
  const {
    drProjectData,
    distressPinned,
    setDistressPinned,
    handleSeekTo,
    fps,
    distressData,
  } = props;
  let allDetections = useMemo(() => {
    let detections = [];
    distressProjectPredictions.forEach((prediction) => {
      Object.values(prediction.detections)
        .filter((eachDetection) => {
          return eachDetection.archived
            ? eachDetection.archived === "0"
            : eachDetection;
        })
        .forEach((detection) => {
          const combinedData = {
            ...detection,
            ...prediction,
          };
          detections = [...detections, combinedData];
        });
    });
    return detections;
  }, [distressProjectPredictions]);
  allDetections.sort((a, b) => {
    return isGroupData
      ? a.groupFrameNumber - b.groupFrameNumber
      : a.frameNumber - b.frameNumber;
  });
  return (
    <>
      <Grid container spacing={0} sx={{ height: `calc(100vh - ${16 * 2}px)` }}>
        <Grid
          item
          xs={9}
          sx={{
            height: `calc(10vh - ${16 * 2}px)`,
            display: "flex",
            justifyContent: "start",
            alignItems: "end",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: "650",
              fontSize: "18px",
              // marginTop: "17px",
              marginLeft: "20px",
            }}
          >
            Distress driven{" "}
          </Typography>
        </Grid>
        <Grid
          item
          xs={3}
          sx={{
            height: `calc(10vh - ${16 * 2}px)`,
            display: "flex",
            justifyContent: "end",
            alignItems: "end",
          }}
        >
          <Tooltip title={`Section driven`} arrow placement="left-end">
            <Switch
              checked={isWholeDistressVisualization}
              onChange={handleDataVisualizationChange}
              inputProps={{ "aria-label": "controlled" }}
              sx={{
                "& .MuiSwitch-thumb": {
                  backgroundColor: "#025196",
                },
                "& .Mui-checked": {
                  color: "#025196",
                },
                "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                  backgroundColor: "#025196",
                },
              }}
            />
          </Tooltip>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{ height: `calc(90vh - ${16 * 2}px)`, overflowY: "auto" }}
        >
          <AllDistressTable
            drProjectData={drProjectData}
            distressPinned={distressPinned}
            setDistressPinned={setDistressPinned}
            handleSeekTo={handleSeekTo}
            fps={fps}
            distressData={distressData}
            dataList={allDetections}
            isGroupData={isGroupData}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default AllDistressList;
